/**
 * Provides an easy way to make a POST request from a <a> link click.
 */
var $ = require('jquery');

$('body').on('click', '.link-poster', function (event) {
	event.preventDefault();

	var url = $(this).attr('href');

	var data = $(this).data('link-poster');
	data = data ? data.clarify() : {};
	data['_method'] = $(this).data('method');

	$.pjax({ url: url, data: data, type: 'POST' });
});
