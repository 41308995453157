<template>
	<div :id="id" ref="editor">
		<textarea ref="textarea" :name="name" :value="localValue" :class="inputClass" :placeholder="placeholder" />
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import { useEditorProps } from '@/lib/components/Shared/editor/editorProps';
import { useUploadProps } from '@/lib/components/Shared/editor/uploads/uploadsProps';
import { simpleMdeController } from '@/lib/components/Shared/editor/simplemde/SimpleMde.controller';
import { useLanguageProps } from '@/lib/components/Shared/editor/languageProps';

export default defineComponent({
	props: {
		...useLanguageProps,
		...useUploadProps,
		...useEditorProps,
	},

	setup(props, { emit }) {
		return simpleMdeController(props, emit);
	},
});
</script>

<style>
.editor-toolbar .btn-markdown-newline {
	display: block;
	height: 0;
	opacity: 0;
	margin-bottom: 2px;
	cursor: default;
}

.editor-toolbar {
	padding: 5px 10px 1px;
}

.editor-toolbar a {
	margin-top: 1px;
	margin-bottom: 1px;
}
</style>
