var $ = require('jquery');

module.exports = function() {
    // Attachments
    var toggleAttachmentOptions = function() {
        var attachmentTypesField = $('#attachment-type-field');

        attachmentTypesField.addClass('hidden');
        $('[id^="attachment-types-"]').addClass('hidden');

        if ($('#attachments').is(':checked')) {
            attachmentTypesField.removeClass('hidden');
            toggleAttachmentTypes();
        }
    };

    // Attachment types
    var toggleAttachmentTypes = function() {
        var selected = $('#attachmentTypeFieldId').val();
        var types = $('#attachment-types-'+selected);

        $('[id^="attachment-types-"]').addClass('hidden').find(':input').attr('disabled', true);

        if (types.length) {
            types.removeClass('hidden').find(':input').attr('disabled', false);
        }
    };

    $('#attachments').click(toggleAttachmentOptions);
    $('#attachmentTypeFieldId').change(toggleAttachmentTypes);

    toggleAttachmentOptions();
};