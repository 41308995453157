export default function (value, type) {
  if (value === null || typeof value === 'undefined' || (typeof value === 'string' && value.trim() == '')) {
    return false;
  }

  switch(type ? type.toLowerCase() : null) {
    case 'date':
      return !!value && !!value['date'] && typeof value['date'] === 'string';
    case 'datetime':
      const dateTimeArray = !!value && !!value['datetime'] && typeof value['datetime'] === 'string' ? value['datetime'].split(' ') : [];
      const date = dateTimeArray[0];
      const time = dateTimeArray[1];

      return !!date && !!time;
    case 'time':
      return !!value && typeof value === 'string';
  }

  return value === 0 || !!value;
};
