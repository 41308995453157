import Vue from 'vue';
import store from '@/lib/store';
import ReviewList from './component/ReviewList';

export default function() {
  const reviewList = new Vue({
    el: '#review-list',
    name: 'ManageReviewListApp',
    components: {
      'review-list': ReviewList
    },
    store
  });

  $(document).one('pjax:end', () => {
    reviewList.$destroy();
  });
}
