<template>
	<div class="row">
		<div :class="infoClass">
			<h3>{{ category.name }}</h3>
			<!--	eslint-disable-next-line vue/no-v-html -->
			<div v-html="category.description"></div>
			<div v-if="showBlankPdfLink" class="form-group">
				<a :href="category.blankPdf" class="pdf-link-blank-pdf-link" target="_blank">
					<i class="af-icons af-icons-pdf"></i>
					<span class="no-decoration">{{ lang.get('entries.download_sample_pdf') }}</span>
				</a>
			</div>
			<div class="form-group">
				<a v-if="enterAllowed" :href="category.startEntryUrl" class="btn btn-lg btn-primary ignore">
					{{ lang.get('shared.enter') }}
				</a>
			</div>
		</div>
		<div v-if="hasSponsors" class="col-md-6">
			<h4 v-if="category.sponsorsHeading.length">{{ category.sponsorsHeading }}</h4>
			<span v-for="(sponsor, i) in category.sponsors" :key="i" class="card-sponsor">
				<img :src="sponsor.file" :alt="sponsor.alt" :style="`max-width:${sponsor.params.w}px;`" />
			</span>
		</div>
	</div>
</template>

<script>
import langMixin from '@/lib/components/Translations/mixins/lang-mixin';
import settingsMixin from '@/lib/components/Shared/mixins/settings-mixin';

export default {
	mixins: [langMixin, settingsMixin],
	props: {
		category: {
			type: Object,
			required: true,
		},
	},
	computed: {
		infoClass() {
			return this.hasSponsors ? 'col-md-6' : 'col-md-12';
		},
		hasSponsors() {
			return this.category.sponsors.length > 0;
		},
		showBlankPdfLink() {
			return parseInt(this.settingsService.get('allow-blank-pdf-download')) === 1;
		},
		enterAllowed() {
			return this.category.activeEntryRound || false;
		},
	},
};
</script>

<style scoped>
.card-sponsor {
	display: inline-block;
	padding: 10px 10px 10px 0;
}

h4 {
	margin-top: 0;
}
</style>
