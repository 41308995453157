var $ = require('jquery');

/**
 * Vimeo video player.
 *
 * Usage:
 *   var vimeoPlayer = new VimeoPlayer;
 *   vimeoPlayer.setup(elements);
 *
 */
module.exports = function () {

    /**
     * Keep track of all player instances.
     */
    var players = [];

    /**
     * Loop through all elements and initialise the video player.
     *
     * Makes use of Vimeo Player API
     * https://developer.vimeo.com/player
     * 
     * @param {String} elements
     * @return {Array}
     */
    this.setup = function (elements) {
        $(elements).each(function() {
            var player = new Vimeo.Player(this);

            players.push(player);
        });

        return players;
    };

    /**
     * Pause video playback.
     */
    this.pause = function() {
        $.each(players, function(index, player) {
            player.pause();
        });
    };
}
