<template>
  <portal :target-el="`#${modalId}`">
    <modal
      v-if="showModal"
      v-model="showModal"
      :header="false"
      :confirm-button-label="confirmButtonLabel"
      :close-button-label="cancelButtonLabel"
      @closed="$emit('closed')"
      @confirmed="$emit('confirmed')"
    >
      <close-icon
        slot="before-content"
        @click.prevent="$emit('closed')"
      ></close-icon>
      {{ confirmation }}
    </modal>
  </portal>
</template>

<script>
import CloseIcon from '../ListActions/Partials/CloseIcon';
import { Modal } from 'vue-bootstrap';

export default {
  components: {
    CloseIcon,
    Modal
  },
  props: {
    modalId: {
      type: String,
      default: 'modal-target-confirmation-modal'
    },
    showModal: {
      type: Boolean,
      default: false
    },
    confirmation: {
      type: String,
      default: 'Are you sure?'
    },
    confirmButtonLabel: {
      type: String,
      default: 'Continue'
    },
    cancelButtonLabel: {
      type: String,
      default: 'Cancel'
    }
  },
  beforeMount() {
    let modalTarget = document.getElementById(this.modalId);

    if (!modalTarget) {
      modalTarget = document.createElement('div');
      modalTarget.setAttribute('id', this.modalId);
      const appendTo =
        document.getElementById('pjaxContainer') || document.body;
      appendTo.appendChild(modalTarget);
    }
  }
};
</script>
