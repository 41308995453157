import Audio from '@/lib/audio';
import AutoSaver from '@/lib/autosaver';
import StandaloneUploader from '@/lib/standalone-uploader';
import TableField from '@/lib/components/Fields/TableField';
import toastr from '@/lib/tectoastr';
import VideoModal from '@/domain/services/VideoPlayer/VideoPlayerModal';
import VideoPlayer from '@/domain/services/VideoPlayer/VideoPlayer';
import VideoThumbnails from '@/lib/video-thumbnails';
import Vue from 'vue';

export default function () {
	// Setup standalone uploader (for file fields).
	new StandaloneUploader();

	const errorHandling = (response) => {
		if (response.responseJSON) {
			const errorsString = Object.values(response.responseJSON)
				.reduce((acc, val) => acc.concat(val), [])
				.join(' ');

			toastr.error(errorsString);
		}
	};

	// Autosaver
	const autosaver = new AutoSaver($('form.autosave'), undefined, (response) => errorHandling(response));
	autosaver.setSupressWarnings(true);

	// Save actionTaken value
	$('.review-task-form button[value="proceed"], .review-task-form button[value="stop"]').on('click', function (event) {
		event.preventDefault();
		$('#actionTaken').val($(this).val());
		autosaver.saveSubmit();
	});

	// Save for later
	const saveForLater = (targetUrl) => {
		autosaver.setSuccessCallback(() => toastr.success($('#review-saved').html()));
		autosaver.saveClose(targetUrl);
	};

	$('.review-task-form button[value="save"]').on('click', function (event) {
		event.preventDefault();
		saveForLater($(this).data('target'));
	});

	// Audio
	const audio = new Audio();
	audio.setup('jp-jplayer');

	// Video
	const videoThumbnails = new VideoThumbnails();

	VideoPlayer.setup('video-js-standalone');
	VideoModal.setup('play-video');
	videoThumbnails.setup('preview-container.video');

	// Initialise table fields
	const tableFields = document.getElementsByClassName('table-field-input-container');

	for (let i = 0; i < tableFields.length; i++) {
		new Vue({
			el: tableFields[i],
			name: 'TaskApp' + i,
			components: {
				'table-field': TableField,
			},
			provide: function () {
				return {
					lang: {
						locale: App.language,
					},
				};
			},
		});
	}
}
