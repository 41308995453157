// https://github.com/jquery/jquery/security/advisories/GHSA-gxr4-xjj5-5px2
$.htmlPrefilter = function(html) {
  return html;
};

// https://github.com/jquery/jquery/issues/2432
$.ajaxPrefilter(function(s) {
  if (s.crossDomain) {
    s.contents.javascript = false;
  }
});
