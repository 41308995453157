<script>
import $ from 'jquery';
import _ from 'underscore';
import toastr from 'toastr';
import Columnator from './Shared/Columnator.vue';
import HelpIcon from './Shared/HelpIcon.vue';
import ReactiveMarker from './Shared/ReactiveMarker.vue';
import SelectAllMarker from './Shared/SelectAllMarker.vue';
import FundAllocator from './ListActions/FundAllocator';
import Archiver from '@/lib/components/ListActions/Archiver';
import Unarchiver from '@/lib/components/ListActions/Unarchiver';
import Deletism from '@/lib/components/ListActions/Deletism';
import Undeletism from './ListActions/Undeletism';
import ListAction from './ListActions/ListAction';
import Resubmission from './ListActions/Resubmission';
import Star from './Shared/Star';
import Recusal from '@/lib/components/ListActions/Recusal';
import RecusalRemover from '@/lib/components/ListActions/RecusalRemover';
import Destroyer from './ListActions/Destroyer';
import { Popover } from 'vue-bootstrap';
import Reviewer from './ListActions/Reviewer';
import ContractCreator from './ListActions/ContractCreator';
import DuplicateArchiver from './ListActions/DuplicateArchiver';

import ScheduleGrantReport from './ListActions/ScheduleGrantReport';
import AssignJudges from '@/lib/components/ListActions/AssignJudges';
import CreateDocument from '@/lib/components/ListActions/CreateDocument';
import FormInviterListAction from '@/modules/forms/components/FormInviterListAction';
import EditDocument from '@/modules/documents/components/EditDocument';
import AllocationPaymentsLink from '@/modules/allocation-payment/components/AllocationPaymentsLink.vue';
import CommentAction from '@/lib/components/ListActions/CommentAction.vue';
import ReviewTaskResetAction from '@/lib/components/ListActions/ReviewTaskResetAction';
import ReassignReviewer from '@/lib/components/ListActions/ReassignReviewer';

import 'datatables.net';
import 'datatables.net-colreorder';
import 'datatables.net-responsive';

/**
 * The DataTable component.
 *
 * Provides functionality to check/uncheck all rows.
 */
export default {
	components: {
		Columnator,
		HelpIcon,
		ReactiveMarker,
		SelectAllMarker,
		ListAction,
		Resubmission,
		FundAllocator,
		ScheduleGrantReport,
		Archiver,
		Unarchiver,
		Undeletism,
		Deletism,
		Recusal,
		RecusalRemover,
		Star,
		Reviewer,
		ContractCreator,
		DuplicateArchiver,
		AssignJudges,
		CreateDocument,
		Destroyer,
		Popover,
		FormInviterListAction,
		EditDocument,
		AllocationPaymentsLink,
		CommentAction,
		ReassignReviewer,
		ReviewTaskResetAction,
	},
	props: {
		ids: {
			type: Array,
			required: true,
		},
		reorderUrl: {
			type: String,
			default: '',
		},
		reorderParams: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			table: null,
			datatable: null,
			selected: [],
		};
	},
	computed: {
		allChecked() {
			return this.selected.length === this.ids.length;
		},
	},
	watch: {
		selected() {
			this.$emit('update:selected', this.selected);
		},
	},
	mounted() {
		let table = this.$el;

		if (table.nodeName.toLowerCase() !== 'table') {
			console.warn('The child element should be a table.');
		}

		this.table = $(table);

		const headers = this.table.find('th');
		const fixedColumns = this.getFixedColumnsLeft(headers);
		const columnDefs = this.getColumnDefs(headers);

		this.datatable = this.table.DataTable({
			colReorder: {
				realtime: false,
				fixedColumnsLeft: fixedColumns,
			},
			autoWidth: false,
			bPaginate: false,
			bInfo: false,
			searching: false,
			bSort: false,
			responsive: true,
			columnDefs: columnDefs,
		});

		this.blockCheckboxInput();
		this.removeTabIndexOfCheckboxes();
		this.bindEvents();
	},
	beforeDestroy() {
		if (!this.datatable) {
			return;
		}

		this.datatable.destroy();
		this.datatable = null;

		this.unbindEvents();
	},
	methods: {
		bindEvents() {
			$(window).on('slidein', this.refresh);
			this.table.on('column-reorder.dt', this.reorderColumns);
		},
		unbindEvents() {
			$(window).off('slidein', this.refresh);
			this.table.off('column-reorder.dt', this.reorderColumns);
		},
		checkAll() {
			this.selected = !this.allChecked ? this.ids.slice() : [];
		},
		getColumnDefs(headers) {
			return _.map(headers, (th, i) => {
				th = $(th);

				return {
					targets: i,
					width: th.data('fixed') ? '1%' : null,
				};
			});
		},
		getFixedColumnsLeft(headers) {
			return _.reduce(headers, (memo, th) => memo + ($(th).data('fixed') ? 1 : 0), 0);
		},
		blockCheckboxInput() {
			this.table.find('td:first-child input[type="checkbox"], td:first-child .checkbox').click((event) => {
				event.stopPropagation();
			});
		},
		removeTabIndexOfCheckboxes() {
			this.table.find('td:first-child').each((index, td) => {
				td.removeAttribute('tabindex');
			});
		},
		reorderColumns() {
			if (!this.reorderUrl) {
				return;
			}

			const columns = [];

			this.table.find('th').each((index, th) => {
				columns.push($(th).data('name'));
			});

			this.$http.post(this.reorderUrl, Object.assign(this.reorderParams, { columns: columns })).then(
				() => {},
				() => {
					toastr.error($('#alerts-column-order').text());
				}
			);
		},
		refresh() {
			setTimeout(() => {
				if (!this.datatable) {
					return;
				}

				this.datatable.responsive.rebuild();
				this.datatable.responsive.recalc();
			}, 300);
		},
	},
};
</script>
