<template>
	<popover v-if="canDisplayTitle" :content="title" :trigger="triggerTooltip">
		<button :class="classObject" :style="styleObject" @click="save" v-text="value"></button>
	</popover>

	<button v-else :class="classObject" :style="styleObject" @click="save" v-text="value"></button>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import isTouchDevice from '@/lib/is-touch-device.js';
import { Popover } from 'vue-bootstrap';
import { getTrans } from '@/services/global/translations.interface';

export default {
	components: {
		Popover,
	},
	props: {
		enabled: {
			type: Boolean,
			required: true,
		},
		isTopPick: {
			type: Boolean,
			required: true,
		},
		isBottomPick: {
			type: Boolean,
			required: true,
		},
		value: {
			type: Number,
			required: true,
		},
		category: {
			type: String,
			required: true,
		},
		entrySlug: {
			type: String,
			required: true,
		},
		scoreSetSlug: {
			type: String,
			required: true,
		},
		canRemove: {
			type: Boolean,
			required: true,
		},
		entryView: {
			type: Boolean,
			required: false,
		},
	},
	data: () => ({
		selected: false,
	}),
	computed: {
		...mapGetters('topPick', ['entryPreference', 'entryPreferenceSaving', 'pickTitle', 'selectedPreferences']),

		canDisplayTitle() {
			return (this.entryView || this.isTopPick || this.isBottomPick) && this.title;
		},

		saving() {
			return this.entryPreferenceSaving(this.entrySlug) === this.value;
		},
		title() {
			const title = this.pickTitle(this.value) || '';
			const lang = getTrans();

			if (this.isTopPick) {
				return this.extractInterfaceText(lang.get('judging.picks.top'), this.entryView ? title : null);
			} else if (this.isBottomPick) {
				return this.extractInterfaceText(lang.get('judging.picks.bottom'), this.entryView ? title : null);
			}

			return title;
		},

		active() {
			return this.entryPreference(this.entrySlug) === this.value;
		},
		alreadySelected() {
			return (
				// Loose comparison with value
				// eslint-disable-next-line eqeqeq
				this.selectedPreferences.filter((preference) => preference == this.value).length === 1
			);
		},
		triggerTooltip() {
			return (!this.active && this.title) || this.isTopPick || this.isBottomPick ? 'hover' : 'click';
		},
		classObject() {
			return {
				'top-pick-preference': true,
				hover: !isTouchDevice(),
				active: this.active,
				disabled: !this.enabled,
				saving: this.saving,
				selected: this.entryView && this.alreadySelected,
			};
		},

		styleObject() {
			return {
				border: 0,
			};
		},
	},
	methods: {
		...mapActions('topPick', ['saveEntryPreference']),
		save() {
			if ((!this.active || this.canRemove) && !this.saving && this.enabled) {
				this.saveEntryPreference({
					scoreSetSlug: this.scoreSetSlug,
					entrySlug: this.entrySlug,
					value: this.active ? 0 : this.value,
					category: this.category,
				});
			}
		},

		extractInterfaceText(text, suffix) {
			text = text.startsWith('<edit-interface-text')
				? text.match(/^\s*<edit-interface-text.*\s+payload="([^"]*)".*/i)[1].clarify()?.text
				: text;

			if (suffix) {
				return `${text}: ${suffix}`;
			}

			return text;
		},
	},
};
</script>
