/**
 * Convert markdown links to html e.g.
 * Foo [My link](http://google.com) bar
 * =>
 * Foo <a href="http://google.com">My link</a> bar
 *
 * @param string
 * @returns string
 */
exports.applyMarkdownLinks = function(string) {
  return string.replace(/\[(.+)\]\((.+)\)/gim, function(a, b, c) {
    return '<a href="' + c + '">' + b + '</a>';
  });
};
