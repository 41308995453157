var $ = require('jquery');
var underscore = require('underscore');
/* eslint-disable @typescript-eslint/naming-convention */
var Swipe = require('swipejs');
var VideoPlayer = require('@/domain/services/VideoPlayer/VideoPlayer').default;
var YouTubePlayer = require('./video-youtube.js');
var VimeoPlayer = require('./video-vimeo.js');
var VideoThumbnails = require('./video-thumbnails.js');
var FullScreen = require('./fullscreen.js');
var url = require('./url.js');
/* eslint-enable @typescript-eslint/naming-convention */
/**
 * Slideshow.
 *
 * Usage:
 *   var slideshow = new Slideshow();
 *   slideshow.setup(container);
 *
 */
module.exports = function () {
	/**
	 * The element with slideshow
	 */
	var slideshow;

	/**
	 * The Swipe.js instance
	 */
	var slider;

	/**
	 * An array that holds ids of all slides
	 */
	var slides = [];

	/**
	 * Video player
	 */
	var player;

	/**
	 * YouTube player
	 */
	var youtubePlayer;

	/**
	 * Vimeo player
	 */
	var vimeoPlayer;

	/**
	 * A template used to display a 'No slides found' message
	 */
	var notFoundTemplate = underscore.template('<div class="no-slides-found"><h1><%= message %></h1></div>');

	/**
	 * Maintain a reference to the original this
	 */
	// eslint-disable-next-line @typescript-eslint/no-this-alias
	var self = this;

	/**
	 * Selectors
	 */
	var selectors = {
		pjaxContainer: '#pjaxContainer',
		slideshow: '#slideshow',
		contactSheet: '#contact-sheet',
		slides: '.slideshow-slide, .slideshow-contact-sheet',
		video: 'video-js-standalone',
		youtube: 'iframe[id*=youtube]',
		vimeo: 'iframe[id*=vimeo]',
		videoThumbnails: 'video-link',
		pageTitle: 'div[data-title]',
		nav: {
			previous: '.slideshow-nav-previous',
			next: '.slideshow-nav-next',
			fullscreen: '.slideshow-nav-fullscreen',
		},
	};

	/**
	 * Data attributes
	 */
	var data = {
		previousEntry: 'previous-entry',
		nextEntry: 'next-entry',
		noSlidesFound: 'no-slides-found',
		pageTitle: 'title',
	};

	/**
	 * Pjax options
	 */
	var pjaxOptions = {
		container: selectors.pjaxContainer,
		timeout: 25000,
	};

	/**
	 * Swipe.js options
	 */
	var sliderOptions = {
		draggable: false,
		autoRestart: false,
		continuous: false,
		disableScroll: true,
		stopPropagation: true,
	};

	/**
	 * Setup video
	 */
	var setupVideo = function () {
		setupStandaloneVideo();
		setupYouTubeVideo();
		setupVimeoVideo();

		setupVideoThumbnails();
	};

	/**
	 * Setup standalone video
	 */
	var setupStandaloneVideo = function () {
		VideoPlayer.setup(selectors.video, {
			fluid: false,
		});
	};

	/**
	 * Setup YouTube video
	 */
	var setupYouTubeVideo = function () {
		youtubePlayer = new YouTubePlayer();

		youtubePlayer.setup(selectors.youtube);
	};

	/**
	 * Setup Vimeo video
	 */
	var setupVimeoVideo = function () {
		vimeoPlayer = new VimeoPlayer();

		vimeoPlayer.setup(selectors.vimeo);
	};

	/**
	 * Setup video thumbnails
	 */
	var setupVideoThumbnails = function () {
		var videoThumbnails = new VideoThumbnails();

		videoThumbnails.setup(selectors.videoThumbnails);
	};

	/**
	 * Track slides - populate slides array
	 */
	var trackSlides = function () {
		slideshow.find(selectors.slides).each(function (index, slide) {
			slides[index] = $(slide).attr('id');
		});
	};

	/**
	 * Setup slider
	 */
	var setupSlider = function () {
		var startSlide = url.getUrlParameter(window.location.href, 'slideshow-reverse') ? slides.length - 1 : 0;

		slider = new Swipe(
			slideshow.find(selectors.slideshow)[0],
			$.extend(sliderOptions, {
				startSlide: startSlide,
			})
		);
	};

	/**
	 * Pause all video playback
	 */
	var pauseVideoPlayback = function () {
		VideoPlayer.pause(selectors.video);
		youtubePlayer.pause();
		vimeoPlayer.pause();
	};

	/**
	 * Return the count of slides
	 *
	 * @return {Number}
	 */
	this.slideCount = function () {
		return slider.getNumSlides();
	};

	/**
	 * Is the slideshow empty?
	 *
	 * @return {Boolean}
	 */
	this.slideshowEmpty = function () {
		return slider.getNumSlides() <= 1;
	};

	/**
	 * Is the first slide open?
	 *
	 * @return {Boolean}
	 */
	this.onFirstSlide = function () {
		return slider.getPos() === 0;
	};

	/**
	 * Return the index of the current slide
	 *
	 * @return {Number}
	 */
	this.currentSlide = function () {
		return slider.getPos();
	};

	/**
	 * Is the last slide open?
	 *
	 * @return {Boolean}
	 */
	this.onLastSlide = function () {
		return slider.getNumSlides() === slider.getPos() + 1;
	};

	/**
	 * Append parameters to next/previous entry URL
	 *
	 * @param {String} direction Can be 'next' or 'previous'
	 * @param {String} entryUrl URL of the next/previous entry
	 * @return {String}
	 */
	var entryUrl = function (direction, entryUrl) {
		// Start from the last slide when moving to the previous entry
		if (direction === 'previous') {
			entryUrl = url.setUrlParameter(entryUrl, 'slideshow-reverse', true);
		}

		return entryUrl;
	};

	/**
	 * Return URL of the previous entry
	 *
	 * @return {String}
	 */
	this.previousEntry = function () {
		var url = slideshow.data(data.previousEntry);

		return url ? entryUrl('previous', url) : '';
	};

	/**
	 * Return URL of the next entry
	 *
	 * @return {String}
	 */
	this.nextEntry = function () {
		var url = slideshow.data(data.nextEntry);

		return url ? entryUrl('next', url) : '';
	};

	/**
	 * Navigate to an URL
	 *
	 * @param {String} url
	 */
	this.navigate = function (url) {
		if ($.support.pjax) {
			$.pjax(
				$.extend(pjaxOptions, {
					url: url,
				})
			);
		} else {
			window.location.href = url;
		}
	};

	/**
	 * Open previous slide
	 */
	this.previousSlide = function () {
		var previousEntry = self.previousEntry();

		pauseVideoPlayback();

		if (self.onFirstSlide() && previousEntry) {
			self.navigate(previousEntry);
		} else {
			slider.prev();
			refreshNavigation();
		}
	};

	/**
	 * Open next slide
	 */
	this.nextSlide = function () {
		var nextEntry = self.nextEntry();

		pauseVideoPlayback();

		if (self.onLastSlide() && nextEntry) {
			self.navigate(nextEntry);
		} else {
			slider.next();
			refreshNavigation();
		}
	};

	/**
	 * Refresh navigation
	 */
	var refreshNavigation = function () {
		var nextButton = $(selectors.nav.next);
		var previousButton = $(selectors.nav.previous);

		nextButton.removeClass('hidden');
		previousButton.removeClass('hidden');

		if (self.onLastSlide() && !self.nextEntry()) {
			nextButton.addClass('hidden');
		}

		if (self.onFirstSlide() && !self.previousEntry()) {
			previousButton.addClass('hidden');
		}
	};

	/**
	 * Navigate to a slide
	 *
	 * @param {Number} index Index of the slide
	 */
	this.slide = function (index) {
		pauseVideoPlayback();
		slider.slide(index);
		refreshNavigation();
	};

	/**
	 * Setup navigation
	 */
	var setupNavigation = function () {
		var previousButton = slideshow.find(selectors.nav.previous);
		var nextButton = slideshow.find(selectors.nav.next);

		previousButton.on('click.slideshow', function (event) {
			event.preventDefault();
			self.previousSlide();
		});

		nextButton.on('click.slideshow', function (event) {
			event.preventDefault();
			self.nextSlide();
		});

		refreshNavigation();
	};

	/**
	 * Setup keyboard navigation
	 */
	var setupKeyboardNavigation = function () {
		if ($.support.pjax) {
			$('body').off('keydown.slideshow');
		}

		$('body').on('keydown.slideshow', function (event) {
			// Left arrow
			if (event.which === 37) {
				self.previousSlide();
			}

			// Right arrow
			if (event.which === 39) {
				self.nextSlide();
			}
		});
	};

	/**
	 * Setup contact sheet
	 */
	var setupContactSheet = function () {
		$(selectors.contactSheet)
			.find('a')
			.on('click.slideshow', function () {
				var id = $(this).data('navigate-to');

				if (id) {
					self.slide(underscore.indexOf(slides, id));
				}
			});
	};

	/**
	 * Setup full screen mode
	 */
	var setupFullscreenMode = function () {
		var button = slideshow.find(selectors.nav.fullscreen);
		var fullscreen = new FullScreen();

		fullscreen.setup(button);
	};

	/**
	 * Display a 'No slides found' message
	 */
	var showNotFoundMessage = function () {
		slideshow.find(selectors.contactSheet).html(
			notFoundTemplate({
				message: slideshow.data(data.noSlidesFound),
			})
		);
	};

	/**
	 * Update page title
	 */
	var pageTitleCallback = function () {
		var newTitle = $(document).find(selectors.pageTitle).first();

		if (newTitle) {
			document.title = newTitle.data(data.pageTitle);
		}
	};

	/**
	 * Setup pjax
	 */
	var setupPjax = function () {
		if (!$.support.pjax) {
			return;
		}

		$(document).on('pjax:end', pageTitleCallback);
	};

	/**
	 * Setup slideshow
	 *
	 * @param {String} container
	 */
	this.setup = function (container) {
		slideshow = $(container);

		if (!slideshow.length) {
			return;
		}

		trackSlides();

		setupVideo();
		setupSlider();
		setupNavigation();
		setupKeyboardNavigation();
		setupContactSheet();
		setupFullscreenMode();

		setupPjax();

		if (self.slideshowEmpty()) {
			showNotFoundMessage();
		}
	};
};
