import Vue from 'vue';

export default {
	setValidationErrors(state, errors) {
		Vue.set(state, 'validationErrors', { ...state.validationErrors, ...errors });
	},
	resetValidationError(state, key) {
		Vue.set(state, 'validationErrors', { ...state.validationErrors, ...{ [key]: [] } });
	},
	resetValidationErrors(state) {
		Vue.set(state, 'validationErrors', {});
	},
	setFormError(state, error) {
		Vue.set(state, 'formError', error);
	},
	resetFormError(state) {
		Vue.set(state, 'formError', null);
	},
	clearValidationError(state, key) {
		Vue.delete(state.validationErrors, key);
	},
};
