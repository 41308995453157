export default {
  methods: {
    pusherSubscribe(accountGlobalId) {
      this.pusher = window.pusher;
      this.accountChannel = this.pusher.subscribe(
        'vanity-domain-setup-' + accountGlobalId
      );

      this.accountChannel.bind('vanity.domain.ready', data => {
        window.location.href = data.accountUrl;
      });
    },
    pusherUnsubscribe() {
      this.pusher.disconnect();
      this.pusher = null;
      this.accountChannel = null;
    }
  }
};
