<template>
	<label :id="`${fieldId}-label`" :for="fieldId">
		<field-access-icon v-if="showFieldAccess" :field="field" :is-manager="isManager" />
		<!-- eslint-disable-next-line vue/no-v-html -->
		<span v-html="label"></span>
	</label>
</template>

<script>
import FieldAccessIcon from './FieldAccessIcon';

export default {
	inject: ['lang'],
	components: {
		FieldAccessIcon,
	},
	props: {
		field: {
			type: Object,
			required: true,
		},
		isManager: {
			type: Boolean,
			default: false,
		},
		for: {
			type: String,
			default: null,
		},
		elementId: {
			type: String,
			default: '',
		},
	},
	computed: {
		isOptional() {
			return (
				!this.field.required &&
				!['content', 'formula'].includes(this.field.type) &&
				((this.field.entrantReadAccess && this.field.entrantWriteAccess) || this.field.resource === 'Users')
			);
		},
		label() {
			if (!this.isOptional) {
				return this.field.labelMarkdown ? this.field.labelMarkdown : this.field.title;
			}

			const text = !this.field.labelMarkdown ? this.field.title : this.field.labelMarkdown;

			if (!text) {
				return null;
			}

			// Split the text by <br> or line breaks
			const parts = text.split(/(\r\n|\n|\r|<br>)/);

			// First meaningful line
			const firstLine = parts[0].trim();
			const optionalText = this.lang.get('miscellaneous.optional');

			// Keep the remaining text, convert remaining line breaks to <br>
			const remainingText =
				parts.length > 1
					? parts.slice(1).join('').replace(/\n/g, '<br>') // Convert \n to <br>
					: '';

			// Append optional text at the end of the first line and convert to HTML
			return `${firstLine}${optionalText}${remainingText}`;
		},
		fieldId() {
			return this.for ? this.for : this.field.slug + '-' + this.elementId;
		},
		showFieldAccess() {
			return this.field.resource !== 'Users';
		},
	},
};
</script>
