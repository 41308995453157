<template>
	<div class="marker-action">
		<list-action-form ref="form" :ids="ids" :labels="labels" :route="route" :method="method" @submitted="toggle">
			<input type="hidden" name="reviewerId" :value="reviewerId" />
			<input type="hidden" name="startNotificationId" :value="startNotificationId" />
			<portal :to="name">
				<modal
					v-model="showModal"
					:header="false"
					:confirm-button-label="labels.title"
					:close-button-label="labels.cancel"
					:header-close-button="true"
					:confirm-disabled="!formFilled"
					:confirm-on-enter="false"
					@closed="modalClosed"
					@confirmed="submit"
				>
					<close-icon slot="before-content" @click.prevent="toggle"></close-icon>
					<h4 class="modal-title">{{ labels.title }}</h4>
					<div class="form-group">
						<label for="reviewerId">{{ labels.reviewer }}</label>
						<select-dropdown
							:dropdown-options="formatReviewerLabels(programManagers)"
							input-name="reviewerId"
							:empty-value="true"
							:default-label="labels.reviewer_placeholder"
							:add-new-element="false"
							value-property="name"
							@selected="selectReviewer"
						>
						</select-dropdown>
					</div>
					<div class="form-group">
						<label for="startNotificationId">{{ labels.notification_to_send }}</label>
						<select-field
							id="startNotificationId"
							name="startNotificationId"
							:items="notifications"
							:value="defaultNotificationId"
							:empty-option="true"
							:required="false"
							@selected="selectStartNotification"
						/>
					</div>
				</modal>
			</portal>
		</list-action-form>
	</div>
</template>

<script>
import ListAction from './ListAction.vue';
import CloseIcon from './Partials/CloseIcon';
import { Typeahead, Modal, SelectField } from 'vue-bootstrap';
import modalMixin from './mixins/modal-mixin';
import SelectDropdown from '@/lib/components/Shared/SelectDropdown.vue';

/**
 * The reassign reviewer modal component.
 */
export default {
	name: 'ReassignReviewer',
	components: {
		CloseIcon,
		Typeahead,
		Modal,
		SelectField,
		SelectDropdown,
	},
	extends: ListAction,
	mixins: [modalMixin],
	props: {
		name: {
			type: String,
			required: true,
			validator: (value) => ['reassign-reviewer'].indexOf(value) !== -1,
		},
		programManagers: {
			type: Array,
			default: () => [],
		},
		notifications: {
			type: Array,
			default: () => [],
		},
		notificationId: {
			type: Number,
			default: null,
		},
	},
	computed: {
		formFilled() {
			return this.reviewerId;
		},
		defaultNotificationId() {
			return this.ids.length === 1 ? this.notificationId : null;
		},
	},
	data() {
		return {
			startNotificationId: null,
			reviewerId: null,
		};
	},
	methods: {
		selectStartNotification(name, value) {
			this.startNotificationId = value;
		},
		selectReviewer(name, value) {
			this.reviewerId = value.id;
		},
		formatReviewerLabels(programManagers) {
			return programManagers.map((programManager) => ({
				id: programManager.id,
				name: programManager.name + ' (' + programManager.email + ')',
			}));
		},
	},
};
</script>
