const isString = (value: unknown): value is string => typeof value === 'string' || value instanceof String;

const isNumber = (value: unknown): value is number => typeof value === 'number' && isFinite(value);

const isBoolean = (value: unknown): value is boolean => value === true || value === false;

const isPrimitive = <T = string | number | boolean>(value: unknown): value is T =>
	isString(value) || isNumber(value) || isBoolean(value);

const isObject = <T extends object>(value: unknown): value is T =>
	value !== null && typeof value === 'object' && !isArray(value);

const isArray = <T>(value: unknown): value is T[] => Array.isArray(value);

const isUndefined = (value: unknown): value is undefined => typeof value === undefined;

const isNull = (value: unknown): value is null => value === null;

const isNullOrUndefined = (value: unknown): value is null | undefined => isNull(value) || isUndefined(value);

const isFunction = <T extends object>(value: unknown): value is T => value !== null && typeof value === 'function';

export {
	isString,
	isNumber,
	isBoolean,
	isPrimitive,
	isObject,
	isArray,
	isUndefined,
	isNull,
	isNullOrUndefined,
	isFunction,
};
