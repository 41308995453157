<template>
  <div>
    <scoring-input :id="`scores-1`" v-model.number="value" v-on="$listeners"/>
  </div>
</template>

<script>
import ScoringInput from "../../../lib/components/Fields/ScoringInput";

export default {
  components: { ScoringInput },
  props: {
    score: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      value: this.score
    }
  }
}
</script>
