<template>
  <div class="alert-info sticky" role="alert">
    <div class="icon">
      <div class="af-icons-md af-icons-md-alert-info"></div>
    </div>
    <div class="message">
      <p v-html="lang.get('fields.form.formula.feature-disabled', { url: '/feature-disabled/formula_field' })"></p>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['lang']
};
</script>
