var Vue = require('vue');
var NewCustomExportLayout = require('./components/NewCustomExportLayout.vue').default;
var EditCustomExportLayout = require('./components/EditCustomExportLayout.vue').default;

module.exports = function() {
  new Vue({
    el: '#custom-export-layout',
    name: 'ExportApp',
    components: {
      'new-custom-export-layout': NewCustomExportLayout,
      'edit-custom-export-layout': EditCustomExportLayout
    }
  });
};
