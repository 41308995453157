<template>
  <validation-errors :attribute="id" :validation-errors="errors"/>
</template>

<script>
/**
 * this is a base component for instant validation errors indicators
 * it uses reactive errors data provided from wrapper
 */

import ValidationErrors from "@/lib/components/Fields/ValidationErrors";
import {reactiveInject, withReactivity} from "@/lib/utils";

export default {
  components: { ValidationErrors },
  inject: withReactivity(['id']),
  computed: {
    errors() {
      return reactiveInject(this, 'errors')
    }
  }
}
</script>
