<template>
  <div>
    <img v-if="src" :alt="ariaLabel" :aria-label="ariaLabel" :src="src" />
    <blank-img v-else />
  </div>
</template>

<script>
import BlankImg from './BlankImg';

export default {
  components: {
    BlankImg
  },
  props: {
    src: {
      type: String,
      default: null
    },
    ariaLabel: {
      type: String,
      default: 'Form cover'
    }
  }
};
</script>

<style scoped>
img {
  width: 100%;
  height: 100%;
}
</style>
