<template>
  <div v-if="shortcuts.length > 0">
    <div class="row row-relatives">
      <div v-if="isDashboard" class="col-xs-12">
        <div class="selector-title">
          <h2 class="title">{{ title }}</h2>
        </div>
      </div>
    </div>
    <div class="row shortcuts-row">
      <div class="col-xs-12">
        <div class="shortcuts-inner">
          <strong v-if="!isDashboard">{{ title }}&emsp;</strong>
          <span v-for="(savedView, index) in shortcuts" :key="savedView.id">
            <a
              v-if="displayShortcut(savedView)"
              :href="'/search/settings/' + savedView.slug"
            >
              {{ savedView.name }}
            </a>
            <span v-if="displayPipe(savedView, index)">&emsp;|&emsp;</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import langMixin from '@/lib/components/Translations/mixins/lang-mixin.js';

export default {
  mixins: [langMixin],
  props: {
    area: {
      type: String,
      default: ''
    },
    isDashboard: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Saved views'
    },
    savedViews: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('savedView', ['listViewShortcuts']),
    shortcuts() {
      return this.isDashboard ? this.savedViews : this.listViewShortcuts;
    }
  },
  created() {
    this.storeSavedViews(this.savedViews);
  },
  methods: {
    ...mapMutations('savedView', ['storeSavedViews']),
    displayShortcut(savedView) {
      return (
        (this.isDashboard && savedView.shortcuts.dashboard === true) ||
        (!this.isDashboard && savedView.shortcuts.listView === true)
      );
    },
    displayPipe(savedView, index) {
      return (
        this.displayShortcut(savedView) && index !== this.shortcuts.length - 1
      );
    }
  }
};
</script>

<style scoped>
.shortcuts-row {
  width: fit-content;
}

.shortcuts-inner {
  background: white;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
}

.shortcuts-inner a {
  text-decoration: none;
}

.shortcuts-inner a:hover {
  text-decoration: underline;
}

.shortcuts-bar-space-above {
  margin-top: 15px;
}
</style>
