<template>
  <div class="marker-action">
    <list-action-form
      ref="form"
      :ids="ids"
      :labels="labels"
      :route="route"
      :method="method"
      @submitted="confirmSubmit"
    >
    </list-action-form>
    <modal
      v-model="showModal"
      :title="labels.confirmModalTitle"
      :header="false"
      :confirm-button-label="labels.ok"
      :close-button-label="labels.cancel"
      :confirm-on-enter="false"
      @closed="showModal = false"
      @confirmed="submit"
    >
      <close-icon
        slot="before-content"
        @click.prevent="showModal = false"
      ></close-icon>
      {{ modalBody }}
    </modal>
  </div>
</template>

<script>
import ListAction from './ListAction.vue';
import CloseIcon from './Partials/CloseIcon';
import { Modal } from 'vue-bootstrap';

/**
 * The Archiver component.
 */
export default {
  components: {
    Modal,
    CloseIcon
  },
  extends: ListAction,
  data() {
    return {
      showModal: false
    };
  },
  computed: {
    modalBody() {
      const id =
        this.ids.length > 1 ? 'alerts-archive-items' : 'alerts-archive-item';
      const element = document.getElementById(id);
      return element ? element.textContent : '';
    }
  },
  methods: {
    confirmSubmit() {
      this.showModal = true;
    }
  }
};
</script>
