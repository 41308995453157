import $ from 'jquery';

export default function() {
  $('#lockScoring').change(function() {
    const checked = $(this).is(':checked');
    $('#visibleOnLeaderboard').attr('disabled', !checked);
    if (!checked) {
      $('#visibleOnLeaderboard').prop('checked', false);
    }
  });
}
