<template>
	<div>
		<div v-for="language in supportedLanguages" :key="language">
			<slot :name="`header-for-${language}`"></slot>
			<div
				:class="{
					'input-group': multiLanguage,
					error: hasMultilingualError(getName(language)),
				}"
			>
				<div v-if="multiLanguage" :id="getId(language)" class="input-group-addon">
					{{ language }}
				</div>
				<input
					v-if="mode === 'text'"
					type="text"
					:aria-required="required"
					:maxlength="maxLength"
					:class="['form-control', 'multilingual-' + property]"
					:aria-labelledby="getLabelledBy(language)"
					:name="getName(language)"
					:value="getValue(language)"
					:disabled="disabled"
					@input="(e) => onInput(language, property, e.target.value)"
					@keyup="onKeyUp"
					@focus="(element) => onFocus(element)"
				/>
				<textarea
					v-if="mode === 'textarea'"
					:aria-required="required"
					rows="3"
					:class="['form-control', 'multilingual-' + property]"
					:aria-labelledby="getLabelledBy(language)"
					:name="getName(language)"
					:value="getValue(language)"
					:disabled="disabled"
					@input="(e) => onInput(language, property, e.target.value)"
					@keyup="onKeyUp"
					@focus="(element) => onFocus(element)"
				></textarea>
				<text-editor
					v-if="mode === 'markdown'"
					:id="`multilingual-${language}-${property}`"
					:aria-labelledby="getLabelledBy(language)"
					:class="'multilingual-' + property"
					:input-class="inputClass"
					:mode="markdownMode"
					:spell-checker="language === 'en_GB'"
					:name="getName(language)"
					:value="getValue(language)"
					:markdown-guide-label="lang.get('miscellaneous.markdown_guide.label')"
					:disabled="disabled"
					:toolbar-media-embed="toolbarMediaEmbed"
					@input="(value) => onInput(language, property, value)"
					@keyup="onKeyUp"
					@focus="(element) => onFocus(element)"
				/>
				<slot :name="`for-${language}`"></slot>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import { multilingualController } from '@/lib/components/Translations/Multilingual.controller';
import { props } from '@/lib/components/Translations/multilingualProps';
import TextEditor from '@/lib/components/Shared/editor/TextEditor.vue';

export default defineComponent({
	components: {
		TextEditor,
	},

	props: props,

	setup(props, { emit }) {
		return multilingualController(props, emit);
	},
});
</script>
