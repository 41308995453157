var Vue = require('vue');
var SettingDomain = require('./SettingDomain.vue').default;

module.exports = function() {
    new Vue({
        el: '#setting-domain',
        name: 'SettingDomainApp',
        components: { 'setting-domain': SettingDomain },
    });
};
