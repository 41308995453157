<script>
// Smooth height transition - based on:
// https://markus.oberlehner.net/blog/transition-to-height-auto-with-vue/

export default {
  functional: true,
  render(createElement, context) {
    const data = {
      props: {
        name: 'collapse'
      },
      on: {
        enter(element) {
          element.style.position = 'absolute';
          element.style.visibility = 'hidden';
          element.style.height = 'auto';

          const { height } = getComputedStyle(element);

          element.style.position = 'static';
          element.style.visibility = 'visible';
          element.style.height = 0;

          requestAnimationFrame(() => {
            element.style.height = height;
          });
        },
        afterEnter(element) {
          element.style.height = 'auto';
        },
        leave(element) {
          const { height } = getComputedStyle(element);

          element.style.height = height;

          requestAnimationFrame(() => {
            element.style.height = 0;
          });
        }
      }
    };

    return createElement('transition', data, context.children);
  }
};
</script>

<style scoped>
* {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>

<style>
.collapse-enter-active,
.collapse-leave-active {
  transition: height 0.2s ease-in-out;
  overflow: hidden;
}

.collapse-enter,
.collapse-leave-to {
  height: 0;
}
</style>
