/**
 * A 'min_file_size' filter for Plupload.
 *
 * How to use:
 * plupload.addFileFilter('min_file_size', minFileSize);
 */
export default function(minSize, file, cb) {
  if (typeof(file.size) !== 'undefined' && minSize && file.size < minSize) {
    this.trigger('Error', {
      code: -603,
      file: file
    });
    cb(false);
  } else {
    cb(true);
  }
};
