var $ = require('jquery');
var jplayer = require('../vendor/jplayer/jquery.jplayer.js');
var tectoastr = require('tectoastr');

/**
 * Audio player - setup jPlayer for mp3 playback.
 *
 * http://jplayer.org/
 *
 * Usage:
 *   var audio = new Audio;
 *   audio.setup(elements);
 *
 */
module.exports = function () {
    // Player element
    var player;

    // Container element holding ui
    var container;

    // Is audio playing?
    var playing = false;

    // Enable error/warning reporting through alerts
    var debug = false;

    // Path to swf file (flash fallback)
    var swfPath = '/vendor/jplayer';

    /**
     * Attach launcher
     *
     * element -> element holding player
     */
    var attachLauncher = function(element) {
        var button = $(element).next('.jp-audio-stream').find('.jp-play');

        button.on('click', function(event) {
            event.preventDefault();

            // player is already launched, nothing to do
            if ($(element).data('id') == $(player).data('id')) {
                return;
            }

            if (playing) {
                disableTimer();
                $(player).jPlayer('destroy').off();
            }

            initJPlayer(element);
            enableErrorHandling();
            enableTimer();

            playing = true;
        });
    };

    /**
     * Init jPlayer
     *
     * element -> element holding player
     */
    var initJPlayer = function(element) {
        player = element;
        container = '#jp-container-' + $(element).data('id');

        $(element).jPlayer({
            ready: function() {
                playMedia();
            },
            swfPath: swfPath,
            cssSelectorAncestor: container,
            supplied: $(player).data('format'),
            wmode: 'window',
            useStateClassSkin: true,
            smoothPlayBar: true,
            errorAlerts: debug,
            warningAlerts: debug
        });
    };

    /**
     * Play media
     */
    var playMedia = function () {
        var media = {};

        media[$(player).data('format')] = $(player).data('url');
        $(player).jPlayer('setMedia', media).jPlayer('play');
    }

    /**
     * Enable timer
     */
    var enableTimer = function() {
        $(player).on($.jPlayer.event.play, function () {
            $(container).find('.jp-timer').removeClass('hidden');
        });

        $(player).on($.jPlayer.event.pause, function () {
            $(container).find('.jp-timer').removeClass('hidden');
        });

        $(player).on($.jPlayer.event.ended, function () {
            $(container).find('.jp-timer').addClass('hidden');
        });
    }

    /**
     * Disable timer
     */
    var disableTimer = function() {
        $(container).find('.jp-timer').addClass('hidden');
    }

    /**
     * Enable error handling
     */
    var enableErrorHandling = function() {
        $(player).on($.jPlayer.event.error, function(event) {
            tectoastr.error(event.jPlayer.error.message);
        });
    }

    /**
     * Clicking a preview will control (play/pause) audio
     */
    var controlAudio = function(preview) {
        $(preview).on('click', function(event) {
            event.preventDefault();
            $(this).next('.jp-jplayer').next('.jp-audio-stream').find('.jp-play').trigger('click');
        });
    }

    /**
     * Setup audio
     *
     * elements -> elements holding player
     */
    this.setup = function (elements) {
        elements = $('.'+elements);

        elements.each(function() {
            attachLauncher(this);
        });

        controlAudio('.preview-container.audio');
    };
}
