<template>
	<div>
		<!-- Use regular check box list if option <= 20 -->
		<div v-if="displayCheckboxList" class="form-group">
			<div v-for="(option, index) in field.options" :key="optionId(option, index)" class="checkbox styled">
				<input
					:id="optionId(option, index)"
					v-model="selectedOptions"
					type="checkbox"
					:value="option.id"
					:disabled="disabled"
					:aria-required="field.required ? 'true' : 'false'"
					:aria-invalid="hasError"
					:aria-describedby="ariaDescribedby"
					@change="onChange(selectedOptions)"
				/>
				<label :for="optionId(option, index)">
					{{ option.text }}
				</label>
			</div>
		</div>
		<!-- Use multiselect component if option > 20 -->
		<multiselect
			v-else
			:options="field.options"
			:placeholder="lang.get('fields.form.multiselect.placeholder')"
			:selected-options="selectedOptions"
			:value-property="'text'"
			:filter="true"
			:counter="true"
			:select-all="true"
			:select-all-label="lang.get('fields.form.multiselect.select_all')"
			:subset="false"
			:randomizer="false"
			:disabled="disabled"
			@selected="onChange"
		>
		</multiselect>
	</div>
</template>

<script>
import Field from './Field.vue';
import { Multiselect } from 'vue-bootstrap';

export default {
	inject: ['lang'],
	components: {
		Multiselect,
	},
	extends: Field,
	data() {
		return {
			selectedOptions: this.value || [],
		};
	},
	computed: {
		displayCheckboxList() {
			return this.field.options.length <= 20;
		},
	},
	methods: {
		optionId(option, index) {
			return `option-${this.field.slug}-${this._uid}-${index}`;
		},
		onChange(options) {
			this.onInput(options.length ? options : null);
		},
	},
};
</script>
