<template>
  <div
    v-if="hasErrors"
    class="alert-error sticky island"
    tabindex="-1"
    role="alert"
  >
    <div class="icon">
      <div class="af-icons-md af-icons-md-alert-error"></div>
    </div>
    <div class="message">
      <ul class="validation-errors">
        <template v-for="(errors, key) in validationErrors">
          <li v-if="typeof errors === 'string'" :key="key">
            {{ errors }}
          </li>
          <li v-for="(error, index) in errors" v-else :key="key + index">
            {{ error }}
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('validation', ['validationErrors']),
    hasErrors() {
      return Object.keys(this.validationErrors).length;
    }
  }
};
</script>
