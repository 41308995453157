
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import { ckeditorConfig } from '@/lib/components/Shared/editor/ckeditor/cke-config';
import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { EditorEmit } from '@/lib/components/Shared/editor/EditorEvents';
import { onPastedInput } from '@/lib/components/Shared/editor/ckeditor/on-pasted-input';
import { prepareHtmlForEditorContent } from '@/lib/components/Shared/editor/ckeditor/editor-content-preparser';
import { getTrans } from '@/services/global/translations.interface';
import { useEditorProps } from '@/lib/components/Shared/editor/editorProps';
import { uuid } from '@/lib/uuid.js';
import { setLastActiveEditor } from '@/lib/components/Shared/MergeFields/services/lastActiveEditor';
import { customToolbar } from '@/lib/components/Shared/editor/ckeditor/toolbar/buttons';
import { Editor } from '@/lib/components/Shared/editor/TextEditor.types';
import { useLanguageProps } from '@/lib/components/Shared/editor/languageProps';

export default defineComponent({
	props: {
		...useEditorProps,
		...useLanguageProps,
	},

	setup(props, { emit }: { emit: EditorEmit }) {
		const editorUniqueId = 'ckeditor-' + uuid();
		let currentHtml = ref(prepareHtmlForEditorContent(props.value));
		let editor = ref(null);
		const textarea = ref(null);

		const ckeditorInitialized = (newEditor) => {
			editor.value = newEditor;
			setContent(currentHtml.value);

			if (props.disabled) {
				editor.value.enableReadOnlyMode(editorUniqueId);
			}

			editor.value.model.document.on('change:data', onInput);
			editor.value.ui.focusTracker.on('change:isFocused', (evt, name, isFocused) => onFocus(isFocused));
			editor.value.plugins
				.get('ClipboardPipeline')
				.on('inputTransformation', (e, data) => onPastedInput(e, data, editor.value));
		};

		const useEditorContent = (editorReference: Editor) => ({
			getContent() {
				let html = editorReference.value.getData();

				// Fix CKE losing html tags (like "<div>"), prepend it with invisible space
				const zeroWidthSpace = '⁠';
				html = html.replace(/&lt;(\S)/g, '&lt;' + zeroWidthSpace + '$1');

				return html;
			},

			setContent(html: null | string) {
				return editorReference.value ? editorReference.value.setData(html) : null;
			},
		});

		const { getContent, setContent } = useEditorContent(editor);

		watch(
			() => props.value,
			(newValue) => {
				if (newValue !== getContent()) {
					setContent(newValue || '');
				}
			}
		);

		const onFocus = (isFocused) => {
			if (isFocused) {
				setLastActiveEditor(editor.value);
				emit('focus', { editor: editor.value });
			} else {
				onBlur();
			}
		};

		const onInput = () => {
			currentHtml.value = getContent();

			// emit native keyup event
			const event = new Event('keydown');
			textarea.value.dispatchEvent(event);

			emit('input', currentHtml.value);
		};

		const onBlur = () => {
			// emit native blur event
			const event = new Event('blur');
			textarea.value.dispatchEvent(event);

			emit('blur');
		};

		onBeforeUnmount(() => {
			if (editor.value) {
				editor.value.destroy();
			}
		});

		onMounted(() => {
			const toolbarConfig = customToolbar({ toolbarMediaEmbed: props.toolbarMediaEmbed });

			ClassicEditor.create(document.querySelector('#' + editorUniqueId), ckeditorConfig({ toolbar: toolbarConfig }))
				.then((editor) => ckeditorInitialized(editor))
				.catch((error) => console.error(error.stack));
		});

		return {
			editor,
			currentHtml,
			editorUniqueId,
			textarea,
			lang: getTrans(),
		};
	},
});
