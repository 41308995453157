<template>
  <input
    :id="getId()"
    :value="value"
    class="form-control"
    type="email"
    :disabled="disabled"
    :aria-required="field.required ? 'true' : 'false'"
    :aria-invalid="hasError"
    :aria-describedby="ariaDescribedby"
    @input="onInput($event.target.value)"
    @blur="validate"
  />
</template>

<script>
import Field from './Field.vue';

export default {
  extends: Field,
};
</script>
