import CalculationMethods from '@/modules/fields/CalculationMethods.js';
const { mapMutations, mapGetters } = require('vuex');

export default {
  props: {
    column: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapMutations(['updateCalculations']),
    getCalculatedValue(values) {
      return CalculationMethods.hasOwnProperty(this.columnCalculationFunction)
        ? CalculationMethods[this.columnCalculationFunction](values)
        : CalculationMethods[null](values);
    }
  },
  computed: {
    ...mapGetters(['getCalculations']),
    calculations: {
      get() {
        return this.getCalculations(this.column) || {};
      },
      set(value) {
        this.updateCalculations({ column: this.column, ...value });
      }
    },
    columnDisplayCalculation() {
      return this.calculations.enable || false;
    },
    columnCalculationFunction() {
      return this.calculations.calculation || null;
    }
  }
};
