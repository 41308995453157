<template>
  <div class="random-assignments-summary">
    {{ summaryString() }}
  </div>
</template>

<script>
export default {
  props: {
    selectedEntries: {
      type: Array,
      required: true
    },
    selectedJudges: {
      type: Array,
      required: true
    },
    selectedContextMode: {
      type: String,
      default: null
    },
    howMany: {
      type: String,
      default: null
    },
    atMostTrans: {
      type: String,
      required: true
    },
    assignmentTrans: {
      type: String,
      required: true
    },
    assignmentsTrans: {
      type: String,
      required: true
    },
    entryTrans: {
      type: String,
      required: true
    },
    entriesTrans: {
      type: String,
      required: true
    },
    judgeTrans: {
      type: String,
      required: true
    },
    judgesTrans: {
      type: String,
      required: true
    }
  },
  methods: {
    isContextEntriesToJudges() {
      return this.selectedContextMode === 'entries_to_judges';
    },
    entriesString() {
      let numOfEntries = this.selectedEntries.length;
      if (this.isContextEntriesToJudges()) {
        numOfEntries = Math.min(this.howMany, numOfEntries);
      }
      return (
        numOfEntries +
        ' ' +
        (numOfEntries === 1 ? this.entryTrans : this.entriesTrans)
      );
    },
    judgesString() {
      let numOfJudges = this.selectedJudges.length;
      if (!this.isContextEntriesToJudges()) {
        numOfJudges = Math.min(this.howMany, numOfJudges);
      }
      return (
        numOfJudges +
        ' ' +
        (numOfJudges === 1 ? this.judgeTrans : this.judgesTrans)
      );
    },
    assignmentsString() {
      const howMany = Math.min(
        this.howMany,
        this.selectedContextMode === 'entries_to_judges'
          ? this.selectedEntries.length
          : this.selectedJudges.length
      );
      const total =
        (this.isContextEntriesToJudges()
          ? this.selectedJudges.length
          : this.selectedEntries.length) * howMany;
      return (
        this.atMostTrans +
        ' ' +
        total +
        ' ' +
        (total === 1 ? this.assignmentTrans : this.assignmentsTrans)
      );
    },
    summaryString() {
      if (!this.selectedJudges.length && !this.selectedEntries.length) {
        return '';
      }

      return (
        this.judgesString() +
        ' x ' +
        this.entriesString() +
        ' = ' +
        this.assignmentsString()
      );
    }
  }
};
</script>
