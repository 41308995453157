<template>
	<div class="marker-action">
		<list-action-form ref="form" :ids="ids" :labels="labels" :route="route" :method="method" @submitted="toggle">
			<input type="hidden" name="source" value="manage-entries" />
			<input type="hidden" name="scoreSetId" :value="scoreSet" />
			<input type="hidden" name="roundId" :value="round" />
			<input type="hidden" name="roleSearchKeywords" :value="roleKeywords" />
			<input v-if="hasRoleFilter" type="hidden" name="roleId" :value="judgeFilters.role" />
			<template v-for="(entryId, index) in ids">
				<input :key="entryId + '_' + index" type="hidden" name="entries[]" :value="entryId" />
			</template>
			<template v-for="(judgesId, index) in selectedJudges">
				<input :key="judgesId + '_' + index" type="hidden" name="judges[]" :value="judgesId" />
			</template>
			<input type="hidden" name="sendNotifications" :value="enableNotifications ? 1 : 0" />
			<input type="hidden" name="notificationId" :value="notification" />
			<portal :to="name">
				<modal
					v-model="showModal"
					:header="false"
					:confirm-button-label="labels.confirm"
					:close-button-label="labels.cancel"
					:header-close-button="true"
					:confirm-disabled="!formFilled"
					:confirm-on-enter="false"
					@closed="modalClosed"
					@confirmed="submit"
				>
					<close-icon slot="before-content" @click.prevent="toggle"></close-icon>
					<h4 class="modal-title">{{ labels.title }}</h4>
					<div class="form-group">
						<label for="scoreSetId">{{ labels.scoreSet }}</label>
						<select-field
							id="scoreSetId"
							name="scoreSetId"
							:value="scoreSetId"
							:items="scoreSets"
							:required="true"
							@selected="selectScoreSet"
						/>
					</div>
					<div class="form-group">
						<label for="roundId">{{ labels.round }}</label>
						<select-field
							id="roundId"
							name="roundId"
							:value="roundId"
							:items="rounds"
							:required="true"
							@selected="selectRound"
						/>
					</div>
					<div class="form-group">
						<search-field
							id="judge-search"
							name="judge-search"
							:search-keywords.sync="roleKeywords"
							:trigger-search.sync="researchJudges"
							:filters="judgeFilters"
							:label="labels.judgeSearch"
							:search-label="labels.search"
							:reset-label="labels.reset"
							url="/assignment/judges"
							:on-search="judgeSearchResults"
							:show-filters="!!roleId"
							:advanced-search="true"
							:advanced-search-label="labels.advancedSearch"
							@reset="resetJudges"
						>
							<div class="form-group form-group-subordinate">
								<label for="role">{{ labels.role }}</label>
								<select-field
									id="role"
									name="role"
									:value="roleId"
									:items="roles"
									:empty-option="true"
									:required="true"
									@selected="selectJudgeFilter"
								/>
							</div>
						</search-field>
					</div>
					<div class="form-group">
						<multiselect
							id="multiselect-judges"
							:filter="false"
							name="judges[]"
							:select-all-label="labels.selectAll"
							value-property="name"
							value-popover-placement="right"
							value-popover-property="title"
							:options="judgesList"
							:selected-options="selectedJudgesSource"
							:randomizer="false"
							:remember-selection="true"
							@selected="selectJudges"
						>
							<template slot-scope="{item}">
								<assignments-judge v-if="item" :item="item" />
							</template>
						</multiselect>
						<p v-if="judgesList.length >= 1000" class="help-text">{{ labels.judgesCapped }}</p>
					</div>
					<div class="form-group">
						<checkbox
							name="sendNotifications"
							:selected="sendNotifications"
							:value="enableNotifications"
							:aria-label="labels.sendAssignmentNotification"
							@change="toggleEnableNotification"
						>
							<template slot="label">{{ labels.sendAssignmentNotification }}</template>
						</checkbox>
					</div>
					<div v-if="enableNotifications" class="form-group dependent">
						<select-field
							id="notificationId"
							name="notificationId"
							:value="notificationId"
							:items="notifications"
							value-property="subject"
							:required="true"
							@selected="selectNotification"
						/>
					</div>
				</modal>
			</portal>
		</list-action-form>
	</div>
</template>

<script>
import { Modal, SelectField, Multiselect, Checkbox } from 'vue-bootstrap';
import SearchField from '@/lib/components/Judging/SearchField';
import AssignmentsJudge from '@/modules/assignments/components/AssignmentsJudge';
import modalMixin from '@/lib/components/ListActions/mixins/modal-mixin';
import CloseIcon from '@/lib/components/ListActions/Partials/CloseIcon';
import ListAction from '@/lib/components/ListActions/ListAction';

export default {
	name: 'AssignJudges',
	components: {
		CloseIcon,
		Modal,
		SelectField,
		SearchField,
		Multiselect,
		AssignmentsJudge,
		Checkbox,
	},
	extends: ListAction,
	mixins: [modalMixin],
	props: {
		scoreSets: {
			type: Array,
			required: true,
		},
		scoreSetId: {
			type: Number,
			default: null,
		},
		rounds: {
			type: Array,
			required: true,
		},
		roundId: {
			type: Number,
			default: null,
		},
		roles: {
			type: Array,
			required: true,
		},
		roleId: {
			type: Number,
			default: null,
		},
		roleSearchKeywords: {
			type: String,
			default: '',
		},
		judges: {
			type: Array,
			required: true,
		},
		sendNotifications: {
			type: Boolean,
			default: true,
		},
		notifications: {
			type: Array,
			required: true,
		},
		notificationId: {
			type: Number,
			default: null,
		},
		name: {
			type: String,
			required: true,
			validator: (value) => ['assign-judges'].indexOf(value) !== -1,
		},
	},
	data() {
		return {
			scoreSet: null,
			round: null,
			roleKeywords: this.roleSearchKeywords,
			researchJudges: false,
			judgesList: [],
			judgeFilters: {},
			selectedJudges: this.judges,
			enableNotifications: this.sendNotifications,
			notification: null,
			showOnlySelectedJudges: false,
			switchSelectedJudgesSource: false,
			doneInitialSearchResult: false,
		};
	},
	computed: {
		formFilled() {
			return (
				this.scoreSet &&
				this.round &&
				this.selectedJudges.length >= 1 &&
				(!this.enableNotifications || (this.enableNotifications && this.notification))
			);
		},
		hasRoleFilter() {
			return this.judgeFilters.hasOwnProperty('role') && this.judgeFilters?.role;
		},
		selectedJudgesSource() {
			return this.switchSelectedJudgesSource ? this.selectedJudges : this.judges;
		},
	},
	watch: {
		showModal(newVal) {
			this.showOnlySelectedJudges = true;
			this.researchJudges = newVal;
		},
	},
	methods: {
		selectScoreSet(name, value) {
			this.scoreSet = value;
		},
		selectRound(name, value) {
			this.round = value;
		},
		selectJudgeFilter(name, value) {
			this.$set(this.judgeFilters, name, value);
		},
		judgeSearchResults(data) {
			if (this.doneInitialSearchResult === true) {
				this.switchSelectedJudgesSource = true;
			}

			var filteredData = data;

			if (
				this.roleKeywords?.trim().length === 0 &&
				this.selectedJudgesSource.length > 0 &&
				this.showOnlySelectedJudges
			) {
				filteredData = filteredData.filter((judge) => this.selectedJudgesSource.includes(judge.id));
			}

			this.showOnlySelectedJudges = false;
			this.judgesList = filteredData;
			this.doneInitialSearchResult = true;
		},
		selectJudges(selected) {
			this.selectedJudges = selected;
		},
		toggleEnableNotification(value) {
			this.enableNotifications = value;
		},
		selectNotification(name, value) {
			this.notification = value;
		},
		resetJudges() {
			this.judgesList = [];
			this.judgeFilters = {};
		},
	},
};
</script>
