<template>
  <div :class="['moderation-status', status, { active: value === status }]">
    <div class="radio styled">
      <input
        :id="id"
        :name="name"
        type="radio"
        :value="status"
        :checked="value === status"
        @change="$emit('input', status)"
      />
      <label :for="id" v-html="label"></label>
    </div>
  </div>
</template>

<script>
/**
 * The ModerationStatus component.
 */
export default {
  props: {
    value: {
      type: String,
      default: null
    },
    status: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    id() {
      return 'moderation-' + this.status + '-' + this._uid;
    }
  }
};
</script>
