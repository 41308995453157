<template>
  <div>
    <slot v-bind="{ setErrors, hasErrors }" />
  </div>
</template>

<script>
/**
 * this component is intended to wrap up an inline-template
 * with fields with their own validators
 * wrapped components should use setErrors method to populate
 * validation errors here then provided to descendants
 * i.e. <instant-errors/>
 */

import langMixin from '@/lib/components/Translations/mixins/lang-mixin';
import InstantErrors from '@/lib/components/Fields/validator/InstantErrors';
import { reactiveInjects } from '@/lib/utils';

export default {
  components: {
    InstantErrors
  },
  mixins: [langMixin],
  props: {
    fieldId: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: 'field'
    }
  },
  data() {
    return {
      instantErrors: [],
      domElement: null,
      allRules: []
    };
  },
  computed: {
    hasErrors() {
      return this.instantErrors.length > 0;
    }
  },
  methods: {
    setErrors(errors) {
      this.instantErrors = errors.map(error =>
        this.allRules.indexOf(error) !== -1
          ? this.lang.get('validation.' + error, { attribute: this.label.toLowerCase() })
          : error
      );

      if (this.domElement) {
        const parent = this.domElement.parentNode.closest('div.form-group');
        if (parent) {
          parent.closest('div.form-group').classList[this.hasErrors ? 'add' : 'remove']('error');
        }
      }
    }
  },
  provide() {
    return {
      id: `fieldErrors{${this.fieldId}}`,
      ...reactiveInjects({ errors: () => this.instantErrors })
    };
  }
};
</script>
