<template>
  <dropdown
    ref="dropdown"
    button-element="input"
    button-class="form-control"
    menu-class-base="select-dropdown ptn"
    :input-value="selected.id"
    :input-name="inputName"
    :enable-inline-create="addNewElement"
    :inline-create-label="addElementLabel"
    @openInlineCreateModal="inlineCreate"
    @buttonFocus="focus"
  >
    <template slot="button">
      <div class="input-wrapper">
        <input
          v-model="label"
          class="form-control"
          :class="{ 'search-button': isOpen }"
          :placeholder="defaultLabel"
          @focus="focus"
        />
        <span class="caret"></span>
      </div>
    </template>
    <li class="input-wrapper">
      <input
        ref="search"
        v-model="searchString"
        :aria-label="searchLabel"
        class="form-control search-input"
        @focus="isOpen = true"
        @blur="isOpen = false"
      />
      <span class="af-icons af-icons-search"></span>
    </li>
    <li v-for="option in options" :key="option.id">
      <a @click.prevent="handleClick(option)">
        {{ option[valueProperty] }}
        <span v-if="option.id === 0" class="sr-only">{{ deselectLabel }}</span>
      </a>
    </li>
  </dropdown>
</template>
<script>
import {Dropdown} from 'vue-bootstrap';

export default {
  name: 'SelectDropdown',
  components: {Dropdown},
  props: {
    dropdownOptions: {
      type: Array,
      default: () => []
    },
    inputName: {
      type: String,
      default: 'dropdownSelect'
    },
    defaultLabel: {
      type: String,
      default: 'Select One'
    },
    selectedId: {
      type: Number,
      default: 0
    },
    addNewElement: {
      type: Boolean,
      default: false
    },
    emptyValue: {
      type: Boolean,
      default: false
    },
    addElementLabel: {
      type: String,
      default: 'Add'
    },
    valueProperty: {
      type: String,
      default: 'name'
    },
    searchLabel: {
      type: String,
      default: 'Search'
    },
    deselectLabel: {
      type: String,
      default: 'Deselect'
    }
  },
  data() {
    return {
      selected: {},
      searchString: '',
      isOpen: false
    };
  },
  computed: {
    label() {
      return this.selected[this.valueProperty] ?? '';
    },
    lcSearchString() {
      return this.searchString.toLowerCase();
    },
    nonEmptyOptions() {
      if (this.searchString === this.selected[this.valueProperty]) {
        return this.dropdownOptions;
      }

      return this.dropdownOptions.filter(el => el[this.valueProperty].toLowerCase().includes(this.lcSearchString));
    },
    options() {
      return this.emptyValue
        ? [{id: 0, [this.valueProperty]: ''}].concat(this.nonEmptyOptions)
        : this.nonEmptyOptions;
    }
  },
  mounted() {
    this.setCurrentOption();
  },
  methods: {
    setCurrentOption() {
      const currentOption = this.dropdownOptions.find(n => n.id === this.selectedId);
      if (currentOption !== undefined) {
        this.selected = currentOption;
        this.searchString = currentOption[this.valueProperty];
      }
    },
    handleClick(selected) {
      this.selected = selected;
			this.$emit('selected', this.inputName, this.selected);
      this.close();
    },
    inlineCreate() {
      console.log('time to open modal');
    },
    focus() {
      this.open();
      this.$nextTick(() => {
        this.$refs.search.focus();
      });
    },
    close() {
      this.$refs.dropdown.close();
    },
    open() {
      this.searchString = '';
      this.$refs.dropdown.open();
    }
  }
};
</script>

<style scoped>
.select-dropdown-btn {
  height: 100%;
}

.caret,
.af-icons-search {
  position: absolute;
  right: 10px;
}

.input-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.input-wrapper input {
  padding-right: 25px;
  text-overflow: ellipsis;
}

.form-control.search-input {
  border-radius: unset;
  border: unset;
  border-bottom: 1px solid #6f6f6f;
  box-shadow: unset;
}

.search-button {
  border-radius: 4px 4px 0 0;
  border-bottom: 0;
}
</style>
