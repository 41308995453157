<script>
import ResourceList from '@/lib/components/ResourceList.vue';
import Deletism from '@/lib/components/ListActions/Deletism.vue';
import Undeletism from '@/lib/components/ListActions/Undeletism.vue';
import ListAction from '@/lib/components/ListActions/ListAction.vue';
import ReassignReviewer from '@/lib/components/ListActions/ReassignReviewer';
import ReviewTaskResetAction from '@/lib/components/ListActions/ReviewTaskResetAction';

export default {
	components: {
		Deletism,
		Undeletism,
		ListAction,
		ReassignReviewer,
		ReviewTaskResetAction,
	},
	extends: ResourceList,
	computed: {
		reassignReviewerRevealed() {
			return this.reveal === 'reassign-reviewer';
		},
	},
};
</script>
