/* eslint-disable @typescript-eslint/naming-convention */
var Vue = require('vue');
var Audio = require('../../lib/audio.js');
var Cards = require('../../lib/cards.js');
var VideoPlayer = require('@/domain/services/VideoPlayer/VideoPlayer').default;
var VideoModal = require('@/domain/services/VideoPlayer/VideoPlayerModal').default;
var VideoThumbnails = require('../../lib/video-thumbnails.js');
var QuickManager = require('../../lib/components/QuickManager/QuickManager.vue').default;
/* eslint-enable @typescript-eslint/naming-convention */

module.exports = function () {
	/**
	 * Cards
	 */
	var cards = new Cards();
	cards.setup('ul.cards:not(.vertical-gallery)', 'li', 'fitRows');

	/**
	 * Audio
	 */
	var audio = new Audio();
	audio.setup('jp-jplayer');

	/**
	 * Video
	 */
	var videoThumbnails = new VideoThumbnails();

	VideoPlayer.setup('video-js-standalone');
	VideoModal.setup('play-video');
	videoThumbnails.setup('preview-container.video');

	/**
	 * Quick Manager
	 */
	const quickManager = document.getElementById('quickManager');

	if (quickManager) {
		new Vue({
			el: quickManager,
			name: 'QuickManagerApp',
			components: {
				'quick-manager': QuickManager,
			},
		});
	}
};
