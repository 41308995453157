<template>
  <widget class="widget-form widget-pd0" :no-margin="true">
    <template slot="cover">
      <a v-if="coverImageUrl" class="cover-image" :href="href" :aria-label="formName" style="outline: 0">
        <cover :src="coverImageUrl" class="cover" :aria-label="`${formName} cover image`"/>
      </a>
      <div v-else class="cover-bar no-cover-bar"/>
    </template>
    <template slot="heading">
      <span class="form-title">{{ formName }}</span>
    </template>
    <div class="form-body">
      <a class="btn btn-primary btn-sm form-button" :href="href">{{ callToAction }}</a>
    </div>
  </widget>
</template>

<script>
import Widget from "@/lib/components/Shared/Widget";
import Cover from "@/lib/components/Shared/Cover";

export default {
  name: "FormWidget",
  components: {
    Widget,
    Cover
  },
  props: {
    formName: {
      type: String,
      default: 'Form name'
    },
    callToAction: {
      type: String,
      required: true
    },
    coverImageUrl: {
      type: String,
      default: ''
    },
    href: {
      type: String,
      default: '#'
    }
  },
}
</script>

<style scoped>
.form-title {
  padding: 20px;
  display: block;
  font-size: 16px;
  line-height: 21px;
}

.form-body {
  padding: 0 20px 20px 20px;
  display: flex;
  justify-content: flex-start;
}

.form-button {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
