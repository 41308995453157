<template>
  <div class="quick-approver clearfix">
    <moderation-status
      v-for="status in moderationStatuses"
      :key="status"
      v-model="currentStatus"
      :status="status"
      :label="labels[status]"
      :name="name"
    >
    </moderation-status>
  </div>
</template>

<script>
import ModerationStatus from './ModerationStatus.vue';

const toastr = require('toastr');

/**
 * The QuickApprover component.
 */
export default {
  components: {
    ModerationStatus
  },
  props: {
    url: {
      type: String,
      required: true
    },
    moderationStatuses: {
      type: Array,
      required: true
    },
    moderationStatus: {
      type: String,
      required: true
    },
    labels: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      currentStatus: this.moderationStatus
    };
  },
  watch: {
    currentStatus() {
      this.$http
        .post(this.url, {
          moderationStatus: this.currentStatus
        })
        .then(
          () => {},
          error => {
            toastr.error(
              error.response.data.message ||
                document.getElementById('alerts-generic').innerHTML
            );
          }
        );
    }
  }
};
</script>
