import { MultilingualProps } from '@/lib/components/Translations/multilingualProps';

const useMultilingual = (props: MultilingualProps) => {
	const getId = (language: string): string => `${language}-ml-${props.labelledBy}`;
	const getLabelledBy = (language: string): string => `${props.labelledBy} ${getId(language)}`;
	const getName = (language: string): string => `translated[${props.property}][${language}]`;

	return { getId, getLabelledBy, getName };
};

export { useMultilingual };
