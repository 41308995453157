import $ from 'jquery';
import _ from 'underscore';

/* eslint-disable eqeqeq */

const conditions = {
	is: (input, match) => input == match,
	'is not': (input, match) => input != match,
	empty: (input, match) => !match || /^\s*$/.test(match),
	'is not empty': (input, match) => (match ? match.length > 0 : false),
	contains: (input, match) => match.indexOf(input) > -1,
	'does not contain': (input, match) => match.indexOf(input) < 0,
	'starts with': (input, match) => match.slice(0, input.length) == input,
	'does not start with': (input, match) => match.slice(0, input.length) != input,
	'ends with': (input, match) => match.indexOf(input, match.length - input.length) != -1,
	'does not end with': (input, match) => match.indexOf(input, match.length - input.length) == -1,
	'is any of': (input, match) => {
		const values = _.map(input.split(','), (value) => value.trim());
		return _.contains(values, match);
	},
	'is not any of': (input, match) => !conditions['is any of'](input, match),
	'is greater than': (input, match) => parseFloat(match) > parseFloat(input),
	'is less than': (input, match) => match !== '' && parseFloat(match) < parseFloat(input),
	'is checked': (input, match) => match == 1,
	'is not checked': (input, match) => !match || match == 0,
};

const arrayConditions = {
	is: (input, matches) => _.contains(matches, input),
	'is not': (input, matches) => !arrayConditions['is'](input, matches),
	empty: (input, matches) => matches.length == 0,
	'is not empty': (input, matches) => !arrayConditions['empty'](input, matches),
	contains: (input, matches) => !!_.find(matches, (value) => conditions['contains'](input, value)),
	'does not contain': (input, matches) => !arrayConditions['contains'](input, matches),
	'starts with': (input, matches) => !!_.find(matches, (value) => conditions['starts with'](input, value)),
	'does not start with': (input, matches) => !arrayConditions['starts with'](input, matches),
	'ends with': (input, matches) => !!_.find(matches, (value) => conditions['ends with'](input, value)),
	'does not end with': (input, matches) => !arrayConditions['ends with'](input, matches),
	'is any of': (input, matches) => {
		const values = _.map(input.split(','), (value) => value.trim());
		return _.intersection(values, matches).length > 0;
	},
	'is not any of': (input, matches) => !arrayConditions['is any of'](input, matches),
};

/**
 * Given a pattern, input and a value (which can be a string or an array)
 * this function checks if the value meets any of the defined conditions.
 */
const checkCondition = (pattern, input, value) => {
	input = ('' + input).toLowerCase();

	// Catch null and undefined values
	value = value == null ? '' : value;

	if ($.isArray(value)) {
		return (
			_.has(arrayConditions, pattern) &&
			arrayConditions[pattern](
				input,
				value.map((v) => ('' + v).toLowerCase())
			)
		);
	}

	return _.has(conditions, pattern) && conditions[pattern](input, ('' + value).toLowerCase());
};

/**
 * Return a conditional visibility map.
 */
const visibility = (conditionalFields, value, forceHide) =>
	conditionalFields.reduce((visibility, field) => {
		let toggle;

		if (checkCondition(field.conditionalPattern, field.conditionalValue, value)) {
			toggle = field.conditionalVisibility === 'show';
		} else {
			toggle = field.conditionalVisibility === 'hide';
		}

		visibility[field.slug] = forceHide === true ? false : toggle;

		return visibility;
	}, {});

export { checkCondition, visibility };
