<template>
	<a class="btn btn-primary btn-lg" :href="href" @click.prevent="redirect">
		<slot></slot>
	</a>
</template>

<script>
export default {
	props: {
		href: {
			type: String,
			required: true,
		},
	},
	methods: {
		redirect() {
			window.location.href = this.href;
		},
	},
};
</script>
