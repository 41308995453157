<template>
	<form method="POST" :action="route" accept-charset="UTF-8">
		<input name="_method" type="hidden" :value="method" />
		<input v-for="id in ids" :key="id" type="hidden" name="selected[]" :value="id" />
		<slot></slot>
		<button
			v-if="showSubmit"
			class="submit"
			:class="buttonClass ? buttonClass : 'dropdown-menu-item'"
			@click.prevent="submit"
		>
			{{ labels.button }}
		</button>
	</form>
</template>

<script>
const toastr = require('toastr');

/**
 * The ListActionForm component.
 */
export default {
	props: {
		ids: {
			type: Array,
			default: () => [],
		},
		labels: {
			type: Object,
			default: () => ({}),
		},
		route: {
			type: String,
			required: true,
		},
		method: {
			type: String,
			default: 'POST',
		},
		showSubmit: {
			type: Boolean,
			default: true,
		},
		buttonClass: {
			type: String,
			required: false,
		},
	},
	methods: {
		submit() {
			if (this.ids.length === 0) {
				toastr.info(App.selectedItemsMessage.clarify());
				return;
			}

			this.$emit('submitted');
		},
	},
};
</script>
