var render = function render(){var _vm=this,_c=_vm._self._c;return _c('td',{directives:[{name:"on-click-outside",rawName:"v-on-click-outside",value:(_vm.closeEditor),expression:"closeEditor"}],class:[
    'cell',
    {
      'cell-editable': _vm.isEditable,
      'cell-disabled': !_vm.isEditable,
      'cell-last': _vm.isLast
    }
  ],on:{"click":_vm.openEditor,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.closeEditor.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;$event.preventDefault();return _vm.closeEditor.apply(null, arguments)}],"mouseenter":function($event){return _vm.$emit('update:activeRow', _vm.row)},"mouseleave":function($event){return _vm.$emit('update:activeRow', '')}}},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.translatedCellName))]),_vm._v(" "),(_vm.editing)?_c('div',{staticClass:"editor"},[_c('translations',{attrs:{"supported-languages":_vm.$attrs['supported-languages'],"label":_vm.labels.rowLabel,"translations":_vm.translations,"translated":_vm.translated},on:{"update:translated":function($event){_vm.translated=$event}}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }