var Vue = require('vue');
var StatusWidget = require('./components/StatusWidget').default;

var statusWidgetEl = document.getElementById('status-widget');

if (statusWidgetEl) {
  new Vue({
    el: statusWidgetEl,
    name: 'StatusWidgetApp',
    components: {
      'StatusWidget': StatusWidget
    }
  });
}
