export default {
  mainMenu: state => state.mainMenu,
  selectedContext: state => state.selectedContext,
  selectedContextMainMenu: state => getSelectedContextMainMenu(state.mainMenu, state.selectedContext)
};
const getSelectedContextMainMenu = (mainMenu, context) => {
  if (mainMenu[context.name]) {
    return mainMenu[context.name].children;
  }
};
