<template>
    <form-widget
        :href="submitUrl"
        :coverImageUrl="form.coverImageUrl"
        :formName="formName"
        :callToAction="callToAction"
    />
</template>

<script>
import { route } from '@/lib/utils.js';
import FormWidget from "@/lib/components/Forms/FormWidget";

export default {
  components: {
    FormWidget
  },
  props: {
    form: {
      type: Object,
      required: true
    },
    routes: {
      type: Object,
      required: true
    },
    defaultCallToAction: {
      type: String,
      default: 'Start entry'
    }
  },
  computed: {
    submitUrl() {
      return (
        route(this.routes['entry-form.entrant.start']) +
        `?formSlug=${this.form.slug}`
      );
    },
    formName() {
      return this.form.name || '';
    },
    callToAction() {
      return this.form.callToAction || this.defaultCallToAction;
    }
  }
};
</script>