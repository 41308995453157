<template>
  <file-upload-field
    :field="field"
    :name="name"
    :value="field.value"
    :resource-slug="resourceSlug"
    :in-active-tab="true"
  ></file-upload-field>
</template>
<script>
import langMixin from '@/lib/components/Translations/mixins/lang-mixin.js';
import FileUploadField from '@/lib/components/Fields/FileUploadField';
import featuresMixin from '@/lib/components/Shared/mixins/features-mixin.js';
import linksMixin from '@/lib/components/Shared/mixins/links-mixin.js';

export default {
  components: { FileUploadField },
  mixins: [langMixin, linksMixin, featuresMixin],
  props: {
    field: {
      type: Object,
      required: true
    },
    supportedLanguages: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    resourceSlug: {
      type: String,
      required: true
    }
  }
};
</script>
