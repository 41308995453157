<template>
  <div>
    <div class="form-group">
      <div class="signature-pad draw">
        <canvas ref="canvas"></canvas>
        <a href class="clear" @click.prevent="clear">
          {{ lang.get('signatures.form.clear.label') }}
        </a>
      </div>
    </div>
    <div class="form-group">
      <button
        type="submit"
        class="btn btn-primary btn-lg"
        :disabled="!canSign || isEmpty"
      >
        {{ lang.get('signatures.form.agree.label') }}
      </button>
    </div>
    <input type="hidden" name="signature" :value="dataURL" />
  </div>
</template>

<script>
import SignaturePad from 'signature_pad';

export default {
  props: {
    canSign: {
      type: Boolean,
      default: true
    }
  },
  inject: ['lang'],
  data() {
    return {
      signaturePad: null,
      dataURL: ''
    };
  },
  computed: {
    isEmpty() {
      return this.signaturePad ? this.signaturePad.isEmpty() : true;
    }
  },
  mounted() {
    this.create();
    this.resizeCanvas();

    window.addEventListener('resize', this.resizeCanvas);
  },
  beforeDestroy() {
    this.destroy();

    window.removeEventListener('resize', this.resizeCanvas);
  },
  methods: {
    create() {
      this.signaturePad = new SignaturePad(this.$refs.canvas, {
        backgroundColor: 'rgba(255, 255, 255, 0)',
        penColor: 'rgb(0, 0, 0)',
        onEnd: this.onEnd
      });
    },
    destroy() {
      if (this.signaturePad) {
        this.signaturePad.off();
      }
    },
    clear() {
      this.signaturePad.clear();
      this.dataURL = '';
    },
    onEnd() {
      this.dataURL = this.signaturePad.toDataURL('image/png');
    },
    resizeCanvas() {
      // To correctly handle canvas on low and high DPI screens one has
      // to take devicePixelRatio into account and scale the canvas accordingly.
      // https://github.com/szimek/signature_pad#handling-high-dpi-screens
      const ratio = Math.max(window.devicePixelRatio || 1, 1);

      this.$refs.canvas.width = this.$refs.canvas.offsetWidth * ratio;
      this.$refs.canvas.height = this.$refs.canvas.offsetHeight * ratio;
      this.$refs.canvas.getContext('2d').scale(ratio, ratio);
    }
  }
};
</script>
