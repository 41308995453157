export default function (value, data) {
  if(!data) return false
  if(!Array.isArray(data)) data = [data]
  if(data.length === 0) return false

  const min = 1*data[0]
  const max = data.length > 1 ? 1*data[1] : null

  if(max === null) {
    return `${value}`.length >= min
  } else {
    return `${value}`.length >= min && `${value}`.length <= max
  }
};
