<template>
  <div class="row">
    <div class="col-xs-12 col-md-6 col-lg-4">
      <judging-shortcuts
        :routes="routes"
        :menu="menu"
        :permissions="permissions"
        :forms="forms"
      />
    </div>
    <div
      v-for="scoreSet in scoreSets"
      :key="'score-set-' + scoreSet.id"
      class="col-xs-12 col-md-6 col-lg-4"
    >
      <score-set
        :score-set="scoreSet"
        :stats="stats[scoreSet.id] || {}"
        :rounds="rounds[scoreSet.id] || []"
        :judging-progress="judgingProgress[scoreSet.id] || {}"
        :routes="routes"
        :permissions="permissions"
      />
    </div>
    <!-- <div
      v-if="(i + 1) % 2 === 1"
      :key="'clearfix-md-' + scoreSet.id"
      class="clearfix visible-md"
    />
    <div
      v-if="(i + 1) % 3 === 2"
      :key="'clearfix-lg-' + scoreSet.id"
      class="clearfix visible-lg"
    /> -->
  </div>
</template>

<script>
import langMixin from '@/lib/components/Translations/mixins/lang-mixin.js';
import ScoreSet from './ScoreSet';
import JudgingShortcuts from './JudgingShortcuts';

export default {
  components: {
    ScoreSet,
    JudgingShortcuts
  },
  mixins: [langMixin],
  props: {
    scoreSets: {
      type: Array,
      default: () => []
    },
    stats: {
      type: Object,
      default: () => ({})
    },
    rounds: {
      type: Object,
      default: () => ({})
    },
    routes: {
      type: Object,
      default: () => ({})
    },
    judgingProgress: {
      type: Object,
      default: () => ({})
    },
    menu: {
      type: Array,
      default: () => []
    },
    permissions: {
      type: Object,
      default: () => ({})
    },
    availableForms: {
      type: Object,
      required: true
    },
    multiform: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    forms() {
      return this.multiform ? Object.entries(this.availableForms).map(k => ({slug: k[0], name:k[1]})) : null
    }
  }
};
</script>
