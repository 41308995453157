var Vue = require('vue');
var Signature = require('@/lib/components/Signatures/Signature.vue').default;

module.exports = function() {
  const signatureEl = document.getElementById('signature');

  if (signatureEl) {
    const signature = new Vue({
      el: signatureEl,
      name: 'SignatureApp',
      components: {
        Signature
      }
    });
  }
};
