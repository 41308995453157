import Vue from 'vue';
import ContextMenu from './components/Navigation/ContextMenu';
import store from '@/lib/store';

document.querySelectorAll('.context-menu').forEach(el => {
  new Vue({
    el: el,
    components: {
      ContextMenu
    },
    store
  });
});
