<template>
	<span :class="iconStyle" tabindex="0" @click="toggleAndSave" @keyup.space="toggleAndSave">
		<span class="sr-only">{{ resourceLabel }}</span>
	</span>
</template>

<script>
import Icon from '@/lib/components/Shared/Icon.vue';

export default {
	props: {
		model: {
			type: String,
			required: true,
		},
		slug: {
			type: String,
			required: true,
		},
		checked: {
			type: Boolean,
			default: false,
		},
		resourceLabel: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			active: true,
			state: this.checked,
		};
	},
	computed: {
		iconStyle() {
			return `af-icons ${this.toggling}-star ${this.color}-star af-icons-star-${this.shape}`;
		},
		color() {
			return (this.state && this.active) || (!this.state && !this.active) ? 'yellow' : 'grey';
		},
		toggling() {
			return this.active ? 'active' : 'inactive';
		},
		shape() {
			switch (true) {
				case this.active && this.state:
					return 'filled';
				case !this.active && this.state:
					return 'remove';
				case this.active && !this.state:
					return 'hollow';
				case !this.active && !this.state:
					return 'add';
			}

			return '';
		},
	},
	components: {
		Icon,
	},
	methods: {
		toggleAndSave() {
			if (this.active) {
				this.active = false;
				this.$http
					.post('/toggle-star', {
						model: this.model,
						slug: this.slug,
						value: !this.state,
					})
					.then(
						(response) => {
							this.active = true;
							this.state = response.data.value;
						},
						() => (this.active = true)
					);
			}
		},
	},
};
</script>
