import tectoastr from 'tectoastr';

export default function() {
  this.copy = function(value) {
    var $temp = $('<input>');
    $('body').append($temp);
    $temp.val(value).select();
    document.execCommand('copy');
    $temp.remove();
    tectoastr.info($('.api-key-copy-message-text').val());
  };
}
