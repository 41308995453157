<template>
  <div :ref="guide.id">
    <collapsible-box
      class="guide"
      :class="{ selected: selectedGuideId === guide.id }"
      :title="guide.heading"
      :link="link"
      :content="guide.description"
      :collapsed="collapsed"
      @close="$emit('close', guide.id)"
      @open="$emit('open', guide.id)"
    >
      <template>
        <video-embedded v-if="showVideo" :url="guide.videoUrl"></video-embedded>
      </template>
      <div v-if="hasChecklist" class="mtl">
        <div v-for="item in checklist" :key="item.id" :class="['checklist-item', { checked: isItemChecked(item.id) }]">
          <div class="checkbox styled">
            <input
              :id="'item-checklist-' + item.id"
              type="checkbox"
              :checked="item.checked"
              @change="e => checklistItemChanged(item.id, e.target.checked)"
            />
            <label :for="'item-checklist-' + item.id" v-html="item.body" />
          </div>
        </div>
        <div class="reset-guide-wrap">
          <a v-if="anyItemIsChecked" href="javascript:" class="reset-guide" @click="resetGuide()">
            {{ resetGuideText }}
          </a>
        </div>
      </div>
    </collapsible-box>
  </div>
</template>

<script>
import CollapsibleBox from '../Shared/CollapsibleBox';
import VideoEmbedded from '../Video/VideoEmbedded';
import Popover from 'vue-bootstrap/src/Popover';
import copyToClipboard from '@/lib/components/Shared/mixins/copy-to-clipboard-mixin.js';
import { setUrlParameter } from '@/lib/url';
import _ from 'lodash';

export default {
  inject: ['lang'],
  components: { CollapsibleBox, VideoEmbedded, Popover },
  mixins: [copyToClipboard],
  props: {
    guide: {
      type: Object,
      required: true
    },
    resetGuideText: {
      type: String,
      required: true
    },
    videoUrl: {
      type: String,
      default: null
    },
    selectedGuideId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      checklist: this.guide.checklist,
      copiedChecklistId: null
    };
  },
  computed: {
    id() {
      return this.guide.id;
    },
    anyItemIsChecked() {
      return _.some(this.checklist, item => item.checked);
    },
    showVideo() {
      return !!this.guide.videoUrl;
    },
    collapsed() {
      if (this.selectedGuideId === this.id) {
        return false;
      }

      return this.guide.collapsed;
    },
    link() {
      return setUrlParameter(window.location.href, 'guide', this.guide.id);
    },
    hasChecklist() {
      return !_.isEmpty(this.checklist);
    }
  },
  mounted() {
    this.scrollToGuide();
  },
  methods: {
    checklistItemChanged(checklistItemId, isChecked) {
      this.checklist[checklistItemId].checked = isChecked;
      this.$emit('checklistCheck', this.id, checklistItemId, isChecked);
    },
    isItemChecked(itemId) {
      return this.checklist[itemId].checked;
    },
    resetGuide() {
      Object.values(this.checklist).forEach(item => {
        item.checked = false;
      });
      this.$emit('guideReset', this.id);
    },
    scrollToGuide() {
      if (this.selectedGuideId === this.guide.id) {
        const element = this.$refs[this.selectedGuideId];
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  }
};
</script>

<style>
.guide .title {
  display: block;
}

.guide .box-content {
  padding: 0 0 12px;
}

.guide .video-wrapper {
  margin-top: 0;
}

.guide .checklist-item ul {
  margin-bottom: 0;
  padding-left: 15px;
}

.guide .checklist-item.checked,
.guide .checklist-item.checked a {
  color: #bbb;
}

.guide .checklist-item.checked .checkbox label::before {
  border-color: #bbb;
}

.guide .checklist-item.checked .checkbox label::after {
  color: #bbb;
}

.reset-guide-wrap {
  width: 100%;
  text-align: right;
}

.reset-guide {
  display: inline-block;
}
</style>
