import { CommentMode } from '@/lib/components/Comments/Comment.types';
import { commentsServiceFactory } from '@/lib/components/Comments/commentsService';
import { htmlToMarkdown } from '@/lib/markdown/html-to-markdown';
import { trans } from '@/services/global/translations.interface';
import Uploader from '@/lib/components/Uploader/Uploader.vue';
import { useGlobal } from '@/services/global/global.interface';
import { useTranslationsDao } from '@/domain/dao/Translations';
import {
	canUploadFiles,
	deletedFile,
	imageDataUrl,
	uploadCanceled,
	uploadCompleted,
	uploadedFile,
	uploadingFile,
	useFiles,
} from '@/lib/components/Shared/editor/uploads/uploadsLogic';
import { CommentEmitters, CommentEvents } from '@/lib/components/Comments/Comment.events';
import type { CommentProps, CommentUpdatedResponse, CommentView } from '@/lib/components/Comments/Comment.types';
import { Error, errorHandler } from '@/lib/components/Comments/errorHandler';
import { File, FileId } from '@/lib/components/Shared/editor/uploads/Uploads.types';
import Vue, { computed, ref, SetupContext } from 'vue';

const commentControllerFactory =
	() =>
	(props: CommentProps, emit: CommentEmitters): CommentView => {
		const { files, canceledFiles } = useFiles();
		files.value = (props.comment || {}).files || [];

		const uploader = ref<typeof Uploader>(null);
		const disabled = ref(false);
		const mode = ref(CommentMode.VIEW);
		const confirmedDeletion = ref(false);
		const deletingFiles = ref<FileId[]>([]);
		const commentContent = ref<string | null>(null);

		const translations = useTranslationsDao<{
			audit: {
				api: () => string;
			};
		}>();
		const commentAuthor = ref(props.comment.isApi ? translations.audit.api() : props.comment.user);

		const buttonsDisabled = computed(() => pendingUploads.value > 0);
		const showConfirmDeletionModal = ref(false);
		const canUpload = computed(() => canUploadFiles(props));
		const deleteCommentUrl = props.deleteUrl + props.comment.slug;
		const updateCommentUrl = props.updateUrl + props.comment.slug;

		const commentsService = commentsServiceFactory();
		const pendingUploads = computed(commentsService.pendingUploads as () => number);
		const userId = commentsService.userId();

		const startedEditing = () => {
			commentContent.value = htmlToMarkdown(props.comment.content);
			mode.value = CommentMode.EDIT;
			commentsService.changeCommentsEditedBy(1);
		};

		const finishedEditing = () => {
			Vue.prototype.$http
				.put(updateCommentUrl, {
					comment: commentContent.value,
					fileTokens: files.value.map((f: File) => f.token),
					token: props.token,
				})
				.then(
					(response: { data: CommentUpdatedResponse }) => {
						const { slug, content, files, relativeTime, user, userId } = response.data;
						emit(CommentEvents.Updated, { slug, content, files, relativeTime, user, userId });
						mode.value = CommentMode.VIEW;
						commentsService.changeCommentsEditedBy(-1);
					},
					(error: Error) => errorHandler(error)
				);
		};

		const cancelEditing = (): void => {
			mode.value = CommentMode.VIEW;
			commentsService.changeCommentsEditedBy(-1);
		};

		const deleteComment = () => {
			Vue.prototype.$http
				.post(deleteCommentUrl, {
					_method: 'DELETE',
					slug: props.comment.slug,
					token: props.token,
				})
				.then(
					() => {
						if (mode.value === CommentMode.EDIT) commentsService.changeCommentsEditedBy(-1);

						emit(CommentEvents.Deleted);
					},
					(error: Error) => errorHandler(error)
				)
				.finally(() => {
					showConfirmDeletionModal.value = false;
				});
		};

		const onDeletedFile = (file: File): void => {
			deletingFiles.value.push(file.id);

			setTimeout(() => {
				deletedFile(file, files, props.comment, {
					finally: () => {
						deletingFiles.value = deletingFiles.value.filter((fileId: FileId) => fileId !== file.id);
					},
				});
			}, 2000);
		};

		return {
			lang: trans,
			useGlobal,

			uploader,
			disabled,
			files,
			deletingFiles,
			mode,
			commentContent,
			commentAuthor,
			confirmedDeletion,

			buttonsDisabled,
			pendingUploads,
			deleteCommentUrl,
			updateCommentUrl,
			canUpload,
			showConfirmDeletionModal,
			userId,

			startedEditing,
			finishedEditing,
			cancelEditing,
			deleteComment,
			onUpload: () => uploader.value.$refs.button.click(),
			onUploadingFile: (file: File): void => uploadingFile(file, files, commentsService, userId),
			onUploadedFile: (completeFileTokens: FileId[], file: File): void =>
				uploadedFile(completeFileTokens, file, files, commentsService, canceledFiles, userId),
			onImageDataUrl: (dataUrl: string | null, file: File): void => imageDataUrl(dataUrl, file, files),
			onUploadCanceled: (file: File): void => uploadCanceled(file, files, commentsService, canceledFiles),
			onUploadCompleted: (completeFileTokens: FileId[]): void => uploadCompleted(completeFileTokens, trans),
			onDeletedFile,
		};
	};

const commentController = (props: CommentProps, ctx: SetupContext) => commentControllerFactory()(props, ctx.emit);

export { commentController, commentControllerFactory, CommentProps };
