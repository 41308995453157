<script>
import InfoBox from './InfoBox';

const Visibility = Object.freeze({
  OPEN: 'open',
  COLLAPSED: 'closed'
});

export default {
  extends: InfoBox,
  computed: {
    boxClass() {
      return ['box', this.isOpen ? Visibility.OPEN : Visibility.COLLAPSED];
    },
    chevronClass() {
      const direction = this.isCollapsed ? 'up' : 'down';
      return ['af-icons', `af-icons-chevron-${direction}`, 'chevron'];
    }
  },
  created() {
    this.isCollapsed = this.collapsed;
  },
  methods: {
    setClosed() {
      this.isCollapsed = true;
      this.$emit('close');
    },
    setOpen() {
      this.isCollapsed = false;
      this.$emit('open');
    },
    toggleVisibility() {
      if (this.isCollapsed) {
        this.setOpen();
      } else {
        this.setClosed();
      }
    }
  }
};
</script>

<style scoped>
.box .title {
  cursor: pointer;
}
</style>
