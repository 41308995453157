<template>
  <div class="tours">
    <tour v-for="tour in initTours" :key="tour.id" :tour="tour"></tour>
  </div>
</template>

<script>
import CollapsibleBox from '../Shared/CollapsibleBox';
import Tour from './Tour';
import langMixin from '@/lib/components/Translations/mixins/lang-mixin.js';

export default {
  components: { CollapsibleBox, Tour },
  mixins: [langMixin],
  props: {
    initTours: {
      type: Array,
      required: true
    }
  }
};
</script>
