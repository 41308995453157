<template>
	<div>
		<div v-show="loading" class="preview">
			<div class="icon">
				<i class="af-icons-md af-icons-md-circle-processing af-icons-animate-rotate"></i><br />
				<span class="transcoding-status">
					{{ lang.get('files.transcoding.labels.ready') }}
				</span>
			</div>
		</div>
		<a v-show="!loading" target="_blank" rel="noopener noreferrer" class="preview" @click.prevent="play">
			<img v-if="!error" :src="file.image" />
			<div class="icon">
				<i class="af-icons-md af-icons-md-circle-video"></i>
			</div>
		</a>
		<video-modal
			:file-id="file.remoteId"
			:modal-visible="modalVisible"
			:source="file.source"
			:poster="file.image"
			:caption="file.caption"
			:video-player-height="file.videoHeight"
			@closed="stop"
		/>
	</div>
</template>

<script>
import VideoModal from '@/lib/components/Video/VideoModal';

export default {
	inject: ['lang'],
	components: {
		VideoModal,
	},
	props: {
		file: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			modalVisible: false,
			loading: true,
			error: false,
			height: 0,
		};
	},
	mounted() {
		this.preloadImage();
	},
	methods: {
		preloadImage() {
			const img = new Image();

			// Preload image
			img.onload = () => {
				this.height = img.height;
				this.loading = false;
			};

			img.onerror = () => {
				this.loading = false;
				this.error = true;
			};

			img.src = this.file.image;
		},
		play() {
			this.modalVisible = true;
		},
		stop() {
			this.modalVisible = false;
		},
	},
};
</script>
