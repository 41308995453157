import AccountSwitcher from '@/lib/components/Navigation/AccountSwitcher';
import vOutsideEvents from 'vue-outside-events';
import Vue from 'vue';

const accountSwitcherEl = document.getElementById('account-switcher');

if (accountSwitcherEl) {
	Vue.use(vOutsideEvents);
	new Vue({
		el: accountSwitcherEl,
		components: {
			AccountSwitcher,
		},
	});
}
