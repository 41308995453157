/**
 * Routes names are the same as in laravels definitions
 * Root components name is calculated by replacing all dots with dashes in route name
 *
 * Imports point to module definition file that uses
 * Composer.registerVuePlugins
 * Composer.registerComponents
 * to register plugins and components used within root component scope
 *
 */
type ModuleLoader = () => Promise<{
	default: () => void;
	name?: string;
}>;

const routes = {
	'fast-start.index': () => import('@/modules/fast-start/index'),
	'billing.index': () => import('@/modules/billing/index'),
	'test.index': () => import('@/modules/test/index'),
} as Record<string, ModuleLoader>;

export { routes, ModuleLoader };
