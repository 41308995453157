var $ = require('jquery');
// eslint-disable-next-line @typescript-eslint/naming-convention
var TooltipAttacher = require('./tooltip-attacher.js');

var body = $('body');

var tooltipAttacher = new TooltipAttacher();

// Attach tooltips
body.on('pjax:end', function () {
	tooltipAttacher.attach('trigger-tooltip');
});

// Hide tooltips
body.on('click', function (e) {
	tooltipAttacher.hide('trigger-tooltip', e.target);
});

body.on('keyup', function (e) {
	if (e.which === 27) {
		tooltipAttacher.hide('trigger-tooltip', e.target);
	}
});

// This is for fresh page requests
tooltipAttacher.attach('trigger-tooltip');
