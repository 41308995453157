<template>
  <div
    v-if="contentBlock"
    :class="['content-block-container', 'edit', contentBlock.key]"
  >
    <a class="af-icons af-icons-edit" @click.prevent="redirect(editUrl)"></a>
    <content-block
      :content-block="contentBlock"
      :model-name="modelName"
      :model-id="modelId"
      :container-class="containerClass"
      :title-as-link="titleAsLink"
      :title-tag="titleTag"
    />
  </div>
  <div v-else :class="['content-block-container', 'new', contentBlockKey]">
    <a class="af-icons af-icons-edit" @click.prevent="redirect(newUrl)"></a>
  </div>
</template>

<script>
import Storage from '@/lib/storage.js';
import ContentBlock from './ContentBlock';
import { mapGetters } from 'vuex';

export default {
  components: {
    ContentBlock
  },
  props: {
    contentBlockKey: {
      type: String,
      required: true
    },
    contentBlock: {
      type: Object,
      default: null
    },
    modelName: {
      type: String,
      default: null
    },
    modelId: {
      type: Number,
      default: null
    },
    containerClass: {
      type: String,
      default: ''
    },
    titleTag: {
      type: String,
      default: 'h3'
    },
    titleAsLink: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('entryForm', ['userId']),
    newUrl() {
      if (this.modelName && this.modelId) {
        return `/content/block/new?concerns=${this.modelName}&id=${this.modelId}&key=${this.contentBlockKey}`;
      }

      return `/content/block/new?key=${this.contentBlockKey}`;
    },
    editUrl() {
      return `/content/block/${this.contentBlock.slug}`;
    },
    configurationModeStorageKey() {
      return 'configurationMode-' + this.userId;
    }
  },
  methods: {
    redirect(url) {
      const storage = new Storage();
      let redirectUrl = window.location.href;
      if (new Storage().get(this.configurationModeStorageKey) === 'true') {
        redirectUrl += '&configuration=true';
      }
      storage.createCookie('redirect_after_submit', redirectUrl, 1);
      window.location.href = url;
    }
  }
};
</script>
