const prefixEqualSign = str => (str.startsWith('=') ? str : `=${str}`);

export default {
  getFieldTitles: state => state.titles || {},

  getFieldValues: (state, getters) =>
    // Filter out values of incompatible field types
    Object.fromEntries(
      Object.entries(state.values).filter(([key]) => Object.keys(getters.getFieldTitles || {}).includes(key)) || []
    ),

  formulaForEvaluation: (state, getters) => formula => {
    // Convert formula from `=IF({ORwPaZPY}=10, "yes", "no")` to `=IF(ORwPaZPY=10, "yes", "no")`.
    const templatedKeys = Object.keys(getters.getFieldValues || {}).map(item => '{' + item + '}');

    const regex = new RegExp(templatedKeys.join('|'), 'gi');

    const expression = formula.replace(regex, match => match.slice(1, match.length - 1));

    return expression ? prefixEqualSign(expression) : '';
  },

  fieldSlugTitleMap: (state, getters) =>
    Object.fromEntries(
      Object.entries(getters.getFieldTitles || {}).map(([slug, title]) => [`{${slug}}`, `{${title}}`])
    ),

  fieldTitleSlugMap: (state, getters) =>
    Object.fromEntries(
      Object.entries(getters.fieldSlugTitleMap)
        .map(([slug, title]) => [slug, title.toLowerCase()])
        .map(a => a.reverse())
    )
};
