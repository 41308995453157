import { VideoPlayerOptions } from '@/domain/services/VideoPlayer/VideoPlayerTypes';

const videoPlayerOptions = {
	controls: true,
	autoplay: false,
	preload: 'metadata',
	fluid: true,
	// fill: true,
	// responsive: true,
	liveTracker: false,
	liveui: false,
	controlBar: {
		liveDisplay: false,
		pictureInPictureToggle: false,
		muteToggle: false,
		seekToLive: false,
		volumePanel: { inline: false },
	},
	html5: {
		vhs: {
			overrideNative: true,
			limitRenditionByPlayerDimensions: false,
		},
		nativeAudioTracks: false,
		nativeVideoTracks: false,
	},
} as VideoPlayerOptions;

export default videoPlayerOptions;
