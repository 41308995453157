<template>
  <div class="signature">
    <nav class="navigation clearfix">
      <tabs :tabs="tabs()" class="tabs" @selected="id => (tabId = id)" />
    </nav>
    <signature-draw v-if="tabId === 'draw'" :can-sign="canSign" />
    <signature-type v-if="tabId === 'type'" :can-sign="canSign" />
    <signature-upload
      v-if="tabId === 'upload'"
      :can-sign="canSign"
      :routes="routes"
      :contract="contract"
      :uploader-options="uploaderOptions"
    />
    <input type="hidden" name="signatureType" :value="tabId" />
  </div>
</template>

<script>
import { Tabs } from 'vue-bootstrap';
import SignatureDraw from './SignatureDraw';
import SignatureType from './SignatureType';
import SignatureUpload from './SignatureUpload';
import langMixin from '@/lib/components/Translations/mixins/lang-mixin';
import linksMixin from '@/lib/components/Shared/mixins/links-mixin';
import featuresMixin from '@/lib/components/Shared/mixins/features-mixin';
export default {
  components: {
    Tabs,
    SignatureDraw,
    SignatureType,
    SignatureUpload
  },
  mixins: [langMixin, featuresMixin, linksMixin],
  props: {
    canSign: {
      type: Boolean,
      default: true
    },
    uploaderOptions: {
      type: String,
      required: true
    },
    routes: {
      type: Object,
      default: () => {}
    },
    contract: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tabId: 'draw'
    };
  },
  methods: {
    tabs() {
      return [
        { id: 'draw', name: this.lang.get('signatures.tabs.draw') },
        { id: 'type', name: this.lang.get('signatures.tabs.type') },
        { id: 'upload', name: this.lang.get('signatures.tabs.upload') }
      ];
    }
  }
};
</script>

<style scoped>
.tabs {
  border-bottom: 0;
}
</style>
