var Deleter = require('../../lib/deleter.js');

module.exports = function() {
  // Delete buttons
  new Deleter('.deleter');

  $('#activate-volume-entry-pricing').on('change', function() {
    var checked = $(this).prop('checked');
    var form = $(this).parents('form');
    var action = form.attr('action').split('/').slice(3);
    $('.volume-entry-pricing').toggleClass('hidden', !checked);
    $.post(action, form.serialize());
  });

  $('#activate-related-entries-pricing').on('change', function() {
    $('.related-entries-pricing').toggleClass('hidden', !$(this).prop('checked'));
  });

  var updateRelatedEntriesAction = function () {
    $('.related-entries-action-options').addClass('hidden');
    $('.related-entries-action-'+$('#related-entries-action').val()).removeClass('hidden');
  };

  $('#related-entries-action').on('change', updateRelatedEntriesAction);

  updateRelatedEntriesAction();
};
