import Vue from 'vue';
import store from '@/lib/store';
import ProvisioningForm from './components/ProvisioningForm';
export default function() {
  const provisioningFormEl = document.getElementById('provisioning-form');

  if (provisioningFormEl) {
    new Vue({
      el: provisioningFormEl,
      name: 'ProvisioningApp',
      components: {
        'provisioning-form': ProvisioningForm
      },
      store
    });
  }
}
