import { getGlobalData } from '@/lib/utils';

import Lang from 'lang.js/esm/lang';

const getTrans = () =>
	new Lang({
		...getGlobalData('language'),
		messages: getGlobalData('translations'),
	});

// Deprecated: 'trans' will be removed in future versions. Use 'getTrans()' instead.
const trans = getTrans();
const useTranslations = (keys) => keys.map((key) => (p) => getTrans().get(key, p));

export { trans, getTrans, useTranslations };
