import { mountVueComponent } from '@/lib/mount-vue-component';

export default () => {
	import('@/modules/allocation-payment/components/AllocationPayments.vue').then((allocationPayments) => {
		mountVueComponent('allocation-payments', allocationPayments);
	});

	import('@/modules/allocation-payment/components/AllocationPaymentsList.vue').then((allocationPaymentsList) =>
		mountVueComponent('allocation-payments-list', allocationPaymentsList)
	);
};
