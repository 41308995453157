import Vue from 'vue';
import { defaultState } from './state';

export default {
  setDraggedField(state, data) {
    Vue.set(state, 'draggedField', data);
  },

  setTabsLock(state, flag) {
    Vue.set(state, 'tabsLocked', flag);
  },

  /**
   * Enable configuration mode.
   */
  enableConfigurationMode(state, flag) {
    Vue.set(state, 'configurationMode', flag);
  },

  /**
   * Configure field.
   */
  configureField(state, slug) {
    Vue.set(state, 'configuredField', slug);
  },

  /**
   * Configure tab.
   */
  configureTab(state, slug) {
    Vue.set(state, 'configuredTab', slug);
  },

  /**
   * Configure category.
   */
  configureCategory(state, slug) {
    Vue.set(state, 'configuredCategory', slug);
  },

  /**
   * Configure chapter.
   */
  configureChapter(state, slug) {
    Vue.set(state, 'configuredChapter', slug);
  },

  /**
   * Configure form.
   */
  configureForm(state, configure = true) {
    Vue.set(state, 'configuredForm', configure);
  },

  /**
   * Set the configurationInProgress flag.
   */
  setConfigurationInProgressFlag(state, flag) {
    Vue.set(state, 'configurationInProgress', flag);
  },

  /**
   * Set the saving flag.
   */
  setSavingFlag(state, flag) {
    Vue.set(state, 'saving', flag);
  },

  /**
   * Preserve field configuration.
   *
   * This allows to cancel the configuration and revert the modified field
   * back to its original state.
   */
  preserveField(state, field) {
    Vue.set(state, 'preservedField', field);
  },

  /**
   * Preserve tab configuration.
   */
  preserveTab(state, tab) {
    Vue.set(state, 'preservedTab', tab);
  },

  /**
   * Preserve category configuration.
   */
  preserveCategory(state, category) {
    Vue.set(state, 'preservedCategory', category);
  },

  /**
   * Preserve chapter configuration.
   */
  preserveChapter(state, chapter) {
    Vue.set(state, 'preservedChapter', chapter);
  },

  /**
   * Preserve form configuration.
   */
  preserveForm(state, form) {
    Vue.set(state, 'preservedForm', form);
  },

  /**
   * Reset state to default state
   */
  resetState(state) {
    Object.assign(state, defaultState());
  },

  /**
   * Set the hasErrors flag.
   */
  setHasErrors(state, flag) {
    Vue.set(state, 'hasErrors', flag);
  },

  /**
   * Set the fields for conditional list.
   */
  saveConditionalFieldsList(state, fieldsList) {
    Vue.set(state, 'conditionalFieldsList', fieldsList);
  },

  /**
   * Add a field to conditional list.
   */
  addToConditionalFieldsList(state, field) {
    Vue.set(
      state,
      'conditionalFieldsList',
      [...state.conditionalFieldsList, field].sort(function(a, b) {
        return a.title.localeCompare(b.title);
      })
    );
  },

  /**
   * Delete a field from conditional list.
   */
  deleteFromConditionalFieldsList(state, field) {
    const index = state.conditionalFieldsList.findIndex(f => f.id === field.id);
    if (index !== -1) {
      Vue.delete(state.conditionalFieldsList, index);
    }
  }
};
