import { vueData } from '@/domain/services/VueData';
import { computed, ComputedRef, Ref, ref } from 'vue';

type DocumentTemplate = {
	name: string;
	slug: string;
};

type DocumentTemplateTranslations =
	| Record<string, Record<string, { name: string; description: string }>>
	| Record<string, never>;

type FileTypes = {
	pdf: string;
	word: string;
};

type Notification = {
	slug: string;
	subject: string;
};

type Translations = Record<string, { name: string }> | Record<string, never>;

type Props = {
	documentTemplates: DocumentTemplate[];
	documentTemplateTranslations: DocumentTemplateTranslations;
	documentTemplateId?: number | null;
	fileTypes: FileTypes;
	resource: string;
	name: string;
	notifications: Notification[];
	buttonClass?: string | null;
};

type View = {
	documentTemplate: Ref<string | null>;
	fileType: Ref<string>;
	shared: Ref<boolean>;
	notification: Ref<string | null>;
	translated: Ref<Translations>;
	supportedLanguages: string[];
	nameIsset: ComputedRef<boolean>;
	formFilled: ComputedRef<boolean>;
	token: string;
	currentURL: string;
	onTranslated: (translations: Translations) => void;
	toggleShared: (value: boolean) => void;
	selectNotification: (name: string, value: string) => void;
	selectDocumentTemplate: (name: string, value: string) => void;
};

const createDocumentController = (props: Props): View => {
	const documentTemplate: Ref<string | null> = ref(null);
	const fileType: Ref<string> = ref('pdf');
	const shared: Ref<boolean> = ref(false);
	const notification: Ref<string | null> = ref(null);
	const translated: Ref<Translations> = ref({});

	const token = vueData.CSRF_TOKEN;

	if (!token) {
		throw new Error('CSRF token not injected.');
	}

	const nameIsset: ComputedRef<boolean> = computed(() =>
		doesNameExistInTranslations(translated.value, [vueData.defaultLanguage])
	);

	const formFilled: ComputedRef<boolean> = computed(
		(): boolean => !!(documentTemplate.value && fileType.value && nameIsset.value)
	);

	const currentURL = typeof window !== 'undefined' ? window.location.href : '';

	const onTranslated = (translations: Translations): void => {
		translated.value = translations;
	};

	const toggleShared = (value: boolean): void => {
		shared.value = value;
	};

	const selectNotification = (name: string, value: string): void => {
		notification.value = value;
	};

	const selectDocumentTemplate = (name: string, templateSlug: string) => {
		documentTemplate.value = templateSlug;

		translated.value = Object.entries(props.documentTemplateTranslations[templateSlug] || {}).reduce(
			(acc, [language, value]) => {
				acc[language] = { name: value?.name?.trim() || '' };
				return acc;
			},
			{} as Translations
		);
	};

	const doesNameExistInTranslations = (translations: Translations, languages: string[]): boolean => {
		for (const language of languages) {
			if (translations[language] && translations[language]?.name.trim() !== '') {
				return true;
			}
		}

		return false;
	};

	return {
		documentTemplate,
		fileType,
		shared,
		notification,
		translated,
		supportedLanguages: vueData.supportedLanguages,
		nameIsset,
		formFilled,
		token,
		currentURL,
		onTranslated,
		toggleShared,
		selectNotification,
		selectDocumentTemplate,
	};
};

export { createDocumentController, Props, View };
