<template>
  <div class="container">
    <div class="row">
      <br /><br /><br /><br /><br />
      <div class="col-xs-12 col-md-6 col-md-offset-3">
        <h1 class="provisioning-label" role="heading" aria-level="2">
          Hold tight!
        </h1>

        <div class="vertical well">
          <span
            >We’re getting your account ready - this will take several minutes
            so get yourself a cup of tea and relax. If you have any questions,
            you can reach us at
            <a target="_blank" :href="supportUrl">{{ brandName }} support</a>.
          </span>
          <br /><br />
          <i class="af-icons af-icons-repeat af-icons-animate-rotate"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProvisioningMixins from '../../provisioning/components/provisioning-mixins';

export default {
  mixins: [ProvisioningMixins],
  props: {
    accountGlobalId: {
      type: String,
      required: true
    },
    supportUrl: {
      type: String,
      required: true
    },
    brandName: {
      type: String,
      required: true
    }
  },
  created() {
    this.pusherSubscribe(this.accountGlobalId);
  },
  beforeDestroy() {
    this.pusherUnsubscribe();
  }
};
</script>
