<template>
  <td
    v-on-click-outside="closeEditor"
    :class="[
      'cell',
      {
        'cell-editable': isEditable,
        'cell-disabled': !isEditable,
        'cell-last': isLast
      }
    ]"
    @click="openEditor"
    @keydown.enter.prevent="closeEditor"
    @keydown.esc.prevent="closeEditor"
    @mouseenter="$emit('update:activeRow', row)"
    @mouseleave="$emit('update:activeRow', '')"
  >
    <div class="name">{{ translatedCellName }}</div>
    <div v-if="editing" class="editor">
      <translations
        :supported-languages="$attrs['supported-languages']"
        :label="labels.rowLabel"
        :translations="translations"
        :translated.sync="translated"
      >
      </translations>
    </div>
  </td>
</template>

<script>
import { clickOutside } from 'vue-bootstrap';
import Translations from './TableFieldConfiguratorTranslations.vue';
import filtersMixin from './mixins/table-field-filters-mixin.js';
import { mapGetters, mapMutations } from 'vuex';
import { getGlobal } from '@/lib/utils';

export default {
  components: {
    Translations
  },
  mixins: [clickOutside, filtersMixin],
  inheritAttrs: false,
  props: {
    column: {
      type: String,
      required: true
    },
    columnIndex: {
      type: Number,
      required: true
    },
    row: {
      type: String,
      required: true
    },
    rowIndex: {
      type: Number,
      required: true
    },
    lastColumnIndex: {
      type: Number,
      required: true
    },
    labels: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      editing: false,
      translated: {}
    };
  },
  computed: {
    ...mapGetters(['getTranslation', 'getTranslations']),
    key() {
      return this.column + ':' + this.row;
    },
    translations() {
      return this.getTranslations(this.key);
    },
    translatedCellName() {
      if (!this.isEditable) {
        return this.cellName;
      }

      return this.getTranslation(getGlobal('language'), this.key) || this.cellName;
    },
    cellName() {
      let prefix = '';
      let columnIndex = this.columnIndex;

      // Column index went over the available letters of the alphabet - add prefix
      if (columnIndex / 26 >= 1) {
        prefix = String.fromCharCode(65 + (Math.floor(columnIndex / 26) - 1));
        columnIndex = columnIndex % 26;
      }

      // Uppercase letters of the alphabet start at char code 65
      return prefix + String.fromCharCode(65 + columnIndex) + (this.rowIndex + 1);
    },
    isEditable() {
      return this.columnType(this.column) === 'label';
    },
    isLast() {
      return this.columnIndex === this.lastColumnIndex;
    }
  },
  destroyed() {
    this.$emit('editor-closed');
  },
  methods: {
    ...mapMutations(['updateTranslations']),
    openEditor() {
      if (!this.isEditable || this.editing) {
        return;
      }

      this.editing = true;
      this.$emit('editor-opened');

      // Hide controls
      this.$emit('update:activeRow', '');
    },
    closeEditor() {
      if (!this.editing) {
        return;
      }

      this.editing = false;
      this.$emit('editor-closed');

      // Save translations
      this.updateTranslations({
        key: this.key,
        translated: Object.assign({}, this.translated)
      });
    }
  }
};
</script>
