import $ from 'jquery';
/**
 * Adjust xhr options - sign URLs.
 *
 * This throws a warning in the Chrome console:
 *
 * [Deprecation] Synchronous XMLHttpRequest on the main thread is deprecated because of its
 * detrimental effects to the end user's experience. For more help, check https://xhr.spec.whatwg.org/.
 *
 */
const beforeRequest = (options: { uri: string }) => {
	const localOptions = options;
	const el = document.createElement('a');

	el.href = localOptions.uri;
	const pathname = el.pathname.replace(/^\//, '');

	$.ajax({
		type: 'GET',
		url: '/cloud-asset-url?path=' + pathname,
		dataType: 'json',
		async: false,
	})
		.done(function (response: string) {
			options.uri = response;
			return options;
		})
		.fail(function (error: object) {
			console.error('Failed to sign URL', { options, error });
			return options;
		});
};

export default beforeRequest;
