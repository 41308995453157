import Vue from 'vue';
import ScoreSetsList from './components/ScoreSetsList';
import store from '@/lib/store';

export default function () {
  const scoreSetsList = new Vue({
    el: '#score-sets-list',
    name: 'ScoreSetsApp',
    components: {
      ScoreSetsList
    },
    store
  });

  $(document).one('pjax:end', () => {
    scoreSetsList.$destroy();
  });
}
