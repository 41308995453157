<template>
  <div class="saved-views-list-row">
    <div :class="'saved-search saved-search-' + savedView.slug + ' row'">
      <div class="col-xs-1">
        <div class="dropdown action-overflow">
          <a class="dropdown-toggle" data-toggle="dropdown" aria-expanded="true"
            ><i class="af-icons af-icons-action-overflow"></i
          ></a>
          <ul class="dropdown-menu">
            <li>
              <a href="#" class="edit" @click="toggleForm">{{
                lang.get('search.saved-searches.edit')
              }}</a>
            </li>
            <li>
              <a href="#" class="delete" @click="deleteSavedView">{{
                lang.get('search.saved-searches.delete')
              }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-xs-10 saved-view-link-col">
        <span class="redirect">
          <a
            :href="'/search/settings/' + savedView.slug"
            class="saved-view-link"
            >{{ savedView.name }}</a
          >
          <popover
            v-if="savedView.shared"
            :content="
              lang.get('search.saved-searches.shared-by', {
                name: savedView.userFullName
              })
            "
            trigger="hover"
            wrapper-tag="span"
          >
            <span class="af-icons af-icons-shared" />
          </popover>
        </span>
      </div>
    </div>
    <div v-if="showForm" :class="'form form-' + savedView.slug + ' row'">
      <div class="col-xs-12">
        <save-view-form
          :id="'save-view-form-' + savedView.id"
          :area="area"
          :consumer-id="consumerId"
          class="save-view-form"
          :language="language"
          :default-language="defaultLanguage"
          :translations="translations"
          :saved-view="savedView"
          :consumer-can-view-dashboard="consumerCanViewDashboard"
          @close="toggleForm"
        ></save-view-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import langMixin from '@/lib/components/Translations/mixins/lang-mixin.js';
import SaveViewForm from './SaveViewForm';
import { Popover } from 'vue-bootstrap';

export default {
  components: {
    SaveViewForm,
    Popover
  },
  mixins: [langMixin],
  props: {
    savedView: {
      type: Object,
      required: true
    },
    area: {
      type: String,
      required: true
    },
    consumerId: {
      type: Number,
      default: null
    },
    consumerCanViewDashboard: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showForm: false
    };
  },
  methods: {
    ...mapActions('savedView', {
      delete: 'deleteSavedView'
    }),
    toggleForm() {
      this.showForm = !this.showForm;
    },
    deleteSavedView() {
      this.delete(this.savedView);
    }
  }
};
</script>

<style scoped>
.saved-search {
  padding: 5px 5px 0px 5px;
}

.saved-view-link-col {
  padding-left: unset;
}

.saved-view-link {
  text-decoration: none;
}

.af-icons-action-overflow {
  font-size: 24px;
}

.form {
  margin-bottom: 10px;
}
</style>
