<template>
  <div>
    <h2 v-if="savedViews.length > 0" class="h3 first">
      {{ lang.get('search.saved-searches.heading') }}
    </h2>
    <saved-views-list-row
      v-for="savedView in savedViews"
      :key="savedView.id"
      :saved-view="savedView"
      :area="area"
      :consumer-id="consumerId"
      :language="language"
      :default-language="defaultLanguage"
      :translations="translations"
      :consumer-can-view-dashboard="consumerCanViewDashboard"
    ></saved-views-list-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import langMixin from '@/lib/components/Translations/mixins/lang-mixin.js';
import SavedViewsListRow from './SavedViewsListRow';

export default {
  components: {
    SavedViewsListRow
  },
  mixins: [langMixin],
  props: {
    area: {
      type: String,
      required: true
    },
    consumerId: {
      type: Number,
      default: null
    },
    consumerCanViewDashboard: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('savedView', ['savedViews'])
  }
};
</script>
