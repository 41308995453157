<template>
  <component
    :is="componentName"
    v-if="componentName"
    v-bind="componentParams"
  ></component>
  <div v-else v-html="text"></div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: null
    }
  },
  computed: {
    textDecoded() {
      return this.text || '';
    },
    componentName() {
      if (this.textDecoded.startsWith('<edit-interface-text ')) {
        return 'EditInterfaceText';
      }
      return null;
    },
    componentParams() {
      if (!this.textDecoded.startsWith('<edit-interface-text ')) {
        return null;
      }
      return {
        class: 'editable-interface-text',
        payload: this.extractPayloadAttributeFromHtml(this.textDecoded)
      };
    }
  },
  methods: {
    extractPayloadAttributeFromHtml(html) {
      html = html.substr(html.indexOf('payload="') + 9);
      html = html.substr(0, html.indexOf('">'));
      return html;
    }
  }
};
</script>
