import Vue from 'vue';
import store from '@/lib/store';
import AssignmentList from './components/AssignmentList';

export default function() {
    const assignmentList = new Vue({
        el: '#assignment-list',
        name: 'AssignmentListApp',
        components: {
           AssignmentList
        },
        store
    });
    $(document).one('pjax:end', () => {
        assignmentList.$destroy();
    });
}
