<template>
  <div :class="['form-group', { error: validationError || errors.roles }]">
    <label>{{ lang.get('users.form.roles.text') }}</label>
    <div
      v-for="(role, index) in sortedRoles"
      :key="role.slug"
      :class="['checkbox', 'styled', { error: errors[`roles.${role.slug}`] }]"
    >
      <input
        :id="`role-${role.slug}`"
        v-model="selectedRoles"
        :name="`roles[${role.slug}]`"
        type="checkbox"
        :value="role.slug"
        :disabled="role.disabled"
        @change="onChange"
      />
      <label :for="`role-${role.slug}`">
        {{ role.name }}
      </label>
    </div>
    <div v-if="validationError" class="alert-error inline">
      {{ lang.get('validation.roles_required') }}
    </div>
    <div
      v-for="error in errors.roles"
      class="alert-error inline"
      v-html="error"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'RolesBox',
  inject: ['lang'],
  props: {
    roles: {
      type: Array,
      required: true
    },
    errors: {
      type: Object,
      default: () => {}
    },
    values: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      selectedRoles: Object.values(this.values || {}),
      validationError: false
    };
  },
  computed: {
    sortedRoles() {
      return [...this.roles].sort((a, b) => a.name.localeCompare(b.name));
    }
  },
  methods: {
    onChange() {
      this.validationError = this.selectedRoles.length == 0;
      this.$emit('invalidate', this.selectedRoles.length > 0);
    }
  }
};
</script>
