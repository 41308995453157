<template>
  <input
    v-model.trim="localInput"
    type="text"
    :disabled="disabled"
    :aria-label="label"
  />
</template>

<script>
export default {
  props: {
    input: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      localInput: this.input
    };
  },
  watch: {
    localInput() {
      this.$emit('update:input', this.localInput);
    }
  }
};
</script>
