<template>
	<div class="component-preview">
		<h2 class="first"><i class="af-icons af-icons-preview"></i> {{ heading }}</h2>
		<slot></slot>
	</div>
</template>

<script>
export default {
	props: {
		heading: {
			type: String,
			required: true,
		},
	},
};
</script>
