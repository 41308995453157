const imageElements = (html) => [...html.matchAll('(<img [\\s\\S]*?\\/?>)')];

const shouldRemoveTheImage = (src) => src && src.includes('file://');

const removeImageElement = (imageElement, html) => html.replaceAll(imageElement[0], '');

/**
 * Image tag can only contain width within the style attribute, separate width will get stripped.
 */
const preserveImageWidthAndHeight = (width, height, src, style, imageElement, html) => {
	if (!width && !height) {
		return html;
	}

	const newStyle = 'width:' + width + 'px;height:' + height + 'px';

	return html.replace(imageElement[0], '<img src="' + src + '" style="' + newStyle + '">');
};

const firstMatch = (string, regex) => {
	const match = [...(string + '').matchAll(new RegExp(regex, 'gi'))];
	if (match && match[0] && match[0][1]) {
		return match[0][1];
	}

	return null;
};

const handleImage = (imageElement, html) => {
	const width = firstMatch(imageElement, 'width="?(\\d+)"?');
	const height = firstMatch(imageElement, 'height="?(\\d+)"?');
	const src = firstMatch(imageElement, 'src="(.*?)"');
	const style = firstMatch(imageElement, 'style="(.*?)"');

	if (shouldRemoveTheImage(src)) {
		return removeImageElement(imageElement, html);
	}

	return preserveImageWidthAndHeight(width, height, src, style, imageElement, html);
};

const handleImages = (html) => {
	for (let imageElement of imageElements(html)) {
		html = handleImage(imageElement, html);
	}

	return html;
};

export { handleImages };
