import SixDigitCode from '@/lib/components/Clear/Code/SixDigitCode';
import vOutsideEvents from 'vue-outside-events';
import Vue from 'vue';

export default function initSixDigitCode(store) {
	if (store === undefined) {
		store = require('../lib/store/index').default;
	}

	const sixDigitCodeEl = document.getElementById('six-digit-code');

	if (sixDigitCodeEl) {
		Vue.use(vOutsideEvents);
		new Vue({
			el: sixDigitCodeEl,
			name: 'SixDigitCodeApp',
			components: {
				SixDigitCode,
			},
			store,
		});
	}
}
