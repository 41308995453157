<template>
  <draggable v-model="rows" draggable=".row" animation="150">
    <div v-for="(row, index) in rows" :key="row" class="row">
      <a @click.prevent>
        <span v-if="row === activeRow" class="af-icons af-icons-up-down"></span>
        <span v-else class="index">{{ index + 1 }}</span>
      </a>
    </div>
  </draggable>
</template>

<script>
import Draggable from 'vuedraggable';

export default {
  components: {
    Draggable
  },
  props: {
    draggableRows: {
      type: Array,
      default: () => []
    },
    activeRow: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      rows: []
    };
  },
  watch: {
    draggableRows() {
      this.rows = this.draggableRows;
    },
    rows() {
      this.$emit('dragging', this.rows);
    }
  },
  created() {
    this.rows = this.draggableRows;
  }
};
</script>
