<template>
  <div class="marker-action">
    <list-action-form
      v-if="validSelection"
      ref="form"
      :ids="ids"
      :labels="{ button: lang.get('buttons.delete_permanently') + '...' }"
      :route="route"
      :method="method"
      @submitted="reveal"
    >
      <input type="hidden" name="option" :value="selectedType" />
      <input type="hidden" name="confirmPassword" :value="password" />
      <portal to="destroyer">
        <p
          v-html="lang.get('users.destroy.introduction', { link: helpLink })"
        ></p>
        <div class="row">
          <div class="col-lg-6 col-xs-12">
            <div v-for="(label, value) in options" class="radio styled">
              <input
                :id="`type${value}`"
                v-model="selectedType"
                type="radio"
                :value="value"
                name="type"
              />
              <label :for="`type${value}`">
                {{ label }}
              </label>
            </div>
            <br />
            <div class="form-group">
              <label for="confirm">{{
                lang.get('users.destroy.confirm_bulk')
              }}</label>
              <password-field
                id="confirm"
                v-model="password"
                name="password"
              ></password-field>
            </div>
            <div>
              <div class="form-actions">
                <button
                  type="submit"
                  class="btn btn-primary btn-lg"
                  :disabled="!deleteButtonActive"
                  @click="confirmSubmit"
                >
                  {{ lang.get('buttons.delete') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <modal
          v-model="showModal"
          :header="false"
          :confirm-button-label="lang.get('buttons.delete')"
          :close-button-label="lang.get('buttons.cancel')"
          :confirm-on-enter="false"
          @closed="showModal = false"
          @confirmed="submit"
        >
          <close-icon
            slot="before-content"
            @click.prevent="showModal = false"
          ></close-icon>
          <div>
            {{ lang.choice('users.destroy.confirm_message_count', ids.length) }}
            {{ lang.get('users.destroy.confirm_message') }}
          </div>
        </modal>
      </portal>
    </list-action-form>
    <popover v-else ref="popover" placement="bottom" trigger="hover">
      <a class="dropdown-menu-item" disabled="true" @click.prevent
        >{{ lang.get('buttons.delete_permanently') }}...</a
      >
    </popover>
  </div>
</template>

<script>
import ListAction from './ListAction.vue';
import CloseIcon from './Partials/CloseIcon';
import { Modal, Popover } from 'vue-bootstrap';
import langMixin from '../Translations/mixins/lang-mixin';
import PasswordField from '../Fields/PasswordField';

/**
 * The Destroyer component.
 */
export default {
  components: {
    PasswordField,
    CloseIcon,
    Modal,
    Popover
  },
  extends: ListAction,
  mixins: [langMixin],
  props: {
    validateIds: {
      type: Boolean,
      default: false
    },
    validIds: {
      type: Array,
      default: () => []
    },
    helpLink: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showModal: false,
      selectedType: null,
      password: ''
    };
  },
  computed: {
    options() {
      return {
        1: this.lang.get('users.destroy.options.one'),
        2: this.lang.get('users.destroy.options.two'),
        3: this.lang.get('users.destroy.options.three')
      };
    },
    deleteButtonActive() {
      return this.selectedType !== null && this.password.length >= 6;
    },
    validSelection() {
      if (!this.validateIds || this.ids.length === 0) {
        return true;
      }

      return this.ids.every(id => this.validIds.includes(id));
    }
  },
  watch: {
    ids() {
      if (this.ids.length === 0) {
        this.hide();
      }
    }
  },
  methods: {
    confirmSubmit() {
      this.showModal = true;
    },
    reveal() {
      this.$emit('reveal', 'destroyer');
    },
    hide() {
      this.password = '';
      this.$emit('reveal', null);
    }
  }
};
</script>
