<template>
	<action-dropdown :id="dropdownId" ref="dropdown" :label="$attrs.label" :position="$attrs.position">
		<slot></slot>
	</action-dropdown>
</template>

<script>
import ActionDropdown from '../Shared/ActionDropdown.vue';

export default {
	components: {
		ActionDropdown,
	},
	inheritAttrs: false,
	props: {
		revealedAction: {
			type: String,
			default: null,
		},
		dropdownId: {
			type: String,
			default: 'list-action-dropdown',
		},
	},
	watch: {
		revealedAction() {
			this.$refs.dropdown.close();
		},
	},
};
</script>
