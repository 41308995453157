import { getPlayer } from '@/domain/services/VideoPlayer/VideoPlayerWrapper';
import init from '@/domain/services/VideoPlayer/VideoPlayerInit';
import Player from 'video.js/dist/types/player';
import { VideoPlayerOptions } from '@/domain/services/VideoPlayer/VideoPlayerTypes';
import videoPlayerOptions from '@/domain/services/VideoPlayer/VideoPlayerOptions';

/**
 * Keep track of all player instances.
 */
const players: Player[] = [];

/**
 * Loop through all elements and initialise the video player.
 *
 * elements -> one or more elements, optionally a class name
 * customOptions -> custom options that extend the default options
 */
const setup = (element: string, customOptions: Partial<VideoPlayerOptions>) => {
	const options: VideoPlayerOptions = videoPlayerOptions;
	if (customOptions) {
		Object.assign(options, customOptions);
	}

	const elements = document.getElementsByClassName(element);

	for (let i = 0; i < elements.length; i++) {
		const videoId = elements[i].id;
		if (getPlayer(videoId)) {
			getPlayer(videoId).dispose();
		}

		const player = init(videoId, options);

		const aspectRatio = document.getElementById(videoId)?.getAttribute('data-aspect-ratio');
		if (aspectRatio) player.aspectRatio(aspectRatio);

		players.push(player);
	}
};

/**
 * Pause video playback.
 */
const pause = () => {
	players.forEach((player) => {
		if (player) {
			player.pause();
		}
	});
};

export default {
	setup,
	pause,
};
