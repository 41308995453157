<template>
	<div class="url-field-embed">
		<iframe
			v-if="videoEmbedSrc"
			:class="['url-field-embed__video-iframe', `url-field-embed__video-iframe--${urlType}`]"
			:src="videoEmbedSrc"
			frameborder="0"
			allowfullscreen
			:title="`${urlType} video embed`"
		/>
		<div v-if="urlType === 'url'" class="preview-container">
			<redirector :url="url" :external-url="externalUrl">
				<img :src="url2png" class="img-responsive" alt="" />
			</redirector>
		</div>
	</div>
</template>

<script>
import Redirector from '../Shared/Redirector';

export default {
	name: 'UrlFieldEmbedPreview',
	components: { Redirector },
	props: {
		urlType: {
			type: String,
			required: true,
			validator: (urlTypeValue) =>
				({ vimeo: 1, youtube: 1, wistia: 1, url: 1, tiktok: 1, twitch: 1, instagram: 1 }[urlTypeValue] === 1),
		},
		url: {
			type: String,
			required: true,
			description: 'Full url',
		},
		externalUrl: {
			type: String,
			default: null,
			description: 'Url to AwardForce redirector, used only for an `url` type',
		},
		url2png: {
			type: String,
			default: null,
			description: 'Url to a preview of user input, used only for an `url` type',
		},
		urlReferenceId: {
			type: String,
			default: null,
			description: 'Vimeo video id or YouTube video id',
		},
	},
	computed: {
		videoEmbedSrc() {
			return (
				{
					youtube: `https://www.youtube.com/embed/${this.urlReferenceId}`,
					vimeo: `https://player.vimeo.com/video/${this.urlReferenceId}`,
					wistia: `https://fast.wistia.net/embed/iframe/${this.urlReferenceId}?videoFoam=true`,
					tiktok: `https://www.tiktok.com/embed/v2/${this.urlReferenceId}?lang=en-US&amp;`,
					twitch: `https://player.twitch.tv/?channel=${this.urlReferenceId}&parent=${window.location.hostname}`,
					instagram: `https://www.instagram.com/reel/${this.urlReferenceId}/embed`,
				}[this.urlType] || null
			);
		},
	},
};
</script>
