<template>
  <textarea
    v-model="currentValue"
    :class="['form-control', 'ignore', { compact: !isExpanded }]"
    :disabled="disabled"
    :placeholder="placeholder"
    @focus="onFocus"
    @blur="onBlur"
    @input="onInput"
  >
  </textarea>
</template>

<script>
/**
 * The ExpandableTextarea component.
 */
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentValue: this.value,
      isExpanded: false
    };
  },
  watch: {
    value() {
      this.currentValue = this.value;
      this.currentValue ? this.expand() : this.collapse();
    }
  },
  created() {
    if (this.currentValue) {
      this.expand();
    }
  },
  methods: {
    expand() {
      if (this.isExpanded) {
        return;
      }

      this.isExpanded = true;
      this.$emit('expanded');
    },
    collapse() {
      if (!this.isExpanded) {
        return;
      }

      this.isExpanded = false;
      this.$emit('collapsed');
    },
    onFocus() {
      this.expand();
    },
    onBlur() {
      if (!this.currentValue) {
        this.collapse();
      }
    },
    onInput() {
      this.$emit('input', this.currentValue);
    }
  }
};
</script>
