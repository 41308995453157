var Vue = require('vue');
var ScoringCriterionFormWrapper = require('./components/ScoringCriterionFormWrapper').default;
var store = require('../../lib/store/index.js').default;

var FormDisabler = require('../../lib/form-disabler.js');

module.exports = function () {
    if (document.getElementById('scoring-criterion-form-wrapper')) {
        const scoringCriterionForm = new Vue({
            el: '#scoring-criterion-form-wrapper',
            name: 'ScoringCriterionFormApp',
            components: {
                'scoringCriterionFormWrapper': ScoringCriterionFormWrapper
            },
            store
        });
        (new FormDisabler()).disableForm($('.form-disabled'));

        $(document).one('pjax:end', () => {
            scoringCriterionForm.$destroy();
        });
    }
};
