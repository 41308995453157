<template>
	<div class="row">
		<div :class="['col-xs-12', 'col-sm-6', 'col-lg-4', { 'col-sm-offset-3 col-lg-offset-4': centered }]">
			<alert-island v-if="showAlerts" :show-form-errors="false"></alert-island>
			<simple-widget id="six-digit-code" widget-class="simple-widget-form">
				<template slot="content">
					<slot></slot>
					<div v-if="remember" class="form-group">
						<div class="checkbox styled">
							<input id="remember" v-model="rememberMe" type="checkbox" value="1" />
							<label for="remember">
								{{ remember }}
							</label>
						</div>
					</div>
					<div :class="hasError ? 'has-errors' : null">
						<label>{{ getLabel }}</label>
					</div>

					<div
						v-click-outside="() => (currentFocusIndex = null)"
						class="input-wrapper"
						:class="hasError ? 'has-errors' : null"
					>
						<template v-for="index in numberOfDigits">
							<input
								:id="'input-' + index"
								:ref="'input-' + index"
								:key="index"
								maxLength="1"
								size="1"
								pattern="[0-9]{1}"
								type="text"
								:aria-label="`six-digit-code-${index}`"
								:placeholder="showPlaceholder(index) ? '—' : ''"
								:value="digits[index]"
								:disabled="disabled"
								@input="(event) => onInput(index, event.target.value)"
								@focus="setInputFocus(index)"
								@mousedown.prevent="setInputFocus(index)"
								@keydown.left.prevent="onLeft()"
								@keydown.right.prevent="onRight()"
								@keydown.delete="onDelete(index)"
								@keydown.backspace="onDelete(index)"
								@paste.prevent="onPaste"
							/>
						</template>
					</div>

					<div v-if="showBackup">
						<a id="backup-toggle" href="#" @click="handleToggle">{{ toggleText }}</a>
					</div>

					<div :class="{ 'link-group': true, error: hasError }">
						<span v-if="hasError">{{ error }}</span>
						<a
							v-if="resendRoute.length"
							id="resend-code-link"
							class="form-group"
							:href="resendRoute"
							@click.prevent="resendCode"
						>
							{{ resendErrorLabel }}
						</a>
					</div>
					<div class="link-group">
						<button class="btn btn-primary btn-lg" :disabled="disabled" @click="submitCode">
							{{ submitButtonLabel }}
						</button>
						<a v-if="showCancel" href="#" @click.prevent="handleCancel">
							{{ lang.get('buttons.cancel') }}
						</a>
					</div>
					<div v-if="canRequestBackupCode" class="link-group">
						<a :href="sendMobileRoute" class="link-poster ignore" data-method="POST">
							{{ lang.get('auth.authenticator.challenge.send-message') }}
						</a>
					</div>
				</template>
			</simple-widget>
		</div>
	</div>
</template>

<script>
import Storage from '@/lib/storage.js';
import SimpleWidget from '@/lib/components/Shared/SimpleWidget.vue';
import { mapGetters, mapMutations } from 'vuex';
import langMixin from '@/lib/components/Translations/mixins/lang-mixin';
import AlertIsland from '@/lib/components/Shared/AlertIsland';

export default {
	name: 'SixDigitCode',
	components: {
		AlertIsland,
		SimpleWidget,
	},
	mixins: [langMixin],
	props: {
		route: {
			type: String,
			required: true,
		},
		resendRoute: {
			type: String,
			default: '',
		},
		token: {
			type: String,
			default: '',
		},
		showAlerts: {
			type: Boolean,
			default: true,
		},
		recipient: {
			type: String,
			default: '',
		},
		label: {
			type: String,
			default: null,
		},
		submitLabel: {
			type: String,
			default: null,
		},
		centered: {
			type: Boolean,
			default: true,
		},
		remember: {
			type: String,
			default: '',
		},
		showBackup: {
			type: Boolean,
			default: false,
		},
		showCancel: {
			type: Boolean,
			default: true,
		},
		cancelRoute: {
			type: String,
			default: '/',
		},
		cancelMethod: {
			type: String,
			default: 'get',
		},
		autoFocus: {
			type: Boolean,
			default: true,
		},
		hasMobile: {
			type: Boolean,
			default: false,
		},
		sendMobileRoute: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			digits: { 1: '', 2: '', 3: '', 4: '', 5: '', 6: '' },
			currentFocusIndex: null,
			disabled: false,
			rememberMe: false,
			disableResend: false,
			contact: this.recipient || this.preferredContact,
			acceptErrors: false,
			numberOfDigits: 6,
			backupNumberOfDigits: 8,
			codeNumberOfDigits: 6,
			toggleText: '',
			useBackupText: '',
			useCodeText: '',
		};
	},
	computed: {
		...mapGetters('validation', ['formError', 'validationErrors']),
		...mapGetters('authentication', ['preferredContact', 'preferredContactType']),
		code() {
			return Object.values(this.digits)
				.map((digit) => digit)
				.join('');
		},
		isReady() {
			return this.code.length === this.numberOfDigits;
		},
		isFocusOnFirstInput() {
			return this.currentFocusIndex === 1;
		},
		isFocusOnLastInput() {
			return this.currentFocusIndex === this.numberOfDigits;
		},
		hasError() {
			return !!this.error;
		},
		error() {
			if (!this.acceptErrors) {
				return false;
			}

			if (this.formError.message) {
				return this.formError.message;
			}

			return this.validationErrors !== undefined && this.validationErrors.code ? this.validationErrors.code[0] : null;
		},
		getLabel() {
			if (this.label) return this.label;

			const label = this.lang.get('auth.six-digit-code.labels.default');
			return label + ' ' + (this.recipient || this.preferredContact);
		},
		resendErrorLabel() {
			return this.hasError
				? this.lang.get('auth.six-digit-code.errors.resend-code')
				: this.lang.get('auth.request_login_code.code-not-received');
		},
		submitButtonLabel() {
			return this.submitLabel || this.lang.get('buttons.continue');
		},
		canRequestBackupCode() {
			return this.hasMobile && this.sendMobileRoute !== null;
		},
	},
	mounted() {
		if (this.autoFocus) {
			this.setInputFocus(1);
		}

		this.initLanguageProperties();
	},
	methods: {
		...mapMutations('validation', ['resetFormError', 'resetValidationErrors']),
		async onInput(index, value) {
			this.resetFormError();
			this.resetValidationErrors();

			this.acceptErrors = false;

			this.digits[index] = value;

			this.focusNextEmptyInput();

			if (this.isReady) {
				await this.submitCode();
			}
		},
		async submitCode() {
			this.disabled = true;
			this.acceptErrors = true;
			const data = {
				code: this.code,
				token: this.token,
				confirmationToken: this.code,
				recipient: this.recipient,
			};
			if (this.rememberMe) {
				data.remember = '1';
			}

			this.$http
				.post(this.route, data)
				.then(this.handleResponse)
				.catch(() => this.handleFailure());
		},
		handleResponse(response) {
			if (this.hasError) {
				this.handleFailure();
			}

			if (!response.request.responseURL) return;
			if (!response.data) return;

			const { ssoRememberToken, redirectURL } = response.data;

			if (ssoRememberToken !== undefined) {
				new Storage().set('ssoAuthRememberToken', ssoRememberToken);
			}

			if (redirectURL) window.location.href = redirectURL;
		},
		resendCode() {
			if (!this.disableResend) {
				this.disableResend = true;
				window.location.href = this.resendRoute;
			}
		},
		onDelete(index) {
			if (!this.isFocusOnFirstInput && this.digits[index] === '') {
				this.setInputFocus(index - 1);
			}
		},
		showPlaceholder(index) {
			return index !== this.currentFocusIndex;
		},
		focusNextEmptyInput() {
			if (this.isReady && !this.isFocusOnLastInput) {
				return this.setInputFocus(this.currentFocusIndex + 1);
			}

			for (const key in this.digits) {
				if (this.digits[key] === '') {
					return this.setInputFocus(key);
				}
			}

			return this.setInputFocus(this.numberOfDigits);
		},
		setInputFocus(index) {
			this.currentFocusIndex = parseInt(index);
			this.$refs[`input-${index}`][0].focus();
			this.$refs[`input-${index}`][0].select();
		},
		onLeft() {
			if (!this.isFocusOnFirstInput) {
				this.setInputFocus(this.currentFocusIndex - 1);
			}
		},
		onRight() {
			if (!this.isFocusOnLastInput) {
				this.setInputFocus(this.currentFocusIndex + 1);
			}
		},
		async onPaste(event) {
			if (!event.clipboardData || !event.clipboardData.getData) return;

			const pastedClipboardText = event.clipboardData.getData('text');

			if (!pastedClipboardText) return;

			for (const key in this.digits) {
				const parsedKey = parseInt(key);
				this.digits[parsedKey] = pastedClipboardText[key - 1] || '';
			}

			const lastDigit = pastedClipboardText[this.numberOfDigits - 1];

			// eslint-disable-next-line chai-friendly/no-unused-expressions
			lastDigit ? this.setInputFocus(this.numberOfDigits) : this.focusNextEmptyInput();

			if (this.isReady) {
				await this.submitCode();
			}
		},
		handleCancel() {
			if (this.cancelMethod === 'get') {
				window.location.href = this.cancelRoute;
			} else {
				this.$http.request({ method: this.cancelMethod, url: this.cancelRoute });
			}
		},
		handleFailure() {
			this.disabled = false;
			this.$nextTick(() => {
				this.focusNextEmptyInput();
			});
		},
		initDigits(numberOfDigits) {
			return Array.from({ length: numberOfDigits }, (e, index) => 1 + index).reduce((o, key) => ({ ...o, [key]: '' }), {});
		},
		initLanguageProperties() {
			this.useBackupText = this.lang.get('auth.authenticator.challenge.use.backup');
			this.useCodeText = this.lang.get('auth.authenticator.challenge.use.code');
			this.toggleText = this.useBackupText;
		},
		handleToggle(e) {
			e.preventDefault();
			this.toggleText = this.toggleText === this.useCodeText ? this.useBackupText : this.useCodeText;
			this.numberOfDigits = this.toggleText === this.useCodeText ? this.backupNumberOfDigits : this.codeNumberOfDigits;
			this.digits = this.initDigits(this.numberOfDigits);
		},
	},
};
</script>
<style scoped>
.link-group {
	padding-top: 5px;
	padding-bottom: 5px;
	width: 100%;
}
</style>
