<template>
  <div class="score-set-rounds">
    <p v-for="round in rounds" :key="'round-' + round.slug">
      <a :href="roundUrl(round.slug)">
        {{ round.name }}<br />
        {{ round.startsAt }}
        <template v-if="round.endsAt">— {{ round.endsAt }}</template>
      </a>
    </p>
  </div>
</template>

<script>
import { route } from '@/lib/utils.js';

export default {
  props: {
    rounds: {
      type: Array,
      required: true
    },
    routes: {
      type: Object,
      required: true
    }
  },
  methods: {
    roundUrl(roundSlug) {
      return route(this.routes['round.edit'], { round: roundSlug });
    }
  }
};
</script>
