<template>
  <div>
    <search-field
      v-if="field.autocomplete"
      :id="getId()"
      :aria-invalid="hasError"
      :aria-describedby="ariaDescribedby"
      :initial-items="field.options"
      :initial-id="searchFieldId"
      :initial-value="searchFieldValue"
      value-property="text"
      :disabled="disabled"
      @selected="updateSearchValue"
    />
    <select-field
      v-else
      :id="getId()"
      :aria-invalid="hasError"
      :aria-describedby="ariaDescribedby"
      :items="field.options"
      :value="getValue"
      :empty-option="emptyOption"
      value-property="text"
      :disabled="disabled"
      :required="field.required"
      @selected="updateSelectValue"
    />
  </div>
</template>

<script>
import Field from './Field.vue';
import { SearchField, SelectField } from 'vue-bootstrap';

export default {
  components: {
    SearchField,
    SelectField
  },
  extends: Field,
  props: {
    emptyOption: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getValue() {
      if (this.value) {
        return this.value;
      }
      if (!this.emptyOption && this.field.options[0]['id']) {
        return this.field.options[0]['id'];
      }
      return null;
    },
    searchFieldId() {
      if (this.value) {
        return this.value;
      }
      return null;
    },
    searchFieldValue() {
      if (this.value) {
        const option = this.field.options.find(
          // eslint-disable-next-line eqeqeq
          option => option.id == this.value
        );
        return option ? option.text : null;
      }
      return null;
    }
  },
  methods: {
    updateSearchValue(item) {
      this.onInput(item.id);
    },
    updateSelectValue(name, selected) {
      this.onInput(selected);
    }
  }
};
</script>
