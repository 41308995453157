import $ from 'jquery';
import beforeChange from '@/lib/simplemde-before-change';
import { canUploadFiles } from '@/lib/components/Shared/editor/uploads/uploadsLogic';
import { decodeHTML } from '@/lib/components/Shared/editor/helpers/htmlHelper';
import { sanitize } from '@/lib/utils';
import SimpleMDE from 'simplemde/src/js/simplemde';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';

const simpleMdeController = (props, emit) => {
	let simplemde = null;
	const editor = ref(null);
	const textarea = ref(null);
	const localValue = ref(decodeHTML(props.value));

	const canUpload = computed(() => canUploadFiles(props));

	watch(
		() => props.disabled,
		() => refresh()
	);
	watch(
		() => props.value,
		(newValue) => {
			if (newValue !== simplemde.value()) {
				localValue.value = newValue;
				simplemde.value(newValue || '');
			}

			if (props.value && simplemde) {
				simplemde.codemirror.refresh();
			}
		}
	);

	onMounted(() => initialise(getOptions()));
	onBeforeUnmount(() => destroy());

	const toolbarOptions = computed(() => {
		switch (props.mode) {
			case 'full':
				return [
					'bold',
					'italic',
					'heading',
					'|',
					'quote',
					'unordered-list',
					'ordered-list',
					'|',
					canUpload.value ? 'upload' : null,
					'link',
					'|',
					'preview',
					'spell-checker',
					'|',
					'guide',
				];
			case 'single-line':
				return ['bold', 'italic', '|', 'preview', 'spell-checker', '|', 'guide'];
			default:
				return this.customToolbar;
		}
	});

	const initialise = function (options, value = null) {
		value = value !== null ? value : localValue.value || '';

		simplemde = new SimpleMDE(options);
		simplemde.value(value);

		simplemde.options.previewRender = (plainText) => sanitize(simplemde.markdown(plainText));

		simplemde.codemirror.on('change', () => {
			localValue.value = simplemde.value();
			emit('input', simplemde.value());
		});

		simplemde.codemirror.on('beforeChange', beforeChange);

		simplemde.codemirror.on('cursorActivity', onCursorActivity);
		simplemde.codemirror.on('focus', onMdeFocus);
		simplemde.codemirror.on('blur', onBlur);

		enableTabbing();
		makeAccessible();
		updateVisibleTextArea();

		simplemde.codemirror.options.readOnly = props.disabled;
		startAutoRefresh();

		updateWrapper();
	};

	const getOptions = () => ({
		blockStyles: {
			italic: '_',
		},
		element: textarea.value,
		spellChecker: false,
		status: false,
		toolbar: configureToolbar(),
		insertTexts: {
			image: insertImageText(),
			imageUrl: insertImageUrl(),
			link: ['[', '](https://)'],
		},
	});
	const insertImageText = () => ['![](http://', ')'];
	const insertImageUrl = () => ['', ''];
	const configureToolbar = () => {
		if (props.disabled) {
			return [];
		}

		return toolbarOptions.value
			.filter((button) => {
				if (!props.spellChecker && button === 'spell-checker') {
					return false;
				}

				return !!button;
			})
			.map((button) => {
				if (button === 'guide') {
					return guideButton();
				}

				if (button === 'newline') {
					return newlineButton();
				}

				if (button === 'spell-checker') {
					return spellCheckerButton();
				}

				if (button === 'upload') {
					return uploadButton();
				}

				return button;
			});
	};

	const guideButton = () => ({
		name: 'guide',
		action: props.markdownGuideUrl,
		className: 'fa fa-question-circle',
		title: props.markdownGuideLabel,
	});
	const newlineButton = () => ({
		name: 'newline',
		action: {},
		className: 'btn-markdown-newline',
		title: null,
	});
	const spellCheckerButton = () => ({
		name: 'spell-checker',
		action: toggleSpellChecker,
		className: 'fa fa-book',
		title: 'Spell checker',
	});
	const uploadButton = () => ({
		name: 'upload-image',
		className: 'md-upload-img fa fa-paperclip',
		title: 'Upload',
		action: uploadImage,
	});
	const makeAccessible = () => {
		if (simplemde.toolbarElements) {
			Object.keys(simplemde.toolbarElements).forEach((key) => {
				const element = simplemde.toolbarElements[key];
				if (element.tagName === 'A') {
					element.innerHTML = `<span class="sr-only">${element.title}</span>`;
				}
			});
		}
	};

	const uploadImage = () => emit('uploadFile');

	const findWrapper = () => {
		const wrapper = editor.value.getElementsByClassName('CodeMirror CodeMirror-wrap');

		return wrapper.length ? wrapper[0] : null;
	};

	const updateWrapper = () => {
		const wrapper = findWrapper();
		if (wrapper) {
			if (props.disabled) {
				wrapper.setAttribute('disabled', true);
			} else {
				wrapper.removeAttribute('disabled');
			}
		}
	};

	const updateVisibleTextArea = () => {
		$('#' + props.id)
			.find('textarea')
			.attr('aria-label', 'text area');
	};

	const toggleSpellChecker = (editor) => {
		const options = editor.options;
		const currentValue = localValue.value;

		options.spellChecker = !options.spellChecker;

		destroy();
		initialise(options, currentValue);

		// Attempt to trigger the spell checker immediately after the dictionary gets loaded
		setTimeout(() => {
			simplemde.value(currentValue);
		}, 500);

		return editor;
	};

	const enableTabbing = () => {
		simplemde.codemirror.options.extraKeys['Tab'] = false;
		simplemde.codemirror.options.extraKeys['Shift-Tab'] = false;
	};

	let checkIfEditorIsVisible = null;

	/**
	 * SimpleMDE editor value is not visible when it is inside a hidden element (e.g. a tab).
	 * This function checks if the editor is visible and refreshes it when it is.
	 */
	const startAutoRefresh = () => {
		if (!simplemde.value()) return;

		checkIfEditorIsVisible = setInterval(() => {
			if (simplemde?.codemirror && simplemde.codemirror.display.wrapper.offsetHeight > 0) {
				clearInterval(checkIfEditorIsVisible);
				simplemde.codemirror.refresh();
			} else if (!simplemde?.codemirror) {
				clearInterval(checkIfEditorIsVisible);
			}
		}, 250);
	};

	const destroy = () => {
		clearInterval(checkIfEditorIsVisible);
		checkIfEditorIsVisible = null;
		simplemde.toTextArea();
		simplemde = null;
	};

	const refresh = () => {
		if (!simplemde) return;

		destroy();
		initialise(getOptions());
	};

	const onCursorActivity = function () {
		const cursor = simplemde.codemirror.getCursor();

		$(textarea.value).trigger('mdeFocus', [simplemde.codemirror, cursor.line, cursor.ch]);

		emit('keyup', {
			ch: cursor.ch,
			editor: simplemde.codemirror,
			line: cursor.line,
		});
	};

	const onMdeFocus = function () {
		const cursor = simplemde.codemirror.getCursor();

		$(textarea.value).trigger('mdeFocus', [simplemde.codemirror, cursor.line, cursor.ch]);

		emit('focus', {
			ch: cursor.ch,
			editor: simplemde.codemirror,
			line: cursor.line,
		});
	};

	const onBlur = () => {
		// emit native blur event
		const event = new Event('blur');
		textarea.value.dispatchEvent(event);

		emit('blur');
	};

	return {
		editor,
		textarea,
		localValue,
	};
};

export { simpleMdeController };
