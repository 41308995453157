import { areUploaderOptionsValid } from '@/lib/components/Uploader/options-validator';
import { Comment } from '@/lib/components/Comments/Comment.types';
import { CommentsService } from '@/lib/components/Comments/commentsService';
import tectoastr from 'tectoastr';
import toastr from 'toastr';
import { EditorUploadProps, File, FileId, Trans } from '@/lib/components/Shared/editor/uploads/Uploads.types';
import Vue, { Ref, ref } from 'vue';

type Error = { response: { data?: { message?: string } } };

const canUploadFiles = (props: EditorUploadProps) =>
	!!(
		props.uploads &&
		props.uploaderObject &&
		props.uploaderOptions &&
		Object.keys(props.uploaderOptions).length &&
		areUploaderOptionsValid(props.uploaderOptions)
	);

const useFiles = () => {
	const files: Ref<File[]> = ref([]);
	const canceledFiles: Ref<FileId[]> = ref([]);

	return {
		files,
		canceledFiles,
	};
};

const addUserId = (file: File, userId: number): File => {
	if (userId && typeof file.userId === 'undefined') {
		file.userId = userId;
	}

	return file;
};

const uploadingFile = (file: File, files: Ref<File[]>, commentsService: CommentsService, userId: number) => {
	let f = Object.assign({}, file);
	f.temp = true;
	f = addUserId(f, userId);
	files.value.push(f);

	commentsService.changePendingUploadsBy(1);
};

const createNewFileObjectForReadyUpload = (file: File, userId: number): File => {
	const newFile: File = Object.assign({}, file);
	newFile.temp = false;
	newFile.oldId = newFile.id;
	newFile.id = newFile.remoteId;
	return addUserId(newFile, userId);
};

const uploadedFile = (
	completeFileTokens: FileId[],
	file: File,
	files: Ref<File[]>,
	commentsService: CommentsService,
	canceledFiles: Ref<FileId[]>,
	userId: number
): void => {
	if (canceledFiles.value.includes(file.id)) return;

	const newFile = createNewFileObjectForReadyUpload(file, userId);

	files.value.push(newFile);

	const tempFilesToRemove: FileId[] = [];
	if (newFile.oldId) tempFilesToRemove.push(newFile.oldId);

	completeFileTokens = Array.isArray(completeFileTokens) ? completeFileTokens : [completeFileTokens];
	commentsService.changePendingUploadsBy(-completeFileTokens.length);

	// Remove temporary previews that match those tokens
	if (newFile.id) files.value = files.value.filter((f: File) => !f.temp || !tempFilesToRemove.includes(f.id));
};

const uploadCanceled = (
	file: File,
	files: Ref<File[]>,
	commentsService: CommentsService,
	canceledFiles: Ref<FileId[]>
): void => {
	if (!file.id) return;

	canceledFiles.value.push(file.id);
	files.value = files.value.filter((f: File) => !canceledFiles.value.includes(f.id));
	commentsService.changePendingUploadsBy(-1);
};

const uploadError = (trans: Trans): void => {
	tectoastr.error(trans.get('miscellaneous.alerts.generic'));
};

const uploadCompleted = (completeFileTokens: FileId[], trans: Trans): void => {
	if (!completeFileTokens) {
		uploadError(trans);
	}
};

const imageDataUrl = (dataUrl: string | null, file: File, files: Ref<File[]>): void => {
	const index = files.value.findIndex((f: File) => f.id === file.id);
	if (index >= 0) {
		files.value[index].image = dataUrl;
	}
};

const deleteFileUrl = (file: File, comment: Comment | null): string => {
	const slug = comment ? comment.slug : 'undefined';
	return '/comment/file/' + file.id + '/' + slug;
};

const deletedFile = (
	file: File,
	files: Ref<File[]>,
	comment: Comment | null,
	options: { finally?: () => void } = {}
): void => {
	Vue.prototype.$http
		.delete(deleteFileUrl(file, comment))
		.then(
			() => {
				files.value = files.value.filter((f: File) => f.id !== file.id); // Remove the file from gui
			},
			(error: Error) => {
				toastr.error(error?.response?.data?.message || document.getElementById('alerts-generic')?.innerHTML);
			}
		)
		.finally(() => {
			if (comment && !comment.slug) files.value = files.value.filter((f: File) => f.id !== file.id);

			if (options.finally) options.finally();
		});
};

export {
	canUploadFiles,
	deleteFileUrl,
	deletedFile,
	imageDataUrl,
	uploadCanceled,
	uploadCompleted,
	uploadedFile,
	uploadError,
	uploadingFile,
	useFiles,
};
