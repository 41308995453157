<template>
  <modal
    v-model="modalVisible"
    :title="lang.get('files.transcoding.error_header')"
    :footer="false"
    :header-close-button="false"
    @closed="$emit('closed')"
  >
    <close-icon
      slot="before-content"
      @click.prevent="$emit('closed')"
    ></close-icon>
    <span
      v-for="(error, i) in file.transcodingErrors"
      :key="'transcoding-error-' + i"
    >
      {{ error }}<br />
    </span>
  </modal>
</template>

<script>
import CloseIcon from '../ListActions/Partials/CloseIcon';
import { Modal } from 'vue-bootstrap';

export default {
  inject: ['lang'],
  components: {
    CloseIcon,
    Modal
  },
  props: {
    file: {
      type: Object,
      required: true
    },
    modalVisible: {
      type: Boolean,
      default: false
    }
  }
};
</script>
