import Vue from 'vue';
import store from '@/lib/store';
import TagList from './components/TagList';

export default function() {
  const tagList = new Vue({
    el: '#tag-list',
    name: 'TagListApp',
    components: {
       TagList
    },
    store
  });

  $(document).one('pjax:end', () => {
    tagList.$destroy();
  });
}
