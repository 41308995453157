<template>
	<div v-if="files.length" class="cards clearfix uploaded-files">
		<transition-group name="list" tag="div">
			<div v-for="file in files" :key="file.id">
				<file-upload
					class="card"
					:file="file"
					:uploader="uploader"
					:disabled="deletingFiles.indexOf(file.id) !== -1"
					:can-delete="!readOnly && userId === file.userId"
					:hide-empty-action-bar="true"
					:num-of-attachments="files.length"
					:preview-types="uploaderOptions.previewTypes"
					@canceled="$emit('canceled', $event)"
					@deleted="$emit('deleted', file)"
				/>
			</div>
		</transition-group>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import FileUpload from '@/lib/components/Uploader/FileUpload';

export default {
	components: {
		FileUpload,
	},

	props: {
		files: {
			type: Array,
			default: () => [],
		},
		deletingFiles: {
			type: Array,
			default: () => [],
			description: 'File ids being currently deleted',
		},
		readOnly: {
			type: Boolean,
			required: true,
		},
		uploader: {
			type: Object,
			default: () => {},
		},
		uploaderOptions: {
			type: Object,
			default: () => {},
		},
	},

	computed: {
		...mapState('comments', ['userId']),
	},
};
</script>

<style scoped>
.list-enter-active {
	transition: all 0.5s;
}
.list-leave-active {
	transition: all 0.5s;
}
.list-enter,
.list-leave-to {
	opacity: 0;
	transform: translateY(-30px);
}
</style>
