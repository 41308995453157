/****************
 * AF4 Uploader *
 ****************
 *
 * This epic script is a wrapper around Plupload and powers the file upload capaibilities of AF4.
 * It supports both Single File Uploads, and Multi-File Uploads, to support both major use cases.
 *
 * It has also been designed to support other Upload types, if required, as common functionality
 * has been extracted into common helpers.
 *
 * Oh, and it works in IE8! :-)
 *
 */
var plupload = require('plupload');
var filesize = require('filesize');
var tectoastr = require('tectoastr');
var imagesLoaded = require('imagesloaded');
var VideoModal = require('@/domain/services/VideoPlayer/VideoPlayerModal').default;
var datePicker = require('./datepicker.js');
var _ = require('underscore');
var TooltipAttacher = require('./tooltip-attacher.js');
var tooltipAttacher = new TooltipAttacher;

module.exports = function(options, singleUploadElement) {
    // Add custom "min_file_size" file filter to plupload
    plupload.addFileFilter('min_file_size', function(minSize, file, cb) {
        var undef;

        // Invalid file size
        if (file.size !== undef && minSize && file.size < minSize) {
            this.trigger('Error', {
                code : 603,
                file : file
            });
            cb(false);
        } else {
            cb(true);
        }
    });

    // Set the S3 options
    var uploaderOptions = options['s3'];
    // Array of files uploaded, to keep track of the moving parts.
    var uploadFiles = {};
    var fileMap = {};

    // Incremented while files are being uploaded, and decremented when finished, to power disabling save button.
    var uploadingCount = 0;

    /**
     * Helper function to ensure that the specified element is toggled to be shown.
     * This is required as the 'hidden' class we use can cause issues with standard jQuery.show(),
     * because it uses '!important'.
     *
     * @param element The element to be shown.
     */
    var forceShow = function(element) {
        $(element).removeClass('hidden').show();
    };

    /**
     * Updates the progress bar and status element with current status of the specified file.
     *
     * @param container The progress bar container element ('.upload-progress-container')
     * @param status    The status display element.
     * @param file      The Plupload file class.
     */
    var setProgress = function(container, status, file) {

        var progressBar = container.children('.upload-progress');

        forceShow(container);
        forceShow(progressBar);

        $(progressBar).width(Math.round(container.width() * (file.percent / 100)));

        var size = (!isNaN(file.size) && file.size > 0) ? ' ('+filesize(file.loaded)+'/'+filesize(file.size)+')' : '';

        var percent = (file.percent > 0) ? file.percent+'%'+size : status.data('uploading');

        status.text(percent);
    };

    /**
     * Renders a basic preview.
     *
     * @param preview   The element to load the preview in
     * @param icon      The icon to be used
     * @param extension The file extension to be displayed
     */
    var renderBasicPreview = function(preview, icon, extension) {
        var previewContainer = preview.parent('.preview-container');

        var previewTemplate = _.template('<div class="icon"><i class="af-icons-md af-icons-md-<%= icon %>"></i><br>' +
            '<span class="attachment-type"><%= extension %></span></div>');

        preview.append(previewTemplate({ icon: icon, extension: extension.toUpperCase() })).show();

        previewContainer.addClass('no-preview');
    };

    /**
     * Shows a preview of an uploaded file.
     *
     * @param preview The element to load the preview in
     * @param file    The Plupload file class
     */
    var showPreview = function(preview, file) {
        var fileExtension = file.name.split('.').pop().toLowerCase();
        preview.empty().hide();
        switch (true) {
            case (isFileOfType(file, 'image')):
                renderImagePreview(preview, file);
                break;
            case ($.inArray(fileExtension, options.previewTypes.audio) >= 0):
                renderBasicPreview(preview, 'circle-audio', fileExtension);
                break;
            case ($.inArray(fileExtension, options.previewTypes.video) >= 0):
                renderBasicPreview(preview, 'circle-video', fileExtension);
                break;
            default:
                renderBasicPreview(preview, 'circle-document', fileExtension);
        }
    };

    /**
     * Checks whether a file is of a particular type.
     *
     * @param file The Plupload file class
     * @param type The file type
     */
    var isFileOfType = function(file, type) {
        return file.type !== null && file.type.indexOf(type) >= 0;
    };

    /**
     * Renders a preview image of the currently uploading file.
     * Reference: http://www.bennadel.com/blog/2563-showing-plupload-image-previews-using-base64-encoded-data-urls.htm
     *
     * @param preview The element to load the preview in
     * @param file    The Plupload file class
     */
    var renderImagePreview = function(preview, file) {
        var previewContainer = preview.parent('.preview-container');
        var image = $(new window.Image());
        var preloader = new plupload.moxie.image.Image();

        previewContainer.css('height', previewContainer.css('max-height'));

        preloader.onload = function() {
            preloader.downsize(options.preview.width, options.preview.height);
            if (preloader.getAsDataURL()) {
                image.appendTo(preview);
                image.prop("src", preloader.getAsDataURL());
                preview.show();
                previewContainer.css('height', '');
            }
        };

        preloader.load(file.getSource());
    };

    /**
     * Displays the status message in the specified element.
     * For translation purposes, the key is provided to the status message in a data attribute on the element.
     * I.e. <div class="status" data-pending="Pending..."></div>
     *
     * @param element The element to update
     * @param status  The status messege key
     */
    var showStatus = function(element, status, message) {
        let info = message
            ? "&nbsp;<span class='af-icons af-icons-help trigger-tooltip' data-content='"+element.data(message)+"'></span>"
            : '';

        element.html(element.data(status) + info);
        tooltipAttacher.attachTooltips(element.find('.trigger-tooltip'));

        $('.attachments').trigger('onFileUploaded');
    };

    /**
     * Common handler for the 'FilesAdded' event thrown by Plupload.
     *
     * Adds the selected files to the uploadFiles array, disables the buttons marked as '.uploading-disable',
     * and triggers the start of the upload process.
     *
     * @param uploader Plupload instance
     * @param files    Files selected to be uploaded
     * @param id       Uploader id.
     */
    var handleFilesAdded = function(uploader, files, id, count) {

        $.each(files, function(i) {
            uploadFiles[files[i].id] = {
                'id': files[i].id,
                'name': options['tempPrefix'] + files[i].id,
                'original': files[i].name,
                'size': files[i].size,
                'mime': files[i].type,
                'resource': options['resource'],
                'resourceId': options['resourceId'],
                'foreignId': options['foreignId']
            };
        });

        uploadingCount += count;

        disableButtons(true);

        uploader.start();
    };

    /**
     * Disable buttons while the upload is in progress.
     *
     * @param disabled Disable buttons?
     */
    var disableButtons = function(disabled) {
        $('.uploading-disable').prop('disabled', disabled);

        if (disabled) {
            $('.uploading-disable-message').removeClass('hidden');
        } else {
            $('.uploading-disable-message').addClass('hidden');
        }
    }

    /**
     * Common handler for the 'BeforeUpload' event.
     *
     * Manipulates the multipart_params to use a random name, rather than the original file name.
     *
     * @param uploader Plupload instance
     * @param file     File selected to be uploaded
     * @param id       Uploader id.
     */
    var handleBeforeUpload = function(uploader, file, id) {
        uploader.settings.multipart_params.key = uploadFiles[file.id].name;
        uploader.settings.multipart_params.Filename = uploadFiles[file.id].name;
    };

    /**
     * File uploaded successfully to S3, submit to AF and queue responses
     */
    var fileUploaded = function (file, container, processedCallback) {
        var data = uploadFiles[file.id];
        data['tabId'] = $('.tab-content.active input.attachments-tab').val();

        container.find('.upload-progress-container').hide();
        showStatus(container.find('.upload-status'), 'processing');
        $('.attachments').trigger('onFileUploaded');

        $.ajax({
            'type': 'POST',
            'url': options['routes']['upload'],
            'data': data,
            'dataType': 'json',
        }).done((response) => {
            fileMap[response.file] = file.id;
            uploadFiles[file.id].fileId = options['useFileToken'] ? response.token : response.file;
            uploadFiles[file.id].container = container;
            uploadFiles[file.id].processedCallback = processedCallback;
            uploadFiles[file.id].interval = setInterval(() => {
                $.ajax({'type': 'GET', 'url': options['routes']['status']+response.file})
                    .done((response) => {
                        if (['ok', 'rejected'].includes(response.status)) {
                            clearInterval(uploadFiles[file.id].interval);
                            fileProcessed(response);
                        }
                    })
                    .fail(() => {
                        clearInterval(uploadFiles[file.id].interval);
                        fileProcessed({id: response.file, status: 'rejected'});
                    });
            }, 30000);
        }).fail((response) => fileRejected(response, container));
    };

    var fileProcessed = function (response) {
        if (! fileMap[response.id] || ! uploadFiles[fileMap[response.id]]) {
            return;
        }

        var file = uploadFiles[fileMap[response.id]];

        if (response.status == 'rejected') {
            return fileRejected(response, file.container);
        }

        file.container.find('.upload-status').text('');
        file.container.find('input.upload-id').val(file.fileId).trigger('change');
        file.container.find('.filename').html('<a href="' + response.fileUrl + '" target="_blank" class="ignore" rel="noopener noreferrer">' + _.escape(file.original) + '<i class="af-icons af-icons-download pull-right"></i></a>');
        forceShow(file.container.find('.upload-actions'));
        $('.attachments').trigger('onFileUploaded');
        decrementUploadingCount();

        if (file.processedCallback) {
            file.processedCallback(file);
        }
    };

    var fileRejected = function (response, container) {
        showStatus(container.find('.upload-status'), 'failed', response.statusMessage);
        forceShow(container.find('.upload-button'));
        $('.attachments').trigger('onFileUploaded');
        decrementUploadingCount();
    };

    var decrementUploadingCount = function () {
        uploadingCount--;

        if (uploadingCount <= 0) {
            disableButtons(false);
        }
    };

    /**
     * Very basic Error handler...
     * @TODO: Implement proper error handling here.
     *
     * @param up  Plupload instance
     * @param err Error class
     * @param id  Uploader id
     */
    var handleError = function(up, err, id) {
        var errorCode = parseInt(err.code);
        var elementData = $('#'+id).data();

        if(elementData[errorCode.toString().replace(/-/g, "")]) {
            // Error message specific to standalone file uploader
            tectoastr.error(elementData[errorCode.toString().replace(/-/g, "")]);
        } else if(options.errors[errorCode]) {
            // General error message
            tectoastr.error(options.errors[errorCode]);
        } else if(options.errors['-'+errorCode]) {
            tectoastr.error(options.errors['-'+errorCode]);
        } else {
            // Default error message
            tectoastr.error($('#lang-strings #alerts-generic').text());
        }
    };

    /**
     * Cancels an upload in progress, removing the upload container and updating de global uploads count.
     *
     * @param uploader
     * @param container
     * @param file
     */
    var cancelUpload = function (uploader, container, file) {
        uploader.removeFile(file);

        container.find('.delete').trigger('click');

        uploadingCount--;
        if (uploadingCount == 0) {
            disableButtons(false);
        }
    };

    /**
     * Finds all references to files that were already uploaded.
     *
     * @param uploaderContainer - the container element
     * @param identifyByToken - are the files identified by id or token?
     */
    var findUploadedFiles = function(uploaderContainer, identifyByToken) {
        var uploaderContainer = $(uploaderContainer);
        var inputs = uploaderContainer.find('.upload-id');

        uploadedFiles = [];

        $.each(inputs, function(i, input) {
            var fileId = $(input).val();
            var fileContainer = $(input).parent();

            if (!fileId) {
                return true;
            };

            if (identifyByToken) {
                uploadedFiles.push({ id: null, token: fileId, container: fileContainer });
            } else {
                uploadedFiles.push({ id: fileId, token: null, container: fileContainer });
            }
        });

        return uploadedFiles;
    };

    /**
     * Checks whether the uploaded file was transcoded and replaces the video preview.
     *
     * @param uploadedFile
     * @param transcodedFiles
     */
    var setTranscodingStatus = function(uploadedFile, transcodedFiles) {
        var status = null,
            transcodingPreview,
            videoModal,
            previewContainer = uploadedFile.container.find('.preview-container');

        // Find out if the uploaded file is among transcoded files
        $.each(transcodedFiles, function(index, transcodedFile) {
            if ((uploadedFile.id === transcodedFile.id.toString()) || (uploadedFile.token === transcodedFile.token.toString())
                && transcodedFile.transcodingStatus) {

                status = transcodedFile.transcodingStatus;
                transcodingPreview = transcodedFile.transcodingPreview;
            }
        });

        if (status && previewContainer.length) {
            if (status === 'completed') {
                previewContainer.find('.transcoding-status').addClass('hidden');
                previewContainer.find('.transcoding-status-ready').removeClass('hidden');
            }

            transcodingPreview = $(transcodingPreview);

            // Wait for the thumbnail to load
            imagesLoaded(transcodingPreview[0], function() {
                previewContainer.replaceWith(transcodingPreview);

                // Initialise video player
                if (status === 'completed') {
	                VideoModal.setup(transcodingPreview.find('.play-video'));
                }
            });
        }
    };

    /**
     * Handle 'file.transcoding' event.
     *
     * @param uploadedFiles
     * @param transcodedFiles
     */
    var handleTranscodingStatusUpdate = function(uploadedFiles, transcodedFiles) {
        if (uploadedFiles.length && transcodedFiles.length) {
            $.each(uploadedFiles, function(i, uploadedFile) {
                setTranscodingStatus(uploadedFile, transcodedFiles);
            });
        }
    };

  /**
   * Add chunk identifier to filename.
   *
   * @param uploader
   * @param file
   * @param post
   */
    var renameChunk = function (uploader, file, post) {
        var chunk = post.chunk;

        if (chunk !== 0) {
            uploader.settings.multipart_params.key = uploadFiles[file.id].name+'-'+chunk;
        }
    };

    /**
     * Single File Upload class!
     */
    var singleUploader = function(uploadButton) {

        // Unique ID for this specific uploader.
        var id = $(uploadButton).data('uploader');

        // Cache various elements, so we don't need to search for them later.
        var container = $('#'+id);
        var progressContainer = container.find('.upload-progress-container');
        var statusElement = container.find('.upload-status');

        // Setup Uploader
        uploaderOptions['multi_selection'] = false;
        uploaderOptions['browse_button'] = $(uploadButton).get();
        var uploader = new plupload.Uploader(uploaderOptions);

        /**
         * Listen for 'FilesAdded' events.
         *
         * Since we're in single-file mode, we expect only a single file in here.
         * This updates the progress, shows the various containers, hides the upload button, etc...
         *
         * Finally, it fires off the common FilesAdded handler.
         *
         * @param up    Pluploader instance
         * @param files Files being added
         */
        uploader.bind('FilesAdded', function (up, files) {

            showPreview(container.find('.preview'), files[0]);
            setProgress(progressContainer, statusElement, files[0]);
            forceShow(container.find('.file-upload-container'));
            showStatus(statusElement, 'queued');
            container.find('.upload-button').hide();
            container.find('.filename').text(files[0].name);
            container.addClass('card');

            container.find('.upload-cancel a').on('click', function() {
                cancelUpload(up, container, files[0]);
            });

            handleFilesAdded(up, files, id, 1);
        });

        /**
         * Listen for 'BeforeUpload' events.
         *
         * Updates the progress bar, before firing off the common handler.
         *
         * @param up   Pluploader instance
         * @param file File being uploaded
         */
        uploader.bind('BeforeUpload', function (up, file) {
            setProgress(progressContainer, statusElement, file);
            handleBeforeUpload(up, file, id);
        });

        uploader.bind('BeforeChunkUpload', (uploader, file, post) => renameChunk(uploader, file, post));

        /**
         * Listen for 'UploadProgress' events.
         *
         * Updates the progress bar!
         *
         * @param up   Pluploader instance
         * @param file File being uploaded
         */
        uploader.bind('UploadProgress', function (up, file) {
            setProgress(progressContainer, statusElement, file);
        });

        /**
         * Listen for 'FileUploaded' events.
         *
         * Hides the progress bar, and displays the 'processing' status.
         * Then it fires off the common handler with the two callbacks defined.
         *
         * Success: Update elements to reflect successful upload.
         * Fail: Display failed message...
         *
         * @param up       Pluploader instance
         * @param file     File being uploaded
         * @param response Response received from the server.
         */
        uploader.bind('FileUploaded', (up, file) => fileUploaded(file, container));

        /**
         * Listen for 'Error' events.
         *
         * Fires common handler

         * @param up  Pluploader instance
         * @param err Error instance
         */
        uploader.bind('Error', function (up, err) {
            handleError(up, err, id);
        });

        /**
         * Listen for 'file.transcoding' event.
         *
         * @param Event event
         */
        $(document).on('file.transcoding', function (e, data) {
            var transcodedFiles = data.data.transcodedFiles;
            var uploadedFiles = [];

            uploadedFiles = findUploadedFiles(container, options['useFileToken']);

            handleTranscodingStatusUpdate(uploadedFiles, transcodedFiles);
        });

        $(document).one('pjax:end', e => $(document).off('file.transcoding'));

        /**
         * Initiate the Uploader!
         */
        uploader.init();
    };

    /**
     * Multi File Upload functionality.
     */
    var multiUploader = function(uploadButton) {

        // Variables
        var id = $(uploadButton).data('uploader');
        var multiUploaderContainer = $('#'+id);
        var fileContainers = {};

        // Setup Uploader
        uploaderOptions['multi_selection'] = true;
        uploaderOptions['browse_button'] = $(uploadButton).get();
        var uploader = new plupload.Uploader(uploaderOptions);

        /**
         * Listen for 'FilesAdded' events.
         *
         * Checks for violations to the max attachments rules.
         * Copies the template into the attachments container, for each new file.
         * Displays various progress and info.
         *
         * Fires common handler.
         */
        uploader.bind('FilesAdded', function (up, files) {

            var count = 0;

            $.each(files, function(i) {
                var fileCount = $('#' + id).children('.upload-multi').not('#upload-template-replace').length;
                var fileOrder = fileCount + 1;
                if (typeof options['maxAttachments'] != 'undefined') {
                    if (fileCount + 1 == options['maxAttachments']) {
                        $('.upload-multi-button').prop('disabled', true).off();
                        $('.upload-file-limit').removeClass('hidden').show();
                        $('.attachment-container input[type=file]').addClass('disabled-raw-uploader').prop('disabled', true);
                    } else if (fileCount >= options['maxAttachments']) {
                        uploader.removeFile(files[i]);
                        return true; // skip to the next iteration
                    }
                }

                count++;

                $('#upload-templates .upload-multi').clone().prop('id', 'file-'+files[i].id).appendTo('#'+id);
                var container = $('#file-'+files[i].id);
                fileContainers[files[i].id] = {
                    'container': container,
                    'progress': container.find('.upload-progress-container'),
                    'status': container.find('.upload-status')
                };

                container.find('.sort-order').append(fileOrder);
                container.addClass('sort-order-' + fileOrder);
                container.addClass('card');

                showPreview(container.find('.preview'), files[i]);
                setProgress(fileContainers[files[i].id].progress, fileContainers[files[i].id].status, files[i]);
                forceShow(container.find('.file-upload-container'));
                showStatus(fileContainers[files[i].id].status, 'queued');

                // Add the file id as an attribute to each field
                container.find('input[name], textarea[name], select[name]').each(function() {
                    $(this).attr('data-id', files[i].id);
                });

                container.find('.filename').text(files[i].name);

                container.find('.upload-cancel a').on('click', function() {
                    cancelUpload(up, container, files[i]);
                });
            });

            if (count == 0) {
                return;
            }

            $('.attachments').trigger('onFilesAdded');

            handleFilesAdded(up, files, id, count);
        });

        /**
         * Listen for 'BeforeUpload' events.
         *
         * Start the progress bar.
         *
         * Fires common handler
         */
        uploader.bind('BeforeUpload', function (up, file) {
            setProgress(fileContainers[file.id].progress, fileContainers[file.id].status, file);
            handleBeforeUpload(up, file, id);

            // Attach datepicker
            fileContainers[file.id].container.find('.datetime, .date, .time').each(function() {
                datePicker.bindToElement($(this));
            });
        });

        uploader.bind('BeforeChunkUpload', (uploader, file, post) => renameChunk(uploader, file, post));

        /**
         * Updates Upload Progress Bar
         */
        uploader.bind('UploadProgress', function (up, file) {
            setProgress(fileContainers[file.id].progress, fileContainers[file.id].status, file);
        });

        /**
         * Listen for 'FileUploaded' events.
         *
         * Swaps status to processing, and defines the two callbacks for the commmon handler.
         */
        var updatePlaceholders = (file) => {
            file.container.find('input[name], textarea[name], select[name]').each(function () {
                $(this).prop('name', $(this).prop('name').replace('[-]', '['+file.fileId+']'));
                $(this).removeAttr('data-id');
            });

            file.container.find('input[id], textarea[id], select[id]').each(function() {
                $(this).prop('id', $(this).prop('id').replace('-', file.fileId));
            });

            file.container.find('label[for]').each(function() {
                $(this).prop('for', $(this).prop('for').replace('-', file.fileId));
            });

            $('.attachments').trigger('onFileUploaded');
        };
        uploader.bind('FileUploaded', (up, file) => fileUploaded(file, fileContainers[file.id].container, updatePlaceholders));

        /**
        * Basic Error handler for now.
        */
        uploader.bind('Error', function (up, err) {
            handleError(up, err, id);
        });

        /**
         * Listen for 'file.transcoding' event.
         *
         * @param Event event
         */
        $(document).on('file.transcoding', function (e, data) {
            var transcodedFiles = data.data.transcodedFiles;
            var uploadedFiles = [];

            uploadedFiles = findUploadedFiles(multiUploaderContainer, false);

            handleTranscodingStatusUpdate(uploadedFiles, transcodedFiles);
        });

        $(document).one('pjax:end', e => $(document).off('file.transcoding'));

        /**
        * Initiate the Uploader!
        */
        uploader.init();
    };

    /**
     * Load generic - we don't have a single uploader to setup.
     */
    if (singleUploadElement == undefined) {
        /**
         * Create Instances of the Uploader for each instance of `.upload-single` found.
         */
        $('.upload-single .upload-button').each(function() {
            singleUploader(this);
        });

        /**
         * Create MultiUpload instance of each instance of the `.upload-multi-button`.
         *
         * Also checks max attachments to see if buttons need to be disabled.
         */
        $('.upload-multi-button').each(function() {
            multiUploader(this);

            if (typeof options['maxAttachments'] != 'undefined') {
                if (options['maxAttachments'] <= $('.attachments .upload-multi.card').length) {
                    $('.upload-multi-button').prop('disabled', true).off();
                    $('.upload-file-limit').removeClass('hidden').show();
                }
            }
        });

        /**
        * Bind Single Upload 'Delete' button action.
        */
        $('.upload-single .upload-actions .delete').on('click', function () {
            var container = $(this).closest('.upload-single');

            container.removeClass('card');
            container.find('.upload-id').val('').trigger('change');
            container.find('.upload-button').removeClass('hidden').show();
            container.find('.file-upload-container').hide();
        });

    /**
     * Bind to specified single uploader
     */
    } else {
        singleUploader(singleUploadElement.find('.upload-button'));

        singleUploadElement.find('.upload-actions .delete').on('click', function () {
            var container = $(this).closest('.upload-standalone');

            container.removeClass('card');
            container.find('.upload-id').val('').trigger('change');
            container.find('.upload-button').removeClass('hidden').show();
            container.find('.file-upload-container').hide();
        });
    }

    // Listen for external events
    $(document).on('file.processed', (e, data) => fileProcessed(data));
    $(document).one('pjax:end', e => $(document).off('file.processed'));
};
