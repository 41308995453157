<template>
  <div class="radio-list-option">
    <div class="radio styled">
      <input
        :id="id"
        type="radio"
        :value="value"
        :name="name"
        :checked="checked"
        @change="onChange"
      />
      <label :for="id">
        <div v-if="allowHtml" v-html="text"></div>
        <template v-else>{{ text }}</template>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    checked: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: null
    },
    allowHtml: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    id() {
      return this.value;
    }
  },
  methods: {
    onChange() {
      this.$emit('change', this.value);
    }
  }
};
</script>

<style>
.radio-list-option {
  width: 100%;
  min-height: 38px;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 6px 0 6px 8px;
  border-bottom: 1px solid #ddd;
}

.radio-list-option .radio {
  margin: 0;
}

.radio-list-option .radio.styled label {
  display: flex;
  align-items: center;
  min-height: inherit;
}

.radio-list-option label::after {
  top: inherit;
}

/* IE only fix for radio dot position */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .radio-list-option .radio.styled label::after {
    top: 3.5px;
  }
}
</style>
