import Vue from 'vue';
import store from '@/lib/store';
import AwardList from './components/AwardList';

export default function() {
  const awardList = new Vue({
    el: '#award-list',
    name: 'AwardListApp',
    components: {
      'award-list': AwardList
    },
    store
  });

  $(document).one('pjax:end', () => {
    awardList.$destroy();
  });
}
