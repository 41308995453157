/**
 * Updates the word and character counters.
 */
module.exports = {
    // Count words
    words: function (value) {
        return value
            .split(/&nbsp;|(^|\s+)[!\'"#$%&()*+,\-.\/\\:;<=>?@\[\]^_`{|}~«»\u2000-\u206F\u2E00-\u2E7F\s]+(\s+|$)|\s+/)
            .filter(function(value) {
                return value && value.trim();
            })
            .length;
    },

    // Count characters
    characters: function (value) {
        value = value.trim().replace(/&nbsp;/g, ' ');
        return value.length ? value.match(/./g).length : 0;
    }

};
