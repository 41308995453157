import * as marked from 'marked';

marked.setOptions({
	breaks: true, // Convert new line character to line break
});

const isMarkdown = (markdownOrHtml) => {
	if (typeof markdownOrHtml !== 'string' || !markdownOrHtml) {
		return false;
	}

	return markdownOrHtml.length && markdownOrHtml.charAt(0) !== '<';
};

/**
 * Convert markdown notation to html using marked.js library.
 */
const markdownToHtml = (markdown) => {
	if (!isMarkdown(markdown)) {
		throw new Error('Invalid markdown passed');
	}

	return marked.parse(markdown);
};

export { markdownToHtml, isMarkdown };
