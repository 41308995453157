<script>
var $ = require('jquery');
var tectoastr = require('tectoastr');

export default {
	name: 'SettingDomain',
	props: {
		seed: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			custom: '',
			errors: {
				vanity: { sub: null, root: null },
				custom: null,
			},
			redirect: false,
			status: { status: null, label: '' },
			timeout: {
				id: 0,
				delay: 5000,
			},
			vanity: {
				sub: '',
				root: '',
			},
		};
	},

	computed: {
		canRecheck() {
			return ['wait_dns', 'wait_certificate', 'failed'].indexOf(this.status.status) > -1;
		},
	},

	mounted() {
		this.parseSeed();
		this.startStatusUpdates();
	},

	methods: {
		// Parse seed data from server
		parseSeed() {
			const data = this.seed.clarify();

			this.custom = data.custom;
			this.redirect = data.redirect;
			this.status = Object.assign({}, this.status, data.status);
			this.vanity = Object.assign({}, this.vanity, data.vanity);
		},

		showLoader() {
			$('#loader').show().fadeTo(250, 0.8);
		},

		hideLoader() {
			$('#loader').fadeTo(250, 0, function () {
				$(this).hide();
			});
		},

		// Submit form :-)
		submit() {
			this.showLoader();

			$.ajax({
				type: 'POST',
				url: '/setting/domain',
				dataType: 'json',
				data: {
					vanitySub: this.vanity.sub,
					vanityRoot: this.vanity.root,
					custom: this.custom,
					redirect: this.redirect ? 1 : 0,
				},
				success: (response) => this.formSuccess(response),
				error: (response) => this.formError(response),
				complete: () => this.hideLoader(),
			});
		},

		// Handle form success
		formSuccess(response) {
			if (response.redirect) {
				window.location.href = response.redirect;
				return;
			}

			this.errors.vanity.sub = null;
			this.errors.vanity.sub = null;
			this.errors.custom = null;

			this.status = Object.assign({}, this.status, response.status);
			this.startStatusUpdates();
		},

		// Handle form error
		formError(error) {
			switch (error.status) {
				case 0:
					window.location.reload();
					return;
				case 422:
					// eslint-disable-next-line no-case-declarations
					const errors = error.responseJSON || {};
					this.errors.vanity.sub = (errors.vanitySub || []).shift();
					this.errors.vanity.root = (errors.vanityRoot || []).shift();
					this.errors.custom = (errors.custom || []).shift();
					return;
				default:
					return tectoastr.error(error.responseJSON.message || $('#lang-strings #alerts-generic').text());
			}
		},

		// Re-initiate the domain status checks
		recheckStatus() {
			this.showLoader();
			$.ajax({
				type: 'POST',
				url: '/setting/domain/status',
				dataType: 'json',
				success: () => this.startStatusUpdates(),
				error: (error) => this.formError(error),
				complete: () => this.hideLoader(),
			});
		},

		startStatusUpdates() {
			if (this.timeout.id) {
				clearTimeout(this.timeout.id);
			}

			if (!this.custom) {
				return;
			}

			this.timeout.delay = 5000;
			this.timeout.id = setTimeout(() => this.updateStatus(), this.timeout.delay);
		},

		// Update status indicator
		updateStatus() {
			$.ajax({
				type: 'GET',
				url: '/setting/domain/status',
				dataType: 'json',
				success: (response) => {
					this.status = Object.assign({}, this.status, response.status);
					this.timeout.delay += 1000; // slowly increase timeout to avoid spamming server
					this.timeout.id = setTimeout(() => this.updateStatus(), this.timeout.delay);
				},
				error: (response) => this.formError(response),
			});
		},
	},
};
</script>
