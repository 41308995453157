<script>
import { Typeahead, SearchField } from 'vue-bootstrap';
import FiltertronTray from '@/lib/components/Filtertron/FiltertronTray';
import ExportActionDropdown from '@/lib/components/Exports/ExportActionDropdown';
import SaveViewForm from '@/modules/search/components/SaveViewForm';
import SavedViewsList from '@/modules/search/components/SavedViewsList';
import KeywordSearch from './KeywordSearch';
import Storage from '@/lib/storage.js';
import ScoreSetSelector from '@/lib/components/Selectors/ScoreSetSelector.vue';
import LocalisedDatepicker from '@/lib/components/Shared/LocalisedDatepicker';
import langMixin from '@/lib/components/Translations/mixins/lang-mixin';

export default {
  components: {
    Typeahead,
    SearchField,
    FiltertronTray,
    ExportActionDropdown,
    SaveViewForm,
    SavedViewsList,
    KeywordSearch,
    ScoreSetSelector,
    LocalisedDatepicker
  },
  mixins: [langMixin],
  props: {
    searching: {
      type: Boolean,
      default: false
    },
    area: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      active: false,
      showSaveViewForm: false
    };
  },
  watch: {
    active() {
      this.$emit('toggled');
    }
  },
  mounted() {
    this.storage = new Storage();
    const active = this.storage.get('filtertron-active-' + this.area);
    this.active = this.searching && active === 'open';
  },
  methods: {
    toggle() {
      this.active = !this.active;
      this.storage.set('filtertron-active-' + this.area, this.active ? 'open' : 'closed');
    },
    submit(event) {
      $.pjax.submit(event);
    }
  }
};
</script>
