<template>
  <div ref="progressContainer" class="upload-progress-container">
    <div class="upload-progress" :style="{ width: progressBarWidth }"></div>
    <div class="upload-cancel">
      <a @click.prevent="$emit('canceled', file)">
        {{ lang.get('files.actions.cancel') }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['lang'],
  props: {
    file: {
      type: Object,
      required: true
    }
  },
  computed: {
    progressBarWidth() {
      return Math.round(this.maxWidth() * (this.file.percent / 100)) + 'px';
    }
  },
  methods: {
    maxWidth() {
      return this.$refs.progressContainer
        ? this.$refs.progressContainer.clientWidth
        : 0;
    }
  }
};
</script>
