<template>
  <div class="judging-shortcut" @mouseover="show" v-on-click-outside="hide"  tabindex=-1 @keyup.esc="hide">
    <a :href="route(url)" class="shortcut-link action">{{ action }}</a>
    <div v-if="hasMenu" class="forms-menu" :style="iconStyle">
    <dropdown
        class="first-level-menu"
        button-class="dropdown-toggle"
        menu-class="dropdown-plus"
    >
      <template slot="button" slot-scope="plus">
        <a class="shortcut-link plus-icon" :style="iconStyle" @click.prevent="openMenu('menu', plus, true)"><icon name="add"/></a>
      </template>

      <li v-for="action in actions">
        <a class="dropdown-menu-item" v-if="action.href" :href="action.href">{{action.name}}</a>
        <dropdown v-if="action.children" button-class="a-without-underline" menu-class="dropdown-submenu">

          <template slot="button" slot-scope="splus">
            <a class="dropdown-menu-item" @click.prevent="openMenu('submenu', splus)">{{action.name}}</a>
          </template>

          <li v-for="child in action.children">
            <a v-if="child.href" :href="child.href">{{child.name}}</a>
          </li>
        </dropdown>
      </li>

    </dropdown>
    </div>
    <div v-else class="first-level-menu">
    <a :href="actions[0].href" class="plus-icon" :style="iconStyle"><icon name="add"/></a>
    </div>
  </div>
</template>

<script>
import { route } from '@/lib/utils.js';
import Icon from '@/lib/components/Shared/Icon';
import isTouchDevice from '@/lib/is-touch-device.js';
import { Dropdown } from 'vue-bootstrap';
import { clickOutside } from 'vue-bootstrap';

export default {
  components: {
    Icon,
    Dropdown
  },
  mixins: [clickOutside],
  props: {
    url: {
      type: String,
      required: true
    },
    action: {
      type: String,
      required: true
    },
    actions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      menus: {},
      visible: false,
      expanded: false
    }
  },
  computed: {
    iconStyle() {
      return isTouchDevice() || (this.visible && this.expanded) ? 'display: inline-block' : ''
    },
    isTouchDevice() {
      return isTouchDevice()
    },
    hasMenu() {
      return this.actions.length > 1
    }
  },
  methods: {
    route,
    openMenu(menuName, menyContext, closeAll) {
      if(closeAll === true) {
        Object.keys(this.menus).forEach(menu => this.closeMenu(menu))
      } else if(this.menus[menuName] !== undefined) {
        this.closeMenu(menuName)
      }

      this.menus[menuName] = menyContext
      this.menus[menuName].open()
      this.expanded = true
    },
    closeMenu(menuName) {
      console.log(menuName);
      if(this.menus[menuName] !== undefined) {
        this.menus[menuName].close()
        delete this.menus[menuName]
      }
      this.expanded = false;
    },
    show() {
      this.visible = true
    },
    hide() {
      Object.keys(this.menus).forEach(menu => this.closeMenu(menu))
      this.expanded = false
      this.visible = false
    },
    toggle() {
      if(this.visible) this.show()
      else this.hide()
    }
  }
};
</script>

<style scoped>
  .judging-shortcut .plus-icon {
    text-align: center;
    width: 70px;
    font-size: 120%;
    width: auto;
    display: none;
  }

  .judging-shortcut .forms-menu {
    display: none;
  }

  .judging-shortcut:hover .forms-menu {
    display: inline-block;
  }

  .judging-shortcut:hover .plus-icon {
    display: inline-block;
  }

  .judging-shortcut a {
    text-decoration: none;
  }

  .first-level-menu {
    position: absolute;
    right: 30px;
    margin: 0px 5px;
    letter-spacing: 0;
  }

  .first-level-menu ul {
    top: 2em;
  }
</style>
