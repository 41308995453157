import TurndownService from 'turndown';

TurndownService.prototype.escape = html => html.replaceAll('<', '&lt;').replaceAll('>', '&gt;');

const options = {
  emDelimiter: '*',
  headingStyle: 'atx'
};
const turndownService = new TurndownService(options);

export function htmlToMarkdown(html) {
  return turndownService.turndown(html);
}
