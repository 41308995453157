import $ from 'jquery';
import _ from 'underscore';
import Audio from '@/lib/audio';
import AutoSaver from 'autosaver';
import Cards from '@/lib/cards';
import { emitSaveComment } from '@/lib/autosaver-comment';
import isMobile from '@/lib/navigation-is-mobile';
import { mountVueComponent } from '@/lib/mount-vue-component';
import tectoastr from '@/lib/tectoastr.js';
import VideoModal from '@/domain/services/VideoPlayer/VideoPlayerModal';
import VideoPlayer from '@/domain/services/VideoPlayer/VideoPlayer';
import VideoThumbnails from '@/lib/video-thumbnails';
import { commentUIBus, CommentUISignals } from '@/lib/components/Comments/signals/Comments';

export default function () {
	import('./components/EntryJudge').then((entryJudge) => {
		mountVueComponent('entry-judge', entryJudge);

		/**
		 * Handle conflictOfInterest checkbox
		 */
		var toggleConflictOfInterest = function () {
			var checked = $('form #conflictOfInterest').prop('checked');
			$('.conflictOfInterest-show').toggleClass('hidden', !checked);
		};

		/**
		 * Removes all error notices from scores.
		 */
		var clearErrors = function () {
			var scores = $('[name^="scores["]').parents('.form-group');

			scores.removeClass('error');
			scores.find('p#validation-error').remove();
		};

		/**
		 * Displays autosave error messages.
		 */
		var handleAutosaveErrors = function (response) {
			switch (response.status) {
				case 403:
					tectoastr.error(response.responseJSON.message);
					break;
				case 422:
					var errors = response.responseJSON;
					tectoastr.error(_.flatten(_.values(errors)).join('<br>'));
					highlightInvalidScores(errors);
					break;
				case 401:
					if (response.responseJSON.type === 'preview-mode') {
						tectoastr.error(response.responseJSON.message);
						autosaver.disable();
					}

					break;
			}
		};

		/**
		 * Adds visual indicators to scores that failed validations.
		 */
		var highlightInvalidScores = function (errors) {
			_.each(errors, function (message, id) {
				var score = $(`[name="${id}]"`).parents('.form-group');

				score.addClass('error');
				score.find('p#validation-error').remove();
				score.append('<p id="validation-error">' + _.flatten(_.values(message)).join('<br>') + '</p>');
			});
		};

		$('form #conflictOfInterest').on('click', toggleConflictOfInterest);
		toggleConflictOfInterest();

		/**
		 * AutoSaver
		 */
		var autosaver = new AutoSaver($('form.autosave'), clearErrors, handleAutosaveErrors);

		// Autosave comments
		commentUIBus.on(CommentUISignals.TYPING, () => autosaver.debouncedTriggerAutoSave());
		autosaver.setBeforeSaveCallback(emitSaveComment);

		// Auto save on browser close or page refresh
		window.addEventListener('beforeunload', autosaver.triggerAutoSave(), { once: true });

		// Auto save on pjax navigation
		$(document).one('pjax:beforeSend', () => autosaver.triggerAutoSave());

		$(document).one('pjax:end', () => {
			commentUIBus.off(CommentUISignals.TYPING);
			window.removeEventListener('beforeunload', autosaver.triggerAutoSave());
		});

		autosaver.setSupressWarnings(true);

		$('form.autosave .scoring-autosave[data-target]').on('click', function () {
			autosaver.setSupressWarnings(false);
			autosaver.saveClose($(this).data('target'));
		});

		// We want to trigger the autosave when the comment is saved to make sure the comment is immediately associated to the score
		$('form')
			.find('.comment-action-add')
			.on('commentsaved', () => autosaver.save());

		/**
		 * Cards
		 */
		var cards = new Cards();
		cards.setup('ul.cards:not(.vertical-gallery)', 'li', 'fitRows');

		/**
		 * Audio
		 */
		var audio = new Audio();
		audio.setup('jp-jplayer');

		/**
		 * Video
		 */
		var videoThumbnails = new VideoThumbnails();

		VideoPlayer.setup('video-js-standalone');
		VideoModal.setup('play-video');
		videoThumbnails.setup('preview-container.video');

		if (!isMobile()) {
			$('.header-controls').stickybits({
				useStickyClasses: true,
			});
		}
	});
}
