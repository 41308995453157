<template>
  <div class="radio-list">
    <panel>
      <div v-if="hasAnyOptions">
        <div
          v-for="(optionsList, header) of options"
          :key="header"
          class="radio-list-group"
        >
          <div
            v-if="header && optionsList.length"
            class="radio-list-option strong"
          >
            {{ header }}
          </div>
          <radio-list-option
            v-for="option of optionsList"
            :key="option.value"
            :text="option.text"
            :value="option.value"
            :checked="option.value === selected"
            :name="name"
            :header="header"
            :allow-html="allowHtml"
            @change="onChange"
          ></radio-list-option>
        </div>
      </div>
      <div v-else class="pam">
        {{ nothingFoundText }}
      </div>
    </panel>

    <slot></slot>
  </div>
</template>

<script>
import { Panel } from 'vue-bootstrap';
import { flatArray } from '../utils';
import RadioListOption from './RadioListOption';

export default {
  components: { Panel, RadioListOption },
  props: {
    options: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      default: null
    },
    selected: {
      type: String,
      default: null
    },
    nothingFoundText: {
      type: String,
      default: 'Reset'
    },
    resetText: {
      type: String,
      default: 'Reset'
    },
    allowHtml: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasAnyOptions() {
      const values = flatArray(Object.values(this.options));
      return !!(values.length && values[0].value);
    }
  },
  methods: {
    onChange(state) {
      this.$emit('change', state);
    },
    reset(value) {
      this.$emit('reset', value);
    }
  }
};
</script>

<style>
.radio-list {
  margin-bottom: 12px;
}

.radio-list > .panel {
  overflow-y: auto;
  height: 350px;
  margin-top: 4px;
  margin-bottom: 0;
  border: 1px solid #6f6f6f;
}

.radio-list .panel > .panel-body {
  padding: 0;
}
</style>
