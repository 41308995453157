<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import FeatureBox from './FeatureBox';

export default {
  extends: FeatureBox,
  props: {
    useShowHide: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('featureIntro', ['collapsed'])
  },
  watch: {
    // Watch collapsed store state and update local variables
    collapsed() {
      this.isCollapsed = this.collapsed;
      this.visible = !this.collapsed;
    }
  },
  created() {
    this.visible = !this.initCollapsed;
  },
  mounted() {
    this.setIntroState({
      collapsed: this.initCollapsed
    });
  },
  methods: {
    collapseStateChanged(collapsed) {
      this.changeCollapsedState({
        collapsed: collapsed,
        url: this.urlUpdateVisibility
      });

      this.updateSize();
    },
    ...mapMutations('featureIntro', ['setIntroState']),
    ...mapActions('featureIntro', ['changeCollapsedState'])
  }
};
</script>
