var _ = require('underscore');
var Columnator = require('@/lib/columnator.js');
var IndexView = require('@/lib/index-view.js');
export default function() {
  new Columnator();
  new IndexView();

  document.querySelectorAll('.primary-selector').forEach(primarySelector => {
    primarySelector.onchange = _.debounce(function(event) {
      const form = event.target.parentNode.querySelector('form');
      const checkedRadioButton = event.target;

      if (form && !checkedRadioButton.getAttribute('checked')) {
        form.submit();
      }

      const radioButtons = checkedRadioButton.closest('tbody').querySelectorAll(`[name="${checkedRadioButton.name}"]`);
      radioButtons.forEach(radioButton => radioButton.removeAttribute('checked'));
      checkedRadioButton.setAttribute('checked', 'checked');
    }, 20);
  });
}
