import $ from 'jquery';
import EntriesList from '../entries/components/EntriesList';
import { Popover } from 'vue-bootstrap';
import store from '@/lib/store';
import Vue from 'vue';

export default function () {
	const entriesList = new Vue({
		el: '#entries-list',
		components: {
			EntriesList,
			Popover,
		},
		store,
	});

	$(document).one('pjax:end', () => {
		entriesList.$destroy();
	});
}
