export const defaultState = () => ({
  contexts: [],
  baseMenu: {},
  mainMenu: {},
  selectedContext: {}
});

const state = defaultState();

export default state;
