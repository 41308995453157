import Vue from 'vue';
import GrantList from "./components/GrantList";
import store from '@/lib/store';

export default function() {
    const grantList = new Vue({
        el: '#grant-list',
        name: 'GrantApp',
        components: {
            'grant-list': GrantList
        },
        store
    });
    $(document).one('pjax:end', () => {
        grantList.$destroy();
    });
}
