<template>
  <div class="score-set-rounds">
    <p v-if="scoreSet.galleryStartDate">
      <a :href="scoreSetUrl(scoreSet.slug)">
        {{ lang.get('rounds.form.starts.label') }}<br />
        {{ scoreSet.galleryStartDate }}
      </a>
    </p>
    <p v-if="scoreSet.galleryEndDate">
      <a :href="scoreSetUrl(scoreSet.slug)">
        {{ lang.get('rounds.form.ends.label') }}<br />
        {{ scoreSet.galleryEndDate }}
      </a>
    </p>
  </div>
</template>

<script>
import { route } from '@/lib/utils.js';

export default {
  inject: ['lang'],
  props: {
    scoreSet: {
      type: Object,
      required: true
    },
    routes: {
      type: Object,
      required: true
    }
  },
  methods: {
    scoreSetUrl(scoreSetSlug) {
      return route(this.routes['score-set.edit'], { scoreSet: scoreSetSlug });
    }
  }
};
</script>
