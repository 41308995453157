export default {
  props: {
    tableFieldCalculationFunctions: {
      type: Array,
      default: () => [
        { id: 'sum', name: 'Sum' },
        { id: 'average', name: 'Average' },
        { id: 'count', name: 'Count' },
        { id: 'min', name: 'Min' },
        { id: 'max', name: 'Max' }
      ]
    }
  },
  provide() {
    return {
      calculationFunctions: this.tableFieldCalculationFunctions
    };
  }
};
