var $ = require('jquery');

/**
 * (Tabular) list view fields selector.
 */
module.exports = function () {
	/**
	 * Find all columnator elements.
	 *
	 * @type {*|jQuery|HTMLElement}
	 */
	var elements = $('[data-columnator]');

	/**
	 * Setup Popover on all columnator elements.
	 */
	elements.each(function () {
		$(this).popover({
			content: $(this).data('loading'),
			placement: 'right',
		});
	});

	/**
	 * Retrieve fields list on popover show.
	 */
	elements.on('show.bs.popover', function () {
		$.ajax({
			url: $(this).data('columnator'),
			context: this,
		}).done(function (data) {
			var element = $(this);
			element.data('bs.popover').tip().find('.popover-content').html(data);
			element.data('bs.popover').tip().find('.arrow').css('top', '16px');
			element
				.data('bs.popover')
				.tip()
				.find('.columnator-hide, button[type=submit]')
				.on('click', function () {
					element.popover('hide');
				});
		});
	});

	/**
	 * For PUT/PATCH/DELETE forms emulating hyperlink elements in <li> lists make sure dropdowns are closed on submit.
	 */
	$('.form-action-list').submit(function () {
		$('.dropdown').removeClass('open');
	});
};
