<template>
  <form class="row" method="post">
    <div class="col-xs-12 col-md-6 chapters-left-column">
      <emails-box
        :users-limit="usersLimit"
        :users-total="usersTotal"
        :errors="errors"
        :values="values.emails"
        @invalidate="onInvalidateEmails"
      ></emails-box>
      <roles-box
        :roles="roles"
        :errors="errors"
        :values="values.roles"
        @invalidate="onInvalidateRoles"
      ></roles-box>
      <message-box :value="values.message"></message-box>
      <div class="form-actions">
        <send-button
          :enabled="emailsAreValid && rolesAreValid && enabled"
        ></send-button>
      </div>
    </div>
  </form>
</template>

<script>
import langMixin from '@/lib/components/Translations/mixins/lang-mixin.js';
import EmailsBox from '@/lib/components/Shared/EmailsBox';
import RolesBox from './RolesBox';
import MessageBox from './MessageBox';
import SendButton from './SendButton';

export default {
  name: 'UsersInviteForm',
  components: {
    SendButton,
    EmailsBox,
    RolesBox,
    MessageBox
  },
  mixins: [langMixin],
  props: {
    usersLimit: {
      type: Number,
      default: 0
    },
    usersTotal: {
      type: Number,
      default: 0
    },
    roles: {
      type: Array,
      required: true
    },
    errors: {
      type: Object,
      default: () => {}
    },
    values: {
      type: Object,
      default: () => {}
    },
    enabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      emailsAreValid: false,
      rolesAreValid: false
    };
  },
  computed: {},
  methods: {
    onInvalidateEmails(valid) {
      this.emailsAreValid = valid;
    },
    onInvalidateRoles(valid) {
      this.rolesAreValid = valid;
    }
  }
};
</script>

<style scoped>
.help-text {
  display: block;
}
</style>
