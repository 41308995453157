<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="marker-action">
		<list-action-form ref="form" :ids="ids" :labels="labels" :route="route" :method="method" @submitted="toggle">
			<div v-if="!userMode">
				<input
					v-for="(inputEmail, index) in emails"
					:key="'email-' + index"
					type="hidden"
					:value="inputEmail"
					:name="`emails[${index}]`"
				/>
			</div>
			<div v-else>
				<input id="invitee-email" type="hidden" :value="user.email" name="emails[]" />
				<input type="hidden" value="1" name="userMode" />
			</div>
			<input type="hidden" name="formId" :value="selectedFormId" />
			<input type="hidden" name="role" :value="selectedRole" />
			<input type="hidden" name="chapter" :value="selectedChapter" />
			<input type="hidden" name="category" :value="selectedCategory" />
			<input type="hidden" name="message-markdown" :value="messageValue" />
			<input type="hidden" name="deadlineDate" :value="deadlineValue.datetime" />
			<input type="hidden" name="deadlineTimezone" :value="defaultTimezone" />
			<input type="hidden" name="notificationId" :value="notification" />
			<portal :to="name">
				<modal
					v-model="showModal"
					:header="false"
					:confirm-button-label="labels.title"
					:close-button-label="labels.cancel"
					:header-close-button="true"
					:confirm-on-enter="false"
					:confirm-disabled="!canSubmit"
					@closed="handleClose"
					@confirmed="submit"
				>
					<close-icon slot="before-content" @click.prevent="toggle"></close-icon>
					<div :key="name">
						<h4>
							<b>{{ labels.title }}</b>
						</h4>
						<div v-if="!invitedNotifications.length" id="currencies-warning" class="alert-warning island" role="alert">
							<div class="icon">
								<i class="af-icons-md af-icons-md-alert-warning"></i>
							</div>
							<!-- eslint-disable-next-line vue/no-v-html -->
							<div
								class="message"
								@click="hanldeRedirect"
								v-html="lang.get('notifications.missing', { notification: lang.get('notifications.triggers.entry_invited') })"
							></div>
						</div>
						<emails-box
							v-if="user === null"
							v-model="emails"
							:users-limit="usersLimit"
							:users-total="usersTotal"
							:errors="errors"
							:multiple-emails-help-message="lang.get('form.messages.multiple_emails')"
							@invalidate="onInvalidateEmails"
							@change="onEmailsChanged"
						></emails-box>
						<div v-else class="form-group">
							<label for="invitee-username" class="emails-label">
								{{ lang.get('form.form.name.label') }}
							</label>
							<input id="invitee-username" type="text" disabled="disabled" class="form-control" :value="userName" />
						</div>
						<div v-if="showFormSelector" class="form-group">
							<label for="formSelector">{{ labels.formSelector }}</label>
							<select-field
								id="formSelector"
								:value="selectedFormId"
								name="formId"
								:items="forms"
								:empty-option="false"
								:disabled="!userMode"
								@selected="onFormSelected"
							></select-field>
						</div>
						<div v-if="!userMode" class="form-group">
							<label for="roleSelector">{{ labels.roleSelector }}</label>
							<select-field
								id="roleSelector"
								name="role"
								:items="sortedRoles"
								:value="role"
								:empty-option="false"
								@selected="onRoleSelected"
							>
							</select-field>
						</div>
						<div v-if="chaptersForForm.length > 1" class="form-group">
							<label for="chapterSelector">{{ labels.chapterSelector }}</label>
							<select-field
								id="chapterSelector"
								:value="selectedChapter"
								name="chapter"
								:items="chaptersForForm"
								:empty-option="true"
								@selected="onChapterSelected"
							>
							</select-field>
						</div>
						<div class="form-group">
							<label for="categorySelector">
								{{ labels.categorySelector }}
							</label>
							<select-field
								id="categorySelector"
								:items="categoriesForChapter"
								:value="selectedCategory"
								:empty-option="true"
								name="category"
								aria-label="subcategory selector"
								@selected="onCategorySelected"
							/>
						</div>
						<div class="form-group">
							<label for="message">{{ labels.message }}</label>
							<text-editor
								id="message"
								:value="messageValue"
								name="message-markdown"
								:markdown-guide-label="lang.get('miscellaneous.markdown_guide.label')"
								@input="onMessageInput"
							/>
						</div>
						<div class="form-group">
							<label for="deadline">{{ labels.deadline }}</label>
							<localised-datepicker
								id="deadline"
								:value="deadlineValue"
								mode="datetime"
								:hidden-fields="true"
								:default-timezone="defaultTimezone"
								:preselect-current-date="false"
								:highlight-today="false"
								@changed="onDeadlineChanged"
							></localised-datepicker>
						</div>
						<div class="form-group">
							<label for="notificationId">{{ labels.notifications }}</label>
							<select-field
								id="notificationId"
								name="notificationId"
								:value="notificationId"
								:items="invitedNotifications"
								value-property="subject"
								:required="true"
								@selected="selectNotification"
							/>
						</div>
					</div>
				</modal>
			</portal>
		</list-action-form>
	</div>
</template>

<script>
import CloseIcon from './Partials/CloseIcon';
import EmailsBox from '@/lib/components/Shared/EmailsBox';
import ListAction from './ListAction.vue';
import LocalisedDatepicker from '@/lib/components/Shared/LocalisedDatepicker';
import { mapState } from 'vuex';
import modalMixin from './mixins/modal-mixin';
import { SelectField, Modal } from 'vue-bootstrap';
import TextEditor from '@/lib/components/Shared/editor/TextEditor.vue';

export default {
	components: {
		CloseIcon,
		SelectField,
		Modal,
		EmailsBox,
		TextEditor,
		LocalisedDatepicker,
	},
	inject: ['lang'],
	extends: ListAction,
	mixins: [modalMixin],
	props: {
		ids: {
			type: Array,
			default: () => [],
		},
		name: {
			type: String,
			required: true,
			validator: (value) => ['form-inviter'].indexOf(value) !== -1,
		},
		email: {
			type: String,
			default: null,
		},
		roles: {
			type: Array,
			required: true,
		},
		role: {
			type: String,
			default: '',
		},
		chapters: {
			type: Array,
			required: true,
		},
		chapter: {
			type: [Number, String],
			default: '',
		},
		categories: {
			type: Array,
			required: true,
		},
		category: {
			type: [Number, String],
			default: '',
		},
		message: {
			type: String,
			default: '',
		},
		deadline: {
			type: String,
			default: '',
		},
		usersLimit: {
			type: Number,
			default: 255,
		},
		usersTotal: {
			type: Number,
			default: 0,
		},
		formId: {
			type: [Number, String],
			default: '',
		},
		forms: {
			type: Array,
			default: () => [],
		},
		user: {
			type: Object,
			default: null,
		},
		chapterOption: {
			type: String,
			default: null,
		},
		showFormSelector: {
			type: Boolean,
			required: true,
		},
		notificationId: {
			type: Number,
			default: null,
		},
		invitedNotifications: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			selectedRole: this.role,
			emails: [],
			selectedChapter: parseInt(this.chapter),
			selectedCategory: parseInt(this.category),
			selectedFormId: parseInt(this.formId),
			selectedFormChapterOption: this.chapterOption,
			emailsAreValid: false,
			messageValue: this.message,
			deadlineValue: {
				datetime: this.deadline,
			},
			errors: {},
			isVisible: false,
			notification: null,
		};
	},
	computed: {
		...mapState('global', ['defaultTimezone']),
		categoriesForChapter() {
			return this.categories.filter(
				(category) =>
					this.selectedChapter !== '' &&
					category.chapters.indexOf(this.selectedChapter) !== -1 &&
					category.formId === this.selectedFormId
			);
		},
		chaptersForForm() {
			return this.chapters.filter(
				(chapter) =>
					this.selectedFormId !== '' &&
					(this.selectedFormChapterOption === 'all' || chapter.forms.indexOf(this.selectedFormId) !== -1)
			);
		},
		userMode() {
			return this.user !== null;
		},
		userName() {
			if (this.user === null) {
				return '';
			}

			if (this.user.fullName.trim() === '') {
				return this.user.email;
			}

			return this.user.fullName;
		},
		canSubmit() {
			if (this.userMode) {
				return !!this.selectedFormId;
			}

			return this.emails.length && this.emailsAreValid && !!this.selectedRole;
		},
		sortedRoles() {
			return [...this.roles].sort((a, b) => a.name.localeCompare(b.name));
		},
	},
	methods: {
		onInvalidateEmails(valid) {
			this.emailsAreValid = valid;
		},
		onEmailsChanged(emails) {
			this.emails = emails;
		},
		onFormSelected(e, v) {
			v = parseInt(v);
			const form = this.forms.find((form) => form.id === v);
			this.selectedFormId = v;
			this.selectedCategory = '';
			this.selectedFormChapterOption = form.chapterOption;
			this.selectedChapter = this.chaptersForForm.length > 1 ? '' : this.chaptersForForm[0].id;
		},
		onChapterSelected(e, v) {
			this.selectedChapter = v;
			if (this.categoriesForChapter.indexOf(this.selectedCategory) === -1) {
				this.selectedCategory = '';
			}
		},
		onCategorySelected(e, v) {
			this.selectedCategory = v;
		},
		onMessageInput(value) {
			this.messageValue = value;
		},
		onRoleSelected(e, v) {
			this.selectedRole = v;
		},
		onDeadlineChanged(v) {
			this.deadlineValue.datetime = v;
		},
		selectNotification(name, value) {
			this.notification = value;
		},
		handleClose() {
			this.reviewStage = null;
			this.modalClosed();
		},
		hanldeRedirect(event) {
			if (event.target.tagName === 'A') {
				this.toggle();
			}
		},
	},
};
</script>
