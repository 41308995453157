<template>
  <div class="widget">
    <header v-if="header">
      <slot name="cover"></slot>
      <div :class="{ container: container, 'no-margin': noMargin }">
        <div class="mt-dropdown">
          <slot name="overflow"></slot>
        </div>
        <h2 class="mt-title">
          <slot name="heading"></slot>
        </h2>
      </div>
    </header>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    header: {
      type: Boolean,
      default: true
    },
    container: {
      type: Boolean,
      default: false
    },
    noMargin: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.widget-pd0 {
  padding: 0;
}

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-direction: row;
  padding: 10px 10px 20px 20px;
}

.mt-title {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 15px;
}

.container > .mt-title {
  margin-left: 15px;
}

.no-margin > .mt-title {
  margin-left: 1px;
}

.mt-dropdown {
  position: absolute;
  letter-spacing: 0;
}

.mt-dropdown::v-deep .af-icons {
  font-size: 24px;
}

.mt-dropdown::v-deep ul {
  top: 2em;
}
</style>
