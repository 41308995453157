import { mountVueComponent } from '@/lib/mount-vue-component';

var $ = require('jquery');

export default function () {
	import('@/modules/content-block/component/ContentBlockForm.vue').then((contentBlockForm) => {
		mountVueComponent('content-block-form', contentBlockForm);

		var contentBlocksWithType = {};

		var roleSelection = function () {
			var value = $('input[name=roleVisibility]:checked').val();

			if (value === 'select') {
				$('#roleVisibilityContainer').show();
			} else {
				$('#roleVisibilityContainer').hide();
			}
		};

		$('input[name=roleVisibility]').change(roleSelection);

		const displayVisibilitySelector = function () {
			if (Object.keys(contentBlocksWithType).length === 0) {
				$.ajax({
					type: 'GET',
					url: '/content/block/type/all',
					dataType: 'json',
				}).success(function (data) {
					contentBlocksWithType = data['content-blocks'];
					toggleVisibilitySelector();
					toggleRoleVisibilitySelector();
				});
			} else {
				toggleVisibilitySelector();
				toggleRoleVisibilitySelector();
			}
		};

		const toggleVisibilitySelector = function () {
			const locationKey = $('#inputContentBlockKey').val();

			if (contentBlocksWithType[locationKey] && contentBlocksWithType[locationKey].type === 'info-box') {
				$('.content-block-visibility').removeClass('hide').show();
			} else {
				$('#visibility').val('open');
				$('.content-block-visibility').hide();
			}
		};

		const toggleRoleVisibilitySelector = function () {
			const locationKey = $('#inputContentBlockKey').val();

			if (contentBlocksWithType[locationKey] && contentBlocksWithType[locationKey]['enable-roles-based'] === true) {
				$('.content-block-role-visibility').removeClass('hide').show();
			} else {
				$('.content-block-role-visibility').hide();
			}
		};

		$('#inputContentBlockKey').on('change', function () {
			displayVisibilitySelector();
			toggleRoleVisibilitySelector();
			roleSelection();
		});

		displayVisibilitySelector();
		toggleRoleVisibilitySelector();
		roleSelection();
	});
}
