<template>
  <div class="marker-action">
    <list-action-form
      ref="form"
      :ids="ids"
      :labels="labels"
      :route="route"
      :method="method"
      :show-submit="showLabel"
      @submitted="toggle"
    >
      <input type="hidden" name="reportFormId" :value="reportFormId" />
      <input type="hidden" name="dueDate" :value="dueDate.datetime" />
      <input type="hidden" name="dueDateTimezone" :value="dueDate.timezone" />
      <portal :to="name">
        <modal
          v-model="showModal"
          :header="false"
          :confirm-button-label="labels.ok"
          :confirm-disabled="disableConfirm"
          :close-button-label="labels.cancel"
          :header-close-button="true"
          :confirm-on-enter="false"
          @closed="modalClosed"
          @confirmed="toggleAndSubmit"
        >
          <close-icon
            slot="before-content"
            @click.prevent="toggle"
          ></close-icon>
          <div>
            <div>
              <div v-if="showReportSelector">
                <div class="form-group">
                  <label>
                    {{ labels.from }}
                  </label>
                  <select-field
                    name="reportForms"
                    :items="reportForms"
                    :empty-option="false"
                    id-property="id"
                    value-property="name"
                    @selected="(name, id) => (reportFormId = id)"
                  />
                </div>
              </div>
              <div class="form-group">
                <label>
                  {{ labels.dueDate }}
                </label>
                <localised-datepicker
                  :id="getId"
                  :name="getId"
                  mode="datetime"
                  :right-aligned="true"
                  :include-timezone="false"
                  :preselect-current-date="true"
                  :highlight-today="false"
                  :disabled="false"
                  :value="this.dueDate"
                  @update:value="value => this.dueDate = value"
                />
              </div>
            </div>
          </div>
        </modal>
      </portal>
    </list-action-form>
  </div>
</template>

<script>
import ListAction from './ListAction.vue';
import { SelectField, Modal } from 'vue-bootstrap';
import LocalisedDatepicker from '@/lib/components/Shared/LocalisedDatepicker';
import CloseIcon from './Partials/CloseIcon';
import langMixin from '../Translations/mixins/lang-mixin';
import modalMixin from './mixins/modal-mixin';

export default {
  inject: ['lang'],
  components: {
    LocalisedDatepicker,
    Modal,
    CloseIcon,
    SelectField
  },
  extends: ListAction,
  mixins: [langMixin, modalMixin],
  props: {
    name: {
      type: String,
      required: true
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    reportForms: {
      type: Array,
      required: true
    },
    showReportSelector: {
      type: Boolean,
      default: true
    },
    currentDueDate: {
      type: Object,
      default: () => ({ datetime: '', timezone: '' })
    }
  },
  data() {
    return {
      reportFormId: null,
      dueDate: {
        datetime: '',
        timezone: ''
      }
    };
  },
  computed: {
    getId() {
      return this.reportFormId + '-dueDate';
    },
    disableConfirm() {
      return (!this.reportFormId && this.showReportSelector) || !this.dueDate.datetime;
    }
  },
  mounted() {
    this.dueDate = this.currentDueDate;
  },
  methods: {
    toggleAndSubmit() {
      this.toggle();

      if (this.showLabel) {
        this.submit();
      }

      this.$emit('submitted', {
        reportFormId: this.reportFormId,
        dueDate: this.dueDate
      });
    }
  }
};
</script>

<style scoped lang="scss">
.marker-action-schedule-report {
  .buttons {
    margin-left: 10px;
    display: inline-block;
    .btn {
      &:first-child {
        margin-left: 0;
      }
      margin-right: 0;
      margin-left: 10px;
    }
  }
}
</style>
