<template>
	<div class="marker-action">
		<list-action-form ref="form" :ids="ids" :labels="labels" :route="route" :method="method" @submitted="toggle">
			<input type="hidden" name="updatedAt" :value="document.updatedAt" />
			<input type="hidden" name="shared" :value="shared ? 1 : 0" />
			<input
				v-for="language in supportedLanguages"
				:key="language"
				type="hidden"
				:name="'translated[name][' + language + ']'"
				:value="translated[language] ? translated[language]['name'] : ''"
			/>
			<portal :to="name">
				<modal
					v-model="showModal"
					:header="false"
					:confirm-button-label="labels.title"
					:close-button-label="labels.cancel"
					:header-close-button="true"
					:confirm-on-enter="false"
					:confirm-disabled="false"
					@closed="modalClosed"
					@confirmed="submit"
				>
					<close-icon slot="before-content" @click.prevent="toggle"></close-icon>

					<slot></slot>

					<div class="form-group">
						<label for="documentName">{{ labels.documentName }}</label>
						<multilingual
							labelled-by="documentName"
							:required="true"
							:resource="{ translated: translated }"
							:supported-languages="supportedLanguages"
							property="name"
							@input="onTranslated"
						/>
					</div>

					<div>
						<checkbox
							name="sendNotifications"
							:selected="shared"
							:value="shared"
							:aria-label="labels.shared"
							@change="toggleShared"
						>
							<template slot="label">{{ labels.shared }}</template>
						</checkbox>
					</div>
				</modal>
			</portal>
		</list-action-form>
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import ListAction from '@/lib/components/ListActions/ListAction.vue';
import ListActionForm from '@/lib/components/ListActions/ListActionForm.vue';
import CloseIcon from '@/lib/components/ListActions/Partials/CloseIcon.vue';
import modalMixin from '@/lib/components/ListActions/mixins/modal-mixin';
import Multilingual from '@/lib/components/Translations/Multilingual.vue';
import { Modal, Checkbox } from 'vue-bootstrap';
import { editDocumentController } from '@/modules/documents/components/EditDocument.controller';

export default defineComponent({
	name: 'EditDocument',
	components: { Modal, CloseIcon, ListActionForm, Multilingual, Checkbox },
	extends: ListAction,
	mixins: [modalMixin],
	props: {
		name: {
			type: String,
			required: true,
			validator: (value) => ['edit-document'].indexOf(value) !== -1,
		},
		document: {
			type: Object,
			required: true,
		},
	},
	setup: editDocumentController,
});
</script>
