var $ = require('jquery');

module.exports = function () {
	// eslint-disable-next-line no-undef
	var currencySymbols = currencySymbolMap.clarify();
	var currencyField = $('#currency');

	var updateCurrencySymbol = function () {
		var code = currencyField.val();
		// eslint-disable-next-line no-prototype-builtins
		var symbol = currencySymbols.hasOwnProperty(code) ? currencySymbols[code] : code;
		$('#currency-symbol').text(symbol);
	};

	updateCurrencySymbol();
	$('#currency').on('change', updateCurrencySymbol);
};
