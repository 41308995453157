var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.videoUrl || _vm.imageUrl)?_c('div',{class:[
    'col-xs-12',
    'col-sm-4',
    'col-md-4',
    'col-lg-3',
    'feature-video-or-image-wrapper'
  ]},[(_vm.showVideo)?_c('div',{ref:"videoWrapper",staticClass:"feature-box-video-wrapper"},[(_vm.videoWidth && _vm.videoHeight)?_c('video-embedded',{ref:"video",attrs:{"url":_vm.videoUrl,"width":_vm.videoWidth,"height":_vm.videoHeight}}):_vm._e()],1):(_vm.imageUrl)?_c('div',{staticClass:"feature-box-image-wrapper"},[_c('a',{staticClass:"strip",attrs:{"href":_vm.imageBigUrl || _vm.imageUrl,"target":"_blank","rel":"noopener noreferrer"}},[_c('img',{attrs:{"src":_vm.imageUrl}})])]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }