import Vue from 'vue';

export default {
  changePendingUploadsBy(state, change) {
    Vue.set(state, 'pendingUploads', state.pendingUploads + change);
  },

  changeCommentsBeingEdited(state, change) {
    Vue.set(state, 'commentsEdited', state.commentsEdited + change);
  },

  storeUserId(state, user) {
    Vue.set(state, 'userId', user);
  }
};
