import Vue from 'vue';
import _ from 'underscore';
import buildFormData from '@/lib/formdata';
import { extractErrorMessages } from '@/lib/ajax';
import { newOrder } from './helpers';

const toastr = require('toastr');

export default {
  dropOnTheTop({ state, commit, dispatch }, { tabSlug, tabId }) {
    const field = state.draggedField;
    commit('setDraggedField', null);

    if (state.tabsLocked || !field || field.resource !== 'Entries') {
      return;
    }

    commit('entryForm/selectTab', tabId, { root: true });

    dispatch('associateFieldWithTab', {
      resource: field.resource,
      tabId: field.tabId,
      tabSlug: tabSlug,
      fieldSlug: field.fieldSlug
    });
  },

  /**
   * Save tab order.
   */
  saveTabOrder({ commit }, { tabs }) {
    const saveTabOrderUrl = '/entry-form/manager/tabs/order';
    const order = newOrder(tabs);

    if (_.isEmpty(order)) {
      return;
    }

    // Optimistic update, avoid jerky UI
    commit('entryForm/reorderTabs', order, { root: true });

    return Vue.prototype.$http
      .post(saveTabOrderUrl, {
        tabs: tabs.map(tab => tab.id)
      })
      .then(
        response => {
          commit('entryForm/reorderTabs', response.data.order || {}, {
            root: true
          });
        },
        () => {
          commit(
            'entryForm/storeStatus',
            {
              status: 'error',
              message: 'entries.form.errors.problem-saving-tab-order'
            },
            { root: true }
          );
        }
      );
  },

  /**
   * Save field order.
   */
  saveFieldOrder({ commit }, { fields }) {
    const saveFieldOrderUrl = '/entry-form/manager/fields/order';
    const order = newOrder(fields);

    if (_.isEmpty(order)) {
      return;
    }

    // Optimistic update, avoid jerky UI
    commit('entryForm/reorderFields', order, { root: true });

    return Vue.prototype.$http
      .post(saveFieldOrderUrl, {
        fields: fields.map(field => field.id)
      })
      .then(
        response => {
          commit('entryForm/reorderFields', response.data.order || {}, {
            root: true
          });
        },
        () => {
          commit(
            'entryForm/storeStatus',
            {
              status: 'error',
              message: 'entries.form.errors.problem-saving-field-order'
            },
            { root: true }
          );
        }
      );
  },

  addField({ dispatch, rootGetters }, { field }) {
    const fieldData = rootGetters['entryForm/fieldData'](field);
    const route = `/entry-form/manager/fields`;

    dispatch('fieldRequest', { field, route, data: fieldData });
  },

  saveField({ dispatch, rootGetters }, { field }) {
    const fieldData = rootGetters['entryForm/fieldData'](field);
    const route = `/entry-form/manager/fields/${field.slug}`;

    dispatch('fieldRequest', { field, route, data: fieldData, method: 'put' });
  },

  deleteField({ dispatch }, { field }) {
    const route = `/entry-form/manager/fields/${field.slug}`;

    return dispatch('fieldRequest', { field, route, method: 'delete' });
  },

  handleError({ commit }, { error }) {
    if (
      error.response &&
      error.response.status === 422 &&
      error.response.data
    ) {
      extractErrorMessages(error.response.data).forEach(e => {
        toastr.error(e);
      });
    } else {
      commit(
        'entryForm/storeStatus',
        {
          status: 'error',
          message: 'entries.form.errors.problem-saving-configuration'
        },
        { root: true }
      );
    }

    commit('setSavingFlag', false);
  },

  fieldRequest({ state, commit, dispatch }, { field, route, data, method }) {
    commit('setSavingFlag', true);

    const formData = buildFormData(data);
    formData.append('_method', method || 'post');

    return Vue.prototype.$http.post(route, formData).then(
      response => {
        if (response.data.field) {
          commit(
            'entryForm/replaceField',
            {
              oldField: field,
              newField: response.data.field
            },
            { root: true }
          );
          if (
            !state.conditionalFieldsList.some(
              field => field.id === response.data.field.id
            )
          ) {
            commit('addToConditionalFieldsList', {
              id: response.data.field.id,
              resource: response.data.field.resource,
              title: response.data.field.title,
              type: response.data.field.type
            });
          }
          commit('setConfigurationInProgressFlag', false);
          commit('preserveField', null);
          commit('configureField', null);
        } else {
          commit('deleteFromConditionalFieldsList', field);
        }

        commit('setSavingFlag', false);

        return response;
      },
      error => dispatch('handleError', { error })
    );
  },

  tabRequest({ commit, dispatch }, { tab, route, data, method }) {
    commit('setSavingFlag', true);

    const formData = buildFormData(data);
    formData.append('_method', method || 'post');

    return Vue.prototype.$http.post(route, formData).then(
      response => {
        if (response.data.tab) {
          commit(
            'entryForm/replaceTab',
            {
              oldTab: tab,
              newTab: response.data.tab
            },
            { root: true }
          );

          commit('setConfigurationInProgressFlag', false);
          commit('preserveTab', null);
          commit('configureTab', null);

          // Immediately switch to the replaced tab
          commit('entryForm/selectTabBySlug', response.data.tab.slug, {
            root: true
          });
        }

        commit('setSavingFlag', false);

        return response;
      },
      error => dispatch('handleError', { error })
    );
  },

  categoryRequest({ commit, dispatch, rootGetters }, { route, data, method }) {
    commit('setSavingFlag', true);

    const formData = buildFormData(data);
    formData.append('_method', method || 'post');

    return Vue.prototype.$http.post(route, formData).then(
      response => {
        const category = response.data.category;
        const allCategories = response.data.categories;

        commit('setSavingFlag', false);

        commit('setConfigurationInProgressFlag', false);
        commit('preserveCategory', null);
        commit('configureCategory', null);

        commit('entryForm/flushLoadedChapters', null, { root: true });

        // Update all categories
        commit('entryForm/updateCategories', allCategories, { root: true });

        // Since the parent/child relationships might have changed by this point
        // we are going to reload the chapter categories.
        dispatch(
          'entryForm/loadChapter',
          { reload: true },
          { root: true }
        ).then(response => {
          // Select the newly created category
          if (response && response.status === 200 && category) {
            commit('entryForm/selectCategory', category.id, { root: true });
            return;
          }

          // Deselect category if it's no longer visible as a result of configuration
          // changes (such as when it's detached from the selected chapter)
          if (!rootGetters['entryForm/selectedCategory']) {
            commit('entryForm/selectCategory', null, { root: true });
          }
        });

        return response;
      },
      error => dispatch('handleError', { error })
    );
  },

  chapterRequest(
    { commit, dispatch, rootState },
    { chapter, route, data, method }
  ) {
    commit('setSavingFlag', true);

    const formData = buildFormData(data);
    formData.append('_method', method || 'post');

    return Vue.prototype.$http.post(route, formData).then(
      response => {
        if (response.data.chapter) {
          commit(
            'entryForm/replaceChapter',
            {
              oldChapter: chapter,
              newChapter: response.data.chapter
            },
            { root: true }
          );

          commit('setConfigurationInProgressFlag', false);
          commit('preserveChapter', null);
          commit('configureChapter', null);

          // Immediately switch to the replaced chapter
          commit('entryForm/setChapterId', response.data.chapter.id, {
            root: true
          });
        }

        commit('setSavingFlag', false);

        return response;
      },
      error => dispatch('handleError', { error })
    );
  },

  /**
   * Associate field with tab.
   */
  associateFieldWithTab(
    { commit, rootState },
    { resource, tabId, tabSlug, fieldSlug }
  ) {
    const associateFieldWithTabUrl = `/entry-form/manager/associate/${fieldSlug}/${tabSlug}`;

    const fields =
      rootState['entryForm'].fields
        // eslint-disable-next-line eqeqeq
        .filter(f => f.tabId == tabId)
        .filter(f => f.resource === resource) || [];

    return Vue.prototype.$http
      .post(associateFieldWithTabUrl, {
        fields: fields.map(field => field.id)
      })
      .then(
        () => {
          commit(
            'entryForm/moveFieldToTab',
            {
              tabSlug: tabSlug,
              fieldSlug: fieldSlug
            },
            { root: true }
          );
        },
        () => {
          commit(
            'entryForm/storeStatus',
            {
              status: 'error',
              message: 'entries.form.errors.problem-associating-field-with-tab'
            },
            { root: true }
          );
        }
      );
  },

  /**
   * Restore configuration.
   */
  restoreConfiguration({ dispatch }) {
    return Promise.all([
      dispatch('restoreFieldConfiguration'),
      dispatch('restoreTabConfiguration'),
      dispatch('restoreCategoryConfiguration'),
      dispatch('restoreChapterConfiguration'),
      dispatch('restoreFormConfiguration')
    ]);
  },

  /**
   * Restore field configuration.
   */
  restoreFieldConfiguration({ commit, state }) {
    if (state.configuredField === 'newfield') {
      commit('entryForm/removeField', state.configuredField, { root: true });
    } else if (state.configurationInProgress && state.preservedField) {
      commit('entryForm/updateField', state.preservedField, { root: true });
      commit('preserveField', null);
    }
    commit('configureField', null);
  },

  /**
   * Restore tab configuration.
   */
  restoreTabConfiguration({ commit, state }) {
    if (state.configuredTab === 'newtab') {
      commit('entryForm/removeTab', state.configuredTab, { root: true });
    } else if (state.configurationInProgress && state.preservedTab) {
      commit('entryForm/updateTab', state.preservedTab, { root: true });
      commit('preserveTab', null);
    }
    commit('configureTab', null);
  },

  /**
   * Restore category configuration.
   */
  restoreCategoryConfiguration({ commit, state }) {
    if (state.configuredCategory === 'newcategory') {
      commit('entryForm/removeCategory', state.configuredCategory, {
        root: true
      });
      commit('entryForm/selectCategory', null, { root: true });
    } else if (state.configurationInProgress && state.preservedCategory) {
      commit('entryForm/updateCategory', state.preservedCategory, {
        root: true
      });
      commit('preserveCategory', null);
    }
    commit('configureCategory', null);
  },

  /**
   * Restore chapter configuration.
   */
  restoreChapterConfiguration({ commit, state }) {
    if (state.configuredChapter === 'newchapter') {
      commit('entryForm/removeChapter', state.configuredChapter, {
        root: true
      });
    } else if (state.configurationInProgress && state.preservedChapter) {
      commit('entryForm/updateChapter', state.preservedChapter, { root: true });
      commit('preserveChapter', null);
    }
    commit('configureChapter', null);
  },

  /**
   * Restore form configuration.
   */
  restoreFormConfiguration({ commit, state }) {
    if (state.configurationInProgress && state.preservedForm) {
      commit('entryForm/setForm', state.preservedForm, { root: true });
      commit('preserveForm', null);
    }
    commit('configureForm', null);
  },

  /**
   * Configure field.
   */
  configureField({ commit, dispatch }, { slug }) {
    dispatch('restoreConfiguration').then(() => {
      commit('setConfigurationInProgressFlag', false);
      commit('configureField', slug);
    });
  },

  /**
   * Configure tab.
   */
  configureTab({ commit, dispatch }, { slug }) {
    dispatch('restoreConfiguration').then(() => {
      commit('setConfigurationInProgressFlag', false);
      commit('configureTab', slug);

      // Immediately switch to the configured tab
      commit('entryForm/selectTabBySlug', slug, { root: true });
    });
  },

  /**
   * Configure category.
   */
  configureCategory({ commit, dispatch }, { slug }) {
    dispatch('restoreConfiguration').then(() => {
      commit('setConfigurationInProgressFlag', false);
      commit('configureCategory', slug);

      // Select the configured category
      if (slug === 'newcategory') {
        commit('entryForm/selectCategory', 0, { root: true });
      }
    });
  },

  /**
   * Configure chapter.
   */
  configureChapter({ commit, dispatch }, { slug }) {
    dispatch('restoreConfiguration').then(() => {
      commit('setConfigurationInProgressFlag', false);
      commit('configureChapter', slug);
    });
  },

  /**
   * Configure form.
   */
  configureForm({ commit, dispatch }, { slug }) {
    dispatch('restoreConfiguration').then(() => {
      commit('setConfigurationInProgressFlag', false);
      commit('configureForm', slug);
    });
  },

  addTab({ dispatch, rootGetters }, { tab }) {
    const tabData = rootGetters['entryForm/tabData'](tab);
    const route = `/entry-form/manager/tabs`;

    dispatch('tabRequest', { tab, route, data: tabData });
  },

  saveTab({ dispatch, rootGetters }, { tab }) {
    const tabData = rootGetters['entryForm/tabData'](tab);
    const route = `/entry-form/manager/tabs/${tab.slug}`;

    dispatch('tabRequest', { tab, route, data: tabData, method: 'put' });
  },

  deleteTab({ dispatch }, { tab }) {
    const route = `/entry-form/manager/tabs/${tab.slug}`;

    return dispatch('tabRequest', { tab, route, method: 'delete' });
  },

  addCategory({ dispatch, rootGetters }, { category }) {
    const parent = rootGetters['entryForm/categoriesIndex'].find(
      c => c.id === category.parentId
    );
    const categoryData = rootGetters['entryForm/newCategoryData'](
      category,
      parent
    );
    const route = '/entry-form/manager/categories';

    dispatch('categoryRequest', { category, route, data: categoryData });
  },

  saveCategory({ dispatch, rootGetters }, { category }) {
    const parent = rootGetters['entryForm/categoriesIndex'].find(
      c => c.id === category.parentId
    );
    const categoryData = rootGetters['entryForm/categoryData'](
      category,
      parent
    );
    const route = `/entry-form/manager/categories/${category.slug}`;

    dispatch('categoryRequest', {
      category,
      route,
      data: categoryData,
      method: 'put'
    });
  },

  deleteCategory({ dispatch }, { category }) {
    const route = `/entry-form/manager/categories/${category.slug}`;

    return dispatch('categoryRequest', { category, route, method: 'delete' });
  },

  addChapter({ dispatch, rootGetters }, { chapter }) {
    const chapterData = rootGetters['entryForm/chapterData'](chapter);
    const route = `/entry-form/manager/chapters`;

    dispatch('chapterRequest', { chapter, route, data: chapterData });
  },

  saveChapter({ dispatch, rootGetters}, { chapter }) {
    const chapterData = rootGetters['entryForm/chapterData'](chapter);
    const route = `/entry-form/manager/chapters/${chapter.slug}`;

    dispatch('chapterRequest', {
      chapter,
      route,
      data: chapterData,
      method: 'put'
    });
  },

  deleteChapter({ dispatch }, { chapter }) {
    const route = `/entry-form/manager/chapters/${chapter.slug}`;

    return dispatch('chapterRequest', { chapter, route, method: 'delete' });
  },

  saveForm({ dispatch, rootGetters }, { form }) {
    const data = rootGetters['entryForm/currentFormData'](form);
    const route = `/entry-form/manager/form/${form.slug}`;

    dispatch('formRequest', { form, route, data, method: 'put' });
  },

  formRequest({ commit, dispatch }, { form, route, data, method }) {
    commit('setSavingFlag', true);

    const formData = buildFormData(data);
    formData.append('_method', method || 'post');

    return Vue.prototype.$http.post(route, formData).then(
      response => {
        if (response.data.form) {
          commit('entryForm/setForm', response.data.form, { root: true });
          commit('entryForm/updateFormChapters', response.data.form.chapters, {
            root: true
          });
          commit('setConfigurationInProgressFlag', false);
          commit('preserveForm', null);
          commit('configureForm', null);
        }

        commit('setSavingFlag', false);

        return response;
      },
      error => dispatch('handleError', { error })
    );
  },

  conditionalFieldsListRequest({ commit }, { route }) {
    return Vue.prototype.$http.get(route).then(
      response => {
        commit(
          'entryFormConfiguration/saveConditionalFieldsList',
          response.data,
          { root: true }
        );
      },
      () => {
        commit(
          'entryForm/storeStatus',
          {
            status: 'error',
            message: 'entries.form.errors.problem-retrieving-fields'
          },
          { root: true }
        );
      }
    );
  },

  loadConditionalFieldsList({ state, rootGetters, dispatch }) {
    if (state.conditionalFieldsList.length === 0) {

      const route =
        '/entry-form/manager/fields-for-conditional/' +
        (state.entry ? state.entry.slug : '/') +
        '?formId=' + rootGetters['entryForm/data'].formId
      dispatch('conditionalFieldsListRequest', { route });
    }
  }
};
