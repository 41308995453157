<script>
import FiltertronSearch from './Filtertron/FiltertronSearch';
import DataTable from './DataTable.vue';
import AddActionDropdown from './ListActions/AddActionDropdown.vue';
import ListActionDropdown from './ListActions/ListActionDropdown.vue';
import SeasonSelector from './Selectors/SeasonSelector.vue';
import FormSelector from './Selectors/FormSelector.vue';
import StateSelector from './Selectors/StateSelector.vue';
import SavedViewsShortcutsBar from '../../modules/search/components/SavedViewsShortcutsBar';
import Deletism from './ListActions/Deletism';
import Undeletism from './ListActions/Undeletism';
import ScoreSetSelector from '@/lib/components/Selectors/ScoreSetSelector.vue';

/**
 * The base ResourceList component.
 */
export default {
	components: {
		FiltertronSearch,
		DataTable,
		AddActionDropdown,
		ListActionDropdown,
		SeasonSelector,
		FormSelector,
		StateSelector,
		SavedViewsShortcutsBar,
		Deletism,
		Undeletism,
		ScoreSetSelector,
	},
	props: {
		ids: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			selected: [],
			reveal: null,
		};
	},
	methods: {
		onReveal(action) {
			this.reveal = this.reveal !== action ? action : null;
		},
		refreshTable() {
			if (this.$refs.table) {
				this.$refs.table.refresh();
			}
		},
	},
};
</script>
