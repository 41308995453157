<template>
  <nav class="mr-auto context-menu" aria-label="Workspaces">
    <ul>
      <li :class="{ selected: homeSelected }">
        <a class="context-menu-link" href="/">
          <div class="highlight">
            <span class="vertical-line"></span>
          </div>
          <div class="context-menu-text"><i class="af-icons af-icons-home"></i><span class="sr-only">{{ lang.get('menu.home') }}</span></div>
        </a>
      </li>
      <li v-for="context in contexts" :class="{ selected: selectedContext.name === context.name }">
        <a class="context-menu-link" :href="context.route" @click.prevent="setSelectedContext(context)">
          <div class="highlight">
            <span class="vertical-line"></span>
          </div>
          <div class="context-menu-text">{{ context.text.charAt(0).toUpperCase() + context.text.slice(1) }}</div>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapState } from 'vuex';
import { windowLocation } from '@/lib/utils';
import langMixin from '@/lib/components/Translations/mixins/lang-mixin';
const loader = require('../../loader');

export default {
  name: 'ContextMenu',
  components: {},
  mixins: [langMixin],
  props: {
    contexts: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState('menu', ['selectedContext']),
    homeSelected() {
      return windowLocation.getPathName() === '/';
    }
  },
  methods: {
    setSelectedContext(context) {
      loader.showLoader();
      this.$http.put('/context-menu/selected/' + context.name, {}).then(() => {
        window.history.replaceState(null, '', '/clear-breadcrumbs');
        window.location.href = context.route;
      });
    }
  }
};
</script>
<style scoped>
.context-menu-text > .af-icons {
  font-size: 20px;
}
</style>
