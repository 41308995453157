var $ = require('jquery');

module.exports = function () {
	// Submit and continue
	var submitContinue = function () {
		var form = $('#integration-form');

		form.attr('action', $('#update-continue').data('route'));
		form.submit();
	};

	$('[name="testMode"]').change(submitContinue);
	$('#requestPlagiarismScan').click(submitContinue);

	// Driver specific settings
	var toggleDriverSpecificSettings = function () {
		$('.driver-specific').hide().find(':input').prop('disabled', true);

		var driver = $('[name="driver"]').val();
		if (driver) {
			$('[id^="' + driver + '"]')
				.show()
				.find(':input')
				.prop('disabled', false);
		}
	};

	$('#driver').change(toggleDriverSpecificSettings);
	toggleDriverSpecificSettings();

	// Fixed values
	var addFixedValueInputs = function () {
		var template = $(this).closest('table').find('#fixed-value-template');
		var row = template.clone().prop('id', null).toggle();

		row.find('input').prop('disabled', false);

		template.closest('tbody').append(row);
	};

	$('.add-fixed-value').click(addFixedValueInputs);

	// Contributors
	var toggleContributorTabSettings = function () {
		$('[class^="contributor-tab-"]').hide().find(':input').prop('disabled', true);

		var tab = $('#contributors-tab').val();
		if (tab) {
			$('[class*="contributor-tab-' + tab + '"]')
				.show()
				.find(':input')
				.prop('disabled', false);
		}
	};

	$('#contributors-tab').change(toggleContributorTabSettings);
	toggleContributorTabSettings();
};
