<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div v-if="range" class="top-pick-preferences" data-url="saveUrl">
			<div v-if="guest">
				<a
					v-for="preference in preferences"
					:key="entrySlug + '-' + preference"
					class="top-pick-preference hover login-required"
					:href="loginLink"
					v-html="preference"
				></a>
			</div>
			<div v-else>
				<preference
					v-for="(preference, index) in preferences"
					:key="entrySlug + '-' + preference"
					:index="index"
					:is-top-pick="index === 0"
					:is-bottom-pick="index === preferences.length - 1"
					:entry-view="entryView"
					:value="preference"
					:category="category"
					:enabled="enabled"
					:entry-slug="entrySlug"
					:score-set-slug="scoreSetSlug"
					:can-remove="canRemove"
				>
				</preference>
			</div>
		</div>
		<div v-else>-</div>
	</div>
</template>

<script>
import Preference from './Preference.vue';
import { mapMutations } from 'vuex';

export default {
	components: {
		Preference,
	},
	props: {
		guest: {
			type: Boolean,
			required: true,
		},
		scoreSetSlug: {
			type: String,
			required: true,
		},
		entrySlug: {
			type: String,
			required: true,
		},
		category: {
			type: String,
			default: '',
		},
		roleRegistration: {
			type: String,
			required: true,
		},
		entryPreference: {
			type: Number,
			default: 0,
		},
		assignmentPreferences: {
			type: Object,
			default: () => {},
		},
		range: {
			type: Number,
			required: true,
		},
		enabled: {
			type: Boolean,
			required: true,
		},
		canRemove: {
			type: Boolean,
			default: false,
		},
		translations: {
			type: Object,
			default: () => {},
		},
		entryView: {
			type: Boolean,
			required: false,
		},
	},
	computed: {
		loginLink() {
			return this.roleRegistration
				? `/register/${this.roleRegistration}?message=top-pick.messages.login-required`
				: '/?message=top-pick.messages.login-required';
		},
		preferences() {
			return [...Array(this.range).keys()].map((i) => i + 1);
		},
		saveUrl() {
			return `entry/pick/${this.scoreSetSlug}/${this.entrySlug}`;
		},
	},
	created() {
		if (this.entryPreference) {
			this.resetState();

			this.storeEntryPreference({
				value: this.entryPreference,
				entrySlug: this.entrySlug,
				category: this.category,
			});
		}

		if (this.assignmentPreferences) {
			this.storeAssignmentPreferences(this.assignmentPreferences);
		}

		this.storeTranslations(this.translations);
	},
	methods: {
		...mapMutations('topPick', ['storeEntryPreference', 'storeAssignmentPreferences', 'resetState', 'storeTranslations']),
	},
};
</script>
