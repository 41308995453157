<template>
	<modal
		id="video-player-modal"
		v-model="isModalVisible"
		:header="false"
		:footer="false"
		:header-close-button="false"
		@closed="onClose"
	>
		<close-icon slot="before-content" @click.prevent="onClose"></close-icon>
		<video
			:id="videoId"
			:style="styleObject"
			:height="videoPlayerHeight"
			:poster="poster"
			class="video-js vjs-default-skin vjs-big-play-centered vjs-16-9"
			crossorigin="anonymous"
		>
			<source :src="source" type="application/x-mpegURL" />
			<track v-if="captionSource" kind="captions" :src="captionSource" label="Captions" default />
		</video>
	</modal>
</template>

<script>
import CloseIcon from '@/lib/components/ListActions/Partials/CloseIcon';
import { Modal } from 'vue-bootstrap';
import options from '@/domain/services/VideoPlayer/VideoPlayerOptions';
import init from '@/domain/services/VideoPlayer/VideoPlayerInit';

export default {
	components: {
		CloseIcon,
		Modal,
	},
	props: {
		fileId: {
			type: [String, Number],
			required: true,
		},
		source: {
			type: String,
			required: true,
		},
		poster: {
			type: String,
			default: '',
		},
		modalVisible: {
			type: Boolean,
			default: false,
		},
		caption: {
			type: Object,
			default: null,
		},
		videoPlayerHeight: {
			type: Number,
			required: true,
		},
	},
	data() {
		return {
			player: null,
			isPlaying: false,
			isModalVisible: false,
		};
	},
	computed: {
		videoId() {
			return 'modal-video-' + this.fileId;
		},
		styleObject() {
			return {
				display: 'block',
				margin: 'auto',
				'min-height': `min(${this.videoPlayerHeight}px, 95vh)`,
				width: 'auto',
				'max-width': '100vw',
			};
		},
		captionSource() {
			return this.caption?.source;
		},
	},
	watch: {
		caption: {
			handler(caption) {
				// Disable all text tracks when the caption is deleted.
				if (!caption && this.player) {
					const textTracks = this.player.textTracks();

					for (let i = 0; i < textTracks.length; i++) {
						const track = textTracks[i];
						track.mode = 'disabled';
					}
				}
			},
			deep: true,
		},
		modalVisible(visible) {
			this.isModalVisible = visible;
			// Since the video is displayed inside a modal there's no need to initialise
			// the video player in a lifecycle hook (mounted). Defer initialisation until
			// the modal becomes visible.
			if (visible && !this.player) {
				this.player = init(this.videoId, options);

				this.player.on(['waiting', 'pause'], () => (this.isPlaying = false));
				this.player.on('playing', () => (this.isPlaying = true));
			}

			if (visible && this.caption) {
				this.addCaptionTrack();
			}
		},
	},
	beforeDestroy() {
		if (this.player) {
			this.player.dispose();
		}
	},
	methods: {
		onClose() {
			// Pausing a video which is loading can have negative consequences (DOMException).
			// If the video is already playing pause immediately, otherwise bind to the
			// 'playing' event and pause when the player is ready.
			if (this.isPlaying) {
				this.player.pause();
			} else {
				this.player.one('playing', () => this.player.pause());
			}

			this.$emit('closed');
		},
		addCaptionTrack() {
			const source = this.caption?.source;

			if (!source) {
				return;
			}

			this.player.ready(() => {
				const newTrack = {
					src: source,
					kind: 'captions',
					label: 'Captions',
					default: true,
					mode: 'showing',
				};

				this.player.addRemoteTextTrack(newTrack, true);
			});
		},
	},
};
</script>
