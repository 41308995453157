var $ = require('jquery');

/**
 * Handle the 'select-all' functionality of file-type checkboxes
 */
module.exports = function () {
	// jQuery bindings
	$('.attachment-filetypes .select-all input[type=checkbox]').on('click', function () {
		var checked = $(this).prop('checked');

		$(this)
			.closest('.filetype-category')
			.find('input[type=checkbox]')
			.each(function () {
				$(this).prop('checked', checked);
			});
	});

	$('.filetype-category input[type=checkbox]')
		.not('.toggle')
		.on('click', function () {
			$(this).closest('.filetype-category').find('.select-all input[type=checkbox]').prop('checked', false);
		});
};
