<template>
  <div
    v-if="videoUrl || imageUrl"
    :class="[
      'col-xs-12',
      'col-sm-4',
      'col-md-4',
      'col-lg-3',
      'feature-video-or-image-wrapper'
    ]"
  >
    <!-- Video -->
    <div v-if="showVideo" ref="videoWrapper" class="feature-box-video-wrapper">
      <video-embedded
        v-if="videoWidth && videoHeight"
        ref="video"
        :url="videoUrl"
        :width="videoWidth"
        :height="videoHeight"
      ></video-embedded>
    </div>

    <!-- Image -->
    <div v-else-if="imageUrl" class="feature-box-image-wrapper">
      <a
        :href="imageBigUrl || imageUrl"
        target="_blank"
        rel="noopener noreferrer"
        class="strip"
      >
        <img :src="imageUrl" />
      </a>
    </div>
  </div>
</template>

<script>
import VideoEmbedded from '../Video/VideoEmbedded';

export default {
  name: 'FeatureBoxVideo',
  components: { VideoEmbedded },
  props: {
    imageUrl: {
      type: String,
      default: null
    },
    imageBigUrl: {
      type: String,
      default: null
    },
    videoUrl: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      videoWidth: 0,
      videoHeight: 0
    };
  },
  computed: {
    showImage() {
      return !this.videoUrl && this.imageUrl;
    },
    showVideo() {
      return !!this.videoUrl;
    }
  },
  watch: {
    showVideo() {
      if (this.showVideo) {
        setTimeout(() => {
          this.updateSize();
        }, 50);
      }
    }
  },
  mounted() {
    this.updateSize();
    window.addEventListener('resize', this.updateSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateSize)
    this.destroy();
  },
  methods: {
    destroy() {
      if (this.$refs.video) {
        this.$refs.video.$destroy();
      }
    },
    updateSize() {
      if (this.videoUrl && this.$refs.videoWrapper) {
        const iframe = this.$refs.videoWrapper.querySelector('iframe');
        this.videoWidth = this.$refs.videoWrapper.clientWidth
        this.videoHeight = this.videoWidth * (9/16);
        if (iframe) {
          iframe.style.maxHeight = this.videoHeight+'px';
        }
      }
    }
  }
};
</script>
