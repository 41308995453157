export default {
  openModalWithAllocation({commit}, allocation) {
    commit('setAllocation', allocation);
    commit('setModalIsOpen', true);
  },
  openModalWithAllocationAndLock({commit}, payload) {
    const {allocation, editOnlyAllocationPayment} = payload;
    commit('setEditOnlyAllocationPayment', editOnlyAllocationPayment);
    commit('setAllocation', allocation);
    commit('setModalIsOpen', true);
  },
  resetState({commit}, options = {}) {
    commit('setEditOnlyAllocationPayment', null);
    commit('setAllocation', null);
    commit('setModalIsOpen', false);

    if (Object.prototype.hasOwnProperty.call(options, 'hasChanges')) {
      commit('setHasChanges', options.hasChanges);
    }
  }
};
