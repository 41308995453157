<template>
  <div class="score-set-progress">
    <a :href="permissions.canViewProgress ? progressUrl(scoreSet.id) : false">
      <template v-if="scoreSet.mode === 'voting'">
        <score-set-stat
          :count="judgingProgress['total']"
          :label="judgingProgress['label']"
        />
      </template>
      <template v-else>
        <chart
          v-if="judgingProgress['data']"
          :data="judgingProgress['data']"
          :width="70"
          :height="70"
          class="chart mbm mtm"
        />
        {{ judgingProgress['progress'] }}%
        {{ lang.get('judging.dashboard.progress.label') }}
      </template>
    </a>
  </div>
</template>

<script>
import { route } from '@/lib/utils.js';
import Chart from '@/lib/components/Shared/Chart';
import ScoreSetStat from './ScoreSetStat';

export default {
  inject: ['lang'],
  components: {
    Chart,
    ScoreSetStat
  },
  props: {
    scoreSet: {
      type: Object,
      required: true
    },
    judgingProgress: {
      type: Object,
      required: true
    },
    routes: {
      type: Object,
      required: true
    },
    permissions: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    progressUrl(id) {
      return route(this.routes['leaderboard.progress']) + '?score-set=' + id;
    }
  }
};
</script>
