<template>
	<div id="comment-list" class="comment-list">
		<transition-group name="list" tag="div">
			<comment
				v-for="comment in comments"
				:key="comment.slug"
				:comment="comment"
				:delete-url="deleteUrl"
				:update-url="updateUrl"
				:token="token"
				:read-only="readOnly"
				:uploads="uploads"
				:uploader-object="uploaderObject"
				:uploader-options="uploaderOptionsFor(comment)"
				:default-language="defaultLanguage"
				:language="language"
				:translations="translations"
				@deleted="$emit('deleted', comment)"
				@updated="$emit('updated', $event)"
			/>
		</transition-group>
	</div>
</template>

<script>
import Comment from '@/lib/components/Comments/Comment';
import langMixin from '@/lib/components/Translations/mixins/lang-mixin.js';

export default {
	components: {
		Comment,
	},

	mixins: [langMixin],

	props: {
		comments: {
			type: Array,
			required: true,
		},
		token: {
			type: String,
			required: true,
		},
		deleteUrl: {
			type: String,
			required: true,
		},
		updateUrl: {
			type: String,
			required: true,
		},
		readOnly: {
			type: Boolean,
			required: true,
		},
		uploads: {
			type: Boolean,
			default: false,
		},
		uploaderObject: {
			type: Object,
			default: () => {},
		},
		uploaderOptions: {
			type: Object,
			default: () => {},
		},
	},

	methods: {
		uploaderOptionsFor(comment) {
			const options = Object.assign({}, this.uploaderOptions);
			options.resourceId = comment.id;
			return options;
		},
	},
};
</script>

<style scoped>
.list-enter-active {
	transition: all 0.3s;
}
.list-leave-active {
	transition: all 0.3s;
}
.list-enter,
.list-leave-to {
	opacity: 0;
	transform: translateY(-30px);
}
</style>
