import DOMPurify from 'dompurify';

export function stripTags(string) {
  return DOMPurify.sanitize(string, { ALLOWED_TAGS: ['b', 'i'] });
}

export function toCamelCase(sentence) {
  return sentence.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g,
      function(camelCaseMatch, i) {
        if (+camelCaseMatch === 0)
          return "";
        return i === 0 ? camelCaseMatch.toLowerCase() :
            camelCaseMatch.toUpperCase();
      });
}