var $ = require('jquery');
var screenfull = require('screenfull');

/**
 * Full screen mode.
 *
 * Allows to trigger full screen mode in capable browsers.
 *
 * Usage:
 *   var fullscreen = new FullScreen();
 *   fullscreen.setup(button);
 *
 */
module.exports = function () {
	/**
	 * Classes
	 */
	var classes = {
		off: 'off',
	};

	/**
	 * Maintain a reference to the original this
	 */
	// eslint-disable-next-line @typescript-eslint/no-this-alias
	var self = this;

	/**
	 * Can the full screen mode be enabled?
	 */
	this.isEnabled = function () {
		return screenfull.enabled;
	};

	/**
	 * Are we now in full screen mode?
	 */
	this.isFullscreen = function () {
		return screenfull.isFullscreen;
	};

	/**
	 * Toggle full screen mode
	 */
	this.toggle = function () {
		screenfull.toggle();
	};

	/**
	 * Update full screen button
	 *
	 * @param {JQuery} button
	 * @param {Boolean} fullscreen
	 */
	var updateButton = function (button, fullscreen) {
		if (fullscreen) {
			button.addClass(classes.off);
		} else {
			button.removeClass(classes.off);
		}
	};

	/**
	 * Handle click on the full screen button
	 *
	 * @param {JQuery} button
	 */
	var handleClick = function (button) {
		button.on('click.fullscreen', function () {
			self.toggle();
		});

		// Listen to the raw 'fullscreenchange' event
		document.addEventListener(screenfull.raw.fullscreenchange, function () {
			updateButton(button, self.isFullscreen());
		});

		updateButton(button, self.isFullscreen());
	};

	/**
	 * Setup full screen mode
	 *
	 * @param {JQuery|String} button
	 */
	this.setup = function (button) {
		if (!self.isEnabled()) {
			return;
		}

		handleClick(typeof button !== 'string' && button instanceof $ ? button : $(button));
	};
};
