var $ = require('jquery');
var tectoastr = require('tectoastr');

module.exports = function () {
	$('#btn-copy').click(function () {
		var $temp = $('<textarea>');
		$('body').append($temp);
		$temp.val($('#formatted-content').text()).select();
		document.execCommand('copy');
		$temp.remove();

		tectoastr.info($('#copied-message').text());
	});
};
