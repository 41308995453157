import Vue from 'vue';
import store from '@/lib/store';
import ScoringCriteriaList from './components/ScoringCriteriaList';

export default function() {
  const scoringCriteriaList = new Vue({
    el: '#scoring-criteria-list',
    name: 'ScoringCriteriaApp',
    components: {
      ScoringCriteriaList
    },
    store
  });

  $(document).one('pjax:end', () => {
    scoringCriteriaList.$destroy();
  });
}
