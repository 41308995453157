<template>
  <box>
    <widget :class="widgetStyle" :container="true">
      <template slot="cover">
        <cover
          v-if="form.coverImageUrl"
          :aria-label="`${form.name} cover image`"
          :src="form.coverImageUrl"
          class="cover"
        />
        <div v-else class="cover-bar no-cover-bar" />
      </template>
      <template slot="overflow">
        <overflow-menu
          :form="form"
          :can-create="canCreate"
          :can-update="canUpdate"
          :can-delete="canDelete"
          :routes="routes"
          :deletable="deletable"
          v-on="$listeners"
        />
      </template>
      <span class="form-type">
          {{ lang.get(`form.name.default.` + form.type) }}
      </span>
      <template slot="heading">
        {{ form.name }}
      </template>
      <div class="container">
        <counter
          :url="submittableUrl"
          :count="form.submittableCount"
          :units="lang.get(`form.units.` + form.type)"
        />
        <round v-if="form.round" :form-slug="form.slug" :round="form.round" />
      </div>
    </widget>
  </box>
</template>

<script>
import Cover from '@/lib/components/Shared/Cover';
import Box from './Box';
import Widget from '@/lib/components/Shared/Widget';
import OverflowMenu from './OverflowMenu';
import Counter from './Counter';
import Round from './Round';

export default {
  name: 'FormBox',
  inject: ['lang'],
  components: {
    Box,
    Widget,
    Cover,
    OverflowMenu,
    Counter,
    Round
  },
  props: {
    form: {
      type: Object,
      required: true
    },
    routes: {
      type: Object,
      default: () => ({})
    },
    canCreate: {
      type: Boolean,
      required: true
    },
    canUpdate: {
      type: Boolean,
      required: true
    },
    canDelete: {
      type: Boolean,
      required: true
    },
    deletable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    widgetStyle() {
      return [
        'widget-forms',
        'widget-pd0',
        this.form.type === 'entry' ? `widget-round-${this.status}` : '',
        'island',
      ];
    },
    status() {
      return this.form.round ? this.form.round.status : 'ended';
    },
    submittableUrl() {
      return `forms/select/${this.form.slug}/${this.form.type === 'report' ? `grant-report` : `entry`}.manager.index`;
    }
  }
};
</script>

<style scoped>

.container {
  display: flex;
  align-items: left;
  justify-content: flex-start;
  width: 100%;
  flex-direction: row;
  padding: 10px 10px 20px 20px;
}
.form-type {
  padding-left: 20px;
  position: relative;
  top: -15px;
}

</style>
