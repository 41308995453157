import Vue from 'vue';
import { defaultState } from './state';

export default {
  setName(state, name) {
    state.name = name;
  },

  setScoreSet(state, scoreSet) {
    Vue.set(state.scoreSets, 0, scoreSet);
  },

  clearScoreSet(state) {
    state.scoreSets = [];
  },

  configureScoringCriterion(state, criterion) {
    state.configuredCriterion = criterion;
  },

  addScoringCriterion(state, scoringCriterion) {
    state.scoringCriteria.push(scoringCriterion);
  },

  updateScoringCriterion(state, scoringCriterion) {
    state.scoringCriteria = state.scoringCriteria.map(c =>
      c.id === scoringCriterion.id ? scoringCriterion : c
    );
  },

  deleteScoringCriterion(state, scoringCriterion) {
    state.scoringCriteria = state.scoringCriteria.filter(
      c => c.id !== scoringCriterion.id
    );
  },

  clearScoringCriteria(state) {
    state.scoringCriteria = [];
  },

  setPanel(state, panel) {
    Vue.set(state.panels, 0, panel);
  },

  clearPanel(state) {
    state.panels = [];
  },

  storeRounds(state, rounds) {
    state.rounds = rounds.map(round => ({ ...round, selected: false }));
  },

  deselectRounds(state) {
    state.rounds = state.rounds.map(round => ({ ...round, selected: false }));
  },

  selectRound(state, { id, selected }) {
    // eslint-disable-next-line eqeqeq
    const index = state.rounds.findIndex(round => round.id == id);

    if (index >= 0) {
      Vue.set(state.rounds, index, {
        ...state.rounds[index],
        selected: selected
      });
    }
  },

  addRound(state, round) {
    state.rounds.push(round);
  },

  configureRound(state, roundId) {
    state.configuredRound = roundId;
  },

  updateRound(state, round) {
    state.rounds = state.rounds.map(r => (r.id === round.id ? round : r));
  },

  deleteRound(state, round) {
    state.rounds = state.rounds.filter(r => r.id !== round.id);
  },

  resetState(state) {
    Object.assign(state, defaultState());
  }
};
