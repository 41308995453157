export const defaultState = () => ({
  name: null,
  configuredCriterion: null,
  configuredRound: null,
  scoreSets: [],
  scoringCriteria: [],
  panels: [],
  rounds: []
});

const state = defaultState();

export default state;
