<script>
import ListAction from './ListAction.vue';

/**
 * The Undeletism component.
 */
export default {
  extends: ListAction
};
</script>
