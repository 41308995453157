var $ = require('jquery');

var Filters = require('../../common/UIClasses/Filters');
var SelectAll = require('../../common/UIClasses/SelectAll');

/**
 * Access matrix
 */
module.exports = function() {
    var accessMatrix = $('.access-matrix');

    var checkReadAccess = function() {
        var parentRow = $(this).closest('tr');

        if (!$(this).prop('checked')) {
            parentRow.find('.access-read:visible').prop('disabled', false);
            parentRow.find('.access-write:visible').prop('checked', false).prop('disabled', false);
            parentRow.find('.access-required:visible').prop('checked', false).prop('disabled', true);
        }
    };

    var checkWriteAccess = function() {
        var parentRow = $(this).closest('tr');

        if ($(this).prop('checked')) {
            parentRow.find('.access-read:visible').prop('checked', true).prop('disabled', true);
            parentRow.find('.access-required:visible').prop('disabled', false)
        } else {
            parentRow.find('.access-read:visible').prop('disabled', false)
            parentRow.find('.access-required:visible').prop('checked', false).prop('disabled', true);
        }
    };

    var checkRequiredAccess = function() {
        var parentRow = $(this).closest('tr');

        if ($(this).prop('checked')) {
            parentRow.find('.access-read:visible').prop('checked', true).prop('disabled', true);
            parentRow.find('.access-write:visible').prop('checked', true).prop('disabled', false);
            parentRow.find('.access-required:visible').prop('disabled', false);
        }
    };

    accessMatrix.find('.access-read').on('change', checkReadAccess);
    accessMatrix.find('.access-write').on('change', checkWriteAccess);
    accessMatrix.find('.access-required').on('change', checkRequiredAccess);

    ( new Filters )
        .addFilter($('#categoryFilter'), 'categories')
        .addFilter($('#tabFilter'), 'tab')
        .addFilter($('#typeFilter'), 'type')
        .addResetTrigger($('#resetFilters'))
        .addOnChangeCallback(
            function( isAnyFilterFailedTest ) {
                let count = accessMatrix.find('tbody tr')
                    .removeClass('hidden')
                    .map( (key,row) => $(row) )
                    .filter( (key,row) => isAnyFilterFailedTest(row) )
                    .each( (key,row) => row.addClass('hidden') )
                    .length;

                let filterInfoMessage = count => $('#filterInfo').data( count > 1 ? 'hidden-fields' : 'hidden-field' );
                let filterInfo = count => count ? '<i>'+count+' '+filterInfoMessage(count)+'</i>' : '';

                $('#filterInfo').html(filterInfo(count));

                selectAlls.forEach( sa => sa.refresh() );
            }
        );

    let selectAlls = $('.access-matrix .select-all input[type="checkbox"]').map( (key,button) => ( new SelectAll ).addTrigger($(button)).addTargets($('.' + $(button).data('toggle'))) ).toArray();
};
