import Vue from 'vue';
import ResourceList from '../../lib/components/ResourceList';
import store from '@/lib/store';

export default function() {
    const grantStatusList = new Vue({
        el: '#grant-status-list',
        name: 'GrantStatusApp',
        components: {
            'grant-status-list': ResourceList
        },
        store
    });
    $(document).one('pjax:end', () => {
        grantStatusList.$destroy();
    });
}
