<template>
	<nav class="primary-menu" aria-label="Primary" role="navigation">
		<ul>
			<li v-for="item in menu" ref="menuItem" :key="item.name" :class="itemClasses(item)">
				<component
					:is="item.type !== 'menu' ? 'a' : 'button'"
					:id="primaryMenuId(item)"
					:ref="'menu-item-' + item.name"
					:href="item.link || false"
					:class="{ active: isActive(item) || isFocused(item) || isParentFocused(item) }"
					:aria-current="ariaCurrent(item)"
					:aria-expanded="ariaExpanded(item)"
					:type="item.link ? undefined : 'button'"
					@click.prevent="$emit('menuSelected', item)"
					@keypress.space.prevent="clickOnSpaceKey(item, $event)"
					@focus="$emit('focus', { item, event: $event })"
					@keydown.up.exact="$emit('focusPrevious', { event: $event, isParentMenuClosed: !isActive(item) })"
					@keydown.down.exact="$emit('focusNext', { event: $event, isParentMenuClosed: !isActive(item) })"
					@keydown.tab.exact="$emit('focusNext', { event: $event, isParentMenuClosed: !isActive(item) })"
					@keydown.shift.tab.exact="$emit('focusPrevious', { event: $event, isParentMenuClosed: !isActive(item) })"
				>
					<span :class="['icon', 'af-icons', 'af-icons-' + item.icon]"></span>
					<!-- eslint-disable-next-line vue/no-v-html -->
					<span class="text" v-html="item.text"></span>
					<span v-if="item.extras.pendingCount" class="badge badge-alert">{{ item.extras.pendingCount }}</span>
				</component>
				<sub-menu
					v-if="hasSubMenu(item)"
					:sub-menu="item"
					:current-url="currentUrl"
					:primary-menu-active-item="primaryMenuActiveItem"
					:primary-menu-open="primaryMenuOpen"
					:labelled-by="primaryMenuId(item)"
					:current-focus="currentFocus"
					@openShortcutMenu="(item) => $emit('openShortcutMenu', item)"
					@menuSelected="(item) => $emit('menuSelected', item)"
					@itemSelected="(item) => $emit('itemSelected', item)"
					@togglePrimarySubMenu="togglePrimarySubMenu"
					@focus="(payload) => $emit('focus', payload)"
					@focusNext="(payload) => $emit('focusNext', payload)"
					@focusPrevious="(payload) => $emit('focusPrevious', payload)"
				/>
			</li>
		</ul>
	</nav>
</template>

<script>
import SubMenu from './PrimaryMenuSubMenu';
import _ from 'underscore';

export default {
	components: {
		SubMenu,
	},
	props: {
		menu: {
			type: Array,
			default: () => [],
		},
		currentUrl: {
			type: String,
			default: null,
		},
		primaryMenuActiveItem: {
			type: String,
			default: null,
		},
		primaryMenuOpen: {
			type: Boolean,
			default: true,
		},
		twoLevelMenus: {
			type: Array,
			default: () => [],
		},
		currentFocus: {
			type: Object,
			default: null,
		},
	},
	watch: {
		menu() {
			this.$nextTick(() => {
				this.overlapMenuItemCheck();
			});
		},
		currentFocus(focusTo) {
			if (focusTo) {
				const element = this.$refs['menu-item-' + focusTo.name];

				if (!element) {
					return;
				}

				element[0].focus();
			}
		},
	},
	created() {
		window.addEventListener('resize', _.debounce(this.overlapMenuItemCheck, 50));
		window.addEventListener('scroll', _.debounce(this.overlapMenuItemCheck, 50));
	},
	methods: {
		hasSubMenu(item) {
			return item.type === 'menu' && item.children && item.children.length > 0 && !this.twoLevelMenus.includes(item.name);
		},
		isSettingsMenu(item) {
			return item.name === 'settings';
		},
		primaryMenuId(item) {
			return 'primary-menu-' + item.name;
		},
		ariaCurrent(item) {
			if (this.primaryMenuActiveItem === item.name && !this.hasSubMenu(item)) {
				return 'page';
			}
		},
		ariaExpanded(item) {
			return this.hasSubMenu(item) || this.isSettingsMenu(item) ? String(this.isActive(item)) : null;
		},
		clickOnSpaceKey(item, event) {
			// faking <button /> functionality on <a /> tags
			if (item.link && event.code === 'Space') {
				this.$emit('menuSelected', item);
			}
		},
		itemClasses(item) {
			if (item.extras?.class) {
				return item.extras.class;
			}

			return '';
		},
		togglePrimarySubMenu() {
			setTimeout(
				function () {
					this.overlapMenuItemCheck();
				}.bind(this),
				500
			);
		},
		overlapMenuItemCheck() {
			let menuItem = this.$refs.menuItem;
			if (menuItem) {
				menuItem.forEach((item) => {
					if (item.classList.contains('menu-bottom-fixed')) {
						let bottomFixedMenuItemRect = item.getBoundingClientRect();
						let boundaryMenuItemRect = menuItem[menuItem.length - 2].getBoundingClientRect();
						if (boundaryMenuItemRect.bottom > bottomFixedMenuItemRect.top) {
							item.classList.add('box-shadow');
						} else {
							item.classList.remove('box-shadow');
						}
					}
				});
			}
		},
		isActive(item) {
			return this.primaryMenuActiveItem === item.name;
		},
		isFocused(item) {
			return this.currentFocus?.name === item.name;
		},
		isParentFocused(item) {
			return this.currentFocus?.parent?.name === item.name;
		},
	},
};
</script>
