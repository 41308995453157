/**
 * generic Class for managing Select All button behavior for a group of checkboxes
 */
module.exports = class {

    constructor() {
        this.trigger = null;
        this.targets = null;
        $('body').on('tabchange', () => this.refresh() );
    }

    /**
     * adds a triggering ( Selecting All ) button
     *
     * @param checkbox
     * @returns {module.exports}
     */
    addTrigger(checkbox) {
        this.trigger = checkbox;
        this.trigger.on(
            'click',
            () => this.visibleTargets.prop('checked', this.trigger.prop('checked'))
        );
        this.refresh()
        return this;
    }

    /**
     * adds a set ot target ( being selected by Select All ) checkboxes
     *
     * @param checkboxes
     * @returns {module.exports}
     */
    addTargets(checkboxes) {
        this.targets = checkboxes;
        this.targets.on('change', () => this.refresh() );
        this.refresh();
        return this;
    }

    /**
     * returns only visible target checkboxes
     *
     * @returns {Array}
     */
    get visibleTargets() {
        return this.targets ? this.targets.filter(':visible') : [];
    }

    /**
     * returns only visible and checked target checkboxes
     *
     * @returns {Array}
     */
    get visibleAndCheckedTargets() {
        return this.targets ? this.targets.filter(':visible').filter(':checked') : [];
    }

    /**
     * refershes a triggering ( Selecting All ) button state
     * according to target checkboxes current checked state
     */
    refresh() {
        if( this.trigger && this.targets ) {
             this.trigger.prop('checked', this.visibleAndCheckedTargets.length && this.visibleTargets.length === this.visibleAndCheckedTargets.length);
        }
    }
}