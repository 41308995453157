<template>
	<div class="preview">
		<img v-if="image" :src="image" :alt="file.original" :style="style" />
		<div v-else ref="preview" :style="style" />
	</div>
</template>

<script>
import $ from 'jquery';
import { computed, defineComponent, ref, watch } from 'vue';

export default defineComponent({
	props: {
		uploader: {
			type: Object,
			required: true,
		},
		file: {
			type: Object,
			required: true,
		},
		maxWidth: {
			type: Number,
			default: 999,
		},
		maxHeight: {
			type: Number,
			default: 240,
		},
	},

	setup(props, { emit }) {
		const image = ref(props.file.image);

		const style = computed(() => ({
			width: 'auto',
			'max-width': '100%',
			height: 'auto',
			'max-height': `${props.maxHeight}px`,
		}));

		watch(
			() => props.file,
			(fileObject) => (image.value = fileObject.image),
			{ deep: true }
		);

		const loadImagePreview = () => {
			const image = $(new window.Image());
			const source = props.uploader.getFile(props.file.id);
			if (!source) {
				return;
			}

			const imageDataUrl = window.URL.createObjectURL(source.getNative());
			image.load((e) => {
				const width = e.target.width;
				const height = e.target.height;

				emit('imageDataUrl', imageDataUrl);
				emit('imageData', {
					name: props.file.original,
					url: imageDataUrl,
					size: props.file.size,
					width,
					height,
				});
			});
			if (imageDataUrl) {
				image.prop('src', imageDataUrl);
				image.value = imageDataUrl;
			}
		};

		if (!image.value) {
			loadImagePreview();
		}

		return {
			image,
			style,
		};
	},
});
</script>

<style scoped>
.preview img {
	max-width: 100%;
}
</style>
