var underscore = require('underscore');
var $ = require('jquery');
var tectoastr = require('tectoastr');

require('datatables.net');
require('datatables.net-colreorder');
require('datatables.net-responsive');

/**
 * DataTable - responsive tables with support for column reordering.
 *
 * http://datatables.net/
 *
 * Usage:
 *   var datatable = new DataTable;
 *   datatable.setup(table);
 *
 */
module.exports = function () {
	/**
	 * Table element
	 */
	var table;

	/**
	 * DataTable
	 */
	var datatable;

	/**
	 * Area
	 */
	var area;

	/**
	 * Return column definitions
	 *
	 * https://datatables.net/reference/option/columnDefs
	 *
	 * @param {jQuery} headers
	 * @return {Array}
	 */
	var getColumnDefs = function (headers) {
		return underscore.map(headers, function (th, i) {
			th = $(th);

			return {
				targets: i,
				width: th.data('fixed') ? '1%' : null,
			};
		});
	};

	/**
	 * Calculate the number of fixed columns
	 *
	 * https://datatables.net/reference/option/colReorder.fixedColumnsLeft
	 *
	 * @param {jQuery} headers
	 * @return {Number}
	 */
	var getFixedColumnsLeft = function (headers) {
		return underscore.reduce(
			headers,
			function (memo, th) {
				return memo + ($(th).data('fixed') ? 1 : 0);
			},
			0
		);
	};

	/**
	 * Reorder callback
	 *
	 * @param {Object} event
	 * @param {Object} settings
	 * @param {Object} details
	 */
	var reorderCallback = function () {
		var columns = [];

		table.find('th').each(function (index, th) {
			columns.push($(th).data('name'));
		});

		$.ajax({
			type: 'POST',
			url: '/search/columns/order',
			data: {
				area: area,
				columns: columns,
			},
		}).fail(function () {
			tectoastr.error($('#alerts-column-order').text());
		});
	};

	/**
	 * Prevent checkbox input from expanding hidden column details
	 *
	 * @param {jQuery} table
	 */
	var blockCheckboxInput = function (table) {
		table.find('td:first-child input[type="checkbox"], td:first-child .checkbox').click(function (event) {
			event.stopPropagation();
		});
	};

	var removeEvents = function () {
		$(window).off('slidein', this.refresh);
		table.off('column-reorder.dt', reorderCallback);
	};

	this.preInit = function () {
		if (!datatable) {
			return;
		}

		datatable.destroy();
		datatable = null;
		removeEvents();
	};

	/**
	 * Refresh the DataTable
	 */
	this.refresh = function () {
		if (!datatable) {
			return;
		}

		datatable.responsive.rebuild();
		datatable.responsive.recalc();
	};

	/**
	 * Setup DataTable
	 *
	 * @param {JQuery|String} element
	 */
	this.setup = function (element) {
		table = typeof element !== 'string' && element instanceof $ ? element : $(element);

		if ($.fn.DataTable.isDataTable(table)) {
			return;
		}

		this.preInit();
		area = table.data('area');

		var headers = table.find('th');
		var fixedColumns = getFixedColumnsLeft(headers);
		var columnDefs = getColumnDefs(headers);

		datatable = table.DataTable({
			colReorder: {
				realtime: false,
				fixedColumnsLeft: fixedColumns,
			},
			autoWidth: false,
			bPaginate: false,
			bInfo: false,
			searching: false,
			bSort: false,
			responsive: true,
			columnDefs: columnDefs,
		});

		$(window).on('slidein', this.refresh);
		table.on('column-reorder.dt', reorderCallback);

		blockCheckboxInput(table);
	};
};
