import store from '@/lib/store';

type CommentsService = {
	pendingUploads: () => number;

	changePendingUploadsBy: (changeBy: number) => void;

	userId: () => number;

	commentsEdited: () => number;

	changeCommentsEditedBy: (changeBy: number) => void;
};

const commentsServiceFactory = (): CommentsService => ({
	pendingUploads: () => store.state.comments.pendingUploads as number,

	changePendingUploadsBy: (changeBy: number) => {
		store.commit('comments/changePendingUploadsBy', changeBy);
	},

	userId: () => store.state.comments.userId as number,

	commentsEdited: () => store.state.comments.commentsEdited as number,

	changeCommentsEditedBy: (changeBy: number) => {
		store.commit('comments/changeCommentsBeingEdited', changeBy);
	},
});

export { commentsServiceFactory, CommentsService };
