<template>
	<div :class="['file-upload-container', 'upload-standalone', !captionMode ? 'card' : 'uploader-addon']">
		<div class="upload-details-container">
			<div v-if="showActionBar" class="upload-details card-header">
				<file-upload-sorter
					v-if="attachment !== null"
					:attachment="attachment"
					:num-of-attachments="numOfAttachments"
					@movedLeft="$emit('movedLeft', attachment)"
					@movedRight="$emit('movedRight', attachment)"
				/>
				<file-upload-status :file="file" />
				<file-upload-actions
					v-if="(canDelete && !disabled) || failed"
					:file="file"
					:caption-mode="captionMode"
					:field-required="$attrs['field-required']"
					@deleted="$emit('deleted', file)"
					@replaced="$emit('replaced', file)"
					@retry="$emit('retry', file)"
				>
					<template v-if="captionMode" slot="label">
						{{ lang.get('files.captions.label') }}
					</template>
				</file-upload-actions>
			</div>
		</div>
		<file-upload-preview
			v-if="!captionMode"
			:disabled="disabled"
      :uploader="uploader"
			:file="file"
			:preview-types="previewTypes"
			@imageDataUrl="$emit('imageDataUrl', $event)"
		/>
		<file-upload-transcoding-errors
			v-if="!captionMode && hasTranscodingErrors"
			:file="file"
			:modal-visible="modalVisible"
			@retried="$emit('retried', file)"
			@toggled="toggleModal"
		/>
		<file-upload-transcoding-modal v-if="!captionMode" :file="file" :modal-visible="modalVisible" @closed="hideModal" />
		<file-upload-progress v-if="inProgress" :file="file" @canceled="$emit('canceled', file)" />
		<slot></slot>
		<attachment-fields v-if="attachment" :attachment="attachment" />
		<file-upload-link :file="file" />
	</div>
</template>

<script>
import AttachmentFields from './AttachmentFields';
import FileUploadActions from './FileUploadActions';
import FileUploadLink from './FileUploadLink';
import FileUploadPreview from './FileUploadPreview';
import FileUploadProgress from './FileUploadProgress';
import FileUploadSorter from './FileUploadSorter';
import FileUploadStatus from './FileUploadStatus';
import FileUploadTranscodingErrors from './FileUploadTranscodingErrors';
import FileUploadTranscodingModal from './FileUploadTranscodingModal';
import Status from './Status.js';
import TranscodingStatus from './TranscodingStatus.js';

export default {
	inject: ['lang'],
	components: {
		FileUploadActions,
		FileUploadLink,
		FileUploadPreview,
		FileUploadProgress,
		FileUploadSorter,
		FileUploadStatus,
		FileUploadTranscodingErrors,
		FileUploadTranscodingModal,
		AttachmentFields,
	},
	props: {
		uploader: {
			type: Object,
			required: true,
		},
		captionMode: {
			type: Boolean,
			default: false,
		},
		file: {
			type: Object,
			required: true,
		},
		attachment: {
			type: Object,
			default: null,
		},
		canDelete: {
			type: Boolean,
			default: true,
		},
		numOfAttachments: {
			type: Number,
			default: 0,
		},
		previewTypes: {
			type: Object,
			default: () => ({
				audio: [],
				video: [],
			}),
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		hideEmptyActionBar: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			modalVisible: false,
		};
	},
	computed: {
		inProgress() {
			return this.file.status === Status.QUEUED || this.file.status === Status.UPLOADING;
		},
		isCompleted() {
			return this.file.status === Status.COMPLETED;
		},
		failed() {
			return this.file.status === Status.FAILED;
		},
		hasTranscodingErrors() {
			return (
				this.file.transcodingStatus === TranscodingStatus.ERROR &&
				this.file.transcodingErrors &&
				this.file.transcodingErrors.length
			);
		},
		showActionBar() {
			if (this.hideEmptyActionBar && !this.canDelete) {
				return false;
			}

			return this.canDelete && !this.disabled;
		},
	},
	methods: {
		toggleModal(visible) {
			this.modalVisible = visible;
		},
		hideModal() {
			this.modalVisible = false;
		},
	},
};
</script>
