<template>
	<dropdown
		:id="id"
		:label="label"
		:button-class="'link-selector ' + extraButtonClass"
		container-class="dropdown-light"
		:aria-label="ariaLabel"
	>
		<li v-for="link in links" :key="link.link">
			<a :href="baseUrl + link.link" class="dropdown-menu-item">
				{{ link.name }}
			</a>
		</li>
	</dropdown>
</template>

<script>
import { Dropdown } from 'vue-bootstrap';
import { computed } from 'vue';

/**
 * The LinkSelector component.
 *
 * Expects an array of links:
 *
 * [
 *	 { link: 'http://af.dev/2018', name: '2018' },
 *	 { link: 'http://af.dev/2019', name: '2019' }
 * ]
 *
 */
export default {
	components: {
		Dropdown,
	},
	props: {
		id: {
			type: String,
			default: 'link-selector',
		},
		links: {
			type: Array,
			required: true,
		},
		activeLink: {
			type: String,
			default: '',
		},
		baseUrl: {
			type: String,
			default: '',
		},
		extraButtonClass: {
			type: String,
			default: '',
		},
		ariaLabel: {
			type: String,
			default: '',
		},
	},
	setup(props) {
		const getSelectedLink = () => {
			const firstLink = props.links[0];
			return props.activeLink ? props.activeLink : firstLink.link;
		};

		const label = computed(() => {
			if (!props.links.length) {
				return;
			}

			const selectedLink = getSelectedLink();

			return props.links.find((link) => link.link?.toString() === selectedLink)?.name;
		});

		return {
			label,
		};
	},
};
</script>
