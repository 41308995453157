<template>
	<div>
		<text-editor
			v-if="enableMarkdown"
			:id="getId()"
			:value="value"
			:markdown-guide-label="lang.get('miscellaneous.markdown_guide.label')"
			:disabled="disabled"
			:in-active-tab="inActiveTab"
			@input="onInput"
			@blur="validate"
		/>
		<resizable-textarea
			v-else
			:id="getId()"
			:value="value"
			:disabled="disabled"
			:aria-invalid="hasError"
			:aria-describedby="ariaDescribedby"
			:required="field.required"
			@input="onInput"
			@blur="validate"
		></resizable-textarea>
	</div>
</template>

<script>
import Field from '@/lib/components/Fields/Field.vue';
import ResizableTextarea from '@/lib/components/Shared/ResizableTextarea.vue';
import TextEditor from '@/lib/components/Shared/editor/TextEditor.vue';

export default {
	inject: ['lang'],
	components: {
		TextEditor,
		ResizableTextarea,
	},
	extends: Field,
	props: {
		enableMarkdown: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
