<template>
	<div>
		<dropdown ref="dropDown" button-class="" :manual="true">
			<template slot="label">
				<double-click tag="span" class="drag-handle" @single-click="toggleDropdown" @double-click="configureColumn">
					{{ translatedColumnName }} <span class="caret"></span>
				</double-click>
			</template>
			<li>
				<button class="dropdown-menu-item btn-link" type="button" @click="configureColumn">
					{{ labels.configureColumn }}
				</button>
			</li>
			<li>
				<button class="dropdown-menu-item btn-link" type="button" @click="addColumn({ position: 'left', from: column })">
					{{ labels.insertLeft }}
				</button>
			</li>
			<li>
				<button class="dropdown-menu-item btn-link" type="button" @click="addColumn({ position: 'right', from: column })">
					{{ labels.insertRight }}
				</button>
			</li>
			<li v-if="canDeleteColumns">
				<button class="dropdown-menu-item btn-link" type="button" @click="removeColumn(column)">
					<popover v-if="preventColumnDeletion(column)" :content="labels.alertDeleteColumn" trigger="hover">
						<span class="delete-row" disabled="true">{{ labels.deleteColumn }}</span>
					</popover>
					<span v-else>{{ labels.deleteColumn }}</span>
				</button>
			</li>
		</dropdown>
		<portal :target-el="`#${modalId}`">
			<modal
				v-model="showModal"
				:header="false"
				:confirm-button-label="labels.save"
				:close-button-label="labels.cancel"
				@closed="showModal = false"
				@confirmed="save"
			>
				<close-icon slot="before-content" @click="showModal = false"></close-icon>
				<h4 class="modal-title">{{ labels.column }} > {{ columnName }}</h4>
				<translations
					:supported-languages="$attrs['supported-languages']"
					:label="labels.columnLabel"
					:translations="translations"
					:translated.sync="translatedColumnLabel"
				>
				</translations>
				<div class="form-group">
					<label for="column-type">{{ labels.columnType }}</label>
					<select-field :empty-option="false" :items="inputTypes" :value="type" @selected="onTypeSelect"> </select-field>
				</div>
				<div v-if="type === 'currency'" class="form-group">
					<select-field
						:items="currencies.list"
						:value="currency"
						:empty-option="false"
						aria-label="currency"
						@selected="onSelectCurrency"
					>
					</select-field>
				</div>
				<div v-if="calculationsAvailable" class="form-group">
					<checkbox
						name="displayCalculation"
						:selected="displayCalculation"
						:value="displayCalculation"
						:aria-label="labels.displayCalculation"
						@change="toggleDisplayCalculation"
					>
						<template slot="label">{{ labels.displayCalculation }}</template>
					</checkbox>
				</div>
				<transition name="push">
					<div v-if="displayCalculation && calculationsAvailable" class="calculation-fields">
						<div class="form-group">
							<select-field
								:items="calculationFunctions"
								:value="calculationFunction"
								:empty-option="false"
								:required="true"
								@selected="onCalculationFunctionSelect"
							>
							</select-field>
						</div>
						<translations
							:supported-languages="$attrs['supported-languages']"
							:label="labels.calculationLabel"
							:translations="calculationTranslations"
							:translated.sync="translatedCalculationLabel"
						>
						</translations>
					</div>
				</transition>
			</modal>
		</portal>
	</div>
</template>

<script>
import { Checkbox, Dropdown, Modal, Popover, SelectField } from 'vue-bootstrap';
import { mapGetters, mapMutations } from 'vuex';
import DoubleClick from '../Shared/DoubleClick.vue';
import Translations from './TableFieldConfiguratorTranslations.vue';
import filtersMixin from './mixins/table-field-filters-mixin.js';
import CloseIcon from '@/lib/components/ListActions/Partials/CloseIcon';
import calculationsMixin from '@/lib/components/Fields/mixins/table-field-calculations-mixin';
import { getGlobal } from '@/lib/utils';

export default {
	inject: ['currencies', 'calculationFunctions'],
	components: {
		Dropdown,
		Modal,
		Popover,
		SelectField,
		Checkbox,
		Translations,
		DoubleClick,
		CloseIcon,
	},
	mixins: [filtersMixin, calculationsMixin],
	inheritAttrs: false,
	props: {
		columns: {
			type: Array,
			required: true,
		},
		index: {
			type: Number,
			required: true,
		},
		inputTypes: {
			type: Array,
			required: true,
		},
		labels: {
			type: Object,
			required: true,
		},
		hasEntries: {
			type: Boolean,
			default: false,
		},
		existingColumns: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			showModal: false,
			type: null,
			currency: null,
			displayCalculation: false,
			calculationFunction: null,
			defaultCalculationFunction: 'sum',
			translatedColumnLabel: {},
			translatedCalculationLabel: {},
		};
	},
	computed: {
		...mapGetters(['getTranslation', 'getTranslations', 'getCalculationTranslation', 'getCalculationTranslations']),
		translations() {
			return this.getTranslations(this.column);
		},
		calculationTranslations() {
			return this.getCalculationTranslations(this.column);
		},
		translatedColumnName() {
			return this.getTranslation(getGlobal('language'), this.column) || this.columnName;
		},
		columnName() {
			let prefix = '';
			let index = this.index;

			// Column index went over the available letters of the alphabet - add prefix
			if (index / 26 >= 1) {
				prefix = String.fromCharCode(65 + (Math.floor(index / 26) - 1));
				index = index % 26;
			}

			// Uppercase letters of the alphabet start at char code 65
			return this.labels.column + ' ' + prefix + String.fromCharCode(65 + index);
		},
		canDeleteColumns() {
			return this.columns.length > 1;
		},
		modalId() {
			return `modal-target-${this.column}`;
		},
		calculationsAvailable() {
			return ['integer', 'decimal', 'decimal-precise', 'currency'].includes(this.type);
		},
	},
	watch: {
		type() {
			if (!this.calculationsAvailable) {
				this.displayCalculation = false;
			}
		},
	},
	created() {
		this.type = this.columnType(this.column);
		this.currency = this.columnSelectedCurrency(this.column) || this.currencies.defaultCurrency;
		this.displayCalculation = this.columnDisplayCalculation;
		this.calculationFunction = this.columnCalculationFunction || this.defaultCalculationFunction;
	},
	beforeMount() {
		let modalTarget = document.getElementById(this.modalId);

		if (!modalTarget) {
			modalTarget = document.createElement('div');
			modalTarget.setAttribute('id', this.modalId);
			const appendTo = document.getElementById('pjaxContainer') || document.body;
			appendTo.appendChild(modalTarget);
		}
	},
	methods: {
		...mapMutations(['insertColumn', 'deleteColumn', 'updateTranslations', 'updateCalculationTranslations']),
		toggleDropdown() {
			this.$refs.dropDown.toggle();
		},
		configureColumn() {
			this.showModal = true;
			this.$refs.dropDown.close();
		},
		addColumn(payload) {
			this.insertColumn(payload);
			this.$refs.dropDown.close();
		},
		save() {
			if (this.type) {
				this.saveFilters(this.column, this.type, this.currency ? { currency: this.currency } : {});
			}

			this.calculations = { enable: this.displayCalculation, calculation: this.calculationFunction };

			this.updateTranslations({
				key: this.column,
				translated: Object.assign({}, this.translatedColumnLabel),
			});

			this.updateCalculationTranslations({
				key: this.column,
				translated: Object.assign({}, this.translatedCalculationLabel),
			});
		},
		removeColumn(column) {
			// Make sure the clickOutside directive doesn't incorrectly detect
			// a click outside when the column disappears
			if (!this.preventColumnDeletion(column)) {
				setTimeout(() => this.deleteColumn(column), 0);
			}
		},
		onTypeSelect(name, selected) {
			this.type = selected;
		},
		onCalculationFunctionSelect(name, selected) {
			this.calculationFunction = selected;
		},
		onSelectCurrency(name, selected) {
			this.currency = selected;
		},
		toggleDisplayCalculation() {
			this.displayCalculation = !this.displayCalculation;
		},
		preventColumnDeletion(col) {
			return this.hasEntries && this.existingColumns.includes(col);
		},
	},
};
</script>

<style scoped>
.modal-body .calculation-fields {
	margin-left: 23px;
}
</style>
