<template>
  <date-time-timezone
    mode="time"
    :id="id"
    :field="field"
    :show-timezone="false"
    :value="value"
    :disabled="disabled"
    :element-id="elementId"
    :aria-required="field.required ? 'true' : 'false'"
    :aria-invalid="hasError"
    :aria-describedby="ariaDescribedby"
    @update:value="onInput"
    @changed="validate"
  />
</template>

<script>
import DateTimeTimezone from './DateTimeTimezone';
import Field from './Field';

export default {
  components: { DateTimeTimezone },
  extends: Field,
};
</script>
