var $ = require('jquery');

/**
 * When visiting an account page that requires a form, the following
 * function should be executed which will bind a select2 instance to
 * a hidden field for the account owner select dropdown.
 */
module.exports = function () {
	$('#owner').select2({
		ajax: {
			url: '/users/autocomplete/all',
			dataType: 'json',
			delay: 250,
			data: function (searchTerm) {
				return {
					name: searchTerm,
				};
			},
			results: function (data) {
				return {
					results: data,
				};
			},
			cache: true,
		},
		formatResult: function (user) {
			return user.firstName + ' ' + user.lastName + ' (' + user.email + ')';
		},
		formatSelection: function (user) {
			return user.firstName + ' ' + user.lastName;
		},
		minimumInputLength: 3,
	});
};
