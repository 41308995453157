import Base64 from '@/lib/base64';
import { getGlobal } from '@/lib/utils';
const clarifiedData = () => {
	const obfuscatedData = getGlobal('obfuscatedData');
	if (!obfuscatedData) return [];

	return JSON.parse(Base64.decode(obfuscatedData));
};

const globals = {
	get: (key) => clarifiedData()[key],
};

const useGlobals = (keys) => keys.map((key) => globals.get[key]);
const useGlobal = (key) => globals.get(key);

export { globals, useGlobals, useGlobal };
