var $ = require('jquery');
var strip = require('../vendor/staaky/strip.pkgd.js');

module.exports = {
	init: function () {
		$.extend(strip.Skins.strip, {
			onShow: function () {
				var stripWindow = $('.strp-window');

				// Check that the overlay doesn't exist yet
				if (stripWindow.prev().hasClass('strp-overlay')) {
					return;
				}

				$($('<div />').addClass('strp-overlay')).insertBefore(stripWindow);
			},
			afterHide: function () {
				$('.strp-overlay').remove();
			},
		});
	},
};
