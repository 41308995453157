var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.visible)?_c('div',{ref:"box",class:['row', 'feature-box-wrapper', _vm.isCollapsed ? 'collapsed' : 'open'],attrs:{"aria-live":"polite","aria-atomic":"true","aria-expanded":_vm.isCollapsed ? 'false' : 'true'}},[_c('feature-box-video-or-image',{ref:"videoWrapper",attrs:{"show-video":_vm.showImageOrVideo,"image-url":_vm.imageUrl,"image-big-url":_vm.imageBigUrl,"video-url":_vm.videoUrl}}),_vm._v(" "),_c('div',{class:[
			'col-xs-9',
			'col-sm-' + (_vm.showImageOrVideo ? 6 : 10),
			'col-md-' + (_vm.showImageOrVideo ? 6 : 10),
			'col-lg-' + (_vm.showImageOrVideo ? 7 : 10),
			'col-box-content',
		]},[_c('h2',[_vm._v(_vm._s(_vm.heading))]),_vm._v(" "),(!_vm.isCollapsed)?_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.content)}}),_vm._v(" "),_vm._t("post-middle-col")],2):_vm._e()]),_vm._v(" "),(_vm.useShowHide && _vm.urlUpdateVisibility)?_vm._t("show-hide",function(){return [_c('div',{ref:"showHide",class:['col-xs-2', 'col-sm-2', 'col-md-2', 'col-lg-2', 'col-show-hide']},[_c('show-hide',{attrs:{"url":_vm.urlUpdateVisibility,"collapsed":_vm.isCollapsed,"show-text":_vm.showText,"hide-text":_vm.hideText},on:{"change":_vm.onShowHide}})],1)]}):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }