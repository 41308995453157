var $ = require('jquery');

/**
 * Filtertron manages a variety of features for searching, including working with the
 * search cache, hooking into pagination links, binding to search form submissions,
 * and a variety of other functions. This is to ensure that search positions and
 * criteria are maintained for when users come back to a given search page.
 *
 * To use, simply bind filtertron to the component sitting on the page. This should be
 * an element with the class "filtertron", but it could be anything. It requires
 * one argument - the resource's search results that it's managing:
 *
 *    $('.filtetron').filtertron('entries');
 *
 * This value gets used as the key for the search cache, and so it's important that it
 * is unique, otherwise you will see some odd behaviour between search screens that
 * share that key.
 */
$.fn.filtertron = function (resource, options) {
	var ftElement = $(this);
	var expanded = ftElement.find('.extended-container').hasClass('visible');

	// Flips the state of the expanded search options, in effect showing/hiding the
	// additional fields that can be used for additional search criteria.
	ftElement.find('#expander').click(function () {
		// If it's not expanded, the search results need to move out of the way before
		// the expanded filtertron component expands. If the opposite is true,
		// then the expanded fields need to contract first before the search results
		// area takes up that space again. This results in a much smoother and neater
		// user experience than it all happening at once. //- Kirk
		if (!expanded) {
			$('#searchResults').addClass('courteous');

			ftElement
				.find('.extended-container')
				.delay(100)
				.queue(function () {
					$(this).addClass('visible');
					$(this).dequeue();
				});
		} else {
			ftElement.find('.extended-container').removeClass('visible');

			$('#searchResults')
				.delay(100)
				.queue(function () {
					$(this).removeClass('courteous');
					$(this).dequeue();
				});
		}

		// Set our new expanded state
		expanded = !expanded;

		ftElement.delay(500).queue(function () {
			// eslint-disable-next-line no-prototype-builtins
			if (options.hasOwnProperty('cards')) {
				options.cards.refresh();
			}

			$(this).dequeue();
		});
	});
};
