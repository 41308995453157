import $ from 'jquery';

export default {
  methods: {
    copyToClipboard(text) {
      if (!navigator.clipboard) {
        const $temp = $('<textarea>');
        $('body').append($temp);
        $temp.val(text).select();
        document.execCommand('copy');
        $temp.remove();
      } else {
        navigator.clipboard.writeText(text);
      }
    }
  }
};
