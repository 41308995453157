<template>
  <widget :header="false" class="widget-judging-shortcuts island">
    <h4 class="section padded">{{lang.get('judging.dashboard.shortcuts.guided_configuration')}}</h4>
    <div class="forms-dropdown-container padded">
      <action-dropdown
          v-if="multiform"
          class="forms-dropdown"
          id="list-action-dropdown"
          button-class="btn btn-primary btn-lg btn-nowrap"
          menu-class="dropdown action-dropdown-primary"
          :actions="guided"
      >
        <template slot="label" slot-scope="dd">
          {{ lang.get('judging.dashboard.shortcuts.reviewing_fast_start') }} <span class="caret"></span>
        </template>

        <ul class="action-list">
          <li v-for="action in guided">
            <a class="dropdown-menu-item" style="text-overflow: ellipsis" :href="action.href">{{action.name}}</a>
          </li>
        </ul>

      </action-dropdown>
      <a v-else class="btn btn-primary btn-lg btn-nowrap" :href="'/' + routes['fast-start.index']">{{lang.get('judging.dashboard.shortcuts.reviewing_fast_start')}}</a>
    </div>
    <h4 class="section padded">{{lang.get('judging.dashboard.shortcuts.detailed_configuration')}}</h4>
    <div class="section">
      <judging-shortcut
        :url="routes['score-set.index']"
        :action="lang.get('judging.dashboard.shortcuts.score_sets')"
        :actions="scoresets"
      />
      <judging-shortcut
        :url="routes['round.index']"
        :action="lang.get('judging.dashboard.shortcuts.rounds')"
        :actions="rounds"
      />
      <judging-shortcut
        :url="routes['panel.index']"
        :action="lang.get('judging.dashboard.shortcuts.panels')"
        :actions="panels"
      />
      <judging-shortcut
          :url="routes['assignment.index']"
          :action="lang.get('judging.dashboard.shortcuts.assignments')"
          :actions="assignments"
      />
    </div>
  </widget>
</template>

<script>
import Widget from '@/lib/components/Shared/Widget';
import JudgingShortcut from './JudgingShortcut';
import SubMenu from '@/lib/components/Navigation/SubMenu';
import ActionDropdown from '@/lib/components/Shared/ActionDropdown.vue';
import isTouchDevice from '@/lib/is-touch-device.js';

export default {
  inject: ['lang'],
  components: {
    Widget,
    JudgingShortcut,
    SubMenu,
    ActionDropdown
  },
  props: {
    routes: {
      type: Object,
      required: true
    },
    menu: {
      type: Array,
      default: () => []
    },
    permissions: {
      type: Object,
      default: () => ({})
    },
    forms: {
      type: Array,
      default: null
    }
  },
  computed: {
    multiform() {
      return this.forms !== null && this.forms.length > 0
    },
    guided() {
      return this.forms ? this.forms.map(form => this.url('fast-start.index', form)) : null
    },
    scoresets() {
      return this.forms ? this.forms.map(form => this.url('score-set.new', form)) : [{href: '/' + this.routes['score-set.new']}]
    },
    rounds() {
      return this.forms ? this.forms.map(form => this.url('round.add', form)) : [{href: '/' + this.routes['round.add']}]
    },
    panels() {
      return this.forms ? this.forms.map(form => this.url('panel.new', form)) : [{href: '/' + this.routes['panel.new']}]
    },
    assignments() {
      return this.forms ? [
        {name: this.lang.get('judging.dashboard.shortcuts.assignments_manual'), children: this.forms.map(form => this.url('assignment.new', form))},
        {name: this.lang.get('judging.dashboard.shortcuts.assignments_random'), children: this.forms.map(form => this.url('assignment.new-random', form))}
      ] : [
        {name: this.lang.get('judging.dashboard.shortcuts.assignments_manual'), href: '/' + this.routes['assignment.new']},
        {name: this.lang.get('judging.dashboard.shortcuts.assignments_random'), href: '/' + this.routes['assignment.new-random']}
      ]
    },
    notTouchDevice() {
      return isTouchDevice() !== true
    }
  },
  methods: {
    url(route, form) {
      return { name:form.name, href: '/' + this.routes[route] + '?formSlug=' + form.slug }
    }
  }
};
</script>

<style scoped>
  .widget-judging-shortcuts {
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 0 0 10px 0;
  }

  .widget-judging-shortcuts .padded{
    padding: 0 10px 0 20px;
  }

  .forms-dropdown-container {
    min-height: 50px;
  }

  .forms-dropdown {
    position: absolute;
  }

  .section {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
</style>
