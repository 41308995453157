import Vue from 'vue';

const create = (options: Record<string, unknown>) => new Vue(options);

const destroy = (vue: Vue) => {
	vue.$destroy();
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const VueModule = {
	create,
	destroy,
};

export { VueModule };
