var $ = require('jquery');
// eslint-disable-next-line @typescript-eslint/naming-convention
var Isotope = require('isotope-layout');
var imagesLoaded = require('imagesloaded');

/**
 * Display a card view.
 *
 * Usage:
 *   var cards = new Cards;
 *   cards.setup(cards, card, layout, isStill)
 *
 */
module.exports = function () {
	var isotope;

	/**
	 * Setup cards
	 *
	 * cards   -> a jQuery object that contains all cards (usually an ul element)
	 * card    -> a jQuery object that contains a single card (usually a li element)
	 * layout  -> layout mode - masonry or fitRows
	 * isStill -> boolean - choose between animated or still layout
	 */
	this.setup = function (cards, card, layout, isStill) {
		var transition = isStill ? 0 : '0.1s';

		var options = {
			itemSelector: card,
			transitionDuration: transition,
			getSortData: {
				'sort-order': '.sort-order parseInt',
			},
			sortBy: 'sort-order',
			sortAscending: true,
			isOriginLeft: !$('html[dir=rtl]').length,
		};
		if (layout === 'fitRows') {
			options.layoutMode = 'fitRows';
		} else {
			options.masonry = {
				columnWidth: 240,
				gutter: 20,
				horizontalOrder: true,
			};
		}

		if ($(cards).length > 0) {
			imagesLoaded(cards, function () {
				isotope = new Isotope(cards, options);

				$('body').on('tabchange', function () {
					isotope.layout();
				});
			});
		}
	};

	/**
	 * Refresh layout
	 */
	this.refresh = function () {
		if (!isotope) {
			return;
		}

		isotope.reloadItems();
		isotope.arrange();
	};
};
