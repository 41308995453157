import Vue from 'vue';

export default {
  setAllocation(state, allocation) {
    Vue.set(state, 'allocation', allocation);
  },
  setEditOnlyAllocationPayment(state, editOnlyAllocationPayment) {
    Vue.set(state, 'editOnlyAllocationPayment', editOnlyAllocationPayment || null);
  },
  setModalIsOpen(state, isOpen) {
    Vue.set(state, 'modalIsOpen', isOpen);
  },
  setHasChanges(state, hasChanges) {
    Vue.set(state, 'hasChanges', hasChanges);
  }
};
