var $ = require('jquery');

/**
 * YouTube video player.
 *
 * Usage:
 *   var youTubePlayer = new YouTubePlayer;
 *   players = youTubePlayer.setup(elements);
 *
 *   players.done(function(players) {
 *       console.log(players);
 *   });
 *
 */
module.exports = function () {

    /**
     * Keep track of all player instances.
     */
    var players = [];

    /**
     * Keep track of ready player ids.
     */
    var ready = [];

    /**
     * Loop through all elements and initialise the video player.
     * 
     * @param {String} elements
     * @return {Array}
     */
    var attachPlayers = function(elements) {
        $(elements).each(function() {
            var id = $(this).attr('id');
            var player = new YT.Player(id, {
                events: {
                    onReady: function() {
                        ready.push(id);
                    }
                }
            });

            players.push(player);
        });

        return players;
    }

    /**
     * Wait for YouTube Player API to be ready and then attach YT.Player to elements.
     *
     * Makes use of YouTube Player API
     * https://developers.google.com/youtube/iframe_api_reference?csw=1#Examples
     * 
     * @param {String} elements
     * @return {Deferred}
     */
    this.setup = function (elements) {
        var dfd = $.Deferred();

        if (typeof(YT) == 'undefined' || typeof(YT.Player) == 'undefined') {
            global.onYouTubeIframeAPIReady = function() {
                dfd.resolve(attachPlayers(elements));
            };
        } else {
            dfd.resolve(attachPlayers(elements));
        }

        return dfd;
    };

    /**
     * Is the player ready?
     */
    this.isReady = function(player) {
        var id = $(player.getIframe()).attr('id');

        return $.inArray(id, ready) >= 0;
    }

    /**
     * Pause video playback.
     */
    this.pause = function() {
        var self = this;

        $.each(players, function(index, player) {
            if (self.isReady(player)) {
                player.pauseVideo();
            }
        });
    };
}
