import Vue from 'vue';

export default {
  setTopCenterContentBlock(state, contentBlock) {
    Vue.set(state, 'topCenterContentBlock', contentBlock);
  },
  resetTopCenterContentBlock(state) {
    Vue.set(state, 'topCenterContentBlock', {});
  }
};
