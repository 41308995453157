<template>
  <div ref="form" class="saved-searches-form">
    <div class="clearfix">
      <div class="field field-name">
        <input
          v-model="name"
          class="form-control"
          :placeholder="lang.get('search.saved-searches-form.placeholder')"
          type="text"
        />
      </div>
      <div class="field field-shared">
        <select
          v-model="shared"
          class="form-control"
          :disabled="userId && userId !== consumerId"
        >
          <option value="0">{{
            lang.get('search.saved-searches-form.private')
          }}</option>
          <option value="1">{{
            lang.get('search.saved-searches-form.shared')
          }}</option>
        </select>
      </div>
    </div>
    <div class="add-to-shortcuts">
      <div class="form-group">
        <div class="checkbox styled">
          <input
            :id="'shortcut-' + slug"
            v-model="listView"
            name="shortcut"
            type="checkbox"
          />
          <label :for="'shortcut-' + slug">
            {{ lang.get('search.saved-searches-form.add-to-shortcuts') }}
          </label>
        </div>
      </div>
      <!--      <div class="form-group" v-if="consumerCanViewDashboard">-->
      <!--        <div class="checkbox styled">-->
      <!--          <input-->
      <!--            :id="'dashboard-' + slug"-->
      <!--            v-model="dashboard"-->
      <!--            name="dashboard"-->
      <!--            type="checkbox"-->
      <!--          />-->
      <!--          <label :for="'dashboard-' + slug">-->
      <!--            {{ lang.get('search.saved-searches-form.add-to-dashboard') }}-->
      <!--          </label>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <div class="buttons">
      <input
        class="btn btn-primary btn-sm"
        type="submit"
        :value="lang.get('search.saved-searches-form.save')"
        @click="saveView"
      />
      <a class="btn btn-tertiary btn-sm" @click="cancel">{{
        lang.get('buttons.cancel')
      }}</a>
    </div>
  </div>
</template>

<script>
import langMixin from '@/lib/components/Translations/mixins/lang-mixin.js';
import { mapActions } from 'vuex';

export default {
  mixins: [langMixin],
  props: {
    area: {
      type: String,
      required: true
    },
    consumerId: {
      type: Number,
      default: null
    },
    consumerCanViewDashboard: {
      type: Boolean,
      default: true
    },
    savedView: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      slug: null,
      name: '',
      shared: 0,
      listView: 0,
      dashboard: 0,
      userId: null,
      userFullName: ''
    };
  },
  mounted() {
    if (this.savedView) {
      this.slug = this.savedView.slug;
      this.name = this.savedView.name;
      this.shared = this.savedView.shared ? 1 : 0;
      this.listView = this.savedView.shortcuts.listView;
      this.dashboard = this.savedView.shortcuts.dashboard;
      this.userId = this.savedView.userId;
      this.userFullName = this.savedView.userFullName;
    }
  },
  methods: {
    ...mapActions('savedView', ['updateSavedView']),
    saveView() {
      let data = {
        slug: this.slug,
        name: this.name,
        shared: this.shared,
        shortcut: this.listView,
        dashboard: this.dashboard,
        area: this.area,
        userId: this.userId,
        userFullName: this.userFullName
      };
      if (!this.savedView) {
        this.handleCreate(data);
      } else {
        this.handleUpdate(data);
      }
    },
    cancel() {
      let parent = this.$refs.form.parentElement;

      if (parent.classList.contains('new-save-view-form')) {
        this.hideParent(parent);
      } else {
        this.$emit('close');
      }
    },
    handleCreate(data) {
      this.$http.post('/search/settings', data).then(() => {
        this.reloadPage();
      });
    },
    handleUpdate(data) {
      if (this.userId !== this.consumerId) {
        data.shared = '1';
      }
      this.updateSavedView(data);
      this.$emit('close');
    },
    hideParent(parent) {
      parent.classList.add('hidden');
    },
    reloadPage() {
      window.location.reload();
    }
  }
};
</script>
