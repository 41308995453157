import { isIE9OrOlder } from '@/lib/utils.js';
import breakpoints from '@/lib/navigation-breakpoints.js';

export default {
    props: {
      stickySelector: {
        type: String,
        default: null
      },
      stickyOffset: {
        type: Number,
        default: 0
      }
    },
    created() {
        // Use matchMedia to enable sticky header when the 'sm' breakpoint is reached.
        // This is more effective than detecting a mobile device or hooking into the
        // window 'resize' event, because it's triggered on window resize and also
        // on zoom in / zoom out.
        if (this.stickySelector && !isIE9OrOlder() && window.matchMedia) {
            this.mediaQuery = window.matchMedia(`(min-width: ${breakpoints.sm}px)`);
            this.mediaQuery.addListener(this.stickyHeaderListener);
            this.stickyHeaderListener(this.mediaQuery);
        }
    },
    beforeDestroy() {
        if (this.mediaQuery) {
            this.mediaQuery.removeListener(this.stickyHeaderListener);
        }
        if (this.stickyBits) {
            this.stickyBits.cleanup();
        }
    },
    methods: {
        stickyHeaderListener() {
            if (this.mediaQuery.matches && this.stickySelector) {
                this.$nextTick(() => {
                    this.stickyBits = $(this.stickySelector).stickybits({
                        useStickyClasses: true,
                        stickyBitStickyOffset: this.stickyOffset
                    });
                });
            } else {
                if (this.stickyBits) {
                    this.stickyBits.cleanup();
                    this.stickyBits = null;
                }
            }
        }
    }
};
