import $ from 'jquery';

export default function () {
	function displayGatewayCredentials(ele) {
		$("div[id$='-credentials']").hide();
		$('div#' + ele + '-credentials').show();
	}

	let gatewayReplace = function (value) {
		if (value) {
			return value.replace('_', '-');
		}
	};

	$('#payment-gateway-selector').on('change', function () {
		displayGatewayCredentials(gatewayReplace($(this).val()));
	});

	displayGatewayCredentials(gatewayReplace($('#payment-gateway-selector').val()));

	let autosaveFormUponStripeConnectAuthorization = function () {
		let form = $(this).closest('form');
		let formSubmitUrl = form.attr('action');
		let redirectAfterSubmitUrl = $('#stripe-connect-auth-endpoint').data('url');

		let showLoader = function () {
			$('#loader').show().fadeTo(250, 0.8);
		};

		$.ajax({
			type: 'POST',
			url: formSubmitUrl,
			data: form.serializeArray(),
			success: function () {
				document.location = redirectAfterSubmitUrl;
			},
		});
		showLoader();
		return false;
	};

	$('#stripe-connect-authorise').click(autosaveFormUponStripeConnectAuthorization);
}
