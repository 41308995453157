import Vue from 'vue';

export default {
  /**
   * Save saved views to state
   * @param state
   * @param savedViews
   */
  storeSavedViews(state, savedViews) {
    Vue.set(state, 'savedViews', savedViews);
  },

  updateSavedViews(state, savedView) {
    let index = state.savedViews.findIndex(
      elem => elem.slug === savedView.slug
    );
    state.savedViews.splice(index, 1, {
      area: savedView.area,
      name: savedView.name,
      shared: !!parseInt(savedView.shared),
      shortcuts: {
        dashboard: savedView.dashboard,
        listView: savedView.shortcut
      },
      slug: savedView.slug,
      userFullName: savedView.userFullName,
      userId: savedView.userId
    });
  },

  deleteFromSavedViews(state, savedView) {
    let index = state.savedViews.findIndex(
      elem => elem.slug === savedView.slug
    );

    state.savedViews.splice(index, 1);
  }
};
