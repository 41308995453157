import { getVideoDuration } from './mediaInfo';
const isNotVideo = (file) => file.type.indexOf('video') === -1 && file.type.indexOf('x-shockwave-flash') === -1;

const videoLengthComparison = async (limit, file, comparisonFn) => {
	const duration = await getVideoDuration(file);

	if (duration === null) {
		return false;
	}

	return comparisonFn(duration, limit);
};

export { isNotVideo, videoLengthComparison };
