<script>
import SimpleWidget from '@/lib/components/Shared/SimpleWidget';
import { mapMutations } from 'vuex';

export default {
  components: { SimpleWidget },
  mounted() {
    this.resetTopCenterContentBlock();
  },
  methods: {
    ...mapMutations('splashWithMenu', ['resetTopCenterContentBlock'])
  }
};
</script>
