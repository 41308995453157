import Vue from 'vue';
import MainMenu from './components/Navigation/MainMenu.vue';
import store from '@/lib/store';

const mainMenuEl = document.getElementById('main-menu');

if (mainMenuEl) {
  new Vue({
    el: mainMenuEl,
    name: 'MainMenuApp',
    components: {
      MainMenu
    },
    store
  });
}
