<script>
import ResourceList from '../../lib/components/ResourceList.vue';
import ListAction from '../../lib/components/ListActions/ListAction.vue';

export default {
  components: {
    ListAction
  },
  extends: ResourceList
};
</script>
