import contract from './contract.js';
import feedback from './feedback.js';
import index from './index';
import manageDuplicates from './manage-duplicates.js';
import manageEntries from './manage-entries.js';
import manageEntryView from './manage-entry-view.js';
import preview from './preview.js';
import reviewEntries from './review-entries.js';
import { Router } from 'pjax';

Router.get('entry/duplicates/manage', manageDuplicates);
Router.get('entry/entrant', index);
Router.get('entry/entrant/:any/contract/:any', contract);
Router.get('entry/entrant/:any/preview', preview);
Router.get('entry/feedback/:any', feedback);
Router.get('entry/manager', manageEntries);
Router.get('entry/manager/([a-zA-Z]+)', manageEntryView);
Router.get('entry/manager/:any/preview', preview);
Router.get('entry/review', reviewEntries);
