import { getImageHeight, getImageWidth } from './mediaInfo';
const isNotImage = (file) => file.type.indexOf('image') === -1;

const imageWidthComparison = async (limit, file, comparisonFn) => {
	const width = await getImageWidth(file);

	if (width === null) {
		return false;
	}

	return comparisonFn(width, limit);
};

const imageHeightComparison = async (limit, file, comparisonFn) => {
	const height = await getImageHeight(file);

	if (height === null) {
		return false;
	}

	return comparisonFn(height, limit);
};

export { isNotImage, imageWidthComparison, imageHeightComparison };
