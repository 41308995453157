<template>
	<collapsible>
		<sub-menu
			v-if="isOpen"
			id="primary-submenu"
			class="primary-menu-submenu"
			:current-url="currentUrl"
			:items="subMenu.children"
			:aria-labelledby="labelledBy"
			:current-focus="currentFocus"
			@openShortcutMenu="(item) => $emit('openShortcutMenu', item)"
			@itemSelected="(item) => $emit('itemSelected', item)"
			@focus="(payload) => $emit('focus', payload)"
			@focusNext="(payload) => $emit('focusNext', { ...payload, isParentMenuClosed: !isOpen })"
			@focusPrevious="(payload) => $emit('focusPrevious', { ...payload, isParentMenuClosed: !isOpen })"
		/>
	</collapsible>
</template>

<script>
import Collapsible from '../Shared/Collapsible';
import SubMenu from './SubMenu';

export default {
	components: {
		Collapsible,
		SubMenu,
	},
	props: {
		subMenu: {
			type: Object,
			default: () => ({}),
		},
		currentUrl: {
			type: String,
			default: null,
		},
		primaryMenuActiveItem: {
			type: String,
			default: null,
		},
		primaryMenuOpen: {
			type: Boolean,
			default: true,
		},
		labelledBy: {
			type: String,
			default: '',
		},
		currentFocus: {
			type: Object,
			default: null,
		},
	},
	computed: {
		isOpen() {
			return this.primaryMenuActiveItem === this.subMenu.name && this.primaryMenuOpen;
		},
	},
	watch: {
		isOpen() {
			this.$emit('togglePrimarySubMenu', this.isOpen);
		},
	},
};
</script>
