import { getReferees } from '@/modules/entry-form/Referee.api';
import { makeId } from './helpers';
import { visibility } from './conditions';
import Vue from 'vue';

export default {
	/**
	 * Load initial data.
	 */

	loadData({ dispatch }) {
		const tabRequest = dispatch('loadTabs');
		const chapterRequest = dispatch('loadChapter');

		Promise.all([tabRequest, chapterRequest]).then(() => {
			dispatch('loadFields');
		});
	},

	selectFirstTab({ commit, getters }) {
		commit('selectTab', getters.firstVisibleTab.id);
	},

	/**
	 * An asynchronous action that fetches tabs from the API - it should be dispatched
	 * as soon as the form is created.
	 */
	loadTabs({ commit, getters, state }) {
		const getTabsUrl =
			`/${getters.submittableRoute}/${getters.formType}/tabs/` +
			(state.entry ? state.entry.slug : '') +
			'?formSlug=' +
			state.form.slug;

		// TODO: Integrate all Ajax requests in entry-form-api store
		return Vue.prototype.$http.get(getTabsUrl).then(
			(response) => {
				commit('storeTabs', response.data);
				commit('setTabsLoadingStatus', true);
			},
			() => {
				commit('storeStatus', {
					status: 'error',
					message: 'entries.form.errors.problem-retrieving-tabs',
				});
			}
		);
	},

	/**
	 * An asynchronous action that fetches fields from the API - it should be dispatched
	 * as soon as the form is created.
	 */
	loadFields({ commit, dispatch, getters, state }, tabId) {
		const tab = state.tabs.find((tab) => tab.id === (tabId || state.tabId));

		if (tab && tab.slug !== 'newtab' && !state.loadedTabFields.includes(tab.id) && !state.tabFieldsLoading[tab.id]) {
			commit('setTabFieldsLoading', { tabId: tab.id, status: true });
			const getFieldsUrl =
				`/${getters.submittableRoute}/${getters.formType}/fields/${tab.slug}/` + (state.entry ? state.entry.slug : '');
			// TODO: Integrate all Ajax requests in entry-form-api store
			return Vue.prototype.$http.get(getFieldsUrl).then(
				(response) => {
					const fields = [...response.data, ...state.fields];
					commit('storeFields', fields);
					const loadedTabFields = [...state.loadedTabFields, tab.id];
					commit('setLoadedTabFields', loadedTabFields);
					// Once fields are in the store, let's set entry field visibility.
					dispatch('setConditionalVisibility', {
						fields: getters.entryFields(),
						resource: 'Entries',
					});
					if (state.entry && !state.attachmentsLoaded && tab.type === 'Attachments') {
						dispatch('loadAttachments');
					}

					if (state.entry && !state.contributorsLoaded && tab.type === 'Contributors') {
						dispatch('loadContributors');
					}

					if (state.entry && !state.refereesLoaded && tab.type === 'Referees') {
						dispatch('loadReferees');
					}

					commit('setTabFieldsLoading', { tabId: tab.id, status: false });
					dispatch('loadPendingTabs');
				},
				() => {
					commit('setTabFieldsLoading', { tabId: tab.id, status: false });
					commit('storeStatus', {
						status: 'error',
						message: 'entries.form.errors.problem-retrieving-fields',
					});
				}
			);
		}
	},

	/**
	 * An asynchronous action that fetches attachments from the API - it should be dispatched
	 * as soon as the form is created.
	 */
	loadAttachments({ commit, dispatch, getters, state }) {
		if (!state.entry || !getters.hasAttachments) {
			commit('setAttachmentsLoadingStatus', true);
			return Promise.resolve([]);
		}

		const getAttachmentsUrl = `/${getters.submittableRoute}/${getters.formType}/attachments/${state.entry.slug}`;

		// TODO: Integrate all Ajax requests in entry-form-api store
		return Vue.prototype.$http.get(getAttachmentsUrl).then(
			(response) => {
				commit('storeTabUploaderOptions', response.data.uploaderOptions);

				commit('storeAttachments', {
					attachments: response.data.attachments,
					attachmentFields: getters.attachmentFields(),
				});

				commit('storeLinks', { links: response.data.links });

				// Once attachments are in the store, let's set attachment field visibility.
				const attachments = state.attachments;

				Object.keys(attachments).forEach((tabId) => {
					const attachmentFields = getters.attachmentFields(tabId);

					attachments[tabId].forEach((attachment) => {
						dispatch('setConditionalVisibility', {
							fields: attachmentFields,
							resource: 'Attachments',
							resourceId: attachment.id,
						});
					});
				});

				commit('setAttachmentsLoadingStatus', true);
			},
			() => {
				commit('storeStatus', {
					status: 'error',
					message: 'entries.form.errors.problem-retrieving-attachments',
				});
			}
		);
	},

	/**
	 * An asynchronous action that fetches contributors from the API - it should be dispatched
	 * as soon as the form is created.
	 */
	loadContributors({ commit, dispatch, getters, state }) {
		if (!state.entry || !getters.hasContributors) {
			commit('setContributorsLoadingStatus', true);
			return Promise.resolve([]);
		}

		const getContributorsUrl = `/${getters.submittableRoute}/${getters.formType}/contributors/${state.entry.slug}`;

		// TODO: Integrate all Ajax requests in entry-form-api store
		return Vue.prototype.$http.get(getContributorsUrl).then(
			(response) => {
				commit('storeContributors', {
					contributors: response.data,
					contributorFields: getters.contributorFields(),
				});

				// Once contributors are in the store, let's set contributor field visibility.
				const contributors = state.contributors;

				Object.keys(contributors).forEach((tabId) => {
					const contributorFields = getters.contributorFields(tabId);

					contributors[tabId].forEach((contributor) => {
						dispatch('setConditionalVisibility', {
							fields: contributorFields,
							resource: 'Contributors',
							resourceId: contributor.id,
						});
					});
				});

				commit('setContributorsLoadingStatus', true);
			},
			() => {
				commit('storeStatus', {
					status: 'error',
					message: 'entries.form.errors.problem-retrieving-contributors',
				});
			}
		);
	},

	/**
	 * Loop through all provided fields and set conditional visibility.
	 */
	setConditionalVisibility(context, { fields, resource, resourceId }) {
		let conditionalVisibility = resourceId
			? context.state.conditionalVisibility[resource][resourceId]
			: context.state.conditionalVisibility[resource];

		let descendantsVisibility = {};

		fields.forEach((field) => {
			const conditionalFields = context.getters.conditionalFields(field);
			const value = context.getters.fieldValue(field, resourceId);
			const fieldVisibility = visibility(conditionalFields, value);

			const hiddensSlugs = Object.keys(fieldVisibility).reduce(
				(acc, val) => (fieldVisibility[val] === false ? { ...acc, [val]: true } : { ...acc }),
				{}
			);
			const fieldDescendantsVisibility = context.state.fields.reduce(
				(acc, val) =>
					hiddensSlugs[val.conditionalFieldSlug] === true && val.conditionalVisibility !== 'hide'
						? { ...acc, [val.slug]: false }
						: { ...acc },
				{}
			);

			descendantsVisibility = {
				...descendantsVisibility,
				...fieldDescendantsVisibility,
			};
			conditionalVisibility = { ...conditionalVisibility, ...fieldVisibility };
		});

		context.commit('storeConditionalVisibility', {
			conditionalVisibility: {
				...conditionalVisibility,
				...descendantsVisibility,
			},
			resource: resource,
			resourceId: resourceId,
		});
	},

	loadChapter({ commit, state, dispatch, rootGetters }, payload) {
		const reload = payload && payload.reload;

		if (state.chapterId && state.season.id) {
			let categories = state.loadedChapters.find((c) => c.chapterId === state.chapterId);

			if (!reload && categories) {
				if (categories.categories.length === 1) {
					commit('selectCategory', categories.categories[0].id);
				}

				return Promise.resolve(true);
			}

			const locked = state.locks.lockedCategory || state.locks.readOnly;
			let active, trashed;

			if (locked) {
				active = 'false';
				trashed = 'true';
			} else {
				active = state.hasManagerRole ? 'false' : 'true';
				trashed = 'false';
			}

			const getChapterUrl = rootGetters['entryFormApi/routes'].categories(
				state.season.id,
				state.chapterId,
				state.form.id,
				trashed,
				active
			);

			commit('setChaptersLoadingStatus', false);

			// TODO: Integrate all Ajax requests in entry-form-api store
			return Vue.prototype.$http.get(getChapterUrl).then(
				(response) => {
					commit('storeCategories', response.data);
					commit('setChaptersLoadingStatus', true);
					let categories = response.data;
					if (categories.length === 1) {
						commit('selectCategory', categories[0].id);
						dispatch('prefillTitle');
					}

					return response;
				},
				() => {
					commit('storeCategories', []);
				}
			);
		} else {
			// When no chapter is selected at the moment, set the loading status
			// to completed and wait for a new selection
			commit('setChaptersLoadingStatus', true);
		}
	},

	/**
	 * Update conditional visibility for a particular field.
	 */
	updateConditionalVisibility({ state, commit, dispatch, getters }, { field, resourceId, forceHide }) {
		if (field.type === 'table') {
			return;
		}

		const conditionalFields = getters.conditionalFields(field);
		const value = getters.fieldValue(field, resourceId);

		const resource = field.resource;
		const fieldVisibility = visibility(conditionalFields, value, forceHide);

		const conditionalVisibility = resourceId
			? {
					...state.conditionalVisibility[resource][resourceId],
					...fieldVisibility,
			  }
			: { ...state.conditionalVisibility[resource], ...fieldVisibility };

		commit('storeConditionalVisibility', {
			conditionalVisibility: conditionalVisibility,
			resource: resource,
			resourceId: resourceId,
		});

		for (let descendant of conditionalFields) {
			dispatch('updateConditionalVisibility', {
				field: descendant,
				resourceId: resourceId,
				forceHide: fieldVisibility[descendant.slug] === false && descendant.conditionalVisibility === 'hide',
			});
		}
	},

	/**
	 * Add new attachment to a tab.
	 */
	addAttachment(context, { tabId, attachmentId, file, fileId, order }) {
		const attachmentFields = context.getters.attachmentFields(tabId);

		const attachment = {
			id: attachmentId,
			fields: attachmentFields,
			tabId: tabId,
			file: file,
			fileId: fileId,
			order: order,
		};
		delete file.attachmentId;

		context.commit('storeAttachment', {
			tabId: tabId,
			attachment: attachment,
		});

		context.commit('seedValues', {
			fields: attachmentFields,
			resource: 'Attachments',
			resourceId: attachment.id,
		});

		context.dispatch('setConditionalVisibility', {
			fields: attachmentFields,
			resource: 'Attachments',
			resourceId: attachment.id,
		});
	},

	/**
	 * Add new contributor to a tab.
	 */
	addContributor(context, tabId) {
		const contributorFields = context.getters.contributorFields(tabId);

		const contributor = {
			id: makeId(),
			fields: contributorFields,
		};

		context.commit('storeContributor', {
			tabId: tabId,
			contributor: contributor,
		});

		context.commit('seedValues', {
			fields: contributorFields,
			resource: 'Contributors',
			resourceId: contributor.id,
		});

		context.dispatch('setConditionalVisibility', {
			fields: contributorFields,
			resource: 'Contributors',
			resourceId: contributor.id,
		});
	},

	selectChapter({ commit, dispatch, state }, selectedChapterId) {
		if (selectedChapterId === state.chapterId) return;
		commit('updateChapterId', selectedChapterId);
		return dispatch('loadChapter');
	},

	selectCategory({ commit, dispatch, getters }, selectedCategoryId) {
		// eslint-disable-next-line eqeqeq
		if (getters.categories.find((s) => s.current == selectedCategoryId)) return;
		commit('selectCategory', selectedCategoryId);
		dispatch('prefillTitle');
	},

	/**
	 * Prefill the entry title based on the currently selected category.
	 */
	prefillTitle({ commit, getters, state }) {
		const category = getters.categoriesIndex.find((c) => c.id === state.selectedCategoryId);

		if (category && category.fillEntryName && !state.title && state.user) {
			commit('updateTitle', state.user.name || '');
		}
	},

	selectTabBySlug({ commit, getters }, tabSlug) {
		const tab = getters.visibleTabs.find((t) => t.slug === tabSlug) || getters.visibleTabs[0];
		if (tab) {
			commit('selectTab', tab.id);
		}
	},

	/**
	 * Reset store state
	 */
	resetStoreState({ commit }) {
		commit('resetState');
	},

	orderAttachments(context, { url, data }) {
		// TODO: Integrate all Ajax requests in entry-form-api store
		return Vue.prototype.$http.put(url, data);
	},

	focusErrorsTab({ getters, commit }) {
		const failedTab = getters.visibleTabs.find((tab) => tab.invalidFields);

		if (failedTab) {
			commit('selectTab', failedTab.id);
		}
	},

	loadPendingTabs({ getters, dispatch }) {
		const nextTab = getters.nextPendingTab;
		if (nextTab) {
			dispatch('loadFields', nextTab.id);
		}
	},

	/**
	 * Updates the ApiError with the 'Details' tab id if it is saveable.
	 */
	updateDetailsTabApiErrors({ getters, commit }) {
		const detailsTab = getters.detailsTab;

		return getters.isSaveable
			? commit('deleteTabIdApiErrors', detailsTab.id)
			: commit('addTabIdToApiErrors', detailsTab.id);
	},

	setFieldTitlesForFormula({ commit, getters, rootState }) {
		const fieldTitles = Object.fromEntries(
			getters.getFields
				? getters.getFields
						.filter((field) => rootState.global.formulaFieldCompatibleTypes.includes(field.type))
						.map((field) => [field.slug, field.title])
				: []
		);

		commit('formulaField/setFieldTitles', fieldTitles, { root: true });
	},

	/**
	 * Add new referee to a tab
	 */
	addReferee(context, tabId) {
		const refereeFields = context.getters.refereeFields(tabId);

		const referee = {
			id: makeId(),
			tabId: tabId,
			name: '',
			email: '',
			fields: refereeFields,
		};

		context.commit('storeReferee', {
			tabId: tabId,
			referee: referee,
		});

		context.commit('seedValues', {
			fields: refereeFields,
			resource: 'Referees',
			resourceId: referee.id,
		});

		context.dispatch('setConditionalVisibility', {
			fields: refereeFields,
			resource: 'Referees',
			resourceId: referee.id,
		});
	},

	loadReferees({ commit, dispatch, getters, state }) {
		if (!state.entry || !getters.hasReferees) {
			commit('setRefereesLoadingStatus', true);
			return Promise.resolve([]);
		}

		return (
			getReferees(getters.submittableRoute, getters.formType, state.entry.slug).then((response) => {
				commit('storeReferees', {
					referees: response,
					refereeFields: getters.refereeFields(),
				});

				const referees = state.referees;

				Object.keys(referees).forEach((tabId) => {
					const refereeFields = getters.refereeFields(tabId);

					referees[tabId].forEach((referee) => {
						dispatch('setConditionalVisibility', {
							fields: refereeFields,
							resource: 'Referees',
							resourceId: referee.id,
						});
					});
				});

				commit('setRefereesLoadingStatus', true);
			}),
			() => {}
		);
	},
};
