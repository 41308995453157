import Vuex from 'vuex';
import getters from './getters';
import mutations from './mutations';

export function createStore() {
  return new Vuex.Store({
    state() {
      return {
        columns: [],
        rows: [],
        filters: [],
        dynamicRowsEnabled: false,
        calculations: [],
        dynamicRows: [],
        translations: {},
        values: {}
      };
    },
    getters,
    mutations
  });
}

export default {
  getters,
  mutations
};
