const eventListeners = [];
let hasFocusJumpOccurred = false;

/**
 * Solution used to force next focus to go into filtertron. By default, the filtertron is placed at the end of dom.
 */

export function changeFocusFlowForTabKey(fromElement, toElement, entersFiltertron, leavesFiltertron) {
  changeFocusFlow(fromElement, () => {
    if (ifTabIsPressed()) {
      if (focusOnElementCallback(toElement, entersFiltertron, leavesFiltertron)) {
        event.preventDefault();
      }
    }
  });
}

export function changeFocusFlowForShiftTabKey(fromElement, toElement, entersFiltertron, leavesFiltertron) {
  changeFocusFlow(fromElement, () => {
    if (ifShiftTabIsPressed()) {
      if (focusOnElementCallback(toElement, entersFiltertron, leavesFiltertron)) {
        event.preventDefault();
      }
    }
  });
}

export function focusElement(elementQuerySelector) {
  focusOnElementCallback(elementQuerySelector, true, false);
}

export function cleanUpEventListeners() {
  for (let listener of eventListeners) {
    const element = listener.element;
    element.removeEventListener('keydown', listener.callback);
  }
}

export function markFocusJumpHasOccurred() {
  hasFocusJumpOccurred = true;
}

export function markFocusJumpHasNotOccurred() {
  hasFocusJumpOccurred = false;
}

function changeFocusFlow(listenOnElement, callback) {
  const element = getElement(listenOnElement);
  if (!element) {
    return;
  }

  element.addEventListener('keydown', callback);

  eventListeners.push({
    element: element,
    callback: callback
  });
}

function ifShiftTabIsPressed() {
  return event && event.shiftKey && event.key === 'Tab';
}

function ifTabIsPressed() {
  return event && !event.shiftKey && event.key === 'Tab';
}

function focusOnElementCallback(queryOrElement, entersFiltertron = false, leavesFiltertron = false) {
  if ((entersFiltertron && !hasFocusJumpOccurred) || (leavesFiltertron && hasFocusJumpOccurred)) {
    const elementToFocus = getElement(queryOrElement);

    if (elementToFocus) {
      elementToFocus.focus();
      if (entersFiltertron) {
        hasFocusJumpOccurred = true;
      } else if (leavesFiltertron) {
        hasFocusJumpOccurred = false;
      }

      return true;
    }
  }

  return false;
}

function getElement(queryOrElement) {
  const element = typeof queryOrElement === 'string' ? document.querySelector(queryOrElement) : queryOrElement;
  if (element) {
    return element;
  }
  return null;
}
