<template>
  <input :id="'slider-' + slug" ref="slider" v-model="localScore" type="text" />
</template>

<script>
import isTouchDevice from '@/lib/is-touch-device.js';
import ScoringControl from './ScoringControl';
import Slider from 'bootstrap-slider';

export default {
  extends: ScoringControl,
  props: {
    hasAbstained: {
      type: Boolean,
      default: false
    },
    increment: {
      type: Number | String,
      default: 0
    },
    label: {
      type: String,
      required: true
    },
    slug: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      localScore: this.score,
      slider: null
    };
  },
  watch: {
    minScore() {
      this.slider.setAttribute('min', parseFloat(this.minScore));
      this.setLocalScoreAndSliderValue();
    },
    maxScore() {
      this.slider.setAttribute('max', parseFloat(this.maxScore));
      this.setLocalScoreAndSliderValue();
    },
    increment() {
      this.slider.setAttribute('step', parseFloat(this.increment));
    }
  },
  mounted() {
    this.slider = new Slider(this.$refs.slider, {
      min: parseFloat(this.minScore),
      max: parseFloat(this.maxScore),
      step: parseFloat(this.increment),
      value: parseFloat(this.localScore),
      enabled: !(this.hasAbstained || this.lockScores),
      tooltip: isTouchDevice() ? 'always' : 'show',
      formatter: value => this.label + ' ' + value
    });
    this.slider.on(
      'change',
      function(value) {
        this.localScore = value.newValue;
        this.$emit('update:score', this.localScore);
      }.bind(this)
    );
    this.setLocalScoreAndSliderValue();
  },
  beforeDestroy() {
    this.slider.destroy();
  },
  methods: {
    setLocalScoreAndSliderValue() {
      // Calculate and set the local score and slider value to the mid point between min and max score
      this.localScore =
        parseFloat(this.minScore) +
        Math.abs(parseFloat(this.minScore) - parseFloat(this.maxScore)) / 2;
      this.slider.setValue(this.localScore);
    }
  }
};
</script>
