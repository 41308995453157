const pjax = require('@/vendor/pjax/pjax-router.js');

/**
 * this is needed to have pjax router
 * properly imported as ES6 module
 * to both JS and TS files
 *
 * it does not reproduce full pjax signature
 * just fields used in our codebase
 */

/* eslint-disable @typescript-eslint/naming-convention */
const Router = pjax.Router;
const Config = pjax.Config;
const Init = pjax.init;
const Utility = pjax.Utility;
/* eslint-enable @typescript-eslint/naming-convention */

export { Config, Init, Router, Utility };
