<script>
import ResourceList from '@/lib/components/ResourceList.vue';
import Deletism from '@/lib/components/ListActions/Deletism.vue';
import Undeletism from '@/lib/components/ListActions/Undeletism.vue';
import ListAction from '@/lib/components/ListActions/ListAction.vue';
import ListActionDropdown from '@/lib/components/ListActions/ListActionDropdown.vue';
import EditDocument from '@/modules/documents/components/EditDocument.vue';
import CreateDocument from '@/lib/components/ListActions/CreateDocument.vue';
import langMixin from '@/lib/components/Translations/mixins/lang-mixin';

export default {
	name: 'DocumentsList',
	components: {
		Deletism,
		Undeletism,
		ListAction,
		ListActionDropdown,
		EditDocument,
		CreateDocument,
	},
	extends: ResourceList,
	mixins: [langMixin],
	computed: {
		editDocumentRevealed() {
			return this.reveal === 'edit-document';
		},
		createDocumentRevealed() {
			return this.reveal === 'create-document';
		},
	},
};
</script>
