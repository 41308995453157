/**
 * Set/get/remove items from local storage, fallback to cookies if local storage is not available.
 *
 * Usage:
 *   var storage = new Storage;
 *
 *   storage.set(key, value);
 *   storage.get(key);
 *   storage.remove(key);
 *
 */
module.exports = function(customOptions) {

    var options = {
        preferLocalStorage: true,
        cookieExpirationDays: 120, // three months
        cookiePath: '/'
    };

    if (customOptions) {
        $.extend(options, customOptions);
    }

    var checkSupport = function() {
        try {
            var storage = window['localStorage'];
            var test = '__test__';

            // Set and remove an item from local storage to confirm that it is enabled.
            // Safari in private browsing mode may report that localStorage is available
            // when it is not and throw a QuotaExceededError later.
            storage.setItem(test, test);
            storage.removeItem(test);

            return true;

        } catch(e) {
            return false;
        }
    }

    var useLocalStorage = false;

    if (options.preferLocalStorage && checkSupport()) {
        useLocalStorage = true;
    }

    /**
     * Set item in local storage, fallback to cookies.
     *
     * @param {String} key
     * @param {String} value
     */
    this.set = function(key, value) {
        if (typeof value !== undefined && typeof value === 'string') {
            if (useLocalStorage) {
                localStorage.setItem(key, value);
            } else {
                this.createCookie(key, value, options.cookieExpirationDays);
            }
        }
    }

    /**
     * Get item from local storage, fallback to cookies.
     *
     * @param {String} key
     * @param {String} value
     * @return {String|null}
     */
    this.get = function(key) {
        return useLocalStorage ?
            localStorage.getItem(key) :
            this.readCookie(key);
    }

    /**
     * Remove item from local storage, fallback to cookies.
     *
     * @param {String} key
     */
    this.remove = function(key) {
        if (useLocalStorage) {
            localStorage.removeItem(key);
        } else {
            this.removeCookie(key);
        }
    }

    /**
     * Remove cookie.
     *
     * @param {String} key
     */
    this.removeCookie = function(key) {
        this.createCookie(key, '', -1);
    }

    /**
     * Create cookie.
     *
     * @param {String} key
     * @param {String} value
     * @param {Number} expire The number of days until the cookie expires
     * @param {String} domain
     */
    this.createCookie = function(key, value, expire, domain = null) {
        var date = new Date();

        date.setTime(date.getTime() + (expire * 24 * 60 * 60 * 1000));

        var cookie = key + "=" + value + "; expires=" + date.toGMTString() + "; path=" + options.cookiePath;
        if (domain !== null && typeof domain === 'string' && domain.length > 3) {
            cookie += '; domain=' + domain;
        }
        document.cookie = cookie;
    }

    /**
     * Read cookie.
     *
     * @param {String} key
     * @return {String|null}
     */
    this.readCookie = function(key) {
        var keyEquals = key + "=";
        var cookieArray = document.cookie.split(';');

        for (var i = 0, cookieCount = cookieArray.length; i < cookieCount; i++) {
            var cookie = cookieArray[i];

            // Remove any space preceeding the cookie value
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1, cookie.length);
            }

            if (cookie.indexOf(keyEquals) === 0) {
                 return cookie.substring(keyEquals.length, cookie.length);
            }
        }

        return null;
    }
};
