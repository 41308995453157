<template>
	<div class="action-group">
		<div class="title">
			<dropdown
				v-if="overflowMenuVisible(layout)"
				:id="id(layout)"
				button-class="action-list-overflow-button"
				menu-class="sub-dropdown"
			>
				<span slot="label">
					<i class="af-icons af-icons-action-overflow"></i>
				</span>
				<li v-if="layout.editAction">
					<a :href="layout.editAction" class="dropdown-menu-item">{{ editLabel }}</a>
				</li>
				<li v-if="layout.deleteAction">
					<a :href="layout.deleteAction" class="dropdown-menu-item" @click.prevent.stop="deleteLayout(layout)">
						{{ deleteLabel }}
					</a>
				</li>
			</dropdown>
			<div class="title">
				<span>{{ layout.name }}</span>
				<i v-if="layout.shared" class="af-icons af-icons-shared"></i>
			</div>
		</div>
		<div class="actions">
			<a :href="layout.excel">{{ excelLabel }}</a>
			<a :href="layout.csv">{{ csvLabel }}</a>
		</div>
	</div>
</template>

<script>
import { Dropdown } from 'vue-bootstrap';
import $ from 'jquery';
import tectoastr from 'tectoastr';

export default {
	components: {
		Dropdown,
	},
	props: {
		listItem: {
			type: Object,
			default: () => ({}),
			validator: (item) =>
				/* eslint-disable no-prototype-builtins */
				item.hasOwnProperty('id') &&
				item.hasOwnProperty('name') &&
				item.hasOwnProperty('excel') &&
				item.hasOwnProperty('csv'),
			/* eslint-enable no-prototype-builtins */
		},
		excelLabel: {
			type: String,
			default: 'Excel',
		},
		csvLabel: {
			type: String,
			default: 'CSV',
		},
		editLabel: {
			type: String,
			default: 'Edit',
		},
		deleteLabel: {
			type: String,
			default: 'Delete',
		},
	},
	data() {
		return {
			layout: {},
		};
	},
	mounted() {
		this.layout = this.listItem;
	},
	methods: {
		overflowMenuVisible(layout) {
			return layout.editAction || layout.deleteAction;
		},
		id(layout) {
			return 'layout-' + layout.id;
		},
		deleteLayout(layout) {
			this.$emit('deleting', layout);

			$.ajax({
				type: 'DELETE',
				url: layout.deleteAction,
				dataType: 'json',
			})
				.done(() => {
					this.$emit('deleted', layout);
				})
				.fail((error) => {
					tectoastr.error(error.responseJSON.message || document.getElementById('alerts-generic').innerHTML);
				});
		},
	},
};
</script>
