import { supportedLanguages } from '@/lib/utils';
import { onMounted, Ref, ref, SetupFunction } from 'vue';

type Translation = {
	field: string;
	language: string;
	value: string;
};

type Document = {
	shared: boolean;
	translations: Translation[];
};

type Props = {
	name: string;
	document: Document;
};

type View = {
	shared: Ref<boolean>;
	translated: Ref<object>;
	supportedLanguages: string[];
	toggleShared: (value: boolean) => void;
	onTranslated: (updatedTranslated: object) => void;
	setTranslations: () => void;
};

type Hook = <T = () => void, R = void>(callback: T, target?: unknown) => R;

type Dependencies = {
	onMountedHook: Hook;
};

const editDocumentControllerFactory =
	({ onMountedHook }: Dependencies) =>
	(props: Props): View => {
		const shared: Ref = ref(props.document.shared || false);

		const translated: Ref = ref({});

		const toggleShared = (value: boolean): void => {
			shared.value = value;
		};

		const onTranslated = (updatedTranslated: object): void => {
			translated.value = updatedTranslated;
		};

		const setTranslations = (): void => {
			translated.value = {};
			props.document.translations.forEach(
				({ language, field, value }: { language: string; field: string; value: string }) => {
					translated.value[language] = {
						...(translated.value[language] || {}),
						[field]: value,
					};
				}
			);
		};

		onMountedHook(() => setTranslations());

		return {
			shared,
			translated,
			supportedLanguages: supportedLanguages(),
			toggleShared,
			onTranslated,
			setTranslations,
		};
	};

const editDocumentController: SetupFunction<Props, View> = (props: Props) =>
	editDocumentControllerFactory({
		onMountedHook: onMounted as Hook,
	})(props);

export { Props, View, editDocumentController, editDocumentControllerFactory };
