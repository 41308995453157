<template>
  <div class="checkbox styled">
    <input
      :id="getId()"
      :checked="checked"
      class="form-control"
      type="checkbox"
      :disabled="disabled"
      :aria-required="field.required ? 'true' : 'false'"
      :aria-invalid="hasError"
      :aria-describedby="ariaDescribedby"
      @change="onInput(invertValue(value))"
    />
    <field-label :field="field" :is-manager="isManager" :for="getId()" />
    <help-icon v-if="field.helpText" :content="field.helpText" />
  </div>
</template>

<script>
import Field from './Field.vue';
import FieldAccessIcon from './FieldAccessIcon.vue';
import FieldLabel from './FieldLabel.vue';
import HelpIcon from '../Shared/HelpIcon';

export default {
  inject: ['lang'],
  components: {
    FieldAccessIcon,
    FieldLabel,
    HelpIcon
  },
  extends: Field,
  computed: {
    checked() {
      return !this.isNegative(this.value);
    }
  },
  methods: {
    isNegative(value) {
      // eslint-disable-next-line eqeqeq
      return value == 0 || value == null || value === false || value === '';
    },
    invertValue(value) {
      return this.isNegative(value) ? 1 : '';
    }
  }
};
</script>

<style scoped>
.checkbox {
  margin-right: 30px;
}

/* Help icon */
.checkbox > span {
  position: absolute;
  z-index: 2;
  margin-left: 3px;
}
</style>
