<template>
	<div>
		<slot></slot>
	</div>
</template>

<script>
/**
 * this component is intended to wrap up an inline-template
 * with primitive fields i.e. without their own validators
 * wrapped components should use validateMe on an event
 * to sefl-validate
 * validation errors here then provided to descendants
 * i.e. <instant-errors/>
 */

import WithError from '@/lib/components/Fields/validator/WithError';
import validatorMixin from '@/lib/components/Fields/validator/mixin';
import { validationRules } from '@/lib/components/Fields/validator/index';

import components from '@/lib/components/Fields';

export default {
	components: { ...components },
	extends: WithError,
	mixins: [validatorMixin],
	props: {
		fieldType: {
			type: String,
			required: true,
		},
		validateOnBlur: {
			type: Boolean,
			default: true,
		},
		validateOnChange: {
			type: Boolean,
			default: true,
		},
		validateOnMount: {
			type: Boolean,
			default: false,
		},
		rules: {
			type: Array,
			default: () => [],
		},
		fieldValue: {
			type: [String, Number, Boolean, Array, Object],
			default: null,
		},
		validateOnValue: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		const allRules = validationRules(this.fieldType, this.rules, this.required);
		return {
			allRules: [...allRules.validationRules, allRules.requiredValidator].filter((r) => r),
			...allRules,
		};
	},
	watch: {
		fieldValue(newVal, oldVal) {
			if (this.validateOnValue && newVal !== oldVal) {
				this.value = this.fieldValue;
				this.validate();
			}
		},
	},
	mounted() {
		this.domElement = this.$el.querySelector(`#${this.fieldId.replace('.', '\\.')}`);
		if (this.domElement) {
			if (this.validateOnBlur) {
				this.domElement.addEventListener('blur', this.validateMe);
			}

			if (this.validateOnChange) {
				this.domElement.addEventListener('change', this.validateMe);
			}

			if (this.validateOnMount) {
				this.validate(this.domElement.value);
			}
		}
	},
	created() {
		this.$on('update:validationErrors', this.setErrors);
	},
	beforeDestroy() {
		this.$off('update:validationErrors');
	},
	methods: {
		async validateMe(e) {
			this.value = e.target.value;
			await this.validate();
		},
	},
};
</script>
