<template>
  <div>
    <label v-if="label" :for="id">{{ label }}</label>
    <currency-input
      :id="id + '-helper'"
      :value="value"
      class="form-control text-right"
      :currency="null"
      :distraction-free="false"
      :auto-decimal-digits="false"
      :locale="locale"
      @input="onInput"
    />
    <input :id="id" type="hidden" :value="score" :name="name" />
  </div>
</template>

<script>
import { CurrencyInput } from 'vue-currency-input';
import langMixin from '@/lib/components/Translations/mixins/lang-mixin.js';

export default {
  components: { CurrencyInput },
  mixins: [langMixin],
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    value: {
      type: Number,
      required: true
    },
    label: {
      type: [String, null],
      default: null
    }
  },
  data() {
    return {
      score: this.value
    };
  },
  computed: {
    locale() {
      return this.language.replace('_', '-');
    }
  },
  methods: {
    onInput(v) {
      this.score = v;
      this.$emit('input', v);
    }
  }
};
</script>
