/* eslint-disable prettier/prettier */
var $ = require('jquery');
var tectoastr = require('tectoastr');

module.exports = function () {
    /**
     * Voting buttons
     */
    var unvoteButton = $('.unvote-button');

    $('.vote-button').on('click', function () {

        // Check if already voting or voting is disabled
        var voteButton = $(this);
        if (voteButton.hasClass('voting') || voteButton.hasClass('disabled')) {
            return;
        }

				// Disable all vote buttons while voting
				$('.vote-button').prop('disabled', true);

        // Toggle 'voting' state
        var span = voteButton.find('span');
        var originalText = span.text();
        var allowVoteButtonUnvoting = voteButton.data('vote-to-unvote');
        var myVotes = voteButton.attr('data-my-votes'); // Can't use .data() as it caches its value
        var shouldVoteButtonUnvote = allowVoteButtonUnvoting && myVotes === '1';

        voteButton.addClass('voting');
        span.text(voteButton.data('voting'));

        // Send new vote to server
        $.ajax({
            'type': 'POST',
            'url': voteButton.data('url'),
            'data': { votes: (shouldVoteButtonUnvote ? -1 : 1) },
            'dataType': 'json'
        }).done(function (response) {
            voteButtonResponseCallback(response, voteButton);
        }).fail(function (response) {
            // Throw error
            tectoastr.response(response.responseJSON, voteButton.data('failed'));

            // Toggle button back
            voteButton.removeClass('voting');
            span.text(originalText);
        }).always(function () {
					$('.vote-button').prop('disabled', false);
				});
    });

    /**
     * Unvote button
     */
    $('.unvote-button').on('click', function () {

        // Check if already voting
        var voteButton = $('.vote-button');

        if (unvoteButton.hasClass('voting')) {
            return;
        }

        // Toggle 'voting' state
        var span = voteButton.find('span');
        var originalText = span.text();

        unvoteButton.addClass('voting');
        span.text(voteButton.data('unvoting'));

        // Send new vote to server
        $.ajax({
            'type': 'POST',
            'url': voteButton.data('url'),
            'data': { votes: -1 },
            'dataType': 'json'
        }).done(function (response) {
            voteButtonResponseCallback(response, voteButton);
        }).fail(function (response) {
            // Throw error
            tectoastr.response(response.responseJSON);

            // Toggle unvote button back
            unvoteButton.removeClass('voting');
            span.text(originalText);
        });
    });

    function voteButtonResponseCallback(response, voteButton) {
        var span = voteButton.find('span');

        // Update Vote button
        setTimeout(function () {
            voteButton.removeClass('voting').attr('data-my-votes', response.my_current_votes);
        }, 500);

        // Voting counter
        voteButton.closest('.voting-controls').find('.vote-count span').text(response.total_entry_votes);

        // Summaries
        $('span.my-votes').text(response.my_votes);

        // Adjust controls depending on the current number of votes
        if (response.my_entry_votes > 0) {
            span.text(voteButton.data('voted') + ' +' + response.my_entry_votes);
            voteButton.find('.af-icons').removeClass('af-icons-vote').addClass('af-icons-voted');
            voteButton.removeClass('vote').addClass('voted');

            // Show unvote button
            unvoteButton.removeClass('voting').removeClass('hidden');
        } else {
            span.text(voteButton.data('vote'));
            voteButton.find('.af-icons').addClass('af-icons-vote').removeClass('af-icons-voted');
            voteButton.removeClass('voted').addClass('vote');

            // Hide unvote button
            unvoteButton.removeClass('voting').addClass('hidden');
        }
    }
};
