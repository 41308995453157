import Vue from 'vue';
import store from '@/lib/store';
import DomainSetup from './components/DomainSetup';

export default function() {
  const domainHoldEl = document.getElementById('domain-setup');

  if (domainHoldEl) {
    new Vue({
      el: domainHoldEl,
      name: 'DomainSetupApp',
      components: {
        'domain-setup': DomainSetup
      },
      store
    });
  }
}
