/**
 * Select2 Swahili translation.
 */
(function ($) {
	"use strict";

	$.fn.select2.locales['sw'] = {
		formatNoMatches: function () {
			return "Hakuna matokeo";
		},
		formatInputTooShort: function (input, min) {
			var n = min - input.length;
			return "Tafadhali weka herufi " + n + " zaidi";
		},
		formatInputTooLong: function (input, max) {
			var n = input.length - max;
			return "Tafadhali weka herufi " + n + " chache";
		},
		formatSelectionTooBig: function (limit) {
			return "Unaweza kuchagua majimbo " + limit + " tu";
		},
		formatLoadMore: function (pageNumber) {
			return "Matokeo zaidi yanachakatwa...";
		},
		formatSearching: function () {
			return "Inatafutwa...";
		}
	};

	$.extend($.fn.select2.defaults, $.fn.select2.locales['sw']);
})(jQuery);
