import Vue from 'vue';
import store from '@/lib/store';
import OrderList from './components/OrderList';
import StateSelector from '@/lib/components/Selectors/StateSelector.vue';

export default function() {
  const orderList = new Vue({
    el: '#order-list',
    name: 'OrderApp',
    components: {
      StateSelector,
      OrderList
    },
    store
  });

  $(document).one('pjax:end', () => {
    orderList.$destroy();
  });
}
