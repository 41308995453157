<template>
  <progress-bar
    class="judging-progress-bar"
    :value="picksUsed"
    :total="total"
    :units="units"
  >
    <slot></slot>
  </progress-bar>
</template>

<script>
import ProgressBar from '@/lib/components/Shared/ProgressBar.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'PicksProgressBar',
  components: {
    ProgressBar
  },
  props: {
    total: {
      type: Number,
      required: true
    },
    units: {
      type: String,
      default: ''
    },
    preferences: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters('topPick', ['picksUsed'])
  },
  created() {
    for (const i in this.preferences) {
      this.storeEntryPreference(this.preferences[i]);
    }
  },
  methods: {
    ...mapMutations('topPick', ['storeEntryPreference'])
  }
};
</script>
