var $ = require('jquery');

// Don't use redirector if data-prevent-redirector attribute is present
var exclusiveSelector = ':not([data-prevent-redirector])';

$('body')
	.on('click', 'a[data-redirector]' + exclusiveSelector, function () {
		$(this).data('redirector-original', $(this).attr('href'));
		$(this).attr('href', $(this).data('redirector'));
		$(this).attr('target', '_blank');
	})
	.on('focusout', 'a[data-redirector]' + exclusiveSelector, function () {
		let original;
		if ((original = $(this).data('redirector-original'))) {
			$(this).attr('href', original);
			$(this).attr('target', '');
		}
	});
