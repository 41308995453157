<template>
	<div class="comment-container">
		<div class="comment">
			<div class="comment-text" v-html="comment.comment"></div>
			<div class="comment-meta">
				<template v-if="comment.user"> {{ comment.user }}, </template>
				{{ comment.relativeTime }}
			</div>
		</div>
	</div>
</template>

<script>
/**
 * The Comment component.
 */
export default {
	props: {
		comment: {
			type: Object,
			required: true,
		},
	},
};
</script>
