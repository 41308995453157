/* eslint-disable @typescript-eslint/naming-convention */
const parseError = function (error, message) {
	const AttachmentsError = new RegExp(
		// eslint-disable-next-line no-useless-escape
		'^attachmentFields\\.([^.]+)\\.([^.]+)(\\..+)*$',
		'gi'
	);

	const AttachmentsTabError = new RegExp('^attachmentsAndLinks.([0-9]+)$', 'gi');

	const ContributorsTabError = new RegExp('^contributors.([0-9]+)$', 'gi');
	const ContributorsError = new RegExp('^contributors\\.([^.]+)\\.([^.]+)(\\..+)*$', 'gi');

	const RefereesTabError = new RegExp('^referees\\.([^.]+)\\.([0-9]+)$', 'gi');
	const RefereesError = new RegExp('^referees\\.([^.]+)\\.([^.]+)\\.([^.]+)(?:\\.(.+))?$', 'i');

	const FieldsError = new RegExp('^values\\.([^\\.]+)(\\..+)*$', 'gi');

	let matches;

	if ((matches = FieldsError.exec(error))) {
		return {
			type: 'EntryField',
			fieldSlug: matches[1],
			message: message,
		};
	}

	if ((matches = AttachmentsError.exec(error))) {
		return {
			type: 'AttachmentField',
			fileId: matches[1],
			fieldSlug: matches[2],
			message: message,
		};
	}

	if ((matches = ContributorsError.exec(error))) {
		return {
			type: 'ContributorField',
			resourceId: matches[1],
			fieldSlug: matches[2],
			message: message,
		};
	}

	if ((matches = RefereesError.exec(error))) {
		return {
			type: 'RefereeField',
			resourceId: matches[2],
			fieldSlug: matches[4] || matches[3],
			message: message,
		};
	}

	if (
		(matches = {
			title: 'Details',
			chapterId: 'Details',
			categoryId: 'Details',
			seasonId: 'Details',
			userId: 'Details',
			user: 'Details',
			attachments: 'Attachments',
		}[error])
	) {
		return {
			type: 'Tab',
			tab: matches,
			message: message,
			baseField: error,
		};
	}

	// Contributor tabs
	if ((matches = ContributorsTabError.exec(error))) {
		return {
			type: 'Tab',
			tab: 'Contributors',
			tabId: matches[1],
			message: message,
		};
	}

	// Referee tabs
	if ((matches = RefereesTabError.exec(error))) {
		return {
			type: 'Tab',
			tab: 'Referees',
			tabId: matches[2],
			message: message,
		};
	}

	// Attachment tabs
	if ((matches = AttachmentsTabError.exec(error))) {
		return {
			type: 'Tab',
			tab: 'Attachments',
			tabId: matches[1],
			message: message,
		};
	}

	return {
		message: message,
	};
};

/**
 * Helper function for making redirects
 */
const goto = (uri) => (window.location.href = uri);

export { parseError, goto };
