<template>
  <div>
    <div
      v-for="date in ['start', 'end', 'reviewEnd']"
      v-show="isVisible(date)"
      :key="date"
      :class="{ 'form-group': true, disabled: readOnly }"
    >
      <label :id="`${date}-label`" :for="date">
        {{ getLabel(date) }}
      </label>
      <localised-datepicker
        :id="date"
        mode="datetime"
        :value="getDate(date)"
        :defaultTimezone="defaultTimezone"
        :include-timezone="true"
        :preselect-current-date="false"
        :highlight-today="false"
        :hidden-fields="true"
        :disabled="readOnly"
        @update:value="value => $emit('updated', date, value)"
      />
    </div>
  </div>
</template>

<script>
import LocalisedDatepicker from '@/lib/components/Shared/LocalisedDatepicker';
import { mapState } from "vuex";

export default {
  inject: ['lang'],
  components: {
    LocalisedDatepicker
  },
  props: {
    startDate: {
      type: Object,
      default: null
    },
    endDate: {
      type: Object,
      default: null
    },
    reviewEndDate: {
      type: Object,
      default: null
    },
    reviewEndDateVisible: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('global', ['defaultTimezone'])
  },
  methods: {
    getDate(date) {
      switch (date) {
        case 'start':
          return this.startDate;
        case 'end':
          return this.endDate;
        case 'reviewEnd':
          return this.reviewEndDate;
        default:
          return null;
      }
    },
    getLabel(date) {
      switch (date) {
        case 'start':
          return this.lang.get('rounds.form.starts.label');
        case 'end':
          return this.lang.get('rounds.form.ends.label');
        case 'reviewEnd':
          return this.lang.get('rounds.form.review_ends.label');
        default:
          return null;
      }
    },
    isVisible(date) {
      return ['start', 'end'].includes(date) || this.reviewEndDateVisible;
    }
  }
};
</script>
