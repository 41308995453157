import state from './state';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state,
  mutations,
  getters
};
