import { mountVueComponent } from '@/lib/mount-vue-component';

export default function () {
	import('@/lib/components/Shared/HelpIcon.vue').then((helpIcon) => {
		mountVueComponent('help-icon', helpIcon);
	});

	import('@/modules/category/component/CategoryForm.vue').then((categoryForm) => {
		mountVueComponent('category-form', categoryForm, true);
	});
}
