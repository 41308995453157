import Common from './common';
import { mountVueComponent } from '@/lib/mount-vue-component';
import { Multiselect } from 'vue-bootstrap';
import Vue from 'vue';

export default function () {
	var common = new Common();

	$('.btn-api-key-show').click(function () {
		$('.lbl-api-key-show').addClass('hidden');
		$('.input-api-key-masked').addClass('hidden');
		$('.lbl-api-key-hide').removeClass('hidden');
		$('.input-api-key-full').removeClass('hidden');

		let slug = $('#api-key-slug').val();
		$.get('/api-key/' + slug + '/show');
	});

	$('.btn-api-key-hide').click(function () {
		$('.lbl-api-key-hide').addClass('hidden');
		$('.input-api-key-full').addClass('hidden');
		$('.lbl-api-key-show').removeClass('hidden');
		$('.input-api-key-masked').removeClass('hidden');
	});

	$('.btn-api-key-copy').click(function () {
		common.copy($('.input-api-key-full').val());
	});

	new Vue({
		el: '#api-key-contexts',
		name: 'ApiKeyContextsApp',
		components: {
			Multiselect,
		},
	});

	// tooltip-icon
	import('@/lib/components/Shared/TooltipIcon.vue').then((tooltipIcon) => {
		mountVueComponent('tooltip-icon', tooltipIcon);
	});
}
