import Vue from 'vue';
import UsersInviteForm from './UsersInvite/UsersInviteForm.vue';
import store from '../../lib/store';

export default function() {
  const usersInviteFormEl = document.getElementById('users-invite-form');

  if (usersInviteFormEl) {
    const usersInviteForm = new Vue({
      el: usersInviteFormEl,
      name: 'UsersInviteApp',
      components: {
        UsersInviteForm
      },
      store
    });

    $(document).one('pjax:end', () => {
      usersInviteForm.$destroy();
    });
  }
};
