/**
 * URL manipulation helpers
 */

module.exports = {
    /**
     * Get URL parameter by name.
     *
     * Based on:
     * https://davidwalsh.name/query-string-javascript
     *
     * @param {String} url
     * @param {String} name
     * @return {String}
     */
    getUrlParameter: function(url, name) {
        var name, regex, results;

        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        results = regex.exec(url);

        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    },

    /**
     * Set URL parameter.
     *
     * @param {String} url
     * @param {String} name
     * @param {String} value
     * @return {String}
     */
    setUrlParameter: function(url, name, value) {
        var a = document.createElement('a');
        var regex = /(?:\?|&amp;|&)+([^=]+)(?:=([^&]*))*/g;
        var match;
        var str = [];

        a.href = url;

        name = encodeURIComponent(name);

        while (match = regex.exec(a.search)) {
            if (name != match[1]) {
                str.push(match[1]+(match[2]?"="+match[2]:""));
            }
        }

        str.push(name+(value?"="+encodeURIComponent(value):""));

        a.search = str.join('&');

        return a.href;
    },

    /**
     * Removes http parameter from given url and cleans up trailing '?' if needed.
     *
     * Regex101 tests: https://regex101.com/r/6sw44p/2
     */
    removeUrlParameter: function (url, name) {
        if (url.indexOf(name + '=') !== -1) {
            var newUrl = url.replace(new RegExp('([?&])(' + name + '=[^\\\\&=]*)'), '$1');
            if (newUrl.endsWith('&')) {
                newUrl = newUrl.slice(0, -1);
            }
            if (newUrl.endsWith('?')) {
                newUrl = newUrl.slice(0, -1);
            }

            window.history.replaceState({}, document.title, newUrl);
            return newUrl;
        }

        return url;
    },
};
