import { vueData } from '@/domain/services/VueData';

/* eslint-disable @typescript-eslint/naming-convention */
type Headers = {
	'X-CSRF-TOKEN': string;
	'X-Requested-With': 'XMLHttpRequest';
	Accept: 'application/json';

	[key: string]: string;
};
/* eslint-enable @typescript-eslint/naming-convention */

const headersFactory = (customHeaders: Record<string, string> = {}) =>
	({
		'X-CSRF-TOKEN': vueData.CSRF_TOKEN,
		'X-Requested-With': 'XMLHttpRequest',
		Accept: 'application/json',
		...customHeaders,
	} as Headers);

export { headersFactory, Headers };
