export default {
  inEditorMode: state => state.editing,

  hasSetting: state => key =>
    state.config[key] !== undefined && state.config[key] !== null,

  setting: state => (key, defaultValue = null, test = () => true) =>
    state.config[key] && test(state.config[key]) ? state.config[key] : defaultValue,

  hasImage: state => key =>
    state.files[key] &&
    state.files[key].image !== undefined &&
    state.files[key].image !== null,

  image: state => (key, defaultImage = null) =>
    (state.files[key] || {}).image || defaultImage,

  file: state => (key, defaultImage = null) =>
      (state.files[key] || null),

  currentColorId: state => state.currentColorId,

  customColor: state => (key, defaultValue = null) =>
    state.customColors[key] === null ? defaultValue : state.customColors[key],

  defaultColor: state => state.currentColorDefault,

  uploadsInProgress: state => Object.keys(state.uploads).filter(key => state.uploads[key] !== 'completed' && state.files[key] !== '' && state.files[key] !== null).length,

  nonStandardColorsCount: state => Object.keys(state.customColors).filter(key => state.customColors[key] !== null).length > 0,
};
