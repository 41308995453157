<template>
  <div :class="['simple-widget', widgetClass]">
    <slot v-if="hasTitle" name="title" class="widget-title"></slot>
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  props: {
    widgetClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    hasTitle() {
      return !!this.$slots.title;
    }
  }
};
</script>

<style scoped>
/* Makes RTL not overlap the title */
.widget-title {
  display: block !important;
}
</style>
