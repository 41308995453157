<template>
	<div class="row pbl plm">
		<div class="toggle-container pull-left">
			<label :for="id" class="toggle-content-label">
				{{ lang.get('fields.auto_tag.label') }}
				<help-icon :content="lang.get('fields.auto_tag.help')" />
			</label>
			<div class="toggle-content-switch">
				<toggle-switch
					:id="id"
					name="autoTag"
					:on-label="lang.get('buttons.on')"
					:off-label="lang.get('buttons.off')"
					:use-inner-state="true"
					:checked="status"
					:aria-label="lang.get('fields.auto_tag.label')"
					v-on="$listeners"
				>
				</toggle-switch>
			</div>
		</div>
	</div>
</template>

<script>
import ToggleSwitch from '@/lib/components/Shared/ToggleSwitch';
import HelpIcon from '@/lib/components/Shared/HelpIcon.vue';

export default {
	components: { HelpIcon, ToggleSwitch },
	inject: ['lang'],
	props: {
		status: {
			type: Boolean,
			required: true,
		},
		id: {
			type: String,
			default: 'toggle-auto-tag',
		},
	},
};
</script>
