<template>
  <div class="advanced-settings">
    <span>
      {{ lang.get('fields.form.conditional.when') }}
    </span>
    <select-field
      :id="`amount-${position}-field-id`"
      class="input-sm"
      :name="`amount[${position}][fieldId]`"
      :value="variant.fieldId"
      :items="fields"
      @selected="
        (name, fieldId) =>
          $emit('fieldSelected', variant.id, 'fieldId', fieldId)
      "
    />
    <span>
      {{ lang.get('fields.form.conditional.options.is') }}
    </span>
    <select-field
      :id="`amount-${position}-field-value`"
      class="input-sm"
      :name="`amount[${position}][fieldValue]`"
      :value="variant.fieldValue"
      :items="fieldValues[variant.fieldId] || []"
      id-property="value"
      value-property="label"
      @selected="
        (name, value) =>
          $emit('fieldValueSelected', variant.id, 'fieldValue', value)
      "
    />
  </div>
</template>

<script>
import { SelectField } from 'vue-bootstrap';

export default {
  inject: ['lang'],
  components: {
    SelectField
  },
  props: {
    position: {
      type: Number,
      required: true
    },
    variant: {
      type: Object,
      required: true
    },
    fields: {
      type: Array,
      default: () => []
    },
    fieldValues: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style scoped>
.advanced-settings {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50px;
  min-width: 400px;
}

.advanced-settings span {
  flex: 0 0 auto;
  padding: 0 10px;
}

.advanced-settings span:first-child {
  padding-left: 0;
}
</style>
