<template>
	<editor
		v-bind="$props"
		@blur="onBlur"
		@input="onInput"
		@keyup="onKeyup"
		@focus="onFocus"
		@uploadFile="onUploadFile"
	></editor>
</template>

<script>
import CKEditor from '@/lib/components/Shared/editor/ckeditor/CKEditor';
import SimpleMde from '@/lib/components/Shared/editor/simplemde/SimpleMde';
import { defineComponent } from 'vue';
import { getGlobal } from '@/lib/utils';
import { textEditorController } from '@/lib/components/Shared/editor/TextEditor.controller';
import { useEditorProps } from '@/lib/components/Shared/editor/editorProps';
import { useUploadProps } from '@/lib/components/Shared/editor/uploads/uploadsProps';

export default defineComponent({
	components: {
		editor: getGlobal('editor') === 'ckeditor' ? CKEditor : SimpleMde,
	},

	props: {
		...useUploadProps,
		...useEditorProps,
	},

	setup(props, { emit }) {
		return textEditorController(props, emit);
	},
});
</script>
