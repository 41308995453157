import { getFileMetadata } from '@/modules/entries/components/FileMetadata.api';
import { Hook } from '@/domain/utils/Types';
import { onMounted, Ref, ref, SetupFunction } from 'vue';
import { trans, Trans } from '@/domain/dao/Translations';

type Dependencies = {
	onMountedHook: Hook;
};

type Props = {
	file: number;
};

type View = {
	lang: Trans;
	size: Ref<string | null>;
	dataContent: Ref<string | null>;
	loading: Ref<boolean>;
	hasError: Ref<boolean>;
};

const fileMetadataControllerFactory =
	({ onMountedHook }: Dependencies) =>
	(props: Props): View => {
		const loading = ref(true);
		const size = ref('');
		const dataContent = ref('');
		const hasError = ref(false);

		onMountedHook(async () => {
			loading.value = true;
			try {
				const response = await getFileMetadata()(props.file);
				size.value = response.size;
				dataContent.value = response.dataContent;
			} catch (error) {
				hasError.value = true;
			} finally {
				loading.value = false;
			}
		});

		return {
			hasError,
			loading,
			size,
			dataContent,
			lang: trans(),
		};
	};

const fileMetadataController: SetupFunction<Props, View> = (props: Props) =>
	fileMetadataControllerFactory({
		onMountedHook: onMounted as Hook,
	})(props);

export { fileMetadataController, fileMetadataControllerFactory, Props, View };
