import Vue from 'vue';
import { defaultState } from './state';

export default {
  /**
   * Store buttons related data
   */
  storeButtonsData(state, data) {
    Vue.set(state, 'awaitingPayment', data.awaitingPayment);
    Vue.set(state, 'canPay', data.canPay);
    Vue.set(state, 'lockedOnSubmission', data.lockedOnSubmission);
    Vue.set(state, 'paymentOnSubmit', data.paymentOnSubmit);
    Vue.set(state, 'prepaymentRequired', data.prepaymentRequired);
    Vue.set(state, 'readOnly', data.readOnly);
    Vue.set(state, 'resubmissionPossible', data.resubmissionPossible);
    Vue.set(state, 'showSubmitOnFirstTab', data.showSubmitOnFirstTab);
    Vue.set(state, 'submissionPossible', data.submissionPossible);
    Vue.set(state, 'ineligible', data.ineligible);
  },

  /**
   * Reset state to default state
   */
  resetState(state) {
    Object.assign(state, defaultState());
  }
};
