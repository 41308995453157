import { getGlobalData } from '@/lib/utils';
const allFeaturesData = getGlobalData('features');
const featuresData = [];

if (Array.isArray(allFeaturesData)) {
	allFeaturesData.forEach((feature) => {
		if (feature.enabled) {
			featuresData.push(feature.feature);
		}
	});
}

const features = {
	enabled: {
		has: (key) => Object.values(featuresData).includes(key),
	},
};

const useFeatures = (keys) => keys.map((key) => features.enabled.has(key));

const featureEnabled = (key) => features.enabled.has(key);

export { featureEnabled, features, useFeatures };
