<template>
  <div>
    <div v-for="(alert, key) in messages" :key="key">
      <div :class="`alert-${alert.type} sticky island`" role="alert" tabindex="-1">
        <div class="icon">
          <div :class="`af-icons-md af-icons-md-alert-${alert.type}`"></div>
        </div>
        <div class="message" v-html="alert.message"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    message: {
      type: Object,
      default: () => ({
        message: '',
        type: 'info'
      })
    },
    showFormErrors: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('validation', ['formError']),
    ...mapGetters('global', ['flashMessage']),
    messages() {
      return [this.message, this.showFormErrors ? this.formError : {}, this.flashMessage].filter(
        message => message.message && message.message.length
      );
    }
  }
};
</script>
