import Vue from 'vue';
import { defaultState } from './state';

export default {
  startEditorMode(state) {
    Vue.set(state, 'editing', true);
  },

  endEditorMode(state) {
    Vue.set(state, 'editing', false);
  },

  updateTheme(state, settings = {}) {
    Object.keys(settings).forEach(setting => {
      if (state.config.hasOwnProperty(setting)) {
        Vue.set(state.config, setting, settings[setting]);
      }

      if (state.files.hasOwnProperty(setting)) {
        Vue.set(state.files, setting, settings[setting]);
      }
    });
  },

  updateImage(state, settings = {}) {
    Object.keys(settings).forEach(setting => {
      if (state.files.hasOwnProperty(setting)) {
        Vue.set(state.files, setting, {...state.files[setting], image: settings[setting]});
      }
    });
  },

  resetState(state) {
    Object.assign(state, defaultState());
  },

  setCurrentColor(state, currentColor) {
    Vue.set(state, 'currentColorId', currentColor.setting);
    Vue.set(state, 'currentColorDefault', currentColor.default);
  },

  updateCustomColors(state, colors){
    Vue.set(state, 'customColors', {...state.customColors, ...colors});
  },

  clearCustomColors(state){
    Vue.set(state, 'customColors', {});
  },

  setUploadStatus(state, status) {
    Vue.set(state, 'uploads', {...state.uploads, ...status});
  }
};
