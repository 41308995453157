import Vue from 'vue';
import DocumentsList from './components/DocumentsList';
import store from '@/lib/store';

export default function () {
	const documentsList = new Vue({
		el: '#documents-list',
		name: 'DocumentsApp',
		components: {
			DocumentsList,
		},
		store,
	});

	$(document).one('pjax:end', () => {
		documentsList.$destroy();
	});
}
