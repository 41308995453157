import { GettingEndpoint } from '@/domain/services/Api/Endpoint';
import { RoutesGetter, useRoutesDao } from '@/domain/dao/Routes';

const getFileMetadata = () => {
	const routes = useRoutesDao<{
		file: {
			metadata: (id: number) => string;
		};
	}>();

	return GettingEndpoint<{
		size: string;
		dataContent: string;
	}>(routes.file.metadata as RoutesGetter);
};

export { getFileMetadata };
