<script>
/**
 * Poll for the payment status and redirect to the next step (returnUrl) as soon as the payment
 * is confirmed to be chargable.
 */
export default {
  props: {
    statusUrl: {
      type: String,
      required: true
    },
    returnUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      status: 'pending',
      maxPollCount: 60,
      pollCount: 0
    };
  },
  computed: {
    pending() {
      return this.status === 'pending';
    },
    failed() {
      return this.status === 'failed';
    }
  },
  mounted() {
    this.poll();
  },
  methods: {
    poll() {
      this.$http.get(this.statusUrl).then(
        response => {
          const status = response.data.status;

          if (status === 'ready') {
            window.location.href = this.returnUrl;
          } else if (
            status === 'pending' &&
            this.pollCount < this.maxPollCount
          ) {
            this.pollCount += 1;
            setTimeout(this.poll, 3000);
          } else {
            this.status = 'failed';
          }
        },
        () => {
          this.status = 'failed';
        }
      );
    }
  }
};
</script>
