import { processDocumentHtml } from '@/lib/components/Shared/editor/ckeditor/document-importer';

export const onPastedInput = (e, data, editor) => {
	if (editor.isReadOnly) {
		return;
	}

	const dataTransfer = data.dataTransfer;
	const pastedHtml = dataTransfer.getData('text/html');

	let input = processDocumentHtml(pastedHtml);

	if (!input) {
		input = dataTransfer.getData('text/plain');
	}

	data.content = editor.data.htmlProcessor.toView(input);
};
