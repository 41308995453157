import Vue from 'vue';
import { defaultState } from './state';

export default {
  /**
   * Store global state
   */
  storeGlobalState(state, payload) {
    const property = Object.keys(payload)[0];
    const value = Object.values(payload)[0];

    if (state.hasOwnProperty(property)) {
      Vue.set(state, property, value);
    }
  },

  /**
   * Reset state to default state
   */
  resetState(state) {
    Object.assign(state, {...defaultState(), currentBreadcrumb: state.currentBreadcrumb || null });
  },

  storeCurrentBreadcrumb(state, value) {
    Vue.set(state, 'currentBreadcrumb', value);
  },

  storeHistory(state, value) {
    Vue.set(state, 'history', value);
  },

  setLastModifiedField(state, field) {
    Vue.set(state, 'lastModifiedField', field);
  }
};
