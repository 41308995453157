import { createDao } from '@/domain/utils/DataAccessor';
import { vueData } from '@/domain/services/VueData';
import { isArray, isObject, isPrimitive } from '@/domain/utils/TypePredicates';
import { NestedObject, Primitive } from '@/domain/utils/UtilityTypes';

/**
 * Gives access to routes passed from the backend with
 * VueData::registerRoutes([ an array of laravel route names]
 *
 * Routes are retrieved as functions that accept uri parameters
 * if there are no parameters just call function without parameters

 const { forms } = useRoutesDao<{
	 forms: {
	 	edit: (p?: { form: number }) => string;
	 	redirect: () => string;
	 };
 }>();

 const formEdit = forms.edit({ form: 123 })
 const formRedirect = forms.redirect()

 */
const makeRoute = (uri: string, uriParameters: unknown) => {
	const queryParamRegexp = new RegExp('{([^{]+)??}', 'g');

	if (isObject<{ [key: string]: string | number | boolean }>(uriParameters)) {
		uri = uri.replace(queryParamRegexp, (v, name: string) => uriParameters[name.replace('?', '')]?.toString() || '');

		const searchParams = new URLSearchParams(uri.split('?')[1] || '');

		Object.keys(uriParameters)
			.filter((param) => uri.indexOf(param) === -1)
			.forEach((param) => searchParams.append(param, uriParameters[param] as string));

		if ([...searchParams.keys()].length > 0) {
			uri = uri.split('?')[0] + '?' + searchParams.toString();
		}
	} else {
		let uriParametersArray: (string | number | boolean)[] = [];

		if (isPrimitive<string | number | boolean>(uriParameters)) {
			uriParametersArray = [uriParameters];
		}

		if (isArray<string | number | boolean>(uriParameters)) {
			uriParametersArray = uriParameters;
		}

		if (isArray<string | number | boolean>(uriParametersArray)) {
			let i = 0;
			uri = uri.replace(queryParamRegexp, () => uriParametersArray[i++]?.toString() || '');
		}
	}

	return ('/' + uri).replace(/\/*$/, '');
};

type RoutesParam = Primitive | Primitive[] | Record<string, Primitive>;
type RoutesGetter = (p?: RoutesParam) => string;

type RoutesDAO = NestedObject<RoutesGetter>;

const useRoutesDao = <T = unknown>(nested = true) => {
	const routes = {} as RoutesDAO;

	for (const key in vueData.routes) {
		if (typeof vueData.routes[key] === 'string') {
			routes[key] = (p?: RoutesParam) => makeRoute(vueData.routes[key] as string, p);
		}
	}

	return createDao<T>(routes, nested);
};

export { useRoutesDao, RoutesGetter, RoutesParam };
