const validNumberMixin = {
  methods: {
    validNumber (number) {
      // Check for empty input, NaNs or numbers in scientific notation
      return number !== null && number !== '' && !isNaN(number) && !/e/g.test(number.toString());
    }
  }
};

module.exports = validNumberMixin;
