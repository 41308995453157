/**
 * Accessibility tweaks
 */

/**
 * Skip to Content
 *
 * This little snippet helps the skip to content link work by focusing
 * on the #content div, allowing the next tab to go inside - rather than
 * back to the beginning. It's only really needed for WebKit and Opera,
 * other browsers are smart enough (mostly) to figure it out.
 *
 * @url https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/
 */
$('#skip-to-content').on('click', function(event) {
    var skipTo = '#content';
    $(skipTo).attr('tabindex', -1).on('blur focusout', function () {
        $(this).removeAttr('tabindex');
    }).focus();

    event.preventDefault();
});
