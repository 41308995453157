import Vue from 'vue';
import { mountVueComponent } from '@/lib/mount-vue-component';
import MyEntryList from './components/MyEntryList';
import FormsGalleryList from "@/modules/entries/components/FormsGalleryList";

export default function() {
  const myEntryListDOM = document.getElementById('my-entry-list');

  const store = require('@/lib/store/index').default;
  if (myEntryListDOM) {
    const myEntryList = new Vue({
      el: '#my-entry-list',
      components: {
        'my-entry-list': MyEntryList,
        FormsGalleryList
      },
      store
    });

    $(document).one('pjax:end', () => {
      myEntryList.$destroy();
    });
  }

  // CollapsibleBox - <collapsible-box />
  import('@/lib/components/Shared/CollapsibleBox').then(
    collapsibleBoxElement => {
      mountVueComponent('collapsible-box', collapsibleBoxElement);
    }
  );
}
