import { mountVueComponent } from '@/lib/mount-vue-component';
import $ from 'jquery';
import { Multiselect } from 'vue-bootstrap';
import FileTypes from '@/lib/filetypes';
import Vue from 'vue';

export default function() {
  new FileTypes();

    new Vue({
      el: '#category-selector',
      name: 'CategorySelectorApp',
      components: {
            Multiselect
        }
    });

  import('@/lib/components/Fields/ScoringInput').then(ScoringInput =>mountVueComponent('scoring-input', ScoringInput));

  var toggleOptions = function() {
    var type = $('input:radio[name="type"]:checked');

    if (type.length) {
      var options = $('.' + type.val().toLowerCase() + '-options');

      $('.options').addClass('hidden');

      if (options.length) {
        options.removeClass('hidden');
      }
    }
  };

  // Whenever a category selection is made (aka, all, or select), we need to show the appropriate container
  var categorySelection = function() {
    var value = $('input[name=categoryOption]:checked').val();

    if (value == 'select') {
      $('#categorySelectionContainer').show();
    } else {
      $('#categorySelectionContainer').hide();
    }
  };

  $('input:radio[name="type"]').click(function() {
    toggleOptions();
  });

  $('input[name=categoryOption]').change(categorySelection);

    toggleOptions();
    categorySelection();

    // Decode seasonal notifications
    var seasonSelector = $('#seasonId');
    var decodedSeasonEligibleNotifications = seasonEligibleNotifications.clarify();
    var decodedSeasonIneligibleNotifications = seasonIneligibleNotifications.clarify();

    // Refresh notifications lists.
    var refreshNotifications = function (selector, decoded) {
        var eligible = decoded[seasonSelector.val()];
        var eligibleNotification = $(selector);
        var eligibleNotificationValue = eligibleNotification.val();

        eligibleNotification.children().remove();

        $.each(eligible, function(i) {
            if (eligibleNotificationValue === null) {
                eligibleNotificationValue = i;
            }
            eligibleNotification.append($('<option>', {val: i, text: eligible[i], selected: i == eligibleNotificationValue }));
        });

        eligibleNotification.val(eligibleNotificationValue);
    };

    seasonSelector.on('change', function() {
        refreshNotifications('#eligibleNotification', decodedSeasonEligibleNotifications);
        refreshNotifications('#ineligibleNotification', decodedSeasonIneligibleNotifications);
    });

    refreshNotifications('#eligibleNotification', decodedSeasonEligibleNotifications);
    refreshNotifications('#ineligibleNotification', decodedSeasonIneligibleNotifications);

    toggleOptions();
    categorySelection();

    // helpIcon
    import('@/lib/components/Shared/HelpIcon.vue').then(HelpIcon => {
        mountVueComponent('help-icon', HelpIcon);
    });
}
