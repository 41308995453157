<template>
  <div class="marker-action">
    <list-action-form
      ref="form"
      :ids="ids"
      :labels="labels"
      :route="route"
      :method="method"
      @submitted="toggle"
    >
      <input type="hidden" name="contentBlockId" :value="contentBlockId" />
      <portal :to="name">
        <modal
          v-model="showModal"
          :header="false"
          :confirm-button-label="labels.title"
          :close-button-label="labels.cancel"
          :header-close-button="true"
          :confirm-disabled="!contentBlockId"
          :confirm-on-enter="false"
          @closed="handleClose"
          @confirmed="submit"
        >
          <close-icon
            slot="before-content"
            @click.prevent="toggle"
          ></close-icon>
          <div :key="name">
            <label for="templateSelector">{{ labels.templateSelector }}</label>
            <select-field
              id="templateSelector"
              name="contentBlocks"
              :items="contentBlocks"
              :empty-option="false"
              id-property="id"
              value-property="title"
              @selected="(name, id) => (contentBlockId = id)"
            />
          </div>
        </modal>
      </portal>
    </list-action-form>
  </div>
</template>

<script>
import ListAction from './ListAction.vue';
import CloseIcon from './Partials/CloseIcon';
import { SelectField, Modal } from 'vue-bootstrap';
import modalMixin from './mixins/modal-mixin';

export default {
  components: {
    SelectField,
    CloseIcon,
    Modal
  },
  extends: ListAction,
  mixins: [modalMixin],
  props: {
    name: {
      type: String,
      required: true
    },
    contentBlocks: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      contentBlockId: null
    };
  },
  methods: {
    handleClose() {
      this.contentBlockId = null;
      this.modalClosed();
    }
  }
};
</script>

<style scoped lang="scss">
.marker-action-contract-creator {
  .buttons {
    margin-left: 10px;
    display: inline-block;
    .btn {
      &:first-child {
        margin-left: 0;
      }
      margin-right: 0;
      margin-left: 10px;
    }
  }
}
</style>
