import { truthy } from '@/legacy/Equality';
import { useGlobal } from '@/services/global/global.interface';

export default {
	props: {
		settings: {
			type: Object,
			default: () => useGlobal('settings'),
		},
	},
	data() {
		return {
			settingsService: null,
		};
	},
	provide() {
		this.settingsService = {
			settings: this.settings,
			get: (setting) => this.settings[setting] || false,
			enabled: (setting) => truthy(this.settings[setting]),
		};

		return {
			settingsService: this.settingsService,
		};
	},
};
