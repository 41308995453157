import { AnyEditor, EditorProps } from '@/lib/components/Shared/editor/TextEditor.types';
import { EditorEmit, EditorEvents } from '@/lib/components/Shared/editor/EditorEvents';

const textEditorController = (props: EditorProps, emit: EditorEmit) => ({
	onBlur: () => emit(EditorEvents.Blur),
	onFocus: (element: AnyEditor) => emit(EditorEvents.Focus, element),
	onInput: (html: string) => emit(EditorEvents.Input, html),
	onKeyup: (element: HTMLElement) => emit(EditorEvents.Keyup, element),
	onUploadFile: () => emit(EditorEvents.Upload),
});

export { textEditorController };
