/**
 * Select2 Hindi translation
 */
(function ($) {
    "use strict";

    $.fn.select2.locales['in'] = {
    	formatMatches: function (matches) { if (matches === 1) { return "एक परिणाम उपलब्ध है, इसे चुनने के लिए एंटर दबाएं।"; } return matches + " परिणाम उपलब्ध हैं, नेविगेट करने के लिए ऊपर और नीचे तीर का उपयोग करें।"; },
        formatNoMatches: function () { return "कोई मेल नहीं मिले"; },
        formatInputTooShort: function (input, min) { var n = min - input.length; return "कृपया एन्टर करें " + n + " या अधिक अक्षर" + (n == 1 ? "" : "अक्षर"); },
        formatInputTooLong: function (input, max) { var n = input.length - max; return "कृपया मिटा दे " + n + " अक्षर" + (n == 1 ? "" : "अक्षर"); },
        formatSelectionTooBig: function (limit) { return "आप केवल चुन सकते हैं " + limit + " वस्तु" + (limit == 1 ? "" : "वस्तुएँ"); },
        formatLoadMore: function (pageNumber) { return "अधिक परिणाम लोड हो रहे हैं..."; },
        formatSearching: function () { return "खोज कर रहा है..."; }
    };

    $.extend($.fn.select2.defaults, $.fn.select2.locales['in']);
})(jQuery);
