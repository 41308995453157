import globalComponents from '@/global-components';
import { kebabCase } from 'lodash';
import { VueModule } from '@/domain/services/VueModule';
import Vue, { DefineComponent, defineComponent } from 'vue';

const canBeMounted = (el: Element): boolean =>
	el.closest('[inline-template]') === null && el.closest('#judging-dashboard-vue') === null;

const loadGlobalComponentsModules = async () => {
	const { default: globalComponents } = await import('@/global-components');

	const elementsToBeMounted: {
		el: Element;
		component: DefineComponent;
		name: string;
	}[] = [];

	/**
	 * Find all global components in the DOM and create an array of objects
	 */
	Object.keys(globalComponents).forEach((key) => {
		Array.from(document.getElementsByTagName(kebabCase(key)))
			.filter((el) => canBeMounted(el))
			.map((el) => ({
				el: el,
				component: (globalComponents as unknown as Record<string, DefineComponent>)[key],
				name: key,
			}))
			.forEach((element) => elementsToBeMounted.push(element));
	});

	if (elementsToBeMounted.length > 0) {
		/**
		 *  We need to import Vuex here because it is not available in the global scope,
		 *  and then we mount the global components one by one
		 */
		const vuex = await import('@/plugins/vuex');
		const plugins = vuex.default();

		elementsToBeMounted.forEach((element) => {
			VueModule.create({
				...plugins,
				el: element.el,
				name: element.name,
				components: {
					[element.name]: defineComponent(element.component),
				},
			});
		});
	}
};

const boot = (vueContext = Vue) => {
	Object.keys(globalComponents).forEach((key) => {
		vueContext.component(key, (globalComponents as Record<string, DefineComponent>)[key]);
	});
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const GlobalComponentsResolver = {
	boot,
	loadGlobalComponentsModules,
};

export { GlobalComponentsResolver };
