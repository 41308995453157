<template>
  <button type="submit" class="btn btn-primary btn-lg" :disabled="!enabled">
    {{ lang.get('users.buttons.send_invite') }}
  </button>
</template>

<script>
export default {
  inject: ['lang'],
  props: {
    enabled: {
      type: Boolean,
      required: false
    }
  }
};
</script>
