const inputRegex = /\!\[(\d+)\]\(([^\]]*?)\)/g; // Regex tests: https://regex101.com/r/FNw2tz/2

/**
 * Converts our old custom markdown video notation to HTML.
 *
 * Example:
 * ![300](youtu.be/A)
 * becomes
 * <figure class="media" width="300px"><oembed url="youtu.be/A"></oembed></figure>
 */
export default function convertMarkdownVideoNotationToHtml(html) {
	const replaceWith = '<figure class="media" width="$1px">' + '<oembed url="$2"></oembed>' + '</figure>';

	return html.replace(inputRegex, replaceWith);
}
