<template>
	<!-- Edit mode  -->
	<div v-if="mode === 'edit'">
		<button
			class="btn btn-secondary btn-sm comment-action-save"
			type="button"
			:disabled="disabled"
			@click="$emit('update')"
			v-html="lang.get('buttons.save')"
		></button>
		<button
			class="btn btn-tertiary btn-sm comment-action-cancel"
			type="button"
			:disabled="disabled"
			@click="$emit('cancel')"
			v-html="lang.get('buttons.cancel')"
		></button>
	</div>
	<!-- View mode  -->
	<div v-else class="inline-block">
		<a
			href="javascript:;"
			class="comment-action comment-action-edit"
			@click="$emit('edit')"
			v-html="lang.get('buttons.edit')"
		></a>
		<span class="comment-delete">
			<a
				href="javascript:;"
				class="comment-action comment-action-delete"
				@click="$emit('delete')"
				v-html="lang.get('buttons.delete')"
			></a>
		</span>
	</div>
</template>

<script>
export default {
	inject: ['lang'],
	props: {
		mode: {
			type: String,
			required: true,
		},
		comment: {
			type: Object,
			required: true,
		},
		disabled: {
			type: Boolean,
			required: true,
		},
		token: {
			type: String,
			required: true,
		},
	},
};
</script>
