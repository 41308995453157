var Vue = require('vue');
var ManualAssignment = require('./components/ManualAssignment.vue').default;

module.exports = function() {
  new Vue({
    el: '#manual-assignment',
    name: 'ManualAssignmentApp',
    components: {
      'manual-assignment': ManualAssignment
    }
  });
};
