<template>
	<div>
		<simple-widget widget-class="tips">
			<template slot="content">
				<!--	eslint-disable-next-line vue/no-v-html -->
				<h1 v-html="accountName"></h1>
				<!--	eslint-disable-next-line vue/no-v-html -->
				<consumer-content-block
					:editable="consumer.isManager"
					:content-block="homeProgramDescriptionContentBlock"
					:content-block-key="homeProgramDescriptionContentBlock.key"
					:model-id="homeProgramDescriptionContentBlock.id"
					:title-as-link="false"
					title-tag="h2"
					class="tips-body"
					container-class="islet"
				></consumer-content-block>
			</template>
		</simple-widget>
		<!-- Forms and categories -->
		<simple-widget v-if="showFormsCategoriesWidget">
			<template slot="content">
				<consumer-content-block
					v-if="showContentBlock(categoryPromotionContentBlock)"
					:editable="consumer.isManager"
					:content-block="categoryPromotionContentBlock"
					:content-block-key="categoryPromotionContentBlock.key"
					:model-id="categoryPromotionContentBlock.id"
					:title-as-link="false"
					title-tag="h2"
					class="islet"
					container-class="islet"
				></consumer-content-block>
				<form-card
					v-for="form in promotedForms"
					:key="form.slug"
					:form="form"
					:categories="formCategories(form)"
				></form-card>
				<hr v-if="showFormsCategoriesSeparator" class="hr-solid" />
				<div v-for="(category, i) in orphanCategories" :key="category.slug">
					<category-card :category="category"></category-card>
					<hr v-if="i < orphanCategories.length - 1" class="hr-solid" />
				</div>
			</template>
		</simple-widget>
	</div>
</template>

<script>
import SimpleWidget from '@/lib/components/Shared/SimpleWidget';
import langMixin from '@/lib/components/Translations/mixins/lang-mixin';
import { mapState, mapMutations, mapGetters } from 'vuex';
import ConsumerContentBlock from '@/lib/components/ContentBlocks/ConsumerContentBlock';
import FormCard from '@/modules/home/components/FormCard';
import CategoryCard from '@/modules/home/components/CategoryCard';

export default {
	components: {
		FormCard,
		SimpleWidget,
		ConsumerContentBlock,
		CategoryCard,
	},
	mixins: [langMixin],
	props: {
		infoContentBlock: {
			type: Object,
			default: () => {},
		},
		categoryPromotionContentBlock: {
			type: Object,
			default: () => {},
		},
		homeProgramDescriptionContentBlock: {
			type: Object,
			default: () => {},
		},
		menuItems: {
			type: Array,
			default: () => [],
		},
		promotedForms: {
			type: Array,
			default: () => [],
		},
		promotedCategories: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		...mapGetters('authentication', ['consumer']),
		...mapState('global', ['supportedLanguages', 'preferredLanguage', 'accountName']),
		showFormsCategoriesWidget() {
			return (
				this.showContentBlock(this.categoryPromotionContentBlock) ||
				this.promotedForms.length ||
				this.promotedCategories.length
			);
		},
		showFormsCategoriesSeparator() {
			return this.orphanCategories.length && (this.promotedForms.length || this.categoryPromotionContentBlock.id);
		},
		orphanCategories() {
			return this.promotedCategories.filter((category) => !this.promotedForms.some((form) => form.id === category.formId));
		},
	},
	mounted() {
		this.setTopCenterContentBlock(this.infoContentBlock);
	},
	methods: {
		...mapMutations('splashWithMenu', ['setTopCenterContentBlock']),
		formCategories(form) {
			return this.promotedCategories.filter((category) => category.formId === form.id);
		},
		showContentBlock(contentBlock) {
			return contentBlock.key && (contentBlock.id || this.consumer.isManager);
		},
	},
};
</script>

<style lang="scss">
@media (max-width: 1200px) {
	.body.text-content {
		iframe {
			width: 100%;
			max-width: 100%;
			height: auto;
		}
	}
}
</style>
