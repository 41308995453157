import { GettingEndpoint, PostingEndpoint } from '@/domain/services/Api/Endpoint';

const postInitiateReviewStage = (entrySlug: string, formType: string) =>
	PostingEndpoint(`/entry-form/${formType}/referee/review-stage/${entrySlug}`)();

const postResend = (reviewTask: string, formType: string) =>
	PostingEndpoint(`/entry-form/${formType}/referee/resend/review-task/${reviewTask}`)();

const getReferees = (submittableRoute: string, formType: string, slug: string) =>
	GettingEndpoint(`/${submittableRoute}/${formType}/referees/${slug}`)();

export { postInitiateReviewStage, postResend, getReferees };
