var $ = require('jquery');

/**
 * Select open rounds.
 */
module.exports = function () {
	/**
	 * The selector will be attached to this element.
	 */
	var element = $('[data-open-rounds]');

	/**
	 * The tooltip will be attached to this element.
	 */
	var parent = element.parent('span');

	/**
	 * Is the open round selector active?
	 */
	var selectorActive = false;

	/**
	 * Setup popovers.
	 */
	parent.hover(
		function () {
			if (selectorActive) {
				return;
			}

			// Show tooltip
			parent
				.popover({
					content: parent.data('tooltip'),
					placement: 'right',
					trigger: 'manual',
				})
				.popover('toggle');
		},
		function () {
			// Hide tooltip
			parent.popover('hide');
		}
	);

	element.click(function () {
		// Hide tooltip
		parent.popover('hide');

		// Show form
		element
			.popover({
				content: $(this).data('loading'),
				placement: 'right',
				trigger: 'manual',
			})
			.popover('toggle');
	});

	/**
	 * Retrieve open rounds on popover show.
	 */
	element.on('show.bs.popover', function () {
		selectorActive = true;

		$.ajax({
			url: $(this).data('open-rounds'),
			context: this,
		}).done(function (data) {
			var element = $(this);
			var form;

			element.data('bs.popover').tip().find('.popover-content').html(data);
			element.data('bs.popover').tip().find('.arrow').css('top', '20px');
			element
				.data('bs.popover')
				.tip()
				.find('.open-rounds-hide, button[type=submit]')
				.on('click', function () {
					element.popover('hide');
				});

			form = element.data('bs.popover').tip().find('form');

			element
				.data('bs.popover')
				.tip()
				.find('.open-rounds-reset')
				.on('click', function () {
					form.find('input[name="reset"]').val('true');
					form.submit();
				});
		});
	});

	element.on('hide.bs.popover', function () {
		selectorActive = false;
	});
};
