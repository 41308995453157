var $ = require('jquery');

/**
 * Show or hide loader (spinner).
 */
module.exports = {
	showLoader: function () {
		$('#loader').show().fadeTo(250, 0.8);
	},

	hideLoader: function () {
		$('#loader').fadeTo(250, 0, function () {
			$(this).hide();
		});
	},
};
