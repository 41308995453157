<template>
  <modal
    v-if="showModal"
    ref="modal"
    v-model="showModal"
    :header="false"
    :confirm-button-label="confirmButtonLabel || lang.get('buttons.continue')"
    :close-button-label="cancelButtonLabel || lang.get('buttons.cancel')"
    @closed="onClosed()"
    @confirmed="$emit('confirmed')"
  >
    <button id="close" slot="before-content" @click.prevent="onClosed()">
      <i class="af-icons af-icons-cross"></i>
    </button>
    <div
      v-html="confirmation || lang.get('miscellaneous.alerts.delete.comment')"
    />
  </modal>
</template>

<script>
/**
 * Like ConfirmationModal.vue, but:
 * - doesn't rely on Vue Portal,
 * - doesn't require any extra html.
 */
import { Modal } from 'vue-bootstrap';
import langMixin from '@/lib/components/Translations/mixins/lang-mixin.js';

export default {
  components: {
    Modal
  },
  mixins: [langMixin],
  props: {
    showModal: {
      type: Boolean,
      default: true
    },
    confirmation: {
      type: String,
      default: null
    },
    confirmButtonLabel: {
      type: String,
      default: null
    },
    cancelButtonLabel: {
      type: String,
      default: null
    }
  },
  methods: {
    onClosed() {
      this.$refs.modal.onAfterLeave();
      this.$emit('closed');
    }
  }
};
</script>
