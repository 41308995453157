<template>
  <div :id="id" class="loader">
    <div class="spinner spinner-centered">
      <div class="cube1"></div>
      <div class="cube2"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
.spinner-centered {
  margin: 0;
  left: 45%;
  top: 50%;
  /*left: 0;*/
}
</style>
