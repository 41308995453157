
import filesize from 'filesize';
import Status from './Status.js';
import HelpIcon from '../Shared/HelpIcon';
import { emit } from '@/domain/services/LocalEventBus.ts';
import { Events, EventPayloads } from '@/lib/components/Uploader/UploaderEvents.ts';

export default {
	inject: ['lang'],
	components: {
		HelpIcon,
	},
	props: {
		file: {
			type: Object,
			required: true,
		},
	},
	computed: {
		status() {
			switch (this.file.status) {
				case Status.QUEUED:
					return this.lang.get('files.status.queued');
				case Status.UPLOADING:
					return this.uploadStatus;
				case Status.PROCESSING:
					return this.lang.get('files.status.processing');
				case Status.FAILED:
					return this.lang.get('files.status.failed');
				default:
					return '';
			}
		},
		statusMessage() {
			return this.file.statusMessage ? this.lang.get('files.status.' + this.file.statusMessage) : '';
		},
		uploadStatus() {
			const size =
				!isNaN(this.file.size) && this.file.size > 0
					? ' (' + filesize(this.file.loaded) + '/' + filesize(this.file.size) + ')'
					: '';

			return this.file.percent > 0 ? this.file.percent + '%' + size : this.lang.get('files.status.uploading');
		},
	},
	watch: {
		status(status) {
			emit<EventPayloads, Events.Processing>(Events.Processing, Boolean(status || false));
		},
	},
};
