import { configureThirdPartyLoginOnly } from '@/modules/setting/loginCheckbox';
import { mountVueComponent } from '@/lib/mount-vue-component';

var $ = require('jquery');

export default function () {
	import('@/modules/setting/components/EmailMobileRegistrationSettings').then((emailMobileRegistrationSettings) => {
		mountVueComponent('email-mobile-registration-settings', emailMobileRegistrationSettings, false);
	});

	import('@/modules/setting/components/AgreementText.vue').then((agreementText) => {
		mountVueComponent('agreement-text', agreementText);
	});

	import('@/modules/setting/components/ConsentText.vue').then((consentText) => {
		mountVueComponent('consent-text', consentText);
	});

	function displaySamlSettings() {
		if ($('#enable-saml').is(':checked')) {
			$('#saml-settings').removeClass('hidden');
		} else {
			$('#saml-settings').addClass('hidden');
		}
	}

	$('#enable-saml').change(displaySamlSettings);

	displaySamlSettings();

	$('#saml-disabled').on('click', function () {
		window.location.href = '/feature-disabled/saml';
	});

	$('#wordpress-disabled').on('click', function () {
		window.location.href = '/feature-disabled/api';
	});

	configureThirdPartyLoginOnly();
	$('.social-authentication:checkbox, #enable-3rd-party-authentication').on('change', function () {
		configureThirdPartyLoginOnly();
	});

	// helpIcon
	import('@/lib/components/Shared/HelpIcon.vue').then((helpIcon) => {
		mountVueComponent('help-icon', helpIcon);
	});
}
