<template>
  <span :class="classes"></span>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: 'help'
    },
    size: {
      type: String,
      validator: value => ['lg', 'md'].indexOf(value) >= 0,
      default: null
    }
  },
  computed: {
    classes() {
      let size = this.size;
      let name = this.name;
      let classes = [];

      let mainClass = 'af-icons';
      if (size !== null) {
        mainClass += '-' + size;
      }
      classes.push(mainClass);

      let nameClass = mainClass + '-' + name;
      classes.push(nameClass);

      return classes;
    }
  }
};
</script>
