export default {
  getConfiguration: state => () => ({
    columns: state.columns,
    rows: state.rows,
    filters: state.filters,
    dynamicRowsEnabled: state.dynamicRowsEnabled,
    calculations: state.calculations
  }),

  getInput: state => () => {
    // We're only interested in values in existing rows
    const input = Object.keys(state.values)
      .filter(
        row => state.rows.includes(row) || state.dynamicRows.includes(row)
      )
      .reduce(
        (values, row) => ({
          ...values,
          [row]: state.values[row]
        }),
        {}
      );

    // ...and existing columns
    Object.keys(input).forEach(row => {
      input[row] = Object.keys(input[row])
        .filter(column => state.columns.includes(column))
        .reduce(
          (values, column) => ({
            ...values,
            [column]: input[row][column]
          }),
          {}
        );
    });

    return {
      dynamicRows: state.dynamicRows,
      values: input
    };
  },

  getFilters: state => column =>
    state.filters.find(filters => filters.column === column),

  getCalculations: state => column => state.calculations.find(filters => filters.column === column) || {},

  /**
   * Returns translations for the provided key.
   *
   * {
   *   en_GB: 'Column A',
   *   de_DE: 'Spalte A'
   * }
   */
  getTranslations: state => key => {
    let translated = {};

    for (const language in state.translations) {
      if (state.translations[language][key]) {
        translated[language] = state.translations[language][key];
      }
    }

    return translated;
  },

  getTranslation: state => (language, key) => {
    const translations = state.translations[language] || {};
    return translations[key] || '';
  },

  /**
   * Returns calculation translations for the provided key.
   *
   * {
   *   en_GB: 'Sum',
   *   de_DE: 'Summe'
   * }
   */
  getCalculationTranslations: state => key => {
    let translated = {};

    for (const language in state.translations) {
      if (state.translations[language]['calculation:' + key]) {
        translated[language] = state.translations[language]['calculation:' + key];
      }
    }

    return translated;
  },

  getCalculationTranslation: state => (language, key) => {
    const calculationTranslations = state.translations[language] || {};
    return calculationTranslations['calculation:' + key] || '';
  },

  getCellValue: state => (row, column) => {
    if (!state.values[row]) {
      return null;
    }

    return state.values[row][column] || null;
  },

  columnHasCalculation: state => column => {
    const calculation = state.calculations.find(calculation => calculation.column === column) || {};
    return calculation.enable || false;
  },

  tableHasCalculations: state => _.filter(state.calculations, { enable: true }).length > 0
};
