import { useGlobal } from '@/services/global/global.interface';

export const useLanguageProps = {
	language: {
		type: String,
		default: useGlobal('language')?.locale,
	},
	defaultLanguage: {
		type: String,
		default: useGlobal('language')?.fallback,
	},
	translations: {
		type: Object,
		default: () => ({}),
	},
};
