import { mountVueComponent } from '@/lib/mount-vue-component';
import Vue from 'vue';
import Page from '@/modules/content-block/component/Page.vue';

export default function() {
  Vue.component('Page', Page);

  import('@/lib/components/Layout/Splash/SplashWithMenu.vue').then(SplashWithMenu => {
    mountVueComponent('splash-with-menu', SplashWithMenu, true, false);
  });

  import('@/lib/components/Layout/Splash/SimpleMenu.vue').then(SimpleMenu => {
    mountVueComponent('simple-menu', SimpleMenu, true, false);
  });
}
