import { imageHeightComparison, isNotImage } from './imageUtils';

const imageHeightIsExceedingLimit = (maxImageHeight, file) =>
	imageHeightComparison(maxImageHeight, file, (height, limit) => height > limit);

/**
 * A 'max_image_height' filter for Plupload.
 *
 * How to use:
 * https://www.plupload.com/docs/v2/File-Filters
 */
export default async function (maxImageHeight, file, cb) {
	if (isNotImage(file) || !(await imageHeightIsExceedingLimit(maxImageHeight, file))) {
		// eslint-disable-next-line standard/no-callback-literal
		cb(true);
		return;
	}

	this.trigger('Error', {
		code: -608,
		file: file,
	});

	// eslint-disable-next-line standard/no-callback-literal
	cb(false);
}
