<template>
	<div
		:class="[
			'main-menu',
			{
				'primary-menu-open': primaryMenuOpen,
				'secondary-menu-open': secondaryMenuOpen,
			},
		]"
	>
		<primary-menu
			:menu="menuWithParent"
			:current-url="currentUrl"
			:primary-menu-active-item="primaryMenuActiveItem"
			:primary-menu-open="primaryMenuOpen"
			:two-level-menus="twoLevelMenus"
			:current-focus="currentFocus"
			@openShortcutMenu="openShortcutMenu"
			@menuSelected="selectPrimaryMenuItem"
			@itemSelected="(item) => openLink(item.link)"
			@focus="(payload) => setFocus(payload)"
			@focusNext="(payload) => focusToNext(payload)"
			@focusPrevious="(payload) => focusToPrevious(payload)"
		/>
		<!-- Settings menu -->
		<secondary-menu
			:menu="secondaryMenu"
			:settings-menu-is-open="secondaryMenuOpen"
			:current-url="currentUrl"
			:close-label="closeLabel"
			:focus-menu-name="secondaryMenuFocus"
			:current-focus="currentFocus"
			@focus="(payload) => setFocus(payload)"
			@focusNext="(payload) => focusToNext(payload)"
			@focusPrevious="(payload) => focusToPrevious(payload)"
			@itemSelected="(item) => openLink(item.link)"
			@closed="closeSecondaryMenu"
		/>
		<button
			type="button"
			class="btn-primary primary-menu-toggle"
			:aria-expanded="String(primaryMenuOpen)"
			@click.prevent="togglePrimaryMenu"
		>
			<span class="sr-only">{{ toggleLabel }}</span>
			<span class="af-icons af-icons-chevron-up"></span>
		</button>
	</div>
</template>

<script>
import $ from 'jquery';
import Storage from '@/lib/storage.js';
import PrimaryMenu from './PrimaryMenu';
import SecondaryMenu from './SecondaryMenu';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import _ from 'underscore';

const Cookies = {
	PRIMARY_MENU_PREFERENCE: 'primary-menu-preference',
	SECONDARY_MENU_PREFERENCE: 'secondary-menu-preference',
};

const SETTINGS = 'settings';

const MENU_OPEN = 'open';
const MENU_CLOSED = 'closed';

export default {
	components: {
		PrimaryMenu,
		SecondaryMenu,
	},
	props: {
		baseMenu: {
			type: Object,
			default: () => {},
		},
		toggleLabel: {
			type: String,
			default: 'Toggle navigation',
		},
		closeLabel: {
			type: String,
			default: 'Close',
		},
		contexts: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			currentUrl: null,
			menu: [],
			primaryMenuOpen: true,
			primaryMenuActiveItem: null,
			primaryMenuPreference: MENU_OPEN,

			secondaryMenuOpen: false,
			secondaryMenuPreference: MENU_CLOSED,
			secondaryMenuFocus: '',
			isPjaxLoad: false,

			currentFocus: null,
		};
	},
	computed: {
		...mapGetters('global', ['historyBreadcrumb', 'currentBreadcrumb']),
		...mapGetters('menu', ['mainMenu', 'selectedContextMainMenu', 'selectedContext']),
		twoLevelMenus() {
			return this.menuWithParent
				.filter(
					(item) => item.children && item.children.filter((child) => child.children && child.children.length).length > 0
				)
				.map((item) => item.name);
		},
		secondaryMenu() {
			if (this.twoLevelMenus.includes(this.primaryMenuActiveItem)) {
				return this.menuWithParent.find((item) => item.name === this.primaryMenuActiveItem);
			}

			return {};
		},
		hasContextMenu() {
			return this.mainMenu[this.selectedContext?.name] !== undefined;
		},
		/**
		 * Returns the menu with parent references
		 */
		menuWithParent() {
			const createMenuWithParentReferences = (menu, parent = null) =>
				menu.map((item) => {
					const newItem = { ...item, parent: parent ? { ...parent, children: null, parent: null } : null };
					if (item.children && item.children.length > 0) {
						newItem.children = createMenuWithParentReferences(item.children, newItem);
					}

					return newItem;
				});

			return [...createMenuWithParentReferences([...this.menu], null)];
		},
		/**
		 * Returns a flat array of menu items, so we can easily navigate through them
		 */
		flatMenuItems() {
			const flattenMenu = (items) => {
				let flatMenuItems = [];

				items.forEach((item) => {
					flatMenuItems.push({ ...item });
					if (item.children && item.children.length > 0) {
						flatMenuItems = flatMenuItems.concat(flattenMenu(item.children));
					}
				});

				return flatMenuItems;
			};

			return [...flattenMenu(this.menuWithParent, null)];
		},
		currentMenuItemIndex() {
			return this.flatMenuItems.findIndex((currentItem) => currentItem.name === this.currentFocus?.name);
		},
	},
	watch: {
		primaryMenuOpen() {
			setTimeout(this.slideinEvent, 300);
		},
		secondaryMenuOpen() {
			setTimeout(this.slideinEvent, 300);
		},
		selectedContextMainMenu() {
			this.menu = this.selectedContextMainMenu;
			this.currentUrl = this.currentUrl ? this.selectedContext['route'] : window.location.href;

			this.minimizeSingleMenu();
			this.refreshCurrentUrl();
		},
	},
	created() {
		this.storage = new Storage();
		this.loadPreferences();
		this.currentUrl = null;

		$('body').on('pjax:popstate', this.manageHistory);
		$('body').on('pjax:send', () => {
			this.isPjaxLoad = true;
		});
		$('body').on('pjax:end', this.refreshCurrentUrl);
	},
	mounted() {
		this.initializeContextMenu();
	},
	beforeDestroy() {
		$('body').off('pjax:popstate', this.manageHistory);
		$('body').off('pjax:end', this.refreshCurrentUrl);
	},
	methods: {
		...mapMutations('global', ['storeCurrentBreadcrumb', 'storeHistory']),
		...mapActions('menu', ['getMenuItemByName', 'setContextMenuData', 'setSelectedContext']),
		hasSecondaryMenu(item) {
			return this.twoLevelMenus.includes(item.name);
		},
		hasSubMenu(item) {
			return item.children !== undefined && item.children.length > 0;
		},
		openShortcutMenu(shortcutLink) {
			this.secondaryMenuFocus = '';
			if (shortcutLink['shortcut_menu_name']) {
				this.getMenuItemByName(shortcutLink['shortcut_menu_name']).then((item) => {
					if (item && item.type === 'menu') {
						this.selectPrimaryMenuItem(item);
						if (shortcutLink['extras']?.focusMenuName) {
							this.secondaryMenuFocus = shortcutLink['extras']['focusMenuName'];
						}
					}
				});
			}
		},
		selectPrimaryMenuItem(item) {
			this.primaryMenuActiveItem = item.name;

			if (this.hasSubMenu(item) && !this.hasSecondaryMenu(item)) {
				this.primaryMenuOpen = true;
			} else if (this.hasSecondaryMenu(item) && this.primaryMenuPreference === MENU_CLOSED) {
				this.primaryMenuOpen = false;
			} else {
				this.primaryMenuOpen = this.primaryMenuPreference === MENU_OPEN;
			}

			this.secondaryMenuOpen = this.hasSecondaryMenu(item);

			if (this.secondaryMenuOpen) {
				this.savePreferences();
			}

			if (item.link) {
				this.openLink(item.link);
			}
		},
		openLink(link) {
			this.currentUrl = link;
			this.storeCurrentBreadcrumb(link);
			this.secondaryMenuFocus = '';

			$.pjax({ url: link, container: '#pjaxContainer', method: 'GET', headers: { 'X-CLEAR-BREADCRUMB': true } }).then(
				() => {
					this.$nextTick(() => {
						this.setStateUrls(link, link);
					});
				}
			);
		},
		togglePrimaryMenu() {
			this.primaryMenuOpen = !this.primaryMenuOpen;

			this.savePreferences();
		},
		closeSecondaryMenu() {
			this.secondaryMenuOpen = false;

			this.savePreferences();
		},
		recursiveSearch(menu) {
			const url = this.getMenuUrl();
			if (menu.link && url.lastIndexOf(menu.link, 0) === 0) {
				return menu;
			} else {
				if (menu.children) {
					return menu.children.find(this.recursiveSearch);
				}
			}

			return null;
		},
		findMenu() {
			if (!this.hasContextMenu) {
				return false;
			}

			// Give priority to the part of the tree that holds the active primary menu item
			var menu = this.mainMenu[this.selectedContext.name].children.find(this.recursiveSearch);

			if (!menu) {
				_.without(this.contexts, this.selectedContext.name).every((context) => {
					menu = this.mainMenu[context.name].children.find(this.recursiveSearch);
					if (menu) {
						this.setSelectedContext(context);
						this.updateRemoteSelectedContext(context.name);

						return false;
					}

					return true;
				});
			} else {
				this.updateRemoteSelectedContext(this.selectedContext.name);
			}

			return menu;
		},
		refreshCurrentUrl(p, r) {
			this.primaryMenuOpen = this.primaryMenuPreference === MENU_OPEN;
			if (p !== undefined && this.currentUrl !== this.currentBreadcrumb) {
				this.manageHistory(p, r);
			}

			// Recursive search based on the current URL
			const menu = this.findMenu();
			if (menu) {
				this.primaryMenuActiveItem = menu.name;
				this.secondaryMenuOpen = this.hasSecondaryMenu(menu) && this.secondaryMenuPreference === MENU_OPEN;
			}
		},
		loadPreferences() {
			this.primaryMenuPreference = this.storage.get(Cookies.PRIMARY_MENU_PREFERENCE) || MENU_OPEN;
			this.secondaryMenuPreference = this.storage.get(Cookies.SECONDARY_MENU_PREFERENCE) || MENU_CLOSED;
		},
		savePreferences() {
			this.primaryMenuPreference = this.primaryMenuOpen ? MENU_OPEN : MENU_CLOSED;
			this.secondaryMenuPreference = this.secondaryMenuOpen ? MENU_OPEN : MENU_CLOSED;

			this.storage.set(Cookies.PRIMARY_MENU_PREFERENCE, this.primaryMenuPreference);
			this.storage.set(Cookies.SECONDARY_MENU_PREFERENCE, this.secondaryMenuPreference);
		},
		slideinEvent() {
			$(window).trigger('slidein');
		},
		manageHistory(p, r) {
			if (p !== undefined) {
				let historyBreadcrumb = null;
				if (r !== undefined) {
					const responseBreadcrumb = r.getResponseHeader('X-CURRENT-BREADCRUMB');
					if (responseBreadcrumb !== '' && responseBreadcrumb !== window.location.href) {
						historyBreadcrumb = responseBreadcrumb;
						this.storeCurrentBreadcrumb(responseBreadcrumb);
					}
				}

				p.preventDefault();
				this.setStateUrls(window.location.href, historyBreadcrumb);
			}
		},
		setStateUrls(currentUrl, currentBreadcrumb) {
			let state = window.history.state;
			if (state && !state.currentBreadcrumb) {
				state.currentUrl = currentUrl;
				state.currentBreadcrumb = currentBreadcrumb || currentUrl;
				state.currentContextMenu = this.selectedContext;
				window.history.replaceState(state, 'unused argument');
				window.history.replaceState(state, 'unused argument');
			}

			this.storeHistory(state);

			this.refreshCurrentUrl();
		},
		getMenuUrl() {
			// TODO: temporarily disabled the this.historyBreadCrumb to fix the context nav weird issue.
			return this.currentUrl;
		},
		minimizeSingleMenu() {
			if (this.contexts.length === 1 && this.menu.length === 1) {
				this.primaryMenuOpen = false;
				this.primaryMenuPreference = MENU_CLOSED;
				this.savePreferences();
			}
		},
		initializeContextMenu() {
			this.setContextMenuData({ baseMenu: this.baseMenu, contexts: this.contexts });
		},
		updateRemoteSelectedContext($contextName) {
			if ($contextName === this.selectedContext.name) {
				return;
			}

			if (this.isPjaxLoad === false) {
				this.$http.put('/context-menu/selected/' + $contextName, {});
			}
		},

		focusToNext(payload) {
			this.findNextItem({ ...payload, direction: 'down' });
		},
		focusToPrevious(payload) {
			this.findNextItem({ ...payload, direction: 'up' });
		},
		findNextItem(payload) {
			const { direction, event, isParentMenuClosed, visibility } = payload;

			if (this.currentMenuItemIndex === -1) {
				this.currentFocus = null;
				return; // Item not found in the array
			}

			if (direction === 'up') {
				this.navigateUp(event, visibility);
			} else if (direction === 'down') {
				this.navigateDown(event, isParentMenuClosed);
			}
		},

		navigateUp(event, visibility) {
			if (this.currentMenuItemIndex === 0) {
				return; // First item in the array
			}

			const previousItem = this.flatMenuItems[this.currentMenuItemIndex - 1];
			const currentItem = this.flatMenuItems[this.currentMenuItemIndex];

			if (previousItem?.parent?.name === currentItem?.name) {
				this.setFocus({ item: previousItem, event });
			} else {
				this.handlePreviousParentMenuClosed(previousItem, event, visibility);
			}
		},

		handlePreviousParentMenuClosed(previousItem, event, visibility) {
			const isPrimaryMenuClosed =
				previousItem?.parent &&
				this.currentFocus?.parent === null &&
				this.primaryMenuActiveItem !== previousItem?.parent?.name;

			const isSettingMenuItemClosed =
				previousItem?.name !== SETTINGS &&
				previousItem?.parent?.name !== SETTINGS &&
				this.primaryMenuActiveItem === SETTINGS &&
				visibility?.[previousItem?.parent?.name] === false;

			if (isPrimaryMenuClosed || isSettingMenuItemClosed) {
				this.setFocus({ item: previousItem.parent, event });
			} else {
				this.setFocus({ item: previousItem, event });
			}
		},

		navigateDown(event, isParentMenuClosed) {
			let nextItem = this.flatMenuItems[this.currentMenuItemIndex + 1];
			const isLastItem = this.currentMenuItemIndex === this.flatMenuItems.length - 1;
			const currentItemIsSettings = this.currentFocus?.name === SETTINGS;
			const settingsMenuIsClosed = !this.secondaryMenuOpen;

			if (!nextItem || isLastItem || (currentItemIsSettings && settingsMenuIsClosed)) {
				// continue with native tab navigation
				if (event.key === 'Tab') {
					this.currentFocus = null;
				}

				return;
			}

			// if the parent menu is closed, we need to skip all the children of the current item
			if (isParentMenuClosed) {
				let nextIndex = this.currentMenuItemIndex;

				while (nextItem?.parent?.name === this.currentFocus?.name) {
					nextIndex++;
					nextItem = this.flatMenuItems[nextIndex + 1];
				}
			}

			if (!nextItem) {
				// continue with native tab navigation
				if (event.key === 'Tab') {
					this.currentFocus = null;
				}

				return; // Last item in the array
			}

			this.setFocus({ item: nextItem, event });
		},

		setFocus(payload) {
			const { item, event } = payload;
			event.preventDefault();
			this.currentFocus = this.flatMenuItems.find((currentItem) => currentItem.name === item.name);
		},
	},
};
</script>
