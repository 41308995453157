<template>
	<div ref="comments" class="comments-container">
		<comment-list
			:comments="allComments"
			:user-id="userId"
			:token="token"
			:delete-url="deleteUrl"
			:update-url="updateUrl"
			:read-only="readOnly"
			:uploads="uploads"
			:uploader-object="uploaderObject"
			:uploader-options="uploaderOptions"
			:default-language="defaultLanguage"
			:language="language"
			:translations="translations"
			@uploading="$emit('uploading')"
			@updated="updatedComment"
			@deleted="deletedComment"
		/>
		<new-comment
			v-if="!readOnly"
			:create-url="createUrl"
			:token="token"
			:labels="labels"
			:user-id="userId"
			:placeholder="labels['placeholder']"
			:uploads="uploads"
			:uploader-object="uploaderObject"
			:uploader-options="uploaderOptions"
			:markdown-guide-label="labels['markdownGuide']"
			:default-language="defaultLanguage"
			:language="language"
			:translations="translations"
			:create-comment-portal-target="createCommentPortalTarget"
			@commenting="commenting"
			@added="newCommentAdded"
		/>
	</div>
</template>

<script>
import CommentList from '@/lib/components/Comments/CommentList';
import ExpandableTextarea from '@/lib/components/Shared/ExpandableTextarea';
import featuresMixin from '@/lib/components/Shared/mixins/features-mixin';
import langMixin from '@/lib/components/Translations/mixins/lang-mixin.js';
import linksMixin from '@/lib/components/Shared/mixins/links-mixin';
import { mapMutations } from 'vuex';
import NewComment from '@/lib/components/Comments/NewComment';
import plupload from 'plupload';
import { useGlobal } from '@/services/global/global.interface';

export default {
	components: {
		CommentList,
		ExpandableTextarea,
		NewComment,
	},

	mixins: [langMixin, featuresMixin, linksMixin],

	props: {
		readOnly: {
			type: Boolean,
			required: true,
			description: 'Makes comments list non-editable, not possible to add new comments',
		},
		uploads: {
			type: Boolean,
			default: false,
			description: 'Allows/disallows uploading files as comment attachments',
		},
		comments: {
			type: Array,
			required: true,
		},
		createUrl: {
			type: String,
			required: true,
		},
		deleteUrl: {
			type: String,
			required: true,
		},
		updateUrl: {
			type: String,
			required: true,
		},
		token: {
			type: String,
			required: true,
		},
		labels: {
			type: Object,
			required: true,
		},
		userId: {
			type: Number,
			required: true,
		},
		translations: {
			type: Object,
			default: () => {},
		},
		language: {
			type: String,
			default: useGlobal('language')?.locale,
		},
		uploaderOptions: {
			type: Object,
			default: () => {},
		},
		initialLoading: {
			type: Boolean,
			default: false,
		},
		createCommentPortalTarget: {
			type: [String, null],
			default: null,
		},
	},

	data() {
		return {
			allComments: this.comments,
			uploaderObject: null,
		};
	},

	watch: {
		comments(comments) {
			if (this.initialLoading) {
				this.allComments = comments;
			}

			if (this.allComments.length > 0) {
				this.scrollToBottom();
			}
		},
	},

	created() {
		this.uploaderObject = this.createUploaderObjectForFileUploads();
		this.storeUserId(this.userId);
	},

	mounted() {
		this.scrollToBottom();
	},

	methods: {
		commenting() {
			this.$emit('commenting');
			this.scrollToBottom();
		},
		deletedComment(deletedComment) {
			this.allComments = this.allComments.filter((comment) => comment.slug !== deletedComment.slug);
			this.$emit('deleted', deletedComment, this.allComments);
		},
		updatedComment(updatedComment) {
			this.allComments = this.allComments.map((comment) =>
				comment.slug === updatedComment.slug ? { ...updatedComment } : comment
			);
		},
		newCommentAdded(newComment) {
			this.allComments.push(newComment);
			this.$emit('added', newComment, this.allComments);
			setTimeout(() => {
				this.scrollToBottom();
			}, 100);
		},
		scrollToBottom() {
			this.$nextTick(() => {
				let el = this.$refs.comments;
				el.scrollTo({ top: el.scrollHeight, behavior: 'smooth' });
			});
		},
		createUploaderObjectForFileUploads() {
			return new plupload.Uploader(this.uploaderOptions);
		},
		...mapMutations('comments', ['storeUserId']),
	},
};
</script>
