<template>
	<div>
		<input type="hidden" name="__vtab" :value="tabId" />
		<template v-if="tabs">
			<div class="sticky-tabs header-controls clearfix ptm island">
				<tabs
					v-model="selectedTab"
					:tabs="liveTabs"
					class="tabs tabs-borderless ignore"
					@selected="(tid) => $emit('tabSelected', tid)"
				/>
			</div>
			<slot name="header" v-bind="binded" />
			<div v-for="tab in tabs" v-show="selectedTab == tab.id" :id="tab.id" :key="tab.id" class="row" transistion="push">
				<slot :name="tab.id" v-bind="binded" />
			</div>
		</template>
		<template v-else><slot name="header" v-bind="binded" /></template>
		<slot v-bind="binded" />
		<slot name="footer" v-bind="binded" />
	</div>
</template>

<script>
import { setUrlParameter, getUrlParameter } from '@/lib/url';
import { Tabs } from 'vue-bootstrap';
import sticky from './Shared/mixins/sticky';

export default {
	name: 'TabbedContent',
	mixins: [sticky],
	components: {
		Tabs,
	},
	props: {
		tabs: {
			type: Array,
			default: null,
		},
		whenDisabledRedirectTo: {
			type: String,
			default: null,
		},
		stickySelector: {
			type: String,
			default: '.sticky-tabs',
		},
	},
	data() {
		return {
			tabId: null,
		};
	},
	computed: {
		binded() {
			return {
				selectNext: this.onNextTab,
				selectPrev: this.onPrevTab,
				hasNextTab: this.hasNextTab,
				hasPrevTab: this.hasPrevTab,
			};
		},
		liveTabs() {
			return this.tabs.map((t) => ({
				id: t.id,
				name: t.name,
				disabled: t.disabled && !this.whenDisabledRedirectTo,
			}));
		},
		defaultTab() {
			return (
				this.tabs.find((tab) => tab.active === true && tab.disabled !== true) ||
				this.tabs.find((tab) => tab.disabled !== true)
			);
		},
		activeTabIndex() {
			return this.tabs.findIndex((tab) => tab.id === this.tabId);
		},
		nextTab() {
			return [...this.tabs].splice(this.activeTabIndex + 1).find((tab) => tab.disabled !== true);
		},
		prevTab() {
			return [...this.tabs]
				.splice(0, this.activeTabIndex)
				.reverse()
				.find((tab) => tab.disabled !== true);
		},
		hasNextTab() {
			return this.nextTab !== undefined;
		},
		hasPrevTab() {
			return this.prevTab !== undefined;
		},
		selectedTab: {
			get() {
				return this.tabId;
			},

			set(tabId) {
				if (tabId === this.tabId) {
					return;
				}

				const tab = this.tabs.find((tab) => tab.id === tabId);

				if (!tab) {
					return;
				}

				if (tab.disabled) {
					if (this.whenDisabledRedirectTo) {
						window.location.href = this.whenDisabledRedirectTo;
					}

					return;
				}

				this.tabId = tabId;

				if (window.history.state && window.history.state.tab === tabId) {
					return;
				}

				window.history.pushState({ tab: tabId }, null, setUrlParameter(window.location.href, 'vtab', tabId));
			},
		},
	},
	created() {
		this.selectedTab = getUrlParameter(window.location.href, 'vtab') || this.defaultTab.id;

		window.addEventListener('popstate', this.onUriChange);
	},
	beforeDestroy() {
		window.removeEventListener('popstate', this.onUriChange);
	},
	methods: {
		onNextTab() {
			if (this.hasNextTab) {
				this.selectedTab = this.nextTab.id;
			}
		},
		onPrevTab() {
			if (this.hasPrevTab) {
				this.selectedTab = this.prevTab.id;
			}
		},
		onUriChange() {
			this.selectedTab = getUrlParameter(window.location.href, 'vtab') || this.defaultTab.id;
		},
	},
};
</script>
