export const defaultState = () => ({
  routeSet: null,
  errorBag: [],
  ajaxInProgress: false,
  message: null
});

const state = defaultState();

export default state;
