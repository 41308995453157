import { mountVueComponent } from '@/lib/mount-vue-component';

export default function () {
	import('@/modules/entries/components/EntryView').then((EntryView) => {
		mountVueComponent('entry-view', EntryView);
	});

	// helpIcon
	import('@/lib/components/Shared/HelpIcon.vue').then((HelpIcon) => {
		mountVueComponent('help-icon', HelpIcon);
	});
}
