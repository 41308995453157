import { EditorMode } from '@/lib/components/Shared/editor/TextEditor.types';
import toolbarProps from '@/lib/components/Shared/editor/ckeditor/toolbar/toolbarProps';
import { useLinksDao } from '@/domain/dao/Links';

const { markdown_guide: markdownGuide } = useLinksDao();

export const useEditorProps = {
	...toolbarProps,

	value: {
		type: String,
		default: '',
	},
	name: {
		type: String,
		default: null,
	},
	fieldName: {
		type: String,
		default: '',
	},
	spellChecker: {
		type: Boolean,
		default: true,
	},
	mode: {
		type: String,
		default: 'full',
		validator: (mode: EditorMode) => ['full', 'single-line', 'custom'].includes(mode),
	},
	customToolbar: {
		type: Array,
		default: () => ['bold', 'italic'],
	},
	markdownGuideLabel: {
		type: String,
		default: 'About Markdown formatting',
	},
	markdownGuideUrl: {
		type: String,
		default: markdownGuide,
	},
	placeholder: {
		type: String,
		default: null,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	id: {
		type: String,
		default: 'text-editor',
	},
	inActiveTab: {
		type: Boolean,
		default: false,
	},
	useHiddenInput: {
		type: Boolean,
		default: false,
	},
	inputClass: {
		type: String,
		default: '',
	},
};
