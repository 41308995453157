import { nextTick, ref } from 'vue';

export function useModal(modalName, emit) {
	const showModal = ref(false);

	const toggle = () => {
		showModal.value = !showModal.value;
		if (showModal.value) {
			nextTick(() => {
				emit('reveal', modalName);
			});
		}
	};

	const modalClosed = () => {
		showModal.value = false;
		nextTick(() => {
			emit('reveal', null);
		});
	};

	return { showModal, toggle, modalClosed };
}
