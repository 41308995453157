import { defaultState } from './state';
import Vue from 'vue';

export default {
	/**
	 * Select a route set
	 *
	 * @param routeSet - specifies a key in `routesRepo = { ... }` (see getters.js)
	 */
	setRouteSet(state, routeSet) {
		Vue.set(state, 'routeSet', routeSet);
	},

	setErrorBag(state, errors) {
		Vue.set(state, 'errorBag', errors);
	},

	addToErrorBag(state, error) {
		Vue.set(state, 'errorBag', [
			...state.errorBag.filter((e) => {
				if (e.baseField && e.baseField === error.baseField) {
					return false;
				}

				return true;
			}),
			error,
		]);
	},

	removeFromErrorBag(state, error) {
		Vue.set(
			state,
			'errorBag',
			state.errorBag.filter((e) => {
				// remove error from bag based on baseField
				if (error.baseField && error.baseField !== e.baseField) {
					return true;
				}

				// remove tab errors
				if (error.type === 'Tab' && error.tabId && Number(error.tabId) !== Number(e.tabId)) {
					return true;
				}

				for (let key in error) {
					// eslint-disable-next-line eqeqeq
					if (key === 'fieldSlug' && e.fieldSlug !== error.fieldSlug) {
						return true;
					}
				}

				return false;
			})
		);
	},

	resetErrorBag(state) {
		Vue.set(state, 'errorBag', []);
	},

	setAjaxInProgress(state, setAjaxInProgress) {
		Vue.set(state, 'ajaxInProgress', setAjaxInProgress);
	},

	setMessage(state, message) {
		Vue.set(state, 'Message', message);
	},
	setMessageType(state, type) {
		Vue.set(state, 'MessageType', type);
	},

	/**
	 * Reset state to default state
	 */
	resetState(state) {
		Object.assign(state, defaultState());
	},
};
