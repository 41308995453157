<template>
  <div>
    <slot :valueData="valueData" :updateValueData="updateValueData"></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valueData: ''
    };
  },
  methods: {
    updateValueData(v) {
      this.valueData = v;
    }
  }
};
</script>
