var DataTable = require('../../lib/datatable.js');
var Common = require('./common').default;

module.exports = function() {
  var datatable = new DataTable;
  datatable.setup('.datatable');

  var common = new Common();

  $(".btn-api-key-show").click(function () {
    $(this).addClass("hidden");
    $(this).siblings(".api-key-masked").addClass("hidden");
    $(this).siblings(".api-key-full").removeClass("hidden");
    $(this).siblings(".btn-api-key-hide").removeClass("hidden");

    let slug  = $(this).siblings(".api-key-slug").val();
    $.get("/api-key/" + slug + "/show");
  });

  $(".btn-api-key-hide").click(function () {
    $(this).addClass("hidden");
    $(this).siblings(".api-key-full").addClass("hidden");
    $(this).siblings(".api-key-masked").removeClass("hidden");
    $(this).siblings(".btn-api-key-show").removeClass("hidden");
  });

  $(".btn-api-key-copy").click(function () {
    common.copy($(this).siblings(".api-key-full").text())
  });
};