import $ from 'jquery';

/**
 * Session check ping
 */
export default () => {
	if ($('.navbar-user').length) {
		$.ajax({
			type: 'POST',
			dataType: 'json',
			url: '/profile/ping',
		})
			.fail(function (response) {
				/* eslint-disable eqeqeq */
				if (response.status == 401) {
					window.location.href = '/';
				} else if (response.status == 419) {
					$('#page-expired-modal').modal('show');
				} else if (response.status == 503) {
					$('#maintenance-modal').modal('show');
				}
				/* eslint-enable eqeqeq */
			})
			.complete(function () {
				if ($('#maintenance-modal').hasClass('in')) {
					$('#maintenance-modal').modal('hide');
				}
			});
	}
};
