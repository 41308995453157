import toolbarProps from '@/lib/components/Shared/editor/ckeditor/toolbar/toolbarProps';

type LanguageObject = {
	[languageCode: string]: {
		[property: string]: string | null;
	};
};

type MultilingualProps = {
	mode: string;
	required: boolean;
	resource: {
		translated: LanguageObject | null | [];
	};
	property: string;
	maxLength: number;
	markdownMode: string;
	labelledBy: string | null;
};

const props = {
	mode: {
		type: String,
		default: 'text',
		validator: (mode: string) => ['text', 'textarea', 'markdown'].includes(mode),
	},
	required: {
		type: Boolean,
		default: false,
	},
	resource: {
		type: Object,
		required: true,
		validator: (resource: object) => Object.prototype.hasOwnProperty.call(resource, 'translated'),
	},
	property: {
		type: String,
		required: true,
	},
	maxLength: {
		type: Number,
		default: 524288,
	},
	markdownMode: {
		type: String,
		default: 'single-line',
		validator: (mode: string) => ['full', 'single-line'].includes(mode),
	},
	labelledBy: {
		type: String,
		default: null,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	inputClass: {
		type: String,
		default: null,
	},
	...toolbarProps,
};

export { MultilingualProps, props, LanguageObject };
