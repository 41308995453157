import form from './form';
import index from './index';
import manage from './manage';
import { Router } from 'pjax';
import task from './task';

Router.get('review-flow', index);
Router.get('review-flow/:any', form);
Router.get('entry/review/:any', task);
Router.get('entry/manage-reviews', manage);
