<template>
  <div class="form-group">
    <div class="radio styled">
      <input
        id="all-categories"
        v-model="categoryOption"
        type="radio"
        value="all"
        name="categoryOption"
      />
      <label for="all-categories">
        <translation :text="allCategoriesLabel" />
      </label>
    </div>
    <div class="radio styled">
      <input
        id="select-categories"
        v-model="categoryOption"
        type="radio"
        value="select"
        name="categoryOption"
      />
      <label for="select-categories">
        <translation :text="selectCategoriesLabel" />
      </label>
    </div>
    <multiselect
      v-if="categoryOption === 'select'"
      id="category-selector"
      class="override-tray-color"
      name="categories[]"
      :options="multiselectCategories"
      :selected-options="selectedCategories"
      :placeholder="placeholder"
      :select-all-label="lang.get('multiselect.select_all')"
      @selected="onCategoriesSelected"
    />
  </div>
</template>

<script>
import _ from 'underscore';
import { Multiselect } from 'vue-bootstrap';
import Translation from '@/modules/interface-text/components/Translation';

export default {
  inject: ['lang'],
  components: {
    Multiselect,
    Translation
  },
  props: {
    resource: {
      type: Object,
      required: true,
      validator: resource =>
        resource.hasOwnProperty('categoryOption') &&
        resource.hasOwnProperty('categories')
    },
    categories: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      default: 'Select categories'
    },
    allCategoriesLabel: {
      type: String,
      default: 'Resource applies to all categories'
    },
    selectCategoriesLabel: {
      type: String,
      default: 'Resource applies to some categories'
    }
  },
  data() {
    return {
      categoryOption: this.resource.categoryOption,
      selectedCategories: this.resource.categories
    };
  },
  computed: {
    properties() {
      return [this.categoryOption, this.selectedCategories];
    },
    multiselectCategories() {
      if (this.categories[0].hasOwnProperty('children')) {
        return this.categories;
      }

      return this.categories
        .filter(category => category.parentId === null)
        .map(category => this.formatCategory(category))
        .sort((a, b) => a.name.localeCompare(b.name));
    }
  },
  watch: {
    properties() {
      this.$emit('input', {
        categoryOption: this.categoryOption,
        categories:
          this.categoryOption === 'select' ? this.selectedCategories : []
      });
    }
  },
  methods: {
    onCategoriesSelected(categories) {
      if (
        !_.isEqual([...categories].sort(), [...this.selectedCategories].sort())
      ) {
        this.selectedCategories = categories;
      }
    },
    formatCategory(category) {
      return {
        id: category.id,
        name: category.name,
        children: category.hasChildren ? this.filterCategory(category) : null
      };
    },
    filterCategory(category) {
      return this.categories
        .filter(child => child.parentId && child.parentId === category.id)
        .map(child => this.formatCategory(child))
        .sort((a, b) => a.name.localeCompare(b.name));
    }
  }
};
</script>
