import { mapGetters, mapMutations } from 'vuex';
import { validate, validationExtra } from './index.js';

const validatorMixin = {
	props: {
		required: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			// By default no validation rules are specified.
			validationRules: [],

			// By default required fields are validated using 'required' validator.
			requiredValidator: 'required',
		};
	},
	computed: {
		...mapGetters('validation', ['validationErrors']),
		validationId() {
			return this.fieldId + validationExtra(this.fieldType);
		},
	},
	watch: {
		validationErrors(newErrors, oldErrors) {
			if (
				newErrors[this.validationId] !== oldErrors[this.validationId] ||
				newErrors[this.fieldId] !== oldErrors[this.fieldId]
			) {
				this.validate();
			}
		},
	},
	methods: {
		...mapMutations('validation', ['clearValidationError']),
		async validate() {
			return this.validateValue(this.value);
		},
		async validateValue(value) {
			let validationErrors = (
				this.validationErrors[this.validationId] ||
				this.validationErrors[this.fieldId] ||
				[]
			).filter((err) => !!err);
			this.clearValidationError(this.validationId);
			this.clearValidationError(this.fieldId);

			if (validationErrors.length === 0) {
				for (const ruleObject of this.validationRules) {
					let valid, rule, params, data;
					if (typeof ruleObject === 'string') {
						[rule, params] = ruleObject.split(':');
						data = params ? params.split(',') : null;

						if (rule !== this.requiredValidator && !value) {
							continue;
						}

						if (value !== undefined) {
							valid = await validate(rule, JSON.parse(JSON.stringify(value)), data);
						}
					} else if (ruleObject !== null) {
						rule = ruleObject.message;
						valid = await ruleObject.test(JSON.parse(JSON.stringify(value)));
					}

					if (!valid && rule) {
						validationErrors.push(rule);
					} else {
						validationErrors = [];
					}
				}
			}

			this.$emit('errors', validationErrors);
			this.$emit('update:validationErrors', validationErrors);

			return validationErrors.length === 0;
		},
	},
	created() {
		if (this.required) {
			this.validationRules.push(this.requiredValidator);
		}
	},
};

export default validatorMixin;
