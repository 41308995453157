<template>
  <div class="search-panel">
    <label v-if="label" :for="id">{{ label }}</label>
    <div class="keyword-search">
      <a
        v-if="advancedSearch"
        :class="['advanced-search', { active: filtersVisible }]"
        @click.prevent="filtersVisible = !filtersVisible"
      >
        {{ advancedSearchLabel }}
      </a>
      <input v-model="keywords" :name="name" class="form-control" @keydown.enter.prevent="search" />
    </div>
    <div v-show="filtersVisible">
      <slot></slot>
    </div>
    <button class="btn btn-secondary" @click.prevent="search">
      {{ searchLabel }}
    </button>
    <button class="btn btn-link reset" @click.prevent="reset">
      {{ resetLabel }}
    </button>
  </div>
</template>

<script>
import Search from '@/lib/components/Judging/Search';

export default {
  extends: Search
};
</script>
