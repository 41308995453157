import { Lang } from '@/domain/utils/Translations';
import { ComputedRef, Ref } from 'vue';
import { EditorUploadProps, File, FileId } from '@/lib/components/Shared/editor/uploads/Uploads.types';

type Comment = {
	id?: number;
	slug?: string;
	name?: string;
	content?: string;
	user?: string;
	userId?: number;
	temp?: boolean;
	isApi?: boolean;
	file?: File;
	files?: File[];
};

enum CommentMode {
	VIEW = 'view',
	EDIT = 'edit',
}

type CommentProps = EditorUploadProps & {
	comment: Comment;
	token: string;
	readOnly: boolean;
	deleteUrl: string;
	updateUrl: string;
	translations: object;
};

type CommentUpdatedResponse = {
	slug: string;
	content: string;
	files: File[];
	relativeTime: string;
	user: string;
	userId: number;
};

type CommentView = {
	lang: Lang;
	useGlobal: (key: string) => unknown;

	uploader: Ref<null>;
	disabled: Ref<boolean>;
	files: Ref<File[]>;
	deletingFiles: Ref<FileId[]>;
	mode: Ref<string>;
	commentContent: Ref<string | null>;
	commentAuthor: Ref<string | undefined>;
	confirmedDeletion: Ref<boolean>;
	showConfirmDeletionModal: Ref<boolean>;

	buttonsDisabled: ComputedRef<boolean>;
	pendingUploads: ComputedRef<number>;
	deleteCommentUrl: string;
	updateCommentUrl: string;
	canUpload: ComputedRef<boolean>;
	userId: number;

	startedEditing: () => void;
	finishedEditing: () => void;
	cancelEditing: () => void;
	deleteComment: () => void;
	onUpload: () => void;
	onUploadingFile: (file: File) => void;
	onUploadedFile: (completeFileTokens: FileId[], file: File) => void;
	onImageDataUrl: (dataUrl: string | null, file: File) => void;
	onUploadCanceled: (file: File) => void;
	onUploadCompleted: (completeFileTokens: FileId[]) => void;
	onDeletedFile: (file: File) => void;
};

export { Comment, CommentMode, CommentProps, CommentUpdatedResponse, CommentView };
