var $ = require('jquery');

const focusableElements = [
	'a[href]:not([disabled])',
	'button:not([disabled])',
	'textarea:not([disabled])',
	'input[type="text"]:not([disabled])',
	'input[type="radio"]:not([disabled])',
	'input[type="checkbox"]:not([disabled])',
	'select:not([disabled])',
	'h4.modal-title',
];

const trapModal = function (elem) {
	const tabbables = elem.find(focusableElements.join(','));
	const modalContent = elem.find('.modal-content').first();
	const firstModalContentElement = modalContent.find(focusableElements.join(','));

	const firstTabbable = tabbables.first();
	const lastTabbable = tabbables.last();

	/* set focus on first element */
	if (firstModalContentElement.length > 0) {
		firstModalContentElement.first().focus();
	} else {
		firstTabbable.focus();
	}

	/* redirect last tab to first input */
	lastTabbable.on('keydown', function (e) {
		if (e.which === 9 && !e.shiftKey) {
			e.preventDefault();
			firstTabbable.focus();
		}
	});

	/* redirect first shift+tab to last input */
	firstTabbable.on('keydown', function (e) {
		if (e.which === 9 && e.shiftKey) {
			e.preventDefault();
			lastTabbable.focus();
		}
	});

	/* allow escape key to close insiders div */
	elem.on('keyup', function (e) {
		if (e.keyCode === 27) {
			elem.hide();
		}
	});
};

$('#content').on('click', '.view-simple-modal', function (event) {
	const modal = $('#simpleModal').clone();

	const target = event.currentTarget;

	modal.find('.modal-dialog').addClass($(this).data('modal-size'));

	modal.find('.modal-content').html($(this).data('content').clarify());
	modal.modal('show');

	modal.on('shown.bs.modal', function () {
		trapModal(modal);
		if (target.hasAttribute('aria-expanded')) {
			target.setAttribute('aria-expanded', 'true');
		}
	});

	modal.on('hidden.bs.modal', function () {
		modal.remove();
		if (target.hasAttribute('aria-expanded')) {
			target.setAttribute('aria-expanded', 'false');
		}
	});
});

$('#content').on('click', '.view-transcoding-errors', function () {
	const modal = $('#simpleModal').clone();
	const errors = $(this).data('errors');

	modal.find('.modal-content').html(errors);
	modal.modal('show');

	modal.on('shown.bs.modal', function () {
		trapModal(modal);
	});

	modal.on('hidden.bs.modal', function () {
		modal.remove();
	});
});
