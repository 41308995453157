<template>
	<div class="panel panel-default search-panel">
		<div class="panel-body">
			<div v-if="label" class="panel-title">
				<h4>
					<label :for="id">{{ label }}</label>
				</h4>
			</div>

			<div class="keyword-search">
				<a
					v-if="advancedSearch"
					:class="['advanced-search', { active: filtersVisible }]"
					@click.prevent="filtersVisible = !filtersVisible"
				>
					{{ advancedSearchLabel }}
				</a>
				<input v-model="keywords" :name="name" class="form-control" @keydown.enter.prevent="search" />
			</div>
			<div v-show="filtersVisible">
				<slot></slot>
			</div>
			<button :disabled="searching" class="btn btn-secondary" @click.prevent="search">
				{{ searchLabel }}
			</button>
			<button class="btn btn-link reset" @click.prevent="reset">
				{{ resetLabel }}
			</button>
		</div>
	</div>
</template>

<script>
import $ from 'jquery';

export default {
	props: {
		id: {
			type: String,
			default: '',
		},
		name: {
			type: String,
			default: '',
		},
		url: {
			type: String,
			required: true,
		},
		filters: {
			type: Object,
			default: () => ({}),
		},
		label: {
			type: String,
			default: 'Label',
		},
		searchLabel: {
			type: String,
			default: 'Search',
		},
		resetLabel: {
			type: String,
			default: 'Reset',
		},
		allowEmptySearch: {
			type: Boolean,
			default: true,
		},
		advancedSearch: {
			type: Boolean,
			default: false,
		},
		advancedSearchLabel: {
			type: String,
			default: 'Advanced',
		},
		onSearch: {
			type: Function,
			default: () => {},
		},
	},
	data() {
		return {
			keywords: '',
			filtersVisible: false,
			searching: false,
		};
	},
	methods: {
		search() {
			if (this.searching) {
				return;
			}

			const keywords = this.keywords ? { keywords: this.keywords } : {};
			const params = Object.assign(keywords, this.filters);

			if (!this.allowEmptySearch && Object.keys(params).length === 0) {
				return;
			}

			this.searching = true;

			this.ajax(params).success((data) => {
				this.onSearch(data);
				this.searching = false;
			});
		},
		ajax(params) {
			return $.ajax({
				url: this.url,
				type: 'GET',
				dataType: 'json',
				data: params,
			});
		},
		reset() {
			this.keywords = '';

			for (var i in this.$children) {
				this.$children[i].reset();
			}

			this.$emit('reset');
		},
	},
};
</script>
