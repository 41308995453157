<template>
  <div class="pull-left">
    <a
      :class="['move', 'move-left', { hidden: hideMoveLeft }]"
      @click.prevent="moveLeft"
    >
      <i class="af-icons af-icons-move-left"></i>
    </a>
    <span class="sort-order">{{ attachment.order }}</span>
    <a
      :class="['move', 'move-right', { hidden: hideMoveRight }]"
      @click.prevent="moveRight"
    >
      <i class="af-icons af-icons-move-right"></i>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    attachment: {
      type: Object,
      required: true
    },
    numOfAttachments: {
      type: Number,
      default: 0
    }
  },
  computed: {
    hideMoveLeft() {
      return this.attachment.order <= 1;
    },
    hideMoveRight() {
      return this.attachment.order >= this.numOfAttachments;
    }
  },
  methods: {
    moveLeft() {
      this.$emit('movedLeft', this.attachment);
    },
    moveRight() {
      this.$emit('movedRight', this.attachment);
    }
  }
};
</script>
