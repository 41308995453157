/**
 * Extract error messages from the response:
 *   {
 *     propertyA: ['Property A is required', 'Property A must be a number'],
 *     propertyB: ['Property B is required']
 *   }
 *
 * Return error messages:
 *   [
 *     'Property A is required',
 *     'Property A must be a number',
 *     'Property B is required'
 *   ]
 */
export const extractErrorMessages = errors => {
  const messages = [];

  for (let i in errors) {
    if (Array.isArray(errors[i])) {
      errors[i]
        .reduce((acc, val) => acc.concat(val), [])
        .forEach(e => {
          messages.push(e);
        });
    }
  }

  return messages;
};
