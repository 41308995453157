import FileUploadFieldWrapper from '@/modules/fields/components/FileUploadWrapper';
import { mountVueComponent, mountVueWithComponents } from '@/lib/mount-vue-component';

export default function () {
	var darkmode = require('../../common/darkmode.js');
	darkmode.mount();

	import('@/modules/users/components/UserFields').then((UserFields) => {
		mountVueComponent('user-fields', UserFields);
	});

	import('@/modules/documents/components/DocumentsList').then((DocumentsList) => {
		mountVueComponent('documents-list', DocumentsList);
	});

	// helpIcon
	import('@/lib/components/Shared/HelpIcon.vue').then((HelpIcon) => {
		mountVueComponent('help-icon', HelpIcon);
	});

	import('@/lib/components/Fields/validator/Validator').then((validator) => {
		mountVueWithComponents('validator', validator, { FileUploadFieldWrapper });
	});
}
