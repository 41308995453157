<template>
  <div class="stat">
    <a v-if="url" :href="url">
      <span class="count">{{ count }}</span>
      {{ label }}
    </a>
    <span v-else>
      <span class="count">{{ count }}</span>
      {{ label }}
    </span>
  </div>
</template>

<script>
export default {
  inject: ['lang'],
  props: {
    url: {
      type: String,
      default: null
    },
    count: {
      type: [String, Number],
      required: true
    },
    label: {
      type: String,
      required: true
    }
  }
};
</script>
