<template>
  <span>
    <i
      v-if="item.archived"
      class="af-icons af-icons-archived danger align-middle"
    ></i>
    {{ item.title }}
  </span>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>
