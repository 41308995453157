import Vue from 'vue';
import store from '@/lib/store';
import PanelList from './components/PanelList';

export default function() {
  const panelList = new Vue({
    el: '#panel-list',
    name: 'PanelApp',
    components: {
      PanelList
    },
    store
  });

  $(document).one('pjax:end', () => {
    panelList.$destroy();
  });
}
