<template>
	<inserter :active="active" @clicked="toggle">
		<div v-if="active" v-on-click-outside="close" class="column-selector">
			<input v-model="query" type="text" :placeholder="placeholder" @keyup.esc="close" />
			<div v-if="filteredColumns.length" class="list">
				<ul>
					<li v-for="column in filteredColumns" :key="column.name">
						<!-- eslint-disable-next-line vue/no-v-html -->
						<a @click.prevent="select(column.name)" v-html="column.title"></a>
					</li>
				</ul>
			</div>
			<div v-else class="list list-empty">
				{{ emptyListLabel }}
			</div>
		</div>
	</inserter>
</template>

<script>
import { clickOutside } from 'vue-bootstrap';
import Inserter from '@/lib/components/Shared/Inserter';

export default {
	components: {
		Inserter,
	},
	mixins: [clickOutside],
	props: {
		position: {
			type: String,
			required: true,
		},
		insertPosition: {
			type: String,
			default: null,
		},
		columns: {
			type: Array,
			default: () => [],
		},
		placeholder: {
			type: String,
			default: 'Search columns',
		},
		emptyListLabel: {
			type: String,
			default: 'No further columns available',
		},
	},
	data() {
		return {
			query: '',
		};
	},
	computed: {
		active() {
			return this.position === this.insertPosition;
		},
		filteredColumns() {
			const columns = this.columns.filter((column) =>
				typeof column.title === 'string' ? column.title.toLowerCase().indexOf(this.query.toLowerCase()) !== -1 : false
			);

			return columns.sort(this.sort);
		},
	},
	methods: {
		toggle() {
			this.$emit('toggled');
		},
		select(name) {
			this.$emit('selected', name);
		},
		close() {
			this.$emit('closed');
		},
		sort(columnA, columnB) {
			if (columnA.title < columnB.title) {
				return -1;
			}

			if (columnA.title > columnB.title) {
				return 1;
			}

			return 0;
		},
	},
};
</script>
