var underscore = require('underscore');
var $ = require('jquery');

/**
 * Autocomplete based on select2.
 *
 * Usage:
 *   var autocomplete = new Autocomplete();
 *   autocomplete.setup(element);
 *
 */
module.exports = function () {
	/**
	 * @param {Object} data
	 * @return {Object}
	 */
	var prepareData = function (data) {
		underscore.each(data, function (value, key) {
			if (key === '') {
				delete data[key];
			}
		});

		return $.map(data, function (value, key) {
			return { id: key, text: value.toString() };
		});
	};

	/**
	 * @param {HTMLInputElement|String} element
	 */
	var autocomplete = function (element) {
		var data = $(element).data('options');

		if (!data) {
			return;
		}

		data = prepareData(data.clarify());

		// Paginated options for select2 based on:
		// https://github.com/select2/select2/issues/781
		$(element)
			.select2({
				containerCss: 'autocomplete-container',
				dropdownCssClass: 'autocomplete-dropdown',
				initSelection: function (element, callback) {
					var selection = underscore.find(data, function (item) {
						return item.id === element.val();
					});

					callback(selection);
				},
				query: function (options) {
					var pageSize = 10;
					var startIndex = (options.page - 1) * pageSize;
					var filteredData = data;
					var stripDiacritics = window.Select2.util.stripDiacritics;

					if (options.term && options.term.length > 0) {
						if (!options.context) {
							var term = stripDiacritics(options.term.toLowerCase());
							options.context = data.filter(function (item) {
								if (!item.strippedText) {
									item.strippedText = stripDiacritics(item.text.toLowerCase());
								}

								return item.strippedText.indexOf(term) !== -1;
							});
						}

						filteredData = options.context;
					}

					options.callback({
						context: filteredData,
						results: filteredData.slice(startIndex, startIndex + pageSize),
						more: startIndex + pageSize < filteredData.length,
					});
				},
			})
			.addClass('autocomplete-select');
	};

	/**
	 * @param {HTMLInputElement|String} element
	 */
	this.setup = function (element) {
		autocomplete(element);
	};
};
