<script>
import ResourceList from '@/lib/components/ResourceList.vue';
import ScoreSetSelector from '@/lib/components/Selectors/ScoreSetSelector.vue';
import Archiver from '@/lib/components/ListActions/Archiver.vue';
import Unarchiver from '@/lib/components/ListActions/Unarchiver.vue';
import ListAction from '@/lib/components/ListActions/ListAction.vue';
import Tagger from '@/lib/components/ListActions/Tagger.vue';
import FundAllocator from '@/lib/components/ListActions/FundAllocator';
import CreateDocument from '@/lib/components/ListActions/CreateDocument';
import HelpIcon from '@/lib/components/Shared/HelpIcon.vue';
import langMixin from '@/lib/components/Translations/mixins/lang-mixin';
import GrantStatusSelector from '@/lib/components/ListActions/GrantStatusSelector';

export default {
	components: {
		ScoreSetSelector,
		Archiver,
		Unarchiver,
		ListAction,
		Tagger,
		FundAllocator,
		CreateDocument,
		HelpIcon,
		GrantStatusSelector,
	},
	extends: ResourceList,
	mixins: [langMixin],
	computed: {
		taggerRevealed() {
			return this.reveal === 'tagger';
		},
		untaggerRevealed() {
			return this.reveal === 'untagger';
		},
		fundAllocatorRevealed() {
			return this.reveal === 'fund-allocator';
		},
		createDocumentRevealed() {
			return this.reveal === 'create-document';
		},
		grantStatusSelectorRevealed() {
			return this.reveal === 'grant-status-selector';
		},
	},
};
</script>
