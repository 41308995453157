<template>
	<draggable v-model="columns" animation="150" draggable=".column" handle=".drag-handle">
		<div v-for="(column, index) in columns" :key="column" class="column">
			<column
				:column="column"
				:columns="columns"
				:existing-columns="existingColumns"
				:index="index"
				:input-types="$attrs['input-types']"
				:has-entries="hasEntries"
				:labels="$attrs.labels"
				:supported-languages="$attrs['supported-languages']"
			>
			</column>
		</div>
	</draggable>
</template>

<script>
import Draggable from 'vuedraggable';
import Column from './TableFieldConfiguratorColumn.vue';

export default {
	components: {
		Draggable,
		Column,
	},
	inheritAttrs: false,
	props: {
		draggableColumns: {
			type: Array,
			default: () => [],
		},
		hasEntries: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			columns: [],
			existingColumns: [],
		};
	},
	watch: {
		draggableColumns() {
			this.columns = this.draggableColumns;
		},
		columns() {
			this.$emit('dragging', this.columns);
		},
	},
	created() {
		this.columns = this.draggableColumns;
		this.existingColumns = [...(this.columns || [])];
	},
};
</script>
