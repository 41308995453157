/* This file was generated by running the 'lang:refresh-js' command. */

var moment = require('moment');

require('moment/locale/ar');
require('moment/locale/bn');
require('moment/locale/ca');
require('moment/locale/cs');
require('moment/locale/cy');
require('moment/locale/da');
require('moment/locale/de');
require('moment/locale/en-gb');
require('moment/locale/el');
require('moment/locale/es');
require('moment/locale/es');
require('moment/locale/fa');
require('moment/locale/fi');
require('moment/locale/fr');
require('moment/locale/fr');
require('moment/locale/he');
require('moment/locale/hi');
require('moment/locale/it');
require('moment/locale/ja');
require('moment/locale/ko');
require('moment/locale/lt');
require('moment/locale/ms');
require('moment/locale/nl');
require('moment/locale/nb');
require('moment/locale/pl');
require('moment/locale/pt-br');
require('moment/locale/ru');
require('moment/locale/sl');
require('moment/locale/sv');
require('moment/locale/sw');
require('moment/locale/th');
require('moment/locale/uk');
require('moment/locale/vi');
require('moment/locale/zh-cn');
require('moment/locale/zh-hk');

moment.locale('en-gb');
