<template>
  <div class="score-set-stats mts mbs">
    <score-set-stat
      :url="permissions.canViewLeaderboard ? leaderboardUrl(scoreSet) : null"
      :count="stats.entryCount"
      :label="stats.entryCountLabel"
    />
    <score-set-stat
      :url="permissions.canViewProgress ? progressUrl(scoreSet) : null"
      :count="stats.judgeCount"
      :label="stats.judgeCountLabel"
    />
    <score-set-stat
      :url="permissions.canViewAssignments ? assignmentsUrl(scoreSet) : null"
      :count="stats.assignmentCount"
      :label="stats.assignmentCountLabel"
    />
  </div>
</template>

<script>
import { route } from '@/lib/utils.js';
import ScoreSetStat from './ScoreSetStat';

export default {
  inject: ['lang'],
  components: {
    ScoreSetStat
  },
  props: {
    scoreSet: {
      type: Object,
      required: true
    },
    stats: {
      type: Object,
      required: true
    },
    routes: {
      type: Object,
      required: true
    },
    permissions: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    assignmentsUrl(scoreSet) {
      return (
        route(this.routes['assignment.index']) + '?score-set=' + scoreSet.id
      );
    },
    progressUrl(scoreSet) {
      return scoreSet.mode !== 'gallery'
        ? route(this.routes['leaderboard.progress']) +
            '?score-set=' +
            scoreSet.id
        : null;
    },
    leaderboardUrl(scoreSet) {
      return scoreSet.mode !== 'gallery'
        ? route(this.routes['leaderboard.index']) + '?score-set=' + scoreSet.id
        : null;
    }
  }
};
</script>
