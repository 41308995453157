<template>
	<div>
		<input
			:id="getId()"
			:value="value"
			class="form-control"
			type="url"
			placeholder="https://"
			:disabled="disabled"
			:aria-required="field.required ? 'true' : 'false'"
			:aria-invalid="hasError"
			:aria-describedby="ariaDescribedby"
			@blur="onBlur($event.target.value)"
		/>
		<template v-if="(field.url2png || field.urlReferenceId) && value === field.value">
			<br />
			<url-field-embed-preview
				:url-type="field.urlType"
				:url="field.value"
				:external-url="field.externalUrl"
				:url-reference-id="field.urlReferenceId"
				:url2png="field.url2png"
			></url-field-embed-preview>
		</template>
	</div>
</template>

<script>
import Field from './Field.vue';
import UrlFieldEmbedPreview from './UrlFieldEmbedPreview';

export default {
	components: { UrlFieldEmbedPreview },
	extends: Field,
	methods: {
		onBlur(value) {
			this.validate();
			this.fieldInputChanged(value);
		},
		fieldInputChanged(value) {
			if (value && !value.startsWith('http://') && !value.startsWith('https://')) {
				value = 'https://' + value;
			}

			this.onInput(value);
		},
	},
};
</script>
