import CheckboxField from './CheckboxField.vue';
import CheckboxListField from './CheckboxListField.vue';
import ContentField from './ContentField.vue';
import CountryField from './CountryField.vue';
import CurrencyField from './CurrencyField';
import DateField from './DateField.vue';
import DatetimeField from './DatetimeField.vue';
import DateTimeTimezone from './DateTimeTimezone.vue';
import DropDownListField from './DropDownListField.vue';
import EmailField from './EmailField.vue';
import FileUploadField from './FileUploadField.vue';
import NumericField from './NumericField.vue';
import PhoneField from './PhoneField.vue';
import RadioButtonsField from './RadioButtonsField.vue';
import TextField from './TextField.vue';
import TextareaField from './TextareaField.vue';
import TableFieldWrapper from './TableFieldWrapper.vue';
import TimeField from './TimeField.vue';
import UrlField from './UrlField.vue';
import PasswordField from './PasswordField';
import FormulaField from './FormulaField';

export const fieldTypes = {
  'checkbox': 'checkbox-field',
  'checkboxlist': 'checkbox-list-field',
  'content': 'content-field',
  'country': 'country-field',
  'currency': 'currency-field',
  'date': 'date-field',
  'datetimetimezone': 'date-time-timezone',
  'datetime': 'datetime-field',
  'drop-down-list': 'drop-down-list-field',
  'email': 'email-field',
  'file': 'file-upload-field',
  'formula': 'formula-field',
  'numeric': 'numeric-field',
  'phone': 'phone-field',
  'radio': 'radio-buttons-field',
  'table': 'table-field-wrapper',
  'text': 'text-field',
  'textarea': 'textarea-field',
  'time': 'time-field',
  'url': 'url-field',
  'password': 'password-field'
};

export default {
  CheckboxField,
  CheckboxListField,
  ContentField,
  CountryField,
  CurrencyField,
  DateField,
  DatetimeField,
  DateTimeTimezone,
  DropDownListField,
  EmailField,
  FileUploadField,
  NumericField,
  PhoneField,
  RadioButtonsField,
  TableFieldWrapper,
  TextField,
  TextareaField,
  TimeField,
  UrlField,
  PasswordField,
  FormulaField
};
