import Vue from 'vue';
import store from '@/lib/store';
import ReviewStageList from './component/ReviewStageList';

export default function() {
  const reviewStageList = new Vue({
    el: '#review-stage-list',
    name: 'ReviewFlowStageListApp',
    components: {
      ReviewStageList
    },
    store
  });
  $(document).one('pjax:end', () => {
    reviewStageList.$destroy();
  });
}
