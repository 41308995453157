<template>
  <localised-datepicker
    :id="getId()"
    :value="value"
    :include-timezone="!!field.includeTimezone"
    :preselect-current-date="!!field.preselectCurrentDate"
    :mode="mode"
    :highlight-today="!!field.preselectCurrentDate"
    :disabled="disabled"
    @update:value="onInput"
    @changed="dateOrTimeChanged"
  />
</template>

<script>
import LocalisedDatepicker from '../Shared/LocalisedDatepicker.vue';
import Field from './Field.vue';

export default {
  components: { LocalisedDatepicker },
  extends: Field,
  inject: ['lang'],
  props: {
    field: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      required: true,
      validator: prop => ['date', 'datetime', 'time'].indexOf(prop) !== -1
    },
    value: {
      type: [String, Object],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    dateOrTimeChanged(event) {
      this.datetime = event;
      this.$emit('changed', event);
    }
  }
};
</script>
