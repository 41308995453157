export const defaultState = () => ({
	editing: false,
  /* eslint-disable @typescript-eslint/naming-convention */
	config: {
		'footer-height': null,
		'footer-height-mobile': null,
		'footer-link': '',
		'footer-link-mobile': '',
		'footer-logo-height': null,
		'footer-logo-height-mobile': null,
		'footer-logo-horizontal-alignment': 'centre',
		'footer-logo-horizontal-alignment-mobile': 'left',
		'header-height': null,
		'header-height-mobile': null,
		'header-link': '',
		'header-link-mobile': '',
		'header-logo-height': null,
		'header-logo-height-mobile': null,
		'header-logo-horizontal-alignment': 'centre',
		'header-logo-horizontal-alignment-mobile': 'left',
		'home-header-height': null,
		'home-header-height-mobile': null,
		'home-logo-height': null,
		'home-logo-height-mobile': null,
		'home-logo-horizontal-alignment': 'centre',
		'home-logo-horizontal-alignment-mobile': 'left',
		'brand-header-background': '#ffffff',
		'brand-header-background-mobile': '#ffffff',
		'brand-footer-background': '#ffffff',
		'brand-footer-background-mobile': '#ffffff',
	},
	files: {
		'footer-background-image': null,
		'footer-background-image-mobile': null,
		'footer-logo': null,
		'footer-logo-mobile': null,
		'header-background-image': null,
		'header-background-image-mobile': null,
		'header-logo': null,
		'header-logo-mobile': null,
		'home-logo': null,
		'home-logo-mobile': null,
		'home-background-image': null,
		favicon: null,
		'pdf-footer-image': null,
		'pdf-header-image': null,
		'email-header-image': null,
		'email-footer-image': null,
	},
  /* eslint-enable @typescript-eslint/naming-convention */
	currentColorId: null,
	currentColorDefault: null,
	customColors: {},
	uploads: {},
});

const state = defaultState();

export default state;
