import { createDao } from '@/domain/utils/DataAccessor';
import { vueData } from '@/domain/services/VueData';

/**
 * Gives access to accounts links data passed from the backend
 * all links from config/links.php are automatically passed
 * for awardforce / goodgrants entry depending on account type

     type ExpectedType = {
        link1: string;
        link2: string;
     };

     const { link1, link2 } = useLinksDao<ExpectedType>();

 */

const useLinksDao = <T = Record<string, string>>() => createDao<T>(vueData.links);

export { useLinksDao };
