var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
		'main-menu',
		{
			'primary-menu-open': _vm.primaryMenuOpen,
			'secondary-menu-open': _vm.secondaryMenuOpen,
		},
	]},[_c('primary-menu',{attrs:{"menu":_vm.menuWithParent,"current-url":_vm.currentUrl,"primary-menu-active-item":_vm.primaryMenuActiveItem,"primary-menu-open":_vm.primaryMenuOpen,"two-level-menus":_vm.twoLevelMenus,"current-focus":_vm.currentFocus},on:{"openShortcutMenu":_vm.openShortcutMenu,"menuSelected":_vm.selectPrimaryMenuItem,"itemSelected":(item) => _vm.openLink(item.link),"focus":(payload) => _vm.setFocus(payload),"focusNext":(payload) => _vm.focusToNext(payload),"focusPrevious":(payload) => _vm.focusToPrevious(payload)}}),_vm._v(" "),_c('secondary-menu',{attrs:{"menu":_vm.secondaryMenu,"settings-menu-is-open":_vm.secondaryMenuOpen,"current-url":_vm.currentUrl,"close-label":_vm.closeLabel,"focus-menu-name":_vm.secondaryMenuFocus,"current-focus":_vm.currentFocus},on:{"focus":(payload) => _vm.setFocus(payload),"focusNext":(payload) => _vm.focusToNext(payload),"focusPrevious":(payload) => _vm.focusToPrevious(payload),"itemSelected":(item) => _vm.openLink(item.link),"closed":_vm.closeSecondaryMenu}}),_vm._v(" "),_c('button',{staticClass:"btn-primary primary-menu-toggle",attrs:{"type":"button","aria-expanded":String(_vm.primaryMenuOpen)},on:{"click":function($event){$event.preventDefault();return _vm.togglePrimaryMenu.apply(null, arguments)}}},[_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.toggleLabel))]),_vm._v(" "),_c('span',{staticClass:"af-icons af-icons-chevron-up"})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }