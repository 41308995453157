var $ = require('jquery');

module.exports = function () {
	var defaultOptions = {
		content: 'Are you sure?',
	};

	var modal;

	/**
	 * Confirm click handler.
	 *
	 * @param {Function} callback
	 */
	var confirmClickHandler = function (callback) {
		modal.modal('hide');

		if (callback) {
			callback();
		}
	};

	/**
	 * Cancel click handler.
	 *
	 * @param {Function} callback
	 */
	var cancelClickHandler = function (callback) {
		if (callback) {
			callback();
		}
	};

	/**
	 * Show the modal.
	 *
	 * @param {Object} options
	 * @param {Function} onConfirm
	 * @param {Function} onCancel
	 */
	this.show = function (options, onConfirm, onCancel) {
		options = $.extend(defaultOptions, options);

		modal = $('#simpleModal').clone();

		modal.appendTo('body');

		modal.modal();

		modal.find('.modal-header').remove();
		modal.find('.modal-body').html(options.content);

		modal.find('#simpleModalOk').on('click', function () {
			confirmClickHandler(onConfirm);
		});

		modal.find('#simpleModalCancel').on('click', function () {
			cancelClickHandler(onCancel);
		});

		modal.on('hidden.bs.modal', function () {
			modal.remove();
		});

		modal.modal('show');
	};
};
