<template>
  <div>
    <div v-if="isLoading" class="loading-icon-wrapper">
      <i class="af-icons af-icons-repeat af-icons-animate-rotate"></i>
    </div>
    <img v-if="!isLoading && url" :alt="account.name + ' icon'" :src="url" class="account-switcher-favicon" />
    <svg
      v-if="!isLoading && !url && showPlaceholder"
      class="account-switcher-favicon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="12" fill="#ccc" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'AccountSwitcherIcon',
  props: {
    account: { type: Object, required: true }
  },
  data() {
    return {
      isLoading: true,
      url: null,
      showPlaceholder: false
    };
  },
  computed: {
    filteredDomains() {
      return this.account.domains.filter(domain => domain);
    }
  },
  async mounted() {
    await this.loadFavicon();
  },
  methods: {
    async loadFavicon() {
      const domainWithFavicon = await this.checkDomainsAsynchronously(this.filteredDomains);

      if (domainWithFavicon) {
        this.url = 'https://' + domainWithFavicon + '/favicon-logo';
        this.isLoading = false;
      }

      this.showPlaceholder = true;
      this.isLoading = false;
    },
    async checkDomainsAsynchronously(domains) {
      const promises = domains.map(this.fetchIcon);
      const results = await Promise.all(promises);
      const index = results.findIndex(result => result);
      return domains[index];
    },
    async fetchIcon(domain) {
      return new Promise(resolve => {
        let image = new Image();

        image.onload = function() {
          if (this.width > 0) {
            return resolve(domain);
          }
        };

        image.onerror = function() {
          return resolve(false);
        };

        image.src = 'https://' + domain + '/favicon-logo';
      });
    }
  }
};
</script>
