import { Player } from '@/domain/services/VideoPlayer/VideoPlayerWrapper';
import { useVideoPlayerApi } from '@/domain/services/VideoPlayer/VideoPlayerApi';

const videoPlayerErrorHandler = (player: Player, error: MediaError) => {
	const playerId = player.id();
	const fileId = playerId.split('-').pop();

	if (fileId === undefined) {
		return;
	}

	console.error('Video player error', error);

	const errorData = {
		...error,
		fileId: fileId,
		location: window.location.pathname,
		userAgent: window.navigator.userAgent,
	};

	useVideoPlayerApi.postLogError(fileId, errorData);
};

export default videoPlayerErrorHandler;
