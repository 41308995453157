<template>
  <div class="marker-action">
    <list-action-form
      ref="form"
      :ids="ids"
      :labels="labels"
      :route="route"
      :method="method"
      @submitted="toggle"
    >
      <input type="hidden" name="role" :value="roleId" />
      <portal :to="name">
        <div :key="name" :class="[`marker-action-role-selector-${action}`]">
          <div class="row">
            <div class="col-md-3">
              <select-field
                name="roles"
                :items="roles"
                :empty-option="false"
                id-property="id"
                value-property="name"
                @selected="(name, id) => (roleId = id)"
              />
            </div>
            <div class="col-md-4">
              <div class="buttons">
                <a
                  :class="[
                    'btn',
                    'btn-lg',
                    'btn-secondary',
                    { disabled: !roleId }
                  ]"
                  @click.prevent="submit"
                >
                  {{ labels.button }}
                </a>
                <a
                  :class="['btn', 'btn-lg', 'btn-secondary']"
                  @click.prevent="toggle"
                >
                  {{ labels.cancel }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </portal>
    </list-action-form>
  </div>
</template>

<script>
import ListAction from './ListAction.vue';
import { SelectField } from 'vue-bootstrap';

export default {
  components: {
    SelectField
  },
  extends: ListAction,
  props: {
    name: {
      type: String,
      required: true
    },
    roles: {
      type: Array,
      required: true
    },
    action: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      roleId: null
    };
  },
  methods: {
    toggle() {
      this.$emit('reveal', this.name);
    }
  }
};
</script>
