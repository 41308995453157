var Vue = require('vue');
var DataTable = require('./datatable.js');
var ExportActionDropdown = require('./components/Exports/ExportActionDropdown.vue').default;
var SavedViewsList = require('./../modules/search/components/SavedViewsList').default;
var SaveViewForm = require('./../modules/search/components/SaveViewForm').default;
var store = require('./../lib/store').default;

/**
 * Setup base components on the index view.
 *
 * Usage:
 *   new IndexView();
 *
 */

var datatable;

module.exports = function () {

  // Hacky way to move the initialization in the end of the event loop (start of the next event loop)
  process.nextTick(function() {
    datatable = new DataTable;
    datatable.setup('.datatable');
  });

  let savedViewsList, saveViewForm;

  $('.filtertron-tray').filtertronTray({
    onOpen: function () {
      datatable.refresh();

      if (!savedViewsList && !saveViewForm) {
        if ($('.save-view-form').length) {
          saveViewForm = new Vue({
            el: '.save-view-form',
            name: 'SaveViewFormApp',
            components: {
              SaveViewForm
            },
            store
          });
        }
        if ($('.saved-searches').length) {
          savedViewsList = new Vue({
            el: '.saved-searches',
            name: 'SavedSearchesApp',
            components: {
              SavedViewsList
            },
            store
          });
        }

        $(document).one('pjax:end', () => {
          if (saveViewForm) {
            saveViewForm.$destroy();
          }
          if (savedViewsList) {
            savedViewsList.$destroy();
          }
        });
      }
    },
    onClose: function () {
      datatable.refresh();
    }
  });

  $(document).one('pjax:end', () => {
    datatable.refresh();
  });

  var exportActionButton = document.getElementById('export-action');

  if (exportActionButton) {
    new Vue({
      el: exportActionButton,
      name: 'ExportActionButtonApp',
      components: {
        'export-action-dropdown': ExportActionDropdown
      }
    });
  }
};
