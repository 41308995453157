<template>
  <div>
    <div class="form-group">
      <div class="signature-pad type">
        <label for="fullName">
          {{ lang.get('signatures.form.full_name.label') }}
        </label>
        <input
          id="fullName"
          ref="fullName"
          v-model="fullName"
          name="fullName"
          type="text"
        />
      </div>
    </div>
    <div class="form-group">
      <button
        type="submit"
        class="btn btn-primary btn-lg"
        :disabled="isDisabled"
      >
        {{ lang.get('signatures.form.agree.label') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['lang'],
  props: {
    canSign: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      fullName: ''
    };
  },
  computed: {
    isEmpty() {
      return this.fullName.length === 0;
    },
    isDisabled() {
      return !this.canSign || this.isEmpty;
    }
  },
  mounted() {
    this.focus();
  },
  methods: {
    focus() {
      this.$refs.fullName.focus();
    }
  }
};
</script>
