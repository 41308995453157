export const defaultState = () => ({
  configurationMode: false,
  configurationInProgress: false,
  tabsLocked: false,
  draggedField: null,
  saving: false,
  hasErrors: false,

  // Field configuration
  configuredField: null,
  preservedField: null,
  conditionalFieldsList: [],

  // Tab configuration
  configuredTab: null,
  preservedTab: null,

  // Category configuration
  configuredCategory: null,
  preservedCategory: null,

  // Chapter configuration
  configuredChapter: null,
  preservedChapter: null,

  // Form configuration
  configuredForm: null,
  preservedForm: null
});

const state = defaultState();

export default state;
