var $ = require('jquery');
var Vue = require('vue');
var TagInput = require('vue-bootstrap').TagInput;

/**
 * The tags input module abstracts away the underlying implementation detail,
 * and allows us to setup automatic bindings for inputs:
 *
 *  input.tags-input
 *
 * Usage:
 *
 *    var tagsInput = require('tags-input');
 *    tagsInput.setup($('.tags-input'));
 */

module.exports = {
  /**
   * Sets up the relevant elements with a tag input component.
   *
   * @param collection elements
   */
  setup: function(elements) {
    for (var i = 0; i < elements.length; i++) {
      new Vue({
        el: elements[i],
        name: 'TagsInputApp' + i,
        components: {
          'tag-input': TagInput
        }
      });
    }
  }
};
