<template>
	<panel ref="panel" panel-class="scroll-horizontally scroll-vertically shadows">
		<table ref="configurator" class="table-field table-field-configurator">
			<thead>
				<tr>
					<th></th>
					<th :colspan="columns.length">
						<div class="columns">
							<columns
								:draggable-columns="columns"
								:has-entries="hasEntries"
								:input-types="inputTypes"
								:labels="labels"
								:supported-languages="supportedLanguages"
								@dragging="setColumns"
							>
							</columns>
						</div>
					</th>
					<th class="add-column">
						<a @click.prevent="insertColumn({ position: 'right', from: lastColumn })">
							<span class="af-icons af-icons-plus"></span>
						</a>
					</th>
				</tr>
			</thead>
			<tbody :class="{ 'has-calculations': tableHasCalculations }">
				<tr v-for="(row, rowIndex) in rows" :key="row">
					<td v-if="rowIndex === 0" :rowspan="rows.length">
						<div class="rows">
							<rows :active-row="activeRow" :draggable-rows="rows" @dragging="setRows"> </rows>
						</div>
					</td>
					<cell
						v-for="(column, columnIndex) in columns"
						:key="row + ':' + column"
						:active-row.sync="activeRow"
						:column="column"
						:column-index="columnIndex"
						:labels="labels"
						:last-column-index="columns.length - 1"
						:row="row"
						:row-index="rowIndex"
						:supported-languages="supportedLanguages"
						@editor-opened="expandPanel"
						@editor-closed="collapsePanel"
					>
					</cell>
					<td @mouseenter="activeRow = row" @mouseleave="activeRow = ''">
						<button v-show="canDeleteRows && activeRow === row" class="delete-row btn-link" @click="removeRow(row)">
							<Popover v-if="preventRowDeletion(row)" :content="labels.alertDeleteRow" trigger="hover">
								<span class="af-icons af-icons-close-circle" disabled="disabled"></span>
							</Popover>
							<span v-else class="af-icons af-icons-close-circle"></span>
						</button>
					</td>
				</tr>
			</tbody>
			<tfoot>
				<tr v-if="tableHasCalculations" class="row-calculation">
					<th></th>
					<th v-for="column in columns" :key="column" class="cell-calculation">
						<template v-if="columnHasCalculation(column)">
							{{ translatedCalculationLabel(column) }}
						</template>
					</th>
					<th></th>
				</tr>
				<tr>
					<td></td>
					<td :colspan="columns.length">
						<a class="btn btn-tertiary btn-xs add-row" href="javascript:void(0)" @click.prevent="addRow">
							{{ labels.addRow }}
						</a>
						<div class="checkbox-inline checkbox styled">
							<input id="dynamic-rows" :checked="dynamicRowsEnabled" type="checkbox" @change="handleDynamicRows" />
							<label class="dynamic-rows" for="dynamic-rows">
								{{ labels.dynamicRows }}
							</label>
						</div>
					</td>
					<td></td>
				</tr>
			</tfoot>
		</table>
		<input v-model="tableFieldConfiguration" name="configuration" type="hidden" />
		<template v-for="(translation, language) in translations">
			<template v-if="supportedLanguages.includes(language)">
				<input
					v-for="(value, field) in translation"
					:key="field + '-' + language"
					:name="'translatedConfiguration[' + field + '][' + language + ']'"
					:value="value"
					type="hidden"
				/>
			</template>
		</template>
	</panel>
</template>

<script>
import Vue from 'vue';
import { Panel, Popover } from 'vue-bootstrap';
import Columns from './TableFieldConfiguratorColumns.vue';
import Rows from './TableFieldConfiguratorRows.vue';
import Cell from './TableFieldConfiguratorCell.vue';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { createStore } from '../../store/modules/table-field';
import { getGlobal } from '@/lib/utils.js';
import calculationFunctionMixin from '@/lib/components/Fields/mixins/calculation-functions-mixin';

export default {
	components: {
		Panel,
		Popover,
		Columns,
		Rows,
		Cell,
	},
	mixins: [calculationFunctionMixin],
	props: {
		configuration: {
			type: Object,
			default: () => ({}),
		},
		configurationTranslated: {
			type: Object,
			default: () => ({}),
		},
		inputTypes: {
			type: Array,
			default: () => [
				{ id: 'label', name: 'Row label' },
				{ id: 'text', name: 'Plain text' },
				{ id: 'integer', name: 'Integer (1)' },
				{ id: 'decimal', name: 'Decimal (0.1)' },
				{ id: 'decimal-precise', name: 'Decimal (0.01)' },
				{ id: 'currency', name: 'Currency' },
			],
		},
		supportedLanguages: {
			type: Array,
			default: () => ['en_GB'],
		},
		labels: {
			type: Object,
			default: () => ({
				addRow: 'Add row',
				dynamicRows: 'User can add additional rows',
				configureColumn: 'Configure column',
				insertLeft: 'Insert left',
				insertRight: 'Insert right',
				deleteColumn: 'Delete column',
				column: 'Column',
				columnLabel: 'Column label',
				columnType: 'Column type',
				rowLabel: 'Row label',
				save: 'Save',
				cancel: 'Cancel',
			}),
		},
		hasEntries: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			activeRow: '',
			existingRows: [],
		};
	},
	computed: {
		...mapState(['columns', 'rows', 'filters', 'dynamicRowsEnabled', 'translations']),
		...mapGetters(['getConfiguration', 'columnHasCalculation', 'tableHasCalculations', 'getCalculationTranslation']),
		tableFieldConfiguration() {
			return JSON.stringify(this.getConfiguration());
		},
		tableFieldTranslations() {
			return JSON.stringify(this.translations);
		},
		canDeleteRows() {
			return this.rows.length > 1;
		},
		lastColumn() {
			return this.columns[this.columns.length - 1];
		},
	},
	watch: {
		tableFieldConfiguration() {
			this.$emit('updated', this.tableFieldConfiguration, this.tableFieldTranslations);
		},
		tableFieldTranslations() {
			this.$emit('updated', this.tableFieldConfiguration, this.tableFieldTranslations);
		},
	},
	beforeCreate() {
		this.$store = createStore();
	},
	created() {
		this.existingRows = [...(this.configuration?.rows || [])];
		this.setConfiguration(this.configuration);
		this.setTranslations(this.configurationTranslated);
	},
	methods: {
		...mapMutations([
			'setConfiguration',
			'setColumns',
			'setRows',
			'enableDynamicRows',
			'setTranslations',
			'addRow',
			'deleteRow',
			'insertColumn',
		]),
		handleDynamicRows(event) {
			this.enableDynamicRows(event.target.checked);
		},
		expandPanel() {
			Vue.nextTick(() => {
				setTimeout(() => {
					const configurator = this.$refs.configurator;
					const panel = this.$refs.panel;

					if (!configurator || !panel) {
						return;
					}

					const configuratorHeight = configurator.clientHeight + 35;
					const panelHeight = panel.$el.scrollHeight;

					if (panelHeight > configuratorHeight) {
						configurator.style.marginBottom = panelHeight - configuratorHeight + 30 + 'px';
					}
				}, 100);
			});
		},
		collapsePanel() {
			Vue.nextTick(() => {
				const configurator = this.$refs.configurator;

				if (configurator) {
					this.$refs.configurator.style.marginBottom = 0;
				}
			});
		},
		removeRow(row) {
			// Make sure the clickOutside directive doesn't incorrectly detect
			// a click outside when the row disappears
			if (!this.preventRowDeletion(row)) {
				setTimeout(() => this.deleteRow(row), 0);
			}
		},
		translatedCalculationLabel(column) {
			return (
				this.getCalculationTranslation(getGlobal('language'), column) ||
				this.getCalculationTranslation(getGlobal('defaultLanguage'), column)
			);
		},
		preventRowDeletion(row) {
			return this.hasEntries && this.existingRows.includes(row);
		},
	},
};
</script>
