<template>
	<div class="marker-action">
		<list-action-form ref="form" :ids="ids" :labels="labels" :route="route" :method="method" @submitted="toggle">
			<input type="hidden" name="primary" :value="primary" />
			<portal :to="name">
				<modal
					v-model="showModal"
					:header="false"
					:confirm-button-label="labels.title"
					:close-button-label="labels.cancel"
					:header-close-button="true"
					:confirm-disabled="primary.length === 0"
					:confirm-on-enter="false"
					:tab-index="null"
					@closed="modalClosed"
					@confirmed="submit"
				>
					<close-icon slot="before-content" @click.prevent="toggle"></close-icon>
					<label for="duplicate-search">{{ labels.search }}</label>
					<Select2
						v-if="showModal"
						id="duplicate-search"
						element="input"
						:settings="options"
						name="suggest-primary"
						@change="onPrimarySelected"
					/>
				</modal>
			</portal>
		</list-action-form>
	</div>
</template>

<script>
import ListAction from './ListAction.vue';
import CloseIcon from './Partials/CloseIcon';
import { Typeahead, Modal } from 'vue-bootstrap';
import modalMixin from './mixins/modal-mixin';
import Select2 from '../Select2';

/**
 * The Duplicate Archiver component.
 */
export default {
	components: {
		CloseIcon,
		Typeahead,
		Modal,
		Select2,
	},
	extends: ListAction,
	mixins: [modalMixin],
	props: {
		placeholder: {
			type: String,
			default: 'Select primary',
		},
		name: {
			type: String,
			required: true,
			validator: (value) => ['duplicate-archiver'].indexOf(value) !== -1,
		},
	},
	data() {
		return {
			primary: '',
			src: '',
		};
	},
	computed: {
		options() {
			return {
				ajax: {
					url: this.src,
					dataType: 'json',
					delay: 250,
					data: function (searchTerm) {
						return {
							name: searchTerm,
							selected: this.primary,
						};
					},
					results: function (data) {
						return {
							results: data,
						};
					},
					cache: true,
				},
				formatResult: function (entry) {
					return entry.value;
				},
				formatSelection: function (entry) {
					return entry.value;
				},
				minimumInputLength: 3,
			};
		},
	},
	watch: {
		ids(ids) {
			if (this.showModal) {
				this.src = '/entry/duplicates/autocomplete?selected=' + ids + '&name=';
			}
		},
	},
	mounted() {
		this.src = '/entry/duplicates/autocomplete?selected=' + this.ids + '&name=';
	},
	methods: {
		onPrimarySelected(result) {
			this.primary = result;
		},
	},
};
</script>
