<template>
  <popover
    v-if="tooltipText"
    ref="popover"
    :content="tooltipText"
    placement="bottom"
    popover-class="keyword-search-popover"
  >
    <keyword-input v-bind="$props" @keydown="onKeydown" />
  </popover>
  <keyword-input v-else v-bind="$props" />
</template>

<script>
import { Popover } from 'vue-bootstrap';
import KeywordInput from './KeywordInput';

export default {
  components: {
    Popover,
    KeywordInput
  },
  props: {
    keywords: {
      type: String,
      default: ''
    },
    tooltipText: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      value: this.keywords
    };
  },
  methods: {
    onKeydown() {
      this.$refs.popover.close();
    }
  }
};
</script>
