import { Headers } from '@/domain/services/Api/Headers';
import axios, { AxiosError, AxiosResponse } from 'axios';

enum DataRequestMethod {
	GET = 'get',
	DELETE = 'delete',
	POST = 'post',
	PUT = 'put',
}

type DataResponse<T> = AxiosResponse<T>;
type DataError<T> = AxiosError<T>;
type DataRequestConfig<T> = {
	url: string;
	method: DataRequestMethod;
	data?: T;
	headers?: Headers;
};

type DataSource = {
	request<TRequest, TResponse>(config: DataRequestConfig<TRequest>): Promise<DataResponse<TResponse>>;
};

const dataSource: DataSource = axios.create({});

export { DataSource, DataResponse, DataError, DataRequestConfig, DataRequestMethod, dataSource };
