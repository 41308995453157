<template>
  <welcome
    v-if="successResponse"
    :welcome="successResponse"
  ></welcome>
  <div v-else>
    <div class="row-header">
      <div class="col-xs-12">
        <img class="img-responsive af-logo" :src="brandLogo" />
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-md-3 col-md-offset-1 col-md-push-7">
        <h1 class="provisioning-label" role="heading" aria-level="2">
          {{ lang.get('provisioning.form.language.label') }}
        </h1>
        <div class="vertical well ignore">
          <language
            :preferred-language="language"
            :languages="provisioningLanguages"
          ></language>
        </div>
      </div>
      <div class="col-xs-12 col-md-7 col-md-pull-3">
        <h1 class="provisioning-label" role="heading" aria-level="2">
          {{ lang.get('provisioning.form.heading') }}
        </h1>
        <div class="well">
          <div :class="['form-group', { error: errorExists('region') }]">
            <label for="region">
              {{ lang.get('provisioning.form.region.label') }}
            </label>
            <help-icon
              :content="lang.get('provisioning.form.region.help')"
            ></help-icon>
            <select
              id="region"
              v-model="saveAbleData.region"
              class="form-control"
              :disabled="saving"
              @change="changeRegion($event)"
            >
              <option
                v-for="(region, index) in regions"
                :key="index"
                :value="index"
              >
                {{ region }}
              </option>
            </select>
          </div>

          <div :class="['form-group', { error: errorExists('email') }]">
            <span class="af-icons af-icons-lock"></span>
            <label for="email">
              {{ lang.get('provisioning.form.email.label') }}
            </label>
            <input
              id="email"
              v-model="saveAbleData.email"
              type="text"
              class="form-control"
              readonly
              disabled
            />
            <div
              v-if="errorExists['email']"
              class="alert-error sticky inline"
              role="alert"
            >
              {{ errors['email'] }}
            </div>
          </div>
          <div v-if="!user">
            <div :class="['form-group', { error: errorExists('firstName') }]">
              <label for="first_name">
                {{ lang.get('provisioning.form.first_name.label') }}
              </label>
              <input
                id="first_name"
                v-model="saveAbleData.firstName"
                type="text"
                class="form-control"
                :disabled="saving"
                @input="cacheProvisioner('firstName', $event.target.value)"
              />
              <div
                v-if="errorExists['firstName']"
                class="alert-error sticky inline"
                role="alert"
              >
                {{ errors['firstName'] }}
              </div>
            </div>

            <div :class="['form-group', { error: errorExists('lastName') }]">
              <label for="last_name">
                {{ lang.get('provisioning.form.last_name.label') }}
              </label>
              <input
                id="last_name"
                v-model="saveAbleData.lastName"
                type="text"
                class="form-control"
                :disabled="saving"
                @input="cacheProvisioner('lastName', $event.target.value)"
              />
              <div
                v-if="errorExists('lastName')"
                class="alert-error sticky inline"
                role="alert"
              >
                {{ errors['lastName'] }}
              </div>
            </div>

            <div :class="['form-group', { error: errorExists('password') }]">
              <label for="password">
                {{ lang.get('provisioning.form.password.label') }}
                <br />
                <span class="help-text">
                  {{ lang.get('miscellaneous.password.hint') }}
                </span>
              </label>
              <input
                id="password"
                v-model="saveAbleData.password"
                type="password"
                class="form-control"
                :disabled="saving"
              />
              <div
                v-if="errorExists('password')"
                class="alert-error sticky inline"
                role="alert"
              >
                {{ errors['password'][0] }}
              </div>
            </div>

            <div
              :class="[
                'form-group',
                { error: errorExists('password_confirmation') }
              ]"
            >
              <label for="password_confirmation">
                {{ lang.get('provisioning.form.password_confirmation.label') }}
              </label>
              <input
                id="password_confirmation"
                v-model="saveAbleData.password_confirmation"
                type="password"
                class="form-control"
                :disabled="saving"
              />
              <div
                v-if="errorExists('password_confirmation')"
                class="alert-error sticky inline"
                role="alert"
              >
                {{ errors['password_confirmation'][0] }}
              </div>
            </div>
          </div>

          <div :class="['form-group', { error: errorExists('accountName') }]">
            <label for="account_name">
              {{ lang.get('provisioning.form.account_name.label') }}
            </label>
            <help-icon
              :content="lang.get('provisioning.form.account_name.help')"
            ></help-icon>
            <input
              id="account_name"
              v-model="saveAbleData.accountName"
              type="text"
              class="form-control data-hj-allow"
              :disabled="saving"
              @input="debounceGenerateUniqueDomain"
            />
            <div
              v-if="errorExists('accountName')"
              class="alert-error sticky inline"
              role="alert"
            >
              {{ errors['accountName'][0] }}
            </div>
          </div>
          <div
            v-if="
              (suggestedDomain.fullDomain && !accountDomainInEdit) ||
                accountDomainInEdit
            "
            :class="[
              'form-group',
              { error: errorExists('subDomain') && errorExists('rootDomain') }
            ]"
          >
            <label for="sub_domain">
              {{ lang.get('provisioning.form.account_domain.label') }}
            </label>
            <help-icon
              :content="lang.get('provisioning.form.account_domain.help')"
            ></help-icon>
            <div
              v-if="suggestedDomain && !accountDomainInEdit"
              class="domain-name clearfix"
            >
              <div class="pull-left">
                {{ suggestedDomain.fullDomain }}
              </div>
              <div class="pull-right">
                <a @click="toggleEdit">
                  Edit
                </a>
              </div>
            </div>

            <div v-else-if="accountDomainInEdit">
              <div id="sub_domain" class="input-group">
                <span class="input-group-addon input-group-addon-lg"
                  >https://</span
                >
                <input
                  v-model="saveAbleData.subDomain"
                  type="text"
                  class="form-control data-hj-allow"
                  style="width: 49%"
                  :disabled="saving"
                  @input="checkDomainAvailability($event.target.value)"
                />
                <select
                  id="root_domain"
                  v-model="saveAbleData.rootDomain"
                  class="form-control"
                  style="width: 49%"
                  :disabled="saving"
                  @change="cacheProvisioner('rootDomain', $event.target.value)"
                >
                  <option
                    v-for="(domain, index) in rootDomains"
                    :key="index"
                    :value="domain"
                  >
                    {{ domain }}
                  </option>
                </select>
              </div>
              <div
                v-if="errorExists('accountDomain')"
                class="alert-error sticky inline"
                role="alert"
              >
                {{ errors['accountDomain'][0] }}
              </div>
            </div>
            <div
                v-if="errorExists('subDomain')"
                class="alert-error sticky inline"
                role="alert"
            >
              {{ errors['subDomain'][0] }}
            </div>
          </div>
          <div
            v-if="provisioner.isMultilingual"
            class="form-group"
            :class="errorExists('supportedLanguages') ? 'error' : ''"
          >
            <label for="supportedLanguages">
              {{ lang.get('provisioning.form.supported_languages.label') }}
            </label>
            <multiselect
              name="supportedLanguages"
              :options="provisioningLanguages"
              :subset="true"
              subset-name="defaultLanguage"
              :selected-options="multiLang.selected"
              :selected-subset-options="[saveAbleData.defaultLanguage]"
              :counter="false"
              id-property="slug"
              value-property="language"
              :randomizer="false"
              :remember-selection="true"
              :filter="false"
              :disabled="saving"
              :placeholder="
                lang.get('multiselect.placeholder.search_languages')
              "
              @selected="multiLangSelected"
            />
            <div
              v-if="errorExists('supportedLanguages')"
              class="alert-error sticky inline"
              role="alert"
            >
              {{ errors['supportedLanguages'][0] }}
            </div>
          </div>

          <div
            v-if="!provisioner.isMultilingual"
            class="form-group"
            :class="errorExists('language') ? 'error' : ''"
          >
            <label for="language">
              {{ lang.get('provisioning.form.language.label') }}
            </label>
            <select
              id="language"
              v-model="saveAbleData.language"
              class="form-control"
              :disabled="saving"
              @input="cacheProvisioner('language', $event.target.value)"
            >
              <option
                v-for="(language, index) in provisioningLanguages"
                :key="index"
                :value="language.slug"
              >
                {{ language.language }}
              </option>
            </select>
            <div
              v-if="errorExists('language')"
              class="alert-error sticky inline"
              role="alert"
            >
              {{ errors['language'][0] }}
            </div>
          </div>
          <div class="form-group">
            <button
              class="btn btn-primary btn-lg"
              @click="saveRequest"
            >
              <span v-if="saving">
                <i class="busy"></i>
                {{ lang.get('provisioning.processing') }}
              </span>
              <span v-else>
                {{ lang.get('provisioning.form.submit.label') }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import toastr from 'toastr';
import Welcome from './Welcome';
import Language from './Language';
import { route } from '@/lib/utils';
import Storage from '@/lib/storage';
import { Multiselect } from 'vue-bootstrap';
import HelpIcon from '@/lib/components/Shared/HelpIcon';
import langMixin from '@/lib/components/Translations/mixins/lang-mixin';
import _ from 'underscore';

export default {
  components: {
    Language,
    Welcome,
    HelpIcon,
    Multiselect
  },
  mixins: [langMixin],
  props: {
    provisioner: {
      type: Object,
      default: () => ({
        id: null,
        column: null,
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        password_confirmation: null,
        accountName: null,
        subDomain: null,
        rootDomain: null,
        region: null,
        isMultilingual: false,
        supportedLanguages: [],
        defaultLanguage: 'en_GB'
      })
    },
    rootDomains: {
      type: Object,
      default: () => {}
    },
    regions: {
      type: Object,
      default: () => {}
    },
    provisioningLanguages: {
      type: [Array],
      default: null
    },
    user: {
      type: Boolean,
      default: false
    },
    brandLogo: {
      type: String,
      required: true
    },
    routes: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      successResponse: null,
      errors: {},
      accountDomainInEdit: false,
      suggestedDomain: {
        fullDomain: null,
        subDomain: null,
        rootDomain: null
      },
      saveAbleData: this.provisioner,
      multiLang: {
        selected: ['en_GB'],
        selected_subset: ['en_GB']
      },
      saving: false
    };
  },
  computed: {
    errorExists() {
      return field => field in this.errors;
    },
    multiregionDomain() {
      this.urlRegion();
      return window.location.href
        .split(this.saveAbleData.region)[1]
        .split('/')[0];
    }
  },
  created() {
    this.storage = new Storage();
    this.saveAbleData.defaultLanguage = this.multiLang.selected[0];
    this.debounceGenerateUniqueDomain = _.debounce(this.generateUniqueDomain, 300);
    let form =
      JSON.parse(this.storage.readCookie(this.localStorageKey())) ??
      this.restoreProvisionerData();

    if (form !== null) {
      this.saveAbleData = {
        ...this.saveAbleData,
        ...form
      };

      if (form.supportedLanguages) {
        this.multiLang.selected = form.supportedLanguages;
        this.saveAbleData.defaultLanguage = form.defaultLanguage;
      }

      if (form.subDomain && form.rootDomain) {
        this.suggestedDomain.fullDomain =
          form.subDomain + '.' + form.rootDomain;
      }
    }

    if (this.saveAbleData.accountName != null && !this.saveAbleData.subDomain) {
      this.generateUniqueDomain();
    }

    this.urlRegion();
  },
  methods: {
    localStorageKey() {
      return `provisioner-${this.provisioner.id}`;
    },
    cacheProvisioner(input, value) {
      this.saveAbleData[input] = value;

      let storedProvisioner = this.restoreProvisionerData(); // extract stored form
      if (!storedProvisioner) storedProvisioner = {}; // if none exists, default to empty object

      storedProvisioner[input] = value; // store new value
      this.storage.set(
        this.localStorageKey(),
        JSON.stringify(storedProvisioner)
      );
    },
    clearCache() {
      this.storage.remove(this.localStorageKey());
      this.storage.removeCookie(this.localStorageKey());
    },
    restoreProvisionerData() {
      const provisionerState = this.storage.get(this.localStorageKey());
      if (provisionerState) {
        return JSON.parse(provisionerState);
      }
      return null;
    },
    toggleEdit() {
      this.accountDomainInEdit = !this.accountDomainInEdit;
      if (this.accountDomainInEdit) {
        this.saveAbleData.subDomain = '';
      }
    },
    multiLangSelected(selected) {
      this.saveAbleData.supportedLanguages = selected;
      this.checkDefaultLanguage(selected);
    },
    checkDefaultLanguage(selected) {
      if (selected.length === 1) {
        this.saveAbleData.defaultLanguage = selected[0];

        let defaultLangElement = this.getDefaultLanguageElement();
        if (defaultLangElement.length > 0 && defaultLangElement.filter(e => e.value === selected[0]).length > 0) {
          defaultLangElement.filter(e => e.value === selected[0])[0].checked = true;
        }
      }
    },
    getDefaultLanguageElement() {
      return Array.from(document.getElementsByName('defaultLanguage'));
    },
    urlRegion() {
      this.saveAbleData.region = window.location.hostname.split('.')[1];
    },
    changeRegion() {
      this.cacheProvisioner('region', this.saveAbleData.region);
      let domainParts = window.location.hostname.split('.');

      domainParts.splice(1, 1, this.saveAbleData.region);

      this.storeCurrentForm();

      window.location =
        'https://' + domainParts.join('.') + '/' + window.location.search;
    },
    generateUniqueDomain() {
      if (this.accountDomainInEdit) return;
      this.cacheProvisioner('accountName', this.saveAbleData.accountName);
      if (this.saveAbleData.accountName.length <= 1) {
        this.suggestedDomain.fullDomain = '';
        this.saveAbleData.subDomain = '';
        this.saveAbleData.rootDomain = '';
      }
      if (this.saveAbleData.accountName.length > 1) {
        this.$http
          .post(
            route(this.routes['domain-suggestion']) +
              `?${this.provisioner.column}=` +
              this.saveAbleData.id,
            {
              accountName: this.saveAbleData.accountName
            }
          )
          .then(
            response => {
              if (response.data.length !== 0) {
                this.suggestedDomain = response.data;
                this.saveAbleData.subDomain = this.suggestedDomain.subDomain;
                this.saveAbleData.rootDomain = this.suggestedDomain.rootDomain;
              }
            },
            error => {
              toastr.error(error.response.data.message);
            }
          );
      }
    },
    checkDomainAvailability(subDomain){
      this.cacheProvisioner('subDomain', subDomain);

      if (this.saveAbleData.subDomain.length > 1) {
        this.$http
          .post(
            route(this.routes['domain-availability']),
            {
              subDomain: this.saveAbleData.subDomain,
              rootDomain: this.saveAbleData.rootDomain,
            }
          )
          .then(
            response => {
              this.errors = {};
            },
            error => {
              if (error.response.status === 422) {
                this.errors = error.response.data;
              } else {
                toastr.error(error.response.data.message);
              }
            }
          );
      }
    },
    getDefaultLanguage() {
      let defaultLangElement = this.getDefaultLanguageElement();
      this.saveAbleData.defaultLanguage =
        defaultLangElement.length > 0 && defaultLangElement.filter(n => n.checked).length > 0
          ? defaultLangElement.filter(n => n.checked)[0].value
          : '';
    },
    storeCurrentForm() {
      this.getDefaultLanguage();
      // Needs to be stored via cookie in order to share data between different regions aka different domains
      this.storage.createCookie(
        this.localStorageKey(),
        JSON.stringify(this.saveAbleData),
        0.04, // 1 hour ~ 0.04 days
        this.multiregionDomain
      );
    },
    saveRequest() {
      this.saving = true;
      if (!this.saveAbleData.isMultilingual) {
        delete this.saveAbleData.supportedLanguages;
      } else {
        if (this.saveAbleData.supportedLanguages !== null) {
          this.getDefaultLanguage();
        }
      }
      if (!this.user) {
        this.saveAbleData.user = 'new';
      }

      this.$http
        .post(
          route(this.routes['setup']) +
            `?${this.provisioner.column}=` +
            this.saveAbleData.id,
          this.saveAbleData
        )
        .then(
          response => {
            if (response.data !== '') {
              this.successResponse = response.data;
              this.clearCache();
              this.saving = false;
            }
          },
          error => {
            this.saving = false;
            if (error.response.status === 422) {
              this.errors = error.response.data;
            } else {
              toastr.error(error.response.data.message);
            }
          }
        );
    }
  }
};
</script>

<style scoped>
.af-logo {
  max-width: 100px;
  margin: 0 auto;
}
.domain-name {
  margin: 5px 0;
  padding: 8px;
  border: 1px solid #6f6f6f;
  border-radius: 4px;
  background: #fff;
}

.busy {
  animation: spin 1s infinite linear;
  display: inline-block;
  font-weight: bold;
  font-family: sans-serif;
  font-size: 20px;
  font-style: normal;
  color: #fff;
  margin-right: 2px;
}

.busy::before {
  content: '\25E0';
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
</style>
