import { CommentUpdatedResponse } from '@/lib/components/Comments/Comment.types';
import { EventEmitters } from '@/domain/utils/Events';

enum CommentEvents {
	Added = 'added',
	Commenting = 'commenting',
	Updated = 'updated',
	Deleted = 'deleted',
}

type CommentEmitters = EventEmitters<{
	[CommentEvents.Updated]: (data: CommentUpdatedResponse) => void;
	[CommentEvents.Deleted]: () => void;
}>;

export { CommentEvents, CommentEmitters };
