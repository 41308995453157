export default {
  inserted: function(el, binding, vnode) {
    const callback = () => binding.value({ width: el.offsetWidth, height: el.offsetHeight });

    window.addEventListener('resize', callback);

    el._onResize = Object(el._onResize);
    el._onResize[vnode.context._uid] = { callback };

    callback();
  },
  unbind: function(el, binding, vnode) {
    if (!el._onResize?.[vnode.context._uid]) return;

    const { callback } = el._onResize[vnode.context._uid];

    window.removeEventListener('resize', callback);

    delete el._onResize[vnode.context._uid];
  }
};
