import { useGlobal } from '@/services/global/global.interface';

export default {
	props: {
		features: {
			type: Array,
			default: () => useGlobal('features'),
		},
	},
	data() {
		return {
			featuresService: null,
		};
	},
	provide() {
		this.featuresService = {
			features: this.features,
			enabled: function (feature) {
				return this.features.some((f) => f.feature === feature && f.enabled);
			},
			disabled: function (feature) {
				return !this.enabled(feature);
			},
		};

		return {
			featuresService: this.featuresService,
		};
	},
};
