import { isNotVideo, videoLengthComparison } from './videoUtils';

const videoLengthFallsBelowLimit = (minVideoLength, file) =>
	videoLengthComparison(minVideoLength, file, (duration, limit) => duration < limit);

/**
 * A 'min_video_length' filter for Plupload.
 *
 * How to use:
 * https://www.plupload.com/docs/v2/File-Filters
 */
export default async function (maxVideoLength, file, cb) {
	if (isNotVideo(file) || !(await videoLengthFallsBelowLimit(maxVideoLength, file))) {
		// eslint-disable-next-line standard/no-callback-literal
		cb(true);
		return;
	}

	this.trigger('Error', {
		code: -605,
		file: file,
	});

	// eslint-disable-next-line standard/no-callback-literal
	cb(false);
}
