import store from '@/lib/store';
import { computed, ComputedRef } from 'vue';

const useLanguages = () => {
	const hasMultilingualError = store.getters['validation/hasMultilingualError'];
	const validationErrors = store.state.validation.validationErrors;

	const supportedLanguages: ComputedRef<string[]> = computed(() => store.state.global.supportedLanguages);
	const preferredLanguage: ComputedRef<string> = computed(() => store.state.global.preferredLanguage);
	const multiLanguage: ComputedRef<boolean> = computed(() => supportedLanguages.value.length > 1);

	return {
		hasMultilingualError,
		multiLanguage,
		preferredLanguage,
		supportedLanguages,
		validationErrors,
	};
};

export { useLanguages };
