import $ from 'jquery';
import Vue from 'vue';

const mountVueWithComponents = function (tag, component, components, withStore = true, destroy = true) {
	const elements = [...document.getElementsByTagName(tag)];
	const instanceName = (i) =>
		`MountVue${tag.replace(/-./g, (x) => x[1].toUpperCase()).replace(/^./, (x) => x.toUpperCase())}${i}App`;

	if (elements.length !== 0) {
		const store = require('@/lib/store/index').default;
		elements.forEach((el, i) => {
			const vueInstance = new Vue({
				el,
				name: instanceName(i),
				components: Object.assign(components, { [tag]: component.default }),
				store: withStore ? store : null,
			});

			if (destroy) {
				$(document).one('pjax:end', () => {
					vueInstance.$destroy();
				});
			}
		});
	}
};

const mountVueComponent = function (tag, component, withStore = true, destroy = true) {
	mountVueWithComponents(tag, component, {}, withStore, destroy);
};

// Function to mount a Vue component directly to a specific DOM element
const mountVueComponentFromElement = function (element, component, name = null, withStore = true, destroy = true) {
	const store = withStore ? require('@/lib/store/index').default : null;

	const componentName = name || `${component.name}App`;
	const tag = component.name
		.replace(/([A-Z])/g, '-$1')
		.toLowerCase()
		.replace(/^-/, '');

	const vueInstance = new Vue({
		el: element,
		name: componentName,
		components: {
			[tag]: component,
		},
		store,
		provide: function () {
			return {
				lang: {
					locale: App.language,
				},
			};
		},
	});

	if (destroy) {
		$(document).one('pjax:end', () => {
			vueInstance.$destroy();
		});
	}
};

export { mountVueComponent, mountVueWithComponents, mountVueComponentFromElement };
