<template>
	<div v-if="isVimeo" class="video-wrapper">
		<vimeo-player
			:player-height="height"
			:player-width="width"
			:video-id="videoId"
			frameborder="0"
			title="Video"
			@loaded="videoReady"
			@play="handleVideoPlaying"
		></vimeo-player>
	</div>
	<div v-else-if="isYoutube" :style="{ width: width, height: height }" class="video-wrapper">
		<component
			:is="youtubePlayerComponent"
			:player-height="height"
			:player-width="width"
			:video-id="videoId"
			frameborder="0"
			title="Video"
			@playing="handleVideoPlaying"
			@ready="videoReady"
		></component>
	</div>
</template>

<script>
import VimeoPlayer from '@/lib/components/Video/VimeoPlayer.vue';
import Vue from 'vue';

export default {
	components: {
		VimeoPlayer,
	},
	props: {
		url: {
			type: String,
			required: true,
		},
		width: {
			type: Number,
			default: 640,
		},
		height: {
			type: Number,
			default: 320,
		},
	},
	data() {
		return {
			videoPlayed: false,
			youtubePlayerComponent: null,
		};
	},
	computed: {
		isVimeo() {
			return this.url.indexOf('vimeo') !== -1;
		},
		isYoutube() {
			return this.url.indexOf('youtube') !== -1 || this.isYoutuDotBe;
		},
		isYoutuDotBe() {
			return this.url.indexOf('youtu.be') !== -1;
		},
		videoId() {
			if (this.isVimeo) {
				return this.url.substring(this.url.lastIndexOf('/') + 1);
			} else if (this.isYoutuDotBe) {
				return this.url.substring(this.url.lastIndexOf('.be/') + 4);
			} else if (this.isYoutube) {
				return this.url.substring(this.url.lastIndexOf('?v=') + 3);
			}

			return null;
		},
	},
	async mounted() {
		if (this.isYoutube) {
			const isYoutube = await import('vue-youtube-embed');
			Vue.use(isYoutube.default);
			this.youtubePlayerComponent = isYoutube.YouTubePlayer;
		}
	},
	methods: {
		handleVideoPlaying() {
			if (!this.videoPlayed) {
				this.videoPlayed = true;
			}
		},
		videoReady() {
			this.$emit('videoReady');
		},
	},
};
</script>

<style>
.video-wrapper {
	margin-top: 10px;
}
</style>
