import { imageWidthComparison, isNotImage } from './imageUtils';

const imageWidthIsExceedingLimit = (maxImageWidth, file) =>
	imageWidthComparison(maxImageWidth, file, (width, limit) => width > limit);

/**
 * A 'max_image_width' filter for Plupload.
 *
 * How to use:
 * https://www.plupload.com/docs/v2/File-Filters
 */
export default async function (maxImageWidth, file, cb) {
	if (isNotImage(file) || !(await imageWidthIsExceedingLimit(maxImageWidth, file))) {
		// eslint-disable-next-line standard/no-callback-literal
		cb(true);
		return;
	}

	this.trigger('Error', {
		code: -606,
		file: file,
	});

	// eslint-disable-next-line standard/no-callback-literal
	cb(false);
}
