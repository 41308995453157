<template>
  <div class="preview">
    <div class="icon">
      <i :class="'af-icons-md af-icons-md-circle-' + icon"></i><br />
      <span class="attachment-type">
        {{ extension.toUpperCase() }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    extension: {
      type: String,
      required: true
    }
  }
};
</script>
