import { mountVueComponent } from '@/lib/mount-vue-component';
import {getGlobal} from "@/lib/utils";

export default function() {
  import(`./Theme/Theme.${getGlobal('app')}.vue`).then(Theme => {
    mountVueComponent('theme', Theme);
  });

  import('@/lib/components/Layout/BrandHeader').then(BrandHeader => {
    mountVueComponent('brand-header', BrandHeader);
  });
}
