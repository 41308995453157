<template>
	<div class="btn btn-primary btn-lg btn-scoring-select">
		<select
			v-model="localScore"
			class="form-control score-select abstain-disable"
			:class="lockScores ? 'locked-score' : ''"
			@change="$emit('update:score', $event.target.value)"
		>
			<option v-for="option in options" :key="option" :value="option" :selected="localScore && localScore === option">
				{{ option }}
			</option>
		</select>
		<span class="maximum">/ {{ maxScore }}</span>
	</div>
</template>

<script>
import ScoringControl from './ScoringControl';

export default {
	extends: ScoringControl,
	props: {
		increment: {
			type: [Number, String],
			default: 0,
		},
	},
	data() {
		return {
			localScore: this.score,
		};
	},
	computed: {
		options() {
			let b = parseFloat(this.minScore);
			let a = [b];
			while (b < this.maxScore) {
				b += parseFloat(this.increment) > 0 ? parseFloat(this.increment) : 1;
				b = parseFloat(b.toFixed(4));
				a.push(b <= this.maxScore ? b : this.maxScore);
			}

			return a;
		},
	},
};
</script>
