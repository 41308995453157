import { GettingEndpoint } from '@/domain/services/Api/Endpoint';
import { RoutesGetter, useRoutesDao } from '@/domain/dao/Routes';

const useApiUnreadCount = () => {
	const routes = useRoutesDao<{
		updates: {
			unread: {
				count: () => void;
			};
		};
	}>();

	const getUnreadCount = GettingEndpoint<{
		unread: number;
	}>(routes?.updates?.unread?.count as RoutesGetter);

	return { getUnreadCount };
};

export { useApiUnreadCount };
