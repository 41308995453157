<template>
	<div class="marker-action">
		<list-action-form ref="form" :ids="ids" :labels="labels" :route="route" :method="method" @submitted="submit">
			<input type="hidden" name="moderation_status" :value="status" />
		</list-action-form>
	</div>
</template>

<script>
import ListAction from './ListAction.vue';
const $ = require('jquery');

/**
 * The Moderator component.
 */
export default {
	extends: ListAction,
	props: {
		status: {
			type: String,
			required: true,
		},
	},
	methods: {
		submit() {
			$(this.$refs.form.$el).submit();
		},
	},
};
</script>
