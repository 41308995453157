/**
 * Functions required for the multiselect form element.
 */
var $ = require('jquery');
var underscore = require('underscore');
var content = $('.content');

/**
 * Toggles the subset checkbox/radio on and off, based on the primary checkbox being checked.
 */
var toggleCheckboxes = function () {
	$(this)
		.closest('div')
		.siblings('.multiselect-subset-control')
		.find('input')
		.toggleClass('hidden', !$(this).prop('checked'));

	$(this).closest('.multiselect').find('.multiselect-selectall input[type=checkbox]').prop('checked', false);
};

content.on('change', '.multiselect-option input[type=checkbox]', toggleCheckboxes);
$('.content .multiselect-option input[type=checkbox]').each(toggleCheckboxes);

$(document).on('pjax:end', function () {
	$('.content .multiselect-option input[type=checkbox]').each(toggleCheckboxes);
});

/**
 * Select all primary checkboxes.
 */
content.on('change', '.multiselect-selectall input[type=checkbox]', function () {
	// Save state, as we need to reset after the change event from -option.
	var checked = $(this).prop('checked');

	$(this)
		.closest('.multiselect')
		.find('.multiselect-option input[type=checkbox]:visible:not(:disabled)')
		.prop('checked', checked)
		.change();

	$(this).prop('checked', checked);
});

/**
 * Filter options based on the provided search term.
 */
content.on('keyup', '.multiselect-filter input', function () {
	var parent = $(this).closest('.multiselect');

	// Hide all options
	parent.find('.multiselect-option').toggleClass('hidden', true).toggleClass('multiselect-row-alt', false);

	// Display contains
	parent.find(".multiselect-option:contains('" + $(this).val() + "')").toggleClass('hidden', false);
});

/**
 * Update selected items counter, and show if hidden.
 */
var updateCounter = function (multiselect) {
	multiselect
		.find('.multiselect-counter')
		.text(multiselect.find('.primary-option:checked').length)
		.removeClass('hidden');
};

/**
 * Update counter on load, and add change event listener
 */
$(document).on('ready pjax:success', function () {
	$('.multiselect')
		.has('.multiselect-counter')
		.each(function () {
			updateCounter($(this));
		});

	content.on('change', '.multiselect:has(.multiselect-counter) .primary-option', function () {
		updateCounter($(this).closest('.multiselect'));
	});
});

/**
 * Randomizer
 */
content.on('click', '.randomizer-toggle a', function (event) {
	event.preventDefault();
	var selector = $(this).closest('.multiselect').find('.randomizer-selector');
	selector.toggleClass('hidden');
	selector.find('.form-control').focus();
});

content.on('click', '.randomizer-selector a', function (event) {
	event.preventDefault();

	var multiselect = $(this).closest('.multiselect');
	multiselect.find('.randomizer-selector').addClass('hidden');
	multiselect.find('.primary-option').prop('checked', false);

	var value = multiselect.find('.randomizer-selector input').val();
	var options = underscore.shuffle(multiselect.find('.primary-option.unassigned')).splice(0, parseInt(value));
	$(options).prop('checked', true);
	updateCounter(multiselect);
});
