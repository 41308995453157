import Vue from 'vue';
import ResourceList from '@/lib/components/ResourceList';
import store from '@/lib/store';

export default function() {
  const auditLogList = new Vue({
    el: '#audit-log-list',
    name: 'AuditLogListApp',
    components: {
      'audit-log-list': ResourceList
    },
    store
  });

  $(document).one('pjax:end', () => {
    auditLogList.$destroy();
  });
}
