var $ = require('jquery');
// eslint-disable-next-line @typescript-eslint/naming-convention
var Uploader = require('uploader');

module.exports = function () {
	if (typeof standaloneOptions !== 'undefined') {
		// eslint-disable-next-line no-undef
		$.each(standaloneOptions, function (i, uploader) {
			var element = $('#' + uploader.id);

			if (element.length > 0) {
				new Uploader(uploader.options.clarify(), element);
			}
		});
	}
};
