import { mountVueComponent } from '@/lib/mount-vue-component';

export default function () {
  import('./components/ScoreSetConfiguration').then(ScoreSetConfiguration => {
    mountVueComponent('score-set-configuration', ScoreSetConfiguration);
  });

  // helpIcon
  import('@/lib/components/Shared/HelpIcon.vue').then(HelpIcon => {
    mountVueComponent('help-icon', HelpIcon);
  });
}
