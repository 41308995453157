<script>
import SearchPanel from './SearchPanel.vue';
import { Multiselect, SelectField } from 'vue-bootstrap';
import AssignmentsEntry from './AssignmentsEntry.vue';
import AssignmentsJudge from './AssignmentsJudge.vue';
import AssignmentsModal from './AssignmentsModal.vue';
import RandomAssignmentsSummary from './RandomAssignmentsSummary.vue';
import $ from 'jquery';

export default {
	components: {
		Multiselect,
		SelectField,
		SearchPanel,
		AssignmentsEntry,
		AssignmentsJudge,
		AssignmentsModal,
		RandomAssignmentsSummary,
	},
	data() {
		return {
			scoreSet: null,
			round: null,
			howMany: null,
			contextMode: null,
			entries: [],
			judges: [],
			entryFilters: {},
			judgeFilters: {},
			selectedEntries: [],
			selectedJudges: [],
			error: false,
			judgeAssignments: null,
		};
	},
	computed: {
		submitButtonDisabled() {
			return this.selectedJudges.length === 0 || this.selectedEntries.length === 0;
		},
	},
	methods: {
		validate() {
			return this.scoreSet && this.round && this.selectedEntries.length && this.selectedJudges.length;
		},
		onSubmit(event) {
			if (!this.validate()) {
				event.preventDefault();
				event.stopPropagation();

				this.error = false;

				setTimeout(() => {
					this.error = true;
				}, 100);
			}
		},
		selectScoreSet(name, value) {
			this.scoreSet = value;
		},
		selectRound(name, value) {
			this.round = value;
		},
		selectHowMany(event) {
			this.howMany = event.target.value;
		},
		selectContextMode(name, value) {
			this.contextMode = value;
		},
		selectJudgeFilter(name, value) {
			this.$set(this.judgeFilters, name, value);
		},
		selectEntryFilter(name, value) {
			this.$set(this.entryFilters, name, value);
		},
		judgeSearchResults(data) {
			this.judges = data;
		},
		entrySearchResults(data) {
			this.entries = data;
		},
		resetJudges() {
			this.judges = [];
			this.judgeFilters = {};
		},
		resetEntries() {
			this.entries = [];
			this.entryFilters = {};
		},
		selectEntries(selected) {
			this.selectedEntries = selected;
		},
		selectJudges(selected) {
			this.selectedJudges = selected;
		},
		showAssignmentsForJudge(judge) {
			$('body').trigger('pjax:send');
			$.ajax({
				url: '/assignment/list/' + judge,
				type: 'GET',
				dataType: 'json',
			})
				.success((data) => (this.judgeAssignments = data))
				.error(() => $('body').trigger('pjax:hide'));
		},
	},
};
</script>
