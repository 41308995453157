import documentTemplate from '@/lib/store/modules/document-template';
import DocumentTemplatesList from './components/DocumentTemplatesList';
import Vue from 'vue';
import store from '@/lib/store';

export default function () {
	const documentTemplatesList = new Vue({
		el: '#document-templates-list',
		name: 'DocumentTemplatesApp',
		components: {
			DocumentTemplatesList,
		},
		store,
	});

	store.registerModule('documentTemplate', documentTemplate);

	$(document).one('pjax:end', () => {
		documentTemplatesList.$destroy();
	});
}
