import $ from 'jquery';
import fields from '@/lib/components/Fields';
import FormSelector from '@/lib/components/Selectors/FormSelector.vue';
import InlineField from '@/lib/components/Fields/InlineField.vue';
import store from '@/lib/store';
import Validator from '@/lib/components/Fields/validator/Validator';
import Vue from 'vue';

export default function (root) {
	(root ? root.find('.vue-field') : $('.vue-field')).each(function () {
		$(this).removeClass('.vue-field');

		new Vue({
			el: this,
			name: 'VueField',
			components: { ...fields, InlineField, FormSelector, Validator },
			store,
		});
	});
}
