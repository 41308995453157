<template>
  <div class="transcoding-error">
    {{ lang.get('files.transcoding.error') }}
    <br /><br />
    <div class="actions">
      <a @click.prevent="$emit('toggled', !modalVisible)">
        {{ lang.get('files.transcoding.error_view') }}
      </a>
      |
      <a v-if="!retrying" @click.prevent="retry">
        {{ lang.get('files.transcoding.actions.retry') }}
      </a>
      <span v-else>
        {{ lang.get('files.transcoding.actions.retrying') }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['lang'],
  props: {
    file: {
      type: Object,
      required: true
    },
    modalVisible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      retrying: false
    };
  },
  methods: {
    retry() {
      if (this.retrying) {
        return;
      }

      this.retrying = true;
      this.$emit('retried', this.file);
    }
  }
};
</script>
