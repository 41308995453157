/**
 * The beforeChange event handler ensures that the merge fields ({account_name},
 * {entry_name}, etc.) are preserved when formatting content (particularly when
 * applying italic formatting to the current selection).
 *
 * https://codemirror.net/doc/manual.html#event_beforeChange
 */
module.exports = (instance, changeObj) => {
    const canUpdate = changeObj.hasOwnProperty('update');
    const selection = instance.getSelection();
    const regex = /(\{[a-zA-Z0-9\_\:]+\})/g;
    const sourceFields = selection.match(regex);

    if (!canUpdate || !sourceFields) {
        return;
    }

    let lines = changeObj.text.map(line => {
        const fields = line.match(regex) || [];
        const replacements = [];

        fields.forEach(field => {
            const match = sourceFields.filter(sourceField => {
                return sourceField.replace(/\_/g, '') === field.replace(/\_/g, '');
            });

            if (match.length) {
                replacements.push({ from: field, to: match[0] });
            }
        });

        replacements.forEach(replacement => {
            line = line.replace(replacement.from, replacement.to);
        });

        return line;
    });

    const asteriskCount = (lines.join('').match(/\*/g) || []).length;

    if (asteriskCount === 1) {
        lines = lines.map(line => line.replace('*', ''));
    }

    changeObj.update(changeObj.from, changeObj.to, lines);

    if (asteriskCount === 2) {
        const from = instance.getCursor('from');
        const to = instance.getCursor('to');

        const underscoreCount = sourceFields.reduce((count, sourceField) => {
            return count + (sourceField.match(/\_/g) || []).length;
        }, 0);

        setTimeout(() => {
            instance.setSelection(
                { line: from.line, ch: from.ch },
                { line: to.line, ch: to.ch + underscoreCount }
            );
        }, 100);
    }
};
