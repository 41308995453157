import Vue from 'vue';
import store from '@/lib/store';
import IntegrationList from './component/IntegrationList';

export default function() {
  const integrationList = new Vue({
    el: '#integration-list',
    name: 'IntegrationApp',
    components: {
      IntegrationList
    },
    store
  });
  $(document).one('pjax:end', () => {
    integrationList.$destroy();
  });
}
