var $ = require('jquery');

module.exports = function () {
	// Existing prices warning
	const warning = $('#currencies-warning');

	$('select[id="currency"]').on('change', function () {
		if (warning.length) {
			warning.removeClass('hidden');
		}
	});
};
