<template>
	<div>
		<label for="message">
			{{ lang.get('broadcasts.form.heading.message') }} {{ lang.get('miscellaneous.optional') }}
		</label>
		<text-editor
			id="message"
			name="message"
			:value="value"
			:markdown-guide-label="lang.get('miscellaneous.markdown_guide.label')"
		/>
	</div>
</template>

<script>
import TextEditor from '@/lib/components/Shared/editor/TextEditor.vue';

export default {
	inject: ['lang'],
	components: {
		TextEditor,
	},
	props: {
		value: {
			type: String,
			default: '',
		},
	},
};
</script>
