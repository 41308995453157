<template>
  <span>
    <a class="widget-form-link" v-if="url" :href="url">
      <h1 class="count">{{ count }}</h1>
      <span>{{ units }}</span>
    </a>
    <span v-else>
      <h1 class="count">{{ count }}</h1>
      <span>{{ units }}</span>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      default: 0
    },
    units: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: null
    }
  }
};
</script>

<style scoped>
span {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding-right: 20px;
}
h1 {
  margin: 0;
}
</style>
