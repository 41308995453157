import { mountVueComponent } from '@/lib/mount-vue-component';

export default function() {
  import('./components/FundsAllocationsList.vue').then(FundsAllocationsList => {
    mountVueComponent('funds-allocations-list', FundsAllocationsList);
  });

  import('../allocation-payment/components/AllocationPayments.vue').then(AlllocationPayments => {
    mountVueComponent('allocation-payments', AlllocationPayments);
  });
}
