var Vue = require('vue');
var InterfaceText = require('./components/InterfaceText.vue').default;
var store = require('../../lib/store/index.js').default;

module.exports = function() {
  new Vue({
    el: '#interface-text',
    name: 'InterfaceTextApp',
    components: {
      'interface-text': InterfaceText
    },
    store
  });
};
