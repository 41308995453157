<script>
import FeatureBox from './FeatureBox';

export default {
  extends: FeatureBox
};
</script>

<style scoped>
.col-box-content {
  padding-left: 30px;
}
</style>
