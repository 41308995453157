var $ = require('jquery');
var underscore = require('underscore');
var tectoastr = require('tectoastr');
var isMobile = require('../navigation-is-mobile.js');
// eslint-disable-next-line @typescript-eslint/naming-convention
var TooltipAttacher = require('../tooltip-attacher.js');
// eslint-disable-next-line @typescript-eslint/naming-convention
var Storage = require('../storage.js');
/**
 * This version of filtertron takes the full height of the browser window and is attached
 * to the right side of the viewport.
 *
 * Usage:
 *   $('.filtertron').filtertronTray();
 *
 */
$.fn.filtertronTray = function (options) {
	var filtertron = $(this);
	var tray = $('.tray');
	var body = $('body');
	var pathname = window.location.pathname;
	var adjustMaxHeightDebounced;

	var advancedSearchButton = filtertron.find('.advanced-search');
	var closeButton = tray.find('.tray-close');
	var saveViewButton = filtertron.find('.search-save');

	var table = filtertron.find('.saved-searches table');
	var formNew = filtertron.find('.new-save-view-form');
	var cancelSavingViewButton = formNew.find('.cancel');
	var forms = table.find('.form');
	var savedSearches = table.find('.saved-search');

	var tooltipAttacher = new TooltipAttacher();

	var defaultOptions = {
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		onOpen: function () {},
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		onClose: function () {},
	};

	options = $.extend(defaultOptions, options);

	var buttonsFixed = false;
	var redirecting = false;
	var filtertronStorage = new Storage();

	var filtertronStateKey = 'filtertron-' + filtertron.find('.tray-content').data('columnator');

	// When the content overflows the verical space available fix the buttons
	// at the bottom of the scrollable area.
	function adjustMaxHeight() {
		var overflow = tray.find('.tray-overflow');
		var buttons = tray.find('.tray-buttons');

		var maxHeight = tray.height() - 100;

		var overflowHeight = buttonsFixed ? overflow.prop('scrollHeight') : overflow.prop('scrollHeight') - 50;

		if (maxHeight > overflowHeight) {
			buttons.removeClass('fixed');
			buttonsFixed = false;

			overflow.css('maxHeight', 'none');
			overflow.css('overflowY', 'visible');
		} else {
			buttons.addClass('fixed');
			buttonsFixed = true;

			overflow.css('maxHeight', maxHeight);
			overflow.css('overflowY', 'auto');
		}
	}

	adjustMaxHeightDebounced = underscore.debounce(adjustMaxHeight, 100);

	// Open the tray
	function openTray() {
		body.addClass('has-tray');
		tray.removeClass('hidden');
		let holocronVideoBox = body.find('.feature-video-or-image-wrapper');
		let holocronContentBox = body.find('.col-box-content');
		if (holocronVideoBox) {
			holocronVideoBox.removeClass('col-lg-3').addClass('col-lg-4');
		}

		if (holocronContentBox) {
			if (holocronVideoBox) {
				holocronContentBox.removeClass('col-lg-8').addClass('col-lg-7');
			} else {
				holocronContentBox.removeClass('col-lg-11').addClass('col-lg-10');
			}
		}

		$('.advanced-search').addClass('active');

		adjustMaxHeight();

		$(window).resize(adjustMaxHeightDebounced);

		filtertronStorage.set(filtertronStateKey, 'open');
		setTimeout(options.onOpen, 300);
	}

	// Close the tray
	function closeTray() {
		body.removeClass('has-tray');
		tray.addClass('hidden');
		let holocronVideoBox = body.find('.feature-video-or-image-wrapper');
		let holocronContentBox = body.find('.col-box-content');
		if (holocronVideoBox) {
			holocronVideoBox.removeClass('col-lg-4').addClass('col-lg-3');
		}

		if (holocronContentBox) {
			if (holocronVideoBox) {
				holocronContentBox.removeClass('col-lg-7').addClass('col-lg-8');
			} else {
				holocronContentBox.removeClass('col-lg-10').addClass('col-lg-11');
			}
		}

		$('.advanced-search').removeClass('active').blur();

		$(window).off('resize', adjustMaxHeightDebounced);

		filtertronStorage.set(filtertronStateKey, 'closed');
		setTimeout(options.onClose, 300);
	}

	// Toggle tray visibility
	function toggleTray(e) {
		e.preventDefault();

		if (!body.hasClass('has-tray')) {
			openTray();
		} else {
			closeTray();
		}
	}

	// Save the search
	function saveView(e) {
		e.preventDefault();

		formNew.toggleClass('hidden');
	}

	// Cancel saving the search
	function cancelSavingView(e) {
		e.preventDefault();

		formNew.addClass('hidden');
	}

	// Reset the list of saved searches
	function resetList() {
		forms.addClass('hidden');
		savedSearches.removeClass('hidden');
	}

	// Handle failure
	var handleFail = function (error) {
		if (error.status === 422) {
			tectoastr.error(underscore.flatten(underscore.values(error.responseJSON)).join(' '));
		} else {
			tectoastr.error(error.responseJSON.message || $('#lang-strings #alerts-generic').text());
		}
	};

	// Cancel editing the search
	function handleCancel() {
		resetList();
	}

	// Saved the search
	function handleSave(e) {
		var nameInput = formNew.find('input[name="name"]');

		if (!nameInput.val()) {
			e.preventDefault();

			nameInput.focus();

			return false;
		}

		formNew.find('.save').attr('disabled', true);

		return true;
	}

	// Update the saved search
	function handleUpdate(e) {
		var form = $(this).closest('form');
		var nameInput = form.find('input[name="name"]');

		e.preventDefault();

		if (!nameInput.val()) {
			nameInput.focus();
			return;
		}

		$.ajax({
			type: 'POST',
			dataType: 'json',
			url: form.attr('action'),
			data: form.serialize(),
		})
			.done(function (response) {
				resetList();
				$('.saved-search-' + response.slug)
					.find('td:last')
					.html(response.view);

				// Reattach tooltips
				tooltipAttacher.attach('trigger-tooltip');
			})
			.fail(handleFail);
	}

	// Open the form
	function handleEdit() {
		var form = $(this).data('form');

		resetList();

		$(this).parents('.saved-search').toggleClass('hidden');

		$('.' + form).toggleClass('hidden');
	}

	// Delete the saved search
	function handleDelete() {
		$.ajax({
			method: 'POST',
			dataType: 'json',
			url: $(this).data('url'),
			data: {
				_method: 'DELETE',
			},
		})
			.done(function (response) {
				$('.saved-search-' + response.slug).remove();
				$('.form-' + response.slug).remove();

				if (table.find('td').length === 0) {
					table.parent().remove();
				}
			})
			.fail(handleFail);
	}

	// Handle redirects
	function handleRedirect(e) {
		if (redirecting) {
			e.preventDefault();
		}

		redirecting = true;
	}

	// Handle saved searches
	function handleSavedSearches() {
		forms.find('.cancel').on('click.filtertron', handleCancel);
		forms.find('.save').on('click.filtertron', handleUpdate);
		formNew.on('submit.filtertron', handleSave);
		table.find('.edit').on('click.filtertron', handleEdit);
		table.find('.delete').on('click.filtertron', handleDelete);
		table.find('.redirect a').on('click.filtertron', handleRedirect);
	}

	// Init tray
	function initTray() {
		var content = filtertron.find('.tray-content');
		var isOpen = filtertronStorage.get(filtertronStateKey) === 'open';
		if (content.html()) {
			// Update tray content
			tray.find('.tray-content').replaceWith(content);
			tray.find('.tray-content').removeClass('hidden');
		}

		// Are any of the search filters active? Open the tray
		// eslint-disable-next-line eqeqeq
		if (filtertron.data('searching') == true && !isMobile() && isOpen) {
			openTray();
		} else {
			destroyTray();
		}

		// Bind click handlers
		advancedSearchButton.on('click.filtertron', toggleTray);
		closeButton.on('click.filtertron', closeTray);
		saveViewButton.on('click.filtertron', saveView);
		cancelSavingViewButton.on('click.filtertron', cancelSavingView);

		handleSavedSearches();
	}

	// Destroy tray
	function destroyTray() {
		body.removeClass('has-tray');
		tray.addClass('hidden');

		$(window).off('resize', adjustMaxHeightDebounced);

		// Unbind click handlers
		closeButton.off('click.filtertron');
	}

	initTray();

	$(document).one('pjax:error', function () {
		destroyTray();
	});

	$(document).one('pjax:beforeReplace', function () {
		// eslint-disable-next-line eqeqeq
		if (window.location.pathname == pathname && !isMobile()) {
			return;
		}

		destroyTray();
	});
};
