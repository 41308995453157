import Lang from 'lang.js';
import { useGlobal } from '@/services/global/global.interface';

/**
 * The langMixin allows to consume Laravel translations on the front-end. This mixin
 * is designed to be imported in the root component. It uses the provide / inject
 * pattern (https://vuejs.org/v2/api/#provide-inject) to make translations available
 * in all child components.
 *
 * Once this mixin is imported in the root component you can add `inject: ['lang']`
 * to any child component. The Lang.js class (https://github.com/rmariuzzo/Lang.js)
 * allows to display Laravel translations using familiar methods:
 *
 * {{ lang.has('fruits.banana') ? lang.get('fruits.banana') : ':<' }}
 * {{ lang.choice('fruits.apple', 1) }}
 *
 * etc.
 */
export default {
	data() {
		return {
			lang: null,
		};
	},
	props: {
		language: {
			type: String,
			default: useGlobal('language')?.locale,
		},
		defaultLanguage: {
			type: String,
			default: useGlobal('language')?.fallback,
		},
		translations: {
			type: [Object, Array],
			default: () => useGlobal('translations'),
		},
	},
	provide() {
		this.lang = new Lang({
			locale: this.language,
			fallback: this.defaultLanguage,
			messages: this.translations,
		});

		return {
			lang: this.lang,
		};
	},
};
