var render = function render(){var _vm=this,_c=_vm._self._c;return _c('td',{class:[
    'cell',
    'cell-' + _vm.type,
    {
      'cell-editable': _vm.isEditable,
      'cell-disabled': !_vm.isEditable,
      'cell-last': _vm.isLast
    }
  ]},[(_vm.component)?[_c(_vm.component,{tag:"component",attrs:{"type":_vm.type,"input":_vm.value,"disabled":_vm.disabled,"label":_vm.key,"currency":_vm.selectedCurrency},on:{"update:input":function($event){_vm.value=$event}}})]:[_vm._v("\n    "+_vm._s(_vm.translatedCellName)+"\n  ")]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }