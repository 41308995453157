/**
 * This is a global variable, because we need it globally and it needs to be non-reactive.
 * Vuex state vars are always reactive and Object.freeze can mess up the editor instance.
 */
import { LastActiveEditor } from '@/lib/components/Shared/MergeFields/MergeFields.types';

let lastActiveEditor: LastActiveEditor | null = null;

const setLastActiveEditor = (editor: LastActiveEditor | null) => {
	lastActiveEditor = editor;
};

export { lastActiveEditor, setLastActiveEditor };
