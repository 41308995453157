import $ from 'jquery';
import { mountVueComponent } from '@/lib/mount-vue-component';
import Uploader from 'uploader';

export default function () {
	import('@/lib/components/Shared/MergeFields/MergeFieldsStandalone.vue').then((mergeFieldsStandalone) => {
		mountVueComponent('merge-fields-standalone', mergeFieldsStandalone);

		var awardTypeButtons = $('input:radio[name=type]');

		var awardTypeValue = function () {
			var awardType = $('input:radio[name=type]:checked');

			if (awardType) {
				return awardType.val();
			}
		};

		var certificateConfig = function () {
			if (awardTypeValue() === 'certificate') {
				$('.certificate-config').show();
				$('.certificate-config :input').prop('disabled', false);
			} else {
				$('.certificate-config').hide();
				$('.certificate-config :input').prop('disabled', true);
			}
		};

		var badgeConfig = function () {
			if (awardTypeValue() === 'badge') {
				$('.badge-config').show();
				$('.badge-config :input').prop('disabled', false);
			} else {
				$('.badge-config').hide();
				$('.badge-config :input').prop('disabled', true);
			}
		};

		awardTypeButtons.on('change', certificateConfig);
		awardTypeButtons.on('change', badgeConfig);

		certificateConfig();
		badgeConfig();

		// Score set selection
		var scoreSetSelection = function () {
			var value = $('input[name=scoreSetOption]:checked').val();

			if (value === 'select') {
				$('#scoreSetSelectionContainer').show();
			} else {
				$('#scoreSetSelectionContainer').hide();
			}
		};

		$('input[name=scoreSetOption]').on('change', scoreSetSelection);

		scoreSetSelection();

		// Badge type (Text/Image) selection
		var badgeTypeSelection = function () {
			var value = $('input[name=badgeType]:checked').val();

			if (value === 'text') {
				$('#badgeColourSelectionContainer').show();
				$('#badgeImageSelectionContainer').hide();
			} else {
				$('#badgeColourSelectionContainer').hide();
				$('#badgeImageSelectionContainer').show();
			}
		};

		$('input[name=badgeType]').on('change', badgeTypeSelection);

		badgeTypeSelection();

		// Colour picker
		var picker = $('#picker');
		var badgeColour = $('#badgeColour');

		var colourSelector = function (hsb, hex) {
			badgeColour.val(hex);
		};

		picker.colpick({
			flat: true,
			layout: 'hex',
			onSubmit: colourSelector,
		});

		picker.colpickSetColor(badgeColour.val());

		// Setup the Uploader, if included on the page.
		if ($('#uploader').length > 0) {
			/* global uploaderOptions */
			new Uploader(uploaderOptions.clarify());
		}

		// Updates the form action to submit to the preview route
		$('#award-preview').click(function (e) {
			e.preventDefault();

			var form = $(this).parents('form');
			var action = form.attr('action');

			form.attr('action', action + '/preview');
			form.submit();
		});

		// Opens the preview PDF in a separate tab, if requested
		/* global preview */
		if (preview.clarify()) {
			var form = $('#pjaxContainer').find('form');
			var action = form.attr('action') + '/preview';

			var newWindow = window.open(action, '_blank');
			newWindow.opener = null;
		}

		var scoreSetViewOption = $('input[id="scoreSetViewOption"]');
		var scoreSetsContainer = $('div[id="scoreSetsContainer"]');
		var scoreSetSelectionContainer = $('div[id="scoreSetSelectionContainer"]');

		var toggleScoreSetsContainer = function () {
			var checkbox = $(this);

			if (checkbox.is(':checked')) {
				scoreSetsContainer.removeClass('hidden');
				scoreSetSelectionContainer.removeClass('hidden');
			} else {
				scoreSetsContainer.addClass('hidden');
				scoreSetSelectionContainer.addClass('hidden');
			}
		};

		scoreSetViewOption.change(toggleScoreSetsContainer);
		toggleScoreSetsContainer.call(scoreSetViewOption);

		import('@/lib/components/Shared/HelpIcon.vue').then((helpIcon) => {
			mountVueComponent('help-icon', helpIcon);
		});
	});
}
