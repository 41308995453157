<template>
  <div class="marker-action">
    <list-action-form
      ref="form"
      :ids="ids"
      :labels="labels"
      :route="route"
      :method="method"
      @submitted="toggle"
    >
      <input type="hidden" :name="'options[' + options.scoreSets + ']'" :value="forceBoolean(scoreSetsSelected)" />
      <input type="hidden" :name="'options[' + options.scoringCriteria + ']'" :value="forceBoolean(criteriaSelected)" />
      <input type="hidden" :name="'options[' + options.rounds + ']'" :value="forceBoolean(roundsSelected)" />
      <portal :to="name">
        <modal
          v-model="showModal"
          :header="false"
          :confirm-button-label="labels.submit"
          :close-button-label="labels.cancel"
          :header-close-button="true"
          :confirm-on-enter="false"
          :confirm-disabled="false"
          @closed="handleClose"
          @confirmed="submit"
        >
          <close-icon slot="before-content" @click.prevent="toggle"></close-icon>
          <div :key="name">
            <h4>
              <b>{{ labels.title }}</b>
            </h4>
            <p>{{ labels.instructions }}</p>
            <div class="form-group">
              <checkbox :selected="true" :disabled="true">
                <template slot="label">{{ labels.settings }}</template>
              </checkbox>
            </div>
            <div class="form-group">
              <checkbox :selected="true" :disabled="true">
                <template slot="label">{{ labels.categories }}</template>
              </checkbox>
            </div>
            <div class="form-group">
              <checkbox :selected="true" :disabled="true">
                <template slot="label">{{ labels.tabs }}</template>
              </checkbox>
            </div>
            <div class="form-group">
              <checkbox :selected="true" :disabled="true">
                <template slot="label">{{ labels.fields }}</template>
              </checkbox>
            </div>
            <div class="form-group">
              <div class="checkbox styled">
                <input
                  :id="'scoreSetsSelected' + id"
                  v-model="scoreSetsSelected"
                  type="checkbox"
                  @change="toggleScoreSets"
                />
                <label :for="'scoreSetsSelected' + id">
                  {{ labels.scoreSets }}
                </label>
              </div>
            </div>
            <div class="form-group">
              <div class="checkbox styled">
                <input
                  :id="'criteriaSelected' + id"
                  v-model="criteriaSelected"
                  type="checkbox"
                  :disabled="criteriaDisabled"
                />
                <label :for="'criteriaSelected' + id">
                  {{ labels.scoringCriteria }}
                </label>
              </div>
            </div>
            <div class="form-group">
              <div class="checkbox styled">
                <input
                  :id="'roundsSelected' + id"
                  v-model="roundsSelected"
                  type="checkbox"
                />
                <label :for="'roundsSelected' + id">
                  {{ labels.rounds }}
                </label>
              </div>
            </div>
          </div>
        </modal>
      </portal>
    </list-action-form>
  </div>
</template>

<script>
import ListAction from '@/lib/components/ListActions/ListAction.vue';
import CloseIcon from '@/lib/components/ListActions/Partials/CloseIcon.vue';
import { Checkbox, Modal } from 'vue-bootstrap';
import modalMixin from '@/lib/components/ListActions/mixins/modal-mixin';

export default {
  components: {
    CloseIcon,
    Checkbox,
    Modal
  },
  inject: ['lang'],
  extends: ListAction,
  mixins: [modalMixin],
  props: {
    name: {
      type: String,
      required: true,
      validator: value => ['form-copier'].indexOf(value) !== -1
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      id: null,
      scoreSetsSelected: true,
      criteriaSelected: true,
      roundsSelected: true,
      isVisible: false
    };
  },
  computed: {
    criteriaDisabled() {
      return !this.scoreSetsSelected;
    }
  },
  mounted() {
    this.id = this._uid;
  },
  methods: {
    handleClose() {
      this.modalClosed();
    },
    toggleScoreSets() {
      if (!this.scoreSetsSelected) {
        this.criteriaSelected = false;
      }
    },
    forceBoolean(value) {
      return value === true ? 1 : 0;
    }
  }
};
</script>
