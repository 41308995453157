import Vue from 'vue';
import store from '@/lib/store';
import ChapterList from './component/ChapterList';

export default function() {
  const chapterList = new Vue({
    el: '#chapter-list',
    name: 'ChapterListApp',
    components: {
       ChapterList
    },
    store
  });

  $(document).one('pjax:end', () => {
    chapterList.$destroy();
  });
}
