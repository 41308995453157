/**
 * Container keeps registered dependencies
 * as singletons that are being instantiated
 * upon the first usage
 *
 * It is supposed to be boot in module file like so:

 bootContainer({
	dependency1Name: Dependency1FactoryFunction,
 	dependency2Name: Dependency2FactoryFunction,
 })

 * and then used anywhere in the module like so:

 const { dependency1Name, dependency2Name } = useContainer<{
	dependency1Name: Dependency1Type;
	dependency2Name: Dependency2Type
 }>();
 */
import { Defactorize } from '@/domain/utils/UtilityTypes';
import { vueHookFactories, VueHooks } from '@/domain/services/VueHooks';

type Dependency = unknown;
type DependencyFactory = () => Dependency;

// eslint-disable-next-line @typescript-eslint/naming-convention
let Container: Record<string, Dependency> | null = null;

const bootContainerWithoutDefaults = (dependencyFactories: Record<string, DependencyFactory> = {}) => {
	Container = new Proxy(
		{
			dependencyFactories,
			dependencyInstances: {} as Record<string, Dependency>,
		},
		{
			get: (target, name: string): Dependency => {
				if (target.dependencyInstances[name] === undefined) {
					target.dependencyInstances[name] = target.dependencyFactories[name]();
				}

				return target.dependencyInstances[name];
			},
			set: () => false,
		}
	);
};

const bootContainer = (dependencyFactories: Record<string, DependencyFactory> = {}) => {
	bootContainerWithoutDefaults({ ...vueHookFactories, ...dependencyFactories });
};

const useContainer = <T extends object>() => Container as T;

/**
 * boots generic container with vue hooks
 * if not rebooted the container will only contain
 * what is defined in resources/assets/js/src/domain/services/VueHooks.ts
 */
bootContainer();

const useServiceProvider = <T extends Record<string, DependencyFactory>>(dependencyFactories: T) => ({
	boot: () => bootContainer(dependencyFactories),
	use: () => useContainer<Defactorize<T> & VueHooks>(),
});

export { Container, useContainer, bootContainer, useServiceProvider };
