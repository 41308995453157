import { emit, off, on, EventCallback as SignalCallback } from '@/domain/services/LocalEventBus';

enum CommentUISignals {
	SAVE_COMMENT = 'comment.save',
	TYPING = 'comment.typing',
}

type CommentUIPayloads = {
	[CommentUISignals.SAVE_COMMENT]: void;
	[CommentUISignals.TYPING]: void;
};

const commentUIBus = {
	emit: <T extends CommentUISignals>(signal: T, payload: CommentUIPayloads[T] = undefined) => emit(signal, payload),

	on: <T extends CommentUISignals>(signal: T, callback: SignalCallback<CommentUIPayloads[T]>) => on(signal, callback),

	off: <T extends CommentUISignals>(signal: T, id?: number) => off(signal, id),
};

export { commentUIBus, CommentUISignals };
