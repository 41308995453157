import { isArray, isObject, isPrimitive, isString } from '@/domain/utils/TypePredicates';
import { NullablePrimitive, Primitive } from '@/domain/utils/UtilityTypes';

type DataParser = <T>(value: unknown) => T;

type DataValidator<T> = (value: T) => boolean;

const equals =
	<T = NullablePrimitive>(yesValues: T[], noValues?: T[]) =>
	(value: T) => {
		if (yesValues.includes(value)) {
			return true;
		}

		if (noValues === undefined || noValues.includes(value)) {
			return false;
		}

		throw new Error(`Invalid value: ${value} for [${yesValues}] or [${noValues}]`);
	};

const bool = () => (value: unknown) => {
	const check = equals<Primitive>([true, 1, '1', 'true'], [false, 0, '0', 'false', '']);

	if (isString(value)) {
		return check(value.trim());
	}

	if (isPrimitive(value)) {
		return check(value);
	}

	throw new Error(`Invalid boolean: ${value}`);
};

const enabled = () => (value: unknown) => {
	const check = equals([true, 1, '1', 'true', 'enabled'], [false, 0, '0', 'false', 'disabled', '']);

	if (isString(value)) {
		return check(value.trim());
	}

	if (isPrimitive(value)) {
		return check(value);
	}

	throw new Error(`Invalid toggling: ${value}`);
};

const convertToBytes = {
	T: (size: number) => size * 1024 * 1024 * 1024 * 1024,
	G: (size: number) => size * 1024 * 1024 * 1024,
	M: (size: number) => size * 1024 * 1024,
	K: (size: number) => size * 1024,
	'': (size: number) => size,
};

type FileSize = {
	raw: string;
	size: number;
	unit: string;
	bytes: number;
};

const fileSize = (validator?: DataValidator<FileSize>) => (value: unknown) => {
	if (!isString(value)) {
		throw new Error(`Invalid file size: ${value}`);
	}

	const matches = /\s*(\d+)\s*(M|K|G|T|)b?\s*/i.exec(value);
	if (isArray<string>(matches) && matches.length === 3) {
		const size = parseInt(matches[1], 10);
		const unit = matches[2].toUpperCase() as keyof typeof convertToBytes;
		const bytes = convertToBytes[unit](size);

		const result: FileSize = { raw: value.trim(), size, unit: `${unit}b`, bytes };

		if (validator === undefined || validator(result)) {
			return result;
		}
	}

	throw new Error(`Invalid file size format: ${value}`);
};

const array =
	<T = Primitive>(validator?: DataValidator<T[]>) =>
	(value: unknown) => {
		let values: T[] | undefined;

		if (isArray<T>(value)) {
			values = value;
		}

		if (isString(value)) {
			const matches = /^\s*\[?([^\]]*)\]?$/i.exec(value);
			if (!isArray<string>(matches) || matches.length !== 2) {
				throw new Error(`Invalid array format: ${value}`);
			}

			values = matches[1]
				.split(',')
				.map((s) => {
					const val = /^\s*(?:'([^']+)'|"([^"]+)"|([^'"\s]+))\s*$/gi.exec(s);
					return val?.[1] || val?.[2] || val?.[3];
				})
				.filter((e) => e !== undefined) as T[];
		}

		if (isArray<T>(values)) {
			if (validator === undefined || validator(values)) {
				return values;
			}
		}

		throw new Error(`Invalid array: ${value}`);
	};

const str = (validator?: DataValidator<string>) => (value: unknown) => {
	if (isString(value)) {
		if (validator === undefined || validator(value.trim())) {
			return value.trim();
		}
	}

	throw new Error(`Invalid string: ${value}`);
};

const obj =
	<T extends object>(validator?: DataValidator<T>) =>
	(value: unknown): T => {
		try {
			let data = value;
			if (isString(value)) {
				data = JSON.parse(value);
			}

			if (isObject<T>(data)) {
				if (validator === undefined || validator(data)) {
					return data;
				}
			}

			throw new Error(`Invalid object: ${value}`);
		} catch (e) {
			throw new Error(`Invalid object: ${value}`);
		}
	};

// eslint-disable-next-line @typescript-eslint/naming-convention
const Parse = {
	array,
	bool,
	enabled,
	fileSize,
	str,
	obj,
};

export { DataParser, Parse, equals };
