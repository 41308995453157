import { mountVueComponent } from '@/lib/mount-vue-component';
import Vue from 'vue';
import { Multiselect } from 'vue-bootstrap';

export default function() {
  new Vue({
    el: '#webhook-subscription-events-selector',
    name: 'WebhookSubscriptionEventsSelector',
    components: {
      Multiselect
    }
  });

  // TooltipIcon
  import('@/lib/components/Shared/TooltipIcon.vue').then(TooltipIcon => {
    mountVueComponent('tooltip-icon', TooltipIcon);
  });
}
