import { Api } from '@/legacy/OldApi';
import { startCase } from 'lodash';
import { VueDataProvider } from '@/domain/services/VueDataProvider';
import { VueModule } from '@/domain/services/VueModule';
import { ModuleLoader, routes } from '@/routes';
import Vue, { Component, defineComponent, SetupContext, SetupFunction } from 'vue';
import Vuex, { Store } from 'vuex';

/* eslint-disable @typescript-eslint/naming-convention */
declare const Route: string;
declare const window: { App: { [key: string]: unknown } };
/* eslint-enable @typescript-eslint/naming-convention */

let vueInstance: Vue | null = null;
let routeTable: Record<string, ModuleLoader> = {};
let vuePlugins: { readonly [name: string]: unknown } = {};
let childComponents: { readonly [name: string]: Component } = {};
const stubs: { [key: string]: unknown } = {};

let store: Store<unknown> | undefined;

const getCurrentRoute = (): ModuleLoader | undefined => routeTable[Route];

const boot = (vueContext = Vue) => {
	vueContext.use(Vuex);
	import('@/lib/store').then(({ default: legacyStore }) => {
		store = legacyStore;
	});
	routeTable = routes as unknown as Record<string, ModuleLoader>;
};

const registerVuePlugins = (plugins: { readonly [name: string]: unknown }) => {
	vuePlugins = plugins || {};
};

const registerComponents = (components: { readonly [name: string]: Component }) => {
	childComponents = components || {};
};

const loadModule = async () => {
	VueDataProvider.bootGlobalData(window.App);

	Api.setup();
	if (store !== undefined) {
		Api.addListener(store);
	}

	const routePromise = getCurrentRoute();

	if (vueInstance) {
		VueModule.destroy(vueInstance);
	}

	if (routePromise !== undefined) {
		const module = await routePromise();
		module.default();

		const name = module.name || Route.replace('.', '-');

		vueInstance = VueModule.create({
			...vuePlugins,
			el: '#vue-root',
			name: startCase(name),
			components: {
				[name]: defineComponent({ name, components: childComponents }),
			},
		});
	}
};

const mockView = <T>(key: string, stub: T) => {
	stubs[key] = stub;
};

const getStub = <T>(key: string): T | undefined => stubs[key] as T | undefined;

const makeSetup =
	<Props, View>(setup: SetupFunction<Props, View>, stubName?: string) =>
	(props: Props, ctx: SetupContext): View => {
		if (stubName !== undefined) {
			const stub = getStub<View>(stubName);
			if (stub !== undefined) return stub as View;
		}

		return setup(props, ctx) as View;
	};

// eslint-disable-next-line @typescript-eslint/naming-convention
const Composer = {
	boot,
	registerVuePlugins,
	registerComponents,
	loadModule,
	mockView,
	makeSetup,
};

const useController = Composer.makeSetup;

export { Composer, useController };
