export default {
  currentBreadcrumb: state => state.currentBreadcrumb,

  historyBreadcrumb: state => {
    const history = state.history;
    if (history && history.currentBreadcrumb) {
      return history.currentBreadcrumb;
    }
    return state.currentBreadcrumb;
  },

  lastModifiedField: state => state.lastModifiedField,
  flashMessage: state => state.flashMessage,
  detectedCountry: state => state.country
};
