<template>
	<div :class="['preview-container', { 'no-preview': previewType !== 'image', disabled }]">
		<image-preview
			v-if="previewType === 'image'"
			:file="file"
			:uploader="uploader"
			@imageDataUrl="$emit('imageDataUrl', $event)"
		/>
		<video-preview v-else-if="previewType === 'video'" :file="file" />
		<basic-preview v-else :icon="previewType" :extension="extension" />
	</div>
</template>

<script>
import BasicPreview from './Preview/FileUploadBasicPreview';
import ImagePreview from './Preview/FileUploadImagePreview';
import VideoPreview from './Preview/FileUploadVideoPreview';

export default {
	components: {
		BasicPreview,
		ImagePreview,
		VideoPreview,
	},
	props: {
		uploader: {
			type: Object,
			required: true,
		},
		file: {
			type: Object,
			required: true,
		},
		previewTypes: {
			type: Object,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		extension() {
			return this.file.original.split('.').pop().toLowerCase();
		},
		isImage() {
			return (
				(this.file.mime && this.file.mime.indexOf('image') >= 0) || (this.file.image && this.file.image.startsWith('blob:'))
			);
		},
		previewType() {
			switch (true) {
				case this.isImage:
					return 'image';
				case this.previewTypes.audio.includes(this.extension):
					return 'audio';
				case this.previewTypes.video.includes(this.extension) && !!this.file.transcodingStatus:
					return 'video';
				default:
					return 'document';
			}
		},
	},
};
</script>

<style scoped>
.preview-container.disabled {
	filter: grayscale(100%);
}
</style>
