<template>
  <div>
    <auto-scoring-toggle v-if="visible" :status="autoScoring" @change="toggleAutoScoring" id="toggle-autoscoring-checkbox"/>
    <div class="form-group" v-if="autoScoring">
      <checkbox-configurator
          :score="field.options[0] !== undefined ? field.options[0].score : null"
          @input="onInput"
      ></checkbox-configurator>
      <input type="hidden" name="options" v-model="options">
    </div>
  </div>
</template>
<script>
import langMixin from '../../../lib/components/Translations/mixins/lang-mixin.js';
import AutoScoringToggle from "../../../lib/components/Fields/AutoScoringToggle";
import CheckboxConfigurator from "../../../lib/components/Fields/CheckboxConfigurator";

export default {
  components: { AutoScoringToggle, CheckboxConfigurator },
  mixins: [langMixin],
  props: {
    field: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      autoScoring: !!this.field.autoScoring,
      options: (JSON.stringify(this.field.options[0] !== undefined ? {[this.field.options[0].id]: this.field.options[0].score} : [])),
      visible: this.field.resource === 'Entries'
    }
  },
  methods: {
    toggleAutoScoring(value) {
      this.autoScoring = !!value
    },
    onInput(value) {
      this.options = JSON.stringify({'1' : value});
    }
  }
};
</script>
