import ExportLayoutList from './components/ExportLayoutList';
import store from '@/lib/store';
import Vue from 'vue';

export default function () {
	const exportLayout = new Vue({
		el: '#export-layout-list',
		name: 'ExportLayoutListApp',
		components: {
			ExportLayoutList,
		},
		store,
	});

	$(document).one('pjax:end', () => {
		exportLayout.$destroy();
	});
}
