import Storage from '@/lib/storage.js';

export const Visibility = Object.freeze({
  OPEN: 'open',
  CLOSED: 'closed',
  INITIALLY: 'initially'
});

export default {
  data() {
    return {
      isOpen: false,
      storage: null
    };
  },
  computed: {
    chevronClass() {
      const direction = this.isOpen ? 'down' : 'up';
      return ['af-icons', `af-icons-chevron-${direction}`];
    }
  },
  created() {
    this.storage = new Storage();
  },
  methods: {
    toggle() {
      if (this.isOpen) {
        this.isOpen = false;
        this.storage.set(this.id, Visibility.CLOSED);
      } else {
        this.isOpen = true;
        this.storage.set(this.id, Visibility.OPEN);
      }
    },
    setInitialVisibility() {
      const visibility = this.storage.get(this.id);

      if (visibility) {
        this.isOpen = visibility === Visibility.OPEN;
        return true;
      }

      return false;
    }
  }
};
