var Vue = require('vue');
var Multiselect = require('vue-bootstrap').Multiselect;

module.exports = function() {
    new Vue({
        el: '#action-selector',
        name: 'ActionSelectorApp',
        components: {
            'multiselect': Multiselect
        }
    });
};
