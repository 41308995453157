<template>
  <div>
    <div class="form-card widget">
      <div class="widget-content">
        <div v-if="form.coverImageUrl" class="widget-image" :style="'width: ' + imageWidth + 'px'">
          <img ref="coverImage" :src="form.coverImageUrl" :alt="form.coverImageAlt" />
        </div>
        <div class="widget-info">
          <h2>{{ form.name }}</h2>
          <div class="widget-button align-bottom">
            <a :href="form.startEntryUrl" class="btn btn-lg btn-primary ignore">{{
              form.callToAction
            }}</a>
          </div>
        </div>
      </div>
    </div>
    <hr v-if="showFormsCategoriesSeparator" class="hr-solid" />
    <div v-for="(category, i) in categories" :key="category.slug">
      <category-card :category="category"></category-card>
      <hr v-if="i < categories.length - 1" class="hr-solid" />
    </div>
  </div>
</template>

<script>
import langMixin from '@/lib/components/Translations/mixins/lang-mixin';
import CategoryCard from '@/modules/home/components/CategoryCard.vue';

export default {
  components: {
    CategoryCard
  },
  mixins: [langMixin],
  props: {
    form: {
      type: Object,
      required: true
    },
    categories: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    showFormsCategoriesSeparator() {
      return this.categories.length > 0;
    },
    imageWidth() {
      return this.$refs?.coverImage?.height || 240;
    }
  }
};
</script>

<style scoped></style>
