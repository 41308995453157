<template>
	<div class="info-box box">
		<h2 class="title">
			{{ tour.heading }}
		</h2>
		<div class="body text-content box-content">
			<div v-html="tour.description"></div>
			<div class="tour">
				<div class="tour-link">
					<a :href="tour.link" target="_self" rel="noopener noreferrer">
						{{ tour.linkText }}
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	inject: ['lang'],
	props: {
		tour: {
			type: Object,
			required: true,
		},
	},
	computed: {
		id() {
			return 'tour-' + this.tour.id;
		},
	},
};
</script>

<style>
.tour .title {
	padding-bottom: 10px;
}

.tour-link {
	margin-top: 2px;
}
</style>
