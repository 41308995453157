<template>
	<div ref="tray" class="tray">
		<div ref="trayHeader" class="tray-header">
			<div class="tray-header-actions">
				<div class="pull-left filtertron-heading" tabindex="0">
					{{ heading }}
				</div>
				<div class="pull-right">
					<a
						class="tray-close"
						role="button"
						aria-label="Close"
						tabindex="0"
						@click.prevent="onCloseButtonClick"
						@keydown.esc.enter="onCloseButtonClick"
					>
						<i class="af-icons af-icons-cross"></i>
					</a>
				</div>
			</div>
		</div>
		<div class="tray-content">
			<div ref="trayOverflow" class="tray-overflow" :style="{ maxHeight: maxHeight, overflowY: overflowY }">
				<slot :scroll-to-top="scrollToTop" :buttons-fixed="buttonsFixed" :resize-tray="debouncedOnResize"></slot>
			</div>
		</div>
	</div>
</template>

<script>
import $ from 'jquery';
import _ from 'underscore';
import Vue from 'vue';
import Storage from '@/lib/storage.js';
import { markFocusJumpHasNotOccurred } from '@/lib/focus-flow-changer';
import filtertronFocusFlowChanges from '@/lib/components/Filtertron/mixins/filtertron-focus-flow-changes';

export default {
	mixins: [filtertronFocusFlowChanges],
	props: {
		isOpen: {
			type: Boolean,
			default: false,
		},
		heading: {
			type: String,
			default: '',
		},
		area: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			buttonsFixed: false,
			maxHeight: 'none',
			overflowY: 'visible',
			debouncedOnResize: null,
			hasFinishedMounting: false,
		};
	},
	watch: {
		isOpen() {
			if (this.isOpen === true) {
				document.body.classList.toggle('has-tray');

				Vue.nextTick(() => {
					this.onResize();
				});

				this.enableFocusFlowChanges();
				if (this.hasFinishedMounting) {
					this.forceFiltertronFocus();
				}
			} else {
				document.body.classList.remove('has-tray');

				this.disableFocusFlowChanges();
			}
		},
	},
	mounted() {
		$(document).one('pjax:error', this.destroy);
		$(document).one('pjax:start', this.destroy);

		this.debouncedOnResize = _.debounce(this.onResize, 100);

		$(window).on('resize', this.debouncedOnResize);

		setTimeout(() => markFocusJumpHasNotOccurred(), 50);
		setTimeout(() => (this.hasFinishedMounting = true), 200);
	},
	beforeDestroy() {
		$(window).off('resize', this.debouncedOnResize);
	},
	methods: {
		onCloseButtonClick() {
			this.$emit('beforeClose');
			this.close();

			// Focus Advanced button when advanced search tray is closed (aria good practice)
			const advancedSearchEl = document.querySelector('.advanced-search');
			if (advancedSearchEl) {
				advancedSearchEl.focus();
			}
		},
		close() {
			this.$emit('update:isOpen', false);

			const storage = new Storage();
			storage.set('filtertron-active-' + this.area, 'closed');
		},
		destroy() {
			document.body.classList.remove('has-tray');

			Vue.nextTick(() => {
				const oldTarget = document.getElementById('filtertron-tray');
				const newTarget = document.createElement('div');

				newTarget.setAttribute('id', 'filtertron-tray');

				document.body.replaceChild(newTarget, oldTarget);

				this.onResize();

				this.$emit('update:isOpen', false);
			});
		},
		onResize(offsetHeight) {
			offsetHeight = typeof offsetHeight === 'number' ? offsetHeight : null;

			if (!offsetHeight && !this.$refs.trayOverflow) {
				return;
			}

			const previousOverflowY = this.overflowY;
			this.overflowY = 'auto';

			Vue.nextTick(() => {
				const trayHeaderHeight = this.$refs.trayHeader.clientHeight ?? 50;
				const trayButtonsHeight = this.$el.querySelector('.tray-buttons')
					? this.$el.querySelector('.tray-buttons').offsetHeight
					: 50;

				const maxHeight = document.documentElement.clientHeight - trayHeaderHeight - trayButtonsHeight;
				const overflowHeight = offsetHeight ?? this.$refs.trayOverflow.scrollHeight;
				this.overflowY = previousOverflowY;

				if (maxHeight > overflowHeight) {
					this.buttonsFixed = false;
					this.maxHeight = 'none';
					this.overflowY = 'visible';
				} else {
					this.buttonsFixed = true;
					this.maxHeight = maxHeight + 'px';
					this.overflowY = 'auto';
				}
			});
		},
		scrollToTop() {
			setTimeout(() => (this.$refs.trayOverflow.scrollTop = 0), 100);
		},
	},
};
</script>
