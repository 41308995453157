<script>
import $ from 'jquery';
import tectoastr from 'tectoastr';
import TextEditor from '@/lib/components/Shared/editor/TextEditor.vue';
import { getGlobal } from '@/lib/utils';
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
	props: {
		initialHtml: {
			type: String,
			required: true,
		},
		initialRaw: {
			type: String,
			required: true,
		},
		order: {
			type: String,
			required: true,
		},
	},

	components: {
		TextEditor,
	},

	setup(props) {
		const editing = ref(false);
		const draft = ref(props.initialRaw);
		const editor = ref(null);
		const html = ref(props.initialHtml.toString().clarify());
		const raw = ref(props.initialRaw);

		const addActionVisible = computed(() => !html.value && !editing.value);
		const notesVisible = computed(() => html.value && !editing.value);

		const editNotes = () => {
			editing.value = true;
		};

		const saveNotes = () => {
			raw.value = draft.value;
			$('#loader').show().fadeTo(250, 0.8);
			$.ajax({
				type: 'PUT',
				url: '/order/' + props.order + '/notes',
				dataType: 'json',
				data: {
					notes: draft.value,
				},
				success: (response) => {
					editing.value = false;
					html.value = response.html;
				},
				error: (error) => {
					tectoastr.error(error.responseJSON.message || $('#lang-strings #alerts-generic').text());
				},
				complete: () => {
					$('#loader').fadeTo(250, 0, function () {
						$(this).hide();
					});
				},
			});
		};

		const cancelNote = () => {
			editing.value = false;
			draft.value = raw.value;
		};

		return {
			getGlobal,
			editing,
			draft,
			editor,
			html,
			raw,
			addActionVisible,
			notesVisible,
			editNotes,
			saveNotes,
			cancelNote,
		};
	},
});
</script>
