<template>
	<a :class="theme" target="_blank" :href="link" :rel="rel">
		<div class="status-container">
			<div :class="[{ 'status-indicator': indicator !== '' }, indicator]"></div>
			<div class="status-text">{{ statusText }}</div>
		</div>
	</a>
</template>

<script>
import langMixin from './Translations/mixins/lang-mixin.js';

export default {
	mixins: [langMixin],
	props: {
		theme: {
			type: String,
			default: 'basic',
		},
		link: {
			type: String,
			required: true,
		},
		rel: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			statusText: '',
			indicator: '',
		};
	},
	mounted() {
		this.$nextTick(() => {
			this.fetchStatus();
		});
	},
	methods: {
		fetchStatus() {
			this.$http
				.get(this.link + '/api/v2/status.json')
				.then(this.handleResponse)
				.catch((error) => {
					console.error(error);
				});
		},
		handleResponse(response) {
			this.indicator = response.data.status.indicator;
			this.statusText = this.lang.get('footer.status-widget.' + this.indicator);
		},
	},
};
</script>
