import $ from 'jquery';
import { mountVueComponent } from '@/lib/mount-vue-component';
import { Multiselect } from 'vue-bootstrap';
import Uploader from 'uploader';
import Vue from 'vue';

export default function () {
	// Setup the Uploader, if included on the page.
	if ($('#uploader').length > 0) {
		/* global uploaderOptions */
		var options = uploaderOptions.clarify();
		new Uploader(options);
	}

	// Bind Multi Upload 'Delete' button action.
	$('.images').on('click', '.upload-multi .upload-actions .delete', function () {
		var container = $(this).closest('.upload-multi');
		var fileId = container.find('input.upload-id').val();
		var deletedOrder = container.find('.sort-order').text();

		container.remove();

		$('.images .upload-multi').each(function () {
			var order = $(this).find('.sort-order').text();
			var newOrder = order - 1;
			if (order > deletedOrder) {
				$(this).find('.sort-order').text(newOrder);
			}
		});

		$.ajax({
			type: 'DELETE',
			/* global imageDeleteUrl */
			url: imageDeleteUrl.replace(0, fileId),
		});
	});

	const chapterFormInstance = new Vue({
		el: '#chapter-form',
		components: { Multiselect },
	});

	$(document).one('pjax:end', () => {
		chapterFormInstance.$destroy();
	});

	import('@/lib/components/Shared/HelpIcon.vue').then((helpIcon) => {
		mountVueComponent('help-icon', helpIcon);
	});

	import('@/lib/components/Translations/Multilingual.vue').then((multilingual) => {
		mountVueComponent('multilingual', multilingual);
	});
}
