import Vue from 'vue';
import Prices from './components/Prices';

export default function () {
  const pricesEl = document.getElementById('prices');

  if (pricesEl) {
    const prices = new Vue({
      el: pricesEl,
      name: 'PricesApp',
      components: {
        Prices
      }
    });

    $(document).one('pjax:end', () => {
      prices.$destroy();
    });
  }
};
