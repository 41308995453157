import Vue from 'vue';

export default {
  changeCollapsedState(context, { collapsed, url }) {
    context.commit('setIntroState', { collapsed: collapsed });

    Vue.prototype.$http.post(url, {
      visible: !collapsed
    });
  }
};
