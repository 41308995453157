<template>
	<modal v-model="modalVisible" :title="$attrs.title" :footer="false" :header-close-button="false" @closed="onClose">
		<close-icon slot="before-content" @click.prevent="onClose"></close-icon>
		<quick-comment v-for="comment in comments" :key="comment.slug" :comment="comment" />
	</modal>
</template>

<script>
import { Modal } from 'vue-bootstrap';
import CloseIcon from '@/lib/components/ListActions/Partials/CloseIcon';
import QuickComment from '@/lib/components/Comments/QuickComment';

/**
 * The CommentModal component.
 */
export default {
	components: {
		CloseIcon,
		Modal,
		QuickComment,
	},
	inheritAttrs: false,
	model: {
		prop: 'visibility',
		event: 'change',
	},
	props: {
		visibility: {
			type: Boolean,
			default: false,
		},
		comments: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			modalVisible: this.visibility,
		};
	},
	watch: {
		visibility() {
			this.modalVisible = this.visibility;
		},
	},
	methods: {
		onClose() {
			this.modalVisible = false;
			this.$emit('change', this.modalVisible);
		},
	},
};
</script>
