<template>
  <select-field
    :id="getId()"
    class="countries"
    :value="value"
    :items="countries"
    :empty-option="false"
    :disabled="disabled"
    :aria-required="field.required ? 'true' : 'false'"
    :aria-invalid="hasError"
    :aria-describedby="ariaDescribedby"
    @selected="valueChanged"
  ></select-field>
</template>

<script>
import Field from './Field.vue';
import { mapState } from 'vuex';
import { SelectField } from 'vue-bootstrap';

export default {
  components: { SelectField },
  extends: Field,
  computed: {
    ...mapState('global', ['countries'])
  },
  methods: {
    valueChanged(name, newValue) {
      this.onInput(newValue);
    }
  }
};
</script>
