var $ = require('jquery');
var autosize = require('../lib/autosize.js');
var boxer = require('../lib/boxer.js');
var ping = require('./ping.js').default;
var selectSelector = 'select[enhance]';
var counter = require('../lib/counter');

var select2Handler = function () {
	var select2Options = {
		allowClear: true,
		minimumResultsForSearch: -1,
	};

	var placeholder = $(this).attr('placeholder');

	if (typeof placeholder !== typeof undefined && placeholder !== false) {
		select2Options.placeholder = placeholder;
	}

	$(this).select2(select2Options);
};

var autosizer = function () {
	autosize($('textarea:not(.ignore)'));
};

// Whenever the loading is complete, we then want to hide the loading animation
$(document).on('pjax:end', function () {
	// bind new selects
	$(selectSelector).each(select2Handler);

	// Content can change the required heights, so let's make sure it updates
	autosizer();
});

// bind to any select elements that wish to be enhanced on page load
$(selectSelector).each(select2Handler);

// This is our table sorting functionality
$('#content').on('click', 'a.sortable', function () {
	var sorter = $(this);

	if (sorter.hasClass('sort-asc')) {
		sorter.addClass('sort-desc');
		sorter.removeClass('sort-asc');
	} else {
		sorter.removeClass('sort-desc');
		sorter.addClass('sort-asc');
	}
});

$('#content').on('click', 'a.retry-transcoding', function (event) {
	event.preventDefault();
	$.post($(this).attr('href'));
	$(this).replaceWith('<span>Retrying...</span>');
});

$(document).on('tab.activated', function (event, id) {
	setTimeout(function () {
		var textareas = $('#' + id + 'Content').find('textarea');

		textareas.each(function (index, ta) {
			autosize.update(ta);
		});
	}, 25);
});

$('.footer-bar .terms').on('click', function (e) {
	if (e.originalEvent.detail === 3) {
		$(this).fadeOut(function () {
			$(this).html('Force mode activated!').fadeIn();
			$('#menu-main').append(
				// eslint-disable-next-line max-len
				'<li><a href="/maintenance-force-test"><span class="af-icons-md af-icons-md-circle-fail"></span><span class="text">Do not click!</span></a></li>'
			);
		});
	}
});

// Update the indicator
var updateCountIndicator = function (count, input, selector, data) {
	var indicator = input
		.parent()
		.siblings()
		.first()
		.find(selector + ' .used');
	indicator.text(count);
	indicator.parent().toggleClass('error', count > input.data(data));
};

// Bind update function to keyup events
$('#content').on('keyup change', '[data-max-words]', function () {
	var count = counter.words($(this).val());
	updateCountIndicator(count, $(this), '.word-count', 'max-words');
});

$('#content').on('keyup change', '[data-max-characters]', function () {
	var count = counter.characters($(this).val());
	updateCountIndicator(count, $(this), '.character-count', 'max-characters');
});

// Refresh counters on page load
$(document).on('pjax:end', function () {
	$('[data-max-words]').each(function () {
		var count = counter.words($(this).val());
		updateCountIndicator(count, $(this), '.word-count', 'max-words');
	});

	$('[data-max-characters]').each(function () {
		var count = counter.characters($(this).val());
		updateCountIndicator(count, $(this), '.character-count', 'max-characters');
	});
});

// Tooltip code
/*
$.fn.tooltipster('setDefaults', {
  delay: 0,
  maxWidth: 400,
  position: 'right',
  speed: 250,
  trigger: 'click',
  timer: 5000
});
*/

// Prevent typing in fields with the .no-type class
// Especially handy for date, datetime and time fields
$('input.no-type').keydown(function (e) {
	e.preventDefault();
	return false;
});

autosizer();
boxer.init();

window.setInterval(ping, 1000 * 60 * 2); // Every 2 minutes

document.addEventListener('visibilitychange', function () {
	// Every time the content of the tab becomes visible
	if (document.visibilityState === 'visible') {
		ping();
	}
});

// Remove target attribute and add ignore class to internal links in the homepage
$('#home-container a')
	.filter(function () {
		return this.hostname === window.location.hostname;
	})
	.removeAttr('target')
	.addClass('ignore');

// Prepend default protocol to URL type fields
$(document).on('focusout', 'input[data-filter="url"]', function () {
	var value = $(this).val();

	if (value && !value.match('^https?://')) {
		$(this).val('https://' + value);
	}
});

// Lock button on form submit
var lockButton = function (i, button) {
	button = $(button);

	button.closest('form').on('submit', function () {
		button.attr('disabled', true);
	});
};

$('.lock-on-submit').each(lockButton);

$(document).on('pjax:end', function () {
	$('.lock-on-submit').each(lockButton);
});

// Registration accessibility focus on validation error
const $homeValidationError = $('#home-container .alert-error');

if ($homeValidationError.length) {
	$homeValidationError.focus();
}

// close profile menu when tabbing away
$('#menu-user > li:last-child > a').keydown(function (e) {
	if (e.which === 9 && e.shiftKey) {
		// do nothing
	} else if (e.which === 9) {
		$('#menu-user').hide();
	}
});
