<template>
	<div :class="boxClass">
		<h2 class="title">
			<div
				tabindex="0"
				role="button"
				:aria-pressed="ariaPressed"
				:aria-expanded="isCollapsed ? 'false' : 'true'"
				@click.prevent="toggleVisibility"
				@keyup.enter="toggleVisibility"
				@mousedown="ariaPressed = 'true'"
				@mouseup="ariaPressed = 'false'"
				@mouseover="hover = true"
				@mouseleave="hover = false"
				@focus="hover = true"
			>
				{{ title }}
				<transition name="slide-fade">
					<button
						v-if="link"
						v-show="hover"
						class="copy-link"
						:title="copyContent"
						:aria-label="copyContent"
						@click.stop="copyLink"
						@keyup.space="copyLink"
						@focus="hover = true"
						@blur="hover = false"
					>
						<transition name="fade">
							<i v-if="copied" class="af-icons af-icons-tick"></i>
							<i v-else class="af-icons af-icons-link"></i>
						</transition>
					</button>
				</transition>
				<i aria-hidden="true" :class="chevronClass"></i>
			</div>
		</h2>
		<div v-if="!isCollapsed" class="body text-content box-content">
			<div v-html="content"></div>
			<slot></slot>
		</div>
	</div>
</template>

<script>
import copyToClipboard from '@/lib/components/Shared/mixins/copy-to-clipboard-mixin.js';

export default {
	inject: ['lang'],
	mixins: [copyToClipboard],
	props: {
		title: {
			type: String,
			required: true,
		},
		content: {
			type: String,
			default: null,
		},
		collapsed: {
			type: Boolean,
			default: false,
		},
		link: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			isCollapsed: false,
			ariaPressed: 'false',
			copied: false,
			hover: false,
		};
	},
	computed: {
		boxClass() {
			return ['info-box', 'box'];
		},
		chevronClass() {
			return null;
		},
		copyContent() {
			if (this.copied) {
				const text = this.lang.get('audit.action.copied');
				return text[0].toUpperCase() + text.slice(1).toLowerCase() + '!';
			}

			return this.lang.get('shared.copy-link');
		},
	},
	watch: {
		copied() {
			setTimeout(() => (this.copied = false), 1000);
		},
	},
	methods: {
		copyLink() {
			this.copied = true;
			this.copyToClipboard(this.link);
		},
	},
};
</script>
