<script>
import LinkSelector from '../Shared/LinkSelector.vue';

/**
 * The StateSelector component.
 */
export default {
  extends: LinkSelector
};
</script>
