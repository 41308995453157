
import DraggableColumns from './DraggableColumns.vue';
import { defineComponent } from 'vue';
import { useController } from '@/domain/services/Composer';
import { newCustomExportLayoutController, View } from '@/modules/exports/components/NewCustomExportLayout.controller';

export default defineComponent({
	components: {
		DraggableColumns,
	},
	props: {
		area: { type: String, required: true },
		columns: { type: Array, default: () => [] },
	},
	setup: useController(newCustomExportLayoutController, 'NewCustomExportLayoutController') as () => View,
});
