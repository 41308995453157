import Vue from 'vue';
import store from '@/lib/store';
import UserList from './components/UserList';

export default function() {
  const userList = new Vue({
    el: '#user-list',
    name: 'UserListApp',
    components: {
      UserList
    },
    store
  });

  $(document).one('pjax:end', () => {
    userList.$destroy();
  });
}
