<template>
  <dropdown
    :id="'score-set-overflow-menu-' + scoreSet.id"
    button-element="button"
    button-class="dropdown-toggle"
    container-class="action-overflow"
  >
    <span slot="label">
      <span class="sr-only">{{ scoreSet.name }}</span>
      <icon name="action-overflow" />
    </span>
    <li>
      <a v-if="permissions.canUpdateScoreSets" :href="editScoreSetUrl(scoreSet.slug)">
        {{ lang.get('judging.dashboard.menu.edit_score_set') }}
      </a>
    </li>
    <li>
      <a v-if="scoreSet.mode !== 'gallery' && permissions.canViewLeaderboard" :href="leaderboardUrl(scoreSet.id)">
        {{ lang.get('judging.dashboard.menu.leaderboard') }}
      </a>
    </li>
    <li>
      <a :href="panelUrl(scoreSet.id)">
        {{ lang.get('judging.dashboard.menu.panels') }}
      </a>
    </li>
    <li>
      <a v-if="scoreSet.mode !== 'gallery' && permissions.canViewProgress" :href="progressUrl(scoreSet.id)">
        {{ lang.get('judging.dashboard.menu.progress') }}
      </a>
    </li>
    <li>
      <a
        v-if="scoreSet.mode === 'vip_judging' && permissions.canViewScoringCriteria"
        :href="scoringCriteriaUrl(scoreSet.id)"
      >
        {{ lang.get('judging.dashboard.menu.scoring_criteria') }}
      </a>
    </li>
    <li>
      <a v-if="permissions.canViewAssignments" :href="assignmentsUrl(scoreSet.id)">
        {{ lang.get('judging.dashboard.menu.assignments') }}
      </a>
    </li>
  </dropdown>
</template>

<script>
import { route } from '@/lib/utils.js';
import { Dropdown } from 'vue-bootstrap';
import Icon from '@/lib/components/Shared/Icon';

export default {
  inject: ['lang'],
  components: {
    Dropdown,
    Icon
  },
  props: {
    scoreSet: {
      type: Object,
      required: true
    },
    routes: {
      type: Object,
      required: true
    },
    permissions: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    editScoreSetUrl(slug) {
      return route(this.routes['score-set.edit'], { scoreSet: slug });
    },
    leaderboardUrl(id) {
      return route(this.routes['leaderboard.index']) + '?score-set=' + id;
    },
    panelUrl(id) {
      return route(this.routes['panel.index']) + '?score-set=' + id;
    },
    progressUrl(id) {
      return route(this.routes['leaderboard.progress']) + '?score-set=' + id;
    },
    scoringCriteriaUrl(id) {
      return route(this.routes['scoring-criteria.index']) + '?score-set=' + id;
    },
    assignmentsUrl(id) {
      return route(this.routes['assignment.index']) + '?score-set=' + id;
    }
  }
};
</script>
