<template>
  <div class="quick-tagger">
    <tag-input
      v-model="currentTags"
      :src="$attrs['src']"
      :placeholder="labels['placeholder']"
    >
    </tag-input>
  </div>
</template>

<script>
import { TagInput } from 'vue-bootstrap';

const toastr = require('toastr');

/**
 * The QuickTagger component.
 */
export default {
  components: {
    TagInput
  },
  props: {
    tags: {
      type: [Array, String],
      default: () => []
    },
    url: {
      type: String,
      required: true
    },
    labels: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      currentTags: this.tags
    };
  },
  watch: {
    currentTags() {
      this.$http
        .post(this.url, {
          tags: this.currentTags
        })
        .then(
          () => {},
          error => {
            toastr.error(
              error.response.data.message ||
                document.getElementById('alerts-generic').innerHTML
            );
          }
        );
    }
  }
};
</script>
