var $ = require('jquery');
/* eslint-disable @typescript-eslint/naming-convention */
var _ = require('underscore');
var OpenRounds = require('../../lib/open-rounds.js');
var store = require('../../lib/store/index.js').default;
var isMobile = require('../../lib/navigation-is-mobile.js');

const Vue = require('vue');
const ToggleSwitch = require('../../lib/components/Shared/ToggleSwitch.vue').default;
const ProgressBar = require('./PicksProgressBar.vue').default;
const Preferences = require('./Preferences/Preferences.vue').default;
const ScoreSetSelector = require('@/lib/components/Selectors/ScoreSetSelector.vue').default;

module.exports = function () {
	store.commit('topPick/resetState');

	$(document).one('pjax:start', () => {
		store.commit('topPick/resetState');
	});

	if ($('#toggle-my-picks-only').length) {
		new Vue({
			el: '#toggle-my-picks-only',
			name: 'TopPickToggleApp',
			components: {
				'toggle-switch': ToggleSwitch,
			},
		});
	}

	if ($('#picks-progress-bar').length) {
		new Vue({
			el: '#picks-progress-bar',
			name: 'TopPickProgressBarApp',
			components: {
				'picks-progress-bar': ProgressBar,
			},
			store,
		});
	}

	new Vue({
		el: '#scoreSetSelector',
		components: {
			'score-set-selector': ScoreSetSelector,
		},
		store,
	});

	const rows = document.querySelectorAll('.preferences');

	[].forEach.call(rows, (el) => {
		new Vue({
			el: el,
			name: 'TopPickPreferencesApp',
			components: {
				preferences: Preferences,
			},
			store,
		});
	});

	new OpenRounds();

	// Filtertron
	$('.filtertron').filtertron('entries', {
		expander: '#filtertronExpander',
	});

	if (!isMobile()) {
		$('.header-controls').stickybits({
			useStickyClasses: true,
		});

		const scrollHandler = _.debounce(function () {
			// Header became sticky
			if ($('.header-controls').hasClass('js-is-sticky')) {
				$('#quick-selector-category').addClass('closed');
			}

			// Top of the browser window reached
			if ($(window).scrollTop() === 0 && $('#quick-selector-category').hasClass('open')) {
				$('#quick-selector-category').removeClass('closed');
			}
		}, 100);

		// Collapse category selector when it becomes sticky to save vertical space
		$(window).on('scroll', scrollHandler);

		$(document).one('pjax:end', () => {
			$(window).off('scroll', scrollHandler);
		});
	}
};
