import $ from 'jquery';
import Judging from './Judging';
import OpenRounds from '../../lib/open-rounds.js';
import store from '@/lib/store';
import Vue from 'vue';
export default function () {
	const judgingEl = document.getElementById('judging');

	if (judgingEl) {
		const judging = new Vue({
			el: judgingEl,
			name: 'JudgingApp',
			components: {
				Judging,
			},
			store,
		});

		$(document).one('pjax:end', () => {
			judging.$destroy();
		});
	}

	new OpenRounds();
}
