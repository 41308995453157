<script>
import ResourceList from '../../../lib/components/ResourceList.vue';
import Deletism from '../../../lib/components/ListActions/Deletism.vue';
import Undeletism from '../../../lib/components/ListActions/Undeletism.vue';
import Recusal from '../../../lib/components/ListActions/Recusal.vue';
import RecusalRemover from '../../../lib/components/ListActions/RecusalRemover.vue';
import Tagger from '../../../lib/components/ListActions/Tagger.vue';

export default {
  components: {
    Deletism,
    Undeletism,
    Recusal,
    RecusalRemover,
    Tagger
  },
  extends: ResourceList,
  computed: {
    taggerRevealed() {
      return this.reveal === 'tagger';
    },
    untaggerRevealed() {
      return this.reveal === 'untagger';
    }
  }
};
</script>
