module.exports = function() {
    // TOTP
    var totpStatus = $('#totp-status');

    $('#totp-enable').on('click', function () {
        $('.totp-register').removeClass('hidden');
        $('.totp-enabler').hide();
    });

    $('#totp-toggle-secret').on('click', function (e) {
        e.preventDefault();
        $('.totp-show-secret').toggleClass('hidden')
    });

    $('#totp-register').on('click', function () {
        totpStatus.text(totpStatus.data('verifying'));
        totpStatus.removeClass('text-danger');

        var data = {payload: $('#totp-code').val() };
        $.post('/profile/authenticator/totp', data)
            .done(function() {
                $('.totp-register').hide();
                $('.totp-register-complete').removeClass('hidden');
                $('.backup-disabled').removeClass('disabled');
            })
            .fail(function() {
                totpStatus.text(totpStatus.data('failed'));
                totpStatus.addClass('text-danger');
            });
    });

    // Codes
    $('#codes-enable').on('click', function () {
        if ($(this).hasClass('disabled')) return;

        $('.codes-enabled').removeClass('hidden');
        $('.codes-enabler').hide();
    });

    var textarea = $('#codes-output');
    textarea.css('height', 'auto').css('min-height', 'auto').attr('rows', 1);

    $('#codes-regenerate').on('click', function () {
        textarea.text(textarea.data('generating'));

        $.post('/profile/authenticator/codes', {}, function(codes) {
            var output = '';
            var lines = 0;

            while (codes.length) {
                output += codes.shift()+'    '+codes.shift()+'\n';
                lines++;
            }

            textarea.text(output);
            textarea.attr('rows', lines);
        });
    });
};
