<template>
	<div class="marker-action">
		<list-action-form ref="form" :ids="ids" :labels="labels" :route="route" :method="method" @submitted="confirmSubmit">
		</list-action-form>
		<modal
			v-model="showModal"
			:title="labels.confirmModalTitle"
			:header="false"
			:confirm-button-label="labels.ok"
			:close-button-label="labels.cancel"
			:confirm-on-enter="false"
			@closed="showModal = false"
			@confirmed="submit"
		>
			<close-icon slot="before-content" @click.prevent="showModal = false"></close-icon>
			{{ modalBody }}
		</modal>
	</div>
</template>

<script>
import ListAction from './ListAction.vue';
import CloseIcon from './Partials/CloseIcon';
import { Modal } from 'vue-bootstrap';

/**
 * The review flow reset action component.
 */
export default {
	components: {
		Modal,
		CloseIcon,
	},
	extends: ListAction,
	data() {
		return {
			showModal: false,
		};
	},
	computed: {
		modalBody() {
			return this.ids.length > 1 ? this.labels.resetActionItems : this.labels.resetActionItem;
		},
	},
	methods: {
		confirmSubmit() {
			this.showModal = true;
		},
	},
};
</script>
