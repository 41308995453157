<template>
	<div class="guides">
		<guide
			v-for="guide in initGuides"
			:key="guide.id"
			:guide="guide"
			:selected-guide-id="selectedGuideId"
			:reset-guide-text="resetGuideText"
			:translations="translations"
			@close="guideClosed(guide.id)"
			@open="guideOpened(guide.id)"
			@checklistCheck="checklistCheck"
			@guideReset="guideReset"
		></guide>
	</div>
</template>

<script>
import CollapsibleBox from '../Shared/CollapsibleBox';
import Guide from './Guide';
import langMixin from '@/lib/components/Translations/mixins/lang-mixin.js';

export default {
	components: { CollapsibleBox, Guide },
	mixins: [langMixin],
	props: {
		initGuides: {
			type: Array,
			required: true,
		},
		resetGuideText: {
			type: String,
			required: true,
		},
		resetGuideUrl: {
			type: String,
			required: true,
		},
		updateChecklistUrl: {
			type: String,
			required: true,
		},
		updateVisibilityUrl: {
			type: String,
			required: true,
		},
		selectedGuideId: {
			type: Number,
			default: null,
		},
	},
	methods: {
		checklistCheck(guideId, checklistItemId, isChecked) {
			this.$http.post(this.updateChecklistUrl, {
				guide: guideId,
				item: checklistItemId,
				checked: isChecked,
			});
		},
		guideClosed(guideId) {
			this.$http.post(this.updateVisibilityUrl, {
				guide: guideId,
				visible: false,
			});
		},
		guideOpened(guideId) {
			this.$http.post(this.updateVisibilityUrl, {
				guide: guideId,
				visible: true,
			});
		},
		guideReset(guideId) {
			this.$http.post(this.resetGuideUrl, {
				guide: guideId,
			});
		},
	},
};
</script>
