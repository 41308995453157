var Vue = require('vue');
var PaymentStatus = require('./PaymentStatus.vue').default;

module.exports = function() {
  new Vue({
    el: '#payment-status',
    name: 'PaymentStatusApp',
    components: {
      'payment-status': PaymentStatus
    }
  });
};
