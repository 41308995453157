<script>
import TranscodingStatus from '../TranscodingStatus.js';
import TranscodingCompleted from './TranscodingCompleted';
import TranscodingError from './TranscodingError';
import TranscodingProgress from './TranscodingProgress';

export default {
  components: {
    TranscodingCompleted,
    TranscodingError,
    TranscodingProgress
  },
  props: {
    file: {
      type: Object,
      required: true
    }
  },
  computed: {
    component() {
      switch (this.file.transcodingStatus) {
        case TranscodingStatus.WAITING:
        case TranscodingStatus.PROGRESSING:
          return TranscodingProgress;
        case TranscodingStatus.COMPLETED:
        case TranscodingStatus.WARNING:
          return TranscodingCompleted;
        default:
          return TranscodingError;
      }
    }
  },
  render(createElement) {
    return createElement(this.component, {
      props: {
        file: this.file
      }
    });
  }
};
</script>
