<template>
	<div class="checkbox styled">
		<input :id="uid" type="checkbox" :name="name" :disabled="disabled" :checked="isChecked" @click="onClick" />
		<label :for="uid">
			<span class="sr-only">{{ label }}</span>
		</label>
	</div>
</template>

<script>
/**
 * The ReactiveMarker component.
 *
 * Supports v-model property, which can be used to reference an array with selected ids. ReactiveMarker
 * takes care of setting up a two-way binding and updates the array as needed.
 *
 * Example of use:
 *
 * <reactive-marker :id="id" v-model="ids" />
 */

export default {
	model: {
		prop: 'ids',
		event: 'change',
	},
	props: {
		name: {
			type: String,
			default: 'selected[]',
		},
		id: {
			type: Number,
			required: true,
		},
		ids: {
			type: Array,
			default: () => [],
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		label: {
			type: String,
			default: 'Checkbox list',
		},
	},
	data() {
		return {
			localIds: [],
		};
	},
	computed: {
		isChecked() {
			if (this.disabled) {
				return;
			}

			return this.localIds.includes(this.id);
		},
		uid() {
			return `reactive-marker-${this.id}`;
		},
	},
	watch: {
		ids() {
			this.localIds = this.ids.slice();
		},
	},
	created() {
		this.localIds = this.ids.slice();
	},
	methods: {
		onClick() {
			if (this.disabled) {
				return;
			}

			if (this.isChecked) {
				this.localIds = this.localIds.filter((id) => id !== this.id);
			} else {
				this.localIds.push(this.id);
			}

			this.$emit('change', this.localIds);
		},
	},
};
</script>

<style scoped>
/* make it clickable inside datatable */
input {
	z-index: auto !important;
}
</style>
