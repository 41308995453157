<template>
	<div class="secondary-menu">
		<nav v-if="settingsMenuIsOpen" aria-label="Settings Menu" role="navigation">
			<div class="secondary-menu-items">
				<h1>{{ menu.text }}</h1>
				<sub-menu
					v-for="submenu in submenus"
					:id="id(submenu)"
					:key="submenu.name"
					:is-open="visibility[submenu.name]"
					:sub-menu="submenu"
					:current-url="currentUrl"
					:current-focus="currentFocus"
					@itemSelected="onItemSelected"
					@toggled="onToggled"
					@focus="(payload) => $emit('focus', payload)"
					@focusNext="(payload) => $emit('focusNext', { ...payload, visibility })"
					@focusPrevious="(payload) => $emit('focusPrevious', { ...payload, visibility })"
				/>
			</div>
			<button class="secondary-menu-close" @click.prevent="$emit('closed')">
				<span class="sr-only">{{ closeLabel }}</span>
				<span class="af-icons af-icons-cross"></span>
			</button>
		</nav>
	</div>
</template>

<script>
import Storage from '@/lib/storage.js';
import SubMenu from './SecondaryMenuSubMenu';

const Visibility = Object.freeze({
	OPEN: 'open',
	CLOSED: 'closed',
});

export default {
	components: {
		SubMenu,
	},
	props: {
		menu: {
			type: Object,
			default: () => ({}),
		},
		currentUrl: {
			type: String,
			default: null,
		},
		closeLabel: {
			type: String,
			default: 'Close',
		},
		focusMenuName: {
			type: String,
			default: '',
		},
		settingsMenuIsOpen: {
			type: Boolean,
			default: false,
		},
		currentFocus: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			storage: null,
			visibility: {},
		};
	},
	computed: {
		submenus() {
			return this.menu.children || [];
		},
	},
	watch: {
		submenus() {
			this.restoreState();
		},
		focusMenuName() {
			if (this.focusMenuName !== '') {
				this.focusMenu(this.focusMenuName);
			}
		},
		menu() {
			if (Object.keys(this.menu).length === 0) {
				this.$emit('closed');
			}
		},
	},
	created() {
		this.storage = new Storage();
		this.restoreState();
	},
	methods: {
		id(item) {
			return 'secondary-menu-' + item.name;
		},
		localStorageKey(submenu) {
			return `submenu-${submenu.name}`;
		},
		onItemSelected(item, submenu) {
			// Collapse all submenus except for the one that contains the selected item
			this.focusMenu(submenu.name);

			this.$emit('itemSelected', item);
		},
		focusMenu(menuName) {
			Object.keys(this.visibility).forEach((name) => {
				this.$set(this.visibility, name, menuName === name);
			});
		},
		onToggled(submenu) {
			// Toggle visibility
			this.$set(this.visibility, submenu.name, !this.visibility[submenu.name]);

			// Save state in local storage
			this.saveState(submenu);
		},
		saveState(submenu) {
			this.storage.set(this.localStorageKey(submenu), this.visibility[submenu.name] ? Visibility.OPEN : Visibility.CLOSED);
		},
		restoreState() {
			// Restore state based on the information from local storage
			this.submenus.forEach((submenu) => {
				const state = this.storage.get(this.localStorageKey(submenu));

				this.$set(this.visibility, submenu.name, state !== Visibility.CLOSED);
			});
		},
	},
};
</script>
