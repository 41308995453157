<template>
  <div class="row">
    <div class="col-xs-12 col-md-6 col-md-offset-3">
      <h1 class="provisioning-label" role="heading" aria-level="2">
        {{ lang.get('provisioning.welcome.heading') }}
      </h1>

      <div class="vertical well">
        <span v-html="welcome.welcomeMessage"> </span>
        <br />
        <br />
        <a :href="welcome.vanityUrl" class="btn btn-primary btn-lg">
          {{ lang.get('provisioning.welcome.button') }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import ProvisioningMixins from './provisioning-mixins';
export default {
  inject: ['lang'],
  mixins: [ProvisioningMixins],
  props: {
    welcome: {
      type: Object,
      required: true
    }
  },
  created() {
    this.pusherSubscribe(this.welcome.accountGlobalId);
  },
  beforeDestroy() {
    this.pusherUnsubscribe();
  }
};
</script>
