<template>
  <ul class="action-list">
    <div v-if="!layouts.length && emptyMessage" class="caption">
      {{ emptyMessage }}
    </div>
    <li v-for="layout in layouts" :key="layout.id">
      <export-layout-list-item
        :list-item="layout"
        :excel-label="excelLabel"
        :csv-label="csvLabel"
        :edit-label="editLabel"
        :delete-label="deleteLabel"
        @deleting="removeLayout"
      >
      </export-layout-list-item>
    </li>
  </ul>
</template>

<script>
import ExportLayoutListItem from './ExportLayoutListItem.vue';

export default {
  components: {
    ExportLayoutListItem
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    excelLabel: {
      type: String,
      default: 'Excel'
    },
    csvLabel: {
      type: String,
      default: 'CSV'
    },
    editLabel: {
      type: String,
      default: 'Edit'
    },
    deleteLabel: {
      type: String,
      default: 'Delete'
    },
    emptyMessage: {
      type: String,
      default: 'Empty'
    }
  },
  data() {
    return {
      layouts: []
    };
  },
  mounted() {
    this.layouts = this.list;
  },
  methods: {
    removeLayout(layout) {
      this.layouts = this.layouts.filter(l => l.id !== layout.id);
    }
  }
};
</script>
