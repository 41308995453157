import convertMarkdownVideoNotationToHtml from '@/lib/components/Shared/editor/markdown-video/markdown-video';
import { markdownToHtml } from '@/lib/markdown/markdown-to-html';

const convertMarkdownToHtmlIfNeeded = (htmlOrMarkdown) => {
	try {
		return markdownToHtml(htmlOrMarkdown);
	} catch (e) {
		return htmlOrMarkdown;
	}
};

/**
 * Parse the editor content, just as it is stored in the database:
 * - convert our custom markdown video notation, its format: ![width](url), into a format understood by the editor,
 * - convert if needed the editor content from Markdown to HTML; in our old editor called SimpleMDE the content
 *   was stored in the database in Markdown. We now store it as HTML.
 */
const prepareHtmlForEditorContent = (content) => {
	if (!content) {
		return '';
	}

	content = convertMarkdownVideoNotationToHtml(content);

	return convertMarkdownToHtmlIfNeeded(content);
};

export { prepareHtmlForEditorContent };
