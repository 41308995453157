<template>
	<div class="panel panel-default">
		<div class="panel-body">
			<div class="panel-title mbm">
				<h4>{{ title }}</h4>
				<span class="draggable-columns-buttons">
					<button class="btn btn-secondary btn-sm" type="button" @click="clearAll">{{ clearAllLabel }}</button>
					<button class="btn btn-secondary btn-sm" type="button" @click="addAll">{{ addAllLabel }}</button>
				</span>
			</div>
			<draggable
				tag="ul"
				:list="selectedColumns"
				class="draggable-columns"
				:disabled="!canDrag"
				draggable=".column"
				animation="150"
				filter=".not-draggable"
			>
				<draggable-item
					v-for="(column, index) in selectedColumns"
					:key="column.name"
					tag="li"
					class="column group"
					:index="index"
					:item="column"
					:items="selectedColumns"
					item-key="name"
					:item-description="column.title"
					:grabbed-item="grabbedItem"
					@grabItem="(key) => (grabbedItem = key)"
					@reorderItems="(reorderedItems) => (selectedColumns = reorderedItems)"
				>
					<div class="group">
						<span class="af-icons af-icons-up-down group-hidden"></span>
						{{ strip(column.title) }}
						<a @click.prevent="remove(column.name)">
							<span class="af-icons af-icons-close-circle not-draggable pull-right group-hidden"></span>
						</a>
					</div>
					<column-selector
						:position="column.name"
						:insert-position="insertPosition"
						:columns="availableColumns"
						:placeholder="placeholder"
						:empty-list-label="emptyListLabel"
						@toggled="toggleSelector(column.name)"
						@selected="insert"
						@closed="resetInsertPosition"
					>
					</column-selector>
				</draggable-item>
			</draggable>
			<column-selector
				v-if="selectedColumns.length === 0"
				:position="initialInsertPosition"
				:insert-position="insertPosition"
				:columns="availableColumns"
				:placeholder="placeholder"
				:empty-list-label="emptyListLabel"
				@toggled="toggleSelector(initialInsertPosition)"
				@selected="insert"
				@closed="resetInsertPosition"
			>
			</column-selector>
			<input name="columns" type="hidden" :value="fieldValue" />
		</div>
	</div>
</template>

<script>
import Draggable from 'vuedraggable';
import ColumnSelector from './ColumnSelector.vue';
import { stripTags } from '@/lib/string';
import DraggableItem from '@/lib/components/Shared/DraggableItem.vue';

export default {
	components: {
		Draggable,
		ColumnSelector,
		DraggableItem,
	},
	props: {
		title: {
			type: String,
			required: true,
		},
		columns: {
			type: Array,
			default: () => [],
		},
		selected: {
			type: Array,
			default: () => [],
		},
		placeholder: {
			type: String,
			default: 'Search',
		},
		emptyListLabel: {
			type: String,
			default: 'Empty',
		},
		clearAllLabel: {
			type: String,
			default: 'Clear all',
		},
		addAllLabel: {
			type: String,
			default: 'Add all',
		},
	},
	data() {
		return {
			availableColumns: [],
			selectedColumns: [],
			hoveringOver: null,
			insertPosition: null,
			initialInsertPosition: 'initial',
			grabbedItem: null,
		};
	},
	computed: {
		canDrag() {
			// Enable dragging unless the user is currently inserting a column
			return this.insertPosition === null;
		},
		fieldValue() {
			// Return a comma separated list of column names
			return this.selectedColumns.map((column) => column.name).join(',');
		},
	},
	watch: {
		columns() {
			this.refreshAvailableColumns();
		},
		selected() {
			this.refreshSelectedColumns();
		},
	},
	created() {
		this.refreshAvailableColumns();
		this.refreshSelectedColumns();
	},
	methods: {
		strip(str) {
			return stripTags(str);
		},
		setInsertPosition(name) {
			this.insertPosition = name;
		},
		resetInsertPosition() {
			this.insertPosition = null;
		},
		toggleSelector(name) {
			if (this.insertPosition === name) {
				this.resetInsertPosition();
				return;
			}

			this.setInsertPosition(name);
		},
		getIndex(columns, name) {
			return columns.map((column) => column.name).indexOf(name);
		},
		remove(name) {
			const index = this.getIndex(this.selectedColumns, name);

			if (index >= 0) {
				this.availableColumns.push(this.selectedColumns.splice(index, 1)[0]);
			}
		},
		insert(name) {
			const index = this.getIndex(this.availableColumns, name);

			if (index >= 0) {
				const insertAtIndex = this.getIndex(this.selectedColumns, this.insertPosition) + 1;
				const column = this.availableColumns.splice(index, 1)[0];

				this.selectedColumns.splice(insertAtIndex, 0, column);
				this.resetInsertPosition();
			}
		},
		refreshAvailableColumns() {
			this.availableColumns = this.columns.filter((column) => this.selected.indexOf(column.name) < 0);
		},
		refreshSelectedColumns() {
			this.selectedColumns = this.selected
				.map((name) => this.columns.find((column) => column.name === name))
				.filter((column) => column);
		},
		clearAll() {
			this.selectedColumns = [];
			this.availableColumns = this.columns.slice();
		},
		addAll() {
			this.selectedColumns = [...this.columns].sort((a, b) => a.title.localeCompare(b.title));
			this.availableColumns = [];
		},
	},
};
</script>

<style scoped>
ul,
li {
	list-style-type: none;
	padding: 0;
}

.column {
	padding: 5px;
	cursor: move;
}

.column:focus-visible {
	outline: 2px solid #333;
	outline-offset: -2px;
}

/*Show draggable icons*/
.group-hidden {
	visibility: hidden;
}

.group:hover .group-hidden,
.group:focus .group-hidden,
.group:focus-visible .group-hidden,
.group:focus-within .group-hidden {
	visibility: visible;
}

.draggable-columns-buttons {
	display: flex;
}
</style>
