<template>
  <div class="marker-action">
    <list-action-form
      ref="form"
      :ids="ids"
      :labels="labels"
      :route="route"
      :method="method"
      @submitted="toggle"
    >
      <input type="hidden" name="tags" :value="formattedTags" />
      <portal :to="name">
        <div :key="name" class="marker-action-tagger">
          <div class="tags-input">
            <tag-input
              v-model="tags"
              :placeholder="labels.button"
              :src="src"
            ></tag-input>
          </div>
          <div class="buttons">
            <a
              :class="[
                'btn',
                'btn-lg',
                'btn-secondary',
                { disabled: !formattedTags }
              ]"
              @click.prevent="submit"
            >
              {{ labels.button }}
            </a>
            <a
              :class="['btn', 'btn-lg', 'btn-tertiary ']"
              @click.prevent="toggle"
            >
              {{ labels.cancel }}
            </a>
          </div>
        </div>
      </portal>
    </list-action-form>
  </div>
</template>

<script>
import ListAction from './ListAction.vue';
import { TagInput } from 'vue-bootstrap';

/**
 * The Tagger component.
 */
export default {
  components: {
    TagInput
  },
  extends: ListAction,
  props: {
    name: {
      type: String,
      required: true,
      validator: value => ['tagger', 'untagger'].indexOf(value) !== -1
    },
    src: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      tags: []
    };
  },
  computed: {
    formattedTags() {
      return this.tags.join(', ');
    }
  },
  methods: {
    toggle() {
      this.$emit('reveal', this.name);
    }
  }
};
</script>
<style scoped lang="scss">
.marker-action-tagger {
  .buttons {
    margin-left: 10px;
    display: inline-block;
    .btn {
      &:first-child {
        margin-left: 0;
      }
      margin-right: 0;
      margin-left: 10px;
    }
  }
}
</style>
