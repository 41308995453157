import FormsList from './FormsList';
import store from '@/lib/store';
import Vue from 'vue';
export default function () {
	const formsListEl = document.getElementById('forms-list');

	if (formsListEl) {
		new Vue({
			el: formsListEl,
			components: {
				FormsList,
			},
			store,
		});
	}
}
