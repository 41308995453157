import SeasonSelector from '@/lib/components/Selectors/SeasonSelector';
import Vue from 'vue';

export default function () {
	const seasonSelector = document.querySelector('season-selector:not(edit-interface-text season-selector)');
	if (seasonSelector) {
		new Vue({
			el: seasonSelector,
			name: 'SelectorTitleApp',
			components: {
				'season-selector': SeasonSelector,
			},
		});
	}
}
