<template>
  <div class="checkbox styled">
    <input
      id="select-all-checkbox"
      type="checkbox"
      :name="name"
      :checked="allChecked"
      @click="onClick"
    />
    <label for="select-all-checkbox">
      <span class="sr-only">
        Select all
      </span>
    </label>
  </div>
</template>

<script>
/**
 * The SelectAllMarker component.
 */

export default {
  props: {
    name: {
      type: String,
      default: 'selectAll'
    },
    allChecked: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      this.$emit('selected');
    }
  }
};
</script>

<style scoped>
.checkbox {
  top: 10px;
}
</style>
