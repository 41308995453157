<script>
import DraggableColumns from './DraggableColumns.vue';

export default {
	components: {
		DraggableColumns,
	},
	props: {
		area: {
			type: String,
			required: true,
		},
		columns: {
			type: Array,
			default: () => [],
		},
		selected: {
			type: Array,
			default: () => [],
		},
		name: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			selectedArea: '',
			availableColumns: [],
			selectedColumns: [],
			exportName: '',
		};
	},
	created() {
		this.selectedArea = this.area;
		this.availableColumns = this.columns;
		this.selectedColumns = this.selected;
		this.exportName = this.name;
	},
	methods: {
		selectArea() {
			// Since both the NewCustomExportLayout and EditCustomExportLayout components use the same resources/views/export/form.blade.php view,
			// and the user cannot modify the area during edits,
			// I added this method here only to prevent Vue from warning about the missing method.
		},
	},
};
</script>
