var toastr = require('../../lib/tectoastr');

module.exports = function() {
  $('.transcode-button').click(function() {
    var button = $(this);
    var account = button.data('account');

    button.attr('disabled', 'disabled');
    button.text('0 / ...');

    // Once the request comes back, update the UI to show how many files
    // are going to be transcoded, or, more accurately - how many files are
    // part of the transcode job.
    $.post('transcode/' + account + '/all').done(function(numFiles) {
      button.text('0 / ' + numFiles);
    });
  });
};