// cookies-notice.js scope
(function() {
  const $cookieNoticeDiv = $('#cookieNoticeId');
  const $cookieSelectionDiv = $('#cookieSelectionId');
  const $cookieCheckboxes = $('.cookie-checkbox');
  const $showCookiesNoticeButton = $('#showCookieNotice');
  const slideSpeed = 'fast';

  // showing cookies if they're not set already
  if ($cookieNoticeDiv.data('show-notice') === 1) {
    $cookieNoticeDiv.delay(1000).slideDown(slideSpeed, () => {
      document.getElementById('cookieNoticeId').scrollIntoView({ behavior: 'smooth' });
    });
  }

  // functionality for manage cookies button in footer
  $showCookiesNoticeButton.on('click', e => {
    e.preventDefault();
    if ($cookieNoticeDiv.is(':hidden')) {
      $cookieNoticeDiv
        .slideDown(slideSpeed)
        .queue(function() {
          document.getElementById('cookieNoticeId').scrollIntoView({ behavior: 'smooth' });
        })
        .attr('tabindex', '1')
        .focus()
        .removeAttr('tabindex');
      $showCookiesNoticeButton.attr('aria-expanded', true);
    } else {
      document.getElementById('cookieNoticeId').scrollIntoView({ behavior: 'smooth' });
    }
  });

  $cookieCheckboxes.each(function() {
    const $self = $(this);
    $self.popover({
      trigger: 'hover focus',
      content: $self.data('help-text'),
      placement: 'bottom',
      animation: false,
      template: `<div class="popover" role="tooltip">
      <div class="arrow"></div>
      <div class="popover-content" aria-live="polite"></div>
      </div>`,
      delay: {
        show: 0,
        hide: 300
      }
    });
  });

  // main handler for cookies selection and setup
  // buttons in `cookie-notice.blade.php` have a `data-cookies-action` that handles the action
  $cookieNoticeDiv.on('click', 'button[data-cookies-action]', function(e) {
    let availableCookies = ['necessary', 'analytics', 'marketing', 'social-sharing'];
    const cookieSelectionIsHidden = $cookieSelectionDiv.is(':hidden');
    const buttonCookiesAction = e.target.dataset.cookiesAction;

    // Showing and hiding selection container div
    if (buttonCookiesAction === 'choose') {
      if (cookieSelectionIsHidden) {
        $cookieSelectionDiv.slideDown(slideSpeed, () => {
          $(e.target).attr('aria-expanded', true);
        });
      } else {
        $cookieSelectionDiv.slideUp(slideSpeed, () => {
          $(e.target).attr('aria-expanded', false);
        });
      }
      return;
    }

    if (buttonCookiesAction === 'necessary') {
      availableCookies = availableCookies
        .map(cookieName => (cookieName !== 'necessary' ? null : cookieName))
        .filter(cookieName => cookieName !== null);
    }

    if (buttonCookiesAction === 'save') {
      const cookieInputs = Array.from(document.getElementById('cookieSelectionId').getElementsByTagName('input'));

      availableCookies = cookieInputs
        .map(input => (input.checked ? input.name : null))
        .filter(cookieName => cookieName !== null);
    }

    // setting all filtered cookies
    sendCookiesConsent(availableCookies);
  });

  function sendCookiesConsent(cookies) {
    $.ajax({
      type: 'POST',
      url: '/cookies/accept',
      data: {
        cookies: cookies
      }
    }).success(() => {
      $cookieNoticeDiv.dequeue().slideUp(slideSpeed, () => {
        $showCookiesNoticeButton.attr('aria-expanded', false);
        window.location.reload();
      });
    });
  }
})();
