<template>
	<div class="download">
		<small>
			<div class="filename">
				<a v-if="file.url" :href="file.url" target="_blank" rel="noopener noreferrer" :title="file.original" class="ignore">
					{{ filename }} ({{ size }})
					<i class="af-icons af-icons-download pull-right"></i>
				</a>
				<template v-else>
					{{ file.original }}
				</template>
			</div>
		</small>
	</div>
</template>

<script>
import filesize from 'filesize';

export default {
	inject: ['lang'],
	props: {
		file: {
			type: Object,
			required: true,
		},
	},
	computed: {
		filename() {
			const filename = this.file.original;

			return filename.length > 20 ? Array.from(filename).slice(0, 20).join('') + '…' : filename;
		},
		size() {
			return this.lang.get('files.buttons.size_download', {
				size: filesize(this.file.size),
			});
		},
	},
};
</script>

<style scoped>
.filename {
	text-overflow: ellipsis;
	overflow: hidden;
}
</style>
