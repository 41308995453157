function getAllFocusableElements(element = document) {
  return [
    ...element.querySelectorAll('a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])')
  ].filter(el => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden') && !isHidden(el));
}

export function getNextFocusableElement(afterElement) {
  if (!afterElement) {
    return null;
  }

  const all = getAllFocusableElements();
  const afterElementIndex = all.indexOf(afterElement);
  const nextElementIndex = afterElementIndex + 1;

  return all[nextElementIndex];
}

function isHidden(el) {
  if (el.offsetWidth === 0 && el.offsetHeight === 0) {
    return true;
  }

  let style = window.getComputedStyle(el);
  return style.display === 'none' || style.visibility === 'hidden';
}
