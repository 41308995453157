<template>
	<div class="secondary-menu-submenu">
		<button
			:id="id"
			:ref="'settings-' + subMenu.name"
			class="toggle"
			role="button"
			:aria-expanded="String(isOpen)"
			@focus="$emit('focus', { item: subMenu, event: $event })"
			@click.prevent="toggle($event)"
			@keydown.up.exact="$emit('focusPrevious', { event: $event, isParentMenuClosed: !isOpen })"
			@keydown.down.exact="$emit('focusNext', { event: $event, isParentMenuClosed: !isOpen })"
			@keydown.tab.exact="$emit('focusNext', { event: $event, isParentMenuClosed: !isOpen })"
			@keydown.shift.tab.exact="$emit('focusPrevious', { event: $event, isParentMenuClosed: !isOpen })"
		>
			{{ subMenu.text }}
			<span :class="['af-icons', 'af-icons-chevron-' + (isOpen ? 'down' : 'up'), 'pull-right']" />
		</button>
		<collapsible>
			<sub-menu
				v-if="isOpen"
				id="secondary-submenu"
				:current-url="currentUrl"
				:items="subMenu.children"
				:aria-labelledby="id"
				:current-focus="currentFocus"
				@itemSelected="(item) => $emit('itemSelected', item, subMenu)"
				@focus="(payload) => $emit('focus', payload)"
				@focusNext="(payload) => $emit('focusNext', { ...payload, isParentMenuClosed: !isOpen })"
				@focusPrevious="(payload) => $emit('focusPrevious', { ...payload, isParentMenuClosed: !isOpen })"
			/>
		</collapsible>
	</div>
</template>

<script>
import Collapsible from '../Shared/Collapsible';
import SubMenu from './SubMenu';

export default {
	components: {
		Collapsible,
		SubMenu,
	},
	props: {
		isOpen: {
			type: Boolean,
			default: true,
		},
		subMenu: {
			type: Object,
			default: () => ({}),
		},
		currentUrl: {
			type: String,
			default: null,
		},
		id: {
			type: String,
			default: '',
		},
		currentFocus: {
			type: Object,
			default: null,
		},
	},
	watch: {
		currentFocus(focusedItem) {
			if (focusedItem) {
				const element = this.$refs['settings-' + focusedItem.name];

				if (element) {
					element.focus();
				}
			}
		},
	},
	methods: {
		toggle(event) {
			this.$emit('toggled', this.subMenu);
			this.$emit('focus', { item: this.subMenu, event: event });
		},
	},
};
</script>
