<template>
  <box>
    <widget :header="false">
      <a :href="route(routes['forms.new'])" class="container">
        <div class="icon"><icon name="add" /></div>
        <div class="action" v-html="lang.get('form.buttons.create')" />
      </a>
    </widget>
  </box>
</template>

<script>
import Widget from '@/lib/components/Shared/Widget';
import Box from './Box';
import { route } from '@/lib/utils.js';
import Icon from '@/lib/components/Shared/Icon';

export default {
  name: 'CreateForm',
  inject: ['lang'],
  components: {
    Widget,
    Box,
    Icon
  },
  props: {
    routes: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    route
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: row;
  margin: 10px 0 100px 0;
  padding: 0;
  width: 100%;
}

.action {
  margin: 8px 0;
}

.icon {
  text-align: center;
  display: inline-block;
  width: 70px;
  font-size: 300%;
}
</style>
