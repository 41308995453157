<template>
  <div>
    <editable-content-block
      v-if="editable"
      :content-block="contentBlock.id ? contentBlock : null"
      :content-block-key="contentBlockKey"
      :model-id="modelId"
      :container-class="containerClass"
      :title-as-link="titleAsLink"
      :title-tag="titleTag"
    ></editable-content-block>
    <content-block
      v-else
      :content-block="contentBlock"
      :container-class="containerClass"
      :title-as-link="titleAsLink"
      :title-tag="titleTag"
    ></content-block>
  </div>
</template>

<script>
import ContentBlock from '@/lib/components/ContentBlocks/ContentBlock';
import EditableContentBlock from '@/lib/components/ContentBlocks/EditableContentBlock';

export default {
  components: {
    ContentBlock,
    EditableContentBlock
  },
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    contentBlockKey: {
      type: String,
      required: true
    },
    contentBlock: {
      type: Object,
      default: null
    },
    modelName: {
      type: String,
      default: null
    },
    modelId: {
      type: Number,
      default: null
    },
    containerClass: {
      type: String,
      default: ''
    },
    titleAsLink: {
      type: Boolean,
      default: false
    },
    titleTag: {
      type: String,
      default: 'h3'
    },
    showTitle: {
      type: Boolean,
      default: true
    }
  }
};
</script>
