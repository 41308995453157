const { mountVueComponent } = require('@/lib/mount-vue-component.js');

var Audio = require('../../lib/audio.js');
var Cards = require('../../lib/cards.js');
var VideoPlayer = require('@/domain/services/VideoPlayer/VideoPlayer').default;
var VideoModal = require('@/domain/services/VideoPlayer/VideoPlayerModal').default;
var VideoThumbnails = require('../../lib/video-thumbnails.js');

module.exports = function()
{
    /**
     * Cards
     */
    var cards = new Cards;
    cards.setup('ul.cards', 'li', 'fitRows');

    /**
     * Audio
     */
    var audio = new Audio;
    audio.setup('jp-jplayer');

    /**
     * Video
     */
    var videoThumbnails = new VideoThumbnails;

	  VideoPlayer.setup('video-js-standalone');
		VideoModal.setup('play-video');
    videoThumbnails.setup('preview-container.video');

    /** Comments */
    import('@/lib/components/Comments/Comments.vue').then(Comments => {
        mountVueComponent('comments', Comments);
    });
};
