const useUploadProps = {
	uploads: {
		type: Boolean,
		default: false,
		description: 'True means file uploads are allowed. Valid uploader options are also required.',
	},
	uploaderObject: {
		type: Object,
		default: () => {},
	},
	uploaderOptions: {
		type: Object,
		default: () => {},
	},
};

export { useUploadProps };
