<template>
	<select-field
		:id="id"
		:name="name"
		:items="scoreSets"
		:value="currentScoreSet"
		:disabled="disabled"
		@selected="onSelect"
	>
	</select-field>
</template>

<script>
import $ from 'jquery';
import { SelectField } from 'vue-bootstrap';

/**
 * The ScoreSetSelector component.
 *
 * Populates a select field with score sets, redirects to the provided URL using pjax.
 */
export default {
	components: {
		SelectField,
	},
	props: {
		url: {
			type: String,
			default: '#',
		},
		name: {
			type: String,
			default: 'scoreSetSelector',
		},
		scoreSets: {
			type: Array,
			required: true,
		},
		currentScoreSet: {
			type: Number,
			default: null,
		},
		redirectOnSelect: {
			type: Boolean,
			default: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		id: {
			type: String,
			default: 'scoreSetSelector',
		},
	},
	methods: {
		onSelect(name, selected) {
			if (!this.redirectOnSelect) {
				return this.$emit('change', selected);
			}

			if (this.currentScoreSet !== selected) {
				return this.redirect(selected);
			}
		},
		redirect(id) {
			$.pjax({
				url: this.url,
				data: 'score-set=' + id,
				container: '#pjaxContainer',
				method: 'GET',
			});
		},
	},
};
</script>
