export default {
  validationErrors: state => state.validationErrors,
  formError: state => ({
    message: state.formError,
    type: 'error'
  }),
  hasError: state => key => !!state.validationErrors[key],
  hasMultilingualError: (state, getters) => key =>
    key
      ? getters.hasError(
          String(key)
            .replace(/\[/g, '.')
            .replace(/\]/g, '')
        )
      : false
};
