import EditInterfaceText from '@/modules/interface-text/components/EditInterfaceText';
import FeatureIntro from './lib/components/Holocron/FeatureIntro';
import FeatureIntroRevealer from './lib/components/Holocron/FeatureIntroRevealer';
import FileMetadata from '@/modules/entries/components/FileMetadata.vue';
import FormSelector from '@/lib/components/Selectors/FormSelector';
import GrantStatusSelector from './lib/components/ListActions/GrantStatusSelector';
import NoPjaxButton from '@/lib/components/Shared/NoPjaxButton.vue';
import SeasonSelector from '@/lib/components/Selectors/SeasonSelector';
import SimpleWidget from '@/lib/components/Shared/SimpleWidget';
import StateSelector from '@/lib/components/Selectors/StateSelector';
import Translation from '@/modules/interface-text/components/Translation';

export default {
	EditInterfaceText,
	FileMetadata,
	FeatureIntro,
	FeatureIntroRevealer,
	FormSelector,
	GrantStatusSelector,
	NoPjaxButton,
	SeasonSelector,
	SimpleWidget,
	StateSelector,
	Translation,
};
