<template>
	<button class="btn-link show-hide" type="button" @click="onClick">
		<i :class="['af-icons', 'af-icons-' + (collapsed ? 'preview' : 'read-off')]"></i>
		{{ text }}
	</button>
</template>

<script>
export default {
	props: {
		collapsed: {
			type: Boolean,
			required: true,
		},
		url: {
			type: String,
			required: true,
		},
		hideText: {
			type: String,
			default: 'Hide',
		},
		showText: {
			type: String,
			default: 'Show',
		},
	},
	computed: {
		text() {
			return this.collapsed ? this.showText : this.hideText;
		},
	},
	methods: {
		onClick() {
			this.$emit('change', !this.collapsed);
		},
	},
};
</script>

<style scoped>
.show-hide {
	text-decoration: none;
}
</style>
