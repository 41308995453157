var $ = require('jquery');
var _ = require('underscore');
var moment = require('moment');

require('../../../../../node_modules/eonasdan-bootstrap-datetimepicker/src/js/bootstrap-datetimepicker.js');

/**
 * The datepicker module abstracts away the underlying implementation detail,
 * and allows us to setup automatic bindings for 3 separate inputs:
 *
 *  input.date
 *  input.datetime  and
 *  input.time
 *
 * Each of these types behave in a similar, yet slightly different manner. Date
 * for example, should only show a date selector, but a datetime should show both
 * a date AND a time selector.
 *
 * Usage:
 *
 *    var datePicker = require('datepicker');
 *    datePicker.setup($('.date, .datetime, .time'));
 */

module.exports = {
  /**
   * Date formats.
   */
  formats: {
    date: {
      iso: 'YYYY-MM-DD'
    },
    datetime: {
      iso: 'YYYY-MM-DD HH:mm'
    },
    time: {
      iso: 'HH:mm'
    }
  },

  /**
   * Sets up the relevant elements with a datepicker component.
   *
   * @param {jQuery} elements
   */
  setup: function(elements) {
    for (var i = 0; i < elements.length; i++) {
      this.bindToElement($(elements[i]));
    }
  },

  /**
   * Bind our datetime picker to the element provided.
   *
   * @param {jQuery} element
   */
  bindToElement: function(element) {
    var type = element.data('datepicker-type') || 'datetime';
    var format = element.data('datepicker-format') || 'YYYY-MM-DD HH:mm:ss';
    var locale = element.data('datepicker-locale') || 'en-gb';
    var useCurrent = element.data('datepicker-preselect-current-date');
    var tooltips = element.data('datepicker-tooltips');
    var initialDate, hiddenField;
    var self = this;

    if (typeof useCurrent === 'undefined') {
      useCurrent = true;
    }

    element.datetimepicker({
      allowInputToggle: true,
      focusOnShow: false,
      format: format,
      extraFormats: ['YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm', 'YYYY-MM-DD', 'HH:mm'],
      ignoreReadonly: true,
      showClear: true,
      showClose: true,
      showTodayButton: true,
      locale: locale,
      useCurrent: useCurrent,
      widgetParent: $(element.parent()),
      widgetPositioning: {
        vertical: 'bottom'
      },
      tooltips: tooltips
    }).attr('autocomplete', 'off');

    initialDate = element.data('DateTimePicker').date();

    hiddenField = element.next('.datepicker-hidden-field');

    if (!hiddenField.length) {
      hiddenField = $('<input>', {
        type: 'hidden',
        name: element.attr('name'),
        class: 'datepicker-hidden-field',
        value: initialDate ? this.toIso(initialDate, type) : ''
      }).insertAfter(element);
    }

    element.attr('name', '');

    element.on('dp.change', function(event) {
      if (event.date) {
        hiddenField.val(self.toIso(event.date, type));
      } else {
        hiddenField.val('');
      }
    });

    return self
  },

  /**
   * Convert the date to ISO format.
   *
   * @param {Moment} date
   * @param {String} type
   * @return {String}
   */
  toIso: function(date, type) {
    var isoFormat = _.has(this.formats, type) ?
      this.formats[type].iso :
      this.formats.datetime.iso;

    return date.locale("en").format(isoFormat);
  }
};
