import { EventEmitters } from '@/domain/utils/Events';
import { isUndefined } from '@/domain/utils/TypePredicates';
import { computed, ComputedRef, Ref, ref, SetupFunction, watch } from 'vue';
import { TranslationsParam, useTranslationsDao } from '@/domain/dao/Translations';

type ItemKey = string;

interface Item extends Record<string, unknown> {
	[key: ItemKey]: string | undefined;
}

type Props = {
	index: number;
	item: Item;
	items: Item[];
	itemKey: ItemKey;
	itemDescription: string | null;
	grabbedItem: string | number | null;
	tag: keyof JSX.IntrinsicElements;
	dragDirection: 'vertical' | 'horizontal';
	lockFirstItem: boolean;
};

type View = {
	toggleGrabbed: ($event: Event, forceUngrab?: boolean) => void;
	moveItem: ($event: KeyboardEvent) => void;
	isGrabbed: ComputedRef<boolean>;
	itemRef: Ref<HTMLElement | null>;
	announcement: Ref<string | undefined>;
	label: string;
};

enum DraggableItemEvents {
	GrabItem = 'grabItem',
	ReorderItems = 'reorderItems',
}

type DraggableItemEmitters = EventEmitters<{
	[DraggableItemEvents.GrabItem]: (item: string | null) => void;
	[DraggableItemEvents.ReorderItems]: (items: Record<string, unknown>[]) => void;
}>;

type Context = {
	emit: DraggableItemEmitters;
};

const draggableItemController: SetupFunction<Props, View> = (props, { emit }: Context): View => {
	const itemRef = ref<HTMLDivElement | null>(null);
	const isGrabbed: ComputedRef<boolean> = computed(
		() => !!props?.item?.[props.itemKey] && props.grabbedItem === props?.item?.[props.itemKey]
	);
	const announcement = ref<string | undefined>(undefined);

	const translations = useTranslationsDao<{
		miscellaneous: {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			drag_and_drop: {
				label: () => string;
				grabbed: (params: TranslationsParam) => string;
				ungrabbed: () => string;
				moved: (params: TranslationsParam) => string;
			};
		};
	}>();

	watch(
		() => props.items,
		() => {
			if (isGrabbed.value && itemRef.value) itemRef.value.focus();
		}
	);

	const unsortableItem = props.lockFirstItem && props.index === 0;

	const toggleGrabbed = ($event: Event, forceUngrab = false) => {
		if (unsortableItem) return;

		if (forceUngrab) {
			emit(DraggableItemEvents.GrabItem, null);
			announcement.value = translations.miscellaneous.drag_and_drop.ungrabbed();
			return;
		}

		if ($event.type === 'keydown' && !isUndefined($event?.target)) {
			const targetElement = $event.target as HTMLElement;
			const isDraggableElement = targetElement?.classList.contains('draggable-item');

			if (!isDraggableElement) {
				return;
			}
		}

		$event.preventDefault();

		if (isGrabbed.value) {
			emit(DraggableItemEvents.GrabItem, null);
			announcement.value = translations.miscellaneous.drag_and_drop.ungrabbed();
		} else {
			emit(DraggableItemEvents.GrabItem, props.item?.[props.itemKey] ?? null);

			announcement.value = translations.miscellaneous.drag_and_drop.grabbed({ item: props.itemDescription ?? '' });
		}
	};

	const isValidMoveKey = (event: KeyboardEvent) => {
		const horizontalMoveKeys = ['ArrowLeft', 'ArrowRight'];
		const verticalMoveKeys = ['ArrowUp', 'ArrowDown'];

		const isKeyDown = event.type === 'keydown';
		const hasKey = !!event.key;
		const isHorizontalKey = props.dragDirection === 'horizontal' && horizontalMoveKeys.includes(event.key);
		const isVerticalKey = props.dragDirection === 'vertical' && verticalMoveKeys.includes(event.key);

		return !(isKeyDown && hasKey && (isHorizontalKey || isVerticalKey));
	};

	const moveItem = (event: KeyboardEvent) => {
		if (!isGrabbed.value || isValidMoveKey(event)) return;
		event.preventDefault();

		const direction = props.dragDirection;
		const moveDown = direction === 'vertical' ? event.key === 'ArrowDown' : event.key === 'ArrowRight';
		const targetIndex = moveDown ? props.index + 1 : props.index - 1;

		// Prevent moving the item out of bounds
		if (targetIndex < 0 || targetIndex >= props.items.length) return;
		// Prevent moving the item to the first position if lockFirstItem is true
		if (props.lockFirstItem && targetIndex === 0) return;

		const itemsCopy = [...props.items];
		const draggedItem = itemsCopy[props.index];

		if (draggedItem === undefined) return;

		itemsCopy.splice(props.index, 1);
		itemsCopy.splice(targetIndex, 0, draggedItem);

		emit(DraggableItemEvents.ReorderItems, itemsCopy);

		announcement.value = translations.miscellaneous.drag_and_drop.moved({
			item: props.itemDescription ?? '',
			position: targetIndex + 1,
		});
	};

	return {
		toggleGrabbed,
		moveItem,
		isGrabbed,
		itemRef,
		announcement,
		label: translations.miscellaneous.drag_and_drop.label() ?? null,
	};
};

export { Props, View, draggableItemController, DraggableItemEvents, DraggableItemEmitters };
