import { getNextFocusableElement } from '@/lib/focus-next-element';
import {
  changeFocusFlowForTabKey,
  changeFocusFlowForShiftTabKey,
  cleanUpEventListeners,
  focusElement,
  markFocusJumpHasOccurred,
  markFocusJumpHasNotOccurred
} from '@/lib/focus-flow-changer';

const LAST_FILTERTRON_BUTTON = '.search-form .tray-buttons .action-button:last-of-type';
const FILTERTRON_HEADING = '.filtertron-heading';
const ADVANCED_SEARCH_BUTTON = '.advanced-search';

export default {
  methods: {
    enableFocusFlowChanges() {
      this.focusFlowChangeIntoFiltertron();
      this.focusFlowChangeOutsideFiltertron();
    },
    disableFocusFlowChanges() {
      cleanUpEventListeners();
      markFocusJumpHasNotOccurred();
    },
    forceFiltertronFocus() {
      focusElement(FILTERTRON_HEADING);
      markFocusJumpHasOccurred();
    },

    /**
     * Handle focusing from elements conceptually BEFORE filtertron, into it.
     */
    focusFlowChangeIntoFiltertron() {
      let jumpFromElement = ADVANCED_SEARCH_BUTTON;
      let jumpTo = FILTERTRON_HEADING;

      // Tab
      changeFocusFlowForTabKey(jumpFromElement, jumpTo, true, false);

      // Shift + Tab
      changeFocusFlowForShiftTabKey(jumpTo, jumpFromElement, false, true);
    },
    /**
     * Handle focusing into elements conceptually AFTER filtertron, outside of it.
     */
    focusFlowChangeOutsideFiltertron() {
      const jumpFromElement = LAST_FILTERTRON_BUTTON;
      const jumpTo = getNextFocusableElement(document.querySelector(ADVANCED_SEARCH_BUTTON));

      // Tab
      changeFocusFlowForTabKey(jumpFromElement, jumpTo, false, true);

      // Shift + Tab
      changeFocusFlowForShiftTabKey(jumpTo, jumpFromElement, true, false);
    }
  }
};
