type Option = { [key: string | number]: string | number };

const trimOptionsKeys = (options: Option[]): { [key: string]: string | number }[] =>
	options.map((item) => ({ ...item, key: String(item.key).trim() }));

/**
 * Take an array of objects like this:
 *   [{ key: 1, score: 1}, { key: 3, score: 3}, { key: 2, score: 2}]
 *
 * And return a string like this preserving the order of the keys in the array:
 *   { "1": 1, "3": 3", "2": 2}
 */
export const mapOptionsOrdered = (options: Option[], key = 'key', value = 'value', defaultValue = 0) => {
	const ac: Option = {};
	const trimmedOptions = trimOptionsKeys(options);
	const orderedArray = trimmedOptions.map((item) => item[key]);

	return JSON.stringify(
		trimmedOptions.reduce((result, item) => {
			const k = item[key];
			result[k] = item[value] || defaultValue;

			return result;
		}, ac),
		orderedArray
	);
};
