<template>
  <div class="quick-comments">
    <div class="comments">
      <div class="comment-container">
        <comment-counter :comments="comments" @clicked="viewComments" />
        <div class="comment-new">
          <expandable-textarea
            v-model="comment"
            :placeholder="labels['placeholder']"
            @expanded="buttonVisible = true"
            @collapsed="buttonVisible = false"
          >
          </expandable-textarea>
          <input
            v-if="buttonVisible"
            type="submit"
            :disabled="buttonDisabled"
            :value="labels['button']"
            class="btn btn-secondary btn-sm"
            @click="saveComment"
          />
        </div>
      </div>
    </div>
    <comment-modal
      v-model="modalVisible"
      :title="labels['modalTitle']"
      :comments="comments"
    >
    </comment-modal>
  </div>
</template>

<script>
import ExpandableTextarea from '../Shared/ExpandableTextarea.vue';
import CommentCounter from '../Comments/CommentCounter.vue';
import CommentModal from '../Comments/CommentModal.vue';

const toastr = require('toastr');

/**
 * The QuickComments component.
 */
export default {
  components: {
    ExpandableTextarea,
    CommentCounter,
    CommentModal
  },
  props: {
    postUrl: {
      type: String,
      required: true
    },
    getUrl: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    },
    labels: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      comments: [],
      comment: '',
      modalVisible: false,
      buttonVisible: false,
      buttonDisabled: true
    };
  },
  watch: {
    comment() {
      this.buttonDisabled = !this.comment;
    }
  },
  created() {
    this.loadComments();
  },
  methods: {
    viewComments() {
      this.modalVisible = true;
    },
    loadComments() {
      this.$http.get(this.getUrl).then(
        response => {
          this.comments = response.data;
        },
        error => {
          toastr.error(
            error.response.data.message ||
              document.getElementById('alerts-generic').innerHTML
          );
        }
      );
    },
    saveComment() {
      this.buttonDisabled = true;

      this.$http
        .post(this.postUrl, {
          comment: this.comment,
          token: this.token
        })
        .then(
          () => {
            this.comment = '';
            this.loadComments();
          },
          error => {
            this.buttonDisabled = false;
            toastr.error(
              error.response.data.message ||
                document.getElementById('alerts-generic').innerHTML
            );
          }
        );
    }
  }
};
</script>
