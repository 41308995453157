import Vue from 'vue';
import store from '@/lib/store';
import TabList from './components/TabList';

export default function() {
  const tabList = new Vue({
    el: '#tab-list',
    name: 'TabListApp',
    components: {
      TabList
    },
    store
  });

  $(document).one('pjax:end', () => {
    tabList.$destroy();
  });
}
