import ShowHide from '../../Shared/ShowHide';

export default {
  components: {
    ShowHide
  },
  props: {
    urlUpdateVisibility: {
      type: String,
      default: null
    },
    hideText: {
      type: String,
      default: 'Hide'
    },
    showText: {
      type: String,
      default: 'Show'
    }
  }
};
