const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

const randomString = (length = 5) => {
  let id = '';

  for (let i = 0; i < length; i++) {
    id += validChars.charAt(Math.floor(Math.random() * validChars.length));
  }

  return id;
};

const generateRowId = (existingRows = []) => {
  let rowId;

  do {
    rowId = 'row-' + randomString();
  } while (existingRows.includes(rowId));

  return rowId;
};

const generateColumnId = (existingColumns = []) => {
  let columnId;

  do {
    columnId = 'column-' + randomString();
  } while (existingColumns.includes(columnId));

  return columnId;
};

export { validChars, randomString, generateColumnId, generateRowId };
