<template>
  <form-inviter
    :ids="ids"
    :chapters="chapters"
    :chapter="chapter"
    :categories="categories"
    :category="category"
    :roles="roles"
    :role="role"
    :message="message"
    :deadline="deadline"
    :labels="labels"
    :form-id="formId"
    :forms="forms"
    :user="user"
    name="form-inviter"
    method="POST"
    :route="route"
    :show-form-selector="showFormSelector"
    :invited-notifications="invitedNotifications"
    v-on="$listeners"
  ></form-inviter>
</template>

<script>
import FormInviter from '@/lib/components/ListActions/FormInviter';
import langMixin from '@/lib/components/Translations/mixins/lang-mixin';
import ListAction from '@/lib/components/ListActions/ListAction';

export default {
  components: {
    FormInviter
  },
  extends: ListAction,
  mixins: [langMixin],
  props: {
    ids: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      required: true,
      validator: value => ['form-inviter'].indexOf(value) !== -1
    },
    email: {
      type: String,
      default: null
    },
    roles: {
      type: Array,
      required: true
    },
    role: {
      type: String,
      default: null
    },
    chapters: {
      type: Array,
      required: true
    },
    chapter: {
      type: String,
      default: null
    },
    categories: {
      type: Array,
      required: true
    },
    category: {
      type: String,
      default: null
    },
    message: {
      type: String,
      default: ''
    },
    deadline: {
      type: String,
      default: ''
    },
    usersLimit: {
      type: Number,
      default: 255
    },
    usersTotal: {
      type: Number,
      default: 0
    },
    formId: {
      type: [Number, String],
      default: ''
    },
    forms: {
      type: Array,
      default: () => []
    },
    user: {
      type: Object,
      default: null
    },
    labels: {
      type: Object,
      default: () => ({})
    },
    route: {
      type: String,
      required: true
    },
    showFormSelector: {
      type: Boolean,
      required: true
    },
    invitedNotifications: {
      type: Array,
      default: () => []
    }
  }
};
</script>
