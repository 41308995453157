import integration from './integration';
import languages from './languages';
import registration from './registration';
import social from './social';
var domain = require('./domain.js');
var router = require('pjax').Router;

router.get('setting/domain', domain);
router.get('setting/integration', integration);
router.get('setting/api-v1', integration);
router.get('setting/registration', registration);
router.get('setting/social', social);
router.get('setting/languages', languages);
