<template>
  <div>
    <field-access-icon :field="field" :is-manager="isManager" />
    <div v-html="content"></div>
  </div>
</template>

<script>
import Field from './Field.vue';
import FieldAccessIcon from './FieldAccessIcon';

export default {
  components: {
    FieldAccessIcon
  },
  extends: Field,
  computed: {
    content() {
      return this.field.labelMarkdown
        ? this.field.labelMarkdown
        : this.field.title;
    }
  }
};
</script>
