<script>
import { Multicheckbox, Multiselect } from 'vue-bootstrap';
import RoundDates from '@/lib/components/Rounds/RoundDates';
import langMixin from '@/lib/components/Translations/mixins/lang-mixin.js';
import tabularMixin from '@/lib/components/Navigation/mixins/tabular-mixin.js';

export default {
  components: {
    Multicheckbox,
    Multiselect,
    RoundDates
  },
  mixins: [langMixin, tabularMixin],
  props: {
    type: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      roundType: this.type
    };
  }
};
</script>
