<template>
  <div v-if="total" class="progress mtm">
    <div
      class="progress-bar progress-bar-info"
      role="progressbar"
      aria-valuenow="30"
      aria-valuemin="0"
      aria-valuemax="100"
      :style="{ width: `${percentage}%` }"
    ></div>
    <div class="row">
      <div class="col-xs-6 col-left"><slot></slot></div>
      <div class="col-xs-6 col-right">
        {{ value }} / {{ total }} {{ units }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      required: true
    },
    value: {
      type: Number,
      required: true
    },
    units: {
      type: String,
      default: ''
    }
  },
  computed: {
    percentage() {
      return Math.min(100, Math.max(1, (100 * this.value) / this.total));
    }
  }
};
</script>
