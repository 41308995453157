<template>
  <div class="preview">
    <div class="icon">
      <i
        class="af-icons-md af-icons-md-circle-processing af-icons-animate-rotate"
      ></i>
      <br />
      <span class="transcoding-status">
        {{ lang.get('files.transcoding.labels.in_progress') }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['lang']
};
</script>
