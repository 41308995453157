<template>
	<a
		:id="id"
		:ref="'sub-menu-item' + item.name"
		:href="item.link"
		:target="item.target"
		:rel="item.target === '_blank' ? 'noopener noreferrer' : ''"
		:class="{ active: isActive }"
		:aria-current="ariaCurrent"
		@click="handleClick"
		@focus="$emit('focus', { item, event: $event })"
		@keydown.up.exact="$emit('focusPrevious', { event: $event })"
		@keydown.down.exact="$emit('focusNext', { event: $event })"
		@keydown.tab.exact="$emit('focusNext', { event: $event })"
		@keydown.shift.tab.exact="$emit('focusPrevious', { event: $event })"
	>
		<span class="elements-inner-wrapper" tabindex="-1">
			<!-- eslint-disable-next-line vue/no-v-html -->
			<span v-html="item.text"></span>
		</span>
	</a>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
	props: {
		item: {
			type: Object,
			required: true,
		},
		currentUrl: {
			type: String,
			default: null,
		},
		id: {
			type: String,
			default: null,
		},
		currentFocus: {
			type: Object,
			default: null,
		},
	},
	watch: {
		currentFocus(focusedItem) {
			if (focusedItem) {
				const element = this.$refs['sub-menu-item' + focusedItem.name];

				if (element) {
					element.focus();
				}
			}
		},
	},
	computed: {
		...mapGetters('global', ['historyBreadcrumb']),
		isActive() {
			if (this.item.type === 'link') {
				const url = this.getMenuUrl.replace(/\/?$/, '/'); // ensures that the url always ends with a single slash
				const itemLink = this.item.link.replace(/\/?$/, '/');
				return url.startsWith(itemLink);
			}

			return false;
		},
		ariaCurrent() {
			if (this.isActive) {
				return 'page';
			}

			return false;
		},
		getMenuUrl() {
			return this.historyBreadcrumb || this.currentUrl;
		},
	},
	methods: {
		...mapActions('menu', ['getMenuItemByName']),
		handleClick(event) {
			if (this.item.type === 'shortcut_link') {
				this.$emit('openShortcutMenu', this.item);
				event.preventDefault();
				return;
			}

			if (this.item.target === '_blank') {
				return;
			}

			event.preventDefault();

			this.$emit('itemSelected', this.item);
		},
	},
};
</script>
