import $ from 'jquery';
import Vue from 'vue';
import { vueData } from '@/domain/services/VueData';

const refreshCsrfToken = () => {
	const token = vueData.CSRF_TOKEN;

	if (!token) {
		throw new Error('CSRF token not injected.');
	}

	$.ajaxSetup({
		headers: { 'X-CSRF-TOKEN': token },
	});

	Vue.prototype.$http.defaults.headers.common['X-CSRF-TOKEN'] = token;

	return token;
};

export { refreshCsrfToken };
