<template>
	<dropdown
		:id="id"
		ref="dropdown"
		:button-class="buttonClass"
		:menu-class="menuClass"
		container-class="action-dropdown"
		:horizontal-position="position"
		menu-element="div"
		:action="action"
	>
		<span slot="label" class="action-dropdown-label">
			<slot name="label" v-bind="{ open, close }"> {{ label }} <span class="caret"></span> </slot>
		</span>

		<slot>
			<ul class="action-list">
				<li v-for="action in actions">
					<a class="dropdown-menu-item" style="text-overflow: ellipsis" :href="action.href">{{ action.name }}</a>
				</li>
			</ul>
		</slot>
	</dropdown>
</template>

<script>
import { Dropdown } from 'vue-bootstrap';

export default {
	components: {
		Dropdown,
	},
	props: {
		buttonClass: {
			type: String,
			default: 'btn btn-light btn-sm dropdown-toggle',
		},
		id: {
			type: String,
			default: 'action-dropdown',
		},
		label: {
			type: String,
			default: 'Action',
		},
		position: {
			type: String,
			default: 'left',
		},
		actions: {
			type: Array,
			default: () => [],
		},
		action: {
			type: String,
			default: null,
		},
		menuClass: {
			type: String,
			default: 'dropdown',
		},
	},
	methods: {
		close() {
			this.$refs.dropdown.close();
		},
		open() {
			this.$refs.dropdown.open();
		},
	},
};
</script>

<style scoped>
.action-dropdown-label {
	white-space: nowrap;
}
</style>
