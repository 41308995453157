import Guides from '@/lib/components/Holocron/Guides';
import { Router } from 'pjax';
import Tours from '@/lib/components/Holocron/Tours';
import Vue from 'vue';

Router.get('guides-and-tours', function () {
	let vueContainer = document.getElementById('guides-and-tours');
	if (vueContainer) {
		const store = require('@/lib/store/index').default;
		new Vue({
			el: vueContainer,
			name: 'GuidesAndToursApp',
			components: {
				Guides,
				Tours,
			},
			store,
		});
	}
});
