import $ from 'jquery';
import OpenRounds from '@/lib/open-rounds';
import ScoreSetSelector from '@/lib/components/Selectors/ScoreSetSelector.vue';
import store from '@/lib/store';
import Vue from 'vue';
export default function () {
	new OpenRounds();

	// Filtertron
	$('.filtertron').filtertron('entries', {
		expander: '#filtertronExpander',
	});

	const scoreSetSelector = new Vue({
		el: '#scoreSetSelector',
		components: {
			ScoreSetSelector,
		},
		store,
	});

	$(document).one('pjax:end', () => {
		scoreSetSelector.$destroy();
	});
}
