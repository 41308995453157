<template>
  <a
    ref="redirector"
    :href="url"
    @click="clicked($event)"
    @focusout="focusout($event)"
  >
    <slot></slot>
  </a>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
      description: 'Original url'
    },
    externalUrl: {
      type: String,
      required: true,
      description:
        'AwardForce redirector url, created using external_url php helper function'
    }
  },
  methods: {
    clicked(e) {
      const hyperlink = e.currentTarget;

      hyperlink.setAttribute('href', this.externalUrl);
      hyperlink.setAttribute('target', '_blank');
    },
    focusout(e) {
      const hyperlink = e.currentTarget;

      if (hyperlink.getAttribute('href') === this.externalUrl) {
        hyperlink.setAttribute('href', this.url);
        hyperlink.setAttribute('target', '');
      }
    }
  }
};
</script>
