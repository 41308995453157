import { mountVueComponent } from '@/lib/mount-vue-component';
import $ from 'jquery';

export default function() {
  // Sends a request to the server to generate a token and show the token immediately
  $('#generateToken').click(function() {
    $.post('/setting/generate-token', {}, function(token) {
      $('#apiToken').val(token);
    });
  });

  // helpIcon
  import('@/lib/components/Shared/HelpIcon.vue').then(HelpIcon => {
    mountVueComponent('help-icon', HelpIcon);
  });
}
