import { mountVueComponent } from '@/lib/mount-vue-component';

export default function() {
  import('@/lib/components/Clear/LoginOrRegister').then(LoginOrRegister => {
    mountVueComponent('login-or-register', LoginOrRegister, true);
  });
  import('@/lib/components/Shared/SimpleWidget').then(SimpleWidget => {
    mountVueComponent('simple-widget', SimpleWidget, true);
  });
}
