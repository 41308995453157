
import CommentActionButtons from '@/lib/components/Comments/CommentActionButtons';
import { commentController } from '@/lib/components/Comments/Comment.controller';
import CommentFiles from '@/lib/components/Comments/CommentFiles';
import { CommentView } from '@/lib/components/Comments/Comment.types';
import ConfirmationSimpleModal from '@/lib/components/Shared/ConfirmationSimpleModal';
import { defineComponent } from 'vue';
import TextEditor from '@/lib/components/Shared/editor/TextEditor.vue';
import Uploader from '@/lib/components/Uploader/Uploader';
import { useUploadProps } from '@/lib/components/Shared/editor/uploads/uploadsProps';

export default defineComponent({
	components: {
		Uploader,
		CommentActionButtons,
		CommentFiles,
		ConfirmationSimpleModal,
		TextEditor,
	},

	props: {
		...useUploadProps,
		comment: {
			type: Object,
			required: true,
		},
		token: {
			type: String,
			required: true,
		},
		readOnly: {
			type: Boolean,
			required: true,
		},
		deleteUrl: {
			type: String,
			required: true,
		},
		updateUrl: {
			type: String,
			required: true,
		},
		translations: {
			type: Object,
			default: () => {},
		},
	},

	setup: commentController as CommentView,
});
