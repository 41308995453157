<template>
  <div class="row pbl plm">
    <div class="toggle-container pull-left">
      <label :for="id" class="toggle-content-label" v-html="lang.get('fields.auto_score')" />
      <div class="toggle-content-switch">
        <toggle-switch
          :id="id"
          name="autoScoring"
          :on-label="lang.get('buttons.on')"
          :off-label="lang.get('buttons.off')"
          :use-inner-state="true"
          :checked="status"
          :aria-label="lang.get('fields.auto_score')"
          v-on="$listeners">
        </toggle-switch>
      </div>
    </div>
  </div>
</template>

<script>
import ToggleSwitch from '../../../lib/components/Shared/ToggleSwitch';

export default {
  components: { ToggleSwitch },
  inject: ['lang'],
  props: {
    status: {
      type: Boolean,
      required: true
    },
    id: {
      type: String,
      default: 'toggle-auto-scoring'
    }
  }
};
</script>
