<template>
	<panel panel-class="scroll-horizontally shadows">
		<table class="table-field table-field-input">
			<thead>
				<tr>
					<th></th>
					<th v-for="column in columns" :key="column" :class="{ 'text-right': isNumericColumn(column) }">
						{{ translatedColumnName(column) }}
					</th>
					<th v-if="dynamicRows.length"></th>
				</tr>
			</thead>
			<tbody :class="{ 'has-calculations': tableHasCalculations }">
				<tr v-for="(row, rowIndex) in allRows" :key="row">
					<td class="text-center">{{ rowIndex + 1 }}</td>
					<cell
						v-for="(column, columnIndex) in columns"
						:key="row + ':' + column"
						:column="column"
						:column-index="columnIndex"
						:disabled="disabled"
						:labels="labels"
						:last-column-index="columns.length - 1"
						:row="row"
						:row-index="rowIndex"
					>
					</cell>
					<td v-if="dynamicRows.length">
						<a v-if="dynamicRows.includes(row)" class="delete-row" href="javascript:void(0)" @click.prevent="deleteRow(row)">
							<span class="sr-only">{{ labels.deleteRow }}</span>
							<span class="af-icons af-icons-close-circle"></span>
						</a>
					</td>
				</tr>
			</tbody>
			<tfoot v-if="dynamicRowsEnabled || tableHasCalculations">
				<tr v-if="tableHasCalculations" class="row-calculation">
					<th></th>
					<th v-for="column in columns" :key="column" class="cell-calculation">
						<div v-if="columnHasCalculation(column)">
							<span>{{ translatedCalculationLabel(column) }}</span>
							<calculation :column="column" :values="getColumnValues(column)" />
						</div>
					</th>
					<th v-if="dynamicRows.length"></th>
				</tr>
				<tr v-if="dynamicRowsEnabled">
					<td></td>
					<td :colspan="columns.length">
						<a
							:class="['btn', 'btn-tertiary', 'btn-xs', 'add-row', { disabled: disabled }]"
							href="javascript:void(0)"
							@click.prevent="addDynamicRow"
						>
							{{ labels.addRow }}
						</a>
					</td>
					<th v-if="dynamicRows.length"></th>
				</tr>
			</tfoot>
		</table>
		<input :id="id" v-model="tableFieldInput" :name="name" type="hidden" />
	</panel>
</template>

<script>
import _ from 'underscore';
import { Panel } from 'vue-bootstrap';
import Cell from './TableFieldCell.vue';
import Calculation from './TableCalculation.vue';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { createStore } from '../../store/modules/table-field';
import filtersMixin from './mixins/table-field-filters-mixin.js';
import { getGlobal } from '@/lib/utils';

export default {
	components: {
		Panel,
		Cell,
		Calculation,
	},
	inject: ['lang'],
	mixins: [filtersMixin],
	props: {
		id: {
			type: String,
			required: true,
		},
		configuration: {
			type: Object,
			default: () => ({}),
		},
		configurationTranslated: {
			type: Object,
			default: () => ({}),
		},
		input: {
			type: Object,
			default: () => ({}),
		},
		name: {
			type: String,
			required: true,
		},
		labels: {
			type: Object,
			default: () => ({
				addRow: 'Add row',
				deleteRow: 'Delete Row',
			}),
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapState(['columns', 'rows', 'dynamicRowsEnabled', 'dynamicRows', 'translations']),
		...mapGetters([
			'getInput',
			'getTranslation',
			'getCalculationTranslation',
			'columnHasCalculation',
			'tableHasCalculations',
		]),
		tableFieldInput() {
			return JSON.stringify(this.getInput());
		},
		allRows() {
			return this.rows.concat(this.dynamicRows);
		},
	},
	watch: {
		tableFieldInput(newValue) {
			this.$emit('changed', newValue);
		},
		configuration() {
			this.setConfiguration(this.configuration);
		},
		configurationTranslated() {
			this.setTranslations(this.configurationTranslated);
		},
	},
	beforeCreate() {
		this.$store = createStore();
	},
	created() {
		this.setConfiguration(this.configuration);
		this.setTranslations(this.configurationTranslated);
		this.setInput(this.input);
	},
	methods: {
		...mapMutations(['setConfiguration', 'setTranslations', 'setInput', 'addDynamicRow', 'deleteDynamicRow']),
		translatedColumnName(column) {
			return (
				this.getTranslation(getGlobal('language'), column) || this.getTranslation(getGlobal('defaultLanguage'), column)
			);
		},
		translatedCalculationLabel(column) {
			return (
				this.getCalculationTranslation(getGlobal('language'), column) ||
				this.getCalculationTranslation(getGlobal('defaultLanguage'), column)
			);
		},
		deleteRow(row) {
			this.deleteDynamicRow(row);
			this.$emit('changed', JSON.stringify(this.getInput()));
		},
		getColumnValues(column) {
			let values = [];

			_.forEach(this.getInput().values || {}, (value) => {
				if (value[column]) {
					values.push(+value[column]);
				}
			});

			return values;
		},
	},
};
</script>
