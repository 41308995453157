var $ = require('jquery');

/**
 * The following is simply an extension of the jQuery:contains selector, allowing us to do case-insensitive searches.
 *
 * Taken from: http://stackoverflow.com/questions/2196641/how-do-i-make-jquery-contains-case-insensitive-including-jquery-1-8
 */
$.extend($.expr[':'], {
	contains: function (elem, i, match) {
		return (elem.textContent || elem.innerText || '').toLowerCase().indexOf((match[3] || '').toLowerCase()) >= 0;
	},
});
