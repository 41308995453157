<template>
  <div :class="['form-group', { error: validationError || errors.emails }]">
    <label for="emails" class="emails-label">
      {{ lang.get('users.form.email.label') }}
      <div v-if="usersLimit" class="label-counter">
        <div v-if="usersLeft >= 0">
          {{
            lang.get('validation.attributes.users-left', { left: usersLeft })
          }}
        </div>
        <div v-else class="alert-error inline">
          {{ lang.get('validation.attributes.users-too-many') }}
        </div>
      </div>
    </label>
    <textarea
      id="emails"
      v-model="currentValue"
      v-autogrow
      :min-height="5"
      :class="['form-control', 'ignore']"
      @input="onInput"
      @blur="blured = true"
      @focus="blured = false"
    ></textarea>
    <input
      v-for="(email, index) in emails"
      :key="'email-' + index"
      type="hidden"
      :value="email"
      :name="`emails[${index}]`"
    />
    <div v-if="validationError" class="help-text validation-error">
      {{
        lang.get('validation.multiple_emails', {
          attribute: lang.get('users.form.email.label')
        })
      }}
    </div>
    <div v-else class="help-text">
      {{ multipleEmailsHelp }}
    </div>
    <div
      v-for="(error, index) in errors.emails"
      :key="'error-' + index"
      class="alert-error inline"
      v-html="error"
    ></div>
    <slot></slot>
    <div v-if="showButton" class="mtm">
      <button class="btn btn-secondary" @click="$emit('add', emails)">
        {{ lang.get('buttons.add') }}
      </button>
    </div>
  </div>
</template>

<script>
import emailValidator from '@/lib/components/Fields/validator/email.js';
import { TextareaAutogrowDirective } from 'vue-textarea-autogrow-directive';

export default {
  name: 'EmailsBox',
  inject: ['lang'],
  directives: {
    autogrow: TextareaAutogrowDirective
  },
  props: {
    usersLimit: {
      type: Number,
      default: 0
    },
    usersTotal: {
      type: Number,
      default: 0
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    values: {
      type: Object,
      default: () => ({})
    },
    showButton: {
      type: Boolean,
      default: false
    },
    multipleEmailsHelpMessage: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      currentValue: Object.values(this.values || {}).join(','),
      blured: false,
      multipleEmailsHelp: this.multipleEmailsHelpMessage || this.lang.get('users.form.email.multiple_emails')
    };
  },
  computed: {
    entries() {
      return (
        this.currentValue
          // eslint-disable-next-line no-useless-escape
          .split(/[,;\:\s]/)
          .map(email => email.trim().toLowerCase())
          .filter(email => email.length)
      );
    },
    emails() {
      return [...new Set(this.entries.filter(email => emailValidator(email)))];
    },
    invalidEmails() {
      return [...new Set(this.entries.filter(email => !emailValidator(email)))];
    },
    usersLeft() {
      return this.usersLimit
        ? this.usersLimit - this.usersTotal - this.emails.length
        : 1;
    },
    validationError() {
      return (this.blured && this.invalidEmails.length) || this.usersLeft < 0;
    }
  },
  methods: {
    onInput() {
      this.$emit(
        'invalidate',
        this.invalidEmails.length === 0 &&
          this.emails.length > 0 &&
          this.usersLeft >= 0
      );
      this.$emit('change', this.emails);
    }
  }
};
</script>

<style scoped>
.emails-label {
  width: 100%;
}

.label-counter {
  float: right;
}
</style>
