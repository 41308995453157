
import CommentFiles from '@/lib/components/Comments/CommentFiles';
import ExpandableTextarea from '@/lib/components/Shared/ExpandableTextarea';
import FileUpload from '@/lib/components/Uploader/FileUpload';
import TextEditor from '@/lib/components/Shared/editor/TextEditor.vue';
import Uploader from '@/lib/components/Uploader/Uploader';
import { defineComponent } from 'vue';
import { useUploadProps } from '@/lib/components/Shared/editor/uploads/uploadsProps';
import { newCommentController } from '@/lib/components/Comments/NewComment.controller';
import { NewCommentView } from '@/lib/components/Comments/NewComment.types';

export default defineComponent({
	components: {
		CommentFiles,
		ExpandableTextarea,
		FileUpload,
		TextEditor,
		Uploader,
	},

	props: {
		...useUploadProps,

		createUrl: {
			type: String,
			required: true,
		},
		token: {
			type: String,
			required: true,
		},
		labels: {
			type: Object,
			required: true,
		},
		language: {
			type: String,
			default: 'en_GB',
		},
		userId: {
			type: Number,
			required: true,
		},
		translations: {
			type: Object,
			default: () => {},
		},
		createCommentPortalTarget: {
			type: [String, null],
			default: null,
		},
	},

	setup: newCommentController as NewCommentView,
});
