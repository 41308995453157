<template>
  <fieldset>
    <legend class="panel-title">{{ lang.get('home.register.language.label') }}</legend>
    <div v-for="language in languages" :key="value(language)" class="radio styled">
      <input
        :id="`lang_${value(language)}`"
        v-model="selectedLanguage"
        :value="value(language)"
        type="radio"
        name="language"
        @change="reload"
      />
      <label :for="`lang_${value(language)}`">
        {{ lang.get('languages.name.' + value(language), [], value(language)) }}
      </label>
    </div>
  </fieldset>
</template>

<script>
export default {
  inject: ['lang'],
  props: {
    languages: {
      type: [Array, Object],
      required: true
    },
    preferredLanguage: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedLanguage: null
    };
  },
  created() {
    this.selectedLanguage = this.preferredLanguage;
  },
  methods: {
    reload() {
      const url = new URL(location.href);
      url.searchParams.set('language', this.selectedLanguage);
      window.location.href = url.href;
    },
    value(language) {
      return typeof language === 'string' ? language : language.slug;
    }
  }
};
</script>

<style scoped>
fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

.panel-title {
  font-size: 14px;
}
</style>
