<template>
  <popover
    v-if="payload"
    ref="popoverComp"
    trigger="hover-click"
    placement="top"
    wrapper-tag="span"
    :content="tooltip"
    popover-wrap-class="interface-text-popover"
    @popover:enter="onPopoverEnter()"
  >
    <slot>
      <span v-if="!hasSlotElement" v-html="text" />
    </slot>
  </popover>
</template>

<script>
import { Popover } from 'vue-bootstrap';
import { applyMarkdownLinks } from '../simple-markdown-links.js';

export default {
  name: 'EditInterfaceText',
  components: { Popover },
  props: {
    payload: {
      type: String,
      default: null
    }
  },
  computed: {
    params() {
      return this.payload ? JSON.parse(atob(this.payload)) : {};
    },
    tooltip() {
      return this.url;
    },
    url() {
      // Inject "redirect back" url
      let url = this.params.tooltip;

      if (url && !url.includes('redirect=') && url.includes('selected=')) {
        let chunks = url.split('?selected=');

        url = chunks[0] + '?redirect=' + btoa(document.location) + '&selected=' + chunks[1];
      }

      return url;
    },
    text() {
      return applyMarkdownLinks(this.params.text);
    },
    hasSlotElement() {
      return this.$slots.default && !!this.$slots.default[0].tag;
    }
  },
  mounted() {
    // Change class name to avoid returning this element for later selector in jQuery
    this.$el.className = 'editable-interface-text';
  },
  methods: {
    onPopoverEnter() {
      if (this.slotContent().includes('dropdown-menu')) {
        return;
      }

      // closing all other open popovers
      this.$refs.popoverComp.popoverEntered = true;
      document.documentElement.click();
    },
    slotContent() {
      return this.$el.innerHTML;
    }
  }
};
</script>

<style scoped>
.edit-interface-text:hover,
.editable-interface-text:where(:hover, :focus, :focus-visible) {
  text-decoration: underline dotted;
  cursor: default;
}
.editable-interface-text .popover-button:focus-visible {
  text-decoration: underline dotted;
  cursor: default;
}
</style>
