var $ = require('jquery');

module.exports = {
	/**
	 * Enables the secondary checkbox when the primary checkbox is checked
	 */
	conditionalEnable: function (primaryCheckbox, secondaryCheckbox) {
		var checked = $(primaryCheckbox).prop('checked');

		if (!checked) {
			$(secondaryCheckbox).prop('checked', false).prop('disabled', true);
		}

		$(primaryCheckbox).on('click', function () {
			var checked = $(this).prop('checked');
			$(secondaryCheckbox).prop('disabled', !checked);
			if (!checked) {
				$(secondaryCheckbox).prop('checked', false);
			}
		});
	},

	/**
	 * Disables the secondary checkbox when the primary checkbox is checked
	 */
	conditionalDisable: function (primaryCheckbox, secondaryCheckbox) {
		var checked = $(primaryCheckbox).prop('checked');

		if (checked) {
			$(secondaryCheckbox).prop('checked', false).prop('disabled', true);
		}

		$(primaryCheckbox).on('click', function () {
			var checked = $(this).prop('checked');
			$(secondaryCheckbox).prop('disabled', checked);
			if (checked) {
				$(secondaryCheckbox).prop('checked', false);
			}
		});
	},
};
