import Vue from 'vue';
import { mountVueComponent } from '@/lib/mount-vue-component';
import { Multiselect } from 'vue-bootstrap';

export default function() {
  function displayFormElements(type) {
    $('.discount-type').addClass('hidden');
    $('.discount-type-' + type).removeClass('hidden');
  }

  $('select#type').on('change', function() {
    displayFormElements($(this).val());
  });

  displayFormElements($('select#type').val());

  new Vue({
    el: '#category-selector',
    name: 'CategorySelectorApp',
    components: {
      Multiselect
    }
  });

  // Whenever a category selection is made (aka, all, or select), we need to show the appropriate container
  var categorySelection = function() {
    var value = $('input[name=categoryOption]:checked').val();

    if (value == 'select') {
      $('#categorySelectionContainer').show();
    } else {
      $('#categorySelectionContainer').hide();
    }
  };

  $('input[name=categoryOption]').change(categorySelection);

  categorySelection();

  // helpIcon
  import('@/lib/components/Shared/HelpIcon.vue').then(HelpIcon => {
    mountVueComponent('help-icon', HelpIcon);
  });
}
