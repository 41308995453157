<template>
	<div v-click-outside="hideDropdown" class="account-switcher">
		<button
			ref="accountSwitcherButton"
			class="account-switcher-button"
			type="button"
			:aria-expanded="isDropdownVisible"
			aria-haspopup="true"
			@click="toggleDropdown"
			@keydown.esc.exact="hideDropdown"
			@keydown.down.exact.prevent="focusTo('searchInput')"
			@keydown.up.exact.prevent="focusTo('addAccountButton')"
		>
			<span class="account-switcher-button-text">{{ accountName }}</span>
			<span class="af-icons af-icons-chevron-down"></span>
		</button>
		<transition name="dropdown-fade">
			<div
				v-if="isDropdownVisible"
				ref="dropdown"
				class="account-switcher-dropdown"
				role="menu"
				aria-orientation="vertical"
				aria-labelledby="menu-button"
			>
				<div>
					<div class="account-switcher-searcher">
						<input
							ref="searchInput"
							v-model="filter"
							:placeholder="lang.get('menu.account_switcher.searcher.placeholder')"
							type="search"
							tabindex="0"
							:class="showTopShadow ? 'account-switcher-items-shadow-top' : 'account-switcher-items-no-shadow'"
							@keydown.esc.exact="hideDropdown"
							@keydown.up.exact.prevent="focusTo('addAccountButton')"
							@keydown.down.exact.prevent="focusToFirstAccountOrAddButton()"
							@keydown.tab.exact.prevent="focusToFirstAccountOrAddButton()"
							@keydown.enter.exact.prevent="filteredAccounts.length ? focusTo(filteredAccounts[0].id) : null"
						/>
					</div>

					<div>
						<div v-if="isLoading" class="account-switcher-loading">
							<i class="af-icons af-icons-repeat af-icons-animate-rotate"></i>
							{{ lang.get('menu.account_switcher.loading') }}
						</div>

						<div v-if="!isLoading && !filteredAccounts.length" class="account-switcher-no-account-found">
							<span>{{ lang.get('menu.account_switcher.no_results') }}</span>
						</div>

						<div
							v-if="!isLoading && filteredAccounts.length"
							ref="accountSwitcherItems"
							class="account-switcher-items"
							@scroll="handleScroll"
						>
							<div v-for="(account, index) in filteredAccounts" :key="account.id + '_' + index">
								<button
									:ref="account.id"
									role="menuitem"
									class="account-switcher-result-item"
									@click="selectAccount(account.id)"
									@keydown.esc.exact="hideDropdown"
									@keydown.down.exact.prevent="focusNext(account.id)"
									@keydown.up.exact.prevent="focusPrevious(account.id)"
									@keydown.tab.exact.prevent="focusNext(account.id)"
									@keydown.shift.tab.exact.prevent="focusPrevious(account.id)"
								>
									<account-switcher-icon :account="account"></account-switcher-icon>
									{{ account.name }}
								</button>
							</div>
						</div>
					</div>
					<div
						v-if="isOwnerOrProgramManager"
						class="account-switcher-add-account"
						:class="showBottomShadow ? 'account-switcher-items-shadow-bottom' : 'account-switcher-items-no-shadow'"
					>
						<button
							ref="addAccountButton"
							tabindex="0"
							@keydown.esc.exact="hideDropdown"
							@keydown.up.exact.prevent="focusLastAccount"
							@keydown.down.exact.prevent="focusTo('searchInput')"
							@keydown.tab.exact.prevent="focusTo('searchInput')"
							@keydown.shift.tab.exact.prevent="focusLastAccount"
							@click="addAccount"
						>
							<span class="af-icons af-icons-add"></span>
							{{ lang.get('menu.account_switcher.add_account') }}
						</button>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import langMixin from '@/lib/components/Translations/mixins/lang-mixin.js';
import AccountSwitcherIcon from '@/lib/components/Navigation/AccountSwitcherIcon.vue';

export default {
	name: 'AccountSwitcher',
	components: { AccountSwitcherIcon },
	mixins: [langMixin],
	props: {
		accountName: {
			type: String,
			required: true,
		},
		isOwnerOrProgramManager: {
			type: [String, Boolean],
			default: false,
		},
		addAccountLink: {
			type: String,
			required: true,
		},
		userEmail: {
			type: String,
			required: true,
		},
		accountGlobalId: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			accounts: null,
			isLoading: false,
			isDropdownVisible: false,
			filter: null,
			scrollablePosition: 0,
		};
	},
	computed: {
		parsedAccounts() {
			return this.accounts ? [...this.accounts].sort((a, b) => a.name.localeCompare(b.name)) : [];
		},
		filteredAccounts() {
			return this.filter
				? this.parsedAccounts.filter((account) => account.name.toLowerCase().includes(this.filter.toLowerCase()))
				: this.parsedAccounts;
		},
		scrollableSwitcher() {
			return this.accounts ? this.accounts.length > 10 : false;
		},
		showTopShadow() {
			return this.scrollableSwitcher && this.scrollablePosition > 0;
		},
		showBottomShadow() {
			if (this.filteredAccounts.length < 10) {
				return false;
			}

			if (!this.scrollableSwitcher) {
				return false;
			}

			if (this.scrollablePosition === 0) {
				return true;
			}

			const offsetHeight = this.$refs.accountSwitcherItems.offsetHeight;
			const scrollHeight = this.$refs.accountSwitcherItems.scrollHeight;

			return this.scrollablePosition + offsetHeight + 1 < scrollHeight;
		},
	},
	methods: {
		selectAccount(id) {
			window.location.href = '/auth/account/' + id;
		},
		addAccount() {
			const url = new URL(this.addAccountLink);
			url.searchParams.append('prefill_account_global_id', this.accountGlobalId);
			url.searchParams.append('prefill_requester', this.userEmail);

			window.open(url.href, '_blank');

			this.hideDropdown();
		},
		toggleDropdown() {
			// eslint-disable-next-line
			this.isDropdownVisible ? this.hideDropdown() : this.openDropdown();
		},
		async openDropdown() {
			if (this.isDropdownVisible) {
				return;
			}

			this.isDropdownVisible = true;
			this.isLoading = true;

			const response = await this.$http.get('/auth/accounts');

			this.accounts = response.data;

			this.isLoading = false;

			this.focusTo('searchInput');
		},
		hideDropdown() {
			if (this.isDropdownVisible) {
				this.isDropdownVisible = false;
				this.$refs.accountSwitcherButton.focus();
			}
		},
		focusPrevious(currentId) {
			const previousIndex = this.filteredAccounts.findIndex((account) => account.id === currentId) - 1;

			if (previousIndex < 0) {
				this.focusTo('searchInput');
				return;
			}

			this.focusTo(this.filteredAccounts[previousIndex].id);
		},
		focusNext(currentId) {
			const nextIndex = this.filteredAccounts.findIndex((account) => account.id === currentId) + 1;

			if (nextIndex >= this.filteredAccounts.length) {
				return this.focusTo('addAccountButton');
			}

			this.focusTo(this.filteredAccounts[nextIndex].id);
		},
		focusTo(elementRef) {
			if (!this.isDropdownVisible) {
				this.openDropdown();
			}

			const element = this.$refs[elementRef];

			if (Array.isArray(element)) {
				element[0].focus();
				return;
			}

			if (element) {
				element.focus();
			}
		},
		focusLastAccount() {
			const lastIndex = this.filteredAccounts.length;
			const lastAccount = this.filteredAccounts[lastIndex - 1];

			if (!lastAccount) {
				return this.focusTo('searchInput');
			}

			this.focusTo(lastAccount.id);
		},
		focusToFirstAccountOrAddButton() {
			this.focusTo(this.filteredAccounts.length ? this.filteredAccounts[0].id : 'addAccountButton');
		},
		handleScroll(e) {
			this.scrollablePosition = e.target.scrollTop;
		},
	},
};
</script>

<style scoped>
.dropdown-fade-enter-active,
.dropdown-fade-leave-active {
	transition: all 0.1s ease-out;
}
.dropdown-fade-enter,
.dropdown-fade-leave-to {
	opacity: 0;
	transform: translateY(-12px);
}
</style>
