var Vue = require('vue');
var RandomAssignments = require('./components/RandomAssignments.vue').default;
var RandomAssignmentsSummary = require('./components/RandomAssignmentsSummary.vue').default;

module.exports = function () {
  new Vue({
    el: '#random-assignments',
    name: 'RandomAssignmentsApp',
    components: {
      'random-assignments': RandomAssignments,
      'random-assignments-summary': RandomAssignmentsSummary
    }
  });
};
