import InlineCurrencyField from '@/lib/components/Fields/InlineCurrencyField.vue';
import { mountVueComponentFromElement } from '@/lib/mount-vue-component';
/* eslint-disable @typescript-eslint/naming-convention */
let $ = require('jquery');
let tectoastr = require('tectoastr');
let autosize = require('../vendor/autosize.js');
let config = require('@/legacy/pjax-module.js').Config;
let datePicker = require('./datepicker.js');
let tagsInput = require('./tags-input.js');
let initAlertValidation = require('@/common/alert-validation.js').init;
let initSixDigitCode = require('@/common/six-digit-code.js').default;
let initVue = require('@/common/vue-fields.js').default;
const { getGlobal } = require('@/lib/utils');
/* eslint-enable @typescript-eslint/naming-convention */

/**
 * The PJAX module manages the functionality specific to the PJAX bindings and loader
 * management (aka, the cool animations and loader shown during page transitions).
 */

config.set('matchBehaviour', 'all');

// The long timeout is to ensure that we don't get weird cancellation effects for
// semi-long requests (a few hundred ms). Also helps with slow connections.
// jquery-pjax responses will come up "cancelled" if they time out.
let timeout = 40000;
let pjaxContainer = '#pjaxContainer';
let loader = $('#loader');
let body = $('body');
let fatalError = false;
let cancelled = false;

let originalDefaults = $.pjax.defaults;
$.pjax.defaults = {};
$.pjax.defaults.timeout = timeout;
$.pjax.defaults.container = pjaxContainer;
$.pjax.defaults.push = true;
$.pjax.defaults.maxCacheLength = 0;
$.pjax.defaults.version = originalDefaults.version;

let submissionCallback = function (event) {
	$.pjax.submit(event, pjaxContainer);
};

let showLoader = function (event) {
	return event.relatedTarget ? event.relatedTarget.classList.contains('no-loader') : false;
};

let datePickerCallback = function () {
	datePicker.setup($('.date, .datetime, .time'));
};

let tagsInputCallback = function () {
	tagsInput.setup($('.tags-input'));
};

let pageTitleCallback = function () {
	let newTitle = $('#content').find('div[data-title]').first();

	if (newTitle) {
		document.title = newTitle.data('title');
	}
};

let alertValidationCallback = function () {
	initAlertValidation();
};

let sixDigitCodeCallback = function () {
	initSixDigitCode();
};

let initVueFieldsCallback = function () {
	initVue();
};

let interfaceTextPopoversCallback = function () {
	require('../modules/interface-text/interface-text-popovers.js').default.init();
};

let pageLoaderCallback = function (event) {
	if (!showLoader(event)) {
		document.title = 'Loading...';
	}
};

let addThisCallback = function () {
	// The refresh function is only available if there is at least
	// one website tool enabled in the AddThis UI:
	//
	// https://www.addthis.com/dashboard#gallery/pub/<addthis-code>
	if (
		window.addthis &&
		window.addthis.layers &&
		window.addthis.layers.refresh &&
		typeof window.addthis.layers.refresh === 'function'
	) {
		window.addthis.layers.refresh();
	}
};

let filtertronTrayCallback = function () {
	body.pjax('.tray-content a', pjaxContainer);

	$('.tray-content').on('submit', 'form:not(.ignore)', function (event) {
		submissionCallback(event);
	});
};

const currencyFieldInputCallback = () =>
	document
		.querySelectorAll('.currency-field')
		.forEach((currencyField, index) =>
			mountVueComponentFromElement(currencyField, InlineCurrencyField, `CurrencyInputApp${index}`, true, false)
		);

let preserveScrollPosition = function (event, options) {
	return (
		(options && options.push && options.preserveScrollPosition) ||
		(event && event.relatedTarget && $(event.relatedTarget).hasClass('preserve-scroll-position'))
	);
};

let setFocus = function (event, xhr, options) {
	if (preserveScrollPosition(event, options)) {
		return;
	}

	let cookieNotice = $('#cookieNoticeId');
	let heading = cookieNotice.find('.h1').first();

	// Find h3 only for entrant home and judge dashboard content blocks
	let contentBlockHeading = $(
		'#content .entrant-page .entry-content-block h3, #content .judge-dashboard-page .entry-content-block h3'
	).first();

	// Cookie banner
	if (cookieNotice.attr('data-show-notice') === '1' && heading.length) {
		heading.attr('tabindex', '-1').focus();
		return;
	}

	// If an h3 is found within a content block, focus on it
	if (contentBlockHeading.length) {
		contentBlockHeading.attr('tabindex', '-1').focus();
		return;
	}

	heading = $('#content').find('h1.sr-only').first();

	if (!heading.length) {
		heading = $('#content').find('.breadcrumbs-list').find('h1').first();
	}

	if (heading.length) {
		heading.attr('tabindex', '-1').focus();
		return;
	}

	// Find h1 rendered in vue templates
	heading = $('#content').find('.selector-title').find('h1').first();

	if (!heading.length) {
		heading = $('#content').find('h1').first();
		heading.attr('tabindex', '-1');
		heading.focus();
		return;
	}

	// If found heading in vue templates without tabindex (first call of this function),
	// add tabindex and call the function again with a delay, as directly focusing the h1 here
	// will not trigger the screen reader.
	if (!heading.attr('tabindex')) {
		heading.attr('tabindex', '-1');
		return setTimeout(setFocus, 50);
	}

	// Set focus after delayed recursive call
	heading.focus();
};

// Setup our required PJAX handlers and listeners
body.pjax(getGlobal('pjaxSelector')?.clarify(), pjaxContainer);

$('#content, .tray-content').on('submit', 'form:not(.ignore)', function (event) {
	submissionCallback(event);
});

body.on('pjax:end', filtertronTrayCallback);

// Just in case the load time is really fast, we don't bother showing the loader.
body.on('pjax:send', function (event) {
	if (!showLoader(event)) {
		loader.delay(150).show().fadeTo(250, 0.8);
	}
});

body.on('pjax:hide', function () {
	loader.hide();
});

// Whenever the loading is complete, we then want to hide the loading animation
body.on('pjax:end', function (event, xhr, options) {
	if (fatalError) {
		fatalError = false;
		return;
	} else if (cancelled) {
		// Hide loader without delaying and reset flag
		loader.hide();
		cancelled = false;
		return;
	}

	loader.fadeTo(250, 0, function () {
		$(this).hide();
	});

	// Send user to the top of the page when following pjax links.
	// Preserve the scroll position when using Back/Forward buttons.
	if (!preserveScrollPosition(event, options)) {
		$('html, body').animate({ scrollTop: 0 }, 250);
	}

	autosize($('textarea:not(.ignore)'));
});

// Add callbacks to the pjax:end event
body.on('pjax:end', pageTitleCallback);
body.on('pjax:end', datePickerCallback);
body.on('pjax:end', tagsInputCallback);
body.on('pjax:end', addThisCallback);
body.on('pjax:end', sixDigitCodeCallback);
body.on('pjax:end', initVueFieldsCallback);
body.on('pjax:end', interfaceTextPopoversCallback);
body.on('pjax:end', currencyFieldInputCallback);
body.on('pjax:end', setFocus);
body.on('pjax:end', alertValidationCallback);

body.on('pjax:send', pageLoaderCallback);

body.on('pjax:error', function (event, response) {
	const previewModeError = response.responseJSON !== undefined && response.responseJSON.type === 'preview-mode';
	// Catch response codes we want to allow PJAX to redirect directly to.
	switch (response.status) {
		case 401 && !previewModeError:
		case 403:
		case 404:
		case 423:
			fatalError = true;
			return;
	}

	// Catch cancelled requests
	if (response.statusText === 'abort') {
		cancelled = true;
		return;
	} else if (response.statusText === 'timeout') {
		// Handle timeouts
		tectoastr.warning($('#lang-strings #alerts-timeout').text());
	} else if (previewModeError) {
		// Handle preview mode
		tectoastr.error($('#lang-strings #alerts-preview-mode').text());
	} else if (response.status === 503) {
		// maintenance mode
		$('#maintenance-modal').modal('show');
	} else {
		// Handle other errors
		if (response.status >= 500) {
			tectoastr.error($('#lang-strings #alerts-generic').text() + ' [' + response.status + ']');
		}

		throw new Error('Oops! [' + event.currentTarget.baseURI + '] [' + response.status + ']', { cause: event });
	}

	window.history.back();
	event.preventDefault();
});

autosize($('textarea:not(.ignore)'));

datePickerCallback();
tagsInputCallback();
currencyFieldInputCallback();
initVue();
setFocus();
