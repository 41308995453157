<template>
	<form
		ref="form"
		:class="['vertical', 'scoring-criteria-form', { 'scoring-criteria-form-new': !exists }]"
		@submit.stop.prevent="submitForm"
	>
		<validation-errors></validation-errors>
		<scoring-criterion-form
			id="scoring-criterion-form"
			:criterion="criterion"
			:categories="categories"
			:score-sets="scoreSets"
			:fields="fields"
			:form="form"
			:supported-languages="supportedLanguages"
			@input="handleInput"
			@multilingualInput="handleInput"
		></scoring-criterion-form>
		<div class="form-actions">
			<button type="submit" class="btn btn-primary btn-lg" :disabled="!canSave">
				{{ lang.get('buttons.save') }}
			</button>
			<a :href="cancelUrl" class="btn btn-tertiary btn-lg">
				{{ lang.get('buttons.cancel') }}
			</a>
		</div>
	</form>
</template>

<script>
import ScoringCriterionForm from './ScoringCriterionForm';
import langMixin from '@/lib/components/Translations/mixins/lang-mixin';
import { mapMutations } from 'vuex';
import ValidationErrors from '@/lib/components/Shared/ValidationErrors';

export default {
	components: { ValidationErrors, ScoringCriterionForm },
	mixins: [langMixin],
	props: {
		scoreSets: {
			type: Array,
			required: true,
		},
		categories: {
			type: Array,
			required: true,
		},
		criterion: {
			type: Object,
			required: true,
		},
		supportedLanguages: {
			type: Array,
			required: true,
		},
		fields: {
			type: Array,
			required: true,
		},
		formUrl: {
			type: String,
			required: true,
		},
		cancelUrl: {
			type: String,
			required: true,
		},
		form: {
			type: Object,
			default: null,
		},
		canSave: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		exists() {
			return this.criterion.id > 0;
		},
		formData() {
			return Object.fromEntries(new FormData(this.$refs.form));
		},
	},
	beforeDestroy() {
		this.resetValidationErrors();
	},
	methods: {
		...mapMutations('validation', ['resetValidationErrors', 'clearValidationError']),
		submitForm() {
			let formData = new FormData(this.$refs.form);
			const categories = this.$refs.form.querySelectorAll("input[name='categories[]']");

			categories.forEach((category) => {
				if (category.checked) {
					formData.append('categories[' + category.value + ']', category.value);
				}
			});

			this.$http.request({
				url: this.formUrl,
				method: this.exists ? 'put' : 'post',
				data: new URLSearchParams(Object.fromEntries(formData)).toString(),
			});

			return true;
		},
		handleInput(key) {
			this.clearValidationError(key);
		},
	},
};
</script>
