import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import AttachFile from '@/lib/components/Shared/editor/ckeditor/plugins/attach-file';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Code from '@ckeditor/ckeditor5-basic-styles/src/code';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import ImageResizeHandles from '@ckeditor/ckeditor5-image/src/imageresize/imageresizehandles';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Link from '@ckeditor/ckeditor5-link/src/link';
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';
// import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar'; // Problematic as of March 2023
import List from '@ckeditor/ckeditor5-list/src/list';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript';
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';

import { ToolbarItem } from '@/lib/components/Shared/editor/ckeditor/toolbar/buttons';

const plugins = [
	Alignment,
	AttachFile,
	Autoformat,
	BlockQuote,
	Bold,
	Code,
	Essentials,
	Heading,
	Image,
	ImageCaption,
	ImageResize,
	ImageResizeHandles,
	ImageStyle,
	// ImageToolbar,
	Indent,
	IndentBlock,
	Italic,
	Link,
	LinkImage,
	List,
	MediaEmbed,
	Paragraph,
	Strikethrough,
	Subscript,
	Superscript,
	Table,
	TableToolbar,
	Underline,
];

const ckeditorConfig = ({ toolbar }: { toolbar: ToolbarItem[] }) => ({
	plugins,
	toolbar,
	link: {
		addTargetToExternalLinks: true, // Auto add target="_blank" and rel="noopener noreferrer" to external links
	},
	mediaEmbed: {
		previewsInData: true,
	},
});

export { ckeditorConfig };
