<template>
	<div
		v-if="visible"
		ref="box"
		aria-live="polite"
		aria-atomic="true"
		:aria-expanded="isCollapsed ? 'false' : 'true'"
		:class="['row', 'feature-box-wrapper', isCollapsed ? 'collapsed' : 'open']"
	>
		<!-- Video (or image) column -->
		<feature-box-video-or-image
			ref="videoWrapper"
			:show-video="showImageOrVideo"
			:image-url="imageUrl"
			:image-big-url="imageBigUrl"
			:video-url="videoUrl"
		/>

		<!-- Description column -->
		<div
			:class="[
				'col-xs-9',
				'col-sm-' + (showImageOrVideo ? 6 : 10),
				'col-md-' + (showImageOrVideo ? 6 : 10),
				'col-lg-' + (showImageOrVideo ? 7 : 10),
				'col-box-content',
			]"
		>
			<h2>{{ heading }}</h2>
			<div v-if="!isCollapsed">
				<div v-html="content"></div>
				<slot name="post-middle-col" />
			</div>
		</div>

		<!-- Extra column slot, by default "Show/Hide" button column -->
		<slot v-if="useShowHide && urlUpdateVisibility" name="show-hide">
			<div ref="showHide" :class="['col-xs-2', 'col-sm-2', 'col-md-2', 'col-lg-2', 'col-show-hide']">
				<show-hide
					:url="urlUpdateVisibility"
					:collapsed="isCollapsed"
					:show-text="showText"
					:hide-text="hideText"
					@change="onShowHide"
				></show-hide>
			</div>
		</slot>
	</div>
</template>

<script>
import ShowHide from '../Shared/ShowHide';
import showHideMixin from './mixins/show-hide-mixin.js';
import FeatureBoxVideoOrImage from './FeatureBoxVideoOrImage';

export default {
	components: { FeatureBoxVideoOrImage, ShowHide },
	mixins: [showHideMixin],
	props: {
		content: {
			type: String,
			default: null,
		},
		heading: {
			type: String,
			default: null,
		},
		imageUrl: {
			type: String,
			default: null,
		},
		imageBigUrl: {
			type: String,
			default: null,
		},
		initCollapsed: {
			type: Boolean,
			default: false,
		},
		useShowHide: {
			type: Boolean,
			default: false,
		},
		videoUrl: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			visible: true,
			isCollapsed: this.initCollapsed,
		};
	},
	computed: {
		chevronClass() {
			return 'af-icons-chevron-' + this.isCollapsed ? 'up' : 'down';
		},
		openClosedClass() {
			return this.isCollapsed ? 'closed' : 'open';
		},
		showImageOrVideo() {
			return !this.isCollapsed && (this.imageUrl || this.videoUrl);
		},
	},
	beforeDestroy() {
		this.destroyIframe();
	},
	methods: {
		onShowHide(isNowCollapsed) {
			if (isNowCollapsed) {
				this.destroyIframe();
			}

			this.isCollapsed = isNowCollapsed;
			this.collapseStateChanged(this.isCollapsed);
		},
		collapseStateChanged(collapsed) {
			throw new Error('Not implemented');
		},
		destroyIframe() {
			if (this.$refs.videoWrapper && this.$refs.videoWrapper.$refs.video) {
				this.$refs.videoWrapper.destroy();
			}
		},
		updateSize() {
			if (this.$refs.videoWrapper) {
				this.$refs.videoWrapper.updateSize();
			}
		},
	},
};
</script>
