import Vue from 'vue';
import store from '../../lib/store';
import RoundConfiguration from './components/RoundConfiguration';

export default function () {
  const roundConfigurationEl = document.getElementById('round-configuration');

  if (roundConfigurationEl) {
    const roundConfiguration = new Vue({
      el: roundConfigurationEl,
      name: 'RoundConfigurationApp',
      components: {
        RoundConfiguration
      },
      store
    });

    $(document).one('pjax:end', () => {
      roundConfiguration.$destroy();
    });
  }
};
