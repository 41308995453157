var Base64 = require('./base64.js');

/**
 * A companion to clarify.js
 */
var obfuscate = function(input) {
    return Base64.encode(JSON.stringify(input));
}

module.exports = obfuscate;
