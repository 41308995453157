import { isNotVideo, videoLengthComparison } from './videoUtils';

const videoLengthIsExceedingLimit = (maxVideoLength, file) =>
	videoLengthComparison(maxVideoLength, file, (duration, limit) => duration > limit);

/**
 * A 'max_video_length' filter for Plupload.
 *
 * How to use:
 * https://www.plupload.com/docs/v2/File-Filters
 */
export default async function (maxVideoLength, file, cb) {
	if (isNotVideo(file) || !(await videoLengthIsExceedingLimit(maxVideoLength, file))) {
		// eslint-disable-next-line standard/no-callback-literal
		cb(true);
		return;
	}

	this.trigger('Error', {
		code: -604,
		file: file,
	});

	// eslint-disable-next-line standard/no-callback-literal
	cb(false);
}
