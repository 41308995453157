<template>
	<component :is="component" class="comment-counter" @click="onClick">
		<i class="af-icons af-icons-comment"></i>
		<span class="comment-count">{{ comments.length }}</span>
	</component>
</template>

<script>
/**
 * The CommentCounter component.
 */
export default {
	props: {
		comments: {
			type: Array,
			required: true,
		},
	},
	computed: {
		component() {
			return this.comments.length ? 'a' : 'span';
		},
	},
	methods: {
		onClick() {
			if (this.comments.length) {
				this.$emit('clicked');
			}
		},
	},
};
</script>
