<template>
  <div :style="dimensions">
    <canvas ref="chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js';

Chart.defaults.global.responsive = true;
Chart.defaults.global.maintainAspectRatio = false;

export default {
  props: {
    type: {
      type: String,
      default: 'pie'
    },
    width: {
      type: Number,
      default: 150
    },
    height: {
      type: Number,
      default: 150
    },
    options: {
      type: Object,
      default: () => ({
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        }
      })
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      chart: null
    };
  },
  computed: {
    dimensions() {
      return {
        width: this.width ? this.width + 'px' : false,
        height: this.height ? this.height + 'px' : false
      };
    }
  },
  mounted() {
    const ctx = this.$refs['chart'].getContext('2d');

    this.chart = new Chart(ctx, {
      type: this.type,
      options: this.options,
      data: this.data
    });
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
  }
};
</script>
