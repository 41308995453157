/* eslint-disable @typescript-eslint/naming-convention */
var Chart = require('chart.js');
const Vue = require('vue');
const SavedViewsShortcutsBar = require('./../search/components/SavedViewsShortcutsBar').default;
const store = require('@/lib/store').default;
/* eslint-enable @typescript-eslint/naming-convention */

Chart.defaults.global.responsive = true;
Chart.defaults.global.maintainAspectRatio = false;

module.exports = function () {
	var entryVolumeChart, entryCompletionChart;

	var getContext = function (id) {
		var element = document.getElementById(id);

		if (element) {
			return element.getContext('2d');
		}
	};

	var setupEntryVolumeChart = function () {
		var data, ctx;

		/* eslint-disable no-undef */
		if (typeof entryVolumeChartData !== 'undefined') {
			data = JSON.parse(entryVolumeChartData.clarify());
		}
		/* eslint-enable no-undef */

		ctx = getContext('entryVolumeChart');

		if (data && ctx) {
			return new Chart(ctx, {
				type: 'line',
				data: data,
				options: {
					elements: {
						line: {
							tension: 0,
						},
					},
					legend: {
						display: false,
					},
					tooltips: {
						intersect: false,
						mode: 'index',
					},
					scales: {
						xAxes: [
							{
								gridLines: {
									display: false,
								},
								ticks: {
									maxTicksLimit: 20,
								},
							},
						],
						yAxes: [
							{
								ticks: {
									beginAtZero: true,
									precision: 0,
								},
							},
						],
					},
				},
			});
		}
	};

	var setupEntryCompletionChart = function () {
		var data, ctx;

		/* eslint-disable no-undef */
		if (typeof entryCompletionChartData !== 'undefined') {
			data = JSON.parse(entryCompletionChartData.clarify());
		}
		/* eslint-enable no-undef */

		ctx = getContext('entryCompletionChart');

		if (data && ctx) {
			return new Chart(ctx, {
				type: 'pie',
				data: data,
				options: {
					legend: {
						display: false,
					},
					tooltips: {
						intersect: false,
						mode: 'index',
					},
				},
			});
		}
	};

	entryVolumeChart = setupEntryVolumeChart();
	entryCompletionChart = setupEntryCompletionChart();

	$(document).one('pjax:end', function () {
		if (entryVolumeChart) {
			entryVolumeChart.destroy();
		}

		if (entryCompletionChart) {
			entryCompletionChart.destroy();
		}
	});

	const savedViewsShortcutsBar = document.getElementById('saved-views-shortcuts-bar');
	if (savedViewsShortcutsBar) {
		new Vue({
			el: savedViewsShortcutsBar,
			name: 'SavedViewsShortcutsBarApp',
			components: {
				'saved-views-shortcuts-bar': SavedViewsShortcutsBar,
			},
			store,
		});
	}
};
