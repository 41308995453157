/* eslint-disable @typescript-eslint/naming-convention */
import EditInterfaceText from './components/EditInterfaceText.vue';
import Vue from 'vue';
/* eslint-enable @typescript-eslint/naming-convention */

/**
 * Initialize overridable interface text popovers, which are Vue components.
 *
 * Notice:
 * If the component had a Vue parent in DOM, it has been already initialized.
 * If the component didn't have Vue parent, instantiate them now.
 */
export default {
	init: function () {
		// Timeout is needed to avoid trying to initialize the component twice - first in parent component,
		// second as a root component.
		setTimeout(() => {
			Array.from(document.getElementsByTagName('edit-interface-text')).forEach((editInterfaceText) => {
				new Vue({
					el: editInterfaceText,
					name: 'InterfaceTextPopoverApp',
					components: {
						'edit-interface-text': EditInterfaceText,
					},
				});
			});
		}, 100);
	},
};
