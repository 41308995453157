import Vue from 'vue';
import OrderNotes from './components/OrderNotes';
import OrderBillingDetails from './components/OrderBillingDetails';
import { mountVueComponent } from '@/lib/mount-vue-component';

export default function() {
  new Vue({
    el: '#order-billing-details',
    name: 'OrderBillingDetailsApp',
    components: {
      'order-billing-details': OrderBillingDetails
    }
  });

  new Vue({
    el: '#order-notes',
    name: 'OrderNotesApp',
    components: {
      'order-notes': OrderNotes
    }
  });

  /** Comments */
  import('@/lib/components/Comments/Comments.vue').then(Comments => {
    mountVueComponent('comments', Comments);
  });

  import('@/lib/components/Shared/DescriptionListItem.vue').then(DescriptionListItem => {
    mountVueComponent('description-list-item', DescriptionListItem);
  });
}
