import { imageHeightComparison, isNotImage } from './imageUtils';

const imageHeightFallsBelowLimit = (minImageHeight, file) =>
	imageHeightComparison(minImageHeight, file, (height, limit) => height < limit);

/**
 * A 'min_image_height' filter for Plupload.
 *
 * How to use:
 * https://www.plupload.com/docs/v2/File-Filters
 */
export default async function (minImageHeight, file, cb) {
	if (isNotImage(file) || !(await imageHeightFallsBelowLimit(minImageHeight, file))) {
		// eslint-disable-next-line standard/no-callback-literal
		cb(true);
		return;
	}

	this.trigger('Error', {
		code: -609,
		file: file,
	});

	// eslint-disable-next-line standard/no-callback-literal
	cb(false);
}
