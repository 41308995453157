let Vue = require('vue');
let FeatureUpgrade = require('../../lib/components/Holocron/FeatureUpgrade').default;

export default function() {
  if (document.getElementsByClassName('feature-upgrade').length) {
    new Vue({
      el: '.feature-upgrade',
      name: 'FeatureUpgradeApp',
      components: {
        'feature-upgrade': FeatureUpgrade
      }
    });
  }
}
