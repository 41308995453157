<template>
	<div class="row">
		<div class="col-xs-12">
			<div class="cards">
				<form-box
					v-for="form in forms"
					:key="`form-${form.slug}`"
					:can-create="canCreate"
					:can-update="canUpdate"
					:can-delete="canDelete"
					:form="form"
					:routes="routes"
					:deletable="deletable"
					@showConfirmationModal="showConfirmationModal"
					@reveal="onReveal"
				/>
				<modal
					v-model="showModal"
					:header="false"
					:confirm-button-label="lang.get('buttons.ok')"
					:close-button-label="lang.get('buttons.cancel')"
					@closed="showModal = false"
					@confirmed="submit"
				>
					<button id="close" slot="before-content" @click.prevent="showModal = false">
						<i class="af-icons af-icons-cross"></i>
					</button>
					{{ lang.get('form.alerts.delete') }}
				</modal>
			</div>
		</div>
		<portal-target v-if="formInviterRevealed" name="form-inviter" multiple></portal-target>
		<portal-target v-if="formCopierRevealed" name="form-copier" multiple></portal-target>
	</div>
</template>

<script>
import NewFormBox from './NewFormBox';
import FormBox from './FormBox';
import { Modal } from 'vue-bootstrap';
import langMixin from '@/lib/components/Translations/mixins/lang-mixin.js';
import ResourceList from '@/lib/components/ResourceList';

export default {
	name: 'FormsList',
	components: {
		NewFormBox,
		FormBox,
		Modal,
	},
	extends: ResourceList,
	mixins: [langMixin],
	props: {
		forms: {
			type: Array,
			required: true,
		},
		routes: {
			type: Object,
			default: () => ({}),
		},
		canCreate: {
			type: Boolean,
			required: true,
		},
		canUpdate: {
			type: Boolean,
			required: true,
		},
		canDelete: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			showModal: false,
		};
	},
	computed: {
		deletable() {
			return this.forms.length > 1;
		},
		formInviterRevealed() {
			return this.reveal === 'form-inviter';
		},
		formCopierRevealed() {
			return this.reveal === 'form-copier';
		},
	},
	methods: {
		showConfirmationModal(callback) {
			this.showModal = true;
			this.submit = () => {
				callback();
				this.submit = () => {};
			};
		},
	},
};
</script>

<style scoped>
.cards {
	margin: 0 -15px;
}
</style>
