<template>
  <div class="preview">
    <div class="icon">
      <i class="af-icons-md af-icons-md-circle-fail"></i><br />
      <span class="transcoding-status">
        {{ lang.get('files.transcoding.labels.failed') }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['lang']
};
</script>
