var $ = require('jquery');

/**
 * Allows to select a country and region.
 *
 * Changes visibility of region select fields dynamically.
 *
 * Usage:
 *   Regions(element, onShow);
 *
 * element -> a jQuery object
 * onShow  -> optional callback function run when regions are shown
 *
 */
module.exports = function (element, onShow) {
	var countrySelect = element.find('.select-country');
	var stateInput = $('.form-group-state');

	var changeCountry = function () {
		var country = countrySelect.val();
		var regions = $('.regions');
		var matchingRegions = $('.regions-' + country);

		regions.addClass('hidden');
		regions.find('select').prop('disabled', true);

		if (matchingRegions.length) {
			stateInput.addClass('hidden');
			matchingRegions.removeClass('hidden');
			matchingRegions.find('select').prop('disabled', false);

			if (typeof onShow === 'function') {
				onShow(matchingRegions);
			}
		} else {
			stateInput.removeClass('hidden');
		}
	};

	countrySelect.on('change', changeCountry);

	changeCountry();
};
