<script>
import ResourceList from '../../../lib/components/ResourceList.vue';
import Deletism from '../../../lib/components/ListActions/Deletism.vue';
import Undeletism from '../../../lib/components/ListActions/Undeletism.vue';
import Archiver from '../../../lib/components/ListActions/Archiver.vue';
import Unarchiver from '../../../lib/components/ListActions/Unarchiver.vue';
import ListAction from '../../../lib/components/ListActions/ListAction.vue';

export default {
  components: {
    Deletism,
    Undeletism,
    Archiver,
    Unarchiver,
    ListAction
  },
  extends: ResourceList
};
</script>
