import { createDao } from '@/domain/utils/DataAccessor';
import Lang from 'lang.js/esm/lang';
import { vueData } from '@/domain/services/VueData';
import { NestedObject, Primitive } from '@/domain/utils/UtilityTypes';

/**
 * Gives access to translations passed from the backend with
 * VueData::registerTranslations([ an array translation strings ]
 *
 * Translations are retrieved as functions that accept parameters
 * if there are no parameters just call it without parameters

 const dao = useTranslationsDao<{
	 buttons: {
		 approve: string;
		 cancel: string;
	 };
	 entries: {
	 	titles: Record<string,string>;
	 };
 }>();

 const approve = dao.buttons.approve();
 const cancel = dao.buttons.cancel();
 const entrant = dao.entries.titles.entrant('parameter);

 */
const trans = () =>
	new Lang({
		...vueData.language,
		messages: vueData.translations,
	});

type Trans = ReturnType<typeof trans>;

type TranslationsParam = Primitive | Record<string, Primitive>;
type TranslationsGetter = (p?: TranslationsParam) => string;
type TranslationsDao = NestedObject<TranslationsGetter>;

const useTranslationsDao = <T = unknown>(nested = true) => {
	const messages = {} as TranslationsDao;

	Object.keys(trans().messages)
		.map((key: string) => key.split('.').slice(1).join('.'))
		.forEach((key: string) => {
			messages[key] = (p?: TranslationsParam) => trans().get(key, p);
		});

	return createDao<T>(messages, nested);
};

export { trans, type Trans, useTranslationsDao, TranslationsParam };
