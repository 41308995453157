<template>
  <a class="widget-form-link" :href="url">
    <span v-if="round.name">{{ round.name }}</span>
    <span>{{ from }} - {{ to }}</span>
  </a>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Round',
  props: {
    formSlug: {
      type: String,
      required: true
    },
    round: {
      type: Object,
      required: true
    }
  },
  computed: {
    url() {
      return `forms/select/${this.formSlug}/round.index`;
    },
    from() {
      return this.round.startsAt || '...';
    },
    to() {
      return this.round.endsAt || '...';
    },
    ...mapState('global', ['momentLocale'])
  }
};
</script>

<style scoped>
span {
  display: flex;
  flex-direction: column;
  align-items: left;
}
</style>
