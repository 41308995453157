<template>
  <action-dropdown
    id="export-action-dropdown"
    ref="dropdown"
    :label="$attrs.label"
    :position="$attrs.position"
  >
    <div>
      <export-layout-list
        :list="exportLayouts"
        :excel-label="excelLabel"
        :csv-label="csvLabel"
        :empty-message="emptyMessage"
      >
      </export-layout-list>
      <div class="divider"></div>
      <div class="action-list">
        <div class="caption">
          <strong>{{ customLabel }}</strong>
        </div>
      </div>
      <export-layout-list
        :list="customExportLayouts"
        :excel-label="excelLabel"
        :csv-label="csvLabel"
        :edit-label="editLabel"
        :delete-label="deleteLabel"
      >
      </export-layout-list>
      <div class="action-list">
        <a :href="newAction" class="link dropdown-menu-item">
          <i class="af-icons af-icons-add"></i>
          {{ newLabel }}
        </a>
      </div>
    </div>
  </action-dropdown>
</template>

<script>
import ActionDropdown from '../Shared/ActionDropdown.vue';
import ExportLayoutList from './ExportLayoutList.vue';

export default {
  components: {
    ActionDropdown,
    ExportLayoutList
  },
  inheritAttrs: false,
  props: {
    exportLayouts: {
      type: Array,
      default: () => []
    },
    customExportLayouts: {
      type: Array,
      default: () => []
    },
    customLabel: {
      type: String,
      default: 'Custom'
    },
    newAction: {
      type: String,
      default: '/export/new'
    },
    newLabel: {
      type: String,
      default: 'New'
    },
    excelLabel: {
      type: String,
      default: 'Excel'
    },
    csvLabel: {
      type: String,
      default: 'CSV'
    },
    editLabel: {
      type: String,
      default: 'Edit'
    },
    deleteLabel: {
      type: String,
      default: 'Delete'
    },
    emptyMessage: {
      type: String,
      default: 'Empty'
    }
  },
  data() {
    return {
      layouts: [],
      customLayouts: []
    };
  },
  mounted() {
    this.layouts = this.exportLayouts;
    this.customLayouts = this.customExportLayouts;
  }
};
</script>
