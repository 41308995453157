import Vue from 'vue';

export default {
  /**
   * Update saved view
   * @param {Object} context
   * @param {Object} savedView
   */
  updateSavedView({ commit }, savedView) {
    commit('updateSavedViews', savedView);
    Vue.prototype.$http.put('/search/settings/' + savedView.slug, savedView);
  },
  /**
   * Delete saved view
   * @param {Object} context
   * @param {Object} savedView
   */
  deleteSavedView({ commit }, savedView) {
    commit('deleteFromSavedViews', savedView);
    Vue.prototype.$http.delete('/search/settings/' + savedView.slug);
  }
};
