import ping from '@/common/ping';
import tectoastr from '@/lib/tectoastr';
import { windowLocation } from '@/lib/utils';

function successHandler(response, store) {
	store.commit('validation/resetFormError');
	if (response.status === 200) {
		if (response.headers['X-Location'] !== undefined && response.headers['X-Location'].length) {
			windowLocation.setHref(response.headers['X-Location']);
		} else if (response.data.redirectUrl || response.data.redirect) {
			windowLocation.setHref(response.data.redirectUrl || response.data.redirect);
		}
	}

	return response;
}

function errorHandler(error, store) {
	if (!error) {
		throw new Error('Unknown Ajax error.');
	}

	if (!error.response) {
		throw new Error('Unknown Ajax error.', { cause: error });
	}

	if (error.response.status === 422) {
		store.commit('validation/setValidationErrors', error.response.data);
	} else if (
		(error.response.status === 401 || error.response.status === 429) &&
		error.response.data !== undefined &&
		error.response.data.message !== undefined
	) {
		store.commit('validation/setFormError', error.response.data.message);
	} else if (error.response.statusText === 'abort') {
		// Catch cancelled requests
		return error;
	} else if (error.response.statusText === 'timeout') {
		// Handle timeouts
		tectoastr.warning($('#lang-strings #alerts-timeout').text());
	} else if (error.response.status === 503) {
		// maintenance mode
		$('#maintenance-modal').modal('show');
	} else {
		if (error.response.status === 401) {
			ping();
		}

		// Handle other errors
		if (error.response.status >= 500) {
			tectoastr.error($('#lang-strings #alerts-generic').text() + ' [' + error.response.status + ']');
		}

		throw new Error('Oops! [' + error.request.responseURL + '] [' + error.response.status + ']', { cause: error });
	}

	return Promise.reject(error);
}

export { successHandler, errorHandler };
