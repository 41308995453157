<template>
  <div class="scoring-criterion">
    <div class="scoring-control">
      <scoring-control-input
        v-if="scoringControl === 'input'"
        :max-score="maximumScore"
      ></scoring-control-input>
      <scoring-control-select
        v-else-if="scoringControl === 'select'"
        :min-score="minimumScore"
        :max-score="maximumScore"
        :increment="scoringIncrement"
      ></scoring-control-select>
      <scoring-control-slider
        v-else
        :min-score="minimumScore"
        :max-score="maximumScore"
        :increment="scoringIncrement"
        :label="lang.get('miscellaneous.slider.current_value')"
      ></scoring-control-slider>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import ScoringControlInput from './ScoringControlInput';
import ScoringControlSelect from './ScoringControlSelect';
import ScoringControlSlider from './ScoringControlSlider';

export default {
  inject: ['lang'],
  components: {
    ScoringControlInput,
    ScoringControlSelect,
    ScoringControlSlider
  },
  props: {
    scoringControl: {
      type: String,
      default: 'select'
    },
    minimumScore: {
      type: [String, Number],
      default: 1
    },
    maximumScore: {
      type: [String, Number],
      default: 10
    },
    scoringIncrement: {
      type: [String, Number],
      default: 1
    }
  }
};
</script>
