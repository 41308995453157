import { EventEmitters } from '@/domain/utils/Events';

enum NewCommentEvents {
	Added = 'added',
	Commenting = 'commenting',
}

type NewCommentEmitters = EventEmitters<{
	[NewCommentEvents.Added]: (comment: { comment: string }) => void;
	[NewCommentEvents.Commenting]: () => void;
}>;

export { NewCommentEvents, NewCommentEmitters };
