import Vue from 'vue';
import ResourceList from '../../lib/components/ResourceList';
import store from '@/lib/store';

export default function() {
    const deletionLog = new Vue({
        el: '#deletion-log',
        name: 'ReportsLogApp',
        components: {
            'deletion-log': ResourceList
        },
        store
    });
    $(document).one('pjax:end', () => {
        deletionLog.$destroy();
    });
}
