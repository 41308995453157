import axios from 'axios';
import { refreshCsrfToken } from '@/lib/csrf';
import Vue from 'vue';
import { errorHandler, successHandler } from '@/lib/axios-handlers';

const setup = () => {
	Vue.prototype.$http = axios;
	Vue.prototype.$http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
	Vue.prototype.$http.defaults.headers.common['Accept'] = 'application/json';

	refreshCsrfToken();
};

let listener = {
	successHandler: () => {},
	errorHandler: () => {},
};

const addListener = (store) => {
	listener = {
		successHandler: (response) => successHandler(response, store),
		errorHandler: (error) => errorHandler(error, store),
	};

	Vue.prototype.$http.interceptors.response.use(listener.successHandler, listener.errorHandler);
};

const getListener = () => listener;

// eslint-disable-next-line @typescript-eslint/naming-convention
const Api = {
	setup,
	addListener,
	getListener,
};

export { Api };
