<template>
	<div>
		<div v-for="(translation, language) in resource" :key="language + index + translation">
			<div :class="[{ 'input-group': multiLanguage }, { error: hasError && language === lang.fallback }]">
				<div v-if="multiLanguage" :id="language + '-ml-' + index" class="input-group-addon">
					{{ language }}
				</div>
				<textarea
					:ref="'multilingual' + language"
					v-model="translations[language]"
					rows="1"
					wrap="off"
					:aria-invalid="hasError && language === lang.fallback"
					class="form-control"
					:aria-label="language"
					:disabled="disabled"
					@input="(e) => onInput(language, index, e.target.value)"
					@change="(e) => onChange(language, index, e.target.value)"
					@click="language === lang.fallback ? onClick(index, language) : onClick(-1, language)"
				/>
			</div>
			<div
				v-if="showError(language)"
				:class="['alert-error sticky inline key-error text-left', { mtm6: multiLanguage }]"
				style="margin-left: -5px !important"
			>
				{{ lang.get('validation.required_generic') }}
			</div>
		</div>
		<div :id="`multilingual-${lang.fallback}-${index}`"></div>
	</div>
</template>

<script>
export default {
	inject: ['lang'],
	props: {
		supportedLanguages: {
			type: Array,
			required: true,
		},
		index: {
			type: Number,
			required: true,
		},
		delimiter: {
			type: String,
			default: '\r\n',
		},
		resource: {
			type: Object,
			required: true,
		},
		property: {
			type: String,
			required: true,
		},
		focusedItem: {
			type: Number,
			required: true,
		},
		focusedLang: {
			type: String,
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			translations: JSON.parse(JSON.stringify(this.resource)),
		};
	},
	computed: {
		multiLanguage() {
			return this.supportedLanguages.length > 1;
		},
		hasError() {
			return this.getValue(this.lang.fallback) === '';
		},
		isFocused() {
			return this.index === this.focusedItem;
		},
	},
	watch: {
		resource: {
			handler: function (newValue) {
				this.translations = JSON.parse(JSON.stringify(newValue));
			},
			deep: true,
		},
		focusedItem() {
			if (this.isFocused) {
				this.setFocus();
			}
		},
		hasError() {
			this.$emit('error', this.index, this.hasError);
		},
	},
	mounted() {
		if (this.isFocused && this.index > 0) {
			this.setFocus();
		}

		if (this.hasError) {
			this.$emit('error', this.index, this.hasError);
		}
	},
	beforeDestroy() {
		this.$emit('error', this.index, false);
	},
	methods: {
		getValue(language) {
			return this.translations[language] || '';
		},
		onInput(language, index, value) {
			const delimiter = value.includes('\r\n') ? '\r\n' : '\n';
			value = this.cleanupValue(value);
			if (value.includes(delimiter)) {
				value = this.filterValues(value.split(delimiter));
				this.translations[language] = value[0];
			}

			this.$emit('input', value, language, index);
		},
		onChange(language, index, value) {
			const delimiter = value.includes('\r\n') ? '\r\n' : '\n';
			if (value.includes(delimiter)) {
				return;
			}

			this.$emit('change', value, language, this.index);
		},
		onClick(index, language) {
			this.$emit('changeFocus', index, language);
		},
		showError(language) {
			return this.hasError && language === this.lang.fallback && this.isFocused;
		},
		setFocus() {
			this.$nextTick(() => {
				if (this.$refs['multilingual' + this.focusedLang] !== undefined) {
					this.$refs['multilingual' + this.focusedLang][0].focus();
				} else {
					this.$refs['multilingual' + this.lang.fallback][0].focus();
				}
			});
		},
		filterValues(values) {
			values = values.map((v) => v.trim());
			if (values[0] === '') {
				values.splice(0, 1);
			}

			return values;
		},
		cleanupValue(value) {
			return value.replace(/\u00A0/g, ' ');
		},
	},
};
</script>
<style scoped>
textarea {
	min-height: auto;
	white-space: pre-wrap;
	overflow: hidden;
}
.key-error {
	margin: 0 !important;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	/*margin-top: -6px !important;*/
	/*margin-bottom: 0 !important;*/
	/*padding-top: 0 !important;*/
	/*padding-bottom: 0 !important;*/
}
.mtm6 {
	margin-top: -6px !important;
}
</style>
