import Vue from 'vue';

export default {
	updateImage(state, settings = {}) {
		Object.keys(settings).forEach((setting) => {
			if (state.files.hasOwnProperty(setting)) {
				Vue.set(state.files, setting, { ...state.files[setting], image: settings[setting] });
			}
		});
	},

	setUploadStatus(state, status) {
		Vue.set(state, 'upload', status);
	},
};
