import { useGlobal } from '@/services/global/global.interface';

export default {
	props: {
		links: {
			type: Object,
			default: () => useGlobal('links'),
		},
	},
	data() {
		return {
			applicationLinks: null,
		};
	},
	provide() {
		this.applicationLinks = {
			links: this.links,
			get: function (link) {
				return this.links[link];
			},
		};

		return {
			applicationLinks: this.applicationLinks,
		};
	},
};
