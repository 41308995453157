import { AnyEditor } from '@/lib/components/Shared/editor/TextEditor.types';

enum EditorEvents {
	Blur = 'blur',
	Focus = 'focus',
	Input = 'input',
	Keyup = 'keyup',
	Upload = 'upload',
}

type OnBlur = (event: EditorEvents.Blur) => void;
type OnFocus = (event: EditorEvents.Focus, element: AnyEditor) => void;
type OnInput = (event: EditorEvents.Input, html: string) => void;
type OnKeyUp = (event: EditorEvents.Keyup, element: HTMLElement) => void;
type OnUpload = (event: EditorEvents.Upload) => void;

type EditorEmit = OnBlur & OnFocus & OnInput & OnKeyUp & OnUpload;

export { EditorEmit, EditorEvents };
