<template>
	<div class="marker-action">
		<list-action-form ref="form" :ids="ids" :labels="labels" :route="route" :method="method" @submitted="confirmSubmit">
			<modal
				v-model="showModal"
				:title="labels.confirmModalTitle"
				:header="false"
				:confirm-button-label="labels.ok"
				:close-button-label="labels.cancel"
				:confirm-on-enter="false"
				@closed="showModal = false"
				@confirmed="submit"
			>
				<close-icon slot="before-content" @click.prevent="showModal = false"></close-icon>
				{{ modalBody }}
			</modal>
		</list-action-form>
	</div>
</template>

<script>
import ListAction from './ListAction.vue';
import CloseIcon from './Partials/CloseIcon';
import { Modal, Popover } from 'vue-bootstrap';
const $ = require('jquery');

/**
 * The Moderator component.
 */
export default {
	components: {
		CloseIcon,
		Modal,
		Popover,
	},
	extends: ListAction,
	data() {
		return {
			showModal: false,
		};
	},
	computed: {
		validSelection() {
			return this.ids.length > 0;
		},
		modalBody() {
			const element = document.getElementById('alerts-re-submit-item' + (this.ids.length > 1 ? 's' : ''));
			return element && element.textContent ? element.textContent : '';
		},
	},
	methods: {
		submit() {
			$(this.$refs.form.$el).submit();
		},
		confirmSubmit() {
			this.showModal = true;
		},
	},
};
</script>
