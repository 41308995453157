export const defaultState = () => ({
  awaitingPayment: null,
  canPay: false,
  lockedOnSubmission: null,
  paymentOnSubmit: null,
  prepaymentRequired: null,
  readOnly: null,
  resubmissionPossible: null,
  showSubmitOnFirstTab: null,
  submissionPossible: null,
  ineligible: null
});

const state = defaultState();

export default state;
