<script>
import { Popover } from 'vue-bootstrap';
import { mapState } from 'vuex';

export default {
	inject: ['lang'],
	components: {
		Popover,
	},
	props: {
		field: {
			type: Object,
			required: true,
		},
		isManager: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		writableType() {
			return this.field.type && !['content', 'formula'].includes(this.field.type);
		},
		iconClasses() {
			if ((this.isManager || this.configurationMode) && !this.field.entrantReadAccess) {
				return ['danger', 'af-icons-read-off'];
			} else if (this.isManager && !this.field.entrantWriteAccess && this.writableType) {
				return ['danger', 'af-icons-write-off'];
			} else if (!this.isManager && !this.field.entrantWriteAccess && this.writableType) {
				return ['af-icons-lock'];
			}

			return [];
		},
		popoverContent() {
			if (this.isManager && !this.field.entrantReadAccess) {
				return this.lang.get('entries.form.entrant-read-access-off');
			} else if (this.isManager && !this.field.entrantWriteAccess) {
				return this.lang.get('entries.form.entrant-write-access-off');
			}

			return '';
		},
		...mapState('entryFormConfiguration', ['configurationMode']),
	},
	render(createElement) {
		if (!this.iconClasses.length) {
			return;
		}

		const icon = createElement('span', {
			class: ['af-icons', ...this.iconClasses],
		});

		// Wrap the icon in a Popover component
		if (this.popoverContent) {
			return createElement(
				Popover,
				{
					props: {
						content: this.popoverContent,
					},
				},
				[icon]
			);
		}

		return icon;
	},
};
</script>
