<template>
	<div v-if="contentBlock" :id="id" :class="contentBlockClass" tabindex="0">
		<component :is="titleTag" v-if="showTitle" class="title">
			<a
				v-if="titleAsLink"
				:aria-expanded="isOpen ? 'true' : 'false'"
				:aria-controls="`${id}-body`"
				role="button"
				@keyup.enter="toggleVisibility"
				@click.prevent="toggle"
			>
				<i aria-hidden="true" class="af-icons af-icons-info"></i>
				{{ contentBlock.title }}
				<i aria-hidden="true" :class="chevronClass"></i>
			</a>
			<span v-else>{{ contentBlock.title }}</span>
		</component>
		<!--	eslint-disable-next-line vue/no-v-html -->
		<div :id="`${id}-body`" class="body text-content" v-html="contentBlock.content" />
	</div>
</template>

<script>
import storedVisibilityMixin, { Visibility } from '@/lib/components/Shared/mixins/stored-visibility-mixin.js';

export default {
	mixins: [storedVisibilityMixin],
	props: {
		contentBlock: {
			type: Object,
			default: null,
		},
		modelName: {
			type: String,
			default: null,
		},
		modelId: {
			type: Number,
			default: null,
		},
		containerClass: {
			type: String,
			default: '',
		},
		titleAsLink: {
			type: Boolean,
			default: true,
		},
		titleTag: {
			type: String,
			default: 'h3',
		},
		showTitle: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		id() {
			return `info-box-${this.contentBlock.id}-${this.modelId}`;
		},
		contentBlockClass() {
			return this.containerClass || ['entry-content-block', 'info-box', this.isOpen ? Visibility.OPEN : Visibility.CLOSED];
		},
	},
	created() {
		if (this.contentBlock) {
			if (this.setInitialVisibility()) {
				return;
			}

			switch (this.contentBlock.visibility) {
				case Visibility.OPEN:
					this.isOpen = true;
					break;
				case Visibility.CLOSED:
					this.isOpen = false;
					break;
				default:
					// Open initially, then closed
					this.isOpen = true;
					this.storage.set(this.id, Visibility.CLOSED);
					break;
			}
		}
	},
};
</script>
