import Vue from 'vue';
import DarkModeToggle from '../lib/components/DarkModeToggle';
import Storage from '../lib/storage';
import {
    enable as enableDarkMode,
    disable as disableDarkMode,
    auto as followSystemColorScheme,
    setFetchMethod as darkModeFetchMode,
    isEnabled as isDarkReaderEnabled
} from 'darkreader';

const darkModeSettings = {
    brightness: 100,
    contrast: 90,
    sepia: 10
};

darkModeFetchMode(myFetch);

export function mount() {
    if (document.getElementById('dark-mode-toggle')) {
        new Vue({
            el: '#dark-mode-toggle',
            name: 'DarkModeToggleApp',
            components: {
                DarkModeToggle
            }
        });
    }
}

export function init() {
    const storage = new Storage();
    const darkSetting = storage.get('dark-mode-setting');
    if(supportedBrowser()) {
        handleDarkmode(darkSetting || 'disabled');
    }
}
function myFetch(url) {
    url = url.replace(/^http:\/\//i, 'https://');
    return window.fetch(url, {
        mode: 'no-cors',
        headers: {
            'Cache-Control': 'cache'
        }
    });
}

export function handleDarkmode(selected) {
    if (selected === 'enabled') {
        enableDarkMode(darkModeSettings);
    } else if (selected === 'disabled') {
        disableDarkMode();
    } else {
        followSystemColorScheme(selected === 'auto' ? darkModeSettings : false);
    }

    let status;

    if (selected === 'auto') {
        status = systemDarkmode() ? 'enabled' : 'disabled';
    } else {
        status = selected || 'disabled';
    }

    $(window).trigger(`darkmode:${status}`);
    $(window).trigger(`darkmode:toggle`);
}

export function isEnabled() {
    return isDarkReaderEnabled();
}

export function supportedBrowser() {
    return window.matchMedia('(prefers-color-scheme)').media !== 'not all';
}

export function systemDarkmode() {
    return supportedBrowser() ? window.matchMedia('(prefers-color-scheme: dark)').matches : false;
}

/*
 * Hacky way to handle possible darkreader errors. Darkreader uses some setTimout functions which detach their
 * processes so any errors occurring there are un-catchable
 *
 * The only error noticed up to now was during chameleon tours. Nothing breaks when this error appears
 */
window.onerror = (message, source, line, col, error) => {
    if (error === null) {
        return true;
    }
    if (error !== undefined && error.stack && error.stack.indexOf('handleDarkmode') !== -1) {
        console.warn('Darkmode error: '+ message);
        // The error is considered handled and wont bubble up
        return true;
    }
    // The error bubbling will continue;
    return false;
};
