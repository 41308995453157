/**
 * This module handles the modification of search results by applying the trash filter.
 */
var $ = require('jquery');

$('.content').on('select2-selecting', '#trashSelector', function (e) {
	var url = e.val.split('?')[0];
	var data = e.val.split('?')[1];

	$.pjax({ url: url, data: data, container: '#pjaxContainer', method: 'GET' });
});
