import $ from 'jquery';
import Leaderboard from './Leaderboard';
import { mountVueComponent } from '@/lib/mount-vue-component';
import store from '@/lib/store';
import Vue from 'vue';

export default function () {
	const leaderboard = new Vue({
		el: '#leaderboard',
		name: 'LeaderboardApp',
		components: {
			Leaderboard,
		},
		store,
	});

	$(document).one('pjax:end', () => {
		leaderboard.$destroy();
	});

	// helpIcon
	import('@/lib/components/Shared/HelpIcon.vue').then((helpIcon) => {
		mountVueComponent('help-icon', helpIcon);
	});
}
