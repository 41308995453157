
import Uploader from '@/lib/components/Uploader/Uploader';
import Field from './Field';
import toastr from 'toastr';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { getSubmittableUrl, parseValueAsInt } from '@/lib/utils';
import { parseError } from '@/lib/store/modules/entry-form-api/helpers.js';
import ImageDimensionConstraints from '@/lib/components/Uploader/ImageDimensionConstraints';

export default {
	components: {
		Uploader,
	},
	extends: Field,
	inject: ['lang'],
	props: {
		name: {
			type: String,
			default: '',
		},
		resourceSlug: {
			type: String,
			default: '',
		},
		inActiveTab: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			imageDataUrl: null,
			fileToken: null,
			oldFileToken: null,
			renderComponent: true,
			validationFieldKey: 'values.' + this.field.slug,
		};
	},
	computed: {
		token() {
			if (!this.files.length) {
				return null;
			}

			return this.field.required ? this.fileToken || this.oldFileToken : this.fileToken;
		},
		tmpFileId() {
			return this.field.id + '-' + this.foreignId;
		},
		file: {
			get() {
				return this.field.file;
			},
			set(value) {
				this.field.file = value;
			},
		},
		files() {
			if (this.file) {
				return [JSON.parse(this.file)];
			} else {
				let tmpFile;
				try {
					tmpFile = JSON.parse(this.tempFile(this.tmpFileId));
				} catch (e) {
					tmpFile = this.tempFile(this.tmpFileId) || null;
				}

				return tmpFile ? [tmpFile] : [];
			}
		},
		options() {
			let obj = this.field.uploaderOptions.clarify();
			if (obj.foreignId === null) {
				if (this.foreignId === null) {
					delete obj.foreignId;
				} else {
					obj.foreignId = this.foreignId;
				}
			}

			return obj;
		},
		role() {
			return this.isManager ? 'manager' : 'entrant';
		},
		resource() {
			return this.field.resource;
		},
		canModify() {
			return !this.locks.readOnly;
		},
		cannotBeSaved() {
			return this.disabled || this.inPreviewMode;
		},
		minVideoLength() {
			return parseValueAsInt(this.field.minVideoLength);
		},
		maxVideoLength() {
			return parseValueAsInt(this.field.maxVideoLength);
		},
		imageDimensionConstraints(): ImageDimensionConstraints {
			return this.field.imageDimensionConstraints;
		},
		...mapGetters('entryForm', ['tempFile', 'entrySlug', 'inPreviewMode', 'submittable']),
		...mapState('entryForm', ['locks']),
		...mapState('global', ['userSlug']),
	},
	created() {
		this.fileToken = this.value;
		this.oldFileToken = this.value;
	},
	beforeDestroy() {
		this.storeFiles({
			file: null,
			id: this.tmpFileId,
		});
	},
	methods: {
		cancelReplace() {
			this.fileToken = this.oldFileToken;
			this.renderComponent = false;

			this.$nextTick(() => {
				this.renderComponent = true;
			});
		},
		async deletedFile(id, remoteId, isNotCaption) {
			const deleteResult = await this.deleteFileRequest(remoteId, isNotCaption);
			if (deleteResult && isNotCaption) {
				this.storeFiles({
					file: null,
					id: this.tmpFileId,
					fieldId: this.field.id,
				});
				this.file = null;
			}
		},
		replacedFile() {
			this.fileToken = null;
		},
		onImageDataUrl(imageDataUrl, file) {
			this.imageDataUrl = imageDataUrl;
			this.storeFiles({
				file: { ...file, image: imageDataUrl },
				id: this.tmpFileId,
			});
		},
		onStatus(statusMessage) {
			let file = this.tempFile(this.foreignId);
			this.storeFiles({
				file: { ...file, status: statusMessage },
				id: this.tmpFileId,
			});
		},
		deleteFileUrl(fileId) {
			const url = getSubmittableUrl(this.submittable);
			const slug = this.userSlug || this.resourceSlug;

			switch (this.resource) {
				case 'Entries':
					return this.entrySlug
						? '/' + url + '/' + this.role + '/' + this.entrySlug + '/file/' + fileId
						: this.fallbackDeleteRoute(fileId);
				case 'Users':
					return slug ? '/user/' + slug + '/file/' + fileId : this.fallbackDeleteRoute(fileId);
				default:
					return null;
			}
		},
		deleteFileRequest(fileId, isNotCaption) {
			const deleteUrl = this.deleteFileUrl(fileId);
			if (deleteUrl) {
				this.setFormEdited(true);

				return this.$http
					.delete(this.deleteFileUrl(fileId))
					.then(() => {
						if (isNotCaption) {
							this.deleteFileFromEntry({ fieldId: this.field.id });
						}

						this.autosave();
					})
					.catch((error) => {
						if (error?.response?.data?.error) {
							toastr.error(error.response.data.error);
						} else {
							toastr.error(document.getElementById('alerts-generic').innerHTML);
						}

						return false;
					});
			}
		},
		onFileUploaded(value, file) {
			this.fileToken = value;
			this.onInput(value);
			this.storeFiles({
				file: file ? JSON.stringify(this.modifyFile(file)) : null,
				id: this.tmpFileId,
				fieldId: this.field.id,
			});
		},
		onUploading(uploaderId, isUploading) {
			this.$emit('uploading', uploaderId, isUploading);
			this.resetFieldValidationError();
		},
		onError(errorMessage) {
			if (!errorMessage) {
				return;
			}

			const apiErrors = parseError(this.validationFieldKey, [errorMessage]);
			this.setErrorBag([apiErrors]);
			this.setValidationErrors({
				[this.validationFieldKey]: [errorMessage],
			});
		},
		resetFieldValidationError() {
			this.removeFromErrorBag(this.validationFieldKey);
			this.resetValidationError(this.validationFieldKey);
		},
		modifyFile(file) {
			return { ...file, id: file.remoteId, image: this.imageDataUrl };
		},
		fallbackDeleteRoute(fileId) {
			return '/file/own/' + fileId;
		},
		...mapMutations('entryForm', ['storeFiles', 'setFormEdited', 'deleteFileFromEntry']),
		...mapMutations('entryFormApi', ['setErrorBag', 'removeFromErrorBag']),
		...mapActions('entryFormApi', ['autosave']),
		...mapMutations('validation', ['setValidationErrors', 'resetValidationError']),
	},
};
