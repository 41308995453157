<script>
import { defineComponent } from 'vue';
import ResourceList from '@/lib/components/ResourceList.vue';
import Deletism from '@/lib/components/ListActions/Deletism.vue';
import Undeletism from '@/lib/components/ListActions/Undeletism.vue';
import ListAction from '@/lib/components/ListActions/ListAction.vue';

export default defineComponent({
	name: 'DocumentTemplatesList',
	components: {
		Deletism,
		Undeletism,
		ListAction,
	},
	extends: ResourceList,
});
</script>
