import { imageWidthComparison, isNotImage } from './imageUtils';

const imageWidthFallsBelowLimit = (minImageWidth, file) =>
	imageWidthComparison(minImageWidth, file, (width, limit) => width < limit);

/**
 * A 'min_image_width' filter for Plupload.
 *
 * How to use:
 * https://www.plupload.com/docs/v2/File-Filters
 */
export default async function (minImageWidth, file, cb) {
	if (isNotImage(file) || !(await imageWidthFallsBelowLimit(minImageWidth, file))) {
		// eslint-disable-next-line standard/no-callback-literal
		cb(true);
		return;
	}

	this.trigger('Error', {
		code: -607,
		file: file,
	});

	// eslint-disable-next-line standard/no-callback-literal
	cb(false);
}
