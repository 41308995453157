import { flipTranslations } from '@/lib/translations';

export default {
  // Check whether the scoreSets array contains a score set with an assigned id (there
  // can be only a single score set).
  scoreSetId(state) {
    return state.scoreSets.length && state.scoreSets[0].id
      ? state.scoreSets[0].id
      : null;
  },

  scoreSet(state) {
    return state.scoreSets.length ? state.scoreSets[0] : null;
  },

  panel(state) {
    return state.panels.length ? state.panels[0] : null;
  },

  rounds(state) {
    return state.rounds.length ? state.rounds : null;
  },

  // Return data ready to be submitted.
  data(state) {
    return {
      name: state.name,
      scoreSets: state.scoreSets,
      scoringCriteria: state.scoringCriteria.map(criterion => ({
        ...criterion,
        translated: flipTranslations(criterion.translated)
      })),
      panels: state.panels,
      rounds: state.rounds.filter(round => round.selected)
    };
  }
};
