import { isFunction } from '@/domain/utils/TypePredicates';
import videojs from 'video.js';
import { VideoPlayerOptions } from '@/domain/services/VideoPlayer/VideoPlayerTypes';
import PlayerType, { PlayerReadyCallback } from 'video.js/dist/types/player';

import 'videojs-contrib-quality-menu';

// Quality menu plugin is not included in the video.js types
interface Player extends PlayerType {
	qualityMenu?: () => void;
	// eslint-disable-next-line @typescript-eslint/naming-convention
	tech_: {
		vhs: {
			xhr: {
				onRequest: (callback: (options: { uri: string }) => void) => void;
			};
		};
	};
}

const instantiatePlayer = (videoId: string, options: VideoPlayerOptions, callback: PlayerReadyCallback): Player =>
	videojs(videoId, options, callback) as Player;

const getPlayer = (videoId: string): Player => videojs.getPlayer(videoId) as Player;

const addVideoQualitySelector = (player: Player): Player => {
	if (isFunction(player.qualityMenu)) player.qualityMenu();
	return player;
};

export { instantiatePlayer, getPlayer, addVideoQualitySelector, Player };
