<template>
  <div class="marker-action">
    <list-action-form
      ref="form"
      :ids="ids"
      :labels="labels"
      :route="route"
      :method="method"
      @submitted="toggle"
    >
      <input type="hidden" name="fund" :value="fund" />
      <input type="hidden" name="amount" :value="inputValue" />
      <portal :to="name">
        <modal
          v-model="showModal"
          :title="labels.title"
          :header="false"
          :confirm-button-label="labels.title"
          :confirm-disabled="disableConfirm"
          :close-button-label="labels.cancel"
          :header-close-button="true"
          :confirm-on-enter="false"
          @closed="modalClosed"
          @confirmed="submit"
        >
          <close-icon
            slot="before-content"
            @click.prevent="toggle"
          ></close-icon>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <select-field
                  :items="funds"
                  :value="fund"
                  placeholder="Select fund"
                  :empty-option="true"
                  aria-label="fund"
                  @selected="onFundSelected"
                >
                </select-field>
              </div>
            </div>
            <div class="col-md-6">
              <div :class="['form-group', { error: error }]">
                <currency-input
                  id="fundAllocationAmount"
                  v-model="inputValue"
                  class="form-control"
                  :currency="currency"
                  :locale="currentLocale"
                  :distraction-free="false"
                  :allow-negative="false"
                  :placeholder="placeHolder"
                  :disabled="!fund"
                  aria-label="amount"
                  @focusout="onFocusOut"
                  @input="validateInput"
                ></currency-input>
                <span v-if="error" class="help-block error">{{
                  validationMessage
                }}</span>
              </div>
            </div>
          </div>
        </modal>
      </portal>
    </list-action-form>
  </div>
</template>

<script>
import ListAction from './ListAction.vue';
import CloseIcon from './Partials/CloseIcon';
import { SelectField, Modal } from 'vue-bootstrap';
import { CurrencyInput } from 'vue-currency-input';
import langMixin from '../Translations/mixins/lang-mixin';
import modalMixin from './mixins/modal-mixin';

/**
 * The FundAllocator component.
 */
export default {
  components: {
    SelectField,
    CloseIcon,
    Modal,
    CurrencyInput
  },
  extends: ListAction,
  mixins: [langMixin, modalMixin],
  props: {
    name: {
      type: String,
      required: true,
      validator: value => value.startsWith('fund-allocator')
    },
    funds: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      fund: null,
      inputValue: null,
      currency: null,
      selectedFund: null,
      available: 0,
      error: false
    };
  },
  computed: {
    currentLocale() {
      this.lang.locale.replace('_', '-');
    },
    validationMessage() {
      if (this.fund && this.max === 0) {
        return '';
      }
      return this.lang.get('validation.max.numeric', {
        attribute: 'amount',
        max: this.formatCurrency(this.max)
      });
    },
    placeHolder() {
      if (this.currency) {
        return this.formatCurrency(0, 0).replace('0', '');
      }
      return this.lang.get('funding.form.amount.placeholder');
    },
    max() {
      return this.available / this.ids.length;
    },
    disableConfirm() {
      return (
        !this.fund ||
        this.error ||
        this.inputValue === null ||
        this.inputValue === ''
      );
    }
  },
  methods: {
    formatCurrency(amount, fractionDigits = 2) {
      if (this.currency) {
        return Number(amount).toLocaleString(this.currentLocale, {
          style: 'currency',
          currency: this.currency,
          minimumFractionDigits: fractionDigits
        });
      }
      return 0;
    },
    onFundSelected(name, value) {
      this.fund = value;
      const selectedFund = this.funds.find(fund => fund.id === this.fund);
      this.available = selectedFund ? selectedFund.available : 0;
      this.currency = selectedFund ? selectedFund.currency.code : null;
    },
    onFocusOut() {
      this.inputValue = this.calculateValue(this.inputValue);
    },
    calculateValue(value) {
      return value !== '' && value !== null ? Number(value) : null;
    },
    validateInput(value) {
      this.error = value > this.max;
    }
  }
};
</script>
