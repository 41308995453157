<template>
	<div class="marker-action">
		<list-action-form
			v-if="validSelection"
			ref="form"
			:ids="ids"
			:labels="labels"
			:route="route"
			:method="method"
			:button-class="buttonClass"
			@submitted="confirmSubmit"
		>
		</list-action-form>
		<popover v-else ref="popover" placement="bottom" trigger="hover">
			<a class="dropdown-menu-item" disabled="true" @click.prevent>{{ labels.button }}</a>
			<div slot="content">
				{{ labels.invalidSelection }}
			</div>
		</popover>
		<modal
			v-model="showModal"
			:title="labels.confirmModalTitle"
			:header="false"
			:confirm-button-label="labels.ok"
			:close-button-label="labels.cancel"
			:confirm-on-enter="false"
			@closed="showModal = false"
			@confirmed="submit"
		>
			<close-icon slot="before-content" @click.prevent="showModal = false"></close-icon>
			{{ modalBody }}
		</modal>
	</div>
</template>

<script>
import ListAction from './ListAction.vue';
import CloseIcon from './Partials/CloseIcon';
import { Modal, Popover } from 'vue-bootstrap';

/**
 * The Deletism component.
 */
export default {
	components: {
		Modal,
		CloseIcon,
		Popover,
	},
	extends: ListAction,
	props: {
		validateIds: {
			type: Boolean,
			default: false,
		},
		validIds: {
			type: Array,
			default: () => [],
		},
		buttonClass: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			showModal: false,
		};
	},
	computed: {
		validSelection() {
			if (!this.validateIds || this.ids.length === 0) {
				return true;
			}

			return this.ids.every((id) => this.validIds.includes(id));
		},
		modalBody() {
			const id = this.ids.length > 1 ? 'alerts-delete-items' : 'alerts-delete-item';
			const element = document.getElementById(id);

			return element ? element.textContent : '';
		},
	},
	methods: {
		confirmSubmit() {
			this.showModal = true;
		},
	},
};
</script>
