<template>
  <div class="btn btn-primary btn-lg btn-scoring-select">
    <input
      v-model="localScore"
      class="form-control score-select abstain-disable"
      :class="lockScores ? 'disabled' : ''"
      type="text"
      @input="$emit('update:score', $event.target.value)"
    />
    <span class="maximum">/ {{ maxScore }}</span>
  </div>
</template>

<script>
import ScoringControl from './ScoringControl';

export default {
  extends: ScoringControl,
  data() {
    return {
      localScore: this.score
    };
  }
};
</script>
