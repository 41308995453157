<script>
import QuickApprover from './QuickApprover.vue';
import QuickComments from './QuickComments.vue';
import QuickTagger from './QuickTagger.vue';

/**
 * The QuickManager component.
 */
export default {
  components: {
    QuickApprover,
    QuickComments,
    QuickTagger
  }
};
</script>
