<template>
	<div class="marker-action">
		<list-action-form
			ref="createDocumentForm"
			:ids="ids"
			:labels="labels"
			:route="route"
			:method="method"
			:button-class="buttonClass"
			@submitted="toggle"
		>
			<input type="hidden" name="resource" :value="resource" />
			<input type="hidden" name="template" :value="documentTemplate" />
			<input type="hidden" name="fileType" :value="fileType" />
			<input type="hidden" name="shared" :value="shared ? 1 : 0" />
			<input type="hidden" name="notification" :value="notification" />
			<input type="hidden" name="_token" :value="token" />
			<input type="hidden" name="redirectRoute" :value="currentURL" />
			<input
				v-for="language in supportedLanguages"
				:key="language"
				type="hidden"
				:name="'translated[name][' + language + ']'"
				:value="translated[language] ? translated[language]['name'] : ''"
			/>
			<portal :to="name">
				<modal
					v-model="showModal"
					:header="false"
					:confirm-button-label="labels.confirm"
					:close-button-label="labels.cancel"
					:header-close-button="true"
					:confirm-disabled="!formFilled"
					:confirm-on-enter="false"
					@closed="modalClosed"
					@confirmed="$refs.createDocumentForm.$el.submit()"
				>
					<close-icon slot="before-content" @click.prevent="toggle"></close-icon>
					<h4 class="modal-title">{{ labels.title }}</h4>

					<slot></slot>

					<div class="form-group">
						<label for="documentTemplateId">{{ labels.documentTemplate }}</label>
						<select-field
							id="documentTemplateId"
							name="documentTemplateId"
							:value="documentTemplateId"
							:items="documentTemplates"
							:required="true"
							id-property="slug"
							@selected="selectDocumentTemplate"
						/>
					</div>

					<div class="form-group">
						<label>{{ labels.fileType }}</label>
						<div v-for="(label, value, index) of fileTypes" :key="value" class="radio styled" :class="{ first: index === 0 }">
							<input :id="'file-type' + value" v-model="fileType" type="radio" :value="value" />
							<label :for="'file-type' + value" @click="fileType = value">
								{{ label }}
							</label>
						</div>
					</div>

					<div class="form-group">
						<label for="documentName">{{ labels.documentName }}</label>
						<multilingual
							labelled-by="documentName"
							:required="true"
							:resource="{ translated: translated }"
							:supported-languages="supportedLanguages"
							property="name"
							@input="onTranslated"
						/>
					</div>

					<div class="form-group">
						<checkbox
							name="sendNotifications"
							:selected="shared"
							:value="shared"
							:aria-label="labels.shared"
							@change="toggleShared"
						>
							<template slot="label">{{ labels.shared }}</template>
						</checkbox>
					</div>

					<div v-if="shared" class="form-group dependent">
						<label for="notificationSlug">{{ labels.notification }}</label>
						<select-field
							id="notificationSlug"
							name="notificationSlug"
							:items="notifications"
							id-property="slug"
							value-property="subject"
							:empty-option="true"
							:required="false"
							@selected="selectNotification"
						/>
					</div>
				</modal>
			</portal>
		</list-action-form>
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import CloseIcon from '@/lib/components/ListActions/Partials/CloseIcon.vue';
import ListAction from '@/lib/components/ListActions/ListAction.vue';
import ListActionForm from '@/lib/components/ListActions/ListActionForm.vue';
import modalMixin from '@/lib/components/ListActions/mixins/modal-mixin';
import { Modal, SelectField, Checkbox } from 'vue-bootstrap';
import Multilingual from '@/lib/components/Translations/Multilingual.vue';
import { createDocumentController } from '@/lib/components/ListActions/CreateDocument.controller';

export default defineComponent({
	name: 'CreateDocument',
	components: { Multilingual, SelectField, Modal, ListActionForm, Checkbox, CloseIcon },
	extends: ListAction,
	mixins: [modalMixin],
	props: {
		documentTemplates: {
			type: Array,
			required: true,
		},
		documentTemplateTranslations: {
			type: [Object, Array],
			required: true,
		},
		documentTemplateId: {
			type: Number,
			default: null,
		},
		fileTypes: {
			type: Object,
			required: true,
		},
		resource: {
			type: String,
			required: true,
		},
		name: {
			type: String,
			required: true,
			validator: (value) => ['create-document'].indexOf(value) !== -1,
		},
		notifications: {
			type: Array,
			required: true,
		},
		buttonClass: {
			type: String,
			default: null,
		},
	},
	setup: createDocumentController,
});
</script>

<style scoped>
.form-group {
	&.dependent {
		margin-bottom: 0;
	}

	.radio.first {
		margin-top: 0;
	}
}

p {
	margin: 0;
}
</style>
