var $ = require('jquery');
const { stripMarkdown } = require('@/lib/utils');
/**
 * Field copier.
 *
 * Usage:
 *   var fieldCopier = new FieldCopier();
 *   fieldCopier.setup(container, from, to);
 *
 */
module.exports = function () {
	/**
	 * Maximum length of the copied text
	 */
	var maxLength = 32;

	/**
	 * Mamory
	 */
	var memory = {};

	/**
	 * Copy field content on blur
	 *
	 * @param {String} container
	 * @param {String} from
	 * @param {String} to
	 * @param fromKey
	 * @param toKey
	 * @param rawValue
	 */
	var copyOnBlur = function (container, from, to, fromKey = null, toKey = null, rawValue = false) {
		$(container)
			.find(from)
			.each(function (index, field) {
				fromKey = fromKey !== null ? fromKey : from.substr(1);
				toKey = toKey !== null ? toKey : to.substr(1);

				var fromField = $(field);
				var matches = fromField.attr('name').match(new RegExp('translated\\[' + fromKey + '\\]\\[([^\\]]+)\\]'));
				var language, toField;

				// Skip if cannot extract matches
				if (!$.isArray(matches) || matches.length !== 2) {
					return;
				}

				language = matches[1];

				toField = $('[name="translated[' + toKey + '][' + language + ']"]');

				fromField.on('blur', function () {
					if (toField.val() !== '' && toField.val() !== memory[language]) {
						return;
					}

					var value = fromField.val().substring(0, maxLength);
					value = rawValue ? stripMarkdown(value) : value;

					toField.val(value)[0].dispatchEvent(new Event('input'));
					memory[language] = value;
				});
			});
	};

	/**
	 * @param {String} container
	 * @param {String} from
	 * @param {String} to
	 * @param fromKey
	 * @param toKey
	 * @param rawValue
	 */
	this.setup = function (container, from, to, fromKey = null, toKey = null, rawValue = false) {
		copyOnBlur(container, from, to, fromKey, toKey, rawValue);
	};
};
