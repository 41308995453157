<template>
  <div class="row">
    <div class="col-xs-12 col-md-6">
      <div class="form-group">
        <label>{{ lang.get('payments.prices.form.type.label') }}</label>
        <input name="type" type="hidden" :value="selectedType" />
        <select-field
          id="type"
          name="type"
          :value="selectedType"
          :items="priceTypes"
          :empty-option="true"
          :disabled="lockSelectType"
          @selected="(name, type) => (selectedType = type)"
        ></select-field>
      </div>
      <div v-show="selectedType === 'tag'" class="form-group">
        <label>{{ lang.get('payments.prices.form.tags.label') }}</label>
        <div class="form-group">
          <tag-input
            :value="priceTags"
            :src="tagSearchUrl"
            :placeholder="lang.get('buttons.add_tags')"
            hidden-input-name="tags"
          ></tag-input>
        </div>
      </div>
      <div v-show="selectedType !== 'tag'" class="form-group">
        <div class="checkbox styled">
          <input name="default" type="hidden" :value="isDefault ? 1 : 0" />
          <input id="default" v-model="isDefault" name="default" type="checkbox" :disabled="lockSelection" />
          <label for="default">
            {{ lang.get('payments.prices.form.default.label') }}
          </label>
        </div>
        <div class="checkbox styled">
          <input name="selectable" type="hidden" :value="isSelectable ? 1 : 0" />
          <input
            id="selectable"
            v-model="isSelectable"
            name="selectable"
            type="checkbox"
            :disabled="lockSelection || isDefault"
          />
          <label for="selectable">
            {{ lang.get('payments.prices.form.selectable.label') }}
          </label>
        </div>
        <div class="checkbox styled">
          <input id="memberNumberRequired" v-model="memberNumberRequired" name="memberNumberRequired" type="checkbox" />
          <label for="memberNumberRequired">
            {{ lang.get('payments.prices.form.member_number_required.label') }}
          </label>
        </div>
      </div>
    </div>
    <div class="col-xs-12">
      <price-variants
        :currencies="currencies"
        :amounts="amounts"
        :default-amount="defaultAmount"
        :chapters="chapters"
        :chapter-visible="chapterVisible"
        :categories="categories"
        :enable-variants="selectedType === 'entry'"
        :fields="fields"
        :field-values="fieldValues"
      />
    </div>
  </div>
</template>

<script>
import { SelectField, TagInput } from 'vue-bootstrap';
import langMixin from '@/lib/components/Translations/mixins/lang-mixin.js';
import PriceVariants from './PriceVariants';

export default {
  components: {
    SelectField,
    TagInput,
    PriceVariants
  },
  mixins: [langMixin],
  props: {
    priceType: {
      type: String,
      default: null
    },
    priceTypes: {
      type: Array,
      required: true
    },
    priceTags: {
      type: Array,
      required: true
    },
    priceDefault: {
      type: Boolean,
      default: false
    },
    priceSelectable: {
      type: Boolean,
      default: false
    },
    priceMemberNumberRequired: {
      type: Boolean,
      default: false
    },
    tagSearchUrl: {
      type: String,
      required: true
    },
    currencies: {
      type: Array,
      default: () => []
    },
    amounts: {
      type: Array,
      default: () => []
    },
    defaultAmount: {
      type: Object,
      default: () => ({})
    },
    chapters: {
      type: Array,
      default: () => []
    },
    chapterVisible: {
      type: Boolean,
      default: false
    },
    categories: {
      type: Array,
      default: () => []
    },
    entryPriceCount: {
      type: Number,
      default: 0
    },
    entrantPriceCount: {
      type: Number,
      default: 0
    },
    fields: {
      type: Array,
      default: () => []
    },
    fieldValues: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      selectedType: this.priceType,
      isDefault: this.priceDefault,
      isSelectable: this.priceSelectable,
      memberNumberRequired: this.priceMemberNumberRequired
    };
  },
  computed: {
    lockSelection() {
      return (
        (this.selectedType === 'entry' && this.entryPriceCount === 0) ||
        (this.selectedType === 'entrant' && this.entrantPriceCount === 0)
      );
    },
    lockSelectType() {
      return (
        (this.priceType === 'entry' && this.selectedType === 'entry' && this.entryPriceCount === 1) ||
        (this.priceType === 'entrant' && this.selectedType === 'entrant' && this.entrantPriceCount === 1) ||
        (this.priceType === 'entry' && this.entryPriceCount > 0 && this.isDefault) ||
        (this.priceType === 'entrant' && this.entrantPriceCount > 0 && this.isDefault)
      );
    }
  },
  watch: {
    selectedType() {
      // When creating the first entry or entrant price the price needs
      // to be made the default price (the selection is locked).
      if (this.lockSelection) {
        this.isDefault = this.lockSelection;
      }
    },
    isDefault(isDefault) {
      // When the price is the dafault price it also needs to be
      // selectable on the cart.
      if (isDefault) {
        this.isSelectable = true;
      }
    }
  }
};
</script>
