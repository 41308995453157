
import { defineComponent } from 'vue';
import { announceKitIconController, View } from '@/lib/components/Shared/AnnounceKitIcon.controller';
import { useController } from '@/domain/services/Composer';

export default defineComponent({
	props: {
		unreadCountUrl: { type: String, required: true },
		releasesUrl: { type: String, required: true },
	},
	setup: useController(announceKitIconController, 'AnnounceKitIconController') as () => View,
});
