import Vue from 'vue';
import store from '@/lib/store';
import IndexView from '@/lib/index-view';
import RoundList from './components/RoundList';

export default function() {
  const roundList = new Vue({
    el: '#round-list',
    name: 'RoundListApp',
    components: {
      RoundList
    },
    store
  });

  $(document).one('pjax:end', () => {
    roundList.$destroy();
  });
}
