<template>
  <div v-if="browserSupportsDarkMode">
    <a
      v-if="mode === 'icons'"
      href="#"
      role="button"
      @click.prevent="toggleDM"
    >
      <span class="dark-mode-container">
        <i
          v-if="selectedMode !== 'enabled' && selectedMode !== 'auto'"
          :class="['af-icons', 'af-icons-sun']"
          :aria-label="offLabel"
        ></i>
        <i
          v-if="selectedMode === 'auto'"
          :class="['af-icons', 'af-icons-half-moon']"
          :aria-label="autoLabel"
        ></i>
        <i
          v-if="selectedMode === 'enabled'"
          :class="['af-icons', 'af-icons-moon']"
          :aria-label="onLabel"
        ></i>
      </span>
    </a>

    <div v-if="mode === 'radio'">
      <fieldset>
        <legend class="sr-only">{{ panelHeading }}</legend>
        <div class="form-group">
          <div class="radio styled">
            <input
              id="dark-mode-off"
              v-model="selectedMode"
              name="darkmode"
              type="radio"
              value="disabled"
              :disabled="disabled"
            />
            <label for="dark-mode-off">
              {{ offLabel }}
            </label>
          </div>
        </div>
        <div class="form-group">
          <div class="radio styled">
            <input
              id="dark-mode-auto"
              v-model="selectedMode"
              name="darkmode"
              type="radio"
              value="auto"
              :disabled="disabled"
            />
            <label for="dark-mode-auto">
              {{ autoLabel }}
            </label>
          </div>
        </div>
        <div class="form-group">
          <div class="radio styled">
            <input
              id="dark-mode-on"
              v-model="selectedMode"
              name="darkmode"
              type="radio"
              value="enabled"
              :disabled="disabled"
            />
            <label for="dark-mode-on">
              {{ onLabel }}
            </label>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
import { Popover } from 'vue-bootstrap';
import Storage from '../../lib/storage';
import { handleDarkmode, supportedBrowser } from '../../common/darkmode';

export default {
  components: {
    Popover
  },
  props: {
    mode: {
      type: String,
      default: 'icons',
      validator: function(value) {
        return ['icons', 'radio'].indexOf(value) !== -1;
      }
    },
    offLabel: {
      type: String,
      required: true
    },
    autoLabel: {
      type: String,
      required: true
    },
    onLabel: {
      type: String,
      required: true
    },
    panelHeading: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      storage: null,
      selectedMode: null,
      selectedToggleMode: null
    };
  },
  computed: {
    browserSupportsDarkMode() {
      return supportedBrowser();
    }
  },
  watch: {
    selectedMode(value) {
      this.changeMode(value);
    }
  },
  created() {
    this.storage = new Storage();
    this.setSelectedMode();
  },
  methods: {
    toggleDM() {
      let selected;

      if (this.selectedMode === 'disabled') {
        selected = 'auto';
      } else if (this.selectedMode === 'auto') {
        selected = 'enabled';
      } else {
        selected = 'disabled';
      }
      this.selectedMode = selected;
    },
    changeMode(selected) {
      this.$nextTick(() => {
        this.storage.set('dark-mode-setting', selected);
        try {
          handleDarkmode(selected);
        } catch (e) {
          window.location.reload();
        }
      });
    },
    setSelectedMode() {
      this.selectedMode = this.storage.get('dark-mode-setting') || 'disabled';
    }
  }
};
</script>

<style scoped>
.text-white {
  color: #fff;
}
</style>
