import Vue from 'vue';
import FundsAllocationsList from './components/FundsAllocationsList';
import store from '@/lib/store';

export default function() {
    const fundsList = new Vue({
        el: '#funds-list',
        name: 'FundsApp',
        components: {
            FundsAllocationsList
        },
        store
    });

    $(document).one('pjax:end', () => {
        fundsList.$destroy();
    });
}
