import Vue from 'vue';
import store from '@/lib/store';
import IndexView from '@/lib/index-view';
import SeasonList from './components/SeasonList';

export default function() {
  const roundList = new Vue({
    el: '#season-list',
    name: 'SeasonApp',
    components: {
      'season-list': SeasonList
    },
    store
  });

  $(document).one('pjax:end', () => {
    roundList.$destroy();
  });

  new IndexView();
}
