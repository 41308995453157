<template>
  <div>
    <label :for="id" class="hidden">{{ labelText }}</label>
    <div class="btn-group">
      <input
        :id="id"
        ref="input"
        type="text"
        class="form-control search-field"
        :placeholder="placeholderText"
        autofocus
        @input="onInput($event.target.value)"
        @keydown.esc="reset"
      />
      <span v-if="input" class="search-clear" @click="reset">×</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    labelText: {
      type: String,
      default: 'Search'
    },
    placeholderText: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      input: null
    };
  },
  methods: {
    onInput(value) {
      this.input = value;
      this.$emit('input', value);
    },
    reset() {
      this.$refs.input.value = '';
      this.onInput('');
      this.$refs.input.focus();
    }
  }
};
</script>

<style scoped>
.btn-group {
  width: 100%;
}

.search-field::-ms-clear {
  display: none;
}

.search-clear {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 4px 8px 4px 4px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  color: #ccc;
  cursor: pointer;
}

.search-clear:hover {
  color: #999;
}
</style>
