<script>
import RadioList from './RadioList.vue';
import Multilingual from '@/lib/components/Translations/Multilingual';
import { flatArray } from '../utils';
import SearchField from './SearchField.vue';
import ToggleSwitch from '@/lib/components/Shared/ToggleSwitch';
import langMixin from '@/lib/components/Translations/mixins/lang-mixin';

export default {
	components: { Multilingual, RadioList, SearchField, ToggleSwitch },
	mixins: [langMixin],
	props: {
		allOverridables: {
			type: Object,
			required: true,
		},
		initOverrides: {
			type: Object,
			required: true,
		},
		initSelected: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			overridables: this.allOverridables,
			overrides: this.initOverrides,
			selected: this.initSelected,
		};
	},
	computed: {
		hasModifiedTranslations() {
			return this.modifiedOverrides.length > 0;
		},
		fieldsVisible() {
			return flatArray(Object.values(this.overridables)).map((item) => item.value);
		},
		modifiedOverrides() {
			let modified = [];
			for (let field of Object.keys(this.overrides)) {
				const translations = this.overrides[field].translated;
				for (let lang of Object.keys(translations)) {
					if (translations[lang].value !== translations[lang].default) {
						modified.push(field);
					}
				}
			}

			return modified;
		},
	},
	mounted() {
		if (this.initSelected) {
			const elem = document.getElementById(this.initSelected);
			if (elem) {
				elem.scrollIntoView(false);
			}
		}
	},
	methods: {
		changeOverrideSelected(state) {
			this.selected = state;
		},
		isSearchRelevantToField(search, overridable) {
			return [overridable.text.toLowerCase(), overridable.value.toLowerCase()].join(' ').includes(search);
		},
		isSearchRelevantToTranslations(search, field) {
			let translationObjects = (this.overrides[field] ?? []).translated ?? {};
			let translations = flatArray(Object.values(translationObjects).map((t) => t.value))
				.join(' ')
				.toLowerCase();
			let defaults = flatArray(Object.values(translationObjects).map((t) => t.default))
				.join(' ')
				.toLowerCase();

			return translations.includes(search) || defaults.includes(search);
		},
		resetTranslation(field, lang) {
			this.overrides[field].translated[lang].value = this.overrides[field].translated[lang].default;
		},
		showAll() {
			this.overridables = this.allOverridables;
		},
		showModified() {
			const filtered = {};

			for (let header of Object.keys(this.allOverridables)) {
				filtered[header] = [];
				for (let overridable of this.allOverridables[header]) {
					if (this.modifiedOverrides.includes(overridable.value)) {
						filtered[header].push(overridable);
					}
				}
			}

			this.overridables = filtered;
			this.updateTranslationsVisibility();
		},
		searchChanged(search) {
			search = search.toLowerCase();
			const filtered = {};

			for (let header of Object.keys(this.allOverridables)) {
				filtered[header] = [];
				for (let overridable of this.allOverridables[header]) {
					if (
						this.isSearchRelevantToField(search, overridable) ||
						this.isSearchRelevantToTranslations(search, overridable.value)
					) {
						filtered[header].push(overridable);
					}
				}
			}

			this.overridables = filtered;
			this.updateTranslationsVisibility();
		},
		translationChanged(translations, field) {
			this.overrides[field].translated = translations;
		},
		updateTranslationsVisibility() {
			if (!this.fieldsVisible.includes(this.selected)) {
				this.selected = null;
			}
		},
	},
};
</script>

<style scoped>
.panel-body {
	padding-right: 10px;
}

.btn-textarea-reset {
	z-index: 100;
	position: absolute;
	right: 11px;
	bottom: 1px;
	padding: 2px 8px;
	border: 1px solid transparent;
	background: transparent;
	border-radius: 2px;
	text-decoration: none;
	transition: all 0.25s;
}

div:not(.input-group) > .btn-textarea-reset {
	bottom: 68px;
}

.translations textarea:focus + .btn-textarea-reset,
.translations > div > div:hover .btn-textarea-reset {
	opacity: 1;
}

.translations .input-group .btn-textarea-reset {
	right: -4px;
}

.btn-textarea-reset:hover {
	background-color: white;
}

.radio-list-buttons {
	display: flex;
	justify-content: flex-end;
}

.radio-list-buttons .btn {
	margin: 0;
	padding: 3px 5px;
}

.radio-list-buttons .separator {
	margin-top: 2px;
}
</style>
