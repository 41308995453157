const errorMixin = {
  props: {
    name: {
      type: String,
      default: null
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ariaDescribedby() {
      return this.hasError && this.name
        ? this.name
            .replaceAll(' ', '_')
            .replaceAll('.', '_')
            .replaceAll('[]', '')
            .replaceAll('[', '.')
            .replaceAll(']', '') + 'Error'
        : null;
    }
  }
};

export default errorMixin;
