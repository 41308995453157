import { mountVueComponent } from '@/lib/mount-vue-component';

export default function () {
	import('./EntryForm').then((EntryForm) => {
		mountVueComponent('entry-form', EntryForm);
		import('./Configuration/ConfigurationModeSwitch').then((ConfigurationModeSwitch) => {
			mountVueComponent('configuration-mode-switch', ConfigurationModeSwitch);
		});

		import('./Configuration/ConfigurationModeBreadcrumbs').then((ConfigurationModeBreadcrumbs) => {
			mountVueComponent('configuration-mode-breadcrumbs', ConfigurationModeBreadcrumbs);
		});

		import('@/modules/entry-form/Countdown').then((Countdown) => {
			mountVueComponent('countdown', Countdown);
		});
	});
}
