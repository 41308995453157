<template>
	<div>
		<formula-field-configurator
			v-if="featureEnabled('formula_field')"
			:configuration="configuration"
			:field-name="fieldName"
		/>
		<formula-disabled-alert v-else />
	</div>
</template>
<script>
import langMixin from '@/lib/components/Translations/mixins/lang-mixin.js';
import FormulaFieldConfigurator from '@/lib/components/Fields/FormulaFieldConfigurator.vue';
import { mapMutations } from 'vuex';
import FormulaDisabledAlert from '@/lib/components/Fields/FormulaDisabledAlert.vue';
import { featureEnabled } from '@/services/global/features.interface';

export default {
	components: { FormulaFieldConfigurator, FormulaDisabledAlert },
	mixins: [langMixin],
	props: {
		configuration: {
			type: Object,
			required: true,
		},
		fieldTitles: {
			type: Object,
			required: true,
		},
		fieldName: {
			type: String,
			default: 'configuration',
		},
	},
	mounted() {
		this.setFieldTitles(this.fieldTitles);
	},
	methods: {
		featureEnabled,
		...mapMutations('formulaField', ['setFieldTitles']),
	},
};
</script>

<style lang="scss" scoped>
.alert-info {
	margin-bottom: 15px;
}
</style>
