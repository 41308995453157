<template>
	<dropdown
		button-class="dropdown-toggle"
		container-class="action-overflow"
		:aria-label="ariaLabel"
		menu-class="dropdown"
	>
		<span slot="label">
			<i class="af-icons af-icons-action-overflow">
				<span class="sr-only">
					{{ lang.get('buttons.action_overflow', { resource: form.name }) }}
				</span>
			</i>
		</span>
		<li v-if="!form.deletedAt && canUpdate">
			<a :href="url.edit" class="dropdown-menu-item">
				{{ lang.get('form.buttons.edit') }}
			</a>
		</li>
		<li v-if="!form.deletedAt && canUpdate">
			<a :href="url.settings" class="dropdown-menu-item">
				{{ lang.get('form.buttons.settings') }}
			</a>
		</li>
		<li v-if="!form.deletedAt && canUpdate && form.type === 'entry'">
			<form-inviter
				id="form-inviter"
				:ids="[form.id]"
				:forms="form.invitationConfig.forms"
				:chapters="form.invitationConfig.chapters"
				:chapter="form.invitationConfig.chapter"
				:categories="form.invitationConfig.categories"
				:category="form.invitationConfig.category"
				:roles="form.invitationConfig.roles"
				:role="form.invitationConfig.role"
				:message="form.invitationConfig.message"
				:deadline="form.invitationConfig.deadline"
				:labels="form.invitationConfig.labels"
				:chapter-option="form.invitationConfig.chapterOption"
				:show-form-selector="form.invitationConfig.showFormSelector"
				:form-id="form.id"
				:invited-notifications="form.invitationConfig.invitedEntryNotifications"
				name="form-inviter"
				method="POST"
				:route="form.invitationConfig.route"
				v-on="$listeners"
			></form-inviter>
		</li>
		<li v-if="!form.deletedAt && canCreate">
			<a v-if="form.type === 'report'" class="dropdown-menu-item" @click.prevent="copyForm">
				{{ form.copierConfig.labels.button.replace('...', '') }}
			</a>
			<form-copier
				v-else
				id="form-copier"
				:ids="[form.id]"
				:labels="form.copierConfig.labels"
				:options="form.copierConfig.options"
				:form-id="form.id"
				name="form-copier"
				method="POST"
				:route="form.copierConfig.route"
				v-on="$listeners"
			></form-copier>
		</li>
		<li v-if="!form.deletedAt && form.type === 'entry'">
			<a :href="url.entries" class="dropdown-menu-item">
				{{ manageButtonString }}
			</a>
		</li>
		<li v-if="!form.deletedAt && form.type === 'report'">
			<a :href="url.myGrantReports" class="dropdown-menu-item">
				{{ lang.get('form.buttons.my-grant-reports') }}
			</a>
		</li>
		<li v-if="!form.deletedAt && form.type === 'report'">
			<a :href="url.reports" class="dropdown-menu-item">
				{{ manageButtonString }}
			</a>
		</li>
		<li v-if="!form.deletedAt && form.type === 'entry'">
			<a :href="url.judging" class="dropdown-menu-item">
				{{ lang.get('form.buttons.manage-reviewing') }}
			</a>
		</li>
		<li v-if="!form.deletedAt && canDelete && deletable">
			<a class="dropdown-menu-item" @click.prevent="deleteForm">
				{{ lang.get('buttons.delete') }}
			</a>
		</li>
		<li v-if="form.deletedAt">
			<a class="dropdown-menu-item" @click.prevent="undeleteForm">
				{{ lang.get('buttons.undelete') }}
			</a>
		</li>
	</dropdown>
</template>

<script>
import { Dropdown } from 'vue-bootstrap';
import { route } from '@/lib/utils.js';
import FormInviter from '@/lib/components/ListActions/FormInviter';
import FormCopier from './FormCopier';

export default {
	inject: ['lang'],
	components: {
		Dropdown,
		FormInviter,
		FormCopier,
	},
	props: {
		form: {
			type: Object,
			required: true,
		},
		routes: {
			type: Object,
			default: () => ({}),
		},
		canCreate: {
			type: Boolean,
			required: true,
		},
		canDelete: {
			type: Boolean,
			required: true,
		},
		canUpdate: {
			type: Boolean,
			required: true,
		},
		deletable: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		ariaLabel() {
			return `Menu for ${this.form.name || 'form'}`;
		},
		url() {
			return {
				edit: this.route(this.routes['forms.edit'], { form: this.form.slug }),
				settings: this.route(this.routes['forms.settings'], {
					form: this.form.slug,
				}),
				entries: this.route(this.routes['forms.select'], {
					slug: this.form.slug,
					route: 'entry.manager.index',
				}),
				judging: this.route(this.routes['forms.select'], {
					slug: this.form.slug,
					route: 'judging-dashboard.index',
				}),
				reports: this.route(this.routes['forms.select'], {
					slug: this.form.slug,
					route: 'grant-report.manager.index',
				}),
				myGrantReports: this.route(this.routes['forms.select'], {
					slug: this.form.slug,
					route: 'grant-report.entrant.index',
				}),
			};
		},
		manageButtonString() {
			return this.lang.get('form.buttons.' + (this.form.type === 'entry' ? 'manage-entries' : 'manage-grant-reports'));
		},
	},
	methods: {
		copyForm() {
			const data = { selected: [this.form.id] };

			$.pjax({ url: this.form.copierConfig.route, data: data, method: 'POST' });
		},
		deleteForm() {
			const url = this.route(this.routes['forms.delete']);
			const data = { _method: 'DELETE', selected: [this.form.id] };

			this.$emit('showConfirmationModal', () => $.pjax({ url: url, data: data, method: 'POST' }));
		},
		undeleteForm() {
			const url = this.route(this.routes['forms.undelete']);
			const data = { _method: 'PUT', selected: [this.form.id] };

			$.pjax({ url: url, data: data, method: 'POST' });
		},
		route,
	},
};
</script>

<style>
.mt-title {
	overflow: hidden;
	text-overflow: ellipsis;
	margin-left: 15px;
	margin-right: 20px;
}

.mt-dropdown {
	position: absolute;
	margin: 5px 5px 0 -13px;
	letter-spacing: 0;
}

.mt-dropdown ul {
	top: 2em;
}
</style>
