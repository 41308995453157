/**
 * Given an array of resources (can be tabs or fields) in a particular order
 * returns an id/order map.
 */
export const newOrder = resources => {
  let newOrder = {};
  let order = 0;

  resources.forEach(resource => {
    order += 100;
    if (resource.order !== order) {
      newOrder[resource.id] = order;
    }
  });

  return newOrder;
};
