export const configureThirdPartyLoginOnly = () => {
	const socialOptionsSelected = [];
	const socialAuthElements = document.querySelectorAll(
		'.social-authentication:not(#enable-saml-certificate-encrypt):checked'
	);
	socialAuthElements.forEach((element) => {
		socialOptionsSelected.push((element as HTMLInputElement).value);
	});

	const hasSocialOptionsSelected = socialOptionsSelected.length > 0;

	const thirdPartyLoginOnlyElement = document.getElementById('disable-login-form') as HTMLInputElement;

	thirdPartyLoginOnlyElement.disabled = !hasSocialOptionsSelected;

	if (thirdPartyLoginOnlyElement.disabled && thirdPartyLoginOnlyElement.checked) {
		thirdPartyLoginOnlyElement.checked = false;
	}
};
