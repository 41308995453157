<template>
  <div class="form-group">
    <label for="formula">
      {{ lang.get('fields.form.formula.label') }}
    </label>
    <input id="formula" v-model="formula" type="text" class="form-control" />
    <input v-model="formulaConfigurationString" type="hidden" :name="fieldName" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
export default {
  inject: ['lang'],
  props: {
    configuration: {
      type: Object,
      default: () => ({})
    },
    fieldName: {
      type: String,
      default: 'configuration'
    }
  },
  data() {
    return {
      formulaConfiguration: this.configuration
    };
  },
  computed: {
    ...mapGetters('formulaField', ['fieldSlugTitleMap', 'fieldTitleSlugMap']),
    formulaConfigurationString() {
      return JSON.stringify(this.formulaConfiguration);
    },
    formulaField() {
      return this.formulaConfiguration.formula || '';
    },
    formula: {
      get: function() {
        // Convert formula from `=IF({ORwPaZPY}=10, "yes", "no")` to `IF({Short Title}=10, "yes", "no")`.
        const regex = new RegExp(Object.keys(this.fieldSlugTitleMap).join('|'), 'gi');

        return this.formulaField.replace(regex, match => this.fieldSlugTitleMap[match]);
      },
      set: function(newValue) {
        // Convert formula from `IF({Short Title}=10, "yes", "no")` to `=IF({ORwPaZPY}=10, "yes", "no")`.
        const regex = new RegExp(
          Object.keys(this.fieldTitleSlugMap)
            .map(item => _.escapeRegExp(item))
            .join('|'),
          'gi'
        );

        const formula = newValue.replace(regex, match => this.fieldTitleSlugMap[match.toLowerCase()]);

        this.formulaConfiguration = { formula: formula };
      }
    }
  },
  watch: {
    formulaConfiguration() {
      this.$emit('updated', this.formulaConfigurationString);
    }
  }
};
</script>
