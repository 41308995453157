export default {
  OK: 'ok',
  PENDING: 'pending',
  MISSING: 'missing',
  EXPIRED: 'expired',
  REJECTED: 'rejected',
  REJECTED_NOT_FOUND: 'not-found',
  REJECTED_NO_VALIDATOR: 'no-validator',
  REJECTED_VALIDATION_FAILED: 'validation-failed',
  REJECTED_VIRUS_FOUND: 'virus-found'
};
