<template>
  <div class="one-liner scroll-horizontally rounded-shadows" @scroll="onScroll" ref="container">
    <div v-if="scroll > 0" class="panel-shadow panel-shadow-left"/>
    <transition name="fade">
      <arrow-button aria-label="previous" style="left:0; bottom: 132px;" v-if="scroll > 0" type="left" @click="() => scrollTo(-1)"/>
    </transition>
    <form-box
        :form="form"
        :routes="routes"
        v-for="form in forms"
        :key="`formbox-${form.slug}`"
        :default-call-to-action="defaultCallToAction">
    </form-box>
    <transition name="fade">
      <arrow-button aria-label="next" style="right:0; bottom: 132px;" v-if="scroll < 1" type="right" @click="() => scrollTo(1)"/>
    </transition>
    <div v-if="scroll < 1" class="panel-shadow panel-shadow-right"/>
  </div>
</template>

<script>
import ArrowButton from "@/lib/components/Shared/ArrowButton";
import FormBox from './FormBox.vue';

const scrollLeftMax = e => e.scrollWidth - e.clientWidth
const step = 520;

export default {
  name: "FormsGalleryList",
  components: {
    ArrowButton,
    FormBox
  },
  props: {
    forms: {
      type: Array,
      required: true
    },
    routes: {
      type: Object,
      required: true
    },
    defaultCallToAction: {
      type: String,
      default: 'Start entry'
    }
  },
  data() {
    return {
      scroll: 0,
      scrollAmount: 0
    }
  },
  mounted() {
    this.onResize()
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.scroll = this.$refs.container.scrollLeft / scrollLeftMax(this.$refs.container)
      this.scrollAmount = this.$refs.container.scrollLeft
    },
    onScroll(e) {
      this.scroll = e.target.scrollLeft / scrollLeftMax(e.target)
    },
    scrollTo(v) {
      v *= step
      this.scrollAmount = Math.max(0, Math.min(this.scrollAmount+v, scrollLeftMax(this.$refs.container)))

      this.$refs.container.scrollTo({
        left: this.scrollAmount,
        behavior: 'smooth',
      })
    }
  }
}
</script>

<style scoped>
.panel-shadow {
  display: block;
  height: 100%;
  width: 20px;
  z-index: 1;
  position: absolute;
  top: 0;
  background-repeat: no-repeat;
}

.panel-shadow-left {
  left: -30px;
  background: radial-gradient(farthest-side at 0 50%, rgba(0,0,0,.2), rgba(0,0,0,0));
}

.panel-shadow-right {
  right: -30px;
  background: radial-gradient(farthest-side at 100% 50%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
}

</style>
