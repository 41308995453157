var $ = require('jquery');

/**
 * Form disabler - disables all inputs, selects and buttons in the form.
 *
 * Usage:
 *   var fieldDisabler = new FieldDisabler();
 *   fieldDisabler.disableFields($('.form-disabled'));
 *
 */
module.exports = function () {
	this.disableForm = function (form) {
		var lockIcon = '<span class="af-icons af-icons-lock"></span>';

		// Add lock to label
		form.find('.field-label').children('label').prepend(lockIcon);

		// Disable inputs
		form.find('input, select, textarea, button').each(function () {
			$(this).prop('disabled', true);
		});

		// File upload fields need to be handled differently
		if (form.find('.upload-standalone').length) {
			form.find('.upload-button').remove();
			form.find('.upload-details-container').remove();
		}

		// Table fields
		if (form.find('.table-field').length) {
			form.find('.delete-row').remove();
			form.find('tfoot').remove();
		}
	};
};
