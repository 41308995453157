import Vue from 'vue';
import ContractList from './components/ContractList';
import store from '@/lib/store';

export default function() {
  const allContractList = new Vue({
    el: '#contract-list',
    name: 'ContractApp',
    components: {
      ContractList
    },
    store
  });

  $(document).one('pjax:end', () => {
    allContractList.$destroy();
  });
}
