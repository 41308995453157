import JudgingDashboard from './JudgingDashboard';
import store from '../../lib/store';
import Vue from 'vue';

export default function () {
	const judgingDashboardEl = document.getElementById('judging-dashboard-vue');

	if (judgingDashboardEl) {
		const judgingDashboard = new Vue({
			el: judgingDashboardEl,
			name: 'JudgingDashboardApp',
			components: {
				JudgingDashboard,
			},
			store,
		});

		$(document).one('pjax:end', () => {
			judgingDashboard.$destroy();
		});
	}
}
