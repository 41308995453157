import { getGlobal } from '@/lib/utils';

export const defaultState = () => ({
  validationErrors: getGlobal('validationErrors')?.clarify(),
  formError: null
});

const state = defaultState();

export default state;
