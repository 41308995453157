<script>
export default {
  props: {
    delay: {
      type: Number,
      default: 250
    },
    tag: {
      type: String,
      default: 'a'
    }
  },
  data() {
    return {
      clickCount: 0,
      clickTimer: null,
      clickDelay: this.delay
    };
  },
  methods: {
    handleClick(e) {
      e.preventDefault();

      this.clickCount++;

      if (this.clickCount === 1) {
        this.clickTimer = setTimeout(() => {
          this.clickCount = 0;
          this.$emit('single-click');
        }, this.clickDelay);
      } else if (this.clickCount === 2) {
        clearTimeout(this.clickTimer);
        this.clickCount = 0;
        this.$emit('double-click');
      }
    }
  },
  render(createElement) {
    return createElement(
      this.tag,
      {
        on: {
          click: this.handleClick
        }
      },
      this.$slots.default
    );
  }
};
</script>
