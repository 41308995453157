<template>
	<button v-if="collapsed" type="button" class="btn-xs icon feature-intro-revealer" @click="onClick()">
		<span class="sr-only">{{ lang.get('shared.click_to_learn_more') }}</span>
		<i class="af-icons af-icons-info"></i>
	</button>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getTrans } from '@/services/global/translations.interface';

export default {
	props: {
		urlUpdateVisibility: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			visible: false,
		};
	},
	computed: {
		...mapState('featureIntro', ['collapsed']),
		lang() {
			return getTrans();
		},
	},
	watch: {
		// Watch collapsed store state and update local variables
		collapsed() {
			this.visible = this.collapsed;
		},
	},
	methods: {
		onClick() {
			this.changeCollapsedState({
				collapsed: false,
				url: this.urlUpdateVisibility,
			});
		},
		...mapActions('featureIntro', ['changeCollapsedState']),
	},
};
</script>

<style scoped>
.feature-intro-revealer {
	display: inline-block;
	margin-left: 5px;
	margin-right: 5px;
	padding-top: 5px;
	background-color: transparent;
	border: none;
}

.icon {
	cursor: pointer;
}

i {
	font-size: 20px;
}
</style>
