
import { useController } from '@/domain/services/Composer';
import { draggableItemController, View } from '@/lib/components/Shared/DraggableItem.controller';

export default {
	props: {
		index: {
			type: Number,
			required: true,
		},
		item: {
			type: Object,
			required: true,
		},
		items: {
			type: Array,
			required: true,
		},
		itemKey: {
			type: String,
			required: true,
		},
		itemDescription: {
			type: String,
			default: null,
		},
		grabbedItem: {
			type: [String, Number],
			default: null,
		},
		tag: {
			type: String,
			default: 'div',
		},
		dragDirection: {
			type: String,
			default: 'vertical',
		},
		lockFirstItem: {
			type: Boolean,
			default: false,
		},
	},
	setup: useController(draggableItemController, 'DraggableItemController') as () => View,
};
