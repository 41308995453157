var router = require('pjax').Router;
var index = require('./index.js');
var vote = require('./vote.js');
var buttons = require('./buttons.js');
var slideshow = require('./slideshow.js');

router.get('vote/:any', buttons);
router.get('vote/([a-zA-Z]+)', index);
router.get('vote/([a-zA-Z]+)/([a-zA-Z]+)', vote);
router.get('vote/([a-zA-Z]+)/([a-zA-Z]+)/slideshow', slideshow);
