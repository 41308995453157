import { mountVueComponent } from '@/lib/mount-vue-component';
import Vue from 'vue';
import Home from './components/Home';

export default function() {
  Vue.component('Home', Home);

  import('@/lib/components/Layout/Splash/SplashWithMenu').then(SplashWithMenu => {
    mountVueComponent('splash-with-menu', SplashWithMenu, true, false);
  });

  import('@/lib/components/Layout/Splash/SimpleMenu').then(SimpleMenu => {
    mountVueComponent('simple-menu', SimpleMenu, true, false);
  });
}
