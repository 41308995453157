<script>
import $ from 'jquery';

export default {
	props: {
		id: {
			type: String,
			default: '',
		},
		name: {
			type: String,
			default: '',
		},
		url: {
			type: String,
			required: true,
		},
		filters: {
			type: Object,
			default: () => ({}),
		},
		label: {
			type: String,
			default: 'Label',
		},
		searchLabel: {
			type: String,
			default: 'Search',
		},
		resetLabel: {
			type: String,
			default: 'Reset',
		},
		advancedSearch: {
			type: Boolean,
			default: false,
		},
		showFilters: {
			type: Boolean,
			default: false,
		},
		advancedSearchLabel: {
			type: String,
			default: 'Advanced',
		},
		onSearch: {
			type: Function,
			default: () => {},
		},
		searchKeywords: {
			type: String,
			default: '',
		},
		triggerSearch: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			filtersVisible: this.showFilters,
		};
	},
	computed: {
		keywords: {
			get: function () {
				return this.searchKeywords;
			},
			set: function (newValue) {
				this.$emit('update:searchKeywords', newValue);
			},
		},
	},
	mounted() {
		this.$nextTick(() => {
			if (this.triggerSearch) {
				this.search();
				this.$emit('update:triggerSearch', false);
			}
		});
	},
	methods: {
		search() {
			const keywords = this.keywords ? { keywords: this.keywords } : {};
			const params = Object.assign(keywords, this.filters);

			this.ajax(params).success((data) => {
				this.onSearch(data);
			});
		},
		ajax(params) {
			return $.ajax({
				url: this.url,
				type: 'GET',
				dataType: 'json',
				data: params,
			});
		},
		reset() {
			this.keywords = '';

			for (var i in this.$children) {
				this.$children[i].reset();
			}

			this.$emit('reset');
		},
	},
};
</script>
