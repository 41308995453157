const mapGetters = require('vuex').mapGetters;
const mapMutations = require('vuex').mapMutations;

const filtersMixin = {
  methods: {
    ...mapMutations([
      'updateFilters'
    ]),
    saveFilters (column, type, params = {}) {
      this.updateFilters(Object.assign(this.filters(column), {
        column: column,
        row: null,
        rules: {
          type: type,
          ...params
        }
      }));
    },
    filters (column) {
      return this.getFilters(column) || {};
    },
    columnType (column) {
      return (this.filters(column).rules || {}).type;
    },
    columnSelectedCurrency (column) {
      return (this.filters(column).rules || {}).currency;
    },
    isNumericColumn (column) {
      return ['integer', 'decimal', 'decimal-precise', 'currency'].includes(this.columnType(column));
    }
  },
  computed: {
    ...mapGetters([
      'getFilters'
    ])
  }
};

module.exports = filtersMixin;
