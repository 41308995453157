import { useApiUnreadCount } from '@/lib/components/Shared/AnnounceKitIcon.api';
import { useContainer } from '@/domain/services/Container';
import { VueHooks } from '@/domain/services/VueHooks';
import { computed, ComputedRef, Ref, ref, SetupFunction } from 'vue';
import { trans, Trans } from '@/domain/dao/Translations';

type View = {
	unread: Ref<number>;
	hasUnread: ComputedRef<boolean>;
	lang: Trans;
};

type Props = {
	unreadCountUrl: string;
	releasesUrl: string;
};

const { getUnreadCount } = useApiUnreadCount();

const announceKitIconController: SetupFunction<Props, View> = (props): View => {
	const { onMounted } = useContainer<VueHooks>();
	const unread = ref(0);
	const hasUnread = computed(() => unread.value > 0);
	const currentURL = typeof window !== 'undefined' ? window.location.href : '';
	const isReleasesURL = ref(currentURL === props.releasesUrl);

	onMounted(async () => {
		if (isReleasesURL.value) {
			unread.value = 0;
			return;
		}

		try {
			const response = await getUnreadCount();
			unread.value = response.unread;
		} catch (error) {
			unread.value = 0;
		}
	});

	return {
		unread,
		hasUnread,
		lang: trans(),
	};
};

export { announceKitIconController, Props, View };
