<template>
  <select-field
    class="timezones"
    :value="value"
    :items="timezones"
    :empty-option="false"
    :disabled="disabled"
    @selected="valueChanged"
  ></select-field>
</template>

<script>
import { SelectField } from 'vue-bootstrap';

export default {
  components: { SelectField },
  props: {
    /**
     * Timezones in format [{id: 1, name: 'Pacific/Midway'}, {id: 2, name: 'Pacific/Tahiti'}]
     */
    timezones: {
      type: Array,
      required: true
    },
    /**
     * Initial timezone to use
     */
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    valueChanged(name, selected) {
      this.$emit('update:value', selected);
    }
  }
};
</script>
