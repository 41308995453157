import $ from 'jquery';
import { getPlayer } from '@/domain/services/VideoPlayer/VideoPlayerWrapper';
import init from '@/domain/services/VideoPlayer/VideoPlayerInit';
import options from '@/domain/services/VideoPlayer/VideoPlayerOptions';

/**
 * Opens video player in modal window.
 *
 * Usage: VideoModal.setup(playButton);
 */
const setup = (playButtonClass: string) => {
	const playButtons = document.querySelectorAll(`.${playButtonClass}`);

	if (!playButtons.length) {
		return;
	}

	playButtons.forEach((playButton) => handlePlayButtonClick(playButton));
};

/**

 */
const handlePlayButtonClick = (playButton: Element) => {
	playButton.addEventListener('click', function (this: HTMLElement) {
		const modal = this?.nextElementSibling;
		const videoId = modal?.querySelector('video')?.getAttribute('id');

		if (!modal || !videoId) {
			console.error('Video modal not found', { modal, videoId });
			return;
		}

		openModal(modal, videoId);
	});
};

/**
 * Set-up bindings to initialise the video player on demand.
 *
 * Each video attachment has its own modal with the markup needed by videojs to reproduce it. But for the
 * modal to behave properly, before opening we need to clone it and attach it to the body.
 *
 */
const openModal = (modal: Element, videoId: string) => {
	// Adding `modal-` prefix to the video id to avoid conflicts with the original video.
	const playerId = `modal-${videoId}`;
	const clonedVideoPlayerModal = modal.cloneNode(true) as HTMLElement;
	clonedVideoPlayerModal.querySelector('video')?.setAttribute('id', playerId);

	const videoPlayerModal = $(clonedVideoPlayerModal).appendTo(document.body);

	setupPlayer(playerId);

	videoPlayerModal.modal();

	videoPlayerModal.on('hidden.bs.modal', function () {
		const player = getPlayer(playerId);
		const currentTime = player.ended() ? 0 : player.currentTime() || 0;

		// remove the `modal-` prefix to update the original video element
		updateCurrentTime(playerId.replace('modal-', ''), currentTime);

		player.dispose();
		videoPlayerModal.remove();
	});
};

/**
 * Initialises videojs inside the cloned modal appended to the body.
 *
 * To prevent conflicts with the original video, the clone's id is prefixed before initialising the player. The
 * playback starting point is set based on the data-start attribute of the original video element.
 *
 * video -> The video element inside the cloned modal
 */
const setupPlayer = (videoId: string) => {
	const player = init(videoId, options);

	// _html5_api is the default suffix for the videojs player's id
	// https://github.com/videojs/video.js/issues/7358
	const videoElement = document.getElementById(videoId + '_html5_api');
	const currentTime = Number(videoElement?.getAttribute('data-start')) || 0;
	player.currentTime(currentTime);
};

/**
 * Updates the attribute data-start of the original video element,
 * so playback can be resumed if the video is opened again.
 *
 */
const updateCurrentTime = (playerId: string, currentTime: number) => {
	const videoElement = document.getElementById(playerId);
	videoElement?.setAttribute('data-start', String(currentTime));
};

export default {
	setup,
};
