<template>
	<modal ref="modal" v-model="visible" :header="true" :footer="false" :close-button="true" @closed="onClosed">
		<template slot="title">
			<div v-if="data"><slot name="title">Assignments for</slot> {{ judgeName }}</div>
			<div v-else slot="title"><slot name="loading">Loading</slot>...</div>
		</template>
		<table class="table">
			<thead>
				<tr>
					<th><slot name="id">ID</slot></th>
					<th><slot name="entry">Entry</slot></th>
					<th><slot name="scoreset">Score set</slot></th>
				</tr>
			</thead>
			<tbody v-if="data && data.rows">
				<tr v-for="row in data.rows" :key="row.id + '-' + row.entry.name + '-' + row.scoreset.name">
					<td>{{ row.id }}</td>
					<td>{{ row.entry.name }}</td>
					<td>{{ row.scoreset.name }}</td>
				</tr>
			</tbody>
		</table>
	</modal>
</template>

<script>
import { Modal } from 'vue-bootstrap';
import $ from 'jquery';

export default {
	components: {
		Modal,
	},
	props: {
		data: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			visible: false,
		};
	},
	computed: {
		judgeName() {
			return this.data.judge.name;
		},
	},
	watch: {
		data() {
			if (this.data !== null) {
				this.visible = true;
				$('body').trigger('pjax:hide');
			}
		},
	},
	methods: {
		onClosed() {
			this.visible = false;
		},
	},
};
</script>
