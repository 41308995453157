import AnnounceKitIcon from '@/lib/components/Shared/AnnounceKitIcon.vue';
import store from '@/lib/store';
import Vue from 'vue';

document.querySelectorAll('.announce-kit').forEach((el) => {
	new Vue({
		el: el,
		components: {
			AnnounceKitIcon,
		},
		store,
	});
});
