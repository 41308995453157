import requiredValidator from './required.js';
import emailValidator from './email.js';
import numericValidator from './numeric.js';
import lengthValidator from './length.js';
import urlValidator from './url.js';
import includesValidator from './includes.js';
import colorValidator from './color.js';
import markdownValidator from './sanitize-markdown.js';
import phoneValidator from './phone.js';
import existingEmailValidator from '@/lib/components/Fields/validator/existingEmail';
import passwordComplexity from '@/lib/components/Fields/validator/password-complexity';

/**
 * A simple functional validator - each validation rule is handled by one of the functions imported here.
 */
const validators = {
  required: requiredValidator,
  email: emailValidator,
  numeric: numericValidator,
  phone: phoneValidator,
  url: urlValidator,
  markdown: markdownValidator,
  length: lengthValidator,
  password_complexity: v => passwordComplexity(v, 12),
  unique_email_active: existingEmailValidator,
  includes: includesValidator,
  color: colorValidator
};

export function validate(rule, value, data) {
  if (Object.keys(validators).includes(rule)) {
    return validators[rule](value, data);
  }

  return false;
}

export default validators;

export const fieldValidationRules = fieldType =>
  ({
    email: ['email'],
    numeric: ['numeric'],
    phone: ['phone'],
    text: ['markdown'],
    textarea: ['markdown'],
    url: ['url'],
    password: ['password_complexity']
  }[fieldType] || []);

export const validationExtra = fieldType => {
  if (['date', 'datetime', 'time'].includes(fieldType)) {
    return '.' + fieldType;
  }

  return '';
};

export const validationRules = (fieldType, extraRules = [], required = false) => ({
  validationRules: extraRules.concat(fieldValidationRules(fieldType) || []),
  requiredValidator: required ? 'required' : null
});
