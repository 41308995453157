import Vue from 'vue';
import store from '@/lib/store';
import TabbedContent from "./components/TabbedContent";

[...document.getElementsByClassName('tabbed-content')].forEach(
    function(tabbedContentEl) {
        new Vue({
            el: tabbedContentEl,
            components: {
                TabbedContent
            },
            store
        });
    }
)
