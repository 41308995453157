const defaultState = () => ({
	preferences: {},
	preferenceSaving: {},
	categories: {},
	titles: {},
	translations: {},
});

const state = defaultState();

export { defaultState };

export default state;
