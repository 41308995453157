var $ = require('jquery');

/**
 * Configures and attaches tooltips
 *
 * Usage:
 *
 *    var tooltipAttacher = new TooltipAttacher;
 *    tooltipAttacher.attach('trigger-tooltip');
 */
module.exports = function () {
	var rtlEnabled = function () {
		return $('html').attr('dir') === 'rtl';
	};

	this.attachTooltips = function (element) {
		var defaultContainer = rtlEnabled() ? false : 'body';

		// Read popover options from data attributes
		var container = element.data('container') !== undefined ? element.data('container') : defaultContainer;
		var placement = element.data('placement') ? element.data('placement') : 'top';
		var triggerEvent = element.data('trigger') ? element.data('trigger') : 'click';
		var hideEvent = element.data('hide');

		var options = {
			trigger: triggerEvent,
			container: container,
			placement: placement,
		};

		element.popover(options);

		if (hideEvent) {
			element.on(hideEvent, function () {
				$(this).popover('hide');
			});
		}
	};

	/**
	 * Attach tooltips
	 *
	 * elements -> elements with tooltips
	 */
	this.attach = function (elements) {
		elements = $('.' + elements);

		// eslint-disable-next-line @typescript-eslint/no-this-alias
		let parent = this;
		elements.each(function () {
			parent.attachTooltips($(this));
		});
	};

	/**
	 * Hide tooltips
	 *
	 * elements -> elements with tooltips
	 * target -> target element that was just clicked/hovered/focused
	 */
	this.hide = function (elements, target) {
		elements = $('.' + elements);

		elements.each(function () {
			if (!$(this).is(target) && $(this).has(target).length === 0 && $('.popover').has(target).length === 0) {
				$(this).popover('hide');
			}
		});
	};
};
