import Base64 from '@/lib/base64.js';
import { getGlobal } from '@/lib/utils.js';

export const defaultState = () => ({
  accountName: getGlobal('accountName'),
  countries: [],
  country: getGlobal('country'),
  phoneWithLeadingZeros: [],
  timezones: getGlobal('timezones') ? JSON.parse(Base64.decode(getGlobal('timezones'))) : null,
  momentLocale: getGlobal('momentLocale'),
  momentDateFormat: getGlobal('momentDateFormat'),
  momentTimeFormat: getGlobal('momentTimeFormat'),
  supportedLanguages: getGlobal('supportedLanguages') ? JSON.parse(Base64.decode(getGlobal('supportedLanguages'))) : [],
  preferredLanguage: getGlobal('preferredLanguage'),
  defaultLanguage: null,
  defaultTimezone: getGlobal('defaultTimezone'),
  currentBreadcrumb: getGlobal('currentBreadcrumb'),
  history: {},
  lastModifiedField: null,
  formulaFieldCompatibleTypes: getGlobal('formulaFieldCompatibleTypes') ? getGlobal('formulaFieldCompatibleTypes').clarify() : [],
  hyperFormulaLicense: getGlobal('hyperFormulaLicense') ? getGlobal('hyperFormulaLicense').clarify() : 'gpl-v3',
  flashMessage: getGlobal('flashMessage') ? JSON.parse(Base64.decode(getGlobal('flashMessage'))) : {},
  userSlug: null
});

const state = defaultState();

export default state;
