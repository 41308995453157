import { clarify } from '@/domain/services/VueDataProvider';
import obfuscate from '@/lib/obfuscate.js';
import { Player } from '@/domain/services/VideoPlayer/VideoPlayerWrapper';
import Storage from '@/lib/storage.js';
import { throttle } from 'lodash';
import { useVideoPlayerApi } from '@/domain/services/VideoPlayer/VideoPlayerApi';

let logged = 0;
let intervalId: number;
let isSending = false;

const storage = new Storage();

/**
 * Track viewing time of a video.
 */
const onStart = (player: Player) => {
	intervalId = player.setInterval(() => {
		/**
		 * Check readyState (3 = HAVE_FUTURE_DATA) to confirm enough data is available
		 * to advance the current playback position.
		 */
		if (player.readyState() > 3) {
			logged++;

			throttledStore();
			throttledSend();
		}
	}, 1000);
};

/**
 * Stop tracking viewing time of a video.
 */
const onStop = (player: Player) => {
	player.clearInterval(intervalId);

	/**
	 * When the playback stops it's a good signal to send the logged time to the server.
	 */
	send(player);
};

/**
 * Get current viewing time from storage. Attempt to clarify the obfuscated time from
 * storage, if that fails, return 0.
 */
const getCurrentTime = (player: Player) => {
	const playerId = player.id();
	let time: number | string | null = storage.get(playerId);

	if (!time) {
		return 0;
	}

	try {
		time = parseInt(clarify(time));
	} catch (e) {
		time = 0;
		setCurrentTime(time, player);
	}

	return time;
};

/**
 * Set current viewing time in storage.
 */
const setCurrentTime = (time: number, player: Player) => {
	const playerId = player.id();

	storage.set(playerId, obfuscate(time));
};

/**
 * Store viewing time of the video.
 */
const store = (player: Player) => {
	setCurrentTime(getCurrentTime(player) + logged, player);

	logged = 0;
};

/**
 * Send viewing time of the video.
 */
const send = (player: Player) => {
	const playerId = player.id();
	const fileId = playerId.split('-').pop();

	const loggedTime = getCurrentTime(player) + logged;
	/**
	 * Skip it if logged time is less than 3s or communication with the remote server
	 * is already in progress.
	 */
	if (loggedTime < 3 || isSending || !fileId) {
		return;
	}

	isSending = true;

	useVideoPlayerApi
		.putLog(fileId, { watched: loggedTime })
		.then(() => {
			setCurrentTime(0, player);
			logged = 0;
		})
		.catch((e) => {
			logged = 0;
			console.error('Failed to send logged time', e);
		})
		.finally(() => {
			isSending = false;
		});
};

/**
 * Debounced store method.
 */
const throttledStore = () => {
	throttle(store, 5000, { leading: true });
};

/**
 * Debounced send method.
 */
const throttledSend = () => {
	throttle(send, 30000, { leading: true });
};

export { onStart, onStop, isSending };
