<script>
var $ = require('jquery');
var tectoastr = require('tectoastr');
var Regions = require('@/lib/regions.js');

export default {
	props: {
		order: {
			type: String,
			required: true,
		},
		initialCompany: {
			type: String,
			required: true,
		},
		initialAddress: {
			type: String,
			required: true,
		},
		initialCountry: {
			type: String,
			required: true,
		},
		initialCity: {
			type: String,
			required: true,
		},
		initialState: {
			type: String,
			required: true,
		},
		initialRegion: {
			type: String,
			required: true,
		},
		initialPostcode: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			editing: false,
			company: this.initialCompany,
			address: this.initialAddress,
			country: this.initialCountry,
			city: this.initialCity,
			state: this.initialState,
			region: this.initialRegion,
			postcode: this.initialPostcode,
		};
	},
	computed: {
		regionName() {
			return this.state
				? this.state
				: $('.regions-' + this.country)
						.find('.select-region option:selected')
						.text();
		},
		countryName() {
			return $('.select-country')
				.find('option[value="' + this.country + '"]')
				.text();
		},
	},
	updated() {
		new Regions($('.countries'));
	},
	methods: {
		edit() {
			this.editing = true;
		},
		save() {
			$('#loader').show().fadeTo(250, 0.8);
			$.ajax({
				type: 'PUT',
				url: '/order/' + this.order + '/billing-details',
				dataType: 'json',
				data: {
					company: this.company,
					address: this.address,
					country: $('.select-country').find('option:selected').val(),
					city: this.city,
					state: $('.form-group-state').find('input:visible').val(),
					region: $('.select-region:visible').find('option:selected').val(),
					postcode: this.postcode,
				},
				success: (response) => {
					this.editing = false;
					this.company = response.company;
					this.address = response.address;
					this.country = response.country;
					this.city = response.city;
					this.state = response.state;
					this.region = response.region;
					this.postcode = response.postcode;
				},
				error: (error) => {
					tectoastr.error(error.responseJSON.message || $('#lang-strings #alerts-generic').text());
				},
				complete: () => {
					$('#loader').fadeTo(250, 0, function () {
						$(this).hide();
					});
				},
			});
		},
		cancel() {
			this.editing = false;
		},
	},
};
</script>
