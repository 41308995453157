<template>
  <button :aria-label="ariaLabel || type" class="btn arrow-btn" :class="buttonClass" :style="buttonStyle" @click="$emit('click')">
    <icon :name="icon"/>
  </button>
</template>

<script>
import Icon from "@/lib/components/Shared/Icon";

export default {
  name: "ArrowButton",
  components: {Icon},
  props: {
    radius: {
      type: Number,
      default: 25
    },
    size: {
      type: String,
      default: '24px'
    },
    type: {
      type: String,
      required: true,
      validator(value) {
        return ['left', 'right', 'up', 'down'].includes(value)
      }
    },
    variant: {
      type: String,
      default: 'secondary'
    },
    ariaLabel: {
      type: String,
      default: null
    }
  },
  computed: {
    icon() {
      return `arrow-tail-${this.type}`
    },
    buttonStyle() {
      return {
        width: this.radius*2 + 'px',
        height: this.radius*2 + 'px',
        borderRadius: this.radius + 'px',
        fontSize: this.size
      }
    },
    buttonClass() {
      return `btn-${this.variant}`
    }
  }
}
</script>

<style scoped>
  .arrow-btn {
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
  }
</style>
