export default function(value, data) {
  if (!data) return false;
  if (!Array.isArray(data)) data = [data];
  if (data.length === 0) return false;

  const min = 1 * data[0];
  const max = data.length > 1 ? 1 * data[1] : null;

  return (
    checkLength(value, min, max) &&
    hasLetters(value) &&
    hasNumbers(value) &&
    hasSpecialCharacters(value) &&
    hasUpperAndLowerCase(value)
  );
}

function checkLength(value, min, max) {
  if (max === null) {
    return `${value}`.length >= min;
  } else {
    return `${value}`.length >= min && `${value}`.length <= max;
  }
}

function hasLetters(value) {
  const pattern = /\p{L}+/gu;

  return pattern.test(value);
}

function hasNumbers(value) {
  const pattern = /\d/;

  return pattern.test(value);
}

function hasSpecialCharacters(value) {
  const pattern = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  return pattern.test(value);
}

function hasUpperAndLowerCase(value) {
  const pattern = /(\p{Ll}+.*\p{Lu})|(\p{Lu}+.*\p{Ll})+/gu;

  return pattern.test(value);
}