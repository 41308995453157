<template>
  <span>{{ formattedValue }}</span>
</template>

<script>
import { mapGetters } from 'vuex';
import filtersMixin from '@/lib/components/Fields/mixins/table-field-filters-mixin';
import calculationsMixin from '@/lib/components/Fields/mixins/table-field-calculations-mixin';
import validNumberMixin from '@/lib/components/Fields/mixins/table-field-valid-number-mixin';

export default {
  name: 'TableCalculation',
  inject: ['lang'],
  mixins: [filtersMixin, calculationsMixin, validNumberMixin],
  props: {
    values: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentLocale: this.lang.locale.replace('_', '-'),
      numberPrecision: {
        integer: 0,
        decimal: 1,
        currency: 2,
        'decimal-precise': 2
      }
    };
  },
  computed: {
    ...mapGetters(['getCalculations']),
    type() {
      return this.columnType(this.column);
    },
    precision() {
      return this.numberPrecision[this.type];
    },
    selectedCurrency() {
      return this.columnSelectedCurrency(this.column);
    },
    calculatedValue() {
      return this.getCalculatedValue(this.values, this.getCalculations(this.column).calculation);
    },
    formattedValue() {
      if (!this.validNumber(this.calculatedValue)) {
        return null;
      }

      if (this.columnCalculationFunction === 'count') {
        return this.calculatedValue;
      }

      const formattedValue = parseFloat(this.calculatedValue).toFixed(this.precision);

      return this.type === 'currency' ? this.formatCurrency(formattedValue) : formattedValue;
    }
  },
  methods: {
    formatCurrency(value) {
      const formatter = new Intl.NumberFormat(this.currentLocale, {
        style: 'currency',
        currency: this.selectedCurrency
      });

      return formatter.format(value);
    }
  }
};
</script>
