<template>
  <widget
    :class="['widget-score-set', 'widget-round-' + scoreSet.status, 'island']"
  >
    <template slot="overflow">
      <score-set-overflow-menu
        :score-set="scoreSet"
        :routes="routes"
        :permissions="permissions"
      />
    </template>
    <template slot="heading">
      {{ scoreSet.name }}
    </template>
    <score-set-stats
      :score-set="scoreSet"
      :stats="stats"
      :routes="routes"
      :permissions="permissions"
    />
    <div class="details mbm">
      <score-set-gallery-dates
        v-if="scoreSet.mode === 'gallery'"
        :score-set="scoreSet"
        :routes="routes"
      />
      <score-set-rounds v-else :rounds="rounds" :routes="routes" />
      <score-set-progress
        v-if="hasProgress"
        :score-set="scoreSet"
        :judging-progress="judgingProgress"
        :routes="routes"
        :permissions="permissions"
      />
    </div>
  </widget>
</template>

<script>
import _ from 'underscore';
import Widget from '@/lib/components/Shared/Widget';
import ScoreSetStats from './ScoreSetStats';
import ScoreSetRounds from './ScoreSetRounds';
import ScoreSetGalleryDates from './ScoreSetGalleryDates';
import ScoreSetProgress from './ScoreSetProgress';
import ScoreSetOverflowMenu from './ScoreSetOverflowMenu';

export default {
  inject: ['lang'],
  components: {
    Widget,
    ScoreSetStats,
    ScoreSetRounds,
    ScoreSetGalleryDates,
    ScoreSetProgress,
    ScoreSetOverflowMenu
  },
  props: {
    scoreSet: {
      type: Object,
      required: true
    },
    stats: {
      type: Object,
      required: true
    },
    rounds: {
      type: Array,
      required: true
    },
    routes: {
      type: Object,
      default: () => ({})
    },
    judgingProgress: {
      type: Object,
      default: () => ({})
    },
    permissions: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    hasProgress() {
      return !_.isEmpty(this.judgingProgress);
    }
  }
};
</script>
