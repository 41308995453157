<template>
	<div :class="['password-field', { error: hasError }]">
		<input
			:id="getId()"
			:type="revealed ? 'text' : 'password'"
			:name="name"
			class="form-control"
			:disabled="disabled"
			:placeholder="placeholder"
			:aria-invalid="hasError"
			:aria-describedby="ariaDescribedby"
			:aria-label="ariaLabel"
			:aria-required="isRequired ? 'true' : 'false'"
			:autocomplete="autoComplete.length ? autoComplete : undefined"
			@input="onInput($event.target.value)"
			@blur="$emit('change', $event)"
		/>
		<button
			class="password-field__reveal-button"
			:title="ariaText"
			:aria-label="ariaText"
			type="button"
			@click="toggleRevealed"
		>
			<i v-if="revealed" class="af-icons af-icons-read-off"></i>
			<i v-else class="af-icons af-icons-preview"></i>
		</button>
	</div>
</template>

<script>
import Field from './Field.vue';
export default {
	extends: Field,
	props: {
		field: {
			type: Object,
			default: () => ({}),
		},
		placeholder: {
			type: String,
			default: '',
		},
		isRequired: {
			type: Boolean,
			default: false,
		},
		autoComplete: {
			type: String,
			default: '',
		},
		ariaLabel: {
			type: String,
			default: '',
		},
	},
	data: () => ({
		revealed: false,
	}),
	computed: {
		ariaText() {
			return this.revealed ? 'hide password' : 'reveal password';
		},
	},
	methods: {
		getId() {
			return this.name;
		},
		toggleRevealed() {
			this.revealed = !this.revealed;
		},
	},
};
</script>
