type ToolbarItem =
	| 'bold'
	| 'italic'
	| 'underline'
	| 'link'
	| 'attachFile'
	| 'blockQuote'
	| 'code'
	| 'bulletedList'
	| 'numberedList'
	| 'alignment:left'
	| 'alignment:center'
	| 'alignment:right'
	| 'outdent'
	| 'indent'
	| 'mediaEmbed'
	| '|';

const toolbar: ToolbarItem[] = [
	'bold',
	'italic',
	'underline',
	'|',
	'link',
	'attachFile',
	'blockQuote',
	'code',
	'|',
	'bulletedList',
	'numberedList',
	'|',
	'alignment:left',
	'alignment:center',
	'alignment:right',
	'|',
	'outdent',
	'indent',
];

const mediaEmbed: ToolbarItem[] = ['|', 'mediaEmbed'];

const customToolbar = (config: { toolbarMediaEmbed?: boolean }): ToolbarItem[] => {
	let customToolbar = toolbar;

	if (config.toolbarMediaEmbed) {
		customToolbar = [...customToolbar, ...mediaEmbed];
	}

	return customToolbar;
};

export { toolbar, mediaEmbed, customToolbar, ToolbarItem };
