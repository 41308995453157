import Vue from 'vue';
import Webhooks from './Webhooks';
import store from '@/lib/store';

export default function() {
    const webhooks = new Vue({
        el: '#webhooks',
        name: 'WebhooksApp',
        components: {
            Webhooks
        },
        store
    });
    $(document).one('pjax:end', () => {
        webhooks.$destroy();
    });
}
