import { Tabs } from 'vue-bootstrap';

export default {
  components: {
    Tabs
  },
  data() {
    return {
      tabId: null
    };
  },
  methods: {
    selectTab(tabId) {
      this.tabId = tabId;
    }
  }
};
