<template>
  <div v-if="hasErrors" :id="id" class="alert-error sticky inline" role="alert">
    <div v-for="error in validationErrors" :key="error">
      {{ stripTags(error) }}
    </div>
  </div>
</template>

<script>
import { stripTags } from '@/lib/string';

export default {
  props: {
    attribute: {
      type: String,
      default: ''
    },
    validationErrors: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    hasErrors() {
      return this.validationErrors.length;
    },
    id() {
      return this.attribute
        ? this.attribute
            .replaceAll(' ', '_')
            .replaceAll('.', '_')
            .replaceAll('[]', '')
            .replaceAll('[', '.')
            .replaceAll(']', '') + 'Error'
        : null;
    }
  },
  methods: {
    stripTags(text) {
      return stripTags(text);
    }
  }
};
</script>
