<template>
  <input
    :value="value"
    type="text"
    name="keywords"
    class="form-control keywords"
    autocomplete="off"
    :placeholder="placeholder"
    @keydown="$emit('keydown')"
  />
</template>

<script>
export default {
  props: {
    keywords: {
      type: String,
      default: ''
    },
    tooltipText: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      value: this.keywords
    };
  }
};
</script>
