<script>
import ResourceList from '../../lib/components/ResourceList.vue';
import ListAction from '../../lib/components/ListActions/ListAction.vue';
import Deletism from '../../lib/components/ListActions/Deletism.vue';

export default {
  components: {
    Deletism,
    ListAction
  },
  extends: ResourceList
};
</script>
