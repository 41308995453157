var base64 = require('./base64.js');

/**
 * A companion to obfuscate.js
 */
// eslint-disable-next-line no-extend-native
String.prototype.clarify = function () {
	return JSON.parse(base64.decode(this));
};

// eslint-disable-next-line no-extend-native
String.prototype.clarifyDecode = function () {
	return JSON.parse(decodeURIComponent(base64.decode(this).replace(/\+/g, ' ')));
};
