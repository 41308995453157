<template>
	<div class="marker-action">
		<list-action-form
			ref="form"
			:ids="ids"
			:labels="labels"
			:route="route"
			:method="method"
			:button-class="buttonClass"
			@submitted="submit"
		>
		</list-action-form>
	</div>
</template>

<script>
import ListActionForm from './ListActionForm.vue';

const $ = require('jquery');

/**
 * The base ListAction component.
 */
export default {
	components: {
		ListActionForm,
	},
	props: {
		ids: {
			type: Array,
			default: () => [],
		},
		labels: {
			type: Object,
			default: () => ({}),
		},
		route: {
			type: String,
			required: true,
		},
		method: {
			type: String,
			required: true,
		},
		buttonClass: {
			type: String,
			default: null,
		},
	},
	methods: {
		submit() {
			$(this.$refs.form.$el).submit();
		},
	},
};
</script>
