module.exports = function() {

  function displayFormElements(type) {
    $('.tier-type').addClass('hidden');
    $('.tier-type-' + type).removeClass('hidden');
  }

  $('select#type').on('change', function() {
    displayFormElements($(this).val());
  });

  displayFormElements($('select#type').val());

};
