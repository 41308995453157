import Vue from 'vue';

function mountBrandFooter(c, el, store) {
  new Vue({
    name: 'BrandFooter',
    el,
    components: { 'brand-footer': c },
    store
  });
}

const brandFooterEl = document.getElementById('brand-footer');

if (brandFooterEl) {
  import('@/lib/store').then( store =>
    import('@/lib/components/Layout/BrandFooter').then(
      BrandFooter => mountBrandFooter(BrandFooter.default, brandFooterEl, store.default)
    )
  )
}
