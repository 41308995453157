/**
 * generic Class for managing filters panel for matrix
 */
module.exports = class {

    constructor() {
        this.onChangeCallback = () => {};
        this.filters = [];
    }

    /**
     * adds new filter UI element to controller
     *
     * @param element
     * @returns {Filter}
     */
    addFilter(element, name) {
        this.filters.push({name, element})
        element.on(
            'change',
            () => this.onChangeCallback(row => this.filterTests.filter(test => test(row)).length)
        );
        return this;
    }

    /**
     * adds onChange handler to the controller
     *
     * @param handler
     * @returns {Filter}
     */
    addOnChangeCallback(handler) {
        this.onChangeCallback = handler;
        return this;
    }

    /**
     * adds a reset triggering UI element
     *
     * @param button
     */
    addResetTrigger(button) {
        button.on(
            'click',
            () => this.reset()
        );
        return this;
    }

    /**
     * returns an array of functions each of which takes a table Row as an argument
     * if the Row fails to pass a corresponding filter the function returns true
     *
     * @returns {(function(*): boolean)[]}
     */
    get filterTests() {
        return this.filters.map(
            filter => (
                {
                    value: filter.element.val(),
                    name: filter.name
                }
            )
        )
            .filter(filter => filter.name)
            .map(filter => row => {
                return filter.value && (row.data(filter.name) + '').indexOf(filter.value) === -1 && (row.data(filter.name) + '').indexOf('all') === -1;
            });
    }

    /**
     * method resetting all filters
     */
    reset() {
        this.filters.forEach(filter => filter.element.prop('selectedIndex', 0).change());
    }
}
