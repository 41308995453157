<template>
  <action-dropdown
    id="list-action-dropdown"
    ref="dropdown"
    :label="$attrs.label"
    :position="$attrs.position"
    button-class="btn btn-primary btn-lg"
    menu-class="dropdown action-dropdown-primary"
  >
    <slot></slot>
  </action-dropdown>
</template>

<script>
import ActionDropdown from '../Shared/ActionDropdown.vue';
import isTouchDevice from '@/lib/is-touch-device.js';

export default {
  components: {
    ActionDropdown
  },
  inheritAttrs: false,
  props: {
    revealedAction: {
      type: String,
      default: null
    },
    action: {
      type: String,
      default: null
    }
  },
  watch: {
    revealedAction() {
      this.$refs.dropdown.close();
    }
  }
};
</script>
