<template>
  <div class="form-group">
    <label for="label">{{ label }}</label>
    <div
      v-for="language in supportedLanguages"
      :key="language"
      class="input-group"
    >
      <div class="input-group-addon">{{ language }}</div>
      <input v-model="translated[language]" type="text" class="form-control" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Label'
    },
    translations: {
      type: Object,
      required: true
    },
    supportedLanguages: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      translated: {}
    };
  },
  watch: {
    translated() {
      this.$emit('update:translated', this.translated);
    }
  },
  created() {
    this.translated = Object.assign({}, this.translations);
  }
};
</script>
