/**
 * Take an object with translations:
 *   { en_GB: { property: value }}
 *
 * And flip it:
 *   { property: { en_GB: value }}
 */
export const flipTranslations = translations => {
  const translated = {};
  const languages = Object.keys(translations);

  languages.forEach(language => {
    Object.keys(translations[language])
      .forEach(property => {
        if (!translated[property]) {
          translated[property] = {};
        }
        if (typeof  translations[language][property] === 'object') {
            translated[property][language] = JSON.stringify(translations[language][property]);
        } else {
            translated[property][language] = translations[language][property];
        }
      });
  });

  return translated;
};

/**
 * Replace newline characters from:
 *   { en_GB: { property: 'One\nTwo\nThree' }}
 *
 * To:
 *   { en_GB: { property: 'One\r\nTwo\r\nThree' }}
 */
export const convertNewlines = (translations, newline = '\r\n') => {
  const languages = Object.keys(translations);

  languages.forEach(language => {
    Object.keys(translations[language]).forEach(property => {
      translations[language][property] =
        translations[language][property].replace(/(\r)?\n/g, newline);
    });
  });

  return translations;
};
