<template>
  <td
    :class="[
      'cell',
      'cell-' + type,
      {
        'cell-editable': isEditable,
        'cell-disabled': !isEditable,
        'cell-last': isLast
      }
    ]"
  >
    <template v-if="component">
      <component
        :is="component"
        :type="type"
        :input.sync="value"
        :disabled="disabled"
        :label="key"
        :currency="selectedCurrency"
      ></component>
    </template>
    <template v-else>
      {{ translatedCellName }}
    </template>
  </td>
</template>

<script>
import TextInput from './TableFieldTextInput.vue';
import NumberInput from './TableFieldNumberInput.vue';
import CurrencyInput from './TableFieldCurrencyInput';
import { mapGetters, mapMutations } from 'vuex';
import filtersMixin from './mixins/table-field-filters-mixin.js';
import { getGlobal } from '@/lib/utils';

export default {
  components: {
    TextInput,
    NumberInput,
    CurrencyInput
  },
  mixins: [filtersMixin],
  props: {
    column: {
      type: String,
      required: true
    },
    columnIndex: {
      type: Number,
      required: true
    },
    row: {
      type: String,
      required: true
    },
    rowIndex: {
      type: Number,
      required: true
    },
    lastColumnIndex: {
      type: Number,
      required: true
    },
    labels: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: null
    };
  },
  computed: {
    ...mapGetters(['getCellValue', 'getTranslation']),
    key() {
      return this.column + ':' + this.row;
    },
    translatedCellName() {
      return (
        this.getTranslation(getGlobal('language'), this.key) ||
        this.getTranslation(getGlobal('defaultLanguage'), this.key)
      );
    },
    type() {
      return this.columnType(this.column);
    },
    selectedCurrency() {
      return this.columnSelectedCurrency(this.column);
    },
    isEditable() {
      return this.type !== 'label';
    },
    isLast() {
      return this.columnIndex === this.lastColumnIndex;
    },
    component() {
      switch (this.type) {
        case 'label':
          return;
        case 'integer':
        case 'decimal':
        case 'decimal-precise':
          return 'NumberInput';
        case 'currency':
          return 'CurrencyInput';
        default:
          return 'TextInput';
      }
    }
  },
  watch: {
    value() {
      this.setCellValue({
        row: this.row,
        column: this.column,
        value: this.value
      });
    }
  },
  created() {
    this.value = this.getCellValue(this.row, this.column);
  },
  methods: {
    ...mapMutations(['setCellValue'])
  }
};
</script>
