/* global MediaInfo */
const getSize = (file) => () => file.size;

const readChunk = (file) => (chunkSize, offset) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = ({ target: { error, result } }) => (error ? reject(error) : resolve(new Uint8Array(result)));
		const source = file.getSource().getSource();
		reader.readAsArrayBuffer(source.slice(offset, offset + chunkSize));
	});

const getVideoDuration = async (file) => {
	try {
		const mediainfo = await MediaInfo.default();
		const result = await mediainfo.analyzeData(getSize(file), readChunk(file));
		const generalTrack = result.media.track.find((track) => track['@type'] === 'General');
		return generalTrack ? generalTrack.Duration : null;
	} catch (error) {
		console.error(error);
	}
};

const getImageWidth = async (file) => {
	try {
		const mediainfo = await MediaInfo.default();
		const result = await mediainfo.analyzeData(getSize(file), readChunk(file));
		const generalTrack = result.media.track.find((track) => track['@type'] === 'Image');
		return generalTrack ? generalTrack.Width : null;
	} catch (error) {
		console.error(error);
	}
};

const getImageHeight = async (file) => {
	try {
		const mediainfo = await MediaInfo.default();
		const result = await mediainfo.analyzeData(getSize(file), readChunk(file));
		const generalTrack = result.media.track.find((track) => track['@type'] === 'Image');
		return generalTrack ? generalTrack.Height : null;
	} catch (error) {
		console.error(error);
	}
};

export { getVideoDuration, getImageWidth, getImageHeight };
