<script>
import ResourceList from '@/lib/components/ResourceList';
import Deletism from '@/lib/components/ListActions/Deletism';
import Undeletism from '@/lib/components/ListActions/Undeletism';
import ListAction from '@/lib/components/ListActions/ListAction';
import Tagger from '@/lib/components/ListActions/Tagger';
import langMixin from '@/lib/components/Translations/mixins/lang-mixin';
import { mapState } from 'vuex';
import $ from 'jquery';
import CreateDocument from '@/lib/components/ListActions/CreateDocument.vue';

export default {
	components: {
		Deletism,
		ListAction,
		Undeletism,
		Tagger,
		CreateDocument,
	},
	extends: ResourceList,
	mixins: [langMixin],
	computed: {
		...mapState('allocationPayments', ['modalIsOpen', 'hasChanges']),
		taggerRevealed() {
			return this.reveal === 'tagger';
		},
		untaggerRevealed() {
			return this.reveal === 'untagger';
		},
		createDocumentRevealed() {
			return this.reveal === 'create-document';
		},
	},
	watch: {
		modalIsOpen(newValue, oldValue) {
			if (newValue === false && oldValue === true && this.hasChanges) {
				$.pjax.reload('#pjaxContainer');
			}
		},
	},
};
</script>
