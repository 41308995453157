export default {
    data() {
        return {
            showModal: false,
        };
    },
    methods: {
        toggle() {
            this.showModal = !this.showModal;
            if (this.showModal) {
                this.$nextTick(function () {
                    this.$emit('reveal', this.name);
                });
            }
        },
        modalClosed() {
            this.showModal = false;
            this.$nextTick(function() {
                this.$emit('reveal', null);
            });
        },
    }
};
