import Vue from 'vue';

var tectoastr = require('../../../tectoastr.js');

export default {
	saveEntryPreference({ commit, getters, state }, { scoreSetSlug, entrySlug, value, category }) {
		const oldValue = getters.entryPreference(entrySlug);

		if (state.preferenceSaving[entrySlug]) {
			return;
		}

		commit('storeEntryPreferenceSaving', { entrySlug, value });
		commit('storeEntryPreference', { entrySlug, value: 0, category });

		return Vue.prototype.$http.post(`/entry/pick/${scoreSetSlug}/${entrySlug}`, { preference: value }).then(
			(response) => {
				commit('storeEntryPreferenceSaving', {
					entrySlug: response.data.entrySlug,
					value: 0,
				});
				commit('storeEntryPreference', {
					entrySlug: response.data.entrySlug,
					value: response.data.value,
					category: response.data.category,
				});
				commit('storeAssignmentPreferences', response.data.current);
			},
			(response) => {
				commit('storeEntryPreferenceSaving', { entrySlug, value: 0 });
				commit('storeEntryPreference', { entrySlug, oldValue });

				/* eslint-disable no-prototype-builtins */
				if (response.hasOwnProperty('responseJSON') && response.responseJSON.hasOwnProperty('preference')) {
					tectoastr.error(response.responseJSON.preference.join());
				} else if (
					response.hasOwnProperty('response') &&
					response.response.hasOwnProperty('data') &&
					response.response.data.type === 'preview-mode'
				) {
					tectoastr.error(response.response.data.message);
				} else {
					tectoastr.error(state.translations['miscellaneous.alerts.generic']);
				}
				/* eslint-enable no-prototype-builtins */
			}
		);
	},
};
