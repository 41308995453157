import Vue from 'vue';
import store from '@/lib/store';
import RoleList from '../review-flow/component/RoleList';

export default function() {
  const roleList = new Vue({
    el: '#role-list',
    name: 'RoleListApp',
    components: {
      RoleList
    },
    store
  });
  $(document).one('pjax:end', () => {
    roleList.$destroy();
  });
}
