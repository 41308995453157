import _ from 'underscore';
import { sanitizeObject } from '@/lib/utils';

const filterMenuByContext = (menu, selectedContext) => {
  menu = sanitizeObject(menu);
  menu.children = menu.children.reduce((filtered, item) => {
    if (item.type !== 'menu' && item.contexts.includes(selectedContext)) {
      filtered.push(item);
    }

    if (item.type === 'menu' && item.children) {
      filtered.push(filterMenuByContext(item, selectedContext));
    }
    return filtered;
  }, []);
  return menu;
};

const filterMenuHasNoChildren = menu =>
  menu.children?.filter(child => {
    if (child.type === 'menu') {
      return child.children.length > 0;
    }
    return true;
  });

const formatMainMenu = menu => {
  menu = sanitizeObject(menu);
  menu.children = menu.children?.reduce((filtered, child) => {
    child.children = filterMenuHasNoChildren(child);

    if (child.type === 'menu') {
      if(child.children?.length === 0) {
        child.type = null;
        delete child.children;
      }else if (child.children.length === 1 && child.children[0].type !== 'menu') {
        child['link'] = child.children[0].link;
        child['type'] = child.children[0].type;
        delete child.children;
      }
    }

    if (child.type !== null) {
      filtered.push(child);
    }
    return filtered;
  }, []);

  return menu;
};

export default {
  setContextMenuData({ commit, dispatch }, payload) {
    commit('storeBaseMenu', payload.baseMenu);
    commit('setContexts', payload.contexts);
    dispatch('generateContextMainMenu');
    if (payload.contexts) {
      payload.contexts.forEach(context => {
        if (context.selected === true) {
          dispatch('setSelectedContext', context);
        }
      });
    }
  },
  generateContextMainMenu({ state, commit }) {
    if(state.baseMenu && state.contexts.length > 0) {
      var mainMenu = {};
      state.contexts.forEach(item => {
        mainMenu[item.name] = formatMainMenu(filterMenuByContext(state.baseMenu, item.name));
      });
      commit('setMainMenu', mainMenu);
    }
  },
  setSelectedContext({ commit }, payload) {
    commit('setSelectedContext', payload);
  },
  getMenuItemByName({ state }, payload) {
    let selectedMenu = state.mainMenu[state.selectedContext.name];
    if (selectedMenu) {
      return _.where(selectedMenu.children, { name: payload }).find(e => true);
    }
  }
};
