<script>
import ResourceList from '@/lib/components/ResourceList.vue';
import Deletism from '@/lib/components/ListActions/Deletism.vue';
import Undeletism from '@/lib/components/ListActions/Undeletism.vue';
import ListAction from '@/lib/components/ListActions/ListAction.vue';
import FormBox from './FormBox.vue';

export default {
  components: {
    Deletism,
    Undeletism,
    ListAction,
    FormBox
  },
  extends: ResourceList
};
</script>
