<template>
	<div>
		<div class="form-group">
			<div class="upload-wrapper">
				<uploader
					v-if="uploaderOptions"
					:files="files"
					:disabled="!canSign"
					:options="uploaderOptions.clarify()"
					:button-label="lang.get('files.buttons.single')"
					@deleted="deleteFile"
					@uploaded="uploaded"
				></uploader>
			</div>
			<input v-if="fileToken" v-model="fileToken" type="hidden" name="uploadToken" />
		</div>
		<div class="form-group">
			<button type="submit" class="btn btn-primary btn-lg" :disabled="isDisabled">
				{{ lang.get('signatures.form.agree.label') }}
			</button>
		</div>
	</div>
</template>

<script>
import toastr from 'toastr';
import { route } from '@/lib/utils';
import Uploader from '@/lib/components/Uploader/Uploader';

export default {
	components: { Uploader },
	props: {
		canSign: {
			type: Boolean,
			default: true,
		},
		uploaderOptions: {
			type: String,
			required: true,
		},
		routes: {
			type: Object,
			default: () => {},
		},
		contract: {
			type: Object,
			required: true,
		},
	},
	inject: ['lang', 'applicationLinks', 'featuresService'],
	data() {
		return {
			fileToken: null,
			files: [],
		};
	},
	computed: {
		isDisabled() {
			return !this.canSign || !this.fileToken;
		},
	},
	methods: {
		uploaded(token, file) {
			if (token && file) {
				this.fileToken = token;
			}
		},
		deleteFile(id, remoteId) {
			if (remoteId) {
				this.$http
					.delete(
						route(this.routes['entry.entrant.delete.signature'], {
							contract: this.contract.slug,
							id: remoteId,
						})
					)
					.then(
						() => {
							this.fileToken = null;
							this.files = [];
						},
						() => {
							toastr.error(this.lang.get('miscellaneous.alerts.generic'));
						}
					);
			}
		},
	},
};
</script>
