import Vue from 'vue';

export default {
  /**
   * Store menu data
   */
  storeBaseMenu(state, payload) {
    Vue.set(state, 'baseMenu', payload);
  },
  setMainMenu(state, payload) {
    Vue.set(state, 'mainMenu', payload);
  },
  setContexts(state, payload) {
    Vue.set(state, 'contexts', payload);
  },
  setSelectedContext(state, payload) {
    Vue.set(state, 'selectedContext', payload);
  }
};
