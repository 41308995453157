var $ = require('jquery');
// eslint-disable-next-line @typescript-eslint/naming-convention
var Storage = require('./storage.js');

module.exports = {
	init: function () {
		var storage = new Storage();

		// Initialisation after page load
		$(document).on('ready pjax:end', function () {
			// Info-boxes
			$('[id^="info-box-"]').each(function () {
				var preference = storage.get($(this).attr('id'));

				// If a preference has been set for this info-box, enforce it
				if (preference === 'open') {
					openBox($(this));
				} else if (preference === 'closed') {
					closeBox($(this));
				} else if ($(this).hasClass('initially')) {
					// Save preference the first time the user sees an 'open initially' info-box
					storage.set($(this).attr('id'), 'closed');
				}
			});

			// Quick category selector
			$('.quick-selector:not(.ignore)').each(function () {
				var preference = storage.get($(this).attr('id'));

				if (preference === 'closed' || (preference == null && $('#sm-screen-detector').is(':visible'))) {
					closeBox($(this));
				}
			});
		});

		var content = $('#content');

		// Info-boxes
		content.on('click', '.open-info-box', function () {
			var infoBox = $(this).parents('.info-box');
			toggleBox(infoBox, infoBox.attr('id'));
		});

		// Quick selector
		content.on('click', '.open-quick-selector:not(.ignore)', function () {
			var selector = $(this).parents('.quick-selector');
			toggleBox(selector, selector.attr('id'));
		});

		var toggleBox = function (box, storageId) {
			if (box.hasClass('closed')) {
				openBox(box);
				storage.set(storageId, 'open');
			} else {
				closeBox(box);
				storage.set(storageId, 'closed');
			}
		};

		function openBox(box) {
			var expander = box.find('[class*="af-icons-chevron-"]');

			box.find('.open-info-box', '.open-quick-selector:not(.ignore)').attr('aria-expanded', 'true');
			box.removeClass('closed');
			box.addClass('open');

			expander.removeClass('af-icons-chevron-up');
			expander.addClass('af-icons-chevron-down');
		}

		function closeBox(box) {
			var expander = box.find('[class*="af-icons-chevron-"]');

			box.find('.open-info-box', '.open-quick-selector:not(.ignore)').attr('aria-expanded', 'false');
			box.removeClass('initially open');
			box.addClass('closed');

			expander.removeClass('af-icons-chevron-down');
			expander.addClass('af-icons-chevron-up');
		}
	},
};
