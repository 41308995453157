import { mountVueComponent } from '@/lib/mount-vue-component';
import FileTypes from '@/lib/filetypes';
import $ from 'jquery';
import Uploader from 'uploader';

export default function() {
  new FileTypes();

  // Setup the Uploader, if included on the page.
  if ($('#uploader').length > 0) {
    new Uploader(uploaderOptions.clarify());
  }

  // helpIcon
  import('@/lib/components/Shared/HelpIcon.vue').then(HelpIcon => {
    mountVueComponent('help-icon', HelpIcon);
  });
}
