<template>
  <div>
    <input
      v-if="element === 'input'"
      :id="id"
      :name="name"
      :disabled="disabled"
      :required="required"
    />
    <select
      v-else
      :id="id"
      :name="name"
      :disabled="disabled"
      :required="required"
    ></select>
  </div>
</template>

<script>
// Cannot be tested otherwise
import select2 from '../select2';
import $ from 'jquery';

export default {
  model: {
    event: 'change',
    prop: 'value'
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    element: {
      type: String,
      default: 'select'
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    settings: {
      type: Object,
      default: () => {}
    },
    value: null
  },
  data() {
    return {
      select2: null
    };
  },
  watch: {
    options(val) {
      if (this.element === 'input') {
        this.setOption(val);
      }
    },
    value(val) {
      this.setValue(val);
    }
  },
  mounted() {
    const settings = {
      placeholder: this.placeholder,
      ...this.settings
    };
    if (this.element === 'input') {
      settings.data = this.options;
    }
    this.select2 = $(this.$el)
      .find(this.element)
      .select2(settings)
      .on('select2:select select2:unselect change', ev => {
        this.$emit('change', this.select2.val());
        if (ev['params'] !== undefined) {
          this.$emit('select', ev['params']['data']);
        }
      })
      .on('select2:closing', ev => {
        this.$emit('closing', ev);
      })
      .on('select2:close', ev => {
        this.$emit('close', ev);
      })
      .on('select2:opening', ev => {
        this.$emit('opening', ev);
      })
      .on('select2:open', ev => {
        this.$emit('open', ev);
      })
      .on('select2:clearing', ev => {
        this.$emit('clearing', ev);
      })
      .on('select2:clear', ev => {
        this.$emit('clear', ev);
      });
    this.setValue(this.value);
  },
  beforeDestroy() {
    this.select2.select2('destroy');
  },
  methods: {
    setOption(val = []) {
      this.select2.empty();
      this.select2.select2({
        placeholder: this.placeholder,
        ...this.settings,
        data: val
      });
      this.setValue(this.value);
    },
    setValue(val) {
      if (val instanceof Array) {
        this.select2.val([...val]);
      } else {
        this.select2.val([val]);
      }
      this.select2.trigger('change');
    }
  }
};
</script>
