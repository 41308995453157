import { camelCase } from 'lodash';
import { isString } from '@/domain/utils/TypePredicates';
import { NestedObject } from '@/domain/utils/UtilityTypes';

/**
 * Universal data accessor
 * based on string ids it maps data
 * retrieved by a getter function
 * into an object or an array
 *
 * @param getter - function that retrieves data by id
 * @returns function that maps data into an object or an array
 */
const objAccessor =
	<T = any>(getter: (key: string) => T) =>
	(keys: string[]): Record<string, T> =>
		keys.reduce((acc, key) => {
			acc[camelCase(key)] = getter(key);
			return acc;
		}, {} as Record<string, T>);

// this is temporary solution used by deprecated services
// it will be removed with global/services/**
const arrAccessor =
	<T = any>(getter: (key: string) => T) =>
	(keys: string[]): T[] =>
		keys.map((key) => getter(key));

/**
 * Creates DAO object from a repository
 * with keys as dot notation strings i.e. this:

 const things: Record<string, string> = {
	 'foo.bar1': 'foo-bar-1',
	 'foo.bar2': 'foo-bar-2',
 };

 * will be converted to equivalent of this:

 const thingsDao = {
 	foo: {
 		bar1: 'foo-bar-1',
 		bar2: 'foo-bar-2',
 	},
	'foo.bar1': 'foo-bar-1',
	'foo.bar2': 'foo-bar-2',
 };
 */
const createDao = <T = unknown>(repository: NestedObject = {}, nested = true) =>
	new Proxy(repository, {
		get: (record, propertyName) => {
			if (!isString(propertyName)) {
				return record;
			}

			if (propertyName in record) {
				return record[propertyName];
			}

			if (nested !== true) {
				return undefined;
			}

			const children = Object.keys(record)
				.filter((key) => key.startsWith(`${propertyName}.`))
				.map((key) => key.replace(new RegExp(`^${propertyName}.`, 'i'), ''));

			if (children.length > 0) {
				const obj: Record<string, unknown> = {};
				for (const childName of children) {
					let pointer = obj;
					childName.split('.').map((key, index, arr) => {
						if (pointer[key] === undefined) {
							pointer[key] = index === arr.length - 1 ? record[`${propertyName}.${childName}`] : {};
						}

						pointer = pointer[key] as Record<string, unknown>;
					});
				}

				return obj;
			}
		},
	}) as T;

/**
 * Creates flat ( aenemic ) DAO from a repository
 * with keys as dot notation strings i.e. this:

 const things: Record<string, string> = {
 	'foo': 'foo-val',
 	'bar': 'bar-val',
 };

 * will be converted to equivalent of this:

 const thingsDao = {
	 foo: 'foo-bar-1',
	 bar: 'foo-bar-2',
 };

 * the purpose of this is to have DAO i.e.
 * an object that can be accessed as mormal Object
 * but with property names unknown at compile time
 */

const createFlatDao = <T = Record<string, unknown>>(repository: Record<string, unknown>) =>
	new Proxy(repository, {
		get: (record: Record<string, unknown>, propertyName) => (isString(propertyName) ? record[propertyName] : record),
	}) as T;

export { objAccessor, arrAccessor, createDao, createFlatDao };
