<template>
  <div :class="['inserter', { active: isActive }]">
    <a
      v-if="enabled"
      :href="href"
      class="toggle not-draggable"
      :style="{ left: indent + 'px' }"
      @click="onClick"
    >
      <div class="line" :style="{ width: lineWidth }" />
      <div class="icon">
        <span class="af-icons af-icons-add"></span>
      </div>
    </a>
    <div v-else class="toggle not-draggable"></div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: null
    },
    enabled: {
      type: Boolean,
      default: true
    },
    indent: {
      type: Number,
      default: 0
    }
  },
  computed: {
    isActive() {
      return typeof this.active === 'boolean' && this.active;
    },
    lineWidth() {
      if (this.indent !== 0) {
        const sign = this.indent > 0 ? '-' : '+';
        return 'calc(100% ' + sign + ' ' + Math.abs(this.indent) + 'px - 16px)';
      }

      return 'calc(100% - 16px)';
    }
  },
  methods: {
    onClick(e) {
      // When the href attribute is provided Inserter acts as a link
      if (this.href) {
        return;
      }

      e.preventDefault();
      this.$emit('clicked');
    }
  }
};
</script>
