<script>
import ResourceList from '@/lib/components/ResourceList.vue';
import Deletism from '@/lib/components/ListActions/Deletism.vue';
import Undeletism from '@/lib/components/ListActions/Undeletism.vue';
import ListAction from '@/lib/components/ListActions/ListAction.vue';
import RoleSelector from '@/lib/components/ListActions/RoleSelector';
import Destroyer from '@/lib/components/ListActions/Destroyer';
import FormInviterListAction from '@/modules/forms/components/FormInviterListAction';
import CreateDocument from '@/lib/components/ListActions/CreateDocument';
import langMixin from '@/lib/components/Translations/mixins/lang-mixin';

export default {
	components: {
		Deletism,
		Undeletism,
		ListAction,
		RoleSelector,
		Destroyer,
		CreateDocument,
		FormInviterListAction,
	},
	extends: ResourceList,
	mixins: [langMixin],
	computed: {
		roleSelector() {
			return (name) => this.reveal === name;
		},
		destroyerRevealed() {
			return this.reveal === 'destroyer';
		},
		createDocumentRevealed() {
			return this.reveal === 'create-document';
		},
		formInviterRevealed() {
			return this.reveal === 'form-inviter';
		},
	},
};
</script>
