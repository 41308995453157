var Vue = require('vue');
var Cards = require('../../lib/cards.js');
var SearchField = require('vue-bootstrap').SearchField;

module.exports = function() {
    /**
     * Cards
     */
    var cards = new Cards;
    cards.setup('ul.cards', 'li', 'masonry');

    /**
     * Filtertron
     */
    $('.filtertron').filtertron('entries', {
        expander: '#filtertronExpander',
        cards: cards
    });

    /**
     * Category selector
     */
    const categorySelectorEl = document.getElementById('category-selector');

    if (categorySelectorEl) {
        const categorySelector = new Vue({
            el: categorySelectorEl,
            name: 'GalleryApp',
            components: {
                SearchField
            }
        });

        $(document).one('pjax:end', () => {
            categorySelector.$destroy();
        });
    }
};
