
import { defineComponent } from 'vue';
import { fileMetadataController, Props, View } from '@/modules/entries/components/FileMetadata.controller';
import { useController } from '@/domain/services/Composer';

export default defineComponent<Props, View>({
	name: 'FileMetadata',
	props: {
		file: {
			type: Number,
			required: true,
		},
	},
	setup: useController(fileMetadataController, 'FileMetadataController') as () => View,
});
