<template>
	<div class="marker-action">
		<list-action-form
			ref="form"
			:ids="ids"
			:labels="labels"
			:route="route"
			:method="method"
			:button-class="buttonClass"
			@submitted="toggle"
		>
			<input type="hidden" name="review-stage" :value="reviewStage" />
			<portal to="reviewer">
				<div class="reviewer-modal">
					<modal
						v-model="showModal"
						:header="false"
						:confirm-button-label="labels.title"
						:close-button-label="labels.cancel"
						:header-close-button="true"
						:confirm-disabled="!reviewStage"
						:confirm-on-enter="false"
						@closed="handleClose"
						@confirmed="submit"
					>
						<close-icon slot="before-content" @click.prevent="toggle"></close-icon>
						<div :key="name">
							<label for="reviewStageSelector">{{ labels.label }}</label>
							<select-field
								id="reviewStageSelector"
								:aria-label="labels.label"
								:items="stages"
								:value="reviewStage"
								:empty-option="true"
								@selected="onReviewStageSelected"
							>
							</select-field>
						</div>
					</modal>
				</div>
			</portal>
		</list-action-form>
	</div>
</template>

<script>
import ListAction from './ListAction.vue';
import CloseIcon from './Partials/CloseIcon';
import { Modal, SelectField } from 'vue-bootstrap';
import modalMixin from './mixins/modal-mixin';

/**
 * The Reviewer component.
 */
export default {
	components: {
		CloseIcon,
		SelectField,
		Modal,
	},
	extends: ListAction,
	mixins: [modalMixin],
	props: {
		name: {
			type: String,
			required: true,
			validator: (value) => ['reviewer'].indexOf(value) !== -1,
		},
		stages: {
			type: Array,
			required: true,
		},
		buttonClass: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			reviewStage: null,
		};
	},
	methods: {
		onReviewStageSelected(name, value) {
			this.reviewStage = value;
		},
		handleClose() {
			this.reviewStage = null;
			this.modalClosed();
		},
	},
};
</script>
