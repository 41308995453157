const makeId = () => {
	const randomNumber = Math.random().toString().slice(2, 12);
	const timestamp = new Date().getTime();

	return `new-${randomNumber}-${timestamp}`;
};

/**
 * The format of extensions in backend is different from the one
 * in frontend. We need to prefix every file extension (jpg, png)
 * with the extension itself, so ['jpg'] becomes {jpg: 'jpg'}
 */
const transformFileTypes = (fileTypes) => {
	const extensions = {};

	Object.keys(fileTypes).forEach((group) => {
		extensions[group] = {};
		fileTypes[group].forEach((ext) => {
			extensions[group][ext] = ext;
		});
	});

	return extensions;
};

export { makeId, transformFileTypes };
