import { getTrans } from '@/services/global/translations.interface';
import { MultilingualEmit } from '@/lib/components/Translations/Multilingual.events';
import { useLanguages } from '@/lib/components/Translations/languages.interface';
import { useMultilingual } from '@/lib/components/Translations/useMultilingual';
import { LanguageObject, MultilingualProps } from '@/lib/components/Translations/multilingualProps';

const multilingualController = (props: MultilingualProps, emit: MultilingualEmit) => {
	const { hasMultilingualError, multiLanguage, supportedLanguages, validationErrors } = useLanguages();
	const { getId, getLabelledBy, getName } = useMultilingual(props);

	const getValue = (language: string): string | null => {
		const translatedResource: LanguageObject | null | [] = props?.resource?.translated;

		if (!translatedResource || Array.isArray(translatedResource)) {
			return null;
		}

		const translatedLanguage = translatedResource[language] || {};

		return Object.prototype.hasOwnProperty.call(translatedLanguage, props.property)
			? translatedLanguage[props.property]
			: null;
	};

	const onInput = (language: string, property: string, value: string) => {
		/**
		 * ToDo: Switch this to use new translations system (via VueData), the same refers to useGlobals and the store
		 */
		const translated = JSON.parse(JSON.stringify(props.resource.translated));

		if (!translated || Array.isArray(translated)) {
			return emit('input', { [language]: { [property]: value } }, language, property, value);
		}

		if (!translated[language]) {
			translated[language] = {};
		}

		translated[language][property] = value;
		emit('input', translated, language, property, value);
	};

	const onFocus = (element: HTMLInputElement) => emit('focus', element);
	const onKeyUp = (element: HTMLInputElement) => emit('keyup', element);

	return {
		lang: getTrans(),
		multiLanguage,
		supportedLanguages,

		getId,
		getLabelledBy,
		getName,
		getValue,

		onInput,
		onFocus,
		onKeyUp,

		validationErrors,
		hasMultilingualError,
	};
};

export { multilingualController };
