var $ = require('jquery');
// eslint-disable-next-line @typescript-eslint/naming-convention
var Dialog = require('./dialog.js');

/**
 * Deleter Class
 *
 * Performs a delete action with ajax.
 */
module.exports = function (container) {
	var debug = false;
	container = $(container);

	container.find('.deleter-action').on('click', function (e) {
		e.preventDefault();

		var route = $(this).parent().prev('input[name="route"]').val();

		var dialog = new Dialog();

		dialog.show({ content: $('#lang-strings #alerts-delete-item').text() }, function () {
			$.ajax({
				type: 'DELETE',
				url: route,
				data: 'DELETE',
				dataType: 'json',
				timeout: 10000,
			})
				.done(function (response) {
					if (debug) {
						// eslint-disable-next-line no-console
						console.log(response);
					}

					if (response.redirect) {
						window.location.href = response.redirect;
					}
				})
				.fail(function (response) {
					if (debug) {
						// eslint-disable-next-line no-console
						console.log(response);
					}
				});
		});
	});
};
